<template>
  <!-- <layout-default> -->
  <div class="home" :style="[{ height: mainHeight + 'px' }]">
    <home-index></home-index>
  </div>
  <!-- </layout-default> -->
</template>

<script>
import LayoutDefault from "@/components/pages/layouts/Default";
import HomeIndex from "@/components/pages/home/MapOpenlayer";
export default {
  name: "Home",
  components: {
    HomeIndex,
    LayoutDefault,
  },
  computed: {
    isHeader() {
      return this.$store.getters.getIsHeader;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    mainHeight() {
      return this.documentHeight - (this.isHeader ? 3.5 * 16 : 0);
    },
  },
};
</script>
