<template>
  <div class="">
    <DialogDownloadAoi
      v-if="dialogDownloadAoi.show"
      :prop-title="dialogDownloadAoi.title"
      :prop-show="dialogDownloadAoi.show"
      :prop-style="{
        width: dialogDownloadAoi.width,
        overflowY: 'inherit!important',
      }"
      :prop-value="dialogDownloadAoi.value"
      @close="
        dialogDownloadAoi.show = false;
        dialogDownloadAoi.value = null;
      "
      @exportKML="exportKML"
    />
    <div class="_3Hueb8NdkKhJBFbr6Fa">
      <div class="_4Unfhr6dnJnhwh1HbFd define">
        <define
          ref="define"
          :map="map"
          :propFeature="feature"
          @drawEnd="drawEnd($event)"
          @clearSearch="clearSearch"
          @actionClick="actionClick"
          @modifiedFeature="modifiedFeature"
          @showPopupAoiInfo="showPopupAoiInfo"
          @showDialogExport="showDialogExport"
          @clearAllAoi="clearAllAoi"
          @setAoiFeature="setAoiFeature"
        ></define>
      </div>
    </div>
    <div class="animated bounceInDown" v-if="isShow">
      <keep-alive>
        <component
          :is="componentActive"
          :propCriteria="criteria"
          :map="map"
          :propData="{
            projects: componentActive === 'Criteria' ? projects : null,
            address:
              componentActive === 'ListAddress' ? criteria.address.items : null,
            items: componentActive === 'Models' ? objsImage : null,
          }"
          @onSearch="onSearch"
          @close="close"
          @message="onMessage"
          @initCriteria="initCriteria"
        >
        </component>
      </keep-alive>
    </div>
  </div>
</template>
<script>
import DialogDownloadAoi from "./middleside/define/DownloadAoi";
import Measure from "./middleside/Measure";
import Define from "./middleside/Define";
import Filters from "./middleside/Filters";
import Models from "@/components/pages/home/mapopenlayer/middleside/filters/Models";
import Products from "@/components/pages/home/mapopenlayer/middleside/filters/Products";
import ListAddress from "@/components/pages/home/mapopenlayer/middleside/filters/ListAddress";
import Upload from "@/components/pages/home/mapopenlayer/middleside/define/Upload";
import AoiInfo from "./middleside/define/AoiInfo";
import nominatimFunc from "@/utils/functions/nominatim";
import modelFunc from "@/utils/functions/model";
import projectFunc from "@/utils/functions/project";
import { fromLonLat, transform } from "ol/proj";
import {
  Circle as CircleStyle,
  Fill,
  RegularShape,
  Stroke,
  Style,
} from "ol/style.js";
import GeoJSON from "ol/format/GeoJSON";

import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
export default {
  props: {
    map: {
      required: true,
    },
  },
  components: {
    Measure,
    Define,
    Filters,
    Models,
    ListAddress,
    Upload,
    AoiInfo,
    Products,
    DialogDownloadAoi,
  },
  data() {
    return {
      feature: {
        active: {},
        items: [],
      },
      source: Object,
      componentActive: "Criteria",
      address: [],
      projects: [],
      isShow: false,
      criteria: {
        start_time_from: null,
        start_time_to: null,
        aoi: null,
        address: {
          name: "",
          boundary: null,
          boundingbox: null,
          item: [],
        },
        image_name: [],
        processing_level: "1A",
        cloud_coverange_from: 0,
        cloud_coverange_to: 100,
        resolution_from: 0,
        resolution_to: 100,
        incidence_angle_from: 0,
        incidence_angle_to: 90,
        satellite: {
          items: [],
          selected: [],
          types: [],
        },
        beam_mode: "",
        direction: "",
        pol_layer: "",
        model: [],
        projects: [],
      },
      dialogDownloadAoi: {
        show: false,
        title: "Export Aoi to File",
        width: 0,
        value: null,
      },
    };
  },
  async created() {
    if (this.documentWidth < 576) {
      this.dialogDownloadAoi.width = this.documentWidth + "px";
    } else {
      this.dialogDownloadAoi.width = "540px";
    }
  },
  watch: {},
  computed: {
    accessToken() {
      return this.$store.getters.getAccessToken;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
  },
  methods: {
    setAoiFeature(feature) {
      this.$emit("setAoiFeature", feature);
    },
    clearAllAoi() {
      this.$emit("clearAllAoi");
    },
    initCriteria(data) {
      this.$emit("initCriteria", data);
    },
    onMessage(msg) {
      this.$emit("message", msg);
    },
    clearSearch() {
      this.$emit("clearSearch");
    },
    showDialogExport(feature) {
      this.dialogDownloadAoi.value = feature;
      this.dialogDownloadAoi.show = true;
    },
    removeAoi(id) {
      if (this.$refs.define) this.$refs.define.removeFeature(id);
    },
    exportKML() {
      if (this.$refs.define) this.$refs.define.downloadPolygon();
    },
    showPopupAoiInfo(data) {
      this.$emit("showAoiInfo", {
        clientX: data.event && data.event.clientX ? data.event.clientX : 0,
        clientY: data.event && data.event.clientY ? data.event.clientY : 0,
        feature: data.feature,
        name: data.name,
        tool: data.tool,
        show: true,
        id: data.id,
      });
    },
    panToLocation(val) {
      if ((val.lat === val.lng) === 0) return;
      if (Object.keys(this.map).length > 0) {
        this.map.getView().animate({
          center: fromLonLat([val.lng, val.lat]),
          duration: 2000,
        });
      }
    },
    actionClick(val) {
      switch (val.code) {
        case "CRITERIA":
          this.componentActive = "Criteria";
          this.isShow = true;
          break;
        case "MODELS":
          this.componentActive = "Products";
          this.isShow = true;
          break;
        case "UPLOAD":
          this.componentActive = "Upload";
          this.isShow = true;
          break;
      }
    },
    close(val) {
      switch (val.component) {
        case "CRITERIA":
          this.isShow = false;
          break;
        case "MODELS":
          this.componentActive = "Criteria";
          this.isShow = false;
          break;
        case "LIST_ADDRESS":
          this.componentActive = "Criteria";
          break;
        case "UPLOAD":
          this.componentActive = "Criteria";
          this.isShow = false;
          break;
        default:
          this.isShow = false;
          break;
      }
    },
    modifiedFeature(val) {
      // this.aoiInfo.feature = null;
      // this.aoiInfo.feature = val;
      // this.aoiInfo.tool = "Polygon";
    },
    drawEnd(val) {
      this.$emit("drawEnd", val);
    },
    onSearch(val) {
      if (this.componentActive === "Upload") {
        if (val) {
          this.drawBoundary(val);
        }
        this.$emit("onSearch", val);
        this.componentActive = "Criteria";
        this.isShow = false;
      } else {
        if (val.type) {
          if (val.type && val.type === "ZOOM") this.drawBoundary(val);
        }
        this.$emit("onSearch");
        this.componentActive = "Criteria";
        this.isShow = false;
      }
    },
    drawBoundary(val) {
      if (Object.keys(this.map).length > 0) {
        if (Object.keys(this.source).length === 0) {
          var layers = this.map.getLayers().array_;
          if (layers.length > 0) {
            for (let i = 0; i < layers.length; i++) {
              if (layers[i].get("id") === "layerdraw") {
                this.source = layers[i].values_.source;
                break;
              }
            }
          }
        }
      }
      if (Object.keys(this.source).length > 0) {
        for (let i = 0; i < this.source.getFeatures().length; i++) {
          this.source.getFeatures()[i].setStyle(
            new Style({
              fill: new Fill({
                color: "rgba(255,255,255,0.4)",
              }),
              strokeColor: "#3399CC",
              stroke: new Stroke({
                color: "black",
                width: 1.25,
              }),
            })
          );
        }
        let id =
          new Date().getTime() +
          Math.floor(Math.random() * 99999) -
          Math.floor(Math.random() * 100000);
        switch (val.type) {
          case "ZOOM":
          case "SHAPE":
            let features = new GeoJSON().readFeatures(val.feature, {
              featureProjection: "EPSG:3857",
            });
            if (features) {
              if (features.length > 0) {
                features[0].set("id_", id);
                features[0].id_ = id;
                features[0].setStyle(
                  new Style({
                    stroke: new Stroke({
                      color: "red",
                      width: 1.25,
                    }),
                    fill: new Fill({
                      color: "rgba(255,255,255,0.4)",
                    }),
                    strokeColor: "#3399CC",
                  })
                );

                this.source.addFeature(features[0]);
                setTimeout(() => {
                  if (this.$refs.define) {
                    this.$refs.define.addAreaLabel(features[0]);
                  }
                }, 200);
                let extent = features[0].getGeometry().getExtent();
                this.map.getView().animate({
                  center: [extent[0], extent[1]],
                  duration: 1000,
                  zoom: this.map.getView().getZoom(),
                });
              }
            }
            break;
          case "KML":
            val.feature.set("id_", id);
            val.feature.id_ = id;
            val.feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: "red",
                  width: 1.25,
                }),
                fill: new Fill({
                  color: "rgba(255,255,255,0.4)",
                }),
                strokeColor: "#3399CC",
              })
            );
            this.source.addFeature(val.feature);
            setTimeout(() => {
              if (this.$refs.define) {
                this.$refs.define.addAreaLabel(val.feature);
              }
            }, 200);
            let extent1 = val.feature.getGeometry().getExtent();
            this.map.getView().animate({
              center: [extent1[0], extent1[1]],
              duration: 1000,
              zoom: this.map.getView().getZoom(),
            });
            break;
          case "Geojson":
            val.feature.set("id_", id);
            val.feature.id_ = id;
            val.feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: "red",
                  width: 1.25,
                }),
                fill: new Fill({
                  color: "rgba(255,255,255,0.4)",
                }),
                strokeColor: "#3399CC",
              })
            );
            this.source.addFeature(val.feature);
            setTimeout(() => {
              if (this.$refs.define) {
                this.$refs.define.addAreaLabel(val.feature);
              }
            }, 200);
            let extent2 = val.feature.getGeometry().getExtent();
            this.map.getView().animate({
              center: [extent2[0], extent2[1]],
              duration: 1000,
              zoom: this.map.getView().getZoom(),
            });
            break;
          case "Query":
            val.feature.set("id_", id);
            val.feature.id_ = id;
            val.feature.setStyle(
              new Style({
                stroke: new Stroke({
                  color: "red",
                  width: 1.25,
                }),
                fill: new Fill({
                  color: "rgba(255,255,255,0.4)",
                }),
                strokeColor: "#3399CC",
              })
            );
            this.source.addFeature(val.feature);
            break;
        }
        let obj = {
          name: this.$t("label_polygon") + (this.feature.items.length + 1),
          value: val.value,
          id: id,
        };
        this.feature.active = obj;
        this.feature.items.push(obj);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
._3Hueb8NdkKhJBFbr6Fa {
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // width: 50%;
  // height: 30%;
  // margin: auto;
  display: inline-flex;
  flex-direction: row;
}
._4Unfhr6dnJnhwh1HbFd {
  margin: 0 0.25rem;
  z-index: 1;
  &.define {
    background: transparent//white;
  }
}
.animated {
  position: fixed;
  z-index: 2;
  margin: 0 auto; /* Will not center vertically and won't work in IE6/7. */
  left: 0;
  right: 0;
  top: 4rem;
  background: white;
  // min-height: 500px;
  width: 800px;
  // max-height: 672px;
  // overflow-y: auto;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(2, 34, 89, 1);
  -moz-box-shadow: 0px 0px 2px 0px rgba(2, 34, 89, 1);
  box-shadow: 0px 0px 2px 0px rgba(2, 34, 89, 1);
  border-radius: 0.25rem;

  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
}

@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-1000px);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-1000px);
  }

  100% {
    -moz-transform: translateY(0);
  }
}
@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-1000px);
  }

  100% {
    -o-transform: translateY(0);
  }
}
@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-1000px);
  }

  100% {
    transform: translateY(0);
  }
}
.bounceInDown {
  -webkit-animation-name: bounceInDown;
  -moz-animation-name: bounceInDown;
  -o-animation-name: bounceInDown;
  animation-name: bounceInDown;
}
</style>
