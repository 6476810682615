<template>
  <div class="datatable">
    <DataTable
      :value="propItems"
      rowGroupMode="subheader"
      groupRowsBy="provider"
      sortMode="single"
      sortField="provider"
      :sortOrder="1"
    >
      <template #empty v-if="propItems && propItems.length === 0">
        <span class="text-center">{{ $t("label_no_data") }}</span>
      </template>
      <Column field="provider" header="" v-if="propItems.length"></Column>
      <Column
        header="No"
        headerStyle="width: 3rem; text-align: center!important"
        bodyStyle="text-align: center;"
      >
        <template #body="slotProps">
          {{ slotProps.index + 1 }}
        </template></Column
      >
      <Column field="satellite" :header="$t('label_satellite')"></Column>
      <Column
        field="total"
        :header="$t('label_amount')"
        headerStyle="width: 8rem"
      ></Column>
      <Column
        headerStyle="width: 4.25rem; text-align: center!important"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <Button
            icon="fas fa-info-circle"
            type="button"
            class="p-button-sm btn-geostore2"
            :title="$t('button_text_detail')"
            @click="shoDialogDetail(slotProps.data)"
          >
          </Button>
        </template>
      </Column>
      <template #groupheader="slotProps">
        <span class="image-text"
          ><strong>{{ slotProps.data.provider }}</strong
          >&nbsp;({{ $t("lable_download_total") }}:&nbsp;{{
            calculateGroupTotal(slotProps.data.provider)
          }})
        </span>
      </template>
    </DataTable>
  </div>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import Button from "@/components/commons/button/Button";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
import moment from "moment";
export default {
  components: { CommonAccordion, DataTable, Column, Button, ContextMenu },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    calculateGroupTotal(name) {
      let total = 0;
      if (this.propItems) {
        for (let item of this.propItems) {
          if (item.provider === name) {
            total = total + item.total;
          }
        }
      }
      return total;
    },
    shoDialogDetail(item) {
      this.$emit("shoDialogDetail", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.datatable {
  padding: 0.5rem;
}
</style>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.statistic-download__datatable {
  .p-datatable {
    border-radius: 0.25rem;
    .p-datatable-thead {
      tr {
        border-bottom: 1px solid #eff2f5;
        th {
          background: white;
          border: none;
          color: #a1a5b7;
        }
      }
    }
    .p-datatable-tbody {
      tr {
        border-bottom: 1px solid #eff2f5;
        &.p-highlight {
          background: $background-color-2 !important;
          td {
            color: white;
          }
        }
        td {
          border: none;
          color: $text-color;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
</style>