<template>
  <form class="m-2">
    <div class="form-group">
      <label class="control-label">{{ $t("label_kml_file") }}</label>
      <div class="custom-file">
        <input
          type="file"
          accept=".kml"
          class="custom-file-input"
          @change="changeFile"
        />
        <label class="custom-file-label" for="customFile">{{
          propData.file ? propData.file.name : ""
        }}</label>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label">{{ $t("label_boundary") }}</label>
      <textarea
        class="form-control"
        rows="7"
        readonly
        v-model="propData.contents"
      ></textarea>
    </div>
  </form>
</template>
<script>
export default {
  props: {
    propData: {
      type: Object,
      default: () => {
        return {
          file: null,
          boundary: null,
        };
      },
    },
  },
  methods: {
    changeFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      else {
        let vm = this;
        vm.propData.file = files[0];
        var reader = new FileReader();
        reader.onload = async function () {
          vm.propData.contents = reader.result;
        };
        reader.readAsText(files[0]);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
textarea{
  font-size: 14px;
}
</style>