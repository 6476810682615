<template>
  <nav
    class="navbar navbar-icon-top navbar-expand-lg nav-default navbar-main"
    :class="
      routeName === 'Signup' || routeName === 'ContactUs' ? 'fixed-top' : ''
    "
  >
    <a class="navbar-brand d-flex" @click="goHome" :title="$t('title_home')">
      <img
        src="/img/logo3.png"
        width="30"
        height="30"
        class="d-inline-block align-top"
        alt
      />&nbsp; Geospatial Hub
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarToggler"
      aria-controls="navbarToggler"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="fas fa-bars"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarToggler">
      <ul class="navbar-nav navbar-right ml-auto">
        <li class="nav-item dropdown" v-show="loggedIn">
          <a
            class="nav-link dropdown-toggle"
            id="navbarDropdownAfterLoggedin"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ userMe.full_name ? userMe.full_name : "" }}
          </a>
          <div
            class="dropdown-menu dropdown-menu-right dropdown-cyan"
            aria-labelledby="navbarDropdownAfterLoggedin"
          >
            <a class="dropdown-item" @click="goMyselfPage" v-if="loggedIn"
              ><i class="fas fa-info-circle"></i>&nbsp;{{
                $t("label_my_info")
              }}</a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" @click="goShareWithMePage" v-if="loggedIn"
              ><i class="fas fa-images"></i>&nbsp;{{
                $t("menu_sidebar_image_shared_with_me")
              }}</a
            >
            <a class="dropdown-item" @click="goMyRequestPage" v-if="loggedIn"
              ><i class="fas fa-clipboard-list"></i>&nbsp;{{
                $t("menu_sidebar_my_request")
              }}</a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" @click="onLogout" v-if="loggedIn"
              ><i class="fas fa-sign-out-alt"></i>&nbsp;{{
                $t("label_logout")
              }}</a
            >
          </div>
        </li>
        <li
          class="nav-item item-satellite-orbit"
          @click="go2SatelliteOrbitPage"
        >
          <a class="nav-link"> Satellite Orbit </a>
        </li>
        <li class="nav-item item-login" v-if="!loggedIn">
          <a class="nav-link" :title="$t('label_login')" @click="openLoginPage">
            {{ $t("label_login") }}
          </a>
        </li>
        <li class="nav-item item-signin" v-if="!loggedIn">
          <a class="nav-link" :title="$t('label_signup')" @click="goSingupPage">
            {{ $t("label_signup") }}
          </a>
        </li>

        <li class="nav-item item-tasking" v-if="isAdmin">
          <a
            class="nav-link"
            :title="$t('label_tasking')"
            @click="openTaskingForm"
          >
            {{ $t("label_tasking") }}
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
import Dropdown from "@/components/commons/dropdown/Dropdown";
import oauthFunc from "@/utils/functions/oauth";
import meControllerFunc from "@/utils/functions/mecontroller";
import cryptoFunc from "@/utils/functions/crypto";
export default {
  components: {
    Dropdown,
  },
  data() {
    return {
      userMe: {},
      cartCount: 0,
      language: null,
      language2: null,
      languages: [
        {
          code: "en",
          name: "English",
          label: "English",
          id: 2,
        },
        {
          code: "vi",
          name: "Vietnamese",
          label: "Vietnamese",
          id: 1,
        },
      ],
      callBack: null,
    };
  },
  created() {
    if (this.lang) {
      if (this.languages.findIndex((x) => x.code === this.lang) >= 0)
        this.language2 = this.languages.find((x) => x.code === this.lang);
      else {
        this.language2 = this.languages.find((x) => x.code === "en");
      }
    } else {
      this.language2 = this.languages.find((x) => x.code === this.language);
    }
  },
  watch: {
    loggedIn: {
      handler(val) {
        if (val) {
          this.getMyInfo();
          if (this.callBack) {
            this[this.callBack]();
          }
        } else {
          this.cartCount = 0;
          this.callBack = null;
        }
      },
      immediate: true,
    },
    language2(val) {
      localStorage.setItem("language", val.code);
      this.$store.dispatch("setLang", val.code);
    },
  },
  methods: {
    async getMyInfo() {
      try {
        let response = await oauthFunc.userMe(this.accessToken);
        if (response && response.status === 200) {
          this.userMe = response.data;
        } else this.userMe = {};
        this.$store.dispatch("setUser", this.userMe);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getMyInfo);
        } else this.userMe = {};
      }
    },
    async getMyCart() {
      try {
        let response = await meControllerFunc.getMyCart(
          {},
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.cartCount =
            response.data && response.data.data && response.data.data.imageList
              ? response.data.data.imageList.length
              : 0;
          if (this.cartCount && this.cartCount > 0)
            this.$store.dispatch(
              "setMyCart",
              response.data.data.imageList.map((x) => x.id)
            );
        } else this.cartCount = 0;
      } catch (error) {
        this.cartCount = 0;
      }
    },

    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {});
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {});
      }
      localStorage.removeItem("lock-refresh");
    },
    goHome() {
      if (this.$route.path != "/") {
        this.$router.push({
          path: "/",
        });
      }
    },
    go2ContactUs() {
      if (this.$route.path != "/contact")
        this.$router.push({
          path: "/contact",
        });
    },
    goMyCartPage() {
      if (this.$route.path != "/myself/my-cart")
        this.$router.push({
          path: "/myself/my-cart",
        });
    },
    openLoginPage: function () {
      if (this.$route.name === "Signup") {
        this.$router.push({
          name: "Login",
        });
      } else this.$emit("openLoginForm");
    },
    onLogout: function () {
      this.$store.dispatch("clearToken");
      this.userMe = {};
    },
    goMyProjectPage() {
      if (this.$route.path != "/myself/my-project")
        this.$router.push({
          path: "/myself/my-project",
        });
    },
    goMyImagesPage() {
      if (this.$route.path != "/myself/my-images")
        this.$router.push({
          path: "/myself/my-images",
        });
    },
    goMyRequestPage() {
      if (this.$route.path != "/myself/my-order")
        this.$router.push({
          path: "/myself/my-order",
        });
    },
    goShareWithMePage() {
      if (this.$route.path != "/myself/shared-for-me")
        this.$router.push({
          path: "/myself/shared-for-me",
        });
    },
    goGuidePage() {
      let routeData = this.$router.resolve({ name: "Manual" });
      window.open(routeData.href, "_blank");
    },
    openTaskingForm() {
      if (!this.isAdmin) return;
      if (this.$route.name === "ContactUs") {
        this.$router.push({
          path: "/",
        });
      } else {
        if (!this.loggedIn) {
          this.callBack = "openTaskingForm";
          this.openLoginPage();
        } else {
          this.callBack = null;
          this.$store.dispatch("setIsTasking", true);
        }
      }
    },
    goSingupPage() {
      if (this.$route.path != "/signup")
        this.$router.push({
          path: "/signup",
        });
    },
    goMyselfPage() {
      if (this.$route.path != "/myself" || this.$route.path != "/myself/")
        this.$router.push({
          path: "/myself",
        });
    },
    goUserOrderPage() {
      let routeData = this.$router.resolve({ name: "UserOrder" });
      window.open(routeData.href, "_blank");
    },
    go2SatelliteOrbitPage() {
      let routeData = this.$router.resolve({ name: "SatelliteOrbit" });
      window.open(routeData.href, "_blank");
    },
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    accessToken() {
      return this.$store.getters.getAccessToken;
    },
    lang() {
      return this.$store.getters.getLang;
    },
    routeName() {
      return this.$route.name;
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    roles() {
      return this.$store.getters.getRoles;
    },
    isAdmin() {
      return this.roles && this.roles.map((x) => x.name).includes("ROLE_ADMIN");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.item-login,
.item-signin {
  a {
    border: 1px solid $background-color-2;
    color: white;
    width: 7rem;
    border-radius: 0.25rem;
    text-align: center;
    margin-right: 0.6125rem;
  }
}
.item-satellite-orbit {
  a {
    border: 1px solid $background-color-2;
    color: white;
    width: 7rem;
    border-radius: 0.25rem;
    text-align: center;
    margin-right: 0.6125rem;
  }
}
.item-tasking {
  a {
    border: 1px solid $background-color-2;
    background: $background-color-2;
    color: white;
    width: 7rem;
    border-radius: 0.25rem;
    text-align: center;
  }
}
.navbar-main {
  background: $background-nav;
  color: white;
  a.navbar-brand {
    img {
      margin-right: 0.5rem;
    }
  }
  a {
    color: white;
    cursor: pointer;
  }
  .nav-link {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    line-height: 1.5rem;
    &:active {
      background: #007ad9;
      color: white;
    }
  }
  .nav-link:hover {
    color: #fff;
    cursor: pointer;
  }
  .dropdown {
    a,
    p {
      font-size: 14px;
    }
  }
}
.navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
  .navbar-toggler-icon {
    color: rgba(255, 255, 255, 0.5);
  }
}
.navbar-left {
  .nav-item {
    border-right: 1px solid #3b414b;
    padding: 0;
    padding: 0 1rem;
    display: flex;
    &.nav-item__email {
      padding-left: 0;
    }
    .item-icon {
      width: 40px;
      height: 40px;
      background-color: #3b424b;
      border-radius: 50%;
      i {
        width: 40px;
        height: 40px;
        color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
    .item-info {
      margin: 0;
      margin-left: 0.5rem;
      display: flex;
      flex-direction: column;
      .info-label {
        font-size: 12px;
      }
    }
    &:last-child {
      border-right: none;
    }
  }
}
.navbar-right {
  height: 100%;
  li {
    &.item-order {
      border-right: 1px solid #fff;
    }
    &.item-language {
      .p-dropdown {
        border: transparent;
        background: transparent;
        &:active {
          outline: 0;
          border: 0;
          box-shadow: none;
        }
        &:hover {
          outline: 0;
          border: 0;
          box-shadow: none;
        }
        &:focus {
          outline: 0;
          border: 0;
          box-shadow: none;
        }
        &:visited {
          outline: 0;
          border: 0;
          box-shadow: none;
        }
        .p-dropdown-items-wrapper {
          width: 9rem;
          li {
            padding: 0.375rem 1rem;
          }
        }
        .p-dropdown-items-wrapper {
          padding: 0.5rem 0;
        }
        .p-dropdown-item {
          padding: 4px 24px;
        }
        .p-dropdown-label {
          padding-right: 0.25rem;
          color: white;
          font-family: Helvetica Neue, Arial, sans-serif;
        }
      }
      .p-dropdown-trigger {
        display: none;
      }
    }
  }
}
a.dropdown-item {
  cursor: pointer;
  &:hover {
    color: white;
  }

  &:active {
    color: white !important;
    background: #007ad9 !important;
  }
}
.dropdown-divider {
  margin: 0.25rem 0;
}
@media (max-width: 991.98px) {
  .navbar-right {
    li {
      margin-bottom: 0.5rem;
    }
  }
  // .item-satellite-orbit,
  // .item-login,
  // .item-signin {
  //   margin-bottom: 0.5rem;
  // }
}
</style>
