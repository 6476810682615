import { render, staticRenderFns } from "./Models.vue?vue&type=template&id=18851b31&scoped=true&"
import script from "./Models.vue?vue&type=script&lang=js&"
export * from "./Models.vue?vue&type=script&lang=js&"
import style0 from "./Models.vue?vue&type=style&index=0&id=18851b31&lang=scss&scoped=true&"
import style1 from "./Models.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18851b31",
  null
  
)

export default component.exports