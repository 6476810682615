<template>
  <div class="aoi-info">
    <CommonAccordion :isChevronEnd="true" :propTitle="$t('label_aoi_info')"
      ><div slot="content" class="accordion w-100">
        <div class="aoi-map" id="order__aoi-map"></div>
      </div>
    </CommonAccordion>
  </div>
</template>
<script>
var formatArea = function (polygon) {
  var area = getArea(polygon);
  var output;
  if (area > 10000) {
    output = ((area / 1000000) * 100) / 100 + " " + "km2";
  } else {
    output = (area * 100) / 100 + " " + "m2";
  }
  return output;
};
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import Map from "ol/Map";
import View from "ol/View";
import { Tile as TileLayer } from "ol/layer";
import XYZ from "ol/source/XYZ";
import { TileImage } from "ol/source";
import { defaults as defaultControls } from "ol/control";
import { fromLonLat, transform } from "ol/proj";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import Polygon from "ol/geom/Polygon";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import {
  Style,
  Fill,
  Stroke,
  Circle,
  Icon,
  Text,
  RegularShape,
} from "ol/style.js";
import { getCenter } from "ol/extent";
import { getArea } from "ol/sphere.js";
export default {
  components: {
    CommonAccordion,
  },
  props: {
    propItem: {
      type: Object,
      default: () => {},
    },
    propImages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      map: Object,
      layer: Object,
      layerAoi: Object,
    };
  },
  created() {},
  mounted() {
    this.initMap();
  },
  methods: {
    async initMap() {
      var vm = this;
      var source = new VectorSource({ wrapX: false, id: "sourcedraw" });

      this.layer = new VectorLayer({
        source: source,
      });
      this.layer.set("id", "layerdraw");
      var sourceAoi = new VectorSource({ wrapX: false, id: "sourcedrawAoi" });

      this.layerAoi = new VectorLayer({
        source: sourceAoi,
      });
      this.layerAoi.set("id", "layerdrawAoi");
      this.map = new Map({
        controls: defaultControls({ attribution: false, zoom: false }),
        layers: [
          new TileLayer({
            title: "Basemap",
            id: "vgbasemap",
            source: new XYZ({
              url:
                vm.basemap &&
                Object.keys(vm.basemap).length > 0 &&
                vm.basemap.source &&
                vm.basemap.source.url
                  ? vm.basemap.source.url
                  : process.env.VUE_APP_BASEMAP_URL,
            }),
            show: true,
            opacity: 1,
          }),
          this.layer,
          this.layerAoi,
        ],
        target: "order__aoi-map",
        view: new View({
          center: fromLonLat([108.222848, 16.062833]),
          zoom: 8,
          minZoom: 0,
          maxZoom: 20,
        }),
      });

      await this.addFootprintImages();
      await this.addFootprintAOI();
      setTimeout(() => {
        this.map.updateSize();
      }, 500);
    },

    addFootprintAOI() {
      if (this.layerAoi && Object.keys(this.layerAoi).length > 0) {
        this.layerAoi.getSource().clear();
        if (this.propItem && this.propItem.geom) {
          var points = [];
          if (
            this.propItem.geom &&
            this.propItem.geom.coordinates &&
            this.propItem.geom.coordinates.length > 0
          ) {
            for (let j = 0; j < this.propItem.geom.coordinates[0].length; j++) {
              if (j > 4) break;
              points.push(this.propItem.geom.coordinates[0][j]);
            }
          }
          try {
            this.addFeature(
              this.layer,
              points,
              new Style({
                fill: new Fill({
                  color: this.hexToRgbA("#ff0000", 0.3), // "rgba(255,165,0,.5)",
                }),
                stroke: new Stroke({
                  color: this.hexToRgbA("#ff0000", 0.4), //"orange",
                  width: 1.25,
                }),
              }),
              "aoifeature"
            );
          } catch (error) {}
        }
      }
    },
    addFootprintImages() {
      if (this.layer && Object.keys(this.layer).length > 0) {
        let features = this.layer.getSource().getFeatures();
        if (features && features.length > 0) {
          for (let i = 0; i < features.length; i++) {
            if (features[i].getId() != "aoifeature")
              this.layer.getSource().removeFeature(features[i]);
          }
        }
        if (this.propImages && this.propImages.length > 0) {
          for (let i = 0; i < this.propImages.length; i++) {
            var points = [];
            if (
              this.propImages[i].geom &&
              this.propImages[i].geom.coordinates &&
              this.propImages[i].geom.coordinates.length > 0
            ) {
              for (
                let j = 0;
                j < this.propImages[i].geom.coordinates[0].length;
                j++
              ) {
                if (j > 4) break;
                points.push(this.propImages[i].geom.coordinates[0][j]);
              }
            }
            try {
              this.addFeature(
                this.layer,
                points,
                new Style({
                  fill: new Fill({
                    color: "rgba(0,158,247,0.3)",
                  }),
                  stroke: new Stroke({
                    color: "rgba(0,158,247,1)",
                    width: 1.25,
                  }),
                }),
                "FOOTPRINT_" + this.propImages[i].id
              );
            } catch (error) {}
          }
        }
      }
    },
    addFeature(layer, coordinates, style, id) {
      let feature = layer.getSource().getFeatureById(id);
      if (!feature) {
        var points = [];
        try {
          for (var i = 0; i < coordinates.length; i++) {
            points[i] = transform(coordinates[i], "EPSG:4326", "EPSG:3857");
          }
          feature = new Feature({
            geometry: new Polygon([points]),
            id: id,
          });
          feature.set("id", id);
          feature.id_ = id;
          feature.setStyle(style);
          layer.getSource().addFeature(feature);
          let ext = feature.getGeometry().getExtent();
          this.map.getView().fit(ext, this.map.getSize());
          this.map.getView().setZoom(this.map.getView().getZoom() - 1);
          if (id === "aoifeature") this.addAreaLabel(feature);
        } catch (error) {}
      } else {
        feature.style = { visibility: "visible" };
      }
    },
    addAreaLabel(feature) {
      if (this.layerAoi && Object.keys(this.layerAoi).length > 0) {
        let coor = this.getTopLeftFromFeature(feature),
          point;
        if (coor.lat != null && coor.lng != null) {
          point = new Point(
            transform([coor.lng, coor.lat], "EPSG:4326", "EPSG:3857")
          );
        } else {
          var extent = feature.getGeometry().getExtent().slice(0);
          point = new Point([extent[0], extent[3]]);
        }
        let featureLabel = new Feature({
          geometry: point,
          id: "areaLabel",
        });
        featureLabel.setStyle(
          new Style({
            text: new Text({
              textAlign: "center",
              textBaseline: "middle",
              font: "14px Helvetica Neue, Roboto, Avenir, Helvetica, Arial, sans-serif",
              fill: new Fill({ color: this.hexToRgbA("#ffffff", 1) }),
              stroke: new Stroke({
                color: this.hexToRgbA("#000", 0.8),
                width: 2,
              }),
              text: formatArea(feature.getGeometry()).replace(
                "<sup>2</sup>",
                "2"
              ),
              offsetX: 0,
              offsetY: 0,
            }),
          })
        );
        this.layerAoi.getSource().addFeature(featureLabel);
      }
    },
    getTopLeftFromFeature(feature) {
      if (feature && Object.keys(feature).length > 0) {
        let coors = feature.getGeometry().getCoordinates();
        let coordinatesDraw = [];
        for (let i = 0; i < coors[0].length; i++) {
          coordinatesDraw.push(
            transform(coors[0][i], "EPSG:3857", "EPSG:4326")
          );
          if (i >= 3) break;
        }
        var centerTest = getCenter(feature.getGeometry().getExtent());

        centerTest = transform(centerTest, "EPSG:3857", "EPSG:4326");
        let upperPoints = coordinatesDraw.filter((x) => x[1] > centerTest[1]);
        if (upperPoints && upperPoints.length === 0) {
          if (coordinatesDraw.length > 0) {
            upperPoints.push(coordinatesDraw[0]);
          }
        }
        // Find top left
        let indexOfTopLeft = -1;
        let topleft = {
          lat: null,
          lng: null,
        };
        if (upperPoints.length === 1) {
          topleft.lat = upperPoints[0][1];
          topleft.lng = upperPoints[0][0];
        } else if (upperPoints.length == 2) {
          if (upperPoints[0][0] < upperPoints[1][0]) {
            topleft.lat = upperPoints[0][1];
            topleft.lng = upperPoints[0][0];
          } else {
            topleft.lat = upperPoints[1][1];
            topleft.lng = upperPoints[1][0];
          }
        } else if (upperPoints.length == 3) {
          let max = Math.max.apply(
            null,
            upperPoints.map((x) => x[0])
          );
          let idx = upperPoints.findIndex((x) => x[0] === max);
          if (idx >= 0) {
            upperPoints.splice(idx, 1);
            if (upperPoints[0][0] < upperPoints[1][0]) {
              topleft.lat = upperPoints[0][1];
              topleft.lng = upperPoints[0][0];
            } else {
              topleft.lat = upperPoints[1][1];
              topleft.lng = upperPoints[1][0];
            }
          }
        }
        return topleft;
      }
    },

    hexToRgbA(hex, opacity) {
      if (opacity === null || opacity === undefined) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
  },

  computed: {
    basemap() {
      return this.$store.getters.getBasemap;
    },
  },
};
</script>
<style lang="scss">
.order-detail {
  .aoi-info {
    margin-top: 0.5rem;
    .accordion {
      background: white;
      padding: 0.5rem;
      border-radius: 0.5rem;
      .accordion-header a {
        padding-left: 0;
      }
    }

    #order__aoi-map {
      width: 100%;
      height: 400px;
      border: 1px solid #ddd;
      border-radius: 0.25rem;
    }
  }
}
</style>
