import axios from "axios";
import basicApi from "@/utils/apis/basic";
export default {
    async search(params) {
        let url=process.env.VUE_APP_API_SEARCH_ADDRESS ;//+"?";
        // for (const [key, value] of Object.entries(params)) {
        //     url+= key+"="+value+"&";
        // }
        // url= url.substring(0,url.length-1);
        const object = axios({
            method: "get",
            url: url ,
            params: params,
        });
        let response = await object;
        return response;
    }
}