<template>
  <div class="planet-tab">
    <div class="tab-left left-content">
      <div class="planet-steps" :class="scrollActive ? 'shadow-b' : ''">
        <StepProgress
          :steps="steps"
          :current-step="step"
          :activeColor="'#009EF7'"
          :activeTextColor="'#1E1E2D'"
          :passiveColor="'#B3C3D4'"
          :iconClass="'fas fa-check'"
        />
      </div>
      <div
        class="planet-components p-4"
        :style="{ height: contentHeight + 'px' }"
        @scroll="onScroll"
      >
        <div class="component-order-name">
          <div class="form-group" v-show="step === 0">
            <label for="txt-cart-description" class="control-label pl-1">
              Order name
            </label>
            <Textarea
              id="txt-cart-description"
              class="form-control"
              rows="2"
              maxlength="512"
              autofocus
              :autoResize="true"
              v-model="cart.name"
              @change="orderNameChanged"
            >
            </Textarea>
            <small class="w-100 text-right float-right mt-1"
              >{{
                cart.name && cart.name.trim() ? cart.name.trim().length : 0
              }}/512</small
            >
          </div>
        </div>
        <div class="component-select-assets" v-show="step === 1">
          <div class="assets-box">
            <h4 class="title-box">Planet - Select assets</h4>
            <div
              v-for="bundle in bundles4Select"
              :key="bundle.id"
              class="border-t"
            >
              <PlanetBundles
                :prop-bundle="bundle"
                v-if="bundle.values.length > 0"
                @selectedBundle="selectedBundle"
              />
            </div>
          </div>
        </div>

        <div class="component-confirm text-center" v-show="step === 2">
          <label class="control-label"> Order type </label>
          <div class="form-row">
            <div class="col" v-for="item in planetOrderTypes" :key="item.id">
              <RadioButton
                :id="item.id"
                :name="item.id"
                :value="item.id"
                v-model="cart.order_type"
              />
              <label class="m-0 ml-2" :for="item.id">{{ item.label }}</label>
            </div>
          </div>
        </div>
      </div>
      <div
        class="planet-actions d-flex justify-content-between align-items-center px-4 py-2"
      >
        <Button
          v-if="step >= 1"
          @click="prevStep"
          :label="'Back'"
          :icon="'fas fa-arrow-left'"
          :class="'p-button-secondary p-button-text button-prev'"
        />
        <Button
          v-if="step < 2"
          @click="nextStep"
          :label="'Continue'"
          :class="'button-next'"
        />
      </div>
    </div>
    <div class="tab-right">
      <h6 class="summary-title">Order Summary - Planet</h6>
      <div class="summary-order">
        <div class="form-group">
          <label>Order name</label>
          <h6
            class="summary-order-name"
            :class="cart.name ? 'font-weight-bold' : 'text-muted'"
            :title="cart.name ? (cart.name.length > 26 ? cart.name : '') : ''"
          >
            {{
              cart.name
                ? cart.name.length > 26
                  ? cart.name.substring(0, 26) + "..."
                  : cart.name
                : "--"
            }}
          </h6>
        </div>
        <div class="form-group">
          <label>Orders to be placed</label>
          <h6 class="summary-order-name font-weight-bold">1</h6>
        </div>
      </div>
      <ul
        class="order-summary-list"
        :style="{ height: contentHeight - 80 + 'px' }"
      >
        <li
          class="summary-item"
          v-for="bundle in cart.bundles"
          :key="bundle.id"
        >
          <div class="item-header">
            <svg
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-do1tfz"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="SatelliteIcon"
            >
              <path
                fill-rule="evenodd"
                d="m17.714 2.3597 4.0406 4.0406c.4463.4463.4463 1.17 0 1.6162l-4.0405 4.0404.808.8084c.4463.4463.4463 1.1699 0 1.6162l-4.0406 4.0406c-.4463.4463-1.17.4463-1.6162 0l-.8084-.808-4.0404 4.0405c-.4463.4463-1.1699.4463-1.6162 0L2.3597 17.714c-.4463-.4463-.4463-1.17 0-1.6162l4.0401-4.0409-.8076-.8079c-.4463-.4463-.4463-1.17 0-1.6162l4.0406-4.0406c.4463-.4463 1.17-.4463 1.6162 0l.808.8076 4.0408-4.0401c.4463-.4463 1.1699-.4463 1.6162 0ZM4.7838 15.2889l-1.616 1.617 4.0406 4.0406L8.8238 19.33l-4.04-4.0412Zm2.4229-2.4251-1.6149 1.6171 4.04 4.0412 1.616-1.6172-4.0411-4.0411Zm3.2342-6.4635-4.0406 4.0406 7.273 7.2731 4.0407-4.0406-7.2731-7.2731Zm4.04-.8085-1.6171 1.6149 4.0411 4.0411 1.6172-1.616-4.0412-4.04Zm2.425-2.424-1.617 1.616 4.0412 4.04 1.6164-1.6154-4.0406-4.0406Z"
              ></path>
            </svg>
            <span> {{ bundle.id }}</span>
          </div>
          <div class="item-bundle-card">
            <div class="px-2 py-3">
              <div class="item-count">
                <svg
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="BurstModeOutlinedIcon"
                >
                  <path
                    d="M1 5h2v14H1zm4 0h2v14H5zm17 0H10c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1zm-1 12H11V7h10v10zm-3.57-4.38-2 2.57L14 13.47l-2 2.52h8z"
                  ></path></svg
                ><span class="pl-1 pt-1"
                  >&nbsp; {{ bundle.items_length }} items</span
                >
              </div>
              <h6 class="bundle-name mb-0">{{ bundle.bundle_name }}</h6>
              <div
                class="chip-container flex"
                v-if="
                  bundle.assets_item &&
                  bundle.assets_item.auxiliaryFiles === 'UDM2'
                "
              >
                <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                  <path
                    d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16-7-8v8H5l7-8V5h7v14z"
                  ></path>
                </svg>
                <span>UDM2</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="summary-actions" v-if="step > 0 || cart.name">
        <Button
          label="Order"
          class="button-confirm"
          @click="onOrderPlanet"
          :icon="
            propButton && propButton.processing ? 'fas fa-spinner fa-spin' : ''
          "
          :disabled="
            propButton && propButton.disable ? propButton.disable : false
          "
        >
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import StepProgress from "@/components/commons/steps/StepProgress";
import Textarea from "@/components/commons/textarea/Textarea";
import Button from "@/components/commons/button/Button";
import RadioButton from "@/components/commons/radiobutton/RadioButton";
import PlanetBundles from "./PlanetBundles.vue";
import bundles from "@/utils/commons/bundles";
export default {
  components: {
    StepProgress,
    Textarea,
    Button,
    RadioButton,
    PlanetBundles,
  },
  props: {
    propCartName: {
      type: String,
      default: null,
    },
    propPlanetImages: {
      type: Array,
      default: () => [],
    },
    propItemTypesSelected: {
      type: Array,
      default: () => [],
    },
    propButton: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      steps: ["Order name", "Select assets", "Order type"],
      step: 0,
      scrollActive: false,
      planetOrderTypes: [
        {
          id: "full",
          key: "full",
          label: "Full",
        },
        {
          id: "partial",
          key: "partial",
          label: "Partial",
        },
      ],
      cart: {
        name: null,
        order_type: "full",
        bundles: [],
      },
    };
  },
  created() {},
  mounted() {
    setTimeout(() => {
      for (let i = 0; i < this.bundles4Select.length; i++) {
        this.cart.bundles.push({
          id: this.bundles4Select[i].id,
          key: this.bundles4Select[i].id,
          assets:
            this.bundles4Select[i].values &&
            this.bundles4Select[i].values.length > 0
              ? this.bundles4Select[i].values[0].id
              : null,
          assets_item:
            this.bundles4Select[i].values &&
            this.bundles4Select[i].values.length > 0
              ? this.bundles4Select[i].values[0]
              : null,
          bundle_name:
            this.bundles4Select[i].values &&
            this.bundles4Select[i].values.length > 0
              ? this.bundles4Select[i].values[0].name
              : null,
          items_length: this.propPlanetImages.filter(
            (x) => x.properties.item_type === this.bundles4Select[i].id
          ).length,
        });
      }
    }, 100);
  },
  watch: {
    propCartName(val) {
      this.cart.name = val;
    },
  },
  methods: {
    orderNameChanged() {
      this.$emit("orderNameChanged", this.cart.name);
    },
    clearCart() {
      this.cart.name = "";
      this.cart.order_type = "full";
      this.cart.bundles = [];
    },
    onOrderPlanet() {
      let message = [];
      if (!this.cart.name || !this.cart.name.trim()) {
        message.push("Order Name is required.");
      }
      if (
        this.cart.bundles.filter((x) => x.assets).length <
        this.cart.bundles.length
      ) {
        message.push("Assets is required.");
      }
      if (message.length > 0) {
        this.$emit("message", message);
        return;
      }
      this.$emit("onOrderPlanet", this.cart);
    },
    selectedBundle(val) {
      let index = this.cart.bundles.findIndex((x) => x.id === val.id);
      if (index >= 0) {
        this.cart.bundles[index].assets = val.assets;
        this.cart.bundles[index].bundle_name = val.bundle_name;
        this.cart.bundles[index].assets_item = val.assets_item;
      } else {
        this.cart.bundles.push(val);
      }
    },
    async onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop > 0) {
        this.scrollActive = true;
      } else this.scrollActive = false;
    },
    nextStep() {
      this.step++;
    },
    prevStep() {
      this.step--;
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    contentHeight() {
      return (this.documentHeight * 50) / 100;
    },

    bundles4Select() {
      let results = [];
      for (const [key, value] of Object.entries(bundles)) {
        if (this.propItemTypesSelected.includes(key)) {
          let obj = {
            key: key,
            id: key,
            length: this.propPlanetImages.filter(
              (x) => x.properties.item_type === key
            ).length,
            values: [],
          };
          for (let i = 0; i < value.length; i++) {
            if (obj.values.findIndex((x) => x.id === value[i].id) < 0) {
              //remove same assets
              let index = value.findIndex(
                (x) => x.id != value[i].id && x.name === value[i].name
              );
              if (
                index >= 0 &&
                value[i].assets &&
                value[index].assets &&
                value[i].assets.length > value[index].assets.length
              ) {
                for (let k = 0; k < value[index].assets.length; k++) {
                  if (
                    value[i].assets.findIndex(
                      (x) => x === value[index].assets[k]
                    ) >= 0
                  ) {
                    value[index].assets.splice(k, 1);
                    k = k - 1;
                  }
                }
                if (value[index].assets.length === 0) {
                  value.splice(index, 1);
                  i = i - 1;
                  continue;
                }
              } else if (
                index >= 0 &&
                value[i].assets &&
                value[index].assets &&
                value[i].assets.length < value[index].assets.length
              ) {
                for (let k = 0; k < value[i].assets.length.length; k++) {
                  if (
                    value[index].assets.findIndex(
                      (x) => x === value[i].assets[k]
                    ) >= 0
                  ) {
                    value[i].assets.splice(k, 1);
                    k = k - 1;
                  }
                }
                if (value[i].assets.length === 0) {
                  value.splice(i, 1);
                  isSecureContext = i - 1;
                  continue;
                }
              }
              obj.values.push(value[i]);
            }
          }
          results.push(obj);
        }
      }
      return results;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.planet-tab {
  display: grid;
  grid-template-columns: [first] auto [line2] 272px [end];
  .tab-left {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 100%;
    align-items: stretch;
    align-content: stretch;
    .planet-steps {
      &.shadow-b {
        box-shadow: rgba(33, 35, 38, 0.2) 0px 10px 10px -10px;
      }
    }
    .planet-components {
      overflow: auto;
      .component-select-assets {
        .assets-box {
          .border-t {
            border-top-width: 1px;
            border-top-style: solid;
            border-color: rgb(189, 197, 198);
            &:first-child {
              border-top: none;
            }
          }
          .title-box {
            font-size: 20px;
            font-weight: 700;
            &::after {
              content: "";
              display: block;
              width: 100%;
              //border-bottom: 1px dashed #ccc;
              margin-top: 0.25rem;
            }
          }
        }
      }
    }
    .planet-actions {
      width: 100%;
      background-color: rgba($color: #000000, $alpha: 0.005);
      border-radius: 0.25rem;
      .button-prev {
        outline: none;
        box-shadow: none;
        &:focus {
          outline: none;
          box-shadow: none;
        }
        &:active {
          outline: none;
          box-shadow: none;
        }
      }
      .button-next {
        margin-left: auto;
        background-color: $background-color-2;
        border-color: $background-color-2;
      }
    }
  }
  .tab-right {
    background-color: rgba($color: $background-color-2, $alpha: 0.05);
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    .summary-title {
      font-size: 16px;
      font-weight: 700;
      padding: 0.5rem 1rem;
      border-bottom: 1px solid rgba(189, 197, 198, 0.5);
    }
    .summary-order {
      padding: 0.5rem 1rem 1rem;
      border-bottom: 1px solid rgba(189, 197, 198, 0.5);
      display: block;
      .form-group {
        margin-bottom: 0.5rem;
        h6 {
          margin: 0;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
    ul.order-summary-list {
      list-style: none;
      margin: 0;
      padding: 0;
      overflow: auto;
      height: 100%;
      position: relative;
      li.summary-item {
        padding: 0.75rem;
        border-bottom: 1px solid #eff2f5;
        &:last-child {
          border: 0;
        }
        .item-header {
          display: flex;
          box-sizing: border-box;
          list-style: none;
          font-weight: 500;
          font-size: 1rem;
          margin-bottom: 0.25rem;
          background-color: transparent;
          svg {
            user-select: none;
            width: 1em;
            height: 1em;
            display: inline-block;
            fill: currentcolor;
            flex-shrink: 0;
            transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            font-size: 1.5rem;
            margin-right: 4px;
          }
        }
        .item-bundle-card {
          width: 100%;
          color: rgb(0, 31, 35);
          transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          border-radius: 4px;
          border: 1px solid rgb(189, 197, 198);
          overflow: hidden;
          background-color: white;
          flex-shrink: 0;
          .item-count {
            max-width: 100%;
            font-family: Inter, sans-serif;
            font-size: 0.8125rem;
            display: inline-flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            height: 24px;
            color: rgb(0, 31, 35);
            white-space: nowrap;
            transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            cursor: default;
            outline: 0px;
            text-decoration: none;
            padding: 0px;
            vertical-align: middle;
            box-sizing: border-box;
            background-color: transparent;
            border-radius: 4px;
            border: none;
            svg {
              user-select: none;
              width: 1em;
              height: 1em;
              display: inline-block;
              fill: currentcolor;
              flex-shrink: 0;
              transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
              font-size: 1.5rem;
            }
          }
          .bundle-name {
            margin: 0px 0px 0.35em;
            font-weight: 700;
            font-size: 0.875rem;
            line-height: 1.57;
          }
          .chip-container {
            margin-top: 0.5rem;
            font-size: 0.8125rem;
            display: inline-flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            height: 24px;
            color: rgb(0, 31, 35);
            background-color: rgba(0, 0, 0, 0.08);
            white-space: nowrap;
            transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            cursor: default;
            outline: 0px;
            text-decoration: none;
            border: 0px;
            padding: 0px 0.375rem;
            vertical-align: middle;
            box-sizing: border-box;
            border-radius: 4px;
            svg {
              width: 16px;
              height: 16px;
              display: inline-block;
            }
            span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding-left: 4px;
              padding-top: 2px;
              margin: auto 0;
              text-align: center;
            }
          }
        }
      }
    }
    .summary-actions {
      padding: 0.5rem;
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      box-shadow: none;
      display: flex;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;
      flex-shrink: 0;
      position: static;
      background-color: transparent;
      color: inherit;
      background-image: unset;
      border-top: 1px solid rgba(189, 197, 198, 0.5);
      .button-confirm {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        outline: 0px;
        margin: 0px;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        font-size: 0.875rem;
        font-weight: 700;
        text-transform: none;
        line-height: 1.75;
        min-width: 64px;
        padding: 5px 10px;
        border-radius: 4px;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border: 1px solid $background-color-2;
        color: $background-color-2;
        width: 100%;
        box-shadow: none;
        background-color: transparent;
        &:hover {
          background-color: rgba($color: $background-color-2, $alpha: 0.125);
        }
      }
    }
  }
}
</style>
