<template>
  <div
    class="group"
    role="group"
    aria-label="Button group with nested dropdown"
  >
    <h6 class="box-title">
      <strong>Thước đo</strong>
    </h6>
    <div class="btn-group">
      <button
        type="button"
        class="btn btn-transparent"
        :class="selected === 'DISTANCE' ? 'active' : ''"
        @click="measureDistance"
        :title="'Đo khoảng cách'"
      >
        <i class="custome-font custome-measure-distance"></i>
      </button>
      <button
        type="button"
        class="btn btn-transparent"
        :class="selected === 'AREA' ? 'active' : ''"
        @click="measureArea"
        :title="'Đo diện tích'"
      >
        <i class="custome-font custome-measure-area"></i>
      </button>
      <button
        type="button"
        class="btn btn-transparent"
        @click="measureNone"
        :title="'Hủy đo lường'"
      >
        <i class="custome-font custome-tool-draw-cancel"></i>
      </button>
      <button
        type="button"
        class="btn btn-transparent"
        @click="clearAll()"
        :title="'Xóa bỏ đo lường'"
      >
        <i class="custome-font custome-measure-eraser" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</template>
<script>
/**
 * Currently drawn feature.
 * @type {module:ol/Feature~Feature}
 */
var sketch;

/**
 * The help tooltip element.
 * @type {Element}
 */
var helpTooltipElement;

/**
 * Overlay to show the help messages.
 * @type {module:ol/Overlay}
 */
var helpTooltip;

/**
 * The measure tooltip element.
 * @type {Element}
 */
var measureTooltipElement;

/**
 * Overlay to show the measurement.
 * @type {module:ol/Overlay}
 */
var measureTooltip;
/**
 * Message to show when the user is drawing a polygon.
 * @type {string}
 */
var continuePolygonMsg = "Nhấn vào đây để tiếp tục vẽ đa giác"; //"Click to continue drawing the polygon";

/**
 * Message to show when the user is drawing a line.
 * @type {string}
 */
var continueLineMsg = "Nhấn vào đây để tiếp tục vẽ đường"; //"Click to continue drawing the line";

/**
 * Handle pointer move.
 * @param {module:ol/MapBrowserEvent~MapBrowserEvent} evt The event.
 */

/**
 * Format length output.
 * @param {module:ol/geom/LineString~LineString} line The line.
 * @return {string} The formatted length.
 */
var formatLength = function (line) {
  var length = getLength(line);
  var output;
  if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + " " + "km";
  } else {
    output = Math.round(length * 100) / 100 + " " + "m";
  }
  return output;
};

/**
 * Format area output.
 * @param {module:ol/geom/Polygon~Polygon} polygon The polygon.
 * @return {string} Formatted area.
 */
var formatArea = function (polygon) {
  var area = getArea(polygon);
  var output;
  if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + " " + "km<sup>2</sup>";
  } else {
    output = Math.round(area * 100) / 100 + " " + "m<sup>2</sup>";
  }
  return output;
};

import Draw, { createRegularPolygon, createBox } from "ol/interaction/Draw.js";
import { Vector as VectorSource } from "ol/source.js";
import { unByKey } from "ol/Observable.js";
import Overlay from "ol/Overlay.js";
import { getArea, getLength } from "ol/sphere.js";
import { LineString, Polygon } from "ol/geom.js";
import { toLonLat, transform } from "ol/proj.js";
export default {
  props: {
    map: {
      required: true,
    },
  },
  components: {},
  data() {
    return {
      selected: "",
      unitSelected: "",
      draw: Object,
      source: Object,
      features: [],
      drawTool: {
        active: false,
        name: "",
      },
      units: [],
    };
  },
  created() {},
  watch: {
    map(newVal, oldVal) {
      if (
        Object.keys(oldVal).length === 0 &&
        newVal &&
        Object.keys(newVal).length > 0
      ) {
        var vm = this;
        vm.createHelpTooltip();
        vm.createMeasureTooltip();
        vm.map.on("pointermove", vm.pointerMoveHandler);
        vm.map.getViewport().addEventListener("mouseout", function () {
          helpTooltipElement.classList.add("hidden");
        });
      }
    },
  },
  mounted() {},
  methods: {
    generateUnits: function () {
      this.units = [];
      if (this.selected === "DISTANCE") {
        this.units.push({
          text: "Kilômét",
          value: "km",
        });
      } else if (this.selected === "AREA") {
        this.units.push({
          text: "Kilômét vuông",
          value: "km²",
        });
      }
      if (this.units.length > 0) this.unitSelected = this.units[0].value;
      else this.unitSelected = "";
    },
    measureNone: function () {
      var vm = this;
      this.selected = "";
      this.map.removeInteraction(this.draw);

      this.drawTool.active = false;
      if (helpTooltipElement) helpTooltipElement.classList.add("hidden");
      vm.map.removeOverlay(helpTooltip);
      vm.createHelpTooltip();
    },
    measureDistance: function () {
      if (this.selected === "DISTANCE") {
        this.selected = "";
        this.drawTtoolClick("None");
      } else {
        this.selected = "DISTANCE";
        this.drawTtoolClick("LineString");
      }
      this.generateUnits();
    },
    measureArea: function () {
      if (this.selected === "AREA") {
        this.selected = "";
        this.drawTtoolClick("None");
      } else {
        this.selected = "AREA";
        this.drawTtoolClick("Polygon");
      }
      this.generateUnits();
    },
    pointerMoveHandler: function (evt) {
      if (evt.dragging) {
        return;
      }
      /** @type {string} */
      var helpMsg = "Nhấn vào đây để bắt đầu vẽ"; //"Click to start drawing";

      if (sketch) {
        var geom = sketch.getGeometry();
        if (geom instanceof Polygon) {
          helpMsg = continuePolygonMsg;
        } else if (geom instanceof LineString) {
          helpMsg = continueLineMsg;
        }
      }
      if (this.drawTool.active) {
        helpTooltipElement.innerHTML = helpMsg;
        helpTooltip.setPosition(evt.coordinate);
        helpTooltipElement.classList.remove("hidden");
      }
    },
    /**
     * Creates a new help tooltip
     */
    createHelpTooltip: function () {
      if (helpTooltipElement) {
        if (helpTooltipElement.parentNode)
          helpTooltipElement.parentNode.removeChild(helpTooltipElement);
      }
      helpTooltipElement = document.createElement("div");
      helpTooltipElement.className = "help tooltip hidden";
      helpTooltip = new Overlay({
        element: helpTooltipElement,
        offset: [15, 0],
        positioning: "center-left",
      });
      this.map.addOverlay(helpTooltip);
    },

    /**
     * Creates a new measure tooltip
     */
    createMeasureTooltip: function () {
      if (measureTooltipElement) {
        measureTooltipElement.parentNode.removeChild(measureTooltipElement);
      }
      measureTooltipElement = document.createElement("div");
      measureTooltipElement.className = "tooltip tooltip-measure";
      measureTooltip = new Overlay({
        element: measureTooltipElement,
        offset: [0, -15],
        positioning: "bottom-center",
      });
      this.map.addOverlay(measureTooltip);
    },
    drawTtoolClick: function (toolCode) {
      if (Object.keys(this.draw).length > 0) {
        this.map.removeInteraction(this.draw);
      }
      if (this.map.getInteractions()) {
        for (let i = 0; i < this.map.getInteractions().array_.length; i++) {
          if (
            this.map.getInteractions().array_[i].geometryName_ === "drawGraphic"
          ) {
            this.map.removeInteraction(this.map.getInteractions().array_[i]);
            break;
          }
        }
      }
      this.addInteraction(toolCode);
    },
    addInteraction: function (toolCode) {
      var vm = this;
      if (Object.keys(this.map).length > 0) {
        if (Object.keys(this.source).length === 0) {
          var layers = this.map.getLayers().array_;
          if (layers.length > 0) {
            for (let i = 0; i < layers.length; i++) {
              if (layers[i].get("id") === "layermeasure") {
                this.source = layers[i].values_.source;
                break;
              }
            }
          }
          if (Object.keys(this.source).length === 0)
            this.source = new VectorSource({ wrapX: false });
        }
        this.drawTool.name = toolCode;
        if (toolCode !== "None") {
          this.drawTool.active = true;
          if (helpTooltipElement) helpTooltipElement.classList.remove("hidden");

          var geometryFunction;
          if (toolCode === "Box") {
            toolCode = "Circle";
            geometryFunction = createBox();
          } else geometryFunction = null;
          this.draw = new Draw({
            source: this.source,
            type: toolCode,
            geometryName: "drawMeasure",
            geometryFunction: geometryFunction,
          });

          var listener;

          this.draw.on("drawstart", function (evt) {
            //vm.$store.dispatch("setMeasureDraw", true);
            // set sketch
            sketch = evt.feature;

            var tooltipCoord = evt.coordinate;

            listener = sketch.getGeometry().on("change", function (evt) {
              var geom = evt.target;
              var output;
              if (geom instanceof Polygon) {
                output = formatArea(geom);
                tooltipCoord = geom.getInteriorPoint().getCoordinates();
              } else if (geom instanceof LineString) {
                output = formatLength(geom);
                tooltipCoord = geom.getLastCoordinate();
              }
              measureTooltipElement.innerHTML = output;
              measureTooltip.setPosition(tooltipCoord);
            });
          });
          this.draw.on("drawend", function (event) {
            vm.features.push(event.feature);
            var geometry = event.feature.getGeometry();
            var coordinate = geometry.getCoordinates();
            var pixel = vm.map.getPixelFromCoordinate(coordinate);
            if (["LineString", "Box", "Polygon"].includes(vm.drawTool.name)) {
              measureTooltipElement.className = "help tooltip tooltip-static";
              measureTooltip.setOffset([0, -7]);
              // unset tooltip so that a new one can be created
              measureTooltipElement = null;
              vm.createMeasureTooltip();
              unByKey(listener);
            }
            // unset sketch
            sketch = null;
            // setTimeout(() => {
            //   vm.$store.dispatch("setMeasureDraw", false);
            // }, 500);
          });
          this.map.addInteraction(this.draw);
        } else {
          this.drawTool.active = false;
          if (helpTooltipElement) helpTooltipElement.classList.add("hidden");
        }
      }
    },
    clearAll: function () {
      var vm = this;
      this.selected = "";
      this.drawTool.active = false;
      if (Object.keys(this.source).length > 0) {
        var featuresSource = this.source.getFeatures();
        for (let i = 0; i < featuresSource.length; i++) {
          this.source.removeFeature(featuresSource[i]);
        }
      }

      let elements = document.querySelectorAll(".help.tooltip");
      try {
        for (let i = 0; i < elements.length; i++) {
          elements[i].parentNode.removeChild(elements[i]);
        }
      } catch (e) {}
      this.map.removeInteraction(this.draw);
      this.features = [];
      vm.map.removeOverlay(helpTooltip);
      vm.createHelpTooltip();
    },
  },
};
</script>
<style>
#map__openlayer .tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
  z-index: 2 !important;
}
#map__openlayer .tooltip.hidden {
  display: none;
}
#map__openlayer .tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
#map__openlayer .tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}
#map__openlayer .tooltip-measure:before,
#map__openlayer .tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
#map__openlayer .tooltip-static:before {
  border-top-color: #ffcc33;
}
</style>

<style lang="scss" scoped>
button {
  &.btn-transparent {
    color: transparent;
    color: #3f4254;
    border-color: rgb(169, 169, 169);
  }
  &.active {
    color: white;
    border-color: rgba(25, 171, 231, 1);
    background-color: rgba(25, 171, 231, 0.8);
  }
}
</style>

<style scoped>
.group {
  padding: 0.5rem 1rem;
  background-color: white;
  text-align: center;
  display: inline-block;
  opacity: 0.9;
}
.button-group {
  display: flex;
}
button {
  padding: 0 0.5rem;
  margin: 0 0.25rem;
  border-color: #ced4da;
}
.box-title {
  width: 100%;
  text-align: center;
}
</style>
