<template>
  <div class="my-project">
    <div class="my-project__dialog">
      <DialogMetadata
        v-if="dlgOpticalImgMetadata.show"
        :prop-style="{ width: dlgOpticalImgMetadata.width }"
        :prop-show="dlgOpticalImgMetadata.show"
        :prop-title="dlgOpticalImgMetadata.title"
        :prop-item="dlgOpticalImgMetadata.value"
        @close="
          dlgOpticalImgMetadata.show = false;
          dlgOpticalImgMetadata.value = null;
        "
        @initSuccessPreviewImange="initSuccessPreviewImange"
      />
      <DialogMessage
        v-if="message.length > 0"
        :propTitle="$t('title_message_info')"
        :prop-show="message.length > 0"
        :prop-message="message"
        @close="message = []"
      />
    </div>
    <div class="my-project__search">
      <CommonAccordion :propTitle="$t('title_search')" :isChevronEnd="true">
        <div slot="content">
          <MyProjectFilter
            :prop-search="myProject.search"
            :prop-buttons="buttons"
            @onSearch="onSearch"
          />
        </div>
      </CommonAccordion>
    </div>
    <div class="my-project__datatable">
      <CommonAccordion
        :propTitle="$t('title_project_list')"
        :isChevronEnd="true"
      >
        <div slot="content">
          <MyProjectDataTable
            ref="myProjectDataTable"
            :prop-data="myProject.items"
            :prop-table="myProject.table"
            :prop-loading="myProject.loading"
            :propTotal="myProject.total_records ? myProject.total_records : 0"
            @selectChanged="selected = $event"
            @nextPage="nextPage"
          />
        </div>
      </CommonAccordion>
    </div>
    <div
      class="my-project__files"
      v-if="selected && Object.keys(selected).length > 0"
    >
      <CommonAccordion :propTitle="$t('label_files')">
        <div slot="content" class="">
          <MyProjectFilesDataTable
            ref="filesDatatable"
            :prop-items="selected.attachment_file"
            @downloadFile="downloadFile"
          />
        </div>
      </CommonAccordion>
    </div>
    <div
      class="my-project__member"
      v-if="selected && Object.keys(selected).length > 0"
    >
      <CommonAccordion :propTitle="$t('title_member')" :isChevronEnd="true">
        <div slot="content">
          <MyProjectMemberDataTable :prop-items="selected.member" />
        </div>
      </CommonAccordion>
    </div>
    <div
      class="my-project__images"
      v-if="selected && Object.keys(selected).length > 0"
    >
      <CommonAccordion :propTitle="$t('title_images')" :isChevronEnd="true">
        <div slot="content">
          <MyProjecImagestDataTable
            :prop-items="selected.image"
            @showDlgMetadata="
              dlgOpticalImgMetadata.show = true;
              dlgOpticalImgMetadata.value = $event;
            "
          />
        </div>
      </CommonAccordion>
    </div>
  </div>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion3";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import DialogMetadata from "@/components/commons/metadata/Metadata";
import MyProjectFilter from "./project/Filter.vue";
import MyProjectDataTable from "./project/DataTable.vue";
import MyProjectMemberDataTable from "./project/MembersDatatable.vue";
import MyProjecImagestDataTable from "./project/ImagesDatatable.vue";
import MyProjectFilesDataTable from "./project/FilesDatatable.vue";
import meControllerFunc from "@/utils/functions/mecontroller";
import projectFunc from "@/utils/functions/project";
import cryptoFunc from "@/utils/functions/crypto";
import oauthFunc from "@/utils/functions/oauth";
import { getMimeType } from "@/utils/commons/mimetypes";
export default {
  components: {
    CommonAccordion,
    MyProjectFilter,
    MyProjectDataTable,
    DialogMessage,
    DialogMetadata,
    MyProjectMemberDataTable,
    MyProjecImagestDataTable,
    MyProjectFilesDataTable,
  },
  data() {
    return {
      message: [],
      myProject: {
        items: [],
        loading: false,
        search: {
          name: null,
          page: 0,
          size: 20,
        },
        table: {
          selected: null,
          height: 350,
        },
        is_bottom: false,
      },
      dlgOpticalImgMetadata: {
        show: false,
        title: "Metadata",
        value: null,
        width: "480px",
      },
      selected: null,
      buttons: {
        filter: {
          disabled: false,
          processing: false,
        },
      },
    };
  },
  created() {
    setTimeout(() => {
      this.$emit("initBreadcrumb", ["menu_sidebar_my_project"]);
    }, 200);
    this.myProject.table.height = 10 * 40;
    var vm = this;
    if (vm.documentWidth < 576) {
      this.dlgOpticalImgMetadata.width = this.documentWidth + "px";
    } else if (vm.documentWidth >= 576 && vm.documentWidth < 768) {
      this.dlgOpticalImgMetadata.width = "540px";
    } else if (vm.documentWidth >= 768 && vm.documentWidth < 992) {
      this.dlgOpticalImgMetadata.width = "720px";
    } else {
      this.dlgOpticalImgMetadata.width = "960px";
    }
    this.getMyProject();
  },
  methods: {
    nextPage() {
      if (!this.myProject.is_bottom) {
        this.myProject.search.page = this.myProject.search.page + 1;
        this.getMyProject();
      }
    },
    addImage2ProjectSuccess(data) {
      if (this.selected && Object.keys(this.selected).length > 0) {
        if (data && Object.keys(data).length > 0) {
          if (!this.selected.image) this.selected.image = [];
          this.selected.image.push(data);
        }
      }
    },
    initSuccessPreviewImange(data) {
      let index = this.myProject.items.findIndex((x) => x.id === data.id);
      if (index >= 0) {
        this.myProject.items[index].preview_src = data.val;
      }
    },
    async downloadFile(item) {
      var vm = this;
      if (item && Object.keys(item).length > 0) {
        this.$store.dispatch("setFilesDownload", [
          {
            is_add: true,
            file: {
              name: item.name,
              file_name: item.name,
              id: item.id ? item.id : idTemp,
            },
          },
        ]);
        if (item.file) {
          var reader = new FileReader();
          reader.onload = function (e) {
            let blob = new Blob([new Uint8Array(e.target.result)], {
              type: item.file.type,
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", item.name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          };
          reader.readAsArrayBuffer(item.file);
        } else if (item.src) {
          const link = document.createElement("a");
          link.href = item.src;
          link.setAttribute("download", item.name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          await this.onDownloadFile(item);
        }
        this.$store.dispatch("setFilesDownload", [
          {
            is_add: false,
            file: {
              name: item.name,
              file_name: item.name,
              id: item.id ? item.id : idTemp,
            },
          },
        ]);
      }
    },
    async onDownloadFile(data) {
      try {
        //let url, blob;
        let response = await projectFunc.downloadAttachment(
          this.selected.id,
          data.id,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          data.src = response.data.data ? response.data.data.url : null;
          if (data.src) {
            window.open(data.src, "_blank");
          } else
            this.message.push(
              this.$t("message_download_project_file_error", null, {
                name: data.name,
              })
            );
          /*
          if (!window.navigator.msSaveOrOpenBlob) {
            // BLOB NAVIGATOR
            blob = new Blob([response.data], getMimeType(data.file_type));
            url = window.URL.createObjectURL(blob);
          } else {
            // BLOB FOR EXPLORER 11
            blob = new Blob([response.data], getMimeType(data.file_type));
            url = window.navigator.msSaveOrOpenBlob(blob, data.name);
          }

          data.src = url;
          data.size = blob.length;
          const linkdown = document.createElement("a");
          linkdown.href = url;
          linkdown.setAttribute("download", data.name);
          document.body.appendChild(linkdown);
          linkdown.click();
          document.body.removeChild(linkdown);
          window.URL.revokeObjectURL(url);
          */
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.onDownloadFile, data);
        } else {
          let msg =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msg
              ? msg
              : this.$t("message_download_project_file_error", null, {
                  name: data.name,
                })
          );
        }
      }
    },
    async onSearch(data) {
      this.myProject.search.name = data.name ? data.name : null;
      if (this.$refs.myProjectDataTable)
        this.$refs.myProjectDataTable.clearSelect();
      this.selected = null;
      this.myProject.items = [];
      this.myProject.is_bottom = false;
      this.myProject.search.page = 0;
      this.buttons.filter.disabled = true;
      this.buttons.filter.processing = true;
      await this.getMyProject();
      this.buttons.filter.disabled = false;
      this.buttons.filter.processing = false;
    },
    async getMyProject() {
      if (this.myProject.is_bottom) return;
      this.myProject.loading = true;
      try {
        let response = await meControllerFunc.getMyProject(
          {
            text: this.myProject.search.name,
            page: this.myProject.search.page,
            size: this.myProject.search.size,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.myProject.items = [
            ...this.myProject.items,
            ...response.data.content_page,
          ];
          this.myProject.page_number = response.data.page_number;
          this.myProject.page_size = response.data.page_size;
          this.myProject.total_page = response.data.total_page;
          this.myProject.total_records = response.data.total_records;
          if (
            (this.myProject.items.length === this.myProject.total_records &&
              this.myProject.total_page > 1) ||
            (response.data.total_page === 1 &&
              this.myProject.items.length <= response.data.page_size)
          )
            this.myProject.is_bottom = true;
        }
        this.myProject.loading = false;
      } catch (error) {
        this.myProject.loading = false;
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getmyProject);
        }
      }
    },
    async refreshToken(callBack) {
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.path != "/login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(cryptoFunc.encrypt(this.$route.path)),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.path != "/login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(cryptoFunc.encrypt(this.$route.path)),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    userMe() {
      return this.$store.getters.getUser;
    },
  },
};
</script>
<style lang="scss" scoped>
.my-project {
  padding: 0.5rem;
  .my-project__search {
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .my-project__datatable {
    margin-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .my-project__member {
    margin-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .my-project__images {
    margin-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
}
</style>
