<template>
  <div
    class="basemap-side__gallery animate__animated"
    :class="show ? 'animate__fadeInRight' : 'animate__fadeOutRight'"
  >
    <BaseMapGallery
      :propData="propData"
      @changeOpacity="changeOpacity($event)"
      @showLayer="showLayer($event)"
      @closeBasemapsLayer="closeBasemapsLayer"
    />
  </div>
</template>
<script>
import BaseMapGallery from "./basemap/BaseMapGallery.vue";
export default {
  props: {
    propData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseMapGallery,
  },
  data() {
    return {
      componentName: "BaseMapGallery",
      show: false,
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow(val) {
      this.show = val;
    },
  },
  methods: {
    changeOpacity: function (data) {
      this.$emit("changeOpacity", data);
    },
    showLayer: function (data) {
      this.$emit("showLayer", data);
    },
    closeBasemapsLayer() {
      this.show = false;
      setTimeout(() => {
        this.$emit("closeBasemapsLayer");
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.basemap-side__gallery {
  background: white;
  border: 1px solid #c8c8c8;
}
</style>
