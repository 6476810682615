<template>
  <div class="preview-icon mr-2 border">
    <div
      class="spinner-border spinner-border-sm align-items-center mt-4"
      role="status"
      v-if="loading"
    >
      <span class="sr-only">{{ $t("label_loading") }}</span>
    </div>
    <div v-else>
      <img
        v-if="!fullPath"
        :src="'/img/icons/no-image.png'"
        class="preview-image no-image"
      />
      <img
        :src="fullPath"
        class="preview-image"
        @click.stop="showPreviewImage"
        v-else
        onerror="this.src='/img/icons/no-image.png'"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    propAssets: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      loading: false,
      fullPath: null,
    };
  },
  created() {
    if (this.propAssets && this.propAssets.thumbnail) {
      this.fullPath = this.propAssets.thumbnail?.href || null;
    } else {
      this.fullPath = null;
    }
  },
  mounted() {},
  watch: {},
  methods: {
    showPreviewImage() {
      this.$emit("showPreviewImage");
    },
  },
};
</script>
<style lang="scss" scoped>
.preview-icon {
  text-align: center;
  width: 66px;
  height: 66px;
  background-color: #eceff1;
  border-radius: 0.25rem;
  img {
    object-fit: cover;
    width: 64px;
    height: 64px;
    border-radius: 0.25rem;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
