<template>
  <div class="attempts">
    <div class="left">
      <div class="row filter">
        <p class="m-0">
          <label class="date-field grid grid-cols-1 cursor-pointer">
            <date-picker
              class="order-1 control-date w-100"
              format="DD/MM/YYYY HH:mm"
              :disabled-date="notBeforeToday"
              v-model="propEntity.startDate"
              value-type="timestamp"
              :lang="lang"
              :clearable="false"
              :disabled="
                propAttempts && propAttempts.length && propAttempts[0].loading
              "
            ></date-picker>
            <span>{{ $t("label_date") }}</span>
          </label>
        </p>

        <p
          class="m-0"
          v-if="
            propProductTypeSelected.technicalProduct.target.id.toLowerCase() ===
            'oneday'
          "
        >
          <label class="sort-field">
            <select
              :disabled="
                propAttempts && propAttempts.length && propAttempts[0].loading
              "
              v-model="sort"
              class="order-1 form-control"
            >
              <option value="accessToTarget,up" class="ng-binding">
                UTC access to target (Ascending)
              </option>
              <option value="accessToTarget,down" class="ng-binding">
                UTC access to target (Descending)
              </option>
              <option value="mission,up" class="ng-binding">
                Satellite (Ascending)
              </option>
              <option value="mission,down" class="ng-binding">
                Satellite (Descending)
              </option>
              <option value="orderDeadline,up" class="ng-binding">
                Order deadline (Ascending)
              </option>
              <option value="orderDeadline,down" class="ng-binding">
                Order deadline (Descending)
              </option>
              <option value="viewingAngle,up" class="ng-binding">
                Incidence angle (Ascending)
              </option>
              <option value="viewingAngle,down" class="ng-binding">
                Incidence angle (Descending)
              </option>
            </select>
            <span class="label-sort">{{ $t("label_sort") }}</span>
          </label>
        </p>
      </div>
      <div
        class="loading-description"
        v-if="propAttempts && propAttempts.length && propAttempts[0].loading"
      >
        <p>
          <i class="fas fa-spinner fa-spin"></i>&nbsp;Please wait while loading
          products
        </p>
      </div>
      <div
        class="row options"
        v-if="propAttempts && propAttempts.length && propAttempts[0].progTypes"
      >
        <div v-if="errors && errors.length" class="col-12 p-0 m-0">
          <ul class="tasking-errors-list">
            <li
              v-if="[
                'ERROR_AOI_MAX_HEIGHT',
                'ERR_AOI_MAX_HEIGHT',
                'ERR_AOI_MAX_WIDTH',
                'ERROR_AOI_MAX_WIDTH',

                'ERROR_AOI_MIN_HEIGHT',
                'ERR_AOI_MIN_HEIGHT',
                'ERR_AOI_MIN_WIDTH',
                'ERROR_AOI_MIN_WIDTH',
              ]"
              class="well-area-error"
            >
              <p class="well-error-tasking">
                Some polygons are not eligible for the following reasons. Please
                contact with technical support
              </p>
              <button class="btn btn-modify-polygon" @click="modifyPolygon">
                <i class="fas fa-draw-polygon"></i>&nbsp; Modify polygon
              </button>
            </li>
            <li v-for="error in errors" :key="error.code">
              <div
                v-if="
                  ['ERROR_AOI_MAX_HEIGHT', 'ERR_AOI_MAX_HEIGHT'].includes(
                    error.code
                  )
                "
                class="error-height-max"
              >
                <span
                  ><i class="fas fa-arrows-alt-v"></i>&nbsp;<span
                    >Height</span
                  ></span
                ><br />
                <span class="tasking-error-label"><span>max.</span></span
                >&nbsp;
                <span class="tasking-error-message"
                  >{{ error.message }}&nbsp;km</span
                >
              </div>
              <div
                v-else-if="
                  ['ERR_AOI_MAX_WIDTH', 'ERROR_AOI_MAX_WIDTH'].includes(
                    error.code
                  )
                "
                class="error-width-max"
              >
                <span
                  ><i class="fas fa-arrows-alt-h"></i>&nbsp;<span
                    >Width</span
                  ></span
                ><br />
                <span class="tasking-error-label"><span>max.</span></span
                >&nbsp;
                <span class="tasking-error-message"
                  >{{ error.message }}&nbsp;km</span
                >
              </div>
              <div
                v-else-if="
                  ['ERR_MIN_AOI_AREA', 'ERROR_MIN_AOI_AREA'].includes(
                    error.code
                  )
                "
                class="error-area-min"
              >
                <span
                  ><i class="fas fa-vector-square"></i>&nbsp;<span
                    >Area</span
                  ></span
                ><br />
                <span class="tasking-error-label"><span>min.</span></span
                >&nbsp;
                <span class="tasking-error-message"
                  >{{ error.message }}&nbsp;km</span
                >
              </div>
              <div v-else>
                <span
                  ><i class="fas fa-exclamation-triangle"></i>&nbsp;<span>{{
                    error.code
                  }}</span></span
                ><br />
                <span class="tasking-error-message">{{ error.message }}</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-12 p-0 m-0">
          <OneDay
            v-if="
              propProductTypeSelected.technicalProduct.target.id.toLowerCase() ===
              'oneday'
            "
            :prop-attempts="propAttempts"
            :prop-selected="selected"
            :prop-footprints="footprints"
            :prop-sort="sort"
            @mouseOverItem="mouseOverItem"
            @mouseLeaveItem="mouseLeaveItem"
            @selectAttempt="selectAttempt"
            @addFootprint="addFootprint"
          />
          <OneNow
            v-else-if="
              propProductTypeSelected.technicalProduct.target.id.toLowerCase() ===
              'onenow'
            "
            :prop-attempts="propAttempts"
            :prop-selected="selected"
            :prop-footprints="footprints"
            @mouseOverItem="mouseOverItem"
            @mouseLeaveItem="mouseLeaveItem"
            @selectAttempt="selectAttempt"
            @addFootprint="addFootprint"
          />
        </div>
      </div>
    </div>
    <div class="right">
      <AttemptsMap
        :prop-content-height="propContentHeight"
        @initMapSuccess="initMapSuccess"
      />
    </div>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import AttemptsMap from "./attempts/Map.vue";
import OneDay from "./attempts/OneDay";
import OneNow from "./attempts/OneNow";
import { Style, Fill, Stroke } from "ol/style.js";
import WKT from "ol/format/WKT";
import { getCenter } from "ol/extent";
export default {
  components: {
    DatePicker,
    OneDay,
    OneNow,
    AttemptsMap,
  },
  props: {
    propContentHeight: {
      type: Number,
    },
    propFeature: {
      type: Object,
      default: () => {},
    },
    propAttempts: {
      type: Array,
      default: () => [],
    },
    propProductTypeSelected: {
      type: Object,
      default: () => {},
    },
    propEntity: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sort: "accessToTarget,up",
      map: Object,
      layer: Object,
      footprints: [],
      selected: [], //For Cart
      styles: {
        footprint: new Style({
          fill: new Fill({
            color: "rgba(255,191,35,0.3)",
          }),
          stroke: new Stroke({
            color: "#FFBF23",
            width: 1.25,
          }),
        }),
        footprintHover: new Style({
          fill: new Fill({
            color: "transparent",
          }),
          stroke: new Stroke({
            color: "rgba(0,158,247,1)",
            width: 1.25,
          }),
        }),
        image: new Style({
          fill: new Fill({
            color: "rgba(0,0,0,0)",
          }),
          stroke: new Stroke({
            color: "rgba(0,0,0,0.8)",
            width: 1.25,
          }),
        }),
      },
      array4Sort: [
        {
          id: "accessToTarget,up",
          label: "UTC access to target (Ascending)",
        },
        {
          id: "accessToTarget,down",
          label: "UTC access to target (Descending)",
        },
        {
          id: "mission,up",
          label: "Satellite (Ascending)",
        },
        {
          id: "mission,down",
          label: "Satellite (Descending)",
        },
        {
          id: "orderDeadline,up",
          label: "Order deadline (Ascending)",
        },

        {
          id: "orderDeadline,down",
          label: "Order deadline (Descending)",
        },
        {
          id: "viewingAngle,up",
          label: "Incidence angle (Ascending)",
        },
        {
          id: "viewingAngle,down",
          label: "Incidence angle (Descending)",
        },
      ],
    };
  },
  created() {
    this.selected = Object.assign(
      [],
      this.propEntity.attempts ? this.propEntity.attempts : []
    );
  },
  watch: {
    "propEntity.startDate"(newval, oldVal) {
      if (newval && oldVal) {
        this.selected = [];
        this.$emit("getAttempts", new Date(this.propEntity.startDate));
      }
    },
    sort(val) {},
  },
  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    modifyPolygon() {
      this.$emit("modifyPolygon");
    },
    selectAttempt(data) {
      let index = this.selected.findIndex((x) => x.id === data.segment.id);
      if (index >= 0) {
        this.selected.splice(index, 1);
      } else {
        this.addFootprint(data.segment, false);
        this.selected.push(data.segment);
      }
      this.$emit("selectedAttempt", {
        selected: this.selected,
        groups: data.items,
      });
    },
    initMapSuccess(data) {
      this.map = data.map;
      this.layer = data.layer;
      if (this.propFeature && Object.keys(this.propFeature).length) {
        this.layer.getSource().addFeature(this.propFeature);

        var ext = this.propFeature.getGeometry().getExtent();
        this.map.getView().fit(ext, {
          size: this.map.getSize(),
        });
        var center = getCenter(ext);
        this.map.getView().animate({
          center: [center[0], center[1]],
          duration: 100,
          zoom:
            this.map.getView().getZoom() < 2
              ? 2
              : this.map.getView().getZoom() - 0.5,
        });
      }
    },
    addFootprint(data, isOverwrite = true) {
      let feature = this.layer
        .getSource()
        .getFeatureById("footprint_" + data.id);
      if (feature && isOverwrite) {
        this.layer.getSource().removeFeature(feature);
        let index = this.footprints.findIndex((x) => x === data.id);
        if (index >= 0) this.footprints.splice(index, 1);
      } else {
        this.footprints.push(data.id);
        this.addFeature(
          this.layer,
          data.footprint.geometry,
          this.styles.footprint,
          "footprint_" + data.id
        );
      }
    },
    async mouseOverItem(data) {
      if (!this.layer || Object.keys(this.layer).length === 0) return;
      try {
        let feature = this.layer.getSource().getFeatureById("hover_" + data.id);
        if (!feature) {
          this.addFeature(
            this.layer,
            data.footprint.geometry,
            this.styles.footprintHover,
            "hover_" + data.id
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async mouseLeaveItem(data) {
      if (!this.layer || Object.keys(this.layer).length === 0) return;
      try {
        let feature = this.layer.getSource().getFeatureById("hover_" + data.id);
        if (feature) {
          this.layer.getSource().removeFeature(feature);
        }
      } catch (error) {}
    },
    addFeature(layer, wkt, style, id) {
      let feature = layer.getSource().getFeatureById(id);
      if (!feature) {
        const format = new WKT();
        const feature = format.readFeature(wkt, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:3857",
        });
        try {
          feature.set("id", id);
          feature.id_ = id;
          feature.setStyle(style);
          layer.getSource().addFeature(feature);
          let extenFeature = feature.getGeometry().getExtent();
          extenFeature[0] = extenFeature[0] - 1000;
          extenFeature[1] = extenFeature[1] - 1000;
          extenFeature[2] = extenFeature[2] + 1000;
          extenFeature[3] = extenFeature[3] + 1000;
          this.map.getView().fit(extenFeature, this.map.getSize());
        } catch (error) {
          console.log(error);
        }
      } else {
        feature.style = { visibility: "visible" };
      }
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    errors() {
      if (this.propAttempts && this.propAttempts.length > 0) {
        if (
          this.propAttempts[0] &&
          this.propAttempts[0].progTypes &&
          this.propAttempts[0].progTypes.length > 0
        ) {
          return this.propAttempts[0].progTypes[0].errors
            ? this.propAttempts[0].progTypes[0].errors
            : [];
        }
      }
      return [];
    },
  },
};
</script>
<style lang="scss" scoped>
.attempts {
  padding: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: [first] 30rem [line2] auto [end];
  .left {
    .row {
      margin: 0;
    }
    .filter {
      background-color: #eef3f7;
      border-radius: 0.25rem;
      border: 1px solid rgba(0, 0, 0, 0.075);
      display: grid;
      gap: 1rem;
      grid-template-columns: 2fr 3fr;
      padding: 0.5rem 1rem;
      .date-field {
        display: grid;
        grid-auto-columns: repeat(1, 1fr);
        cursor: pointer;
        .control-date {
          max-width: 11rem;
        }
        .order-1 {
          order: 1;
        }
        .label-date {
          order: none;
          margin: 0;
        }
      }
      .sort-field {
        display: grid;
        grid-auto-columns: repeat(1, 1fr);
        cursor: pointer;
        select {
          font-size: 13px !important;
        }
        .order-1 {
          order: 1;
        }
        .label-sort {
          order: none;
          margin: 0;
        }
      }
    }
    .options {
      gap: 1rem;
      margin-top: 0.75rem;
      ul.tasking-errors-list {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          border: 1px solid rgba(220, 53, 69, 0.25);
          padding: 0.5rem 0.75rem;
          border-radius: 0.25rem;
          margin-top: 0.5rem;
          &:first-child {
            margin-top: 0;
          }
          &.well-area-error {
            background-color: rgba(220, 53, 69, 0.025);
            text-align: center;
            .btn-modify-polygon {
              font-size: 12px;
              background: #fd7e14;
              color: white;
              padding: 0.25 0.5rem;
              margin: 0 auto;
              margin: 1rem 0 0.5rem 0;
            }
          }
          .tasking-error-message {
            font-size: 18px;
            color: rgb(220, 53, 69);
          }
          p.well-error-tasking {
            font-family: "Helvetica Neue Light";
            font-style: italic;
            font-size: 100%;
            vertical-align: baseline;
            margin: 0;
          }
        }
      }
    }
    .loading-description {
      margin-top: 1rem;
      padding: 1rem;
      background-color: #eef3f7;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.25rem;
      width: 100%;
      p {
        margin-bottom: 0;
      }
    }
  }
}
</style>
<style lang="scss">
.attempts {
  .left {
    .date-field {
      .mx-input {
        color: #333;
        border: 1px solid #ced4da !important;
        &:disabled {
          background-color: #e9ecef !important;
        }
      }
    }
    .sort-field {
      color: #333;
    }
  }
}
</style>
