<template>
  <div class="statistic-upload">
    <div class="statistic-upload__dialog">
      <DialogDetail
        v-if="dialog.detail.show"
        :prop-style="{ width: dialog.detail.width }"
        :prop-show="dialog.detail.show"
        :prop-title="dialog.detail.title"
        :prop-items="dialog.detail.items"
        @close="
          dialog.detail.show = false;
          dialog.detail.items = [];
        "
      />
    </div>
    <div class="statistic-upload__datatable">
      <DownloadDataTable
        :prop-items="statisticUpload.items"
        @shoDialogDetail="shoDialogDetail"
      />
    </div>
    <div class="statistic-upload__chart">
      <DownloadChart :prop-data="statisticUpload.items" />
    </div>
  </div>
</template>
<script>
import DownloadFilter from "./statisticupload/Filter.vue";
import DownloadDataTable from "./statisticupload/DataTable";
import DownloadChart from "./statisticupload/DataChart";
import DialogDetail from "./statisticupload/dialog/DialogDetail.vue";
import statisticFunc from "@/utils/functions/statistic";
import metaKeyFunc from "@/utils/functions/metakey";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import imageFunc from "@/utils/functions/image";
import meControllerFunc from "@/utils/functions/mecontroller";
export default {
  components: {
    DownloadFilter,
    DownloadDataTable,
    DownloadChart,
    DialogDetail,
  },
  props: {
    propStatisticUpload: {
      type: Array,
      default: () => [],
    },
    propProvider: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      statisticUpload: {
        items: [],
        search: {
          from: null,
          to: null,
          page: 0,
          size: 2147483647,
        },
        table: {
          selected: null,
        },
        is_bottom: false,
      },
      dialog: {
        detail: {
          show: false,
          title: "Chi tiết",
          items: [],
          width: "480px",
        },
      },
    };
  },
  async created() {
    var vm = this;
    if (vm.documentWidth < 576) {
      this.dialog.detail.width = this.documentWidth + "px";
    } else if (vm.documentWidth >= 576 && vm.documentWidth < 768) {
      this.dialog.detail.width = "540px";
    } else if (vm.documentWidth >= 768 && vm.documentWidth < 992) {
      this.dialog.detail.width = "720px";
    } else {
      this.dialog.detail.width = "720px";
    }
  },
  watch: {
    propStatisticUpload(val) {
      this.statisticUpload.items = [];
      if (val && Object.keys(val).length > 0) this.initData(val);
    },
  },
  methods: {
    menuHeaderClick(val) {
      switch (val.code) {
        case "RELOAD":
          break;
      }
    },
    async shoDialogDetail(item) {
      if (item.items && item.items.length > 0) {
        this.dialog.detail.items = item.items;
        this.dialog.detail.show = true;
      } else {
        try {
          let response = await imageFunc.search(
            {
              page: 0,
              size: 2147483647,
            },
            {
              create_time_from: this.statisticUpload.search.from,
              create_time_to: this.statisticUpload.search.to,
              provider_ids: [item.provider_id],
              satellite_ids: [item.satellite_id],
            },
            this.$store.getters.getAccessToken
          );
          if (response && response.status === 200) {
            let index = this.statisticUpload.items.findIndex(
              (x) => x.satellite_id === item.satellite_id
            );
            if (index >= 0)
              this.statisticUpload.items[index].items =
                response.data.content_page;
            this.dialog.detail.items = response.data.content_page;
            this.dialog.detail.show = true;
          }
        } catch (error) {
          if (error.response && error.response.status === 401)
            this.refreshToken(this.shoDialogDetail, item);
        }
      }
    },
    /*---------------------- Get data ---------------------------*/

    initData(data) {
      if (data && data.length > 0) {
        if (this.propProvider && this.propProvider.length > 0) {
          for (let i = 0; i < this.propProvider.length; i++) {
            if (
              this.propProvider[i].sub_meta_key &&
              this.propProvider[i].sub_meta_key.length > 0
            ) {
              for (
                let j = 0;
                j < this.propProvider[i].sub_meta_key.length;
                j++
              ) {
                let index = data.findIndex(
                  (x) =>
                    x.satellite &&
                    x.satellite.name ===
                      this.propProvider[i].sub_meta_key[j].name
                );
                if (index >= 0) {
                  this.statisticUpload.items.push({
                    provider: this.propProvider[i].value,
                    satellite: this.propProvider[i].sub_meta_key[j].value,
                    total: data[index].total,
                    satellite_id: this.propProvider[i].sub_meta_key[j].id,
                    satellite_name: this.propProvider[i].sub_meta_key[j].name,
                    provider_id: this.propProvider[i].id,
                    provider__name: this.propProvider[i].name,
                    items: [],
                  });
                }
              }
            }
          }
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
    /*---------------------- End get data -------------------------*/
  },
};
</script>
<style lang="scss" scoped>
.statistic-upload__search {
  padding: 0.25rem;
  width: 100%;
}
.statistic-upload__datatable {
  padding: 0.25rem;
  width: 100%;
}

.statistic-upload__chart {
  padding: 0.25rem;
  width: 100%;
}
</style>