<template>
  <table>
    <tbody>
      <tr>
        <td>Coordinate system:</td>
        <td class="plaintext">{{ propCoordinateSystem }}</td>
      </tr>
      <tr
        v-if="this.propEntity.feasibilityOneSeries.temporalMode === 'REGULAR'"
      >
        <td>Dates:</td>
        <td class="plaintext">
          {{ strDates }}
        </td>
      </tr>
      <tr
        v-if="
          this.propEntity.feasibilityOneSeries.temporalMode === 'MULTIPERIOD'
        "
      >
        <td>Periods:</td>
        <td class="plaintext">
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="(period, index) in periods"
              :key="'period' + index"
            >
              {{ period }}
            </li>
          </ul>
        </td>
      </tr>
      <tr v-if="numberOfObservations">
        <td>Number of observations:</td>
        <td class="plaintext">{{ numberOfObservations }}</td>
      </tr>
      <tr v-if="periodOfTime">
        <td>Period of time:</td>
        <td class="plaintext">{{ periodOfTime }}</td>
      </tr>
      <tr>
        <td>Max incidence angle:</td>
        <td class="plaintext">{{ maxIncidenceAngle }}&deg;</td>
      </tr>
      <tr>
        <td>Max cloud cover:</td>
        <td class="plaintext">{{ maxCloudCover }}%</td>
      </tr>
      <tr v-if="notificationThreshold">
        <td>Notification threshold:</td>
        <td class="plaintext">{{ notificationThreshold }}</td>
      </tr>
      <tr v-if="acquisitionMode">
        <td>Acquisition Mode:</td>
        <td class="plaintext">{{ acquisitionMode }}</td>
      </tr>
      <tr v-if="minBH">
        <td>Min B/H:</td>
        <td class="plaintext">{{ minBH }}</td>
      </tr>
      <tr v-if="maxBH">
        <td>Max B/H:</td>
        <td class="plaintext">{{ maxBH }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import moment from "moment";
export default {
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },
    propCoordinateSystem: {
      type: String,
      default: "",
    },
  },
  created() {
    console.log(this.propEntity.feasibilityOneSeries.dateRange);
  },
  methods: {
    formatUTCOrderDeadline(val) {
      try {
        moment.locale(this.lang);
        let date = new Date(val);
        return moment(date).utc().format("lll");
      } catch (error) {}
      return null;
    },
    formatUTCAcquisitionStartDate(val) {
      try {
        moment.locale(this.lang);
        let date = new Date(val);
        return moment(date).utc().format("lll");
      } catch (error) {}
      return null;
    },
  },
  computed: {
    strStartDate() {
      try {
        if (
          this.propEntity.feasibilityOneSeries.startDate &&
          this.propEntity.feasibilityOneSeries.temporalMode === "REGULAR"
        ) {
          //moment.locale(this.lang);
          //let date = new Date(this.propEntity.feasibilityOneSeries.startDate);
          //return moment(date).utc().format("ll");
          let start = new Date(this.propEntity.feasibilityOneSeries.startDate);
          let startD = start.getDate();
          if (startD < 10) startD = "0" + startD;
          let startMonth = start.getMonth();
          if (startMonth < 10) startMonth = "0" + startMonth;
          return (
            start.getFullYear() + "-" + startMonth + "-" + startD + "T00:00:00"
          );
        }
      } catch (error) {}
      return null;
    },
    strEndDate() {
      try {
        if (
          this.propEntity.feasibilityOneSeries.endDate &&
          this.propEntity.feasibilityOneSeries.temporalMode === "REGULAR"
        ) {
          // moment.locale(this.lang);
          // let date = new Date(this.propEntity.feasibilityOneSeries.endDate);
          // return moment(date).utc().format("ll");
          let end = new Date(this.propEntity.feasibilityOneSeries.endDate);
          let endD = end.getDate();
          if (endD < 10) endD = "0" + endD;
          let endMonth = end.getMonth();
          if (endMonth < 10) endMonth = "0" + endMonth;
          return end.getFullYear() + "-" + endMonth + "-" + endD + "T23:59:59";
        }
      } catch (error) {}
      return null;
    },
    strDates() {
      return (
        (this.strStartDate ? this.strStartDate : "_") +
        " " +
        this.$t("label_to").toLowerCase() +
        " " +
        (this.strEndDate ? this.strEndDate : "_")
      );
    },
    periods() {
      let results = [];

      if (this.propEntity.feasibilityOneSeries.temporalMode === "MULTIPERIOD") {
        for (
          let i = 0;
          i < this.propEntity.feasibilityOneSeries.dateRange.length;
          i++
        ) {
          let start, end;
          try {
            start = new Date(
              this.propEntity.feasibilityOneSeries.dateRange[i][0]
            );
          } catch (error) {}
          try {
            end = new Date(
              this.propEntity.feasibilityOneSeries.dateRange[i][1]
            );
          } catch (error) {}
          if (start && end) {
            let startD = start.getDate();
            if (startD < 10) startD = "0" + startD;
            let startMonth = start.getMonth() + 1;
            if (startMonth < 10) startMonth = "0" + startMonth;

            let endD = end.getDate();
            if (endD < 10) endD = "0" + endD;
            let endMonth = end.getMonth() + 1;
            if (endMonth < 10) endMonth = "0" + endMonth;
            results.push(
              this.$t("label_from") +
                " " +
                start.getFullYear() +
                "-" +
                startMonth +
                "-" +
                startD +
                "T00:00:00 " +
                this.$t("label_to").toLowerCase() +
                " " +
                end.getFullYear() +
                "-" +
                endMonth +
                "-" +
                endD +
                "T23:59:59"
            );
          }
        }
      }
      return results;
    },
    maxIncidenceAngle() {
      return this.propEntity.feasibilityOneSeries.maxIncidenceAngle;
    },
    maxCloudCover() {
      return this.propEntity.feasibilityOneSeries.maxCloudCover;
    },
    notificationThreshold() {
      return this.propEntity.feasibilityOneSeries.notificationThreshold;
    },
    numberOfObservations() {
      if (this.propEntity.feasibilityOneSeries.temporalMode === "REGULAR")
        return this.propEntity.feasibilityOneSeries.numberOfObservations;
      else return null;
    },
    periodOfTime() {
      if (this.propEntity.feasibilityOneSeries.temporalMode === "REGULAR")
        return this.propEntity.feasibilityOneSeries.periodOfTime;
      else return null;
    },
    acquisitionMode() {
      return this.propEntity.feasibilityOneSeries.acquisitionMode;
    },
    minBH() {
      if (["STEREO", "TRISTEREO"].includes(this.acquisitionMode)) {
        return this.propEntity.feasibilityOneSeries.minBH;
      }
      return null;
    },
    maxBH() {
      if (["STEREO", "TRISTEREO"].includes(this.acquisitionMode)) {
        return this.propEntity.feasibilityOneSeries.maxBH;
      }
      return null;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.plaintext {
  font-family: "Helvetica Neue Light";
}

table {
  width: 100%;
  background-color: white;
  border-radius: 0.25rem;
  padding: 0.5rem;
  tbody {
    tr {
      td {
        padding: 0.25rem 0.75rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.075);
        &:first-child {
          text-align: right;
          width: 11rem;
        }
        &:last-child {
          padding-left: 0;
        }
        &.plaintext {
          font-family: "Helvetica Neue Light";
        }
        .list-group {
          padding: 0;
          margin: 0;
          list-style: none;
          .list-group-item {
            border: 0;
            padding: 0.125rem 0;
          }
        }
      }
    }
    &.group {
      tr {
        td {
          padding: 0.25rem 0.5rem;
        }
        &:first-child {
          td {
            padding: 0.5rem;
            background-color: #eef3f7;
            border-top: 1px solid rgba(0, 0, 0, 0.075);
            border-bottom: 1px solid rgba(0, 0, 0, 0.075);
          }
        }
      }
    }
  }
}
</style>
