<template>
  <div class="feasibility">
    <div class="left">
      <h1 class="tasking-name">
        {{
          propProductTypeSelected &&
          Object.keys(propProductTypeSelected).length > 0
            ? propProductTypeSelected.technicalProduct.sensor.label +
              " " +
              propProductTypeSelected.technicalProduct.target.label
            : ""
        }}
      </h1>
      <div
        :style="{
          height: propContentHeight - 112 + 'px',
          overflowY: 'auto',
          padding: '3px',
        }"
      >
        <p class="m-0">
          <label class="control-field grid grid-cols-1 cursor-pointer">
            <select
              v-model="propFeasibility.sensor"
              disabled
              class="form-control new-style order-1 w-100"
            >
              <option
                v-for="s in propSensors.filter((x) =>
                  this.propFeasibility.sensor
                    ? x.id === this.propFeasibility.sensor
                    : '1==1'
                )"
                :key="s.id"
                :value="s.id"
              >
                {{ s.label }}
              </option>
            </select>
            <span>Sensor</span>
          </label>
        </p>

        <p class="m-0">
          <label class="control-field grid grid-cols-1 cursor-pointer">
            <select
              v-model="propFeasibility.temporalMode"
              class="form-control order-1 w-100"
            >
              <option value="MULTIPERIOD">MULTIPERIOD</option>
              <option value="REGULAR">REGULAR</option>
            </select>
            <span>Acquisition Mode<span class="required">&nbsp;*</span></span>
          </label>
        </p>
        <p class="m-0" v-if="propFeasibility.temporalMode === 'MULTIPERIOD'">
          <label class="control-field grid grid-cols-1 cursor-pointer">
            <date-picker
              class="control-date order-1 w-100"
              format="DD/MM/YYYY"
              :disabled-date="notBeforeStartDate"
              v-model="dateRange"
              value-type="timestamp"
              :lang="lang"
              :clearable="false"
              :show-time-header="true"
              input-class=" form-control new-style"
              range
              :confirm="true"
              :confirm-text="'Apply'"
              :open="datepickerOpen"
              @focus="datepickerOpen = true"
              @confirm="confirmDate"
              @close="datepickerOpen = false"
            ></date-picker>
            <span>Date range(UTC)<span class="required">&nbsp;*</span></span>
          </label>
        </p>
        <fieldset
          class="p0 m-0 mb-2 border-none"
          v-if="propFeasibility.temporalMode === 'MULTIPERIOD'"
        >
          <timeline
            v-if="loading"
            ref="timeline"
            :items="items"
            :groups="groups"
            :options="options"
            @click="clickTimeLine"
            @select="selectTimeLine"
          ></timeline>
        </fieldset>
        <p
          class="d-flex flex-wrap gap-2 m-0"
          v-if="propFeasibility.temporalMode === 'MULTIPERIOD'"
        >
          <span
            v-for="(label, index) in dateRangeLabels"
            :key="'label' + index"
            class="range-label"
            ><a @click="selectRange(index)">
              Period {{ index + 1 }} {{ label }}</a
            >
            <span class="remove-label" @click="removeRange(index)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </span>
        </p>
        <fieldset
          class="grid grid-cols-2 gap-4 border-0 mb-2"
          v-else-if="propFeasibility.temporalMode === 'REGULAR'"
        >
          <p class="m-0">
            <label class="control-field grid grid-cols-1 cursor-pointer">
              <date-picker
                class="control-date order-1 w-100"
                format="DD/MM/YYYY"
                :disabled-date="notBeforeToday"
                v-model="propFeasibility.startDate"
                value-type="timestamp"
                :lang="lang"
                :clearable="false"
                input-class=" form-control new-style"
              ></date-picker>
              <span>Start Date(UTC)<span class="required">&nbsp;*</span></span>
            </label>
          </p>
          <p class="m-0">
            <label class="control-field grid grid-cols-1 cursor-pointer">
              <date-picker
                class="control-date order-1 w-100"
                format="DD/MM/YYYY"
                :disabled-date="notBeforeStartDate"
                v-model="propFeasibility.endDate"
                value-type="timestamp"
                :lang="lang"
                :clearable="false"
                input-class=" form-control new-style"
              ></date-picker>
              <span>End date(UTC)<span class="required">&nbsp;*</span></span>
            </label>
          </p>
        </fieldset>
        <p class="m-0">
          <label class="control-field grid grid-cols-1 cursor-pointer">
            <select
              v-model="propFeasibility.maxIncidenceAngle"
              class="form-control new-style order-1 w-100"
              :class="
                propFeasibility.maxIncidenceAngle > 20 ? 'border-warning' : ''
              "
            >
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
            <span
              :class="
                propFeasibility.maxIncidenceAngle > 20 ? 'text-warning' : ''
              "
              >Max incidence angle<span class="required">&nbsp;*</span></span
            >
            <span
              class="span-warning text-warning order-1"
              v-if="propFeasibility.maxIncidenceAngle > 20"
              >Be aware that geometric quality can be deteriorated if you
              associate an ortho processing level with an incidence angle >
              20.0</span
            >
          </label>
        </p>

        <fieldset class="fields-group grid-cols-2 gap-4 border-0 mb-2">
          <p class="m-0">
            <label class="control-field grid grid-cols-1 cursor-pointer">
              <select
                v-model="propFeasibility.maxCloudCover"
                class="form-control order-1 w-100"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
              </select>
              <span>Max cloud cover<span class="required">&nbsp;*</span></span>
            </label>
          </p>
          <p class="m-0">
            <label class="control-field grid grid-cols-1 cursor-pointer">
              <input
                type="number"
                step="1"
                :min="propFeasibility.maxCloudCover"
                max="100"
                class="form-control order-1"
                :class="messageNotificationThreshold ? 'border-danger' : ''"
                v-model="propFeasibility.notificationThreshold"
              />
              <span :class="messageNotificationThreshold ? 'text-danger' : ''"
                >Acquisition notification threshold<span class="required"
                  >&nbsp;*</span
                ></span
              >
              <span
                class="span-danger text-danger order-1"
                v-if="messageNotificationThreshold"
                >{{ messageNotificationThreshold }}</span
              >
            </label>
          </p>
        </fieldset>

        <fieldset
          class="fields-group grid-cols-2 gap-4 border-0 mb-2"
          v-if="
            propFeasibility.acquisitionMode === 'STEREO' ||
            propFeasibility.acquisitionMode === 'TRISTEREO'
          "
        >
          <p class="m-0">
            <label class="control-field grid grid-cols-1 cursor-pointer">
              <select
                v-model="propFeasibility.acquisitionMode"
                class="form-control order-1 w-100"
              >
                <option value="MONO">MONO</option>
                <option value="STEREO">STEREO</option>
                <option value="TRISTEREO">TRISTEREO</option>
              </select>
              <span>Acquisition Mode<span class="required">&nbsp;*</span></span>
            </label>
          </p>
          <p class="m-0">
            <label class="control-field grid grid-cols-1 cursor-pointer">
              <input
                type="number"
                step="0.01"
                min="0.2"
                max="0.8"
                class="form-control order-1"
                :class="messageMinBH ? 'border-danger' : ''"
                v-model="propFeasibility.minBH"
              />
              <span :class="messageMinBH ? 'text-danger' : ''"
                >Min B/H<span class="required">&nbsp;*</span></span
              >
              <span
                class="span-danger text-danger order-1"
                v-if="messageMinBH"
                >{{ messageMinBH }}</span
              >
            </label>
          </p>
          <p class="m-0">
            <label class="control-field grid grid-cols-1 cursor-pointer">
              <input
                type="number"
                step="0.01"
                min="0.2"
                max="0.8"
                class="form-control order-1"
                :class="messageMaxBH ? 'border-danger' : ''"
                v-model="propFeasibility.maxBH"
              />
              <span :class="messageMaxBH ? 'text-danger' : ''"
                >Max B/H<span class="required">&nbsp;*</span></span
              >
              <span
                class="span-danger text-danger order-1"
                v-if="messageMaxBH"
                >{{ messageMaxBH }}</span
              >
            </label>
          </p>
        </fieldset>
        <p class="m-0" v-if="propFeasibility.acquisitionMode === 'MONO'">
          <label class="control-field grid grid-cols-1 cursor-pointer">
            <select
              class="form-control order-1 w-100"
              v-model="propFeasibility.acquisitionMode"
            >
              <option value="MONO">MONO</option>
              <option value="STEREO">STEREO</option>
              <option value="TRISTEREO">TRISTEREO</option>
            </select>
            <span>Acquisition Mode<span class="required">&nbsp;*</span></span>
          </label>
        </p>
        <p class="m-0 mb-2">
          <label class="control-field grid grid-cols-1 cursor-pointer">
            <Textarea
              class="form-control new-style order-1"
              rows="5"
              maxlength="512"
              autofocus
              :autoResize="true"
              v-model="propFeasibility.comments"
            />
            <span>Comments</span>
          </label>
        </p>
      </div>
    </div>
    <div class="right">
      <FeasibilityMap
        :prop-content-height="propContentHeight"
        @initMapSuccess="initMapSuccess"
      />
    </div>
  </div>
</template>
<script>
import { Timeline } from "vue2vis";
import "vue2vis/dist/vue2vis.css";
import DatePicker from "vue2-datepicker";
import FeasibilityMap from "./feasibility/Map.vue";
import Textarea from "@/components/commons/textarea/Textarea";
import RadioButton from "@/components/commons/radiobutton/RadioButton";
import { Style, Fill, Stroke } from "ol/style.js";
import WKT from "ol/format/WKT";
import { getCenter } from "ol/extent";
import moment from "moment";
export default {
  components: { DatePicker, FeasibilityMap, Textarea, RadioButton, Timeline },
  props: {
    propContentHeight: {
      type: Number,
    },
    propFeature: {
      type: Object,
      default: () => {},
    },
    propProductTypeSelected: {
      type: Object,
      default: () => {},
    },
    propSensors: {
      type: Array,
      default: () => [],
    },
    propFeasibility: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      datepickerOpen: false,
      map: Object,
      layer: Object,
      selectedRangeIndex: -1,
      dateRange: null,
      minDate: null,
      maxDate: null,
      styles: {
        footprint: new Style({
          fill: new Fill({
            color: "rgba(255,191,35,0.3)",
          }),
          stroke: new Stroke({
            color: "#FFBF23",
            width: 1.25,
          }),
        }),
        footprintHover: new Style({
          fill: new Fill({
            color: "transparent",
          }),
          stroke: new Stroke({
            color: "rgba(0,158,247,1)",
            width: 1.25,
          }),
        }),
        image: new Style({
          fill: new Fill({
            color: "rgba(0,0,0,0)",
          }),
          stroke: new Stroke({
            color: "rgba(0,0,0,0.8)",
            width: 1.25,
          }),
        }),
      },
      groups: [
        {
          id: 0,
          content: "Range",
          style: "display:none",
        },
      ],
      items: [],
      options: {},
      loading: false,
    };
  },
  created() {},
  mounted() {
    if (
      !this.propFeasibility.sensor &&
      this.propProductTypeSelected &&
      Object.keys(this.propProductTypeSelected).length
    ) {
      this.propFeasibility.sensor = this.propProductTypeSelected
        .technicalProduct
        ? this.propProductTypeSelected.technicalProduct.sensor.id
        : null;
    }
    if (this.propFeasibility.dateRange.length) {
      for (let i = 0; i < this.propFeasibility.dateRange.length; i++) {
        try {
          let start = new Date(this.propFeasibility.dateRange[i][0]);
          let end = new Date(this.propFeasibility.dateRange[i][1]);
          this.items.push({
            id: start.getTime(),
            content: "Period " + (this.items.length + 1),
            start: start,
            group: 0,
            end: end,
          });
        } catch (error) {}
        this.loading = true;
      }
    }
  },
  methods: {
    clickTimeLine(e) {
      if (e && Object.keys(e).length > 0 && e.item) {
        try {
          this.selectedRangeIndex = this.items.findIndex(
            (x) => x.id === e.item
          );
          let item =
            this.selectedRangeIndex >= 0
              ? this.items[this.selectedRangeIndex]
              : null;
          if (item && Object.keys(item).length > 0) {
            this.dateRange = [
              new Date(item.start).getTime(),
              new Date(item.end).getTime(),
            ];
            this.datepickerOpen = true;
          }
        } catch (error) {}
      }
    },
    selectTimeLine(e) {},
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    notBeforeStartDate(date) {
      if (this.propFeasibility.startDate)
        return date < new Date(this.propFeasibility.startDate);
      else return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    initMapSuccess(data) {
      this.map = data.map;
      this.layer = data.layer;
      if (this.propFeature && Object.keys(this.propFeature).length) {
        this.layer.getSource().addFeature(this.propFeature);

        var ext = this.propFeature.getGeometry().getExtent();
        this.map.getView().fit(ext, {
          size: this.map.getSize(),
        });
        var center = getCenter(ext);
        this.map.getView().animate({
          center: [center[0], center[1]],
          duration: 100,
          zoom:
            this.map.getView().getZoom() < 2
              ? 2
              : this.map.getView().getZoom() - 0.5,
        });
      }
    },
    confirmDate(data) {
      this.loading = true;
      if (!this.minDate) this.minDate = data[0];
      else {
        if (this.minDate > data[0]) this.minDate = data[0];
      }
      if (!this.maxDate) this.maxDate = data[1];
      else {
        if (this.maxDate < data[1]) this.maxDate = data[1];
      }
      if (this.selectedRangeIndex >= 0) {
        let overlapPeriod = [];
        for (let i = 0; i < this.propFeasibility.dateRange.length; i++) {
          if (i === this.selectedRangeIndex) continue;
          if (
            (this.propFeasibility.dateRange[i][0] <= data[0] &&
              data[0] <= this.propFeasibility.dateRange[i][1]) ||
            (this.propFeasibility.dateRange[i][0] <= data[1] &&
              data[1] <= this.propFeasibility.dateRange[i][1]) ||
            (this.propFeasibility.dateRange[i][0] >= data[0] &&
              this.propFeasibility.dateRange[i][1] <= data[1])
          ) {
            overlapPeriod.push(i);
          }
        }
        if (overlapPeriod.length > 0) {
          let msg = [
            "You can't edit this period, its overlap previous period:",
          ];
          for (let i = 0; i < overlapPeriod.length; i++) {
            let start = new Date(
              this.propFeasibility.dateRange[overlapPeriod[i]][0]
            );
            let startMonth = start.getMonth() + 1;
            if (startMonth < 10) startMonth = "0" + startMonth;
            let startD = start.getDate();
            if (startD < 10) startD = "0" + startD;
            let end = new Date(
              this.propFeasibility.dateRange[overlapPeriod[i]][1]
            );
            let endMonth = end.getMonth() + 1;
            if (endMonth < 10) endMonth = "0" + endMonth;
            let endD = end.getDate();
            if (endD < 10) endD = "0" + endD;
            msg.push(
              "Period " +
                (overlapPeriod[i] + 1) +
                ": " +
                start.getFullYear() +
                "-" +
                startMonth +
                "-" +
                startD +
                "T00:00:00 - " +
                end.getFullYear() +
                "-" +
                endMonth +
                "-" +
                endD +
                "T23:59:59"
            );
          }
          this.dateRange = null;
          this.$emit("message", msg);
          return;
        }
        if (this.selectedRangeIndex < this.propFeasibility.dateRange.length)
          this.propFeasibility.dateRange[this.selectedRangeIndex] = data;
        if (this.selectedRangeIndex < this.items.length) {
          this.items[this.selectedRangeIndex].start = new Date(data[0]);
          this.items[this.selectedRangeIndex].end = new Date(data[1]);
        }
      } else {
        let overlapPeriod = [];
        for (let i = 0; i < this.propFeasibility.dateRange.length; i++) {
          if (i === this.selectedRangeIndex) continue;
          if (
            (this.propFeasibility.dateRange[i][0] <= data[0] &&
              data[0] <= this.propFeasibility.dateRange[i][1]) ||
            (this.propFeasibility.dateRange[i][0] <= data[1] &&
              data[1] <= this.propFeasibility.dateRange[i][1]) ||
            (this.propFeasibility.dateRange[i][0] >= data[0] &&
              this.propFeasibility.dateRange[i][1] <= data[1])
          ) {
            overlapPeriod.push(i);
          }
        }
        if (overlapPeriod.length > 0) {
          let msg = [
            "You can't create this period, its overlap previous period:",
          ];
          for (let i = 0; i < overlapPeriod.length; i++) {
            let start = new Date(
              this.propFeasibility.dateRange[overlapPeriod[i]][0]
            );
            let startMonth = start.getMonth() + 1;
            if (startMonth < 10) startMonth = "0" + startMonth;
            let startD = start.getDate();
            if (startD < 10) startD = "0" + startD;
            let end = new Date(
              this.propFeasibility.dateRange[overlapPeriod[i]][1]
            );
            let endMonth = end.getMonth() + 1;
            if (endMonth < 10) endMonth = "0" + endMonth;
            let endD = end.getDate();
            if (endD < 10) endD = "0" + endD;
            msg.push(
              "Period " +
                (overlapPeriod[i] + 1) +
                ": " +
                start.getFullYear() +
                "-" +
                startMonth +
                "-" +
                startD +
                "T00:00:00 - " +
                end.getFullYear() +
                "-" +
                endMonth +
                "-" +
                endD +
                "T23:59:59"
            );
          }
          this.dateRange = null;
          this.$emit("message", msg);
          return;
        }
        this.items.push({
          id: new Date().getTime(),
          content: "Period " + (this.items.length + 1),
          start: new Date(data[0]),
          group: 0,
          end: new Date(data[1]),
        });
        this.propFeasibility.dateRange.push(data);
      }
      this.options.start = this.minDate;
      this.options.end = this.maxDate;
      console.log(this.options);
      setTimeout(() => {
        this.$refs.timeline.redraw();
        this.$refs.timeline.fit();
        this.dateRange = null;
        this.loading = true;
      }, 10);
    },
    selectRange(index) {
      if (index < this.propFeasibility.dateRange.length) {
        this.dateRange = this.propFeasibility.dateRange[index];
        this.datepickerOpen = true;
        this.selectedRangeIndex = index;
      }
    },
    removeRange(index) {
      if (index < this.propFeasibility.dateRange.length) {
        this.propFeasibility.dateRange.splice(index, 1);
        this.items.splice(index, 1);
      }
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    messageMaxIncidenceAngle() {
      if (
        this.propFeasibility.maxIncidenceAngle === "" ||
        this.propFeasibility.maxIncidenceAngle === null ||
        this.propFeasibility.maxIncidenceAngle === undefined
      ) {
        return "The field is required";
      } else {
        if (
          this.propFeasibility.maxIncidenceAngle < 5 ||
          this.propFeasibility.maxIncidenceAngle > 50
        ) {
          return "The value must be from 5 to 50";
        }
      }
      return null;
    },
    messageNotificationThreshold() {
      if (
        this.propFeasibility.maxCloudCover &&
        this.propFeasibility.notificationThreshold
      ) {
        if (
          this.propFeasibility.notificationThreshold <
          this.propFeasibility.maxCloudCover
        ) {
          return "The acquisition notification threshold field must be equal or superior to the max cloud cover";
        } else if (this.propFeasibility.notificationThreshold > 100)
          return "The value is above the maximum allowed value.";
      } else if (
        this.propFeasibility.notificationThreshold === null ||
        this.propFeasibility.notificationThreshold === undefined ||
        this.propFeasibility.notificationThreshold === ""
      )
        return "The acquisition notification threshold field is required";
      else return null;
    },
    messageMinBH() {
      if (
        this.propFeasibility.minBH === null ||
        this.propFeasibility.minBH === undefined ||
        !this.propFeasibility.minBH
      )
        return "The min B/H field is required";
      else {
        if (
          this.propFeasibility.minBH < 0.2 ||
          this.propFeasibility.minBH > 0.8
        )
          return "The value must be from 0.2 to 0.8";
        else return null;
      }
    },
    messageMaxBH() {
      if (
        this.propFeasibility.maxBH === null ||
        this.propFeasibility.maxBH === undefined ||
        !this.propFeasibility.maxBH
      )
        return "The max B/H field is required";
      else {
        if (
          this.propFeasibility.maxBH < 0.2 ||
          this.propFeasibility.maxBH > 0.8
        )
          return "The value must be from 0.2 to 0.8";
        else return null;
      }
    },
    dateRangeLabels() {
      let results = [];
      for (let i = 0; i < this.propFeasibility.dateRange.length; i++) {
        let range = this.propFeasibility.dateRange[i];
        let start = new Date(range[0]);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        let startMonth = start.getMonth() + 1;
        startMonth = startMonth < 10 ? "0" + startMonth : startMonth;
        let startD = start.getDate();
        startD = startD < 10 ? "0" + startD : startD;
        let end = new Date(range[1]);
        let endMonth = end.getMonth() + 1;
        endMonth = endMonth < 10 ? "0" + endMonth : endMonth;
        let endD = end.getDate();
        endD = endD < 10 ? "0" + endD : endD;
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);
        results.push(
          start.getFullYear() +
            "-" +
            startMonth +
            "-" +
            startD +
            " 00:00:00" +
            " ~ " +
            end.getFullYear() +
            "-" +
            endMonth +
            "-" +
            endD +
            " 23:59:59"
        );
      }
      return results;
    },
  },
};
</script>
<style lang="scss" scoped>
.feasibility {
  padding: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: [first] 30rem [line2] auto [end];
  .left {
    h1 {
      &.tasking-name {
        &::after {
          content: "";
          display: block;
          width: 100%;
          border-bottom: 1px dashed #ccc;
          margin-top: 0.5rem;
        }
      }
    }
    .control-field {
      display: grid;
      grid-auto-columns: repeat(1, 1fr);
      cursor: pointer;

      .label-date {
        order: none;
        margin: 0;
      }
    }
    fieldset {
      border: none;
      padding: 0;
      &.fields-group {
        padding: 1rem;
        background-color: rgba($color: #eef3f7, $alpha: 1);
      }
    }
    .justify-center {
      justify-content: center;
    }
  }
  textarea.form-control {
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.4);
    }
  }
  select.new-style {
    background: #eef3f7 !important;
    border-radius: 4px;
    border-color: #eef3f7 !important;
  }

  .grid {
    display: grid;
  }
  .grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .gap-4 {
    gap: 1rem;
  }
  .gap-2 {
    gap: 0.5rem;
  }
  .order-1 {
    order: 1;
  }
  .order-0 {
    order: 0;
  }
  .span-warning {
    font-size: 10px;
  }
  .span-danger {
    font-size: 10px;
  }
  .range-label {
    border: 1px solid #1697de;
    color: #1697de;
    font-size: 11px;
    padding: 0.25rem 1.5rem 0.25rem 0.5rem;
    font-family: "Helvetica Neue Light";
    border-radius: 0.125rem;
    position: relative;
    a {
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .remove-label {
      position: absolute;
      right: 0.1375rem;
      top: 0.175rem;
      width: 1rem;
      height: 1rem;
      &:hover {
        cursor: pointer;
        svg {
          fill: #dc3545;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.feasibility {
  input {
    &.border-warning {
      border-color: #ffc107 !important;
    }
    &.border-danger {
      border-color: #dc3545 !important;
    }
  }
  select {
    &.new-style {
      &.border-warning {
        border-color: #ffc107 !important;
      }
      &.border-danger {
        border-color: #dc3545 !important;
      }
    }
  }
}
</style>
