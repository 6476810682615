<template>
  <div
    class="map__openlayer"
    id="map__openlayerMetadata"
    :style="{ height: mapHeight + 'px' }"
  ></div>
</template>
<script>
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { Tile as TileLayer } from "ol/layer";
import XYZ from "ol/source/XYZ";
import { defaults as defaultControls } from "ol/control";
import { fromLonLat, transform } from "ol/proj";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
import { Vector as VectorLayer, Image as ImageLayer } from "ol/layer";
import { Vector as VectorSource, ImageCanvas } from "ol/source";
import { Style, Fill, Stroke } from "ol/style.js";
import turf from "turf";
export default {
  props: {
    propSrc: {
      required: true,
    },
    propItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      map: Object,
      layer: Object,
      layerDrawImage: Object,
      styles: {
        footprint: new Style({
          fill: new Fill({
            color: "rgba(0,158,247,0.3)",
          }),
          stroke: new Stroke({
            color: "rgba(0,158,247,1)",
            width: 1.25,
          }),
        }),
        image: new Style({
          fill: new Fill({
            color: "rgba(0,0,0,0)",
          }),
          stroke: new Stroke({
            color: "rgba(0,0,0,0.8)",
            width: 1.25,
          }),
        }),
      },
    };
  },
  async mounted() {
    if (!this.basemap || Object.keys(this.basemap).length === 0)
      await this.getAllBasemaps();
    this.initMapOpenlayer();
  },
  created() {},
  methods: {
    initMapOpenlayer: function () {
      var vm = this;
      var source = new VectorSource({ wrapX: false, id: "sourcedraw" });

      this.layer = new VectorLayer({
        source: source,
      });
      this.layer.set("id", "layerdraw");
      this.map = new Map({
        controls: defaultControls({ attribution: false, zoom: false }),
        layers: [
          new TileLayer({
            title: "Basemap",
            id: "vgbasemap",
            source: new XYZ({
              url:
                vm.basemap &&
                Object.keys(vm.basemap).length > 0 &&
                vm.basemap.source &&
                vm.basemap.source.url
                  ? vm.basemap.source.url
                  : process.env.VUE_APP_BASEMAP_URL,
            }),
            show: true,
            opacity: 1,
          }),
          this.layer,
        ],
        target: "map__openlayerMetadata",
        view: new View({
          center: fromLonLat(
            vm.propPoints && vm.propPoints.length > 0
              ? vm.propPoints[0]
              : [108.222848, 16.062833]
          ),
          zoom: 8,
          minZoom: 0,
          maxZoom: 20,
        }),
      });
      this.$emit("initMap", this.map);
      this.addImage2Map();
    },

    addFootprint: async function (val) {
      try {
        this.addFeature(
          this.layer,
          val.geometry.coordinates[0],
          this.styles.footprint
        );
      } catch (error) {
        console.error("addFootprint", error);
      }
    },
    addFeature(layer, coordinates, style) {
      let id =
        this.propItem && this.propItem.id
          ? this.propItem.id
          : new Date().getTime();
      let feature = layer.getSource().getFeatureById(id);
      if (!feature) {
        var points = [];
        try {
          for (var i = 0; i < coordinates.length; i++) {
            points[i] = transform(coordinates[i], "EPSG:4326", "EPSG:3857");
          }
          feature = new Feature({
            geometry: new Polygon([points]),
            id: id,
          });
          feature.set("id", id);
          feature.id_ = id;
          feature.setStyle(style);
          layer.getSource().addFeature(feature);
          let ext = feature.getGeometry().getExtent();
          this.map.getView().fit(ext, this.map.getSize());
          this.map.getView().setZoom(this.map.getView().getZoom() - 1);
        } catch (error) {
          console.error("addFeature", error);
        }
      } else {
        feature.style = { visibility: "visible" };
      }
    },
    fintNearPoint(coors, point) {
      try {
        let result = coors[0];
        let max = Number.MAX_VALUE;
        for (let i = 0; i < coors.length; i++) {
          var distance = turf.distance(
            turf.point(coors[i]),
            turf.point(point),
            null
          );
          if (distance < max) {
            max = distance;
            result = coors[i];
          }
        }
        return result;
      } catch (error) {
        console.log(error);
      }
    },

    getNewCoordinates4ImageMap(item) {
      return new Promise(function (resolve, reject) {
        let coordinates = [
          [item.bbox[0], item.bbox[3]],
          [item.bbox[2], item.bbox[3]],
          [item.bbox[2], item.bbox[1]],
          [item.bbox[0], item.bbox[1]],
        ];
        let distance_AB = Math.abs(coordinates[0][0] - coordinates[1][0]);
        let distance_AD = Math.abs(coordinates[0][1] - coordinates[3][1]);
        try {
          var img = new Image();
          img.src = item.blob_link;
          img.onerror = function (err) {
            console.log(err);
          };
          img.onload = function () {
            var canvas2 = document.createElement("canvas"),
              // Get the 2D context of the canvas
              context = canvas2.getContext && canvas2.getContext("2d"),
              width,
              height,
              height = (canvas2.height =
                img.naturalHeight || img.offsetHeight || img.height);
            width = canvas2.width =
              img.naturalWidth || img.offsetWidth || img.width;

            // Draw the image to the canvas
            context.drawImage(img, 0, 0);
            let pixels2 = [];

            let isBreak = false;
            // Get the data of the image

            for (let i = 0; i < width; i++) {
              for (let j = 0; j < height; j++) {
                let data = context.getImageData(i, j, 1, 1);
                if (
                  ((data.data[0] != 0 || data.data[1]) != 0 ||
                    data.data[2] != 0) &&
                  data.data[3] > 0
                ) {
                  // Left
                  pixels2.push([i, j]);
                  isBreak = true;
                  break;
                }
              }
              if (isBreak) break;
            }
            isBreak = false;
            for (let i = 0; i < height; i++) {
              for (let j = 0; j < width; j++) {
                let data = context.getImageData(j, i, 1, 1);
                if (
                  ((data.data[0] != 0 || data.data[1]) != 0 ||
                    data.data[2] != 0) &&
                  data.data[3] > 0
                ) {
                  //Top
                  pixels2.push([j, i]);
                  isBreak = true;
                  break;
                }
              }
              if (isBreak) break;
            }
            isBreak = false;
            for (let i = width - 1; i >= 0; --i) {
              for (let j = 0; j < height; j++) {
                let data = context.getImageData(i, j, 1, 1);
                if (
                  ((data.data[0] != 0 || data.data[1]) != 0 ||
                    data.data[2] != 0) &&
                  data.data[3] > 0
                ) {
                  //Right
                  pixels2.push([i, j]);
                  isBreak = true;
                  break;
                }
              }
              if (isBreak) break;
            }
            isBreak = false;
            for (let i = height - 1; i >= 0; --i) {
              for (let j = 0; j < width; j++) {
                let data = context.getImageData(j, i, 1, 1);
                if (
                  ((data.data[0] != 0 || data.data[1]) != 0 ||
                    data.data[2] != 0) &&
                  data.data[3] > 0
                ) {
                  //Bottom;
                  pixels2.push([j, i]);
                  isBreak = true;
                  break;
                }
              }
              if (isBreak) break;
            }

            let left_2_A = pixels2[0][0];
            let right_2_B = pixels2[2][0];

            let top_2_A = pixels2[1][1];
            let bottom_2_D = pixels2[3][1];
            //Tinh do dai cua 1 don vi = chieu rong / kich thuoc pixel cua anh.
            // AB là chieu rong , AD chieu cao
            let pixel_AB =
              distance_AB / (width - left_2_A - (width - right_2_B));

            let pixel_AD =
              distance_AD / (height - top_2_A - (height - bottom_2_D));
            // Calculator new coordinates
            // + hoac - khoang transparent cua anh
            coordinates[0][0] = coordinates[0][0] - pixel_AB * pixels2[0][0];
            coordinates[3][0] = coordinates[3][0] - pixel_AB * pixels2[0][0];

            coordinates[0][1] = coordinates[0][1] + pixel_AD * pixels2[1][1];
            coordinates[1][1] = coordinates[1][1] + pixel_AD * pixels2[1][1];

            coordinates[1][0] =
              coordinates[1][0] + pixel_AB * (width - pixels2[2][0]);
            coordinates[2][0] =
              coordinates[2][0] + pixel_AB * (width - pixels2[2][0]);

            coordinates[2][1] =
              coordinates[2][1] - pixel_AD * (height - pixels2[3][1]);
            coordinates[3][1] =
              coordinates[3][1] - pixel_AD * (height - pixels2[3][1]);
            resolve(coordinates);
          };
        } catch (error) {
          resolve(null);
          console.log(error);
        }
      });
    },
    addImage2Map: async function () {
      var vm = this;
      try {
        var corners = [
          { x: 100, y: 100 }, // ul
          { x: 520, y: 20 }, // ur
          { x: 520, y: 380 }, // br
          { x: 100, y: 380 }, // bl
        ];
        var lcoordinates = [],
          pixels = [];
        lcoordinates = await this.getNewCoordinates4ImageMap(this.propItem);
        if (lcoordinates.length < 4) return;
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");
        var img = new Image();
        img.src = vm.propSrc;
        img.onload = function () {};
        await this.addFootprint(this.propItem);
        var bbox = {
          WKID: "EPSG:4326",
          coord: lcoordinates,
        };

        var canvasFunction = function (
          extent,
          resolution,
          pixelRatio,
          size,
          projection
        ) {
          var canvasWidth = size[0],
            canvasHeight = size[1];
          canvas.setAttribute("width", canvasWidth);
          canvas.setAttribute("height", canvasHeight);
          var mapExtent = vm.map.getView().calculateExtent(vm.map.getSize());
          var canvasOrigin = vm.map.getPixelFromCoordinate([
            extent[0],
            extent[3],
          ]);
          var mapOrigin = vm.map.getPixelFromCoordinate([
            mapExtent[0],
            mapExtent[3],
          ]);
          var delta = [
            pixelRatio * mapOrigin[0] - pixelRatio * canvasOrigin[0],
            pixelRatio * mapOrigin[1] - pixelRatio * canvasOrigin[1],
          ];

          var points = bbox.coord;
          if (bbox.WKID != "EPSG:3857") {
            // Verifier egalité sur chaine
            for (var i = 0; i < points.length; i++) {
              points[i] = transform(points[i], bbox.WKID, "EPSG:3857");
            }
            bbox.WKID = "EPSG:3857";
          }
          for (var i = 0; i < points.length; i++) {
            pixels[i] = vm.map.getPixelFromCoordinate(points[i]);
            pixels[i][0] = pixelRatio * pixels[i][0] + delta[0];
            pixels[i][1] = pixelRatio * pixels[i][1] + delta[1];
            corners[i].x = pixels[i][0];
            corners[i].y = pixels[i][1];
          }
          // draw a box
          function drawBBox(mybbox) {
            context.save();
            context.moveTo(pixels[0][0], pixels[0][1]);
            context.lineTo(pixels[1][0], pixels[1][1]);
            context.lineTo(pixels[2][0], pixels[2][1]);
            context.lineTo(pixels[3][0], pixels[3][1]);
            context.closePath();
            context.lineWidth = 1;
            context.stroke();
            context.restore();
          }

          var p = new Perspective(context, img);
          if (p) {
            p.draw([
              [corners[0].x, corners[0].y],
              [corners[1].x, corners[1].y],
              [corners[2].x, corners[2].y],
              [corners[3].x, corners[3].y],
            ]);
          }
          //drawBBox(bbox);
          return canvas;
        };
        var canvasLayer = new ImageLayer({
          source: new ImageCanvas({
            canvasFunction: canvasFunction,
            projection: "EPSG:3857",
          }),
        });
        canvasLayer.setZIndex(vm.map.getLayers().array_.length);
        canvasLayer.set("id", this.propItem.id);
        vm.map.addLayer(canvasLayer);
        vm.map.render();
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    mapHeight() {
      let dom = document.getElementById("tableMetadata");
      if (dom) {
        return dom.offsetHeight;
      } else return 500;
    },
    basemap() {
      return this.$store.getters.getBasemap;
    },
  },
};
</script>
<style lang="scss" scoped>
.map__openlayer {
  position: absolute;
  width: 100%;
  padding: 0;
  border: 1px solid #eee;
  max-height: 1000px;
}
</style>
