<template>
  <Shared4MeList />
</template>
<script>
import Shared4MeList from "./shared4me/List";
export default {
  components: {
    Shared4MeList,
  },
  created() {
    setTimeout(() => {
      this.$emit("initBreadcrumb", [
        "menu_sidebar_data_management",
        "menu_sidebar_image_shared_with_me",
      ]);
    }, 200);
  },
};
</script>