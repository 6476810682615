var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.containerClass,on:{"click":function($event){return _vm.onClick($event)}}},[_c('div',{staticClass:"p-hidden-accessible"},[_c('input',_vm._b({ref:"input",attrs:{"type":"checkbox","readonly":_vm.readonly},domProps:{"checked":_vm.checked,"value":_vm.value},on:{"focus":function($event){return _vm.onFocus($event)},"blur":function($event){return _vm.onBlur($event)}}},'input',_vm.$attrs,false))]),_c('div',{ref:"box",class:[
      'p-checkbox-box',
      {
        'p-highlight': _vm.checked,
        'p-disabled': _vm.$attrs.disabled,
        'p-focus': _vm.focused,
        readonly: _vm.readonly,
      } ],attrs:{"role":"checkbox","aria-checked":_vm.checked}},[_c('span',{class:['p-checkbox-icon', { 'pi pi-check': _vm.checked }]})])])}
var staticRenderFns = []

export { render, staticRenderFns }