var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},[_c('tbody',[_c('tr',[(_vm.maximized)?_c('td',{staticStyle:{"vertical-align":"top"},attrs:{"align":"left","width":"50%"}},[_c('p',[_vm._v(_vm._s(_vm.$t("label_map")))]),_c('div',{staticClass:"map-preview"},[(_vm.imgPreview.src)?_c('MapOpenlayer',{attrs:{"prop-src":_vm.imgPreview.src,"propPoints":_vm.propItem.geom &&
                _vm.propItem.geom.coordinates &&
                _vm.propItem.geom.coordinates.length > 0
                  ? _vm.propItem.geom.coordinates[0]
                  : []}}):_vm._e()],1)]):_vm._e(),(!_vm.maximized)?_c('td',{staticStyle:{"vertical-align":"top","padding-top":"0.75rem","padding-bottom":"0.75rem"},attrs:{"align":"left","width":"35%"}},[_c('p',{style:([
              _vm.propShowDownload
                ? { paddingTop: '0.76125rem', paddingBottom: '0.76125rem' }
                : {} ])},[_vm._v(" "+_vm._s(_vm.$t("label_quicklook"))+" ")]),_c('div',{staticClass:"GP0VF02CF0"},[_c('div',{staticClass:"Iybw1Bhj3Jhd8s pt-1"},[(_vm.imgPreview.loading)?_c('tile',{staticClass:"ehAbnmg4Jwb9BS",attrs:{"loading":_vm.imgPreview.loading,"height":'40',"width":'40',"color":'fd853e'}}):_c('div',[(_vm.imgPreview.src)?_c('quick-look',{attrs:{"prop-data":{
                    img: _vm.imgPreview.src,
                  }}}):_c('img',{attrs:{"width":"100%","src":"/img/icons/no-image.png","title":"No Image"}})],1)],1)])]):_vm._e(),_c('td',{staticStyle:{"vertical-align":"top"},style:([_vm.maximized ? { width: '50%' } : { width: '65%' }]),attrs:{"align":"left"}},[_c('p',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_vm._v(_vm._s(_vm.$t("label_values"))+" ")]),(_vm.propShowDownload)?_c('button',{staticClass:"btn btn-primary btn-sm",attrs:{"disabled":_vm.propButtonDownload &&
                _vm.propButtonDownload.disabled &&
                _vm.propButtonDownload.ids.includes(_vm.propItem.id)},on:{"click":function($event){return _vm.downloadImage(_vm.propItem.id)}}},[_c('i',{class:_vm.propButtonDownload &&
                  _vm.propButtonDownload.processing &&
                  _vm.propButtonDownload.ids.includes(_vm.propItem.id)
                    ? 'fas fa-spinner fa-spin'
                    : 'fas fa-download'}),_vm._v(" "+_vm._s(_vm.$t("button_text_download"))+" ")]):_vm._e()]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table-metadata",attrs:{"id":"tableMetadata","cellspacing":"0","cellpadding":"0"}},[_vm._m(0),_c('tbody',[_c('tr',[_c('td',{staticStyle:{"width":"150px"}},[_vm._v(_vm._s(_vm.$t("label_image_name")))]),_c('td',[_c('div',{attrs:{"title":_vm.entity && _vm.entity.name.length > 50 ? _vm.entity.name : null}},[_vm._v(" "+_vm._s(_vm.entity && _vm.entity.name.length > 50 ? _vm.entity.name.substring(0, 50) + "..." : _vm.entity.name)+" ")])])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("label_satellite")))]),_c('td',[_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.entity.satellite_name ? _vm.entity.satellite_name : null)+" ")])])])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("label_image_time")))]),_c('td',[_c('div',[_vm._v(" "+_vm._s(_vm.entity && _vm.entity.imaging_date ? _vm.entity.imaging_date : "")+" ")])])]),(_vm.imageType && Object.keys(_vm.imageType).length > 0)?_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("label_image_type")))]),_c('td',[_c('div',[_vm._v(" "+_vm._s(_vm.imageType.translation && _vm.imageType.translation[_vm.lang] ? _vm.imageType.translation[_vm.lang].description : _vm.imageType.value ? _vm.imageType.value : null)+" ")])])]):_vm._e(),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("label_incidence_angle")))]),_c('td',[_c('div',[_vm._v(" "+_vm._s(_vm.entity && _vm.entity.incidence_angle ? _vm.entity.incidence_angle : "")+" ")])])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("label_resolution")))]),_c('td',[_c('div',[_vm._v(" "+_vm._s(_vm.entity && _vm.entity.resolution ? _vm.entity.resolution : "")+" ")])])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("label_processing_level")))]),_c('td',[_c('div',[_vm._v(" "+_vm._s(_vm.entity && _vm.entity.processing_level_name ? _vm.entity.processing_level_name : "")+" ")])])]),_vm._l((_vm.entity.metadata.filter(
                    function (x) { return x.active &&
                      x.key != 'boundary' &&
                      !_vm.fieldsIgnore.includes(x.key); }
                  )),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.value)+_vm._s(item.unit ? item.unit : null))])])})],2)])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('colgroup',[_c('col'),_c('col')])}]

export { render, staticRenderFns }