<template>
  <div
    class="attempts-map"
    :style="{ height: propContentHeight - 2 * 16 + 'px' }"
  >
    <div id="attemptsMap"></div>
  </div>
</template>
<script>
var sketch;
import Map from "ol/Map";
import View from "ol/View";
import XYZ from "ol/source/XYZ";
import { Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { defaults as defaultControls } from "ol/control";
import { defaults } from "ol/interaction";
import { Style, Fill, Stroke } from "ol/style.js";
export default {
  props: {
    propContentHeight: {
      type: Number,
    },
  },
  data() {
    return {
      map: Object,
      layer: Object,
      draw: Object,
      modify: Object,
      select: Object,
      fillColor: "#FFBF23",
      fill: new Fill({
        color: "rgba(255,255,255,0.4)",
      }),
      strokeColor: "#FFBF23",
      stroke: new Stroke({
        color: "#FFBF23",
        width: 1.25,
      }),
    };
  },
  watch: {},
  mounted() {
    this.initMapOpenlayer();
  },
  methods: {
    refrestMap() {
      setTimeout(() => {
        this.map.updateSize();
      }, 50);
    },
    initMapOpenlayer: function () {
      var vm = this;
      try {
        var source = new VectorSource({ wrapX: false, id: "sourcedraw" });

        this.layer = new VectorLayer({
          source: source,
          style: new Style({
            fill: new Fill({
              color: this.hexToRgbA(this.fillColor, 0.5),
            }),
            stroke: new Stroke({
              color: "#FFBF23",
              width: 2,
            }),
          }),
        });
        this.layer.set("id", "layerdraw");
        let _zoom = localStorage.getItem("zoom");
        if (!_zoom) _zoom = 6;
        try {
          _zoom = parseFloat(_zoom);
        } catch (errZoom) {
          _zoom = 6;
        }
        let _center = [localStorage.getItem("center")];
        if (!_center || _center.length === 0)
          _center = fromLonLat([108.222848, 16.062833]);
        try {
          if (!_center[0]) _center[0] = "";
          let centerLen = _center[0].split(",");
          if (centerLen.length != 2)
            _center = fromLonLat([108.222848, 16.062833]);
          else {
            try {
              let centerLng = parseFloat(centerLen[0]);
              let centerLat = parseFloat(centerLen[1]);
              if (isNaN(centerLng) || isNaN(centerLat))
                _center = fromLonLat([108.222848, 16.062833]);
              else _center = [centerLng, centerLat];
            } catch (errorPaser) {
              _center = fromLonLat([108.222848, 16.062833]);
            }
          }
        } catch (errorCenter) {
          _center = fromLonLat([108.222848, 16.062833]);
        }
        this.map = new Map({
          controls: defaultControls({ attribution: false, zoom: false }),
          interactions: defaults({ doubleClickZoom: false }),
          layers: [
            new TileLayer({
              id: new Date().getTime(),
              source: new XYZ({
                url:
                  vm.basemap &&
                  Object.keys(vm.basemap).length > 0 &&
                  vm.basemap.source &&
                  vm.basemap.source.url
                    ? vm.basemap.source.url
                    : process.env.VUE_APP_BASEMAP_URL,
              }),
              show: true,
              opacity: 1,
            }),
            this.layer,
          ],
          target: "attemptsMap",
          view: new View({
            center: _center,
            zoom: _zoom,
            minZoom: 0,
            maxZoom: 20,
          }),
        });
        this.$emit("initMapSuccess", {
          map: this.map,
          layer: this.layer,
        });
      } catch (error) {
        console.log(error);
      }
    },
    hexToRgbA(hex, opacity) {
      if (!opacity) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
  },
  computed: {
    basemap() {
      return this.$store.getters.getBasemap;
    },
  },
};
</script>
<style lang="scss" scoped>
.attempts-map {
  width: 100%;
  position: relative;
  #attemptsMap {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
}
</style>
