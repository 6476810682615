<template>
  <div class="group" role="group">
    <div class="form-inline">
      <div class="tool-bar-button">
        <button
          :title="$t('tooltip_criteria')"
          class="btn btn-geostore btn-criteria"
          :class="propButtonsTool.criteria.active ? 'active' : ''"
          v-if="propButtonsTool.criteria.visible"
          :disabled="propButtonsTool.criteria.disable"
          @click="actionClick('SHOW_CRITERIA')"
        >
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <div class="tool-bar-button button-result">
        <button
          :title="$t('tooltip_results')"
          class="btn btn-geostore btn-results"
          :class="propButtonsTool.satellite.active ? 'active' : ''"
          v-if="propButtonsTool.satellite.visible"
          :disabled="propButtonsTool.satellite.disable"
          @click="actionClick('SHOW_SATELLITE')"
        >
          <i
            :class="
              propButtonsTool.satellite.processing
                ? 'fas fa-spinner fa-spin'
                : 'fas fa-camera'
            "
          ></i>
          <span class="badge badge-success" v-if="propImagesTotal">
            {{ propImagesTotal }}
          </span>
        </button>
      </div>
      <div class="tool-bar-button">
        <button
          :title="$t('tooltip_carts')"
          class="btn btn-geostore btn-carts"
          :class="propButtonsTool.cart.active ? 'active' : ''"
          v-if="propButtonsTool.cart.visible"
          :disabled="propButtonsTool.cart.disable"
          @click="actionClick('SHOW_CART')"
        >
          <i class="fas fa-shopping-cart"></i>
          <span class="badge badge-success" v-if="propCartTotal">
            {{ propCartTotal }}
          </span>
        </button>
      </div>
      <div class="col-input-search" v-show="documentWidth >= 768">
        <InputText
          v-model.lazy="search.txtSearch"
          type="search"
          :placeholder="$t('placeholder_insert_place')"
          :style="[
            propButtonsTool.satellite.visible
              ? { width: '16.175rem' }
              : { width: '19.075rem' },
          ]"
        />
      </div>
      <div class="tool-bar-button" v-show="documentWidth >= 768">
        <button
          :title="$t('tooltip_save_query')"
          class="btn btn-save-query"
          :class="propButtonsTool.saveQuery.active ? 'active' : ''"
          v-if="propButtonsTool.saveQuery.visible"
          :disabled="propButtonsTool.saveQuery.disable"
          @click="onSaveQuery"
        >
          <i class="fas fa-save"></i>
        </button>
      </div>
      <div class="tool-bar-button">
        <button
          :title="$t('tooltip_search')"
          type="button"
          class="btn btn-geostore btn-search"
          :class="propButtonsTool.search.active ? 'active' : ''"
          :style="[
            documentWidth < 768
              ? { borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }
              : {},
          ]"
          v-if="propButtonsTool.search.visible"
          :disabled="propButtonsTool.search.disable"
          @click="onSearch"
        >
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
    <div
      class="table-responsive mt-1"
      :style="{ maxHeight: documentHeight - 250 + 'px' }"
      v-show="address.length > 0"
    >
      <table
        class="table table-bordered table-hover"
        :style="[
          propButtonsTool.satellite.visible
            ? { width: '35.5rem' }
            : { width: '36.5rem' },
        ]"
      >
        <tbody>
          <tr v-for="item in address" :key="item._id">
            <td>
              <div @click="selectedAddress(item)">
                <span>{{ item.display_name }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import nominatimFunc from "@/utils/functions/nominatim";
import InputText from "@/components/commons/inputtext/InputText";
export default {
  components: {
    InputText,
  },
  props: {
    propButtonsTool: {
      type: Object,
      default: () => {},
    },
    propCartTotal: {
      type: Number,
      default: 0,
    },
    propImagesTotal: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      search: {
        page: 0,
        size: 50,
        txtSearch: null,
        isBottom: false,
        searching: false,
      },
      address: [],
      time: new Date().getTime(),
    };
  },
  created() {
    this.address = [];
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    callBackFuncs() {
      return this.$store.getters.getCallBackFuncs;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
  watch: {
    loggedIn: {
      handler(val) {
        if (val) {
        }
      },
      immediate: true,
    },
    "search.txtSearch": {
      handler(val) {},
      immediate: true,
    },
  },
  methods: {
    actionClick(code) {
      this.$emit("leftActionClick", {
        code: code,
      });
    },
    clearText() {
      this.search.txtSearch = null;
      this.search.page = 0;
      this.address = [];
      this.search.isBottom = false;
      this.search.searching = false;
    },
    onSaveQuery() {
      this.$emit("saveQuery");
    },
    async onSearch() {
      if (this.search.txtSearch && this.search.txtSearch.trim()) {
        let array = this.search.txtSearch.trim().split(",");
        if (array && array.length === 2) {
          try {
            let lat = parseFloat(array[0]);
            let lng = parseFloat(array[1]);
            if (lat && lng) {
              this.$emit("panToLocation", {
                lat: lat,
                lng: lng,
              });
              return;
            }
          } catch (error) {}
        }
        this.$emit("searchByName", {
          name: this.search.txtSearch.trim(),
        });
      } else {
        this.search.page = 0;
        this.address = [];
        this.search.isBottom = false;
        this.search.searching = false;
        this.onSearchZoom();
      }
    },
    async onSearchAddress() {
      if (new Date().getTime() - this.time < 300) {
        this.time = new Date().getTime();
        return;
      }
      this.time = new Date().getTime();
      setTimeout(async () => {
        try {
          if (this.search.txtSearch) {
            this.address = [];
            let response = await nominatimFunc.search({
              q: this.search.txtSearch,
              format: "json",
              polygon_geojson: 1,
              limit: 1000,
            });
            if (response.status === 200) {
              this.address = response.data;
            }
          }
        } catch (error) {}
      }, 310);
    },
    async onSearchZoom() {
      if (this.$store.getters.getAccessToken) {
        this.$emit("onSearchCriteria");
      } else {
        this.$store.dispatch("setCallBackFuncs", [
          {
            name: "onSearchZoom",
            arguments: [],
          },
        ]);
        this.$store.dispatch("setIsLoginForm", true);
      }
    },
    selectedAddress(val) {
      this.search.txtSearch = val.display_name;
      this.address = [];
      this.$emit("panToLocation", {
        lat: val.lat ? val.lat : null,
        lng: val.lon ? val.lon : null,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.group {
  margin: 0;
  padding: 0;
  padding: 0.3125rem;
  background: rgba($color: #ffffff, $alpha: 0.4);
  text-align: center;
  display: inline-block;
  opacity: 1;
  border-radius: 0.25rem;
}
.box-title {
  width: 100%;
  text-align: center;
  font-size: 14px;
}
div.form-inline {
  position: relative;
  opacity: 1;
}
div.form-inline input[type="search"] {
  background-color: white;
  opacity: 1;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 32px;
  border-color: #ddd;
  border-right: 0;
  &:active {
    outline: none;
    box-shadow: none;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    outline: none;
    box-shadow: none;
  }
}
button {
  height: 32px;
  line-height: normal;
  outline: none;
  box-shadow: none;
  border-radius: 0.25rem;
  &.btn-transparent {
    background: transparent;
    border-color: transparent;
  }
  &.btn-search {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &.btn-save-query {
    background: white;
    border-radius: 0;
    color: $background-color;
    border-color: #ddd;
    border-left: 0;
  }
  &:active {
    outline: none;
    box-shadow: none;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &.active {
    background: $orange;
    color: white;
  }
  &.btn-criteria {
    margin-right: 0.25rem;
  }
  &.btn-carts,
  &.btn-results {
    margin-right: 0.25rem;
    position: relative;
    .badge {
      position: absolute;
      padding: 0.125rem 0.25rem;
      border-radius: 50%;
      font-size: 10px;
      top: 0;
      right: 0.125rem;
    }
  }
}
.table-responsive {
  overflow-y: auto;
  tr {
    text-align: left;
    &:hover {
      cursor: pointer;
    }
  }
}

.col-input-search {
  input {
    font-size: 14px;
    width: 23.5rem;
    border-color: white;
    border-radius: 0.25rem;
  }
  .clear-text {
    z-index: 100;
    position: absolute;
    bottom: 0.5rem;
    right: 3rem;
    &:hover svg {
      fill: #dc3545;
      cursor: pointer;
    }
    svg {
      width: 0.5rem;
      height: 0.5rem;
    }
  }
}
</style>
