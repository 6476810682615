<template>
  <Dialog
    :header="$t('header_detail')"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :maximizable="false"
    :modal="true"
    :contentStyle="{
      width: width + 'px',
    }"
    @hide="onClose"
  >
    <table class="table table-metadata" cellspacing="0" cellpadding="0">
      <tbody>
        <tr>
          <td style="width: 12rem">Type Name</td>
          <td>
            <span class="type-name">{{ propItem.icr.progTypeName }}</span>
          </td>
        </tr>
        <tr>
          <td>Customer Reference</td>
          <td>{{ propItem.icr.customerRef }}</td>
        </tr>
        <tr>
          <td>Id</td>
          <td>{{ propItem.icr.id }}</td>
        </tr>
        <tr>
          <td>Sensor</td>
          <td>{{ propItem.icr.mission }}</td>
        </tr>
        <tr>
          <td>Program</td>
          <td>{{ propItem.icr.program }}</td>
        </tr>
        <tr>
          <td>Max Incidence Angle</td>
          <td>{{ propItem.icr.maxIncidenceAngle }}</td>
        </tr>
        <tr>
          <td>Max Cloud Cover</td>
          <td>{{ propItem.icr.maxCloudCover }}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>{{ propItem.icr.status }}</td>
        </tr>
        <tr>
          <td>Ordered Attempt</td>
          <td>{{ propItem.icr.status }}</td>
        </tr>
        <tr>
          <td>Downloading Configuration</td>
          <td>
            <div
              v-if="
                propItem.icr.downloadingConfigurations &&
                propItem.icr.downloadingConfigurations.length
              "
            >
              <ul class="list-none m-0 p-0">
                <li
                  v-for="(d, index) in propItem.icr.downloadingConfigurations"
                  :key="index + 'd'"
                >
                  {{ d.receivingStation }}&nbsp;{{ d.processingStation }}
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td>Notifications</td>
          <td>
            <div
              v-if="
                propItem.icr.notifications && propItem.icr.notifications.length
              "
            >
              <ul class="list-none m-0 p-0">
                <li
                  v-for="(n, index) in propItem.icr.notifications"
                  :key="index + 'n'"
                >
                  <div v-if="n.channels && n.channels.length">
                    <div v-for="(c, i) in n.channels" :key="i + 'c'">
                      <span v-if="c.addresses && c.addresses.length">
                        {{ c.addresses.join(",") }}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td>Sale Order (SAL)</td>
          <td>{{ propItem.sal }}</td>
        </tr>
        <tr>
          <td>Sale Order (SO)</td>
          <td>{{ propItem.so }}</td>
        </tr>

        <tr>
          <td>Order creator</td>
          <td>{{ propItem.icr.orderCreator }}</td>
        </tr>

        <tr>
          <td>Creation Date</td>
          <td>{{ propItem.icr.creationDate }}</td>
        </tr>
        <tr>
          <td>Geometric Processing</td>
          <td>{{ propItem.icr.productionParameters.geometricProcessing }}</td>
        </tr>

        <tr>
          <td>Projection Code</td>
          <td>{{ propItem.icr.productionParameters.projectionCode }}</td>
        </tr>
        <tr>
          <td>Spectral Bands Combinations</td>
          <td>
            {{ propItem.icr.productionParameters.spectralBandsCombination }}
          </td>
        </tr>
        <tr>
          <td>Product Format</td>
          <td>{{ propItem.icr.productionParameters.productFormat }}</td>
        </tr>
        <tr>
          <td>Pixel Coding</td>
          <td>{{ propItem.icr.productionParameters.radiometricProcessing }}</td>
        </tr>
        <tr>
          <td>Radiometric Processing</td>
          <td>{{ propItem.icr.productionParameters.pixelCoding }}</td>
        </tr>
        <tr>
          <td>Elevation</td>
          <td>{{ propItem.icr.productionParameters.elevation }}</td>
        </tr>
        <tr>
          <td>Priority</td>
          <td>{{ propItem.icr.productionParameters.priority }}</td>
        </tr>
      </tbody>
    </table>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
export default {
  components: {
    Dialog,
  },
  props: {
    propShow: {
      type: Boolean,
      default: false,
    },
    propItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow(val) {
      this.show = val;
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
      this.show = false;
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    width() {
      if (this.documentWidth > 768) {
        return 720;
      } else {
        return this.documentWidth;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
table {
  margin-bottom: 0;
  td {
    border-top: none;
    p {
      background: #a1a5b7;
      font-size: 14px;
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      color: white;
      border-radius: 0.25rem;
    }
    .map-preview {
      position: relative;
    }
    ul {
      li {
        list-style: none;
      }
    }
    .type-name {
      font-weight: 700;
      color: $background-color-2;
      font-size: 18px;
    }
  }
}
table.table-metadata {
  border-collapse: separate;
  border-spacing: 0 0.125rem;
  tr {
    margin-bottom: 0.25rem;
    td {
      padding: 0.5rem 0.75rem;
      border: 1px solid white;
      &:first-child {
        background: $background-color-2;
        color: white;
        border-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        &.no-background {
          background-color: transparent;
          color: #212529;
        }
      }
    }
  }
}
</style>
