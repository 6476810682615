<template>
  <Dialog
    :header="'Order Imagery'"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :maximizable="false"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <div class="cart-confirm">
      <section>
        <div class="page">
          <div class="nesttabs">
            <div class="container">
              <div class="row">
                <div class="col-12 p-0 nesttabscontent">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade h-100 active show"
                      :id="'v-pills-geohub'"
                      role="tabpanel"
                      :aria-labelledby="'v-pills-geohub-tab'"
                    >
                      <GeoHubTab
                        ref="geohubTab"
                        :propCartName="cart.name"
                        :propPriorityLevel="propPriorityLevel"
                        :prop-images="propImages"
                        :propButton="propButton"
                        @message="onMessage"
                        @onOrderGeohub="onOrderGeohub"
                        @orderNameChanged="orderNameChanged"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Dialog>
</template>
<script>
import { VueTabs, VTab } from "vue-nav-tabs";
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import Checkbox from "@/components/commons/checkbox/Checkbox";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import RadioButton from "@/components/commons/radiobutton/RadioButton";
import Textarea from "@/components/commons/textarea/Textarea";
import AoiPreview from "./order/AoiPreview";
import PlanetBundles from "./order/PlanetBundles.vue";
import PlanetTab from "./order/Planet";
import OneAtlasTab from "./order/OneAtlas";
import GeoHubTab from "./order/GeoHub";
import CapellaTab from "./order/Capella";
export default {
  components: {
    VueTabs,
    VTab,
    Dialog,
    Button,
    Checkbox,
    Dropdown,
    RadioButton,
    Textarea,
    AoiPreview,
    PlanetBundles,
    PlanetTab,
    GeoHubTab,
    OneAtlasTab,
    CapellaTab,
  },
  props: {
    propData: {
      type: Array,
      default: () => [],
    },
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "480px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propPriorityLevel: {
      type: Array,
      default: () => [],
    },
    propProcessingLevel: {
      type: Array,
      default: () => [],
    },
    propButton: {
      type: Object,
      default: () => {},
    },
    propImages: {
      type: Array,
      default: () => [],
    },
    propFeature: {
      type: Object,
      default: null,
    },
    propPlanetImages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      cart: {
        name: null,
        address: null,
        description: null,
        note: null,
        email: null,
        full_name: null,
        image_ids: [],
        organization: null,
        phone_number: null,
        processing_level: null,
        priority_id: null,
        planet: {
          order_type: "full",
          bundles: [],
        },
      },
      value: false,
      planetItemTypesSelected: [],
      planetOrderTypes: [
        {
          id: "full",
          key: "full",
          label: "Full",
        },
        {
          id: "partial",
          key: "partial",
          label: "Partial",
        },
      ],
      selectedTab: null,
      tabs: [
        {
          id: "planet",
          label: "Planet",
          visible: false,
        },
        {
          id: "oneatlas",
          label: "OneAtlas",
          visible: false,
        },
        {
          id: "capella",
          label: "Capella",
          visible: false,
        },
        {
          id: "geohub",
          label: "GeoHub",
          visible: false,
        },
      ],
    };
  },
  async created() {
    this.show = this.propShow;
    if (!this.propPriorityLevel || this.propPriorityLevel.length === 0) {
      await this.$emit("getAllPriorityLevel");
    }
    this.planetItemTypesSelected = [
      ...new Set(this.propPlanetImages.map((x) => x.properties.item_type)),
    ];
    this.tabs[0].visible =
      this.propImages.filter((x) => x.type === "PLANET").length > 0;
    if (this.tabs[0].visible && !this.selectedTab) this.selectedTab = "planet";
    this.tabs[1].visible =
      this.propImages.filter((x) => x.type === "ONEATLAS").length > 0;
    if (this.tabs[1].visible && !this.selectedTab)
      this.selectedTab = "oneatlas";
    this.tabs[2].visible =
      this.propImages.filter((x) => x.type === "CAPELLA").length > 0;
    if (this.tabs[2].visible && !this.selectedTab) this.selectedTab = "capella";
    this.tabs[3].visible =
      this.propImages.filter((x) => x.type === "SATELLITE").length > 0;
    if (this.tabs[3].visible && !this.selectedTab) this.selectedTab = "geohub";
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
    propPriorityLevel: {
      handler(val) {
        if (val && val.length > 0) {
          if (this.propPriorityLevel && this.propPriorityLevel.length > 0) {
            let item = this.propPriorityLevel.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.cart.priority_id = item.id;
            }
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    orderNameChanged(val) {
      this.cart.name = val;
    },
    onMessage(data) {
      this.$emit("message", data);
    },
    onOrderPlanet(data) {
      //this.$emit("onOrderPlanet", data);
      this.$emit("onOrderAll", data);
    },
    onOrderOneAtlas(data) {
      //this.$emit("onOrderOneAtlas", data);
      this.$emit("onOrderAll", data);
    },
    onOrderCapella(data) {
      //this.$emit("onOrderCapella", data);
      this.$emit("onOrderAll", data);
    },
    onOrderGeohub(data) {
      //this.$emit("onOrderGeohub", data);
      this.$emit("onOrderAll", data);
    },
    showDialogPriceDetail(data) {
      this.$emit("showDialogPriceDetail", data);
    },
    clearCart(tab) {
      switch (tab) {
        case "PLANET":
          this.tabs[0].visible = false;
          if (this.$refs.planetTab) {
            this.$refs.planetTab.clearCart();
          }
          if (this.tabs[1].visible) {
            this.selectedTab = "oneatlas";
          } else if (this.tabs[2].visible) {
            this.selectedTab = "capella";
          } else if (this.tabs[3].visible) {
            this.selectedTab = "geohub";
          } else this.selectedTab = null;
          break;
        case "ONEATLAS":
          this.tabs[1].visible = false;
          if (this.$refs.oneatlasTab) {
            this.$refs.oneatlasTab.clearCart();
          }
          if (this.tabs[0].visible) {
            this.selectedTab = "planet";
          } else if (this.tabs[2].visible) {
            this.selectedTab = "capella";
          } else if (this.tabs[3].visible) {
            this.selectedTab = "geohub";
          } else this.selectedTab = null;
          break;
        case "CAPELLA":
          this.tabs[2].visible = false;
          if (this.$refs.capellaTab) {
            this.$refs.capellaTab.clearCart();
          }
          if (this.tabs[0].visible) {
            this.selectedTab = "planet";
          } else if (this.tabs[1].visible) {
            this.selectedTab = "oneatlas";
          } else if (this.tabs[3].visible) {
            this.selectedTab = "geohub";
          } else this.selectedTab = null;
          break;
        case "SATELLITE":
          this.tabs[3].visible = false;
          if (this.$refs.geohubTab) {
            this.$refs.geohubTab.clearCart();
          }
          if (this.tabs[0].visible) {
            this.selectedTab = "planet";
          } else if (this.tabs[1].visible) {
            this.selectedTab = "oneatlas";
          } else if (this.tabs[2].visible) {
            this.selectedTab = "capella";
          } else this.selectedTab = null;
          break;
      }
      if (
        !this.tabs[0].visible &&
        !this.tabs[1].visible &&
        !this.tabs[2].visible &&
        !this.tabs[3].visible
      )
        this.onClose();
    },
    onClose() {
      this.show = false;
      this.$emit("close");
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.cart-confirm {
  padding: 0.25rem;
  background-color: white;
  .nesttabs a.nav-link {
    background-color: #eef1f6;
    border: 1px solid #cfd6e1;
    border-bottom: 0;
    border-radius: 0px;
    color: #000;
    z-index: 1;

    &:first-child {
      border-top-left-radius: 0.25rem;
    }
    &:last-child {
      border-bottom-left-radius: 0.25rem;
      border-bottom: 1px solid #cfd6e1;
    }
  }
  .nesttabs a.nav-link.active {
    background-color: #fff;
    z-index: 10000;
    box-shadow: 0px 18px 12px -12px rgba(226, 231, 237, 1);
    border-right: #fff;
    h6 {
      color: $background-color-2;
    }
  }
  .nesttabscontent {
    padding-left: 0;
  }
}
</style>
