<template>
  <div class="wrapper-spinner" v-if="propLoading">
    <div class="_2Kueb7SbeqLd8bUSbd0 circle" v-if="shape == 'circle'">
      <circle-spinner
        :loading="propLoading"
        :height="propHeight"
        :width="propWidth"
        :color="'#fd853e'"
      ></circle-spinner>
      <span class="spinner-text" v-if="propText">{{ propText }}</span>
    </div>
    <div class="_2Kueb7SbeqLd8bUSbd0 square" v-else-if="shape == 'square'">
      <square
        :loading="propLoading"
        :height="propHeight"
        :width="propWidth"
        :color="propColor"
      ></square>
      <span class="spinner-text" v-if="propText">{{ propText }}</span>
    </div>
    <div class="_2Kueb7SbeqLd8bUSbd0 tile" v-else-if="shape == 'tile'">
      <tile
        :loading="propLoading"
        :height="propHeight"
        :width="propWidth"
        :color="propColor"
      ></tile>
      <span class="spinner-text" v-if="propText">{{ propText }}</span>
    </div>
    <div class="_2Kueb7SbeqLd8bUSbd0 pulse" v-else-if="shape == 'pulse'">
      <pulse
        :loading="propLoading"
        :height="propHeight"
        :width="propWidth"
        :color="propColor"
      ></pulse>
      <span class="spinner-text" v-if="propText">{{ propText }}</span>
    </div>
    <div class="_2Kueb7SbeqLd8bUSbd0 fold" v-else-if="shape == 'fold'">
      <fold
        :loading="propLoading"
        :height="propHeight"
        :width="propWidth"
        :color="propColor"
      ></fold>
      <span class="spinner-text" v-if="propText">{{ propText }}</span>
    </div>
    <div class="_2Kueb7SbeqLd8bUSbd0 cube" v-else-if="shape == 'cube'">
      <cube
        :loading="propLoading"
        :height="propHeight"
        :width="propWidth"
        :color="propColor"
      ></cube>
      <span class="spinner-text" v-if="propText">{{ propText }}</span>
    </div>
    <div class="_2Kueb7SbeqLd8bUSbd0 bounce" v-else-if="shape == 'bounce'">
      <bounce
        :loading="propLoading"
        :height="propHeight"
        :width="propWidth"
        :color="propColor"
      ></bounce>
      <span class="spinner-text" v-if="propText">{{ propText }}</span>
    </div>
    <div class="_2Kueb7SbeqLd8bUSbd0 circle" v-else>
      <circle-spinner
        :loading="propLoading"
        :height="propHeight"
        :width="propWidth"
        :color="propColor"
      ></circle-spinner>
      <span class="spinner-text">{{ propText }}</span>
    </div>
  </div>
</template>
<script>
import {
  SquareSpinner,
  BounceSpinner,
  CircleSpinner,
  CubeSpinner,
  FoldSpinner,
  PulseSpinner,
  TileSpinner,
} from "vue-spinners";
export default {
  name: "spinner",
  components: {
    Square: SquareSpinner,
    Bounce: BounceSpinner,
    CircleSpinner: CircleSpinner,
    Cube: CubeSpinner,
    Fold: FoldSpinner,
    Pulse: PulseSpinner,
    Tile: TileSpinner,
  },
  props: {
    propLoading: {
      type: Boolean,
      default: true,
    },
    propShape: {
      type: String,
      default: "",
    },
    propHeight: {
      type: String,
      default: "40",
    },
    propWidth: {
      type: String,
      default: "40",
    },
    propColor: {
      type: String, //Hex color value
      default: "fd853e",
    },
    propText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      shape: "circle",
    };
  },
  created() {
    this.getShape(this.propShape);
  },
  watch: {
    propShape: function (val) {
      this.getShape(val);
    },
  },
  methods: {
    getShape: function (shape) {
      if (
        ![
          "circle",
          "square",
          "tile",
          "pulse",
          "fold",
          "cube",
          "bounce",
        ].includes(shape)
      ) {
        this.shape = "circle";
      } else {
        this.shape = shape;
      }
    },
  },
};
</script>
<style scoped>
.wrapper-spinner {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}
._2Kueb7SbeqLd8bUSbd0 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
<style>
.wrapper-spinner .square .spinner {
  background-image: url("/img/logo3.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: rgba(0, 0, 0, 0.005);
}
.spinner {
  margin: 0 auto !important;
}
</style>
