<template>
  <div
    v-if="data && Object.keys(data).length"
    class="item-detail"
    @mouseover="mouseOverItem(data)"
    @mouseleave="mouseLeaveItem(data)"
  >
    <div>
      <strong
        :title="
          data.properties &&
          data.properties.acquisitionIdentifier &&
          data.properties.acquisitionIdentifier.length > 54
            ? data.properties.acquisitionIdentifier
            : ''
        "
        >{{
          data.properties.acquisitionIdentifier &&
          data.properties.acquisitionIdentifier.length > 54
            ? data.properties.acquisitionIdentifier.substring(0, 54) + "..."
            : data.properties.acquisitionIdentifier
        }}
      </strong>
    </div>
    <div class="media">
      <icon-preview
        :propId="
          data.properties && data.properties.activityId
            ? data.properties.activityId
            : null
        "
        :prop-src="
          data._links && data._links.thumbnail && data._links.thumbnail.href
            ? data._links.thumbnail.href
            : null
        "
        @initSuccessIconImage="initSuccessIconImage(data, $event)"
        @showPreviewImage="showPreviewImage(data)"
      ></icon-preview>
      <div class="media-body">
        <ul>
          <li class="mt-1">
            <div class="">
              <span
                >Order date:
                <strong>{{
                  propItem.createdAt ? propItem.createdAt : ""
                }}</strong></span
              >
              <span class="pl-2 pr-2">|</span>
              <span
                >{{ $t("label_satellite") }}:
                <strong>{{
                  data.properties && data.properties.platform
                    ? data.properties.platform
                    : ""
                }}</strong></span
              >
            </div>
          </li>
          <li class="mt-1">
            <div class="">
              <span
                >Acquisition date:
                <strong>{{
                  data.properties && data.properties.acquisitionDate
                    ? data.properties.acquisitionDate
                    : ""
                }}</strong></span
              >
            </div>
          </li>
          <ItemMetadata :prop-item="data" />

          <li class="mt-1" v-if="message">
            <div class="">
              <span class="text-danger"
                >Message: <strong>{{ message }}</strong></span
              >
            </div>
          </li>
        </ul>
        <div class="actions text-right mt-1">
          <button
            v-if="downloadUrl"
            type="button"
            class="btn btn-transparent2 btn-sm float-left ml-0"
            :title="'Download'"
            @click="downloadImage"
          >
            <i class="fas fa-download"></i>&nbsp;Download
          </button>
          <button
            type="button"
            class="btn btn-transparent2 btn-sm mr-1"
            :title="'Metadata'"
            @click.stop="showMetadata(data)"
          >
            <i class="fas fa-eye"></i>
          </button>
          <button
            type="button"
            class="btn btn-transparent2 btn-sm ml-auto"
            :title="$t('tooltip_pan_2_coordinate')"
            :disabled="!data.geometry"
            @click.stop="onPanCoordinate(data)"
          >
            <i class="fas fa-crosshairs"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import oneAtlasFunc from "@/utils/functions/oneatlas";
import ItemMetadata from "./item/ItemMetadata";
import IconPreview from "./item/IconPreview";
import { fromLonLat, transform } from "ol/proj";
import { Style, Fill, Stroke } from "ol/style.js";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
export default {
  components: { ItemMetadata, IconPreview },
  props: {
    propItem: {
      type: Object,
      default: () => {},
    },
    map: {
      required: true,
    },
  },
  data() {
    return {
      data: {},
      message: "",
      layer: Object,
      styles: {
        footprint: new Style({
          fill: new Fill({
            color: "rgba(0,158,247,0.3)",
          }),
          stroke: new Stroke({
            color: "rgba(0,158,247,1)",
            width: 1.25,
          }),
        }),
        footprintHover: new Style({
          fill: new Fill({
            color: "rgba(0,0,255,0.25)",
          }),
          stroke: new Stroke({
            color: "rgba(0,158,247,1)",
            width: 1.25,
          }),
        }),
        image: new Style({
          fill: new Fill({
            color: "rgba(0,0,0,0)",
          }),
          stroke: new Stroke({
            color: "rgba(0,0,0,0.8)",
            width: 1.25,
          }),
        }),
      },
    };
  },
  created() {
    if (this.propItem && Object.keys(this.propItem).length > 0) {
      this.message =
        this.propItem.deliveries &&
        this.propItem.deliveries.length > 0 &&
        this.propItem.deliveries[0].message
          ? this.propItem.deliveries[0].message
          : null;
      let id = null;
      try {
        id =
          this.propItem.deliveries &&
          this.propItem.deliveries.length > 0 &&
          this.propItem.deliveries[0].datas
            ? this.propItem.deliveries[0].datas.id
            : null;
      } catch (error) {}
      if (id) this.getImageById(id);
    }
  },
  methods: {
    showMetadata() {
      this.$emit("actionClick", {
        code: "SHOW_METADATA_ONEATLAS",
        val: this.data,
      });
    },
    downloadImage() {
      this.$emit("downloadImage", {
        url: this.downloadUrl,
        acquisitionIdentifier:
          this.data.properties && this.data.properties.acquisitionIdentifier
            ? this.data.properties.acquisitionIdentifier
            : null,
      });
    },
    addFeature(layer, coordinates, style, id) {
      let feature = layer.getSource().getFeatureById(id);
      if (!feature) {
        var points = [];
        try {
          for (var i = 0; i < coordinates.length; i++) {
            points[i] = transform(coordinates[i], "EPSG:4326", "EPSG:3857");
          }
          feature = new Feature({
            geometry: new Polygon([points]),
            id: id,
          });
          feature.set("id", id);
          feature.id_ = id;
          feature.setStyle(style);
          layer.getSource().addFeature(feature);
        } catch (err) {
          console.log(err);
        }
      } else {
        feature.style = { visibility: "visible" };
      }
    },
    getLayerDraw() {
      if (Object.keys(this.map).length > 0) {
        if (Object.keys(this.layer).length === 0) {
          var layers = this.map.getLayers().array_;
          if (layers.length > 0) {
            for (let i = 0; i < layers.length; i++) {
              if (layers[i].get("id") === "layerdraw") {
                this.layer = layers[i];
                break;
              }
            }
          }
          if (Object.keys(this.layer).length === 0) {
            var source = new VectorSource({ wrapX: false });
            this.layer = new VectorLayer({
              source: source,
              style: [this.styles.footprint],
            });
            this.layer.set("id", "layerdraw");
            this.layer.setZIndex(this.map.getLayers().length);
            this.map.addLayer(this.layer);
          }
        }
      }
      return this.layer;
    },
    async mouseOverItem(val) {
      if (!this.layer || Object.keys(this.layer).length === 0)
        await this.getLayerDraw();
      try {
        let feature = this.layer
          .getSource()
          .getFeatureById("hover_" + val.properties.id);
        if (!feature) {
          let coors = [];
          if (
            val.geometry &&
            val.geometry.coordinates &&
            val.geometry.coordinates.length > 0
          ) {
            for (let i = 0; i < val.geometry.coordinates[0].length; i++) {
              coors.push(val.geometry.coordinates[0][i]);
            }
          }
          this.addFeature(
            this.layer,
            coors,
            this.styles.footprintHover,
            "hover_" + val.properties.id
          );
        }
      } catch (error) {}
    },
    async mouseLeaveItem(val) {
      if (!this.layer || Object.keys(this.layer).length === 0)
        await this.getLayerDraw();
      try {
        let feature = this.layer
          .getSource()
          .getFeatureById("hover_" + val.properties.id);
        if (feature) {
          this.layer.getSource().removeFeature(feature);
        } else {
        }
      } catch (error) {}
    },
    onPanCoordinate(val) {
      try {
        if (
          val &&
          val.properties &&
          val.properties.geometryCentroid &&
          Object.keys(val.properties.geometryCentroid).length > 0
        ) {
          let zoomLevel = this.map.getView().getZoom();
          this.map.getView().animate({
            center: fromLonLat([
              val.properties.geometryCentroid[0],
              val.properties.geometryCentroid[1],
            ]),
            duration: 2000,
            zoom: zoomLevel < 16 ? zoomLevel + 1 : zoomLevel,
          });
        } else {
          if (
            val.geometry &&
            val.geometry.coordinates &&
            val.geometry.coordinates.length > 0
          ) {
            if (val.geometry.coordinates[0].length > 3) {
              let coordinate = [
                (val.geometry.coordinates[0][0][0] +
                  val.geometry.coordinates[0][2][0]) /
                  2,
                (val.geometry.coordinates[0][0][1] +
                  val.geometry.coordinates[0][2][1]) /
                  2,
              ];
              let zoomLevel = this.map.getView().getZoom();
              this.map.getView().animate({
                center: fromLonLat(coordinate),
                duration: 2000,
                zoom: zoomLevel < 16 ? zoomLevel + 1 : zoomLevel,
              });
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getDetail(url) {
      this.$emit("onloaded", this.propItem.id);
      try {
        let response = await oneAtlasFunc.getOrderDetail(
          url,
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );
        if (response && response.status === 200) {
          if (
            response.data &&
            response.data.features &&
            response.data.features.length > 0
          ) {
            this.data = response.data.features[0];
          }
        }
      } catch (error) {
        console.log(error);
      }
      this.$emit("onloaded", this.propItem.id);
    },
    async getImageById(id) {
      this.$emit("onloaded", {
        image_id: id,
        item_id: this.propItem.id,
      });
      try {
        let response = await oneAtlasFunc.getImageById(
          id,
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );
        if (response && response.status === 200) {
          if (
            response.data &&
            response.data.features &&
            response.data.features.length > 0
          ) {
            this.data = response.data.features[0];
          }
        }
      } catch (error) {
        console.log(error);
      }
      this.$emit("onloaded", {
        image_id: id,
        item_id: this.propItem.id,
      });
    },
  },
  computed: {
    downloadUrl() {
      return this.propItem.deliveries &&
        this.propItem.deliveries.length > 0 &&
        this.propItem.deliveries[0]._links &&
        Object.keys(this.propItem.deliveries[0]._links).length > 0 &&
        this.propItem.deliveries[0]._links.download &&
        this.propItem.deliveries[0]._links.download.href
        ? this.propItem.deliveries[0]._links.download.href
        : null;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.list-group {
  overflow-y: auto;
}
.item-detail {
  font-size: 12px;
  padding: 0.5rem;
  border: 0;
  border-radius: 0;
  border-top: 1px solid #eff2f5;
  &:hover {
    cursor: pointer;
    background-color: rgba($color: #c7e7f9, $alpha: 0.5);
  }
  &.active {
    background-color: rgba(255, 165, 0, 0.5);
    color: $background-color;
  }
  &.fadeOut {
    transition: background-color 2s linear;
    -moz-transition: background-color 2s linear;
    -webkit-transition: background-color 2s linear;
    -ms-transition: background-color 2s linear;
    background-color: transparent;
    color: $background-color;
  }
  .media {
    span {
      font-size: 12px !important;
    }
  }
}
.media {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  button {
    margin-left: 0.25rem;
    &.close {
      span {
        font-size: 24rem;
      }
    }
  }
}
button {
  &.close {
    span {
      font-size: 24px;
    }
  }
  &.btn-export:hover {
    color: white !important;
  }
}
</style>
