<template>
  <div class="coors_radius">
    <h6 class="title">{{ $t("label_center_coordinates") }}</h6>
    <div class="row no-gutters">
      <div class="col">
        <label for="inputRadiuslat" class="col-form-label w-100">{{
          $t("label_latitude")
        }}</label>
        <InputText
          type="number"
          id="i834H2r42"
          class="form-control new-style"
          @input="constrainUserInput('i834H2r42')"
          maxlength="9"
          min="0"
          max="180"
          step="0.000001"
          v-model="lat"
        />
      </div>

      <div class="col">
        <label for="inputRadiusLng" class="col-form-label w-100"
          >{{ $t("label_longitude") }}
        </label>
        <InputText
          type="number"
          id="i834H2r44"
          class="form-control new-style"
          @input="constrainUserInput('i834H2r44')"
          maxlength="10"
          min="0"
          max="180"
          step="0.00001"
          v-model="lng"
        />
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 col-md-6 pr-1">
        <label for="inputRadius" class="col-form-label w-100"
          >{{ $t("label_radius") }} (Km)</label
        >
        <InputText
          type="number"
          id="i834H2r45"
          class="form-control new-style"
          @input="constrainUserInput('i834H2r45')"
          maxlength="4"
          min="0"
          max="6371"
          step="0.01"
          v-model="radius"
        />
      </div>
    </div>
  </div>
</template>
<script>
import InputText from "@/components/commons/inputtext/InputText";
import InputNumber from "@/components/commons/inputnumber/InputNumber";
export default {
  components: {
    InputText,
    InputNumber,
  },
  props: {
    propCentre: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      lat: null,
      lng: null,
      radius: null,
    };
  },
  created() {
    if (this.propCentre) {
      this.lat = this.propCentre.lat ? this.propCentre.lat : null;
      this.lng = this.propCentre.lng ? this.propCentre.lng : null;
      this.radius = this.propCentre.radius ? this.propCentre.radius : null;
    }
  },
  watch: {
    propCentre(val) {
      if (val) {
        this.lat = val.lat ? val.lat : null;
        this.lng = val.lng ? val.lng : null;
        this.radius = val.radius ? val.radius : null;
      }
    },
  },
  methods: {
    latChange() {
      this.$emit("pointChange", {
        lat: this.lat,
        lng: this.lng,
      });
    },
    lngChange() {
      this.$emit("pointChange", {
        lat: this.lat,
        lng: this.lng,
      });
    },
    radiusChange() {
      this.$emit("radiusChange", this.radius);
    },
    onInputRadius(e) {},
    constrainUserInput(id) {
      let input = document.getElementById(id);
      let value = input.value;
      if (value.length > input.maxLength) {
        input.value = value.substring(0, input.maxLength);
      }
      this.$emit("centreChange", {
        lat: this.lat ? parseFloat(this.lat) : null,
        lng: this.lat ? parseFloat(this.lng) : null,
        radius: this.lat ? parseFloat(this.radius) : null,
      });
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    locate() {
      if ((this.lang = "en")) {
        return "en-US";
      } else {
        return "vi-VN";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.coors_radius {
  padding-right: 0.5rem;
  color: $text-color;
  h6.title {
    margin: 0;
    font-size: 14px;
    color: $text-color;
    font-weight: 500;
  }
  .row {
    margin: 0;
    padding: 0;
    .col {
      padding: 0;
      padding-right: 0.25rem;
      &:last-child {
        padding-left: 0.25rem;
        padding-right: 0;
      }
    }
  }
}
</style>
