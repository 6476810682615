<template>
  <div class="relative">
    <div class="images-spinners Iybw1Bhj3Jhd8s" v-if="loading">
      <TileSpinner
        :loading="loading"
        :height="'40'"
        :width="'40'"
        :color="'fd853e'"
        class="ehAbnmg4Jwb9BS"
      ></TileSpinner>
    </div>
    <ul class="list-group">
      <li class="list-group-item" v-for="item in propItems" :key="item.id">
        <ItemComponent
          :prop-item="item"
          :map="map"
          @actionClick="actionClick"
          @onloaded="onloaded"
        />
      </li>
    </ul>
  </div>
</template>
<script>
import { TileSpinner } from "vue-spinners";
import ItemComponent from "./Item.vue";
export default {
  components: {
    ItemComponent,
    TileSpinner,
  },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    map: {
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      loaded: [],
    };
  },
  methods: {
    actionClick(data) {
      this.$emit("actionClick", data);
    },
    async onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (
        scrollTop > 0 &&
        Math.ceil(scrollTop + clientHeight) >= scrollHeight
      ) {
        this.$emit("loadMore", "error");
      }
    },
    onloaded(data) {
      let index = this.loaded.findIndex(
        (x) => x.item_id === data.item_id && x.image_id === data.image_id
      );
      if (index < 0) {
        this.loaded.push({
          image_id: data.image_id,
          item_id: data.item_id,
          loaded: false,
        });
      } else {
        this.loaded[index].loaded = true;
      }
      if (this.loaded.filter((x) => x.loaded).length < this.propItems.length)
        this.loading = true;
      else this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.relative {
  position: relative;
  height: 640px;
}
.Iybw1Bhj3Jhd8s {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
  .ehAbnmg4Jwb9BS {
    display: table-cell;
    vertical-align: middle;
  }
}
.list-group {
  height: 640px;
  overflow: auto;
  .list-group-item {
    padding: 0;
    border: 0;
    &:first-child {
      .item-detail {
        border-top: 0;
      }
    }
  }
}
</style>
