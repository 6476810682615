import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/Layout.vue";
import Home from "../views/Home.vue";
import Guide from "../views/Guide.vue";
import Signup from "../views/Signup.vue";
import Login from "../views/Login.vue";

import User from "../views/User.vue";
import UserInfo from "@/components/pages/user/info/Information";
import UserChangePass from "@/components/pages/user/info/ChangePass";
import UserOrdered from "@/components/pages/user/Ordered";
import UserShared4Me from "@/components/pages/user/Shared4Me";

import MyOrdered from "@/components/pages/user/MyOrdered";
import MyOrderedTasking from "@/components/pages/user/MyOrderedTasking";
import MyOrderedFree from "@/components/pages/user/MyOrderedFree";

import MyProject from "@/components/pages/user/MyProject";
import StatisticUpload from "@/components/pages/user/StatisticUpload";
import StatisticDownload from "@/components/pages/user/StatisticDownload";

import StatisticReport from "@/components/pages/user/StatisticReport";

import IntegratePlanet from "@/components/pages/user/IntegratePlanet";
import IntegratePlanetList from "@/components/pages/user/integrateplanet/List";
import IntegratePlanetDetail from "@/components/pages/user/integrateplanet/Detail";

import IntegrateOneAtlas from "@/components/pages/user/IntegrateOneAtlas";
import IntegrateOneAtlasList from "@/components/pages/user/integrateoneatlas/List";

import TaskingIntegrate from "@/components/pages/user/Tasking";
import TaskingOneAtlas from "@/components/pages/user/tasking/TaskingOneAtlas";

import SatelliteOrbit from "../views/SatelliteOrbit";

import Error404 from "@/components/pages/error/404";
import MySelf404 from "@/components/pages/user/Error404";

// import TestTasking from "../views/TestTasking";

import { store } from "../store";

const ifAuthenticated = (to, from, next) => {
  var promise = store.getters.loggedIn;
  if (!promise) next("/");
  else {
    next();
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
    ],
  },
  // {
  //   path: "/test",
  //   name: "TestTasking",
  //   component: TestTasking,
  // },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  // {
  //   path: "/user-manual",
  //   name: "Manual",
  //   component: Guide,
  // },
  {
    path: "/satellite-orbit",
    name: "SatelliteOrbit",
    component: SatelliteOrbit,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },

  {
    path: "/myself",
    component: User,
    children: [
      {
        path: "",
        name: "UserInfo",
        component: UserInfo,
      },
      {
        path: "change-pass",
        name: "UserChangePass",
        component: UserChangePass,
      },
      {
        path: "shared-for-me",
        name: "UserShared4Me",
        component: UserShared4Me,
      },
      {
        path: "my-order-tasking",
        name: "MyOrderedTasking",
        component: MyOrderedTasking,
      },
      {
        path: "my-order",
        name: "MyOrdered",
        component: MyOrdered,
      },
      {
        path: "my-order/:id",
        name: "UserOrdered",
        component: UserOrdered,
      },
      {
        path: "statistic-upload",
        name: "StatisticUpload",
        component: StatisticUpload,
      },
      {
        path: "statistic-download",
        name: "StatisticDownload",
        component: StatisticDownload,
      },
      {
        path: "statistic",
        name: "StatisticReport",
        component: StatisticReport,
      },
      {
        path: "integrate-planet",
        name: "IntegratePlanet",
        component: IntegratePlanet,
        redirect: {
          name: "IntegratePlanetList",
        },
        children: [
          {
            path: "",
            name: "IntegratePlanetList",
            component: IntegratePlanetList,
          },
          {
            path: ":id",
            name: "IntegratePlanetDetail",
            component: IntegratePlanetDetail,
          },
        ],
      },
      {
        path: "integrate-oneatlas",
        name: "IntegrateOneAtlas",
        component: IntegrateOneAtlas,
        redirect: {
          name: "IntegrateOneAtlasList",
        },
        children: [
          {
            path: "",
            name: "IntegrateOneAtlasList",
            component: IntegrateOneAtlasList,
          },
        ],
      },
      {
        path: "tasking",
        component: TaskingIntegrate,
        redirect: {
          name: "TaskingOneAtlas",
        },
        children: [
          {
            path: "airbus",
            name: "TaskingOneAtlas",
            component: TaskingOneAtlas,
          },
        ],
      },

      {
        path: "*",
        name: "MySelf404",
        component: MySelf404,
      },
    ],
  },
  {
    path: "*",
    name: "Error404",
    component: Error404,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
