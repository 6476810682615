<template>
  <div class="m-2">
    <div class="form-group">
      <label class="control-label">{{ $t("label_query_file") }}</label>
      <div class="custom-file">
        <input
          type="file"
          accept=".json"
          class="custom-file-input"
          @change="changeFile"
        />
        <label class="custom-file-label" for="customFile">{{
          propData.file ? propData.file.name : ""
        }}</label>
      </div>
    </div>
    <div class="list-criteria">
      <div class="table-responsive" :style="{maxHeight: tableHeight +'px'}">
        <table class="table table-bordered table-query">
          <tbody>
            <tr v-for="item in fields.filter(x=>x.value && x.visible)" :key="item.key">
              <td>{{ item.label }}</td>
              <td>{{ item.text }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    propData: {
      type: Object,
      default: () => {
        return {
          file: null,
          fields:[]
        };
      },
    },
  },
  data() {
    return {
      fields:[
        {
          key:"image_time_from",
          label:this.$t('label_date_from'),
          value:null,
          text:null,
          visible:true
        },
        {
          key:"image_time_to",
          label:this.$t('label_date_to'),
          value:null,
          text:null,
          visible:true
        },
        {
          key:"resolution",
          label:this.$t('label_resolution') + ' ('+this.$t('label_resolution_unit')+')',
          value:null,
          text:null,
          visible:true
        },
        {
          key:"incidence_angle",
          label:this.$t('label_incidence_angle') + ' ('+this.$t('label_incidence_angle_unit')+')',
          value:null,
          text:null,
          visible:true
        },
        {
          key:"processing_level",
          label:this.$t('label_processing_level'),
          value:null,
          text:null,
          visible:true
        },
        {
          key:"satellites",
          label:this.$t('label_satellite'),
          value:null,
          text:null,
          visible:true
        },
        {
          key:"cloud_cove",
          label:this.$t('label_cloud_cove')+ ' ('+this.$t('label_cloud_cove_unit')+')',
          value:null,
          text:null,
          visible:true
        },
        {
          key:"imaging_mode",
          label:this.$t('label_image_mode'),
          value:null,
          text:null,
          visible:true
        },
        {
          key:"direction",
          label:this.$t('label_direction'),
          value:null,
          text:null,
          visible:true
        },
        {
          key:"pol_layer",
          label:this.$t('label_pol_layer'),
          value:null,
          text:null,
          visible:true
        },
        {
          key:"boundary",
          label:this.$t('label_boundary'),
          value:null,
          text:null,
          visible:true
        },
        {
          key:"selectedKey",
          label:"selectedKey",
          value:null,
          text:null,
          visible:false
        },
      ]
    };
  },
  computed:{
    documentHeight(){
      return this.$store.getters.getDocumentHeight
    },
    tableHeight(){
      return this.documentHeight - 22.5 * 16
    }
  },
  methods: {
    changeFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      else {
        for (let i = 0; i < this.fields.length; i++) {
          this.fields[i].value = null;
          this.fields[i].text = null;          
        }
        let vm = this;
        vm.propData.file = files[0];
        var reader = new FileReader();
        reader.onload = async function () {
          try {
            let obj = JSON.parse(reader.result);
            for (const [key, value] of Object.entries(obj)) {
              let index = vm.fields.findIndex( x=>x.key === key)
              try {
                if( index >= 0){
                  vm.fields[index].value = value.value,                  
                  vm.fields[index].text = value.text
                }
              } catch (error2) {} 
            }
          } catch (error) {}
          vm.propData.fields = vm.fields
        };
        reader.readAsText(files[0]);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.table-responsive{
  overflow-y: auto;
}
table.table-query {
  tr {
    td {
      padding: 0.5rem 1rem;
      //border: 1px solid white;
      &:first-child {
        background: #0677be;
        color: white;
        width: 15rem;
      }
    }
  }
}
</style>
