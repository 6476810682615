<template>
  <div class="select-options">
    <div class="delivery-mode">
      <h6>Global Order Options</h6>
      <div class="d-flex justify-content-between align-items-center">
        <div class="form-group row m-0 select-mode">
          <label class="col-sm-6 col-form-label">Delivery Mode</label>
          <div class="col-sm-6">
            <input
              v-if="!isModifyDeliveryMode"
              type="text"
              readonly
              class="form-control-plaintext font-weight-light pl-2"
              :value="deliveryTypeLabel"
            />
            <select
              v-else
              class="form-control w-100"
              v-model="propEntity.deliveryType"
            >
              <option
                v-for="type in propDeliveryTypes"
                :key="type.deliveryTypeId"
                :value="type.deliveryTypeId"
              >
                {{ type.label }}
              </option>
            </select>
          </div>
        </div>
        <button
          :disabled="propDisableActions"
          class="btn btn-modify"
          @click="isModifyDeliveryMode = !isModifyDeliveryMode"
        >
          <i class="fas fa-pen"></i>&nbsp;<span>{{
            isModifyDeliveryMode ? "Confirm" : " Modify"
          }}</span>
        </button>
      </div>
    </div>
    <CommonAccordion ref="acordionOptions">
      <div slot="accordion-header" class="accordion-header w-100">
        <a
          class="d-flex justify-content-between align-items-center"
          :class="accordion.active ? 'active' : ''"
        >
          <span>{{
            propProductTypeSelected && propProductTypeSelected.technicalProduct
              ? propProductTypeSelected.technicalProduct.label
              : ""
          }}</span>
          <div>
            Price&nbsp;
            <i class="fas fa-spinner fa-spin" v-if="propPrice.loading"></i>
            <span
              class="listed-price"
              v-if="propPrice && propPrice.discountValue > 0"
              v-html="
                getCurrencyCode(propPrice ? propPrice.currency : null) +
                (propPrice ? propPrice.discountValue : null)
              "
            ></span>
            <strong v-if="propPrice && !propPrice.loading">
              <em
                v-html="getCurrencyCode(propPrice ? propPrice.currency : null)"
              ></em>
              {{ price2Pay | numberFormat }}</strong
            >
            <small v-if="propPrice && !propPrice.loading"
              >&nbsp;(&#45;{{
                propPrice ? propPrice.discountPercentage : ""
              }}&#37;)</small
            >
          </div>
          <span v-html="'Invoid Surface: ' + propEntity.area"></span>
          <div @click="onCollapse">
            <span
              class="pi pi-chevron-down float-right"
              v-if="accordion.active"
            ></span>
            <span class="pi pi-chevron-right float-right" v-else></span>&nbsp;
          </div>
        </a>
      </div>
      <div slot="content" class="p-3">
        <div class="options">
          <div class="left">
            <div
              class="form-group row"
              v-for="option in propEntity.options"
              :key="option.name"
            >
              <label class="col-sm-6 col-form-label">{{
                convertNameLabel(option.label)
              }}</label>
              <div class="col-sm-6">
                <input
                  v-if="!isModifyOptions"
                  type="text"
                  readonly
                  class="form-control-plaintext font-weight-light pl-2"
                  :value="getLabelOption(option.selectedValue, option.values)"
                />
                <select
                  v-else
                  class="form-control mt-1 mb-1 w-100"
                  v-model="option.selectedValue"
                >
                  <option
                    v-for="item in option.values"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.label }}
                  </option>
                </select>
              </div>
            </div>
            <button
              class="btn btn-modify"
              :disabled="propDisableActions"
              @click="onModifyOptions"
            >
              <i class="fas fa-pen"></i>&nbsp;{{
                isModifyOptions ? "Confirm" : " Modify"
              }}
            </button>
          </div>
          <div class="right">
            <h5 class="title">
              Polygon
              <span v-html="propEntity.area"></span>
            </h5>
            <div v-if="['oneday'].includes(this.target)">
              <RightOneDay
                :propEntity="propEntity"
                :prop-coordinate-system="coordinateSystem"
              />
            </div>
            <div v-else-if="['onenow'].includes(this.target)">
              <RightOneNow
                :prop-entity="propEntity"
                :prop-coordinate-system="coordinateSystem"
              />
            </div>
            <div v-else-if="['oneplan'].includes(this.target)">
              <RightOnePlan
                :prop-entity="propEntity"
                :prop-coordinate-system="coordinateSystem"
                :propFeasibilityOneAtlas="propFeasibilityOneAtlas"
              />
            </div>
            <div v-else-if="['oneseries'].includes(this.target)">
              <RightOneSeries
                :prop-entity="propEntity"
                :prop-coordinate-system="coordinateSystem"
              />
            </div>
          </div>
        </div>
      </div>
    </CommonAccordion>
  </div>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import RightOneDay from "./options/RightOneDay.vue";
import RightOneNow from "./options/RightOneNow.vue";
import RightOnePlan from "./options/RightOnePlan.vue";
import RightOneSeries from "./options/RightOneSeries.vue";
export default {
  components: {
    CommonAccordion,
    Dropdown,
    RightOneDay,
    RightOneNow,
    RightOnePlan,
    RightOneSeries,
  },
  props: {
    propDeliveryTypes: {
      type: Array,
      default: () => [],
    },
    propProductOptions: {
      type: Array,
      default: () => [],
    },
    propProductTypeSelected: {
      type: Object,
      default: () => {},
    },
    propPrice: {
      type: Object,
      default: () => {},
    },
    propEntity: {
      type: Object,
      default: () => {},
    },
    // Response from api
    propFeasibilityOneAtlas: {
      type: Array,
      default: () => [],
    },
    propDisableActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isModifyOptions: false,
      isModifyDeliveryMode: false,
      accordion: {
        active: true,
      },
    };
  },
  mounted() {
    if (
      !this.propEntity.deliveryType &&
      this.propDeliveryTypes &&
      this.propDeliveryTypes.length > 0
    )
      this.propEntity.deliveryType = this.propDeliveryTypes[0].deliveryTypeId;
  },
  methods: {
    onModifyOptions() {
      if (this.propDisableActions) return;
      this.isModifyOptions = !this.isModifyOptions;
      if (!this.isModifyOptions) {
        this.$emit("getPrice");
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    convertNameLabel(name) {
      if (!name) return;
      name = name.trim();
      let array = name.split("_");
      for (let i = 0; i < array.length; i++) {
        if (!array[i]) continue;
        array[i] = this.capitalizeFirstLetter(array[i].trim().toLowerCase());
      }
      name = array.join(" ");
      return name;
    },
    getLabelOption(id, values) {
      let item = values.find((x) => x.id === id);
      return item && Object.keys(item).length > 0 ? item.label : null;
    },
    onCollapse() {
      this.accordion.active = !this.accordion.active;
      if (this.$refs.acordionOptions)
        this.$refs.acordionOptions.onHeaderClick();
    },
    getCurrencyCode(txt) {
      let result = "&euro;";
      switch (txt) {
        case "EUR":
          result = "&euro;";
          break;
        case "DOLLAR":
          result = "&#36;";
          break;
        case "POUND":
          result = "&#163;";
          break;
        case "YEN":
          result = "&#165;";
          break;
        case "CENT":
          result = "&#162;";
          break;
        case "RUPEE":
          result = "&#8360;";
          break;
        case "DONG":
          result = "&#8363;";
          break;
        default:
          result = txt;
          break;
      }
      return result;
    },
  },
  computed: {
    deliveryTypeLabel() {
      let item = this.propDeliveryTypes.find(
        (x) => x.deliveryTypeId === this.propEntity.deliveryType
      );
      return item ? item.label : null;
    },
    coordinateSystem() {
      let item = this.propProductOptions.find((x) => x.name === "projection_1");
      if (
        item &&
        Object.keys(item).length > 0 &&
        item.values &&
        item.values.length
      ) {
        let defaultItem = item.values.find((x) => x.id === item.defaultValue);
        return defaultItem && Object.keys(defaultItem).length > 0
          ? defaultItem.label
          : null;
      }
      return null;
    },
    extendedAngle() {
      let item = this.propProductOptions.find(
        (x) => x.name === "extendedAngle"
      );
      return item && Object.keys(item).length ? item.defaultValue : null;
    },
    target() {
      if (
        this.propProductTypeSelected &&
        Object.keys(this.propProductTypeSelected).length > 0
      ) {
        return this.propProductTypeSelected.technicalProduct.target.id;
      }
      return null;
    },
    totalAmount() {
      return this.propPrice &&
        Object.keys(this.propPrice).length &&
        this.propPrice.totalAmount
        ? this.propPrice.totalAmount
        : 0;
    },
    discountValue() {
      return this.propPrice &&
        Object.keys(this.propPrice).length &&
        this.propPrice.discountValue
        ? this.propPrice.discountValue
        : 0;
    },
    price2Pay() {
      return this.totalAmount - this.discountValue >= 0
        ? this.totalAmount - this.discountValue
        : 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.select-options {
  height: auto;
  display: grid;
  grid-auto-columns: 1fr;
  gap: 1rem;
  .delivery-mode {
    background-color: #eef3f7;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 0 1.5rem 0 1rem;
    margin: auto 0;
    height: 6rem;
    h6 {
      margin: 0;
      margin-top: 1rem;
    }
    .select-mode {
      padding-left: 1.5rem;
      min-width: 600px;

      input {
        box-shadow: none;
        outline: none;
      }
    }
    button {
      &.btn-modify {
        color: white;
        width: 10rem;
        background-color: #64748b;
        border: 1px solid #475569;
        font-size: 14px;
        i {
          font-size: 10px;
        }
      }
    }
  }
  .options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    .left {
      .form-group {
        margin: 0;
        padding: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.075);
        .col-sm-6 {
          &:last-child {
            padding: 0;
          }
          .form-control-plaintext {
            font-family: "Helvetica Neue Light";
          }

          input {
            box-shadow: none;
            outline: none;
          }
        }
      }
      button {
        margin: 0.375rem auto;
        display: block;
        justify-content: center;
        &.btn-modify {
          color: white;
          width: 10rem;
          background-color: #64748b;
          border: 1px solid #475569;
          font-size: 14px;
          i {
            font-size: 10px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.tasking-checkout {
  .select-options {
    .accordion-header a {
      color: white;
      background: $background-color-2;
      border: 1px solid $background-color-2;
      border-radius: 0.25rem;
      span {
        color: white !important;
      }
      &.active {
        color: white;
        background: $background-color-2;
        border: 1px solid $background-color-2;
        span {
          color: white;
        }
      }
    }
    .accordion-content {
      &.in {
        border: 1px solid rgba(0, 0, 0, 0.125);
        background-color: #eef3f7;
      }
    }
  }
}
</style>
