<template>
  <div class="shared4me">
    <div class="shared4me__dialog">
      <DialogMetadata
        v-if="dialogMetadata.show"
        :prop-style="{ width: dialogMetadata.width }"
        :prop-show="dialogMetadata.show"
        :prop-title="dialogMetadata.title"
        :prop-item="dialogMetadata.value"
        @close="
          dialogMetadata.show = false;
          dialogMetadata.value = null;
        "
        @initSuccessPreviewImange="initSuccessPreviewImange"
      />

      <DialogPassword
        v-if="dialogPassword.show"
        :prop-style="{ width: dialogPassword.width }"
        :prop-show="dialogPassword.show"
        @close="dialogPassword.show = false"
        @onDownload="downloadWithPass"
        @message="message = $event"
      />
      <DialogMessage
        v-if="message.length > 0"
        :propTitle="$t('title_message_info')"
        :prop-show="message.length > 0"
        :prop-message="message"
        @close="message = []"
      />
    </div>
    <div class="shared4me__search" id="elementSearch">
      <CommonAccordion
        :propTitle="$t('title_search')"
        @accordionHeaderClick="accordionHeaderClick"
      >
        <div slot="content">
          <list-search
            :prop-search="share4Me.search"
            :prop-button="buttons.filter"
            @onSearch="onSearch"
            @message="message = $event"
          ></list-search>
        </div>
      </CommonAccordion>
    </div>
    <div class="shared4me__datatable">
      <CommonAccordion :propTitle="$t('title_image_list')">
        <div slot="content">
          <DataTable
            :prop-items="share4Me.items"
            :prop-loading="share4Me.loading"
            :prop-table-height="share4Me.table.height"
            :prop-total="share4Me.total_records"
            @downloadItem="downloadItem"
            :propButtonDownload="share4Me.buttons.download"
            @showDialogMetadata="
              dialogMetadata.show = true;
              dialogMetadata.value = $event;
            "
            @nextPage="nextPage"
          ></DataTable>
        </div>
      </CommonAccordion>
    </div>
  </div>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion3";
import ListSearch from "./list/Search";
import DataTable from "./list/DataTable";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import DialogPassword from "./list/DialogPassword.vue";
import sharingFunc from "@/utils/functions/sharing";
import meControllerFunc from "@/utils/functions/mecontroller";
import imageSharingFunc from "@/utils/functions/imagesharing";
import imageFunc from "@/utils/functions/image";
import { change_alias, string_all_trim } from "@/utils/commons/common";
import DialogMetadata from "@/components/commons/metadata/Metadata"; //"./list/Metadata.vue";
import cryptoFunc from "@/utils/functions/crypto";
import oauthFunc from "@/utils/functions/oauth";
import axios from "axios";
export default {
  components: {
    ListSearch,
    DataTable,
    CommonAccordion,
    DialogMetadata,
    DialogMessage,
    DialogPassword,
  },
  data() {
    return {
      message: [],
      share4Me: {
        buttons: {
          download: {
            disable: false,
            processing: false,
            id: null,
          },
        },
        items: [],
        table: {
          selected: null,
          height: 400,
        },
        search: {
          page: 0,
          size: 10,
          from: null,
          to: null,
          image_name: null,
        },
        is_bottom: false,
        loading: false,
        total_page: 0,
        total_records: 0,
        page_number: 0,
        page_size: 0,
      },
      dialogMetadata: {
        show: false,
        title: "Metadata",
        value: null,
        width: "480px",
      },
      dialogPassword: {
        show: false,
        width: "480px",
        value: null,
      },
      buttons: {
        filter: {
          disabled: false,
          processing: false,
          visible: true,
        },
      },
    };
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    tableHeight() {
      let element = document.getElementById("elementSearch");
      return (
        this.documentHeight -
        56 -
        (element ? element.offsetHeight : 0) -
        40 -
        4 -
        51
      );
    },
  },
  created() {
    var vm = this;
    if (vm.documentWidth < 576) {
      this.dialogMetadata.width = this.documentWidth + "px";
    } else if (vm.documentWidth >= 576 && vm.documentWidth < 768) {
      this.dialogMetadata.width = "540px";
    } else if (vm.documentWidth >= 768 && vm.documentWidth < 992) {
      this.dialogMetadata.width = "720px";
    } else {
      this.dialogMetadata.width = "960px";
    }
    this.share4Me.search.size = Math.ceil(this.tableHeight / 45) + 5;
    this.getImages();
  },
  mounted() {
    this.share4Me.table.height = this.calcTableHeight();
  },
  methods: {
    accordionHeaderClick() {
      setTimeout(() => {
        this.share4Me.table.height = this.calcTableHeight();
      }, 100);
    },
    calcTableHeight() {
      let element = document.getElementById("elementSearch");
      return (
        this.documentHeight -
        3 * 0.5 * 16 -
        56 -
        (element ? element.offsetHeight : 0) -
        24 -
        2 * 0.5 * 16
      );
    },
    downloadWithPass(password) {
      this.downloadItem(this.dialogPassword.value, password);
      setTimeout(() => {
        this.dialogPassword.show = false;
        this.dialogPassword.value = null;
      }, 200);
    },
    initSuccessPreviewImange(data) {
      let index = this.share4Me.items.findIndex((x) => x.image.id === data.id);
      if (index >= 0) {
        this.share4Me.items[index].image.preview_src = data.val;
      }
    },
    downloadItem(val, password) {
      if ((val.isPass || val.is_pass) && !password) {
        this.dialogPassword.value = val;
        this.dialogPassword.show = true;
        return;
      }
      if (val.download_number >= 0) {
        let checkCancel = false;
        if (val.expired_time) {
          try {
            let expiredTime = new Date(val.expired_time);
            if (expiredTime.getTime() - new Date() < 0) {
              checkCancel = true;
              this.message.push(this.$t("message_file_down_expired"));
            }
          } catch (error) {}
        }
        if (!checkCancel)
          this.downloadImage({
            password: password,
            token: val.token,
            image_id: val.image.id,
            image: val.image,
            id: val.id,
          });
      } else {
        this.message.push(this.$t("message_file_down_out_of_number"));
      }
      let index = this.share4Me.items.findIndex((x) => x.id === val.id);
      if (index >= 0) {
        this.share4Me.items[index].download_number =
          this.share4Me.items[index].download_number - 1;
      }
    },

    async downloadImage(item) {
      try {
        this.share4Me.buttons.download.disable = true;
        this.share4Me.buttons.download.processing = true;
        this.share4Me.buttons.download.id = item.id;
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: true,
            image: item.image,
          },
        ]);
        let response = await imageSharingFunc.download2(
          item.password ? item.password : null,
          item.token ? item.token : null,
          item.image_id,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          //if (response.data.type.toLowerCase() === "file") {
          if (response.data.url) {
            window.open(response.data.url, "_blank");
          } else if (response.data.type.toLowerCase() === "folder") {
            this.downloadFolderAsZip({
              url: response.data.url,
              // .replace("http://", "http:/")
              // .replace("https://", "https:/")
              // .replace("//", "/")
              // .replace("http:/", "http://")
              // .replace("https:/", "https://"),
              method: "post",
              data: response.data.data,
              image: item.image,
            });
          }
        } else {
          this.message.push(
            this.$t("message_download_file_error", null, {
              name: item.image.name,
            })
          );
          let indexIcrement = this.share4Me.items.findIndex(
            (x) => x.image && x.image.id === item.image_id
          );
          if (indexIcrement >= 0) {
            this.share4Me.items[indexIcrement].download_number =
              this.share4Me.items[indexIcrement].download_number + 1;
          }
        }

        this.share4Me.buttons.download.disable = false;
        this.share4Me.buttons.download.processing = false;
        this.share4Me.buttons.download.id = null;
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: item.image,
          },
        ]);
      } catch (error) {
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: item.image,
          },
        ]);

        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.downloadImage, item);
        } else {
          let msg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msg
              ? msg
              : this.$t("message_download_file_error", null, {
                  name: item.image.name,
                })
          );
          let indexIcrement = this.share4Me.items.findIndex(
            (x) => x.image && x.image.id === item.image_id
          );
          if (indexIcrement >= 0) {
            this.share4Me.items[indexIcrement].download_number =
              this.share4Me.items[indexIcrement].download_number + 1;
          }
        }
        this.share4Me.buttons.download.disable = false;
        this.share4Me.buttons.download.processing = false;
        this.share4Me.buttons.download.id = null;
      }
    },
    async downloadFolderAsZip(data) {
      this.$store.dispatch("setImagesDownload", [
        {
          is_add: true,
          image: data.image,
        },
      ]);
      try {
        let response = await imageFunc.downloadFolderAsZip(data.url, data.data);
        if (response.status === 200) {
          let contentType =
            response.headers && response.headers["content-type"]
              ? response.headers["content-type"]
              : "application/zip";
          let url,
            blob = new Blob([response.data], { type: contentType });
          let extension = "";
          try {
            extension = contentType.substring(
              contentType.indexOf("/") + 1,
              contentType.length
            );
          } catch (error2) {}
          if (blob.size === 0) {
            this.message.push(this.$t("message_file_dose_not_exist"));
          } else {
            try {
              if (!window.navigator.msSaveOrOpenBlob) {
                // BLOB NAVIGATOR
                url = window.URL.createObjectURL(blob);
              } else {
                // BLOB FOR EXPLORER 11
                url = window.navigator.msSaveOrOpenBlob(
                  blob,
                  change_alias(data.image.name) + ".zip" //(extension ? "."+extension :".zip")
                );
              }
              const linkdown = document.createElement("a");
              linkdown.href = url;
              linkdown.setAttribute(
                "download",
                change_alias(data.image.name) + ".zip"
              ), //(extension ? "."+extension :".zip"));
                document.body.appendChild(linkdown);
              linkdown.click();
              document.body.removeChild(linkdown);
              window.URL.revokeObjectURL(url);
            } catch (error3) {}
          }
        } else {
          this.message.push(
            this.$t("message_download_file_error", null, {
              name: data.image.name,
            })
          );
        }
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: data.image,
          },
        ]);
      } catch (error) {
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: data.image,
          },
        ]);
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.downloadFolderAsZip, data);
        } else {
          //let responseObj = await error.response.data.text();
          if (
            error.request &&
            error.request.responseType === "blob" &&
            error.response.data instanceof Blob &&
            error.response.data.type &&
            error.response.data.type.toLowerCase().indexOf("json") != -1
          ) {
            let reader = new FileReader();
            reader.onload = () => {
              let responseObj = JSON.parse(reader.result);
              let msg =
                responseObj && responseObj.message ? responseObj.message : null;
              this.message.push(
                msg
                  ? msg
                  : this.$t("message_download_file_error", null, {
                      name: data.image.name ? data.image.name : null,
                    })
              );
            };
            reader.readAsText(error.response.data);
          } else {
            let msg =
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : null;
            this.message.push(
              msg
                ? msg
                : this.$t("message_download_file_error", null, {
                    name: data.image.name,
                  })
            );
          }
        }
      }
    },
    async downloadMyZipFile(val) {
      this.$store.dispatch("setImagesDownload", [
        {
          is_add: true,
          image: val.image,
        },
      ]);
      try {
        let response = await imageSharingFunc.download2(
          val.password ? val.password : null,
          val.token,
          val.image_id,
          this.$store.getters.getAccessToken
        );
        if (response.status === 200) {
          let contentType =
            response.headers && response.headers["content-type"]
              ? response.headers["content-type"]
              : "application/zip";
          let url,
            blob = new Blob([response.data], { type: contentType });
          let extension = "";
          try {
            extension = contentType.substring(
              contentType.indexOf("/") + 1,
              contentType.length
            );
          } catch (error2) {}
          if (blob.size === 0) {
            this.message.push(this.$t("message_file_dose_not_exist"));
          } else {
            try {
              if (!window.navigator.msSaveOrOpenBlob) {
                // BLOB NAVIGATOR
                url = window.URL.createObjectURL(blob);
              } else {
                // BLOB FOR EXPLORER 11
                url = window.navigator.msSaveOrOpenBlob(
                  blob,
                  change_alias(val.image.name) + ".zip" //(extension ? "."+extension :".zip")
                );
              }
              const linkdown = document.createElement("a");
              linkdown.href = url;
              linkdown.setAttribute(
                "download",
                change_alias(val.image.name) + ".zip"
              ), //(extension ? "."+extension :".zip"));
                document.body.appendChild(linkdown);
              linkdown.click();
              document.body.removeChild(linkdown);
              window.URL.revokeObjectURL(url);
            } catch (error3) {
              this.message.push(
                this.$t("message_download_file_error", null, {
                  name: val.image.name ? val.image.name : null,
                })
              );
            }
          }
        } else {
          this.message.push(
            this.$t("message_download_file_error", null, {
              name: val.image.name ? val.image.name : null,
            })
          );
        }
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: val.image,
          },
        ]);
      } catch (error) {
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: val.image,
          },
        ]);
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.downloadMyZipFile, val);
        } else {
          //let responseObj = await error.response.data.text();
          if (
            error.request &&
            error.request.responseType === "blob" &&
            error.response.data instanceof Blob &&
            error.response.data.type &&
            error.response.data.type.toLowerCase().indexOf("json") != -1
          ) {
            if (error.response && error.response.status === 403) {
              let index = this.share4Me.items.findIndex((x) => x.id === val.id);
              if (index >= 0) {
                this.share4Me.items[index].download_number =
                  this.share4Me.items[index].download_number + 1;
              }
            }
            let reader = new FileReader();
            reader.onload = () => {
              let responseObj = JSON.parse(reader.result);
              let msg =
                responseObj && responseObj.message ? responseObj.message : null;
              this.message.push(
                msg
                  ? msg
                  : this.$t("message_download_file_error", null, {
                      name: val.image.name ? val.image.name : null,
                    })
              );
            };
            reader.readAsText(error.response.data);
          } else {
            let msg =
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : null;
            this.message.push(
              msg
                ? msg
                : this.$t("message_download_file_error", null, {
                    name: val.image.name ? val.image.name : null,
                  })
            );
          }
        }
      }
    },

    nextPage() {
      if (!this.share4Me.is_bottom) {
        this.share4Me.search.page = this.share4Me.search.page + 1;
        this.getImages();
      }
    },
    onSearch(data) {
      this.share4Me.search.from = data.from ? data.from : null;
      this.share4Me.search.to = data.to ? data.to : null;
      this.share4Me.search.image_name = data.image_name
        ? data.image_name.trim()
        : null;
      this.share4Me.search.page = 0;
      this.share4Me.items = [];
      this.share4Me.is_bottom = false;
      this.getImages();
    },
    async getImages() {
      if (this.share4Me.is_bottom) return;
      let params = {
        page: this.share4Me.search.page,
        size: this.share4Me.search.size,
      };
      this.share4Me.loading = true;
      this.buttons.filter.disabled = true;
      this.buttons.filter.processing = true;
      try {
        let response = await meControllerFunc.getImagesShare4Me(
          params,
          {
            create_time_from: this.share4Me.search.from
              ? new Date(this.share4Me.search.from)
              : null,
            create_time_to: this.share4Me.search.to
              ? new Date(this.share4Me.search.to)
              : null,
            image_name: this.share4Me.search.image_name
              ? this.share4Me.search.image_name.trim().replace(/\s\s+/g, " ")
              : null,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.share4Me.items = [
            ...this.share4Me.items,
            ...(response.data.content_page ? response.data.content_page : []),
          ];
          this.share4Me.page_number = response.data.page_number;
          this.share4Me.page_size = response.data.page_size;
          this.share4Me.total_page = response.data.total_page;
          this.share4Me.total_records = response.data.total_records;
          if (
            (this.share4Me.items.length === this.share4Me.total_records &&
              response.data.total_page > 1) ||
            (response.data.total_page === 1 &&
              this.share4Me.items.length <= response.data.page_size)
          )
            this.share4Me.is_bottom = true;
          else this.share4Me.is_bottom = false;
          this.share4Me.items.sort(function (a, b) {
            if (new Date(a.created_at) < new Date(b.created_at)) return 1;
            else if (new Date(a.created_at) > new Date(b.created_at)) return -1;
            return 0;
          });
        }
        this.share4Me.loading = false;
        this.buttons.filter.disabled = false;
        this.buttons.filter.processing = false;
      } catch (error) {
        this.share4Me.loading = false;
        this.buttons.filter.disabled = false;
        this.buttons.filter.processing = false;
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getImages, null);
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>
<style lang="scss" scoped>
.shared4me {
  padding: 0.5rem;
  .shared4me__search {
    padding: 0;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .shared4me__datatable {
    margin-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
}
</style>
