<template>
  <div class="datatable">
    <DataTable
      :value="propItems"
      ref="dt"
      :resizableColumns="true"
      columnResizeMode="expand"
      selectionMode="single"
      dataKey="user.id"
      contextMenu
    >
      <template #empty v-if="propItems && propItems.length === 0">
        <span class="text-center">{{ $t("label_no_data") }}</span>
      </template>
      <Column
        header="No"
        headerStyle="width: 3rem; text-align: center!important"
        bodyStyle="text-align: center;"
      >
        <template #body="slotProps">
          {{ slotProps.index + 1 }}
        </template></Column
      >
      <Column header="Member">
        <template #body="slotProps">
          {{ slotProps.data.user.full_name }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
export default {
  components: {
    DataTable,
    Column,
  },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.datatable {
  padding: 0.25rem;
}
</style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
.my-project__member {
  .p-datatable {
    border-radius: 0.25rem;
    .p-datatable-thead > tr > {
      th {
        background: white;
        border: none;
        color: #a1a5b7;
        border-bottom: 1px solid #eff2f5;
      }
    }
    .p-datatable-tbody > tr > {
      td {
        border: none;
        border-bottom: 1px solid #eff2f5;
        color: $text-color;
      }
    }
  }
}
</style>