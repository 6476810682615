<template>
  <div class="list-group">
    <div
      class="list-group-item"
      v-for="attempt in attempts4Show"
      :key="attempt.id"
      @mouseover="mouseOverItem(attempt.segment)"
      @mouseleave="mouseLeaveItem(attempt.segment)"
    >
      <div class="attempt-item">
        <div class="item-text">
          <p class="d-flex justify-content-between align-items-center">
            <strong>{{ propAttempts[0].progTypes[0].mission }}</strong>
            <span
              >{{ $t("label_deadline") }}:
              {{ formatGMTOrderDeadline(attempt.segment.orderDeadline) }}
              | UTC:
              {{ formatUTCOrderDeadline(attempt.segment.orderDeadline) }}</span
            >
          </p>

          <div class="list-group">
            <div
              class="list-group-item"
              v-for="item in attempt.items"
              :key="'item' + item.id"
            >
              <span
                ><strong
                  >{{
                    formatUTCAcquisitionStartDate(item.acquisitionStartDate)
                  }}&nbsp;</strong
                >(UTC)&nbsp;|&nbsp;<strong
                  >{{ $t("label_incidence_angle") }}:</strong
                >&nbsp;{{ item.incidenceAngle.toFixed(2) }}&deg; - 50&deg;</span
              >
            </div>
          </div>
        </div>
        <div class="item-buttons">
          <button
            class="btn border"
            :class="
              propSelected &&
              propSelected.findIndex((x) => x.id === attempt.segment.id) >= 0
                ? 'active'
                : ''
            "
            @click="selectAttempt(attempt)"
          >
            <i class="fas fa-shopping-cart"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    propAttempts: {
      type: Array,
      default: () => [],
    },
    propSelected: {
      type: Array,
      default: () => [],
    },
    propFootprints: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      segments: [],
    };
  },
  created() {
    this.segments =
      this.propAttempts && this.propAttempts.length
        ? this.propAttempts[0].progTypes[0].segments
        : [];
  },
  watch: {
    propAttempts(val) {
      if (val) {
      }
    },
  },
  methods: {
    formatUTCAcquisitionStartDate(val) {
      try {
        moment.locale(this.lang);
        let date = new Date(val);
        return moment(date).utc().format("lll");
      } catch (error) {}
      return null;
    },
    formatUTCOrderDeadline(val) {
      try {
        moment.locale(this.lang);
        let date = new Date(val);
        return moment(date).utc().format("l LT");
      } catch (error) {}
      return null;
    },
    formatGMTOrderDeadline(val) {
      try {
        moment.locale(this.lang);
        let date = new Date(val);
        return moment(date).format("l LT");
      } catch (error) {}
      return null;
    },
    selectAttempt(data) {
      this.$emit("selectAttempt", data);
    },
    addFootprint(item) {
      this.$emit("addFootprint", item);
    },
    mouseOverItem(item) {
      this.$emit("mouseOverItem", item);
    },
    mouseLeaveItem(item) {
      this.$emit("mouseLeaveItem", item);
    },
  },
  computed: {
    products() {
      if (this.attempts4Show && this.attempts4Show.length > 0) {
      }
    },
    attempts4Show() {
      let results = [];
      let segmentsClone = Object.assign([], this.segments);
      try {
        for (let k = 0; k < segmentsClone.length; k++) {
          let items = [];
          let segmentKey = segmentsClone[k].segmentKey
            ? segmentsClone[k].segmentKey
            : null;
          if (segmentKey) {
            let arraySplit = segmentKey.split("::");
            if (arraySplit && arraySplit.length === 2) {
              try {
                let values = JSON.parse(arraySplit[1]);
                if (values && values.length > 0) {
                  let obj = values.find((x) => x.key === "acqPeriods");
                  let acqPeriods =
                    obj && Object.keys(obj).length && obj["value"]
                      ? obj["value"].split(",")
                      : [];
                  for (let i = 0; i < segmentsClone.length; i++) {
                    if (
                      acqPeriods.includes(
                        segmentsClone[i].acquisitionStartDate +
                          " " +
                          segmentsClone[i].acquisitionEndDate
                      )
                    ) {
                      items.push(segmentsClone[i]);
                    }
                  }
                }
              } catch (error2) {}
            }
          }
          if (items.length === 3) {
            results.push({
              id: segmentsClone[k].id,
              segment: segmentsClone[k],
              items: items,
            });
            segmentsClone.splice(k, 1);
            k = k - 1;
          }
        }
      } catch (error) {
        console.log(error);
      }

      return results;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.list-group {
  list-style: none;
  .list-group-item {
    border-color: #eef3f7;
    padding: 0.5rem 0.75rem;
    .attempt-item {
      display: grid;
      grid-template-columns: [first] auto [line2] 2.5rem [end];
      gap: 0.5rem;
      .item-text {
        p {
          margin-bottom: 0.5rem;
        }
        .list-group {
          list-style: none;
          margin: 0;
          padding: 0;
          .list-group-item {
            font-size: 13px;
            border: none;
            border-top: 1px solid #eee;
            padding: 0.25rem 0;
            &:first-child {
              border-top: 0;
            }
          }
        }
      }
      .item-buttons {
        justify-content: center;
        margin: auto;
        button {
          width: 1.75rem;
          height: 1.75rem;
          font-size: 12px;
          margin-right: 0.25rem;
          line-height: 0;
          padding: 0;
          &:last-child {
            margin-right: 0;
          }
          &.active {
            background: rgba(0, 158, 247, 0.8);
            border: 1px solid rgba(0, 158, 247, 1);
            color: white;
          }
        }
      }
    }
  }
}
</style>
