import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { userServiceApiURL } from "@/utils/commons/environment"
export default {
    async info(access_token){
        const object = axios({
            method:basicApi.user_me.info.method,
            url: userServiceApiURL + basicApi.user_me.info.url,           
            headers: {
                "Authorization": "bearer " + access_token,
                "Content-Type": "application/json",
                'Accept-Language':app.$store.getters.getLang
            },
            params: {
            }
        });
        let response = await object;
        return response;
    },
    async create(entity, captcha) {
        let headers = basicApi.user_me.create.headers;
        //headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        let params = Object.assign({}, entity);
        if (!params) params = {};
        params.captcha_key = captcha;
        const object = axios({
            method: basicApi.user_me.create.method,
            url:  userServiceApiURL  +
            basicApi.user_me.create.url,
            data: entity,
            params:params,
            headers: headers
        });
        let response = await object;
        return response;
    },
    async update(entity, access_token) {
        let headers = basicApi.user_me.update.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        let params ;//= Object.assign({}, entity);
        if (!params) params = {};
        const object = axios({
            method: basicApi.user_me.update.method,
            url:  userServiceApiURL  +
            basicApi.user_me.update.url,
            data: entity,
            params:params,
            headers: headers
        });
        let response = await object;
        return response;
    },
    async changePass(entity, access_token) {
        let headers = basicApi.user_me.change_pass.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        let params = Object.assign({}, entity);
        if (!params) params = {};
        const object = axios({
            method: basicApi.user_me.change_pass.method,
            url:  userServiceApiURL  +
            basicApi.user_me.change_pass.url,
            data: entity,
            params:params,
            headers: headers
        });
        let response = await object;
        return response;
    },
    async resetPassword(email, access_token) {
        let headers = basicApi.me_controller.reset_password.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        let params = {
            email:email
        };
        const object = axios({
            method: basicApi.me_controller.reset_password.method,
            url:  userServiceApiURL  +
            basicApi.me_controller.reset_password.url,
            params:params,
            headers: headers
        });
        let response = await object;
        return response;
    },
}