<template>
  <li>
    <div class="mt-1" v-if="imageType === 'OPTICAL'">
      <span
        >Res ({{ resolutionUnit }}):<strong>{{ resolution }}</strong></span
      >
      <span class="pl-1 pr-1">|</span>
      <span
        >Inc Ang ({{ incidenceAngleUnit }}):
        <strong>{{ incidenceAngle }}</strong></span
      >
      <span class="pl-1 pr-1">|</span>
      <span
        >Cloud ({{ cloudCoverageUnit }}):
        <strong>{{ cloudCoverage }}</strong></span
      >
    </div>
    <div class="mt-1" v-else-if="imageType === 'RADAR'">
      <span
        >Look dir:<strong>{{ direction }}</strong></span
      >
      <span class="pl-1 pr-1">|</span>
      <span
        >Pol: <strong>{{ polLayer }}</strong></span
      >
      <span class="pl-1 pr-1">|</span>
      <span
        >Ord dir: <strong>{{ orbitDirection }}</strong></span
      >
    </div>
  </li>
</template>
<script>
export default {
  props: {
    propItem: {
      type: Object,
      default: () => {},
    },
    propImageTypes: {
      type: Array,
      default: () => [],
    },
  },
  created() {},
  computed: {
    incidenceAngle() {
      let result = "";
      if (this.propItem && this.propItem.properties) {
        let incidenceAngle = this.propItem.properties.incidenceAngle;
        if (incidenceAngle != null && incidenceAngle != undefined)
          result = incidenceAngle.toFixed(4);
      }
      return result;
    },

    incidenceAngleUnit() {
      let result = "°";
      return result;
    },
    cloudCoverage() {
      let result = "";
      if (this.propItem && this.propItem.properties) {
        let cloudCover = this.propItem.properties.cloudCover;
        if (cloudCover != null && cloudCover != undefined)
          result = cloudCover.toFixed(4);
      }
      return result;
    },

    cloudCoverageUnit() {
      let result = "%";
      return result;
    },
    resolution() {
      let result = "";
      if (this.propItem && this.propItem.properties) {
        let resolution = this.propItem.properties.resolution;
        if (resolution != null && resolution != undefined) result = resolution;
      }
      return result;
    },
    resolutionUnit() {
      let result = "m";
      return result;
    },
    polLayer() {
      let result = null;
      if (
        this.propItem &&
        Object.keys(this.propItem).length > 0 &&
        this.propItem.metadata
      ) {
        let object = this.propItem.metadata
          ? this.propItem.metadata.find(
              (x) => x.key === "pol_layer" || x.key === "polarisation"
            )
          : null;
        result =
          object && object.value
            ? (object.value + "").trim().replace(/\s\s+/g, " ")
            : null;
      }
      return result;
    },

    direction() {
      let result = null;
      if (
        this.propItem &&
        Object.keys(this.propItem).length > 0 &&
        this.propItem.metadata
      ) {
        let object = this.propItem.metadata
          ? this.propItem.metadata.find(
              (x) => x.key === "direction" || x.key === "look_direction"
            )
          : null;
        result =
          object && object.value
            ? (object.value + "").trim().replace(/\s\s+/g, " ")
            : null;
      }
      return result;
    },

    beamMode() {
      let result = null;
      if (
        this.propItem &&
        Object.keys(this.propItem).length > 0 &&
        this.propItem.metadata
      ) {
        let object = this.propItem.metadata
          ? this.propItem.metadata.find(
              (x) => x.key === "beam_mode" || x.key === "mode"
            )
          : null;
        result =
          object && object.value
            ? (object.value + "").trim().replace(/\s\s+/g, " ")
            : null;
      }
      return result;
    },

    orbitDirection() {
      let result = null;
      if (
        this.propItem &&
        Object.keys(this.propItem).length > 0 &&
        this.propItem.metadata
      ) {
        let object = this.propItem.metadata
          ? this.propItem.metadata.find(
              (x) => x.key === "orbit_direction" || x.key === "orbit"
            )
          : null;
        result =
          object && object.value
            ? (object.value + "").trim().replace(/\s\s+/g, " ")
            : null;
      }
      return result;
    },
    satellite() {},
    imageType() {
      let result = "OPTICAL";
      if (
        this.propItem &&
        this.propItem.properties &&
        this.propItem.properties.sensorType
      )
        result = this.propItem.properties.sensorType;
      return result;
    },
  },
};
</script>
<style lang="scss" scoped></style>
