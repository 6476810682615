
export function getMimeType(extension) {
  let type = {};
  switch(extension){
    case "aac":
      type = { type: "audio/aac" }
      break;
    case "abw":
      type = { type: "application/x-abiword" }
      break;
    case "arc":
      type = { type: "application/x-freearc" }
      break;            
    case "avi":
      type = { type: "video/x-msvideo" }
      break;       
    case "azw":
      type = { type: "application/vnd.amazon.ebook" }
      break;
    case "bin":
      type = { type: "application/octet-stream" }
      break;
    case "bmp":
      type = { type: "image/bmp" }
      break;
    case "bz":
      type = { type: "application/x-bzip" }
      break;
    case "bz2":
      type = { type: "application/x-bzip2" }
      break;
    case "csh":
      type = { type: "application/x-csh" }
      break;            
    case "css":
      type = { type: "text/css" }
      break;       
    case "csv":
      type = { type: "text/csv" }
      break;
    case "doc":
      type = { type: "application/msword" }
      break;
    case "docx":
      type = { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" }
      break;
    //
    case "eot":
      type = { type: "application/vnd.ms-fontobject" }
      break;
    case "epub":
      type = { type: "application/epub+zip" }
      break;
    case "gz":
      type = { type: "application/gzip" }
      break;            
    case "gif":
      type = { type: "image/gif" }
      break;       
    case "htm":
      type = { type: "text/html" }
      break;
    case "html":
      type = { type: "text/html" }
      break;
    case "ico":
      type = { type: "image/vnd.microsoft.icon" }
      break;
    case "ics":
      type = { type: "text/calendar" }
      break;
    case "jar":
      type = { type: "application/java-archive" }
      break;
    case "jpeg":
      type = { type: "image/jpeg" }
      break;            
    case "jpg":
      type = { type: "image/jpeg" }
      break;       
    case "js":
      type = { type: "text/javascript" }
      break;
    case "json":
      type = { type: "application/json" }
      break;
    case "jsonld":
      type = { type: "application/ld+json" }
      break;
    //
    case "mid":
      type = { type: "audio/midi audio/x-midi" }
      break;
    case "midi":
      type = { type: "audio/midi audio/x-midi" }
      break;
    case "mjs":
      type = { type: "text/javascript" }
      break;            
    case "mp3":
      type = { type: "audio/mpeg" }
      break;  
    case "mp4":
      type = { type: "video/mp4" }
      break;       
    case "mpeg":
      type = { type: "video/mpeg" }
      break;
    case "mpkg":
      type = { type: "application/vnd.apple.installer+xml" }
      break;
    case "odp":
      type = { type: "application/vnd.oasis.opendocument.presentation" }
      break;
    case "ods":
      type = { type: "application/vnd.oasis.opendocument.spreadsheet" }
      break;
    case "odt":
      type = { type: "application/vnd.oasis.opendocument.text" }
      break;
    case "oga":
      type = { type: "audio/ogg" }
      break;   
    case "ogg":
      type = { type: "video/ogg" }
      break;           
    case "ogv":
      type = { type: "video/ogg" }
      break;       
    case "ogx":
      type = { type: "application/ogg" }
      break;
    case "opus":
      type = { type: "audio/opus" }
      break;
    case "otf":
      type = { type: "font/otf" }
      break;
    //
    case "png":
      type = { type: "image/png" }
      break;
    case "pdf":
      type = { type: "application/pdf" }
      break;
    case "php":
      type = { type: "application/x-httpd-php" }
      break;
    case "ppt":
      type = { type: "application/vnd.ms-powerpoint" }
      break;
    case "pptx":
      type = { type: "application/vnd.openxmlformats-officedocument.presentationml.presentation" }
      break;
    case "rar":
      type = { type: "application/vnd.rar" }
      break;            
    case "rtf":
      type = { type: "application/rtf" }
      break;       
    case "sh":
      type = { type: "application/x-sh" }
      break;
    case "svg":
      type = { type: "image/svg+xml" }
      break;
    case "swf":
      type = { type: "application/x-shockwave-flash" }
      break;
    //
    case "tar":
      type = { type: "application/x-tar" }
      break;
    case "tif":
      type = { type: "image/tiff" }
      break;
    case "tiff":
      type = { type: "image/tiff" }
      break;
    case "ts":
      type = { type: "video/mp2t" }
      break;
    case "ttf":
      type = { type: "font/ttf" }
      break;
    case "txt":
      type = { type: "text/plain" }
      break;
    case "vsd":
      type = { type: "application/vnd.visio" }
      break;            
    case "wav":
      type = { type: "audio/wav" }
      break;       
    case "weba":
      type = { type: "audio/webm" }
      break;
    case "webm":
      type = { type: "video/webm" }
      break;
    case "webp":
      type = { type: "image/webp" }
      break;
    //
    case "woff":
      type = { type: "font/woff" }
      break;
    case "woff2":
      type = { type: "font/woff2" }
      break;
    case "xhtml":
      type = { type: "application/xhtml+xml" }
      break;
    case "xls":
      type = { type: "application/vnd.ms-excel" }
      break;
    case "xlsx":
      type = { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }
      break;
    case "xml":
      type = { type: "application/xml" }
      break;
    case "xul":
      type = { type: "application/vnd.mozilla.xul+xml" }
      break;            
    case "zip":
      type = { type: "application/zip" }
      break;       
    case "3gp":
      type = { type: "video/3gpp" }
      break;
    case "3g2":
      type = { type: "video/3gpp2" }
      break;
    case "7z":
      type = { type: "application/x-7z-compressed" }
      break;
    default: type = {}; break;
  }
  return type;
}