<template>
    <div class="address">
        
        <div class="address__header">
            <h5 class="address__header-title">
                Địa điểm
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </h5>
        </div>
        <div class="address__body">
            <div class="table-responsive">
                <table class="table table-bordered table-hover">
                    <thead class="thead-geostore">
                        <tr>
                            <th>Địa danh</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in propData.address" :key="item.place_id" :class="item.place_id === selected ? 'active':''">
                            <td>
                                <div @click="selectedAddress(item.place_id)" >
                                    <span class="font-weight-bold">{{ item.display_name }}</span>
                                    <br>
                                    {{ displayBoundingbox(item.boundingbox) }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        
        <div class="address__footer">
          <button class="btn btn-primary" :disabled="!selected" @click="onSelected"><i class="fas fa-check"></i>&nbsp;Chọn</button>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        propData:{
            type:Object,
            default:()=>{
                return{
                    address:[]
                }
            }
        }
    },
    data(){
        return{
            selected:""
        }
    },
    methods:{
        displayBoundingbox(val){
            try {
                //return JSON.stringify([[val[2],val[0]],[val[3],val[0]],[val[3],val[1]],[val[2],val[1]],[val[2],val[0]]])                
                return JSON.stringify(val)
            } catch (error) {
                return JSON.stringify(val)
            }
        },
        selectedAddress(val){
            if(this.selected===val)
                this.selected = "";
            else
                this.selected = val;
        },
        onSelected(){
            this.$emit("selectedAddress",this.propData.address.find(x=>x.place_id === this.selected));
        },
        close(){
            this.$emit("close",{
                component:"LIST_ADDRESS"
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.address{
    height: 592px;
    width: 100%;
    .address__header{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 1rem;
      border-bottom: 1px solid #e9ecef;
      border-top-left-radius: .3rem;
      border-top-right-radius: .3rem;
      .address__header-title{
        width: 100%;
        margin-bottom: 0;
      }
    }
    .address__body{
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1rem;
        padding-bottom: 0;
        max-height:466px;
        overflow-y: auto;
        margin-bottom: 1rem;
        tr{
            &:hover{
                cursor: pointer;
            }
            &.active{
                background: #28a745;
                color: white;
            }
        }
    }
    
    .address__footer{
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 1rem;
        border-top: 1px solid #e9ecef;
    }
}
</style>