<template>
  <div class="outer">
    <DialogConfirm
      v-if="dialogSuccess.show"
      :prop-show="dialogSuccess.show"
      :prop-title="$t('title_message_info')"
      :prop-icon="dialogSuccess.icon"
      :prop-message="dialogSuccess.message"
      :textNo="''"
      :textYes="$t('button_text_close')"
      :propStyle="{ width: dialogSuccess.width }"
      @cancel="closeDialog"
      @confirmed="closeDialog"
    />
    <div class="middle fadeInDown">
      <div class="card card-forgotpass mx-auto">
        <span class="close" @click="onClose"><i class="fas fa-times"></i></span>
        <div class="card-header mx-auto text-center pt-3 pb-4">
          <h3><i class="fas fa-lock fa-4x"></i></h3>
          <h2 class="text-center">{{ $t("label_forget_pass") }}?</h2>
          <p>{{ $t("label_can_reset_pass_here") }}</p>
        </div>
        <div class="card-body">
          <ValidationObserver ref="observerResetPass" v-slot="{ valid }">
            <div class="notification text-danger" v-if="message">
              {{ message }}
            </div>
            <form v-on:keyup.enter="handleReset">
              <div class="form-group form-group__email">
                <ValidationProvider
                  :name="$t('label_email')"
                  :rules="'required'"
                  persist
                >
                  <input
                    id="email"
                    name="email"
                    :placeholder="$t('label_email_address')"
                    class="form-control"
                    type="email"
                    maxlength="255"
                    v-model="email"
                    autofocus
                  />
                </ValidationProvider>
              </div>
              <div class="form-group">
                <button
                  :disabled="!valid || loading"
                  type="button"
                  name="btn"
                  class="btn btn-geostore2 float-right w-100 btn-reset"
                  @click="handleReset"
                >
                  <i class="fa fa-spinner fa-spin" v-if="loading"></i>&nbsp;
                  {{ $t("button_text_reset_pass") }}
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import DialogConfirm from "@/components/commons/dialog/Confirmation2";
import qs from "qs";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import usermecontrollerFunc from "@/utils/functions/usermecontroller";
import { tokenServiceApiURL } from "@/utils/commons/environment";
export default {
  components: { ValidationObserver, ValidationProvider, DialogConfirm },
  data() {
    return {
      email: null,
      message: null,
      loading: false,

      dialogSuccess: {
        show: false,
        title: this.$t("title_message_confirm"),
        width: 540 + "px",
        value: null,
        icon: "",
        message: null,
      },
    };
  },
  async created() {},
  methods: {
    closeDialog() {
      this.dialogSuccess.message = "";
      this.dialogSuccess.show = false;
      this.$store.dispatch("setIsResetForm", false);
    },
    onClose() {
      this.$store.dispatch("setIsResetForm", false);
    },
    async handleReset() {
      var vm = this;
      let message = "";
      this.message = null;
      this.$refs.observerResetPass.validate().then(async (success) => {
        if (!success) {
          setTimeout(() => {
            Object.keys(vm.$refs.observerResetPass.errors).forEach(function (
              key
            ) {
              message =
                message +
                vm.$refs.observerResetPass.errors[key].join(",\n") +
                ". ";
            });
            if (message)
              this.message = message.substring(0, message.length - 2);
          }, 100);
          return;
        }
        this.loading = true;
        try {
          let response = await usermecontrollerFunc.resetPassword(
            this.email.trim().replace(/\s\s+/g, " ")
          );
          this.loading = false;
          if (response && response.status === 200) {
            if (response.data && response.data.success) {
              this.dialogSuccess.message =
                response.data && response.data.message
                  ? response.data.message
                  : null;
              this.dialogSuccess.show = true;
            }
          }
        } catch (error) {
          this.loading = false;
          if (error && error.response && error.response.data)
            this.message = error.response.data.message
              ? error.response.data.message
              : null;
        }
      });
    },
  },
  computed: {
    routePath() {
      return this.$route.path;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
$dark: #343a40;
.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1080;
  background: rgba(0, 0, 0, 0.5);
  input,
  button,
  span,
  p {
    font-size: 14px;
  }
}

.middle {
  display: table-cell;
  vertical-align: middle;
}
.card {
  box-shadow: 0 12px 18px 2px rgba(34, 0, 51, 0.04),
    0 6px 22px 4px rgba(7, 48, 114, 0.12),
    0 6px 10px -4px rgba(14, 13, 26, 0.12);
  border-radius: 0.5rem; //0.125rem;
}
.card-forgotpass {
  padding: 1rem;
  max-width: 26rem;
  .close {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    font-size: 14px;
    width: 24px;
    height: 24px;
    margin: 0 auto;
    padding: 0.3rem 0.45rem;
    border-radius: 50%;
    background: #dde1ec;
    &:hover {
      cursor: pointer;
    }
  }
}

.card-header {
  font-size: 20px;
  border-bottom: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  padding: 0;
  .title {
    font-size: 18px;
    color: $background-color;
    margin-top: 0.75rem;
  }
  img {
    width: 154px;
    height: 112px;
    &:hover {
      cursor: pointer;
    }
  }
}
.card-footer {
  background-color: transparent;
}
.card-body {
  padding: 0;
  padding-top: 1rem;
  .notification {
    border: 1px solid rgba(255, 0, 0, 0.15);
    background-color: rgba(255, 0, 0, 0.1);
    padding: 1rem 0.5rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .form-group__email {
    label {
      margin-bottom: 0.375rem;
      color: #3f4254;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
    input {
      background: #f5f8fa;
      border-radius: 0.25rem;
      border-color: #f5f8fa;
    }
    .error {
      margin-top: 0.25rem;
    }
  }
  .form-row {
    margin-bottom: 1rem;
    .form-check-input {
      margin-top: 0.125rem;
    }
    .label-forget-pass {
      color: $background-color;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
        color: #007bff;
      }
    }
  }
  .btn-reset {
    border-radius: 0.125rem;
    font-size: 14px;
  }
}
input:focus {
  outline: 0 0 0 0 !important;
  box-shadow: 0 0 0 0 !important;
}
form {
  color: $dark;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.6;
  background-color: transparent;
  background-clip: padding-box;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
