<template>
  <div div class="_5UeKhw6Hb2vGd">
    <div
      class="group"
      role="group"
      aria-label="Button group with nested dropdown"
    >
      <div class="button-group">
        <div class="btn-group" role="group">
          <button
            id="btnGroupDropDraw"
            type="button"
            class="btn dropdown-toggle btn-geostore"
            :class="drawTool.active ? 'active' : ''"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-edit"></i>
          </button>
          <div class="dropdown-menu" aria-labelledby="btnGroupDropDraw">
            <a
              class="dropdown-item"
              @click="drawTtoolClick('Box')"
              :class="drawTool.name === 'Box' ? 'active' : ''"
            >
              <i class="far fa-square"></i>&nbsp;{{ $t("label_rectangle") }}
            </a>
            <a
              class="dropdown-item"
              @click="drawTtoolClick('Polygon')"
              :class="drawTool.name === 'Polygon' ? 'active' : ''"
            >
              <i class="fas fa-draw-polygon"></i>&nbsp;{{ $t("label_polygon") }}
            </a>
            <a class="dropdown-item" @click="drawTtoolClick('None')">
              <i class="fas fa-mouse-pointer"></i>&nbsp;{{ $t("label_none") }}
            </a>
            <span
              class="dropdown-item"
              @click="clearAll"
              v-if="
                Object.keys(source).length > 0 &&
                source.getFeatures().length > 0
              "
            >
              <button class="btn btn-danger btn-clear-polygon">
                {{ $t("button_text_remove_all") }}
              </button>
            </span>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-geostore"
          @click="modifyFeature"
          :class="[
            Object.keys(select).length > 0 && Object.keys(modify).length > 0
              ? 'active'
              : '',
          ]"
          :disabled="
            Object.keys(source).length == 0 || source.getFeatures().length == 0
          "
        >
          <i class="fas fa-pen"></i>
        </button>
        <button
          type="button"
          class="btn btn-geostore"
          @click="actionClick('UPLOAD')"
        >
          <i class="fas fa-upload"></i>
        </button>
      </div>
      <div
        v-if="
          Object.keys(propFeature).length > 0 && propFeature.items.length > 0
        "
      >
        <div
          class="row align-items-center no-gutters text-center mt-2 Hywr8Hwf"
        >
          <div class="col-8">
            <div class="dropdown">
              <button
                class="btn btn-transparent dropdown-toggle btn-text-polygon"
                type="button"
                data-toggle="dropdown"
              >
                {{ propFeature.active ? propFeature.active.name : "" }}
                <span class="caret"></span>
              </button>
              <ul class="dropdown-menu features-draw">
                <li
                  v-for="(item, i) in propFeature.items"
                  :key="i"
                  class="p-1"
                  @click="selectedFeature(item ? item.id : 0)"
                >
                  {{ item ? item.name : "" }}
                  <button
                    type="button"
                    class="btn btn-close btn-sm float-right"
                    @click.stop="removeFeature(item ? item.id : 0)"
                  >
                    <i class="fas fa-times"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-4 d-flex polygon-actions">
            <button class="btn-geostore" @click="showPopupAoiInfo">
              <span><i class="fas fa-info-circle"></i></span>
            </button>
            <button class="btn-geostore" @click="showDialogExport">
              <span><i class="fas fa-download"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modify-confirm" v-show="Object.keys(modify).length > 0">
      <div class="modify-confirm__header">
        <span>{{ $t("title_confirm_modify") }}</span>
        <div class="modify-confirm__header--icons">
          <button type="button" class="close" @click="cancelConfirm">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modify-confirm__footer text-center">
        <button
          type="button"
          class="btn btn-transparent"
          @click="cancelConfirm"
        >
          {{ $t("button_text_cancel") }}
        </button>
        <button type="button" class="btn btn-geostore" @click="acceptConfirm">
          {{ $t("button_text_agree") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Currently drawn feature.
 * @type {module:ol/Feature~Feature}
 */
var sketch;

/**
 * The help tooltip element.
 * @type {Element}
 */
var helpTooltipElement;

/**
 * Overlay to show the help messages.
 * @type {module:ol/Overlay}
 */
var helpTooltip;

/**
 * The measure tooltip element.
 * @type {Element}
 */
var measureTooltipElement;

/**
 * Overlay to show the measurement.
 * @type {module:ol/Overlay}
 */
var measureTooltip;
/**
 * Message to show when the user is drawing a polygon.
 * @type {string}
 */
var continuePolygonMsg = "Click to continue drawing the polygon";

/**
 * Message to show when the user is drawing a line.
 * @type {string}
 */
var continueLineMsg = "Click to continue drawing the line";

/**
 * Handle pointer move.
 * @param {module:ol/MapBrowserEvent~MapBrowserEvent} evt The event.
 */
var formatLength = function (line) {
  var length = getLength(line);
  var output;
  if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + " " + "km";
  } else {
    output = Math.round(length * 100) / 100 + " " + "m";
  }
  return output;
};
var formatArea = function (polygon) {
  var area = getArea(polygon);
  var output;
  if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + " " + "km<sup>2</sup>";
  } else {
    output = Math.round((area * 100) / 100) + " " + "m<sup>2</sup>";
  }
  return output;
};
import Draw, { createRegularPolygon, createBox } from "ol/interaction/Draw.js";
import { Vector as VectorSource } from "ol/source.js";
import { Vector as VectorLayer } from "ol/layer";
import { unByKey } from "ol/Observable.js";
import Overlay from "ol/Overlay.js";
import { getArea, getLength } from "ol/sphere.js";
import { LineString, Polygon } from "ol/geom.js";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import turf from "turf";
import { Modify, Select } from "ol/interaction.js";
import { GeoJSON } from "ol/format.js";

import { toLonLat, transform } from "ol/proj.js";
import {
  Circle as CircleStyle,
  Fill,
  RegularShape,
  Stroke,
  Style,
  Text,
} from "ol/style.js";
import KML from "ol/format/KML.js";
import Projection from "ol/proj/Projection";
import Collection from "ol/Collection";
import { getCenter, getTopLeft } from "ol/extent";
import moment from "moment";
export default {
  props: {
    map: {
      required: true,
    },
    propFeature: {
      type: Object,
      default: () => {
        return {
          active: null,
          items: [],
        };
      },
    },
  },
  components: {},
  data() {
    return {
      areaLayer: Object,
      select: Object,
      modify: Object,
      draw: Object,
      modifyObj: {
        active: false,
        area: null,
        geometry: null,
      },
      source: Object,
      drawTool: {
        active: false,
        name: "",
      },
    };
  },
  created() {},
  watch: {
    map(newVal, oldVal) {
      if (
        Object.keys(oldVal).length === 0 &&
        newVal &&
        Object.keys(newVal).length > 0
      ) {
        var vm = this;
        const source = new VectorSource({
          wrapX: false,
        });

        this.areaLayer = new VectorLayer({
          source: source,
          id: "areaLayer",
        });
        this.areaLayer.setZIndex(vm.map.getLayers().array_.length + 10);
        vm.map.addLayer(this.areaLayer);
        vm.map.on("pointermove", vm.pointerMoveHandler);
        vm.map.getViewport().addEventListener("mouseout", function () {
          if (helpTooltipElement) helpTooltipElement.classList.add("hidden");
        });
        if (Object.keys(this.map).length > 0) {
          if (Object.keys(this.source).length === 0) {
            var layers = this.map.getLayers().array_;
            if (layers.length > 0) {
              for (let i = 0; i < layers.length; i++) {
                if (layers[i].get("id") === "layerdraw") {
                  this.source = layers[i].values_.source;
                  break;
                }
              }
            }
            if (Object.keys(this.source).length === 0) return;
          }
        }
      }
    },
  },
  mounted() {},
  methods: {
    addAreaLabel(feature) {
      if (this.areaLayer && Object.keys(this.areaLayer).length > 0) {
        let topLeft = this.getTopLeftFromFeature(feature);
        let point, featureLabel;
        if (topLeft.lat != null && topLeft.lng != null) {
          point = new Point(
            transform([topLeft.lng, topLeft.lat], "EPSG:4326", "EPSG:3857")
          );
        } else {
          var extent = feature.getGeometry().getExtent().slice(0);
          point = new Point([extent[0], extent[3]]);
        }
        featureLabel = new Feature({
          geometry: point,
          id: feature.id_,
          name: this.$t("label_polygon") + " " + this.propFeature.items.length,
        });
        featureLabel.setId(feature.id_);
        featureLabel.setStyle(
          new Style({
            text: new Text({
              textAlign: "center",
              textBaseline: "middle",
              font: "14px Helvetica Neue, Roboto, Avenir, Helvetica, Arial, sans-serif",
              fill: new Fill({ color: this.hexToRgbA("#ffffff", 1) }),
              stroke: new Stroke({
                color: this.hexToRgbA("#000", 0.8),
                width: 2,
              }),
              text:
                this.$t("label_polygon") +
                " " +
                this.propFeature.items.length +
                " : ~" +
                formatArea(feature.getGeometry()).replace("<sup>2</sup>", "2"),
              offsetX: 0,
              offsetY: 0,
            }),
          })
        );
        this.areaLayer.getSource().addFeature(featureLabel);
      }
    },
    cancelConfirm() {
      if (Object.keys(this.select).length > 0) {
        this.map.removeInteraction(this.select);
        this.select = Object;
      }
      if (Object.keys(this.modify).length > 0) {
        this.map.removeInteraction(this.modify);
        this.modify = Object;
      }
      let feature = this.source.getFeatureById(this.propFeature.active.id);
      if (feature)
        feature.getGeometry().setCoordinates(this.modifyObj.coordinates);
      this.modifyObj.active = !this.modifyObj.active;
    },
    acceptConfirm() {
      if (Object.keys(this.select).length > 0) {
        this.map.removeInteraction(this.select);
        this.select = Object;
      }
      if (Object.keys(this.modify).length > 0) {
        this.map.removeInteraction(this.modify);
        this.modify = Object;
      }
      let feature = this.source.getFeatureById(this.propFeature.active.id);
      if (feature) {
        var geom = [];
        geom.push(
          new Feature(
            feature.getGeometry().clone().transform("EPSG:3857", "EPSG:4326")
          )
        );
        var writer = new GeoJSON();
        var geoJsonStr = writer.writeFeatures(geom);
        this.$emit("drawEnd", JSON.parse(geoJsonStr).features[0]);
        this.$emit("modifiedFeature", feature);
        this.$emit("setAoiFeature", feature);
        if (this.areaLayer && Object.keys(this.areaLayer).length > 0) {
          var extent = feature.getGeometry().getExtent().slice(0);
          if (extent && extent.length > 0) {
            let featureLabel = this.areaLayer
              .getSource()
              .getFeatureById(this.propFeature.active.id);
            if (featureLabel && Object.keys(featureLabel).length > 0) {
              setTimeout(() => {
                let coor = this.getTopLeftFromFeature(feature);
                if (coor.lat != null && coor.lng != null) {
                  featureLabel.setGeometry(
                    new Point(
                      transform([coor.lng, coor.lat], "EPSG:4326", "EPSG:3857")
                    )
                  );
                } else
                  featureLabel.setGeometry(new Point([extent[0], extent[3]]));
                featureLabel.setStyle(
                  new Style({
                    text: new Text({
                      textAlign: "center",
                      textBaseline: "middle",
                      font: "14px Helvetica Neue, Roboto, Avenir, Helvetica, Arial, sans-serif",
                      fill: new Fill({ color: this.hexToRgbA("#ffffff", 1) }),
                      stroke: new Stroke({
                        color: this.hexToRgbA("#000", 0.8),
                        width: 2,
                      }),
                      text:
                        this.$t("label_polygon") +
                        " " +
                        this.propFeature.items.length +
                        " : ~" +
                        formatArea(feature.getGeometry()).replace(
                          "<sup>2</sup>",
                          "²"
                        ),
                      offsetX: 0,
                      offsetY: 0,
                    }),
                  })
                );
              }, 200);
            } else {
              featureLabel = new Feature({
                geometry: new Point([extent[0], extent[3]]),
                id: id,
                name:
                  vm.$t("label_polygon") + " " + vm.propFeature.items.length,
              });
              featureLabel.setId(id);
              featureLabel.setStyle(
                new Style({
                  text: new Text({
                    textAlign: "center",
                    textBaseline: "middle",
                    font: "14px Helvetica Neue, Roboto, Avenir, Helvetica, Arial, sans-serif",
                    fill: new Fill({ color: vm.hexToRgbA("#ffffff", 1) }),
                    stroke: new Stroke({
                      color: vm.hexToRgbA("#000", 0.8),
                      width: 2,
                    }),
                    text:
                      vm.$t("label_polygon") +
                      " " +
                      vm.propFeature.items.length +
                      " : ~" +
                      formatArea(event.feature.getGeometry()).replace(
                        "<sup>2</sup>",
                        "²"
                      ),
                    offsetX: 0,
                    offsetY: 0,
                  }),
                })
              );
              vm.areaLayer.getSource().addFeature(featureLabel);
            }
          }
        }
      }
      this.modifyObj.active = !this.modifyObj.active;
    },

    getTopLeftFromFeature(feature) {
      if (feature && Object.keys(feature).length > 0) {
        let coors = feature.getGeometry().getCoordinates();
        let coordinatesDraw = [];
        for (let i = 0; i < coors[0].length; i++) {
          coordinatesDraw.push(
            transform(coors[0][i], "EPSG:3857", "EPSG:4326")
          );
          if (i >= 3) break;
        }
        var centerTest = getCenter(feature.getGeometry().getExtent());
        centerTest = transform(centerTest, "EPSG:3857", "EPSG:4326");
        let upperPoints = coordinatesDraw.filter((x) => x[1] > centerTest[1]);
        if (upperPoints && upperPoints.length === 0) {
          if (coordinatesDraw.length > 0) {
            upperPoints.push(coordinatesDraw[0]);
          }
        }
        // Find top left
        let indexOfTopLeft = -1;
        let topleft = {
          lat: null,
          lng: null,
        };
        if (upperPoints.length === 1) {
          topleft.lat = upperPoints[0][1];
          topleft.lng = upperPoints[0][0];
        } else if (upperPoints.length == 2) {
          if (upperPoints[0][0] < upperPoints[1][0]) {
            topleft.lat = upperPoints[0][1];
            topleft.lng = upperPoints[0][0];
          } else {
            topleft.lat = upperPoints[1][1];
            topleft.lng = upperPoints[1][0];
          }
        } else if (upperPoints.length == 3) {
          let max = Math.max.apply(
            null,
            upperPoints.map((x) => x[0])
          );
          let idx = upperPoints.findIndex((x) => x[0] === max);
          if (idx >= 0) {
            upperPoints.splice(idx, 1);
            if (upperPoints[0][0] < upperPoints[1][0]) {
              topleft.lat = upperPoints[0][1];
              topleft.lng = upperPoints[0][0];
            } else {
              topleft.lat = upperPoints[1][1];
              topleft.lng = upperPoints[1][0];
            }
          }
        }
        return topleft;
      }
    },
    setStyleFeature(feature, style) {
      if (feature) feature.setStyle(style);
    },
    selectedFeature(val) {
      if (!val) return;
      if (Object.keys(this.map).length > 0) {
        if (Object.keys(this.source).length === 0) {
          var layers = this.map.getLayers().array_;
          if (layers.length > 0) {
            for (let i = 0; i < layers.length; i++) {
              if (layers[i].get("id") === "layerdraw") {
                this.source = layers[i].values_.source;
                break;
              }
            }
          }
          if (Object.keys(this.source).length === 0) return;
        }
      }
      let feature = this.source.getFeatureById(this.propFeature.active.id);
      if (feature) {
        this.setStyleFeature(
          feature,
          new Style({
            fill: new Fill({
              color: "rgba(255,255,255,0.4)",
            }),
            strokeColor: "#3399CC",
            stroke: new Stroke({
              color: "black",
              width: 1.25,
            }),
          })
        );
      }
      let item = this.propFeature.items.find((x) => x.id === val);
      if (item) {
        this.propFeature.active = item;
        this.$emit("drawEnd", this.propFeature.active.value);
        let feature = this.source.getFeatureById(this.propFeature.active.id);
        if (feature && Object.keys(feature).length > 0) {
          this.setStyleFeature(
            feature,
            new Style({
              fill: new Fill({
                color: "rgba(255,255,255,0.4)",
              }),
              strokeColor: "#3399CC",
              stroke: new Stroke({
                color: "#fd7e14",
                width: 1.25,
              }),
            })
          );
          this.$emit("setAoiFeature", feature);
        } else {
          this.$emit("setAoiFeature", null);
        }
      }
    },
    removeFeature(val) {
      let index = this.propFeature.items.findIndex((x) => x.id === val);
      if (index >= 0) {
        if (val === this.propFeature.active.id) {
          if (index < this.propFeature.items.length - 1) {
            this.propFeature.active = this.propFeature.items[index + 1];
          } else if (index == this.propFeature.items.length - 1) {
            if (index != 0) {
              this.propFeature.active = this.propFeature.items[index - 1];
            } else this.propFeature.active = null;
          } else {
            this.propFeature.active = null;
          }
        }
        this.propFeature.items.splice(index, 1);
        if (this.propFeature.items.length == 0) {
          this.$store.dispatch("setSearchDto", {
            boundary: null,
          });
        }
        let feature = this.source.getFeatureById(val);
        if (feature) this.source.removeFeature(feature);

        let featureLabel = this.areaLayer.getSource().getFeatureById(val);
        if (featureLabel)
          this.areaLayer.getSource().removeFeature(featureLabel);
        if (this.propFeature.active) {
          this.$emit("drawEnd", this.propFeature.active.value);

          feature = this.source.getFeatureById(this.propFeature.active.id);
          if (feature && Object.keys(feature).length > 0) {
            setTimeout(() => {
              this.setStyleFeature(
                feature,
                new Style({
                  fill: new Fill({
                    color: "rgba(255,255,255,0.4)",
                  }),
                  strokeColor: "#3399CC",
                  stroke: new Stroke({
                    color: "#fd7e14",
                    width: 1.25,
                  }),
                })
              );
            }, 100);
            this.$emit("setAoiFeature", feature);
          } else {
            this.$emit("setAoiFeature", null);
          }
        } else {
          this.$emit("clearSearch");
          this.$emit("setAoiFeature", null);
        }
      }
    },
    showPopupAoiInfo(e) {
      if (
        Object.keys(this.source).length === 0 ||
        this.source.getFeatures().length === 0
      )
        return;
      let feature = this.source.getFeatureById(this.propFeature.active.id);
      this.$emit("showPopupAoiInfo", {
        event: e,
        feature: feature,
        name: this.propFeature.active ? this.propFeature.active.name : null,
        tool:
          this.propFeature.active && this.propFeature.active.tool
            ? this.propFeature.active.tool
            : null,
        id:
          this.propFeature.active && this.propFeature.active.id
            ? this.propFeature.active.id
            : null,
      });
    },
    showDialogExport() {
      if (
        Object.keys(this.source).length === 0 ||
        this.source.getFeatures().length === 0
      )
        return;
      let feature = this.source.getFeatureById(this.propFeature.active.id);
      if (feature) {
        this.$emit("showDialogExport", feature);
      }
    },
    downloadPolygon() {
      if (
        Object.keys(this.source).length === 0 ||
        this.source.getFeatures().length === 0
      )
        return;
      let feature = this.source.getFeatureById(this.propFeature.active.id);
      if (feature) {
        let format = new KML({
          defaultStyle: new Style({
            image: new RegularShape({
              fill: new Fill({
                color: "#fd7e14",
              }),
              points: 2,
              radius1: 1,
              radius2: 1,
            }),
            stroke: new Stroke({
              color: "blue",
              width: 1,
            }),
            fill: new Fill({
              color: "transparent",
            }),
          }),
          extractStyles: true,
        });
        var kmlStyle =
          "<Style id='OutlineOnlyStyle'><PolyStyle><color>ff0000cc</color><fill>0</fill><outline>1</outline></PolyStyle></Style>";

        var geom = [];
        geom.push(
          new Feature(
            feature.getGeometry().clone().transform("EPSG:3857", "EPSG:4326")
          )
        );
        var kmlData = format
          .writeFeatures(geom)
          .replace(/<Folder>/g, "<Folder>" + kmlStyle)
          .replace(/><name>/g, "><styleUrl>#OutlineOnlyStyle</styleUrl><name>");
        var data = new Blob([kmlData], { type: "text/kml" });

        var encodedUri = window.URL.createObjectURL(data);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute(
          "download",
          "kml_export_" +
            this.formatUnixDateTime(new Date().getTime() / 1000)
              .split("/")
              .join("_") +
            ".kml"
        );
        document.body.appendChild(link); // Required for FF
        link.click();
        document.body.removeChild(link);
      }
    },
    formatUnixDateTime(value) {
      if (value) {
        return moment.unix(value).format("DD/MM/YYYY/HH/mm");
      } else return "";
    },
    actionClick(val) {
      this.$emit("actionClick", {
        code: val,
      });
    },
    modifyFeature() {
      let vm = this;
      if (!vm.modifyObj.active) {
        if (Object.keys(this.select).length == 0) {
          let feature = vm.source.getFeatureById(this.propFeature.active.id);
          this.select = new Select({
            features: new Collection([
              feature ? feature : vm.source.getFeatures()[0],
            ]),
          });
          this.map.addInteraction(this.select);
          vm.modifyObj.coordinates = (
            feature ? feature : vm.source.getFeatures()[0]
          )
            .getGeometry()
            .getCoordinates();
        }
        if (Object.keys(this.modify).length == 0) {
          vm.modify = new Modify({
            features: vm.select.getFeatures(),
          });
          vm.map.addInteraction(vm.modify);
        }
      } else {
        if (Object.keys(this.select).length > 0) {
          this.map.removeInteraction(this.select);
          this.select = Object;
        }
        if (Object.keys(this.modify).length > 0) {
          vm.map.removeInteraction(vm.modify);
          vm.modify = Object;
        }
      }
      vm.modifyObj.active = !vm.modifyObj.active;
    },
    pointerMoveHandler: function (evt) {
      if (evt.dragging) {
        return;
      }
      /** @type {string} */
      var helpMsg = this.$t("tooltip_click_2_start_drawing"); //"Click vào để bắt đầu vẽ";

      if (sketch) {
        var geom = sketch.getGeometry();
        if (geom instanceof Polygon) {
          helpMsg = continuePolygonMsg;
          switch (this.drawTool.name) {
            case "Box":
              helpMsg = this.$t("tooltip_click_2_finish");
              //"Click vào để kết thúc";
              break;
            case "Polygon":
              helpMsg = this.$t("tooltip_click_2_continue_drawing_polygon"); // "Click đúp để kết thúc";
              break;
            default:
              break;
          }
        } else if (geom instanceof LineString) {
          helpMsg = continueLineMsg;
        }
      }
      if (this.drawTool.active) {
        helpTooltipElement.innerHTML = helpMsg;
        helpTooltip.setPosition(evt.coordinate);
        helpTooltipElement.classList.remove("hidden");
      }
    },
    /**
     * Creates a new help tooltip
     */
    createHelpTooltip: function () {
      if (helpTooltipElement) {
        if (helpTooltipElement.parentNode)
          helpTooltipElement.parentNode.removeChild(helpTooltipElement);
      }
      helpTooltipElement = document.createElement("div");
      helpTooltipElement.className = "help tooltip hidden";
      helpTooltip = new Overlay({
        element: helpTooltipElement,
        offset: [15, 0],
        positioning: "center-left",
      });
      this.map.addOverlay(helpTooltip);
    },

    /**
     * Creates a new measure tooltip
     */
    createMeasureTooltip: function () {
      if (measureTooltipElement) {
        measureTooltipElement.parentNode.removeChild(measureTooltipElement);
      }
      measureTooltipElement = document.createElement("div");
      measureTooltipElement.className = "tooltip tooltip-measure";
      measureTooltip = new Overlay({
        element: measureTooltipElement,
        offset: [0, -15],
        positioning: "bottom-center",
      });
      this.map.addOverlay(measureTooltip);
    },
    drawTtoolClick: function (toolCode) {
      let elements = document.querySelectorAll(".help.tooltip");
      try {
        for (let i = 0; i < elements.length; i++) {
          elements[i].parentNode.removeChild(elements[i]);
        }
      } catch (e) {}
      if (Object.keys(this.draw).length > 0) {
        this.map.removeInteraction(this.draw);
      }
      if (toolCode.toLowerCase() != "none") {
        this.createHelpTooltip();
        this.addInteraction(toolCode);
      } else {
        this.drawTool.name = null;
        this.drawTool.active = false;
      }
    },
    addInteraction: function (toolCode) {
      var vm = this;
      if (Object.keys(this.map).length > 0) {
        if (Object.keys(this.source).length === 0) {
          var layers = this.map.getLayers().array_;
          if (layers.length > 0) {
            for (let i = 0; i < layers.length; i++) {
              if (layers[i].get("id") === "layerdraw") {
                this.source = layers[i].values_.source;
                break;
              }
            }
          }
          if (Object.keys(this.source).length === 0) return;
        }
        this.drawTool.name = toolCode;
        if (toolCode !== "None") {
          this.drawTool.active = true;
          if (helpTooltipElement) helpTooltipElement.classList.remove("hidden");

          var geometryFunction;
          if (toolCode === "Box") {
            toolCode = "Circle";
            geometryFunction = createBox();
          } else geometryFunction = null;
          this.draw = new Draw({
            source: this.source,
            type: toolCode,
            geometryFunction: geometryFunction,
          });
          var listener;
          this.draw.on("drawstart", function (evt) {
            sketch = evt.feature;
            /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
            var tooltipCoord = evt.coordinate;

            listener = sketch.getGeometry().on("change", function (evt) {
              var geom = evt.target;
              var output;
              if (geom instanceof Polygon) {
                try {
                  output = "~" + formatArea(geom);
                } catch (errorConvert) {}

                tooltipCoord = geom.getInteriorPoint().getCoordinates();
              } else if (geom instanceof LineString) {
                output = formatLength(geom);
                tooltipCoord = geom.getLastCoordinate();
              }
              measureTooltipElement.innerHTML = output;
              measureTooltip.setPosition(tooltipCoord);
            });
          });
          this.draw.on("drawend", function (event) {
            for (let i = 0; i < vm.source.getFeatures().length; i++) {
              vm.source.getFeatures()[i].setStyle(
                new Style({
                  fill: new Fill({
                    color: "rgba(255,255,255,0.125)",
                  }),
                  strokeColor: "#3399CC",
                  stroke: new Stroke({
                    color: "black",
                    width: 1.25,
                  }),
                })
              );
            }
            event.feature.setStyle(
              new Style({
                fill: new Fill({
                  color: "rgba(255,255,255,0.125)",
                }),
                strokeColor: "#3399CC",
                stroke: new Stroke({
                  color: "#fd7e14",
                  width: 1.25,
                }),
              })
            );
            var geom = [];
            geom.push(
              new Feature(
                event.feature
                  .getGeometry()
                  .clone()
                  .transform("EPSG:3857", "EPSG:4326")
              )
            );
            var writer = new GeoJSON();
            var geoJsonStr = writer.writeFeatures(geom);
            let id = new Date().getTime();
            let obj = {
              name:
                vm.$t("label_polygon") +
                " " +
                (vm.propFeature.items.length + 1),
              value: JSON.parse(geoJsonStr).features[0],
              id: id,
              tool: vm.drawTool.name,
            };
            event.feature.set("id_", id);
            event.feature.id_ = id;
            event.feature.set(
              "label",
              vm.$t("label_polygon") + " " + (vm.propFeature.items.length + 1)
            );
            //if(!vm.propFeature.active)
            vm.propFeature.active = obj;
            vm.propFeature.items.push(obj);
            vm.$emit("drawEnd", JSON.parse(geoJsonStr).features[0]);
            if (["LineString", "Box", "Polygon"].includes(vm.drawTool.name)) {
              unByKey(listener);
            }
            // unset sketch
            sketch = null;
            vm.map.removeOverlay(helpTooltip);
            vm.map.removeInteraction(vm.draw);
            vm.drawTool.active = false;
            vm.drawTool.name = "";
            if (vm.areaLayer && Object.keys(vm.areaLayer).length > 0) {
              var extent = event.feature.getGeometry().getExtent().slice(0);
              let topLeft = vm.getTopLeftFromFeature(event.feature);
              let coor = [];
              if (topLeft.lat != null && topLeft.lng != null) {
                coor = transform(
                  [topLeft.lng, topLeft.lat],
                  "EPSG:4326",
                  "EPSG:3857"
                );
              } else coor = [extent[0], extent[3]];
              let featureLabel = new Feature({
                geometry: new Point(coor),
                id: id,
                name:
                  vm.$t("label_polygon") + " " + vm.propFeature.items.length,
              });
              featureLabel.setId(id);
              featureLabel.setStyle(
                new Style({
                  text: new Text({
                    textAlign: "center",
                    textBaseline: "middle",
                    font: "14px Helvetica Neue, Roboto, Avenir, Helvetica, Arial, sans-serif",
                    fill: new Fill({ color: vm.hexToRgbA("#ffffff", 1) }),
                    stroke: new Stroke({
                      color: vm.hexToRgbA("#000", 0.8),
                      width: 2,
                    }),
                    text:
                      vm.$t("label_polygon") +
                      " " +
                      vm.propFeature.items.length +
                      " : ~" +
                      formatArea(event.feature.getGeometry()).replace(
                        "<sup>2</sup>",
                        "²"
                      ),
                    offsetX: 0,
                    offsetY: 0,
                  }),
                })
              );
              vm.areaLayer.getSource().addFeature(featureLabel);
            }

            vm.$emit("setAoiFeature", event.feature);
            vm.createMeasureTooltip();
          });
          this.map.addInteraction(this.draw);

          vm.createHelpTooltip();
          vm.createMeasureTooltip();
        } else {
          this.drawTool.active = false;
          if (helpTooltipElement) helpTooltipElement.classList.add("hidden");
        }
      }
    },
    hexToRgbA(hex, opacity) {
      if (opacity === null || opacity === undefined) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
    clearAll: function () {
      var vm = this;
      this.drawTool.active = false;
      if (Object.keys(this.source).length > 0) {
        var featuresSource = this.source.getFeatures();
        for (let i = 0; i < featuresSource.length; i++) {
          this.source.removeFeature(featuresSource[i]);
        }
      }
      if (this.areaLayer && Object.keys(this.areaLayer).length > 0) {
        this.areaLayer.getSource().clear();
      }
      (this.propFeature.items = []), (this.propFeature.active = {});

      this.$store.dispatch("setSearchDto", {
        boundary: null,
      });
      this.$emit("clearAllAoi");

      let elements = document.querySelectorAll(".help.tooltip");
      try {
        for (let i = 0; i < elements.length; i++) {
          elements[i].parentNode.removeChild(elements[i]);
        }
      } catch (e) {}
      this.map.removeInteraction(this.draw);
      this.map.removeInteraction(this.select);
      this.map.removeInteraction(this.modify);
      vm.map.removeOverlay(helpTooltip);
      vm.createHelpTooltip();
    },
  },
};
</script>
<style lang="scss">
.tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
}
.tooltip.hidden {
  display: none;
}
.tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}
.tooltip-measure:before,
.tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.tooltip-static:before {
  border-top-color: #ffcc33;
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
._5UeKhw6Hb2vGd {
  .group {
    padding: 0.3125rem;
    background-color: rgba(255, 255, 255, 0.4);
    text-align: center;
    display: inline-block;
    opacity: 1;
    border-radius: 0.25rem;
  }
}
.dropdown-menu {
  opacity: 1;
  transform: none !important;
  top: 1.75rem !important;
  left: -0.375rem !important;
  .dropdown-item {
    font-size: 14px;
  }
}
.dropdown-item:last-child:hover {
  background-color: transparent;
}
.dropdown-item.active {
  color: white;
}
button {
  padding: 0 0.5rem;
  &.active {
    color: $background-color;
    border: 1px solid $background-color;
    background: white;
  }
}
.box-title {
  width: 100%;
  text-align: center;
  font-size: 14px;
}
.btn-text-polygon {
  font-size: 14px;
}
.btn-clear-polygon {
  font-size: 14px;
}
.features-draw {
  li {
    font-size: 14px;
    &:hover {
      cursor: pointer;
      background-color: rgba($color: #000, $alpha: 0.1);
    }
    button.btn-close {
      &:hover {
        color: #c82333;
      }
    }
  }
}

.button-group {
  display: flex;
  button {
    width: 2.75rem;
    padding: 0 0.5rem;
    height: 32px;
    margin-left: 0.25rem;
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
    &.active {
      background: $orange;
      border-color: $orange;
      color: white;
    }
    &.btn-clear-polygon {
      width: 6rem;
    }
  }
}
.Hywr8Hwf {
  button.btn-text-polygon {
    color: white;
    background: $background-color;
  }
  .polygon-actions {
    cursor: pointer;
    button {
      height: 22px;
      //padding: 0.15rem 0.15rem;
      padding-right: 0.25rem;
      padding-left: 0.25rem;
      margin-left: 0.185rem;
      border: 1px solid transparent;
      border-radius: 0.25rem;
      i {
        font-size: 0.675rem;
      }
      &:last-child {
        margin-left: 0.165rem;
      }
    }
  }
}
.modify-confirm {
  background: rgba(255, 255, 255, 0.4);
  padding: 0.5rem 0;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  .modify-confirm__header {
    font-size: 14px;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    span {
      text-align: center;
      width: 100%;
      color: $background-color;
      vertical-align: middle;
      padding-left: 0.75rem;
    }
  }
  .modify-confirm__footer {
    button {
      font-size: 14px;
      margin-left: 0.125rem;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
</style>
