<template>
  <div class="confirmation-production-parameters_optical">
    <div class="row no-gutters">
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_product")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="
                propDto.order_request_info.processing_level
                  ? propDto.order_request_info.processing_level.translation &&
                    propDto.order_request_info.processing_level.translation[
                      lang
                    ] &&
                    propDto.order_request_info.processing_level.translation[
                      lang
                    ].value
                    ? propDto.order_request_info.processing_level.translation[
                        lang
                      ].value
                    : propDto.order_request_info.processing_level.value
                  : ''
              "
              readonly
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_resampling_kernel")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="
                propDto.order_request_info.resampling_kernel
                  ? propDto.order_request_info.resampling_kernel.translation &&
                    propDto.order_request_info.resampling_kernel.translation[
                      lang
                    ] &&
                    propDto.order_request_info.resampling_kernel.translation[
                      lang
                    ].value
                    ? propDto.order_request_info.resampling_kernel.translation[
                        lang
                      ].value
                    : propDto.order_request_info.resampling_kernel.value
                  : ''
              "
              readonly
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_bands")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="
                propDto.order_request_info.band
                  ? propDto.order_request_info.band.translation &&
                    propDto.order_request_info.band.translation[lang] &&
                    propDto.order_request_info.band.translation[lang].value
                    ? propDto.order_request_info.band.translation[lang].value
                    : propDto.order_request_info.band.value
                  : ''
              "
              readonly
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_license")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="
                propDto.order_request_info.license
                  ? propDto.order_request_info.license.translation &&
                    propDto.order_request_info.license.translation[lang] &&
                    propDto.order_request_info.license.translation[lang].value
                    ? propDto.order_request_info.license.translation[lang].value
                    : propDto.order_request_info.license.value
                  : ''
              "
              readonly
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_bit_depth")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="
                propDto.order_request_info.bit_depth
                  ? propDto.order_request_info.bit_depth.translation &&
                    propDto.order_request_info.bit_depth.translation[lang] &&
                    propDto.order_request_info.bit_depth.translation[lang].value
                    ? propDto.order_request_info.bit_depth.translation[lang]
                        .value
                    : propDto.order_request_info.bit_depth.value
                  : ''
              "
              readonly
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_ortho_options")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="
                propDto.order_request_info.ortho_options
                  ? propDto.order_request_info.ortho_options.translation &&
                    propDto.order_request_info.ortho_options.translation[
                      lang
                    ] &&
                    propDto.order_request_info.ortho_options.translation[lang]
                      .value
                    ? propDto.order_request_info.ortho_options.translation[lang]
                        .value
                    : propDto.order_request_info.ortho_options.value
                  : ''
              "
              readonly
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_pixel_resolution")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="
                propDto.order_request_info.pixel_resolution
                  ? propDto.order_request_info.pixel_resolution.translation &&
                    propDto.order_request_info.pixel_resolution.translation[
                      lang
                    ] &&
                    propDto.order_request_info.pixel_resolution.translation[
                      lang
                    ].value
                    ? propDto.order_request_info.pixel_resolution.translation[
                        lang
                      ].value
                    : propDto.order_request_info.pixel_resolution.value
                  : ''
              "
              readonly
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_file_format")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="
                propDto.order_request_info.file_format
                  ? propDto.order_request_info.file_format.translation &&
                    propDto.order_request_info.file_format.translation[lang] &&
                    propDto.order_request_info.file_format.translation[lang]
                      .value
                    ? propDto.order_request_info.file_format.translation[lang]
                        .value
                    : propDto.order_request_info.file_format.value
                  : ''
              "
              readonly
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-6 col-form-label">{{
            $t("label_dra_contrast_enhancement")
          }}</label>
          <div class="col-12 col-md-6">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="
                propDto.order_request_info.dra_contrast_enhancement
                  ? propDto.order_request_info.dra_contrast_enhancement
                      .translation &&
                    propDto.order_request_info.dra_contrast_enhancement
                      .translation[lang] &&
                    propDto.order_request_info.dra_contrast_enhancement
                      .translation[lang].value
                    ? propDto.order_request_info.dra_contrast_enhancement
                        .translation[lang].value
                    : propDto.order_request_info.dra_contrast_enhancement.value
                  : ''
              "
              readonly
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_tiling")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="
                propDto.order_request_info.tiling
                  ? propDto.order_request_info.tiling.translation &&
                    propDto.order_request_info.tiling.translation[lang] &&
                    propDto.order_request_info.tiling.translation[lang].value
                    ? propDto.order_request_info.tiling.translation[lang].value
                    : propDto.order_request_info.tiling.value
                  : ''
              "
              readonly
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_delivery_media")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="
                propDto.order_request_info.delivery_media
                  ? propDto.order_request_info.delivery_media.translation &&
                    propDto.order_request_info.delivery_media.translation[
                      lang
                    ] &&
                    propDto.order_request_info.delivery_media.translation[lang]
                      .value
                    ? propDto.order_request_info.delivery_media.translation[
                        lang
                      ].value
                    : propDto.order_request_info.delivery_media.value
                  : ''
              "
              readonly
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-2">
        <label class="col-form-label">{{ $t("label_note") }}</label>
      </div>
      <div class="col-10">
        <Textarea
          class="form-control new-style"
          rows="2"
          maxlength="512"
          :value="propDto.note"
          :autoResize="true"
          readonly
        >
        </Textarea>
      </div>
    </div>
  </div>
</template>
<script>
import InputText from "@/components/commons/inputtext/InputText";
import Textarea from "@/components/commons/textarea/Textarea";
export default {
  components: {
    InputText,
    Textarea,
  },
  props: {
    propDto: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.confirmation-production-parameters_optical {
  padding: 0 0.5rem;
  width: 100%;
  color: $text-color;
  .row {
    margin-bottom: 1rem;
    .col-md-6 {
      padding-right: 0.25rem;
      &:last-child {
        padding-right: 0;
        padding-left: 0.25rem;
      }
    }
    .row {
      margin: 0;
    }
  }
}
</style>
