<template>
  <div class="accordion">
    <div class="accordion-tab">
      <slot name="accordion-header">
        <div class="accordion-header">
          <a
            @click="onHeaderClick"
            :class="active ? 'active' : ''"
            v-if="!isChevronEnd"
          >
            <span class="pi pi-chevron-down" v-if="active"></span>
            <span class="pi pi-chevron-right" v-else></span>&nbsp;{{
              propTitle
            }}</a
          >
          <a @click="onHeaderClick" :class="active ? 'active' : ''" v-else>
            {{ propTitle }}
            <span class="pi pi-chevron-down float-right" v-if="active"></span>
            <span class="pi pi-chevron-right float-right" v-else></span
            >&nbsp;</a
          >
        </div></slot
      >
      <div class="accordion-content" :id="id" :class="active ? 'in' : ''">
        <div :id="id2">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
export default {
  components: {},
  props: {
    propTitle: {
      Type: String,
      default: "",
    },
    propActive: {
      type: Boolean,
      default: true,
    },
    propTimeOut: {
      type: Number,
      default: 100,
    },
    isChevronEnd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: uuidv4(),
      id2: uuidv4(),
      l: undefined,
      w: undefined,
      active: true,
    };
  },
  created() {
    this.active = true;
    if (!this.propActive) {
      setTimeout(() => {
        this.onHeaderClick();
      }, 200);
    }
  },
  mounted() {
    this.w = $("#" + this.id);
    this.l = $("#" + this.id2);
    this.w.height(this.l.outerHeight(true));
    setTimeout(() => {
      this.w.height(!this.active ? 0 : "auto");
      this.w.css("overflow", "visible");
    }, this.propTimeOut);
  },
  methods: {
    onHeaderClick() {
      var vm = this;
      if (this.w.hasClass("in")) {
        this.w.removeClass("in");
        this.w.height(0);
        this.active = false;
        vm.w.css("overflow", "hidden");
      } else {
        setTimeout(() => {
          vm.w.css("overflow", "visible");
          this.w.height("auto");
        }, 100);
        this.w.addClass("in");
        this.w.height(this.l.outerHeight(true));
        this.active = true;
      }
      this.$emit("accordionHeaderClick");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.accordion {
  .accordion-header {
    cursor: pointer;
    position: relative;
    margin-top: 1px;
    width: 100%;
    zoom: 1;
    a {
      font-size: 14px;
      display: block;
      padding: 0.5rem 0.5rem;
      border: 1px solid #c8c8c8;
      color: #333333;
      background-color: #f4f4f4;
      color: #333333;
      font-weight: 700;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      border-radius: 2px;
      -moz-transition: background-color 0.2s, box-shadow 0.2s;
      -o-transition: background-color 0.2s, box-shadow 0.2s;
      -webkit-transition: background-color 0.2s, box-shadow 0.2s;
      transition: background-color 0.2s, box-shadow 0.2s;
      &.active {
        background-color: $background-color;
        border: 1px solid $background-color;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        color: white;
        span {
          color: white;
          top: 1px;
        }
      }
      span {
        color: #848484;
        position: relative;
        top: 2px;
      }
    }
  }
  .accordion-content {
    transition: height 200ms;
    background-color: #ffffff;
    overflow: hidden;
    &.in {
      border: 1px solid #c8c8c8;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }
}
</style>