<template>
  <button
    :class="containerClass"
    type="button"
    v-ripple
    @click="changePageToPrev"
  >
    <span class="p-paginator-icon pi pi-angle-left"></span>
  </button>
</template>

<script>
import Ripple from "@/components/commons/ripple/Ripple";

export default {
  methods: {
    changePageToPrev(e) {
      this.$emit("changePageToPrev", e);
    },
  },
  computed: {
    containerClass() {
      return [
        "p-paginator-prev p-paginator-element p-link",
        {
          "p-disabled": this.$attrs.disabled,
        },
      ];
    },
  },
  directives: {
    ripple: Ripple,
  },
};
</script>