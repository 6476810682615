<template>
  <div :id="id" :class="containerClass">
    <ul role="tablist">
      <template v-for="(item, index) of model">
        <li
          v-if="visible(item)"
          :key="item.code"
          :class="[
            index === 0 ? 'first' : '',
            index === model.length - 1 ? 'last' : '',
            getItemClass(item),
          ]"
          :style="item.style"
          role="tab"
          :aria-selected="isActive(item)"
          :aria-expanded="isActive(item)"
        >
          <span
            class="p-menuitem-link"
            @click="onItemClick($event, item)"
            v-if="!isItemDisabled(item)"
            role="presentation"
          >
            <span class="p-steps-number">{{ index + 1 }}</span>
            <span class="p-steps-title">{{ item.label }}</span>
          </span>
          <span v-else class="p-menuitem-link" role="presentation">
            <span class="p-steps-number">{{ index + 1 }}</span>
            <span class="p-steps-title">{{ item.label }}</span>
          </span>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import UniqueComponentId from "@/utils/commons/primevue/UniqueComponentId";
export default {
  props: {
    id: {
      type: String,
      default: UniqueComponentId(),
    },
    model: {
      type: Array,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    activeStep: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    onItemClick(event, item) {
      if (item.disabled || this.readonly) {
        event.preventDefault();
        return;
      }

      if (item.command) {
        item.command({
          originalEvent: event,
          item: item,
        });
      }
    },
    isActive(item) {
      return item.code && this.activeStep === item.code ? true : false;
    },
    getItemClass(item) {
      return [
        "p-steps-item",
        item.class,
        {
          "p-steps-current": this.isActive(item),

          "p-highlight": this.highlightItem(item),
        },
      ];
    },
    isItemDisabled(item) {
      return item.disabled || (this.readonly && !this.isActive(item));
    },
    visible(item) {
      return typeof item.visible === "function"
        ? item.visible()
        : item.visible !== false;
    },
    highlightItem(item) {
      return (
        this.model.findIndex((x) => x.code === item.code) < this.curentIndexStep
      );
    },
  },
  computed: {
    activeRoute() {
      return this.$route.path;
    },
    containerClass() {
      return ["p-steps p-component", { "p-steps-readonly": this.readonly }];
    },
    curentIndexStep() {
      return this.model.findIndex((x) => x.code === this.activeStep);
    },
  },
};
</script>

<style lang="scss">
.p-steps {
  position: relative;
}

.p-steps ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
}

.p-steps-item {
  position: relative;
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
}
.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid #dee2e6;
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}
// .first.p-steps-item:before {
//   left: 50% !important;
//   width: 50% !important;
// }
.first.p-steps-item:after {
  left: 50% !important;
  width: 50% !important;
  border-top: 5px solid red !important;
  z-index: 10000;
}
.last.p-steps-item:before {
  right: 50% !important;
  width: 50% !important;
}
.p-steps-item .p-menuitem-link {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  text-decoration: none;
}

.p-steps.p-steps-readonly .p-steps-item {
  cursor: auto;
}

.p-steps-item.p-steps-current .p-menuitem-link {
  cursor: default;
}

.p-steps-title {
  white-space: nowrap;
}

.p-steps-number {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-steps-title {
  display: block;
}

.p-steps-item.p-steps-current .p-menuitem-link {
  cursor: default;
}
.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 3px;
  background: #ffffff;
}
.p-steps-item .p-menuitem-link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  text-decoration: none;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #495057;
  border: 1px solid #e9ecef;
  background: #ffffff;
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 50%;
}
.p-steps-number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: #0b282f;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: #6c757d;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #2196f3;
  color: #ffffff;
}
</style>
