<template>
  <CommonAccordion :propTitle="'Capacity'" v-if="isShow">
    <div slot="content">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="capacity__progress-bar">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="amount-remaining">
                <h2>
                  {{
                    (propSubscription.amountMax -
                      propSubscription.amountConsumed)
                      | numberFormat
                  }}
                </h2>
                <span>remaining</span>
              </div>
              <div class="d-flex flex-column text-right days-remaining">
                <p class="mb-1">
                  {{ daysRemaining | numberFormat }} days to left
                </p>
                <span v-if="startDate"
                  >{{ (startDate.getTime() / 1000) | formatUnix
                  }}<span></span> to
                  <span v-if="endDate">{{
                    (endDate.getTime() / 1000) | formatUnix
                  }}</span></span
                >
              </div>
            </div>
            <ProgressBar
              :value="
                ((propSubscription.amountMax -
                  propSubscription.amountConsumed) /
                  propSubscription.amountMax) *
                100
              "
              :showValue="false"
            />
            <div class="d-flex number">
              <div class="amount-consumed amount d-flex flex-column">
                <p class="">
                  {{ propSubscription.amountConsumed | numberFormat }}
                </p>
                <span>Used</span>
              </div>

              <div class="amount-max amount d-flex flex-column">
                <p class="">{{ propSubscription.amountMax | numberFormat }}</p>
                <span>Purchased</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <h5>Available Sensors</h5>
          <table class="table table-bordered table-available-sensors">
            <thead>
              <tr>
                <th>Sensor</th>
                <th>Remaining</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="sensor in availableSensors" :key="sensor.name">
                <td>{{ sensor.name }}</td>
                <td>{{ sensor.remaining }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion3";
import ProgressBar from "@/components/commons/progressbar/ProgressBar";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
export default {
  components: {
    CommonAccordion,
    ProgressBar,
    DataTable,
    Column,
  },
  props: {
    propSubscription: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      availableSensors: [],
    };
  },
  mounted() {},
  watch: {
    propSubscription(val) {
      this.availableSensors = [];
      if (
        this.propSubscription &&
        this.propSubscription.availableSensors &&
        this.unitPrices &&
        this.propSubscription.availableSensors.length > 0
      ) {
        for (
          let i = 0;
          i < this.propSubscription.availableSensors.length;
          i++
        ) {
          this.availableSensors.push({
            name: this.propSubscription.availableSensors[i],
            remaining:
              this.unitPrices[this.propSubscription.availableSensors[i]],
          });
        }
      }
    },
  },
  computed: {
    isShow() {
      return this.propSubscription && this.propSubscription.albumOrdering
        ? this.propSubscription.albumOrdering
        : false;
    },
    lang() {
      return this.$store.getters.getLang;
    },
    startDate() {
      try {
        return new Date(this.propSubscription.startedAt);
      } catch (error) {
        return null;
      }
    },
    endDate() {
      try {
        return new Date(this.propSubscription.endedAt);
      } catch (error) {
        return null;
      }
    },
    daysRemaining() {
      if (this.startDate && this.endDate) {
        return Math.ceil(
          (this.endDate.getTime() - new Date().getTime()) / (1000 * 3600 * 24)
        );
      } else return 0;
    },
    unitPrices() {
      return this.propSubscription.unitPrices
        ? this.propSubscription.unitPrices
        : null;
    },
  },
};
</script>
<style lang="scss" scoped>
.capacity__progress-bar {
  background-color: #ebf0f3;
  border-radius: 0.5rem;
  border: 1px solid #eff2f5;
  padding: 1.25rem;
  .amount-remaining {
    display: flex;
    flex-direction: row;
    h2 {
      font-weight: 700;
      font-size: 36px;
      display: flex;
      margin: 0;
    }
    span {
      font-weight: 400;
      font-size: 16px;
      display: flex;
      align-items: flex-end;
      text-transform: uppercase;
      color: #3f4254;
      margin-left: 0.5rem;
    }
  }
  .days-remaining {
    text-align: right;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      text-transform: uppercase;
      color: #000000;
      text-align: right;
      width: 100%;
    }
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      align-items: center;
      color: #3f4254;
      text-align: right;
      width: 100%;
    }
  }
  .number {
    margin-top: 1rem;
    border-top: 2px solid white;
    .amount {
      padding: 0.5rem 1rem;
      padding-bottom: 0;
      p {
        font-weight: 700;
        margin-bottom: 0.375rem;
        line-height: 24px;
      }
      span {
        font-style: normal;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 18px;
      }
    }
    .amount-consumed {
      width: 9rem;
      border-right: 2px solid white;
    }
  }
}
table {
  border-radius: 0.25rem;
  thead {
    th {
      background-color: #ebf0f3;
      border-bottom: 0;
    }
  }
}
.p-progressbar {
  height: 0.75rem;
}
</style>
<style lang="scss">
.integrate__capacity {
  .p-progressbar-value {
    background: #009ef7 !important;
  }
}
</style>
