<template>
  <div class="tasking-production-parameters_optical">
    <div class="row no-gutters">
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label"
            >{{ $t("label_product") }}<span class="required">*</span></label
          >
          <div class="col-12 col-md-8">
            <Dropdown
              class="new-style w-100"
              :options="propProducts"
              v-model="propEntity.optical.product"
              :showClear="false"
              :filter="true"
              optionValue="id"
              optionLabel="value"
              @change="productChange"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label"
            >{{ $t("label_resampling_kernel") }}&nbsp;<span class="required"
              >*</span
            ></label
          >
          <div class="col-12 col-md-8">
            <Dropdown
              class="new-style w-100"
              :options="propResamplingKernels"
              v-model="propEntity.optical.resampling_kernel"
              :showClear="false"
              :filter="true"
              optionValue="id"
              optionLabel="value"
              @change="resamplingKernelChange"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label"
            >{{ $t("label_bands") }}&nbsp;<span class="required">*</span></label
          >
          <div class="col-12 col-md-8">
            <Dropdown
              class="new-style w-100"
              :options="propBands"
              v-model="propEntity.optical.bands"
              :showClear="false"
              :filter="true"
              optionValue="id"
              optionLabel="value"
              @change="bandsChange"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label"
            >{{ $t("label_license") }}&nbsp;<span class="required"
              >*</span
            ></label
          >
          <div class="col-12 col-md-8">
            <Dropdown
              class="new-style w-100"
              :options="propLicenses"
              v-model="propEntity.optical.license"
              :showClear="false"
              :filter="false"
              optionValue="id"
              optionLabel="value"
              @change="licenseChange"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label"
            >{{ $t("label_bit_depth") }}&nbsp;<span class="required"
              >*</span
            ></label
          >
          <div class="col-12 col-md-8">
            <Dropdown
              class="new-style w-100"
              :options="propBitDepths"
              v-model="propEntity.optical.bit_depth"
              :showClear="false"
              :filter="false"
              optionValue="id"
              optionLabel="value"
              @change="bitDepthChange"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_ortho_options")
          }}</label>
          <div class="col-12 col-md-8">
            <Dropdown
              class="new-style w-100"
              :options="propOrthoOptions"
              v-model="propEntity.optical.ortho_option"
              :showClear="true"
              :filter="false"
              optionValue="id"
              optionLabel="value"
              @change="orthoOptionChange"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label"
            >{{ $t("label_pixel_resolution")
            }}<span class="required">*</span></label
          >
          <div class="col-12 col-md-8">
            <Dropdown
              class="new-style w-100"
              :options="propPixelResolutions"
              v-model="propEntity.optical.pixel_resolution"
              :showClear="false"
              :filter="false"
              optionValue="id"
              optionLabel="value"
              @change="pixelResolutionChange"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label"
            >{{ $t("label_file_format") }} &nbsp;<span class="required"
              >*</span
            ></label
          >
          <div class="col-12 col-md-8">
            <Dropdown
              class="new-style w-100"
              :options="propFileFormats"
              v-model="propEntity.optical.file_format"
              :showClear="false"
              :filter="false"
              optionValue="id"
              optionLabel="value"
              @change="fileFormatChange"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-6 col-form-label"
            >{{ $t("label_dra_contrast_enhancement") }}&nbsp;<span
              class="required"
              >*</span
            ></label
          >
          <div class="col-12 col-md-6">
            <Dropdown
              class="new-style w-100"
              :options="propDRAContrasts"
              v-model="propEntity.optical.dra_contrast"
              :showClear="false"
              :filter="false"
              optionValue="id"
              optionLabel="value"
              @change="draContrastChange"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label"
            >{{ $t("label_tiling") }}&nbsp;<span class="required"
              >*</span
            ></label
          >
          <div class="col-12 col-md-8">
            <Dropdown
              class="new-style w-100"
              :options="propTilings"
              v-model="propEntity.optical.tiling"
              :showClear="false"
              :filter="false"
              optionValue="id"
              optionLabel="value"
              @change="tilingChange"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 col-md-6 pl-0">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label"
            >{{ $t("label_delivery_media") }}&nbsp;<span class="required"
              >*</span
            ></label
          >
          <div class="col-12 col-md-8">
            <Dropdown
              class="new-style w-100"
              :options="propDeliveriesMedia"
              v-model="propEntity.optical.delivery_media"
              :showClear="false"
              :filter="false"
              optionValue="id"
              optionLabel="value"
              @change="deliveryMediaChange"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <label class="col-2 col-form-label">{{ $t("label_note") }}</label>
      <div class="col-10">
        <Textarea
          class="form-control new-style"
          rows="2"
          maxlength="512"
          v-model="propEntity.note"
          :autoResize="true"
        >
        </Textarea>
      </div>
    </div>
  </div>
</template>
<script>
import Dropdown from "@/components/commons/dropdown/Dropdown";
import Textarea from "@/components/commons/textarea/Textarea";
export default {
  components: {
    Dropdown,
    Textarea,
  },
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },
    propProducts: {
      type: Array,
      default: () => [],
    },
    propBands: {
      type: Array,
      default: () => [],
    },
    propLicenses: {
      type: Array,
      default: () => [],
    },
    propBitDepths: {
      type: Array,
      default: () => [],
    },
    propPixelResolutions: {
      type: Array,
      default: () => [],
    },
    propDRAContrasts: {
      type: Array,
      default: () => [],
    },
    propResamplingKernels: {
      type: Array,
      default: () => [],
    },
    propOrthoOptions: {
      type: Array,
      default: () => [],
    },
    propFileFormats: {
      type: Array,
      default: () => [],
    },

    propTilings: {
      type: Array,
      default: () => [],
    },

    propDeliveriesMedia: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    productChange(event) {
      let item = this.propProducts.find((x) => x.id === event.value);
      this.propEntity.optical.product_name =
        item && item.value ? item.value.trim() : null;
    },
    resamplingKernelChange(event) {
      let item = this.propResamplingKernels.find((x) => x.id === event.value);
      this.propEntity.optical.resampling_kernel_name =
        item && item.value ? item.value.trim() : null;
    },
    bandsChange(event) {
      let item = this.propBands.find((x) => x.id === event.value);
      this.propEntity.optical.bands_name =
        item && item.value ? item.value.trim() : null;
    },
    licenseChange(event) {
      let item = this.propLicenses.find((x) => x.id === event.value);
      this.propEntity.optical.license_name =
        item && item.value ? item.value.trim() : null;
    },
    bitDepthChange(event) {
      let item = this.propBitDepths.find((x) => x.id === event.value);
      this.propEntity.optical.bit_depth_name =
        item && item.value ? item.value.trim() : null;
    },
    orthoOptionChange(event) {
      let item = this.propOrthoOptions.find((x) => x.id === event.value);
      this.propEntity.optical.ortho_option_name =
        item && item.value ? item.value.trim() : null;
    },
    pixelResolutionChange(event) {
      let item = this.propPixelResolutions.find((x) => x.id === event.value);
      this.propEntity.optical.pixel_resolution_name =
        item && item.value ? item.value.trim() : null;
    },
    fileFormatChange(event) {
      let item = this.propFileFormats.find((x) => x.id === event.value);
      this.propEntity.optical.file_format_name =
        item && item.value ? item.value.trim() : null;
    },
    draContrastChange(event) {
      let item = this.propDRAContrasts.find((x) => x.id === event.value);
      this.propEntity.optical.dra_contrast_name =
        item && item.value ? item.value.trim() : null;
    },
    tilingChange(event) {
      let item = this.propTilings.find((x) => x.id === event.value);
      this.propEntity.optical.tiling_name =
        item && item.value ? item.value.trim() : null;
    },
    deliveryMediaChange(event) {
      let item = this.propDeliveriesMedia.find((x) => x.id === event.value);
      this.propEntity.optical.delivery_media_name =
        item && item.value ? item.value.trim() : null;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.tasking-production-parameters_optical {
  padding: 0 0.5rem;
  width: 100%;
  color: $text-color;
  .row {
    margin-bottom: 1rem;
    .col-md-6 {
      padding-right: 0.25rem;
      &:last-child {
        padding-right: 0;
        padding-left: 0.25rem;
      }
    }
    .row {
      margin: 0;
    }
  }
  textarea {
    font-size: 14px;
  }
}
</style>
<style lang="scss">
.tasking-production-parameters_optical {
  .p-dropdown-item {
    white-space: pre-wrap !important;
  }
}
</style>
