<template>
  <CommonAccordion
    :propTitle="propBundle.id + ' (' + propBundle.length + ' items)'"
    :isChevronEnd="true"
  >
    <div slot="content" class="p-2">
      <div class="bundles">
        <div
          class="bundle-card"
          v-for="item in propBundle.values"
          :key="item.id"
          :class="selected && selected.id == item.id ? 'active' : ''"
          @click="selected = item"
        >
          <h6 class="bundle-name">{{ item.name }}</h6>
          <p class="bundle-description">{{ item.description }}</p>
          <div
            class="chip-container flex"
            v-if="item.auxiliaryFiles === 'UDM2'"
          >
            <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
              <path
                d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16-7-8v8H5l7-8V5h7v14z"
              ></path>
            </svg>
            <span>UDM2</span>
          </div>
        </div>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
export default {
  components: {
    CommonAccordion,
  },
  props: {
    propBundle: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    setTimeout(() => {
      if (
        this.propBundle &&
        this.propBundle.values &&
        this.propBundle.values.length > 0
      )
        this.selected = this.propBundle.values[0];
    }, 1000);
  },
  watch: {
    "selected.id"(val) {
      this.$emit("selectedBundle", {
        id: this.propBundle.id,
        key: this.propBundle.id,
        assets: val,
        assets_item: this.selected,
        bundle_name:
          this.selected && this.selected.name ? this.selected.name : null,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bundles {
  display: grid;
  width: 100%;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
  grid-template-columns: repeat(2, 1fr);
  .bundle-card {
    padding: 1rem;
    border-radius: 0.25rem;
    border: 1px solid rgb(189, 197, 198);
    background: white;
    &:hover {
      cursor: pointer;
      background-color: rgba(#009ef7, 0.25);
    }
    &.active {
      border: 1px solid #009ef7;
      background-color: rgba(#009ef7, 0.25);
    }
    h6 {
      margin: 0px 0px 0.35em;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.57;
    }
    p {
      margin: 0px;
      letter-spacing: -0.01429em;
      line-height: 1.42857;
      font-weight: 400;
      font-size: 0.875rem;
      display: block;
    }
    .chip-container {
      margin-top: 0.75rem;
      font-size: 0.8125rem;
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      height: 24px;
      color: rgb(0, 31, 35);
      background-color: rgba(0, 0, 0, 0.08);
      white-space: nowrap;
      transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      cursor: default;
      outline: 0px;
      text-decoration: none;
      border: 0px;
      padding: 0px 0.375rem;
      vertical-align: middle;
      box-sizing: border-box;
      border-radius: 4px;
      svg {
        width: 16px;
        height: 16px;
        display: inline-block;
      }
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 4px;
        padding-top: 2px;
        margin: auto 0;
        text-align: center;
      }
    }
  }
}
</style>
