<template>
  <canvas :id="'myChart' + id" width="100%" height="100%"></canvas>
</template>
<script>
import * as Chart from "chart.js";
// import "@/utils/commons/chartjs-plugin-labels";
export default {
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: "bar",
    },
  },
  data() {
    return {
      id: null,
      myLineChart: undefined,
    };
  },
  created() {
    this.id =
      new Date().getTime() -
      Math.floor(Math.random() * 1000) +
      Math.floor(Math.random() * 9999);
  },
  mounted() {
    var canvas = document.getElementById("myChart" + this.id);

    this.myLineChart = new Chart(canvas, {
      type: "pie",
      data: this.chartdata,
      options: this.options,
    });
  },
  methods: {
    updateChart() {
      if (this.myLineChart) this.myLineChart.update();
    },
  },
};
</script>