<template>
  <CommonAccordion :propTitle="$t('title_search')">
    <div slot="content" class="accordion">
      <div class="form-search">
        <div class="form-row no-gutters">
          <div class="col-2">
            <label class="control-label">{{ $t("label_date_from") }}</label>
            <date-picker
              v-model="search.from"
              class="w-100"
              :format="'DD/MM/YYYY'"
              valuetype="timestamp"
              :disabled-date="notAfterToday"
              :input-class="'form-control'"
              :lang="lang"
            >
            </date-picker>
          </div>
          <div class="col-2">
            <label class="control-label">{{ $t("label_date_to") }}</label>
            <date-picker
              v-model="search.to"
              class="w-100"
              :format="'DD/MM/YYYY'"
              valuetype="timestamp"
              :disabled-date="notAfterToday"
              :input-class="'form-control'"
              :lang="lang"
            >
            </date-picker>
          </div>
          <div class="col-2">
            <button
              v-if="propButton.visible"
              :disabled="propButton.disabled"
              type="button"
              class="btn btn-geostore2 btn-sm btn-w120-h36"
              @click="onSearch"
            >
              <i
                :class="
                  propButton.processing
                    ? 'fas fa-spinner fa-spin'
                    : 'fas fa-search'
                "
              ></i
              >&nbsp;{{ $t("button_text_search") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import CommonAccordion from "@/components/commons/accordion/Accordion2";
export default {
  components: {
    DatePicker,
    Dropdown,
    CommonAccordion,
  },
  props: {
    propSearch: {
      type: Object,
      default: () => {
        return {
          from: null,
          to: null,
        };
      },
    },
    propButton: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search: {
        from: null,
        to: null,
      },
    };
  },
  created() {
    this.search.from = this.propSearch.from;
    this.search.to = this.propSearch.to;
  },
  watch: {
    propSearch: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          this.search.from = val.from;
          this.search.to = val.to;
        }
      },
      immediate: true,
    },
  },
  methods: {
    onSearch() {
      if (!this.search.from && this.search.to) {
        this.$emit("message", [
          this.$t("message_search_from_time_is_required"),
        ]);
        return;
      } else if (this.search.from && !this.search.to) {
        this.$emit("message", [this.$t("message_search_to_time_is_required")]);
        return;
      } else if (this.search.from && this.search.to) {
        let from = new Date(this.search.from).setHours(0, 0, 0, 0);
        let to = new Date(this.search.to).setHours(23, 59, 59, 0);
        if (from - to > 0) {
          this.$emit("message", [
            this.$t(
              "message_search_to_time_must_greater_than_or_equal_from_time"
            ),
          ]);
          return;
        }
      }
      this.$emit("onSearch", this.search);
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.form-search {
  padding: 0.5rem;
  button {
    margin-top: 1.25rem;
    font-size: 14px;
  }
  label {
    margin: 0;
    padding: 0;
    padding-left: 0.25rem;
    color: $text-color;
  }
  input {
    background: #eef3f7;
    border-radius: 4px;
    border-color: #eef3f7;
    &::placeholder {
      color: #b3c3d4;
    }
  }
}
.form-group {
  margin-bottom: 0.5rem;
  margin: 0;
  label {
    padding-left: 0.25rem;
  }
}
</style>

<style lang="scss">
.statistic-report__filter {
  .mx-input-wrapper {
    input {
      font-size: 14px;
      background: #eef3f7;
      border-radius: 4px;
      border-color: #eef3f7 !important;
      &:hover {
        border-color: #212121 !important;
      }
      &:disabled,
      .disabled {
        background-color: transparent;
      }
    }
  }
}
</style>
