<template>
  <div class="tasking-confirmation">
    <div class="confirmation__buyer-info">
      <CommonAccordion :isChevronEnd="true" :propTitle="$t('label_buyer_info')"
        ><div slot="content" class="accordion w-100">
          <div class="row no-gutters">
            <div class="col-12 col-md-6">
              <div class="row no-gutters">
                <label class="col-12 col-md-4 col-form-label"
                  >{{ $t("label_buyer_name") }}&nbsp;<span class="required"
                    >*</span
                  ></label
                >
                <div class="col-12 col-md-8">
                  <InputText
                    type="text"
                    id="inutBuyerName"
                    autofocus
                    class="form-control new-style w-100"
                    maxlength="50"
                    v-model="propEntity.buyer_name"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row no-gutters">
                <label class="col-12 col-md-4 col-form-label"
                  >{{ $t("label_organisation_name") }}&nbsp;<span
                    class="required"
                    >*</span
                  ></label
                >
                <div class="col-12 col-md-8">
                  <InputText
                    type="text"
                    class="form-control new-style w-100"
                    maxlength="255"
                    v-model="propEntity.buyer_organization"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-12 col-md-6">
              <div class="row no-gutters">
                <label class="col-12 col-md-4 col-form-label"
                  >{{ $t("label_country") }}&nbsp;<span class="required"
                    >*</span
                  ></label
                >
                <div class="col-12 col-md-8">
                  <AutoComplete
                    v-model="propEntity.buyer_country"
                    :suggestions="suggestionsCountry"
                    @complete="searchCountry($event)"
                    :dropdown="false"
                    field="native"
                    class="new-style"
                    :inputClass="'w-100'"
                    @item-select="itemSelect"
                    @clear="unSelectCountry"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row no-gutters">
                <label class="col-12 col-md-4 col-form-label"
                  >{{ $t("label_province") }}&nbsp;<span class="required"
                    >*</span
                  ></label
                >
                <div class="col-12 col-md-8">
                  <AutoComplete
                    v-model="propEntity.buyer_province"
                    :suggestions="suggestionsProvince"
                    @complete="searchProvince($event)"
                    :dropdown="false"
                    field="name"
                    class="new-style"
                    :inputClass="'w-100'"
                    @item-select="selectProvince"
                    @clear="unSelectProvince"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-12 col-md-6">
              <div class="row no-gutters">
                <label class="col-12 col-md-4 col-form-label"
                  >{{ $t("label_address") }}&nbsp;<span class="required"
                    >*</span
                  ></label
                >
                <div class="col-12 col-md-8">
                  <InputText
                    type="text"
                    class="form-control new-style w-100"
                    maxlength="255"
                    v-model="propEntity.buyer_address"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row no-gutters">
                <label class="col-12 col-md-4 col-form-label"
                  >{{ $t("label_zip_code") }}&nbsp;<span class="required"
                    >*</span
                  ></label
                >
                <div class="col-12 col-md-8">
                  <InputText
                    type="text"
                    class="form-control new-style w-100"
                    maxlength="20"
                    v-model="propEntity.buyer_zip_code"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-12 col-md-6">
              <div class="row no-gutters">
                <label class="col-12 col-md-4 col-form-label"
                  >{{ $t("label_phone_number") }}&nbsp;<span class="required"
                    >*</span
                  ></label
                >
                <div class="col-12 col-md-8">
                  <InputText
                    type="tel"
                    class="form-control new-style w-100"
                    maxlength="10"
                    v-model="propEntity.buyer_phone_number"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row no-gutters">
                <label class="col-12 col-md-4 col-form-label"
                  >{{ $t("label_email") }}&nbsp;<span class="required"
                    >*</span
                  ></label
                >
                <div class="col-12 col-md-8">
                  <InputText
                    class="form-control new-style w-100"
                    maxlength="150"
                    v-model="propEntity.buyer_email"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </CommonAccordion>
    </div>
    <div class="confirmation__area-of-interest">
      <CommonAccordion
        :isChevronEnd="true"
        :propTitle="$t('label_step_area_of_interest')"
        ><div slot="content" class="accordion w-100">
          <div class="row no-gutters">
            <div class="col-12 col-md-6">
              <div class="row no-gutters">
                <label class="col-12 col-md-4 col-form-label">{{
                  $t("label_area_name")
                }}</label>
                <div class="col-12 col-md-8">
                  <InputText
                    class="form-control new-style w-100"
                    maxlength="255"
                    :value="dto.area_name"
                    readonly
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row no-gutters">
                <label class="col-12 col-md-4 col-form-label">{{
                  $t("label_number_of_scenes")
                }}</label>
                <div class="col-12 col-md-8">
                  <InputText
                    class="form-control new-style w-100"
                    maxlength="10"
                    :value="dto.number_of_scenes"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-12 col-md-6">
              <div class="row no-gutters">
                <label class="col-12 col-md-4 col-form-label">{{
                  $t("label_coordinate_system")
                }}</label>
                <div class="col-12 col-md-8">
                  <InputText
                    class="form-control new-style w-100"
                    maxlength="50"
                    :value="'WGS84'"
                    readonly
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row no-gutters">
                <label class="col-12 col-md-4 col-form-label">{{
                  $t("label_satellite")
                }}</label>
                <div class="col-12 col-md-8">
                  <InputText
                    class="form-control new-style w-100"
                    maxlength="50"
                    :value="dto.satellite_name"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
          <fieldset
            class="p-0"
            v-if="
              dto.coordinates_form &&
              dto.coordinates_form.trim().toLowerCase() == 'radius'
            "
          >
            <legend class="w-auto">{{ $t("label_center_coordinates") }}</legend>
            <div class="row no-gutters">
              <div class="col-12 col-md-4">
                <div class="row no-gutters">
                  <label class="col-12 col-md-4 col-form-label">{{
                    $t("label_latitude")
                  }}</label>
                  <div class="col-12 col-md-8">
                    <InputText
                      class="form-control new-style w-100"
                      :value="dto.centre.lat"
                      readonly
                      maxlength="9"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="row no-gutters">
                  <label class="col-12 col-md-4 col-form-label">{{
                    $t("label_longitude")
                  }}</label>
                  <div class="col-12 col-md-8">
                    <InputText
                      class="form-control new-style w-100"
                      :value="dto.centre.lng"
                      readonly
                      maxlength="10"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="row no-gutters">
                  <label class="col-12 col-md-4 col-form-label">{{
                    $t("label_radius")
                  }}</label>
                  <div class="col-12 col-md-8">
                    <InputText
                      class="form-control new-style w-100"
                      :value="dto.centre.radius"
                      readonly
                      maxlength="4"
                    />
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset
            class="p-0"
            v-else-if="
              dto.coordinates_form &&
              dto.coordinates_form.trim().toLowerCase() == 'polygon'
            "
          >
            <legend class="w-auto">{{ $t("label_corner_coordinates") }}</legend>
            <div class="row no-gutters w-100">
              <div class="col-12 col-md-6 corner-group">
                <div class="row corner-group">
                  <label class="col-2 text-position pl-0 pr-0">{{
                    $t("label_upper_left")
                  }}</label>
                  <div class="col-5">
                    <label for="inputRadiuslat" class="col-form-label">{{
                      $t("label_latitude")
                    }}</label>
                    <InputText
                      type="text"
                      class="form-control new-style"
                      readonly
                      maxlength="9"
                      :value="upperLeft.lat"
                    />
                  </div>
                  <div class="col-5">
                    <label for="inputRadiusLng" class="col-form-label">{{
                      $t("label_longitude")
                    }}</label>
                    <InputText
                      type="text"
                      class="form-control new-style"
                      readonly
                      maxlength="10"
                      :value="upperLeft.lng"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 corner-group">
                <div class="row corner-group">
                  <label class="col-2 text-position pl-0 pr-0">{{
                    $t("label_upper_right")
                  }}</label>
                  <div class="col-5">
                    <label for="inputRadiuslat" class="col-form-label">{{
                      $t("label_latitude")
                    }}</label>
                    <InputText
                      type="text"
                      class="form-control new-style"
                      readonly
                      maxlength="9"
                      :value="upperRight.lat"
                    />
                  </div>
                  <div class="col-5">
                    <label for="inputRadiusLng" class="col-form-label">{{
                      $t("label_longitude")
                    }}</label>
                    <InputText
                      type="text"
                      class="form-control new-style"
                      readonly
                      maxlength="10"
                      :value="upperRight.lng"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters w-100">
              <div class="col-12 col-md-6 corner-group">
                <div class="row corner-group">
                  <label class="col-2 text-position pl-0 pr-0">{{
                    $t("label_lower_left")
                  }}</label>
                  <div class="col-5">
                    <label for="inputRadiuslat" class="col-form-label">{{
                      $t("label_latitude")
                    }}</label>
                    <InputText
                      type="text"
                      class="form-control new-style"
                      readonly
                      maxlength="9"
                      :value="lowerLeft.lat"
                    />
                  </div>
                  <div class="col-5">
                    <label for="inputRadiusLng" class="col-form-label">{{
                      $t("label_longitude")
                    }}</label>
                    <InputText
                      type="text"
                      class="form-control new-style"
                      readonly
                      maxlength="10"
                      :value="lowerLeft.lng"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 corner-group">
                <div class="row corner-group">
                  <label class="col-2 text-position pl-0 pr-0">{{
                    $t("label_lower_right")
                  }}</label>
                  <div class="col-5">
                    <label for="inputRadiuslat" class="col-form-label">{{
                      $t("label_latitude")
                    }}</label>
                    <InputText
                      type="text"
                      class="form-control new-style"
                      readonly
                      maxlength="9"
                      :value="lowerRight.lat"
                    />
                  </div>
                  <div class="col-5">
                    <label for="inputRadiusLng" class="col-form-label">{{
                      $t("label_longitude")
                    }}</label>
                    <InputText
                      type="text"
                      class="form-control new-style"
                      readonly
                      maxlength="10"
                      :value="lowerRight.lng"
                    />
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <div class="row no-gutters">
            <div class="col-12">
              <div class="row no-gutters">
                <label class="col-12 col-md-2 col-form-label">{{
                  $t("label_file_name")
                }}</label>
                <div class="col-12 col-md-10">
                  <InputText
                    class="form-control new-style w-100"
                    maxlength="255"
                    :value="fileNameSearch"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </CommonAccordion>
    </div>

    <div class="confirmation__tasking">
      <CommonAccordion
        :isChevronEnd="true"
        :propTitle="$t('label_step_tasking')"
        ><div slot="content" class="accordion w-100">
          <ConfirmTaskingTimeOptical
            :propDto="dto"
            v-if="
              dto.satellite_type &&
              dto.satellite_type.trim().toLowerCase() === 'optical'
            "
          />
          <ConfirmTaskingTimeRadar
            :propDto="dto"
            :propLookDirection="propLookDirection"
            :propOrbitDirection="propOrbitDirection"
            v-else-if="
              dto.satellite_type &&
              dto.satellite_type.trim().toLowerCase() === 'radar'
            "
          />
        </div>
      </CommonAccordion>
    </div>

    <div
      class="confirmation__sensor-mode"
      v-if="
        dto.satellite_type &&
        dto.satellite_type.trim().toLowerCase() === 'radar'
      "
    >
      <CommonAccordion
        :isChevronEnd="true"
        :propTitle="$t('label_step_sensor_mode_and_polatization')"
        ><div slot="content" class="accordion w-100">
          <div class="row no-gutters">
            <div class="col-12 col-md-6">
              <div class="row no-gutters">
                <label class="col-12 col-md-4 col-form-label">{{
                  $t("label_image_mode")
                }}</label>
                <div class="col-12 col-md-8">
                  <InputText
                    class="form-control new-style w-100"
                    maxlength="255"
                    :value="dto.radar.beam_mode_name"
                    readonly
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row no-gutters">
                <label class="col-12 col-md-4 col-form-label">{{
                  $t("label_pol_layer")
                }}</label>
                <div class="col-12 col-md-8">
                  <InputText
                    class="form-control new-style w-100"
                    maxlength="255"
                    :value="dto.radar.pol_layer_name"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
          <fieldset class="p-0">
            <legend class="w-auto">{{ $t("label_interferometry") }}</legend>
            <div class="row no-gutters">
              <div class="col-12 col-md-6">
                <div class="row no-gutters">
                  <label class="col-12 col-md-4 col-form-label">{{
                    $t("label_number_of_iterations")
                  }}</label>
                  <div class="col-12 col-md-8">
                    <InputText
                      class="form-control new-style w-100"
                      maxlength="255"
                      :value="dto.radar.number_of_iterations"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-2">
                <label>{{ $t("label_granularity") }}</label>
              </div>
              <div class="col-10">
                <div class="row no-gutters">
                  <div
                    class="form-group m-0 pr-3"
                    v-for="(item, index) in propGranularities"
                    :key="'granularity' + index"
                  >
                    <Checkbox
                      v-model="granularity"
                      :value="item.id"
                      :id="item.id"
                      :readonly="true"
                    />
                    <label class="p-checkbox-label">{{ item.value }}</label>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </CommonAccordion>
    </div>

    <div class="confirmation__programing-service">
      <CommonAccordion
        :isChevronEnd="true"
        :propTitle="$t('label_step_programming_service')"
        ><div slot="content" class="accordion w-100">
          <div class="row no-gutters">
            <div
              class="form-group m-0 pr-5"
              v-for="(item, index) in propProgramingServices"
              :key="'programing-service' + index"
            >
              <Checkbox
                v-model="programmingService"
                :value="item.id"
                :id="item.id"
                :readonly="true"
              />
              <label class="p-checkbox-label">{{ item.value }}</label>
            </div>
          </div>
        </div>
      </CommonAccordion>
    </div>
    <div class="confirmation__confirmation__production-parameters">
      <CommonAccordion
        :isChevronEnd="true"
        :propTitle="$t('label_step_production_parameters')"
        ><div slot="content" class="accordion w-100">
          <ConfirmProductionParametersOptical
            :propDto="dto"
            v-if="
              dto.satellite_type &&
              dto.satellite_type.trim().toLowerCase() === 'optical'
            "
          />
          <ConfirmProductionParametersRadar
            :propDto="dto"
            v-else-if="
              dto.satellite_type &&
              dto.satellite_type.trim().toLowerCase() === 'radar'
            "
          />
        </div>
      </CommonAccordion>
    </div>
  </div>
</template>
<script>
import InputText from "@/components/commons/inputtext/InputText";
import InputNumber from "@/components/commons/inputnumber/InputNumber";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import AutoComplete from "@/components/commons/autocomplete/AutoComplete";
import DatePicker from "vue2-datepicker";
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import Checkbox from "@/components/commons/checkbox/Checkbox";
import ConfirmProductionParametersOptical from "./confirmation/ProductionParametersOptical";
import ConfirmProductionParametersRadar from "./confirmation/ProductionParametersRadar";
import ConfirmTaskingTimeRadar from "./confirmation/TaskingTimeRadar.vue";
import ConfirmTaskingTimeOptical from "./confirmation/TaskingTimeOptical.vue";
import moment from "moment";
import { change_alias } from "@/utils/commons/common";
export default {
  components: {
    InputText,
    InputNumber,
    Dropdown,
    AutoComplete,
    DatePicker,
    CommonAccordion,
    Checkbox,
    ConfirmProductionParametersOptical,
    ConfirmProductionParametersRadar,
    ConfirmTaskingTimeRadar,
    ConfirmTaskingTimeOptical,
  },
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },
    propGranularities: { type: Array, default: () => [] },
    propProgramingServices: {
      type: Array,
      default: () => [],
    },
    propCountry: {
      type: Array,
      default: () => [],
    },
    propProvinces: {
      type: Array,
      default: () => [],
    },
    propLookDirection: {
      type: Array,
      default: () => [],
    },
    propOrbitDirection: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dto: {},
      granularity: [],
      programmingService: [],
      upperLeft: {
        lat: null,
        lng: null,
      },
      upperRight: {
        lat: null,
        lng: null,
      },
      lowerLeft: {
        lat: null,
        lng: null,
      },
      lowerRight: {
        lat: null,
        lng: null,
      },

      suggestionsCountry: [],
      suggestionsProvince: [],
    };
  },
  created() {
    if (!this.propCountry || this.propCountry.length === 0) {
      this.$emit("getAllCountry");
    }
    try {
      if (!this.propEntity.buyer_name) {
        this.propEntity.buyer_name =
          this.userInfo && this.userInfo.full_name
            ? this.userInfo.full_name.trim()
            : null;
      }
      if (!this.propEntity.buyer_address) {
        this.propEntity.buyer_address =
          this.userInfo && this.userInfo.address
            ? this.userInfo.address.trim()
            : null;
      }
      if (!this.propEntity.buyer_phone_number) {
        this.propEntity.buyer_phone_number =
          this.userInfo && this.userInfo.phone_number
            ? this.userInfo.phone_number.trim()
            : null;
      }
      if (!this.propEntity.buyer_organization) {
        this.propEntity.buyer_organization =
          this.userInfo && this.userInfo.organization
            ? this.userInfo.organization.trim()
            : null;
      }
      if (!this.propEntity.buyer_email) {
        this.propEntity.buyer_email =
          this.userInfo && this.userInfo.email
            ? this.userInfo.email.trim()
            : null;
      }
    } catch (error) {}
    this.dto = Object.assign({}, this.propEntity);
    if (this.dto.coordinates) {
      if (
        this.dto.coordinates[0] &&
        Object.keys(this.dto.coordinates[0]).length > 0
      ) {
        this.upperLeft.lat = this.dto.coordinates[0].lat
          ? this.dto.coordinates[0].lat
          : null;
        this.upperLeft.lng = this.dto.coordinates[0].lng
          ? this.dto.coordinates[0].lng
          : null;
      }
      if (
        this.dto.coordinates[1] &&
        Object.keys(this.dto.coordinates[1]).length > 0
      ) {
        this.upperRight.lat = this.dto.coordinates[1].lat
          ? this.dto.coordinates[1].lat
          : null;
        this.upperRight.lng = this.dto.coordinates[1].lng
          ? this.dto.coordinates[1].lng
          : null;
      }

      if (
        this.dto.coordinates[3] &&
        Object.keys(this.dto.coordinates[3]).length > 0
      ) {
        this.lowerLeft.lat = this.dto.coordinates[3].lat
          ? this.dto.coordinates[3].lat
          : null;
        this.lowerLeft.lng = this.dto.coordinates[3].lng
          ? this.dto.coordinates[3].lng
          : null;
      }
      if (
        this.dto.coordinates[2] &&
        Object.keys(this.dto.coordinates[2]).length > 0
      ) {
        this.lowerRight.lat = this.dto.coordinates[2].lat
          ? this.dto.coordinates[2].lat
          : null;
        this.lowerRight.lng = this.dto.coordinates[2].lng
          ? this.dto.coordinates[2].lng
          : null;
      }
    }
    this.granularity = this.propEntity.radar.granularity
      ? [this.propEntity.radar.granularity]
      : [];
    this.programmingService = this.propEntity.programming_service
      ? [this.propEntity.programming_service]
      : [];
  },
  mounted() {
    document.getElementById("inutBuyerName").focus();
  },
  methods: {
    searchCountry(e) {
      this.suggestionsCountry = this.propCountry.filter(
        (x) =>
          x.native &&
          (change_alias(x.native.trim().split(" ").join("")).indexOf(
            change_alias(e.query.toLowerCase().split(" ").join(""))
          ) >= 0 ||
            change_alias(x.native.trim().split(" ").join("")).search(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            ) >= 0 ||
            change_alias(x.name.trim().split(" ").join("")).indexOf(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            ) >= 0 ||
            change_alias(x.name.trim().split(" ").join("")).search(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            ) >= 0)
      );
    },
    searchProvince(e) {
      this.suggestionsProvince = this.propProvinces.filter(
        (x) =>
          x.name &&
          (change_alias(x.name.trim().split(" ").join("")).indexOf(
            change_alias(e.query.toLowerCase().split(" ").join(""))
          ) >= 0 ||
            change_alias(x.name.trim().split(" ").join("")).search(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            ) >= 0)
      );
    },
    selectProvince(event) {
      this.propEntity.buyer_province_id =
        event && event.value ? event.value.state_id : null;
      this.propEntity.buyer_province =
        event && event.value ? event.value.name : null;
    },
    unSelectProvince() {
      this.propEntity.buyer_province_obj = null;
      this.propEntity.buyer_province_id = null;
      this.propEntity.buyer_province = null;
    },
    unSelectCountry() {
      this.$emit("getStateByCountryId", null);
      this.propEntity.buyer_country_obj = null;
      this.propEntity.buyer_country_id = null;
      this.propEntity.buyer_country = null;
    },
    itemSelect(event) {
      if (event && event.value) {
        this.countryChange(event);
      } else {
        this.unSelectCountry();
      }
    },
    countryChange(event) {
      this.$emit(
        "getStateByCountryId",
        event && event.value ? event.value.country_id : null
      );
      this.propEntity.buyer_country_id =
        event && event.value ? event.value.country_id : null;
      this.propEntity.buyer_country =
        event && event.value ? event.value.native : null;
    },
    provinceChange(event) {
      if (event && event.value) {
        let item = this.propProvinces.find((x) => x.state_id === event.value);
        this.propEntity.buyer_province =
          item && item.name ? item.name.trim() : null;
      } else {
        this.unSelectProvince();
      }
    },
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
  },
  computed: {
    userInfo() {
      return this.$store.getters.getUser;
    },
    lang() {
      return this.$store.getters.getLang;
    },
    fileNameSearch() {
      return this.$store.getters.getFileNameSearch;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.tasking-confirmation {
  padding: 0 0.5rem;
  width: 100%;
  color: $text-color;
  .row {
    margin-bottom: 1rem;
    .col-md-6 {
      padding-right: 0.25rem;
      &:last-child {
        padding-right: 0;
        padding-left: 0.25rem;
      }
    }
    .corner-group {
      label {
        &.text-position {
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }
      }
      .col-5 {
        padding-right: 0.25rem;
        &:last-child {
          padding-left: 0.25rem;
          padding-right: 0;
        }
      }
    }
    .row {
      margin: 0;
    }
  }
  legend {
    font-size: 14px;
  }
  .row {
    .col-md-4 {
      padding-right: 0.25rem;
      padding-left: 0.25rem;
      &:last-child {
        padding-right: 0;
      }
      &:first-child {
        padding-left: 0;
      }
    }
  }
}
</style>
<style lang="scss">
.tasking-confirmation {
  .mx-input-wrapper {
    input {
      font-size: 14px;
      background: #f5f8fa;
      border-radius: 4px;
      border-color: #f5f8fa;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      &:hover {
        border-color: #212121 !important;
      }
      &:disabled,
      .disabled {
        background-color: transparent;
      }
    }
  }
  .p-autocomplete {
    width: 100%;
    input {
      width: 100%;
    }
  }
  .accordion {
    width: 100%;
    .accordion-header a {
      padding-left: 0 !important;
    }
  }
}
</style>
