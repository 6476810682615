<template>
  <div
    class="criteria animate__animated"
    :class="propShow ? 'animate__fadeInLeft' : 'animate__fadeOutLeft'"
    :style="{ width: widgetSize }"
  >
    <div class="criteria__header">
      <h6 class="criteria__header-title">
        <span>{{ $t("title_advanced_search") }}</span>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          @click="onClose"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </h6>
    </div>
    <div class="criteria__body" :style="{ height: bodyHeight + 'px' }">
      <div>
        <CommonAccordion
          :propTitle="''"
          class="search-advanced--accordion search-criteia__accordion"
        >
          <div slot="accordion-header" class="accordion-header">
            <a>Partner</a>
          </div>
          <div slot="content" class="text-center">
            <div class="select-partner d-flex">
              <div class="field-checkbox" v-for="p in partners" :key="p.id">
                <Checkbox
                  :name="p.code"
                  :value="p.code"
                  v-model="propCriteria.partners4Search"
                  :id="p.id"
                />
                <label :for="p.id">{{ p.label }}</label>
              </div>
            </div>
          </div>
        </CommonAccordion>
      </div>
      <div>
        <CommonAccordion
          :propTitle="''"
          class="search-advanced--accordion search-criteia__accordion"
        >
          <div slot="accordion-header" class="accordion-header">
            <a>{{ $t("title_search_criteria") }}</a>
          </div>
          <div slot="content">
            <div class="search-criteia__content">
              <div class="form-row">
                <div class="col-12 col-md-3">
                  <label for="criteria__search-from">{{
                    $t("label_time")
                  }}</label>
                </div>
                <div class="col-12 col-md-9 row no-gutters pr-0">
                  <div class="col">
                    <div class="form-group">
                      <label style="width: 40px" class="range-time-label">{{
                        $t("label_from")
                      }}</label>
                      <date-picker
                        id="criteria__search-from"
                        style="width: calc(100% - 40px)"
                        format="DD/MM/YYYY"
                        :disabled-date="notAfterToday"
                        v-model="propCriteria.start_time_from"
                        value-type="timestamp"
                        :lang="lang"
                      ></date-picker>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label
                        class="range-time-label"
                        style="width: 38px; margin-left: 2px"
                        >{{ $t("label_to") }}</label
                      >
                      <date-picker
                        style="width: calc(100% - 40px)"
                        id="criteria__search-to"
                        value-type="timestamp"
                        format="DD/MM/YYYY"
                        :disabled-date="notAfterToday"
                        v-model="propCriteria.start_time_to"
                        :lang="lang"
                      ></date-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-12 col-md-5">
                  <label for="criteria__search-from"
                    >{{ $t("label_resolution") }}
                    <span
                      >[{{
                        propCriteria.resolution[0] +
                        "-" +
                        propCriteria.resolution[1]
                      }}]</span
                    >&nbsp;(m)</label
                  >
                </div>
                <div class="col-12 col-md-7">
                  <div class="slider-control">
                    <Slider
                      :modelValue="propCriteria.resolution"
                      :range="true"
                      :step="0.1"
                      :min="0"
                      :max="40"
                      @change="resolutionChange"
                    />
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-12 col-md-5">
                  <label for="criteria__search-from"
                    >{{ $t("label_incidence_angle") }}
                    <span
                      >[{{
                        propCriteria.incidence_angle[0] +
                        "-" +
                        propCriteria.incidence_angle[1]
                      }}]</span
                    >(°)</label
                  >
                </div>
                <div class="col-12 col-md-7">
                  <div class="slider-control">
                    <Slider
                      :modelValue="propCriteria.incidence_angle"
                      :range="true"
                      :step="0.1"
                      :min="0"
                      :max="90"
                      @change="incidenceAngleChange"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CommonAccordion>
        <CommonAccordion
          :propTitle="$t('label_datasets')"
          :isChevronEnd="true"
          class="search-advanced--accordion satellites-criteia__accordion"
          v-if="propProvider && Object.keys(propProvider).length > 0"
        >
          <div slot="content">
            <div class="satellites-criteia__content">
              <Tree
                :value="propProvider.tree"
                selectionMode="checkbox"
                :selectionKeys.sync="propCriteria.selectedKey"
              />
            </div>
          </div>
        </CommonAccordion>
        <CommonAccordion
          v-if="showOpticalCriteria"
          :propTitle="$t('title_optical_criteria')"
          :isChevronEnd="true"
          class="search-advanced--accordion optical-criteia__accordion"
        >
          <div slot="content">
            <div class="optical-criteia__content">
              <div class="form-row">
                <div class="col-12 col-md-4">
                  <label
                    >{{ $t("label_cloud_cove") }}
                    <span
                      >[{{
                        propCriteria.cloud_cove[0] +
                        "-" +
                        propCriteria.cloud_cove[1]
                      }}]</span
                    >&nbsp;(%)</label
                  >
                </div>
                <div class="col-12 col-md-8">
                  <div class="slider-control">
                    <Slider
                      :modelValue="propCriteria.cloud_cove"
                      :range="true"
                      :step="0.1"
                      :min="0"
                      :max="100"
                      @change="cloudCoveChange"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CommonAccordion>
        <CommonAccordion
          v-if="showRadarCriteria"
          :propTitle="$t('title_radar_criteria')"
          :isChevronEnd="true"
          class="search-advanced--accordion radar-criteia__accordion"
        >
          <div slot="content">
            <div class="radar-criteia__content">
              <div class="form-row">
                <div class="col-12 col-md-4">
                  <label>{{ $t("label_image_mode") }}</label>
                </div>
                <div class="col-12 col-md-8">
                  <treeselect
                    v-model="propCriteria.imaging_mode"
                    :multiple="false"
                    :options="propImagingMode.items"
                    :normalizer="normalizer"
                    placeholder=""
                    :noChildrenText="$t('label_no_sub_options')"
                    :noOptionsText="$t('label_no_options_text')"
                    :noResultsText="$t('label_no_results_text')"
                  >
                    <label slot="option-label" slot-scope="{ node }">{{
                      node.raw.value
                    }}</label>
                    <div slot="value-label" slot-scope="{ node }">
                      {{ node.raw.value }}
                    </div>
                  </treeselect>
                </div>
              </div>
              <div class="form-row">
                <div class="col-12 col-md-4">
                  <label for="criteria__search-from">{{
                    $t("label_direction")
                  }}</label>
                </div>
                <div class="col-12 col-md-8">
                  <Dropdown
                    class="w-100"
                    :options="propDirection.items"
                    v-model="propCriteria.direction"
                    :showClear="true"
                    optionValue="id"
                    optionLabel="value"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="col-12 col-md-4">
                  <label>{{ $t("label_pol_layer") }}</label>
                </div>
                <div class="col-12 col-md-8">
                  <treeselect
                    v-model="propCriteria.pol_layer"
                    :multiple="false"
                    :options="propPolLayer.items"
                    :normalizer="normalizer"
                    placeholder=""
                    :noChildrenText="$t('label_no_sub_options')"
                    :noOptionsText="$t('label_no_options_text')"
                    :noResultsText="$t('label_no_results_text')"
                  >
                    <label slot="option-label" slot-scope="{ node }">{{
                      node.raw.value
                    }}</label>
                    <div slot="value-label" slot-scope="{ node }">
                      {{ node.raw.value }}
                    </div>
                  </treeselect>
                </div>
              </div>
            </div>
          </div>
        </CommonAccordion>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Treeselect from "@riophae/vue-treeselect";
import Slider from "@/components/commons/slider/Slider";
import CommonAccordion from "@/components/commons/accordion/Accordion";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import Tree from "@/components/commons/tree/Tree";
import Checkbox from "@/components/commons/checkbox/Checkbox";
export default {
  props: {
    propShow: {
      type: Boolean,
      default: false,
    },
    propCriteria: {
      type: Object,
      default: () => {},
    },
    propProvider: {
      type: Object,
      default: () => {},
    },
    propProcessingLevel: {
      type: Object,
      default: () => {},
    },
    propImageTypes: {
      type: Object,
      default: () => {},
    },
    propDirection: {
      type: Object,
      default: () => {},
    },
    propImagingMode: {
      type: Object,
      default: () => {},
    },
    propPolLayer: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DatePicker,
    Treeselect,
    Slider,
    CommonAccordion,
    Dropdown,
    Tree,
    Checkbox,
  },

  data() {
    return {
      showRadarCriteria: false,
      showOpticalCriteria: false,
      normalizer(node) {
        return {
          id: node.name,
        };
      },
      partners: [
        {
          id: "criteriaPLANET",
          code: "PLANET",
          label: "Planet",
        },
        {
          id: "criteriaONEATLAS",
          code: "ONEATLAS",
          label: "OneAtlas",
        },
        {
          id: "criteriaCAPELLA",
          code: "CAPELLA",
          label: "Capella",
        },
        {
          id: "criteriaGEOHUB",
          code: "GEOHUB",
          label: "GeoHub",
        },
      ],
    };
  },
  created() {
    if (this.propProvider && this.propProvider.items.length === 0) {
      this.$emit("getAllProvider");
    }
    if (
      this.propProcessingLevel &&
      this.propProcessingLevel.items.length === 0
    ) {
      this.$emit("getAllProcessingLevel");
    }
    if (this.propImageTypes && this.propImageTypes.items.length === 0) {
      this.$emit("getAllImageTypes");
    }
    if (this.propDirection && this.propDirection.items.length === 0) {
      this.$emit("getAllDirection");
    }
    if (this.propImagingMode && this.propImagingMode.items.length === 0) {
      this.$emit("getAllImagingMode");
    }
    if (this.propPolLayer && this.propPolLayer.items.length === 0) {
      this.$emit("getAllPolLayer");
    }
  },
  mounted() {},
  watch: {
    "propCriteria.selectedKey": {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          let ids = [],
            opticalIds = [];
          for (const [key, value] of Object.entries(val)) {
            if (value.checked) {
              if (this.radarSatellites.map((x) => x.id).includes(key))
                ids.push(key);
              if (this.opticalSatellites.map((x) => x.id).includes(key))
                opticalIds.push(key);
            }
          }
          this.showRadarCriteria = ids.length === 0 ? false : true;
          this.showOpticalCriteria = opticalIds.length === 0 ? false : true;
        } else {
          this.showRadarCriteria = false;
          this.showOpticalCriteria = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    onClose() {
      this.$emit("close");
    },
    resolutionChange(e) {
      this.propCriteria.resolution = e;
    },
    incidenceAngleChange(e) {
      this.propCriteria.incidence_angle = e;
    },
    cloudCoveChange(e) {
      this.propCriteria.cloud_cove = e;
    },
  },
  computed: {
    widgetSize() {
      if (this.documentWidth < 576) return this.documentWidth - 16 + "px";
      else return "30.5rem";
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    bodyHeight() {
      return (
        this.documentHeight -
        16 * 5.8125 -
        49 -
        11 -
        2 -
        2 +
        (!this.isHeader ? 3.5 * 16 : 0)
      );
    },
    isHeader() {
      return this.$store.getters.getIsHeader;
    },
    radarSatellites() {
      let results = [];
      if (this.propImageTypes && Object.keys(this.propImageTypes).length > 0) {
        let objRadar = this.propImageTypes.items.find(
          (x) => x.name.toUpperCase() === "RADAR".toUpperCase()
        );
        if (objRadar && Object.keys(objRadar).length > 0)
          results = objRadar.sub_meta_key ? objRadar.sub_meta_key : [];
      }
      return results;
    },
    opticalSatellites() {
      let results = [];
      if (this.propImageTypes && Object.keys(this.propImageTypes).length > 0) {
        let objOptical = this.propImageTypes.items.find(
          (x) => x.name.toUpperCase() === "OPTICAL".toUpperCase()
        );
        if (objOptical && Object.keys(objOptical).length > 0)
          results = objOptical.sub_meta_key ? objOptical.sub_meta_key : [];
      }
      return results;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.criteria {
  font-size: 14px;
  height: 100%;
  width: 30.5rem;
  background: white;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  .criteria__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    h6 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;

      font-size: 16px;
      font-weight: 500;
    }
    .criteria__header-title {
      width: 100%;
      margin-bottom: 0;
      font-size: 14px;
    }
  }
  .criteria__body {
    width: 100%;
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0.75rem 1rem;
    overflow-y: auto;
    .select-partner {
      align-items: center;
      justify-content: center;
      gap: 0 1.5rem;
      margin: 1rem auto;
      text-align: center;
      width: 100%;
      .field-checkbox {
        align-self: center;
        label {
          padding: 0;
          padding-left: 0.25rem;
          margin: 0;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    div {
      .form-row {
        margin-bottom: 0.5rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .range-time-label {
        line-height: 2rem;
        text-align: center;
        background-color: $background-color-2;
        border: 1px solid $background-color-2;
        border-right: 0;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        color: #f1f4fa;
        font-size: 12px;
      }
      .slider-control {
        line-height: 2.125rem;
        margin: 0.9rem 0;
      }
    }
  }
}
@media (max-width: 576px) {
  .criteria {
    width: calc(100% - 8px) !important;
  }
}
</style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
.search-advanced--accordion {
  margin-top: 0.25rem;
  .form-row {
    label {
      margin: 0;
      line-height: 2.125rem;
    }
    .form-group {
      margin-bottom: 0;
      .mx-input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        font-size: 12px;
      }
    }
  }
  &.search-criteia__accordion {
    .accordion-header a {
      color: white;
      background: $background-color-2;
      border: 1px solid $background-color-2;
      border-radius: 0.25rem;
      span {
        color: white !important;
      }
    }
    .accordion-content {
      &.in {
        border-top: none;
        border: none;
      }
      .search-criteia__content {
        padding: 0.75rem;
      }
    }
  }

  &.satellites-criteia__accordion {
    .accordion-header a {
      border-radius: 0.25rem;
      span {
        color: #333;
      }
      &.active {
        color: white;
        background: $background-color-2;
        border: 1px solid $background-color-2;
        span {
          color: white;
        }
      }
    }
    .accordion-content {
      .satellites-criteia__content {
        padding: 0.75rem;
        .p-tree {
          border-color: transparent;
          padding: 0;
        }
      }
      &.in {
        border-top: none;
        border: none;
      }
    }
  }
  &.optical-criteia__accordion {
    .accordion-header a {
      border-radius: 0.25rem;
      span {
        color: #333;
      }
      &.active {
        color: white;
        background: $background-color-2;
        border: 1px solid $background-color-2;
        span {
          color: white;
        }
      }
    }
    .accordion-content {
      .optical-criteia__content {
        padding: 0.75rem;
      }
      &.in {
        border-top: none;
        border: none;
      }
    }
  }
  &.radar-criteia__accordion {
    border-radius: 0.25rem;
    .accordion-header a {
      span {
        color: #333;
      }
      &.active {
        color: white;
        background: $background-color-2;
        border: 1px solid $background-color-2;
        span {
          color: white;
        }
      }
    }
    .accordion-content {
      .radar-criteia__content {
        padding: 0.75rem;
      }
      &.in {
        border-top: none;
        border: none;
      }
    }
  }

  &.free-criteia__accordion {
    border-radius: 0.25rem;
    .accordion-header a {
      span {
        color: #333;
      }
      &.active {
        color: white;
        background: $background-color-2;
        border: 1px solid $background-color-2;
        span {
          color: white;
        }
      }
    }
    .accordion-content {
      .radar-criteia__content {
        padding: 0.75rem;
      }
      &.in {
        border-top: none;
        border: none;
      }
    }
    fieldset {
      margin-top: 0.5rem;
      legend {
        font-size: 14px;
        font-weight: 550;
      }
    }
  }
  .vue-treeselect__control {
    border: 1px solid #a6a6a6;
    border-radius: 3px;
    &:hover {
      border-color: #212121 !important;
    }
  }
  .p-slider-range {
    background-color: $background-color-2 !important;
  }
  .p-slider-handle {
    border-color: $background-color-2 !important;
  }
  .p-tree-container {
    padding: 0;
  }
}
</style>
<style lang="scss">
.criteria {
  .p-tree {
    font-size: 12px;
  }
  .p-dropdown {
    height: 34px;
    .p-inputtext {
      font-size: 12px;
    }
    .pi {
      font-size: 0.65rem;
    }
    .p-dropdown-clear-icon {
      top: 1.15rem;
    }
    .p-dropdown-items {
      font-size: 12px;
    }
  }
  .vue-treeselect {
    font-size: 12px;
  }
}
</style>
