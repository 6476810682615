<template>
  <div
    class="tasking-partners"
    :style="{ minHeight: propContentHeight + 'px' }"
  >
    <div
      class="partner-item"
      :class="selected === item.id ? 'active' : ''"
      v-for="item in partners"
      :key="item.id"
      @click="selectePartner(item)"
    >
      <label>{{ item.label }}</label>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    propContentHeight: {
      type: Number,
    },
  },
  data() {
    return {
      selected: "ONEATLAS",
      partners: [
        {
          id: "PLANET",
          code: "PLANET",
          label: "Planet",
        },
        {
          id: "ONEATLAS",
          code: "ONEATLAS",
          label: "OneAtlas",
        },
        {
          id: "CAPELLA",
          code: "CAPELLA",
          label: "Capella",
        },
        {
          id: "GEOHUB",
          code: "GEOHUB",
          label: "GeoHub",
        },
      ],
    };
  },
  created() {
    this.$emit("updateEntity", {
      field: "partner",
      value: this.selected,
    });
  },
  methods: {
    selectePartner(data) {
      this.selected = data.id;
      this.$emit("updateEntity", {
        field: "partner",
        value: this.selected,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tasking-partners {
  padding: 1rem;
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  .partner-item {
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: auto 0;
    text-align: center;
    &.active {
      border-width: 1.5px;
      border-color: #fd7e14;
      label {
        color: #fd7e14;
      }
    }
    &:hover {
      cursor: pointer;
    }
    label {
      font-size: 32px;
      margin: auto;
      padding: 0;
    }
  }
}
</style>
