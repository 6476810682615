<template>
  <div class="my-order-content">
    <div class="my-order__dialog">
      <DialogOrderDetail
        v-if="dlgOrderDetail.show"
        :prop-style="{ width: dlgOrderDetail.width }"
        :prop-show="dlgOrderDetail.show"
        :prop-title="dlgOrderDetail.title"
        :prop-items="dlgOrderDetail.value.images"
        :prop-order="dlgOrderDetail.value"
        :prop-button-download="buttons.download"
        :prop-image-types="imageTypes.items"
        @close="
          dlgOrderDetail.show = false;
          dlgOrderDetail.value = null;
          dlgOrderDetail.selected = null;
        "
        @showPlanetMetadata="
          dlgPlanetMetadata.show = true;
          dlgPlanetMetadata.value = $event;
        "
        @showOneAtlasMetadata="
          dlgOneAtlasMetadata.show = true;
          dlgOneAtlasMetadata.value = $event;
        "
        @showCapellaMetadata="
          dlgCapellaMetadata.show = true;
          dlgCapellaMetadata.value = $event;
        "
        @showMetadata="
          dlgOpticalImgMetadata.show = true;
          dlgOpticalImgMetadata.value = $event;
        "
        @onDownloadImage="onDownloadImage"
      />
      <DialogPlanetMetadata
        v-if="dlgPlanetMetadata.show"
        :prop-style="{ width: dlgPlanetMetadata.width }"
        :prop-show="dlgPlanetMetadata.show"
        :prop-title="dlgPlanetMetadata.title"
        :prop-item="dlgPlanetMetadata.value"
        @close="
          dlgPlanetMetadata.show = false;
          dlgPlanetMetadata.value = null;
        "
        @initSuccessPreviewImange="initSuccessPreviewImange"
      />
      <DialogOneAtlasMetadata
        v-if="dlgOneAtlasMetadata.show"
        :prop-style="{ width: dlgOneAtlasMetadata.width }"
        :prop-show="dlgOneAtlasMetadata.show"
        :prop-title="dlgOneAtlasMetadata.title"
        :prop-item="dlgOneAtlasMetadata.value"
        @close="
          dlgOneAtlasMetadata.show = false;
          dlgOneAtlasMetadata.value = null;
        "
        @initSuccessPreviewImange="initSuccessPreviewImange"
      />
      <DialogCapellaMetadata
        v-if="dlgCapellaMetadata.show"
        :prop-style="{ width: dlgCapellaMetadata.width }"
        :prop-show="dlgCapellaMetadata.show"
        :prop-title="dlgCapellaMetadata.title"
        :prop-item="dlgCapellaMetadata.value"
        @close="
          dlgCapellaMetadata.show = false;
          dlgCapellaMetadata.value = null;
        "
        @initSuccessPreviewImange="initSuccessPreviewImange"
      />
      <DialogMetadata
        v-if="dlgOpticalImgMetadata.show"
        :prop-style="{ width: dlgOpticalImgMetadata.width }"
        :prop-show="dlgOpticalImgMetadata.show"
        :prop-title="dlgOpticalImgMetadata.title"
        :prop-item="dlgOpticalImgMetadata.value"
        @close="
          dlgOpticalImgMetadata.show = false;
          dlgOpticalImgMetadata.value = null;
        "
        @initSuccessPreviewImange="initSuccessPreviewImange"
      />
      <DialogRemark
        v-if="dlgDialogRemark.show"
        :prop-style="{
          width: dlgDialogRemark.width,
        }"
        :prop-show="dlgDialogRemark.show"
        :prop-title="dlgDialogRemark.title"
        :prop-value="dlgDialogRemark.value"
        @close="
          dlgDialogRemark.show = false;
          dlgDialogRemark.value = null;
          dlgDialogRemark.title = null;
        "
      />
      <DialogCancelOrder
        v-if="dlgCancelOrder.show"
        :prop-style="{ width: dlgCancelOrder.width }"
        :prop-show="dlgCancelOrder.show"
        :prop-title="dlgCancelOrder.title"
        :prop-items="dlgCancelOrder.value"
        :propButton="buttons.cancelOrder"
        @close="
          dlgCancelOrder.show = false;
          dlgCancelOrder.value = null;
        "
        @message="message = $event"
        @onCancelOrder="onCancelOrder"
      />
      <DialogPassword
        v-if="dialogPassword.show"
        :prop-style="{ width: dialogPassword.width }"
        :prop-show="dialogPassword.show"
        @close="dialogPassword.show = false"
        @onDownload="downloadWithPass"
        @message="message = $event"
      />
      <DialogMessage
        v-if="message.length > 0"
        :propTitle="$t('title_message_info')"
        :prop-show="message.length > 0"
        :prop-message="message"
        @close="message = []"
      />
    </div>
    <div class="my-order__search" id="elementSearch">
      <OrderSearch
        :propSearch="myOrder.search"
        :prop-buttons="buttons"
        :propOrderStatus="orderStatus.items"
        @onSearch="onSearch"
        @message="message = $event"
      />
    </div>
    <div class="my-order__datatable">
      <OrderDataTable
        :propItems="myOrder.items"
        :prop-table="myOrder.table"
        :prop-button-detail="buttons.detail"
        :prop-loading="myOrder.loading"
        :propTotal="myOrder.total_records"
        @nextPage="nextPage"
        @showOrderDetail="showOrderDetail"
        @showDialogCancel="showDialogCancel"
        @shoDialogRemark="shoDialogRemark"
      />
    </div>
  </div>
</template>
<script>
import OrderSearch from "./myordered/Search.vue";
import OrderDataTable from "./myordered/DataTable";
import DialogConfirmation from "@/components/commons/dialog/Confirmation";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import DialogOrderDetail from "./myordered/detail/DialogOrderDetail.vue";
import DialogCancelOrder from "./myordered/detail/DialogCancelOrder.vue";
import DialogRemark from "./myordered/detail/DialogRemark.vue";
import DialogMetadata from "@/components/commons/metadata/Metadata";
import DialogPlanetMetadata from "@/components/commons/metadata/MetadataPlanet";
import DialogOneAtlasMetadata from "@/components/commons/metadata/MetadataOneAtlas";
import DialogCapellaMetadata from "@/components/commons/metadata/MetadataCapella";
import DialogPassword from "./myordered/detail/DialogPassword.vue";
import meControllerFunc from "@/utils/functions/mecontroller";
import metaKeyFunc from "@/utils/functions/metakey";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import imageSharingFunc from "@/utils/functions/imagesharing";
export default {
  components: {
    OrderDataTable,
    OrderSearch,
    DialogConfirmation,
    DialogCancelOrder,
    DialogMessage,
    DialogMetadata,
    DialogOrderDetail,
    DialogRemark,
    DialogPassword,
    DialogPlanetMetadata,
    DialogOneAtlasMetadata,
    DialogCapellaMetadata,
  },
  data() {
    return {
      message: [],
      orderStatus: {
        items: [],
        data: [],
      },
      priorityLevel: {
        items: [],
        data: [],
      },
      imageTypes: { items: [] },
      myOrder: {
        loading: false,
        page_number: 0,
        page_size: 10,
        total_page: 0,
        total_records: 0,
        items: [],
        search: {
          create_from: null,
          create_to: null,
          status_id: null,
          page: 0,
          size: 10,
        },
        table: {
          selected: null,
          height: 400,
        },
        is_bottom: false,
      },
      confirm: {
        message: null,
        show: false,
        title: this.$t("title_message_confirm"),
        icon: "fas fa-question",
        callback: {
          no: {
            method: null,
            params: null,
          },
          yes: {
            method: null,
            params: null,
          },
        },
        initValue: [],
        value: false,
      },
      dlgOrderDetail: {
        show: false,
        title: this.$t("header_detail"),
        value: null,
        selected: null,
        width: "480px",
      },
      dlgPlanetMetadata: {
        show: false,
        title: "Metadata",
        value: null,
        width: "960px",
      },
      dlgOneAtlasMetadata: {
        show: false,
        title: "Metadata",
        value: null,
        width: "960px",
      },
      dlgCapellaMetadata: {
        show: false,
        title: "Metadata",
        value: null,
        width: "960px",
      },
      dlgOpticalImgMetadata: {
        show: false,
        title: "Metadata",
        value: null,
        width: "960px",
      },
      dlgCancelOrder: {
        show: false,
        title: this.$t("title_cancel_order"),
        value: null,
        width: "480px",
      },
      dlgDialogRemark: {
        show: false,
        title: null,
        value: null,
        width: "540px",
      },
      dialogPassword: {
        show: false,
        width: "480px",
      },
      buttons: {
        filter: {
          disabled: false,
          processing: false,
          visible: true,
        },
        detail: {
          disabled: false,
          processing: false,
        },
        cancelOrder: {
          disabled: false,
          processing: false,
        },
        download: {
          disabled: false,
          processing: false,
          ids: [],
        },
      },
    };
  },
  async created() {
    this.myOrder.search.size = Math.ceil(this.tableHeight / 50) + 5;
    this.$emit("initBreadcrumb", ["menu_sidebar_my_request"]);
    var vm = this;
    if (vm.documentWidth < 576) {
      this.dlgOrderDetail.width = this.documentWidth + "px";
      this.dlgOpticalImgMetadata.width = this.documentWidth + "px";
      this.dlgDialogRemark.width = this.documentWidth + "px";
    } else if (vm.documentWidth >= 576 && vm.documentWidth < 768) {
      this.dlgOrderDetail.width = "540px";
      this.dlgOpticalImgMetadata.width = "540px";
      this.dlgDialogRemark.width = "540px";
    } else if (vm.documentWidth >= 768 && vm.documentWidth < 992) {
      this.dlgOrderDetail.width = "720px";
      this.dlgOpticalImgMetadata.width = "720px";
      this.dlgDialogRemark.width = "540px";
    } else {
      this.dlgOrderDetail.width = "960px";
      this.dlgOpticalImgMetadata.width = "960px";
      this.dlgDialogRemark.width = "540px";
    }
    this.getAllImageTypes();
    this.getAllBasemaps();
    await this.getAllOrderStatus();
    this.getMyOrder();
  },

  mounted() {
    setTimeout(() => {
      this.myOrder.table.height = this.calcTableHeight();
    }, 100);
  },
  watch: {
    lang() {
      this.getOrderStatusByLang();
    },
  },
  methods: {
    accordionHeaderClick() {
      setTimeout(() => {
        this.myOrder.table.height = this.calcTableHeight();
      }, 100);
    },
    calcTableHeight() {
      let element = document.getElementById("elementSearch");
      return (
        this.documentHeight -
        3 * 0.5 * 16 -
        56 -
        (element ? element.offsetHeight : 0) -
        24 -
        2 * 0.5 * 16
      );
    },
    /*------------- Emit ------------------*/
    downloadWithPass(password) {
      this.onDownloadImage({
        image_id: this.dlgOrderDetail.selected,
        password: password,
      });
      this.dialogPassword.show = false;
    },
    async onDownloadImage(val) {
      if (this.dlgOrderDetail.value && this.dlgOrderDetail.value.images) {
        let index = this.dlgOrderDetail.value.images
          ? this.dlgOrderDetail.value.images.findIndex(
              (x) => x.image.id === val.image_id
            )
          : -1;
        if (index >= 0) {
          let token = null;
          let image = this.dlgOrderDetail.value.images[index].image;
          if (image) {
            this.dlgOrderDetail.selected = image.id;
            let indexShared = -1;
            if (this.dlgOrderDetail.value.shared) {
              indexShared = this.dlgOrderDetail.value.shared.findIndex(
                (x) =>
                  x.image_id === image.id &&
                  x.order_id === this.dlgOrderDetail.value.id
              );
              if (indexShared >= 0) {
                token = this.dlgOrderDetail.value.shared[indexShared].token
                  ? this.dlgOrderDetail.value.shared[indexShared].token
                  : null;
                if (
                  this.dlgOrderDetail.value.shared[indexShared].is_pass &&
                  !val.password
                ) {
                  this.dialogPassword.show = true;
                  return;
                }
              }
            }

            this.buttons.download.ids.push(val.image_id);
            try {
              this.buttons.download.disabled = true;
              this.buttons.download.processing = true;
              this.$store.dispatch("setImagesDownload", [
                {
                  is_add: true,
                  image: image,
                },
              ]);
              let response = await imageSharingFunc.download2(
                val.password ? val.password : null,
                token,
                val.image_id,
                this.$store.getters.getAccessToken
              );
              if (response && response.status === 200) {
                window.open(decodeURI(response.data.url), "_blank");
              } else {
                this.message.push(
                  this.$t("message_download_file_error", null, {
                    name: image.name,
                  })
                );
              }
              this.$store.dispatch("setImagesDownload", [
                {
                  is_add: false,
                  image: image,
                },
              ]);
              let indexId = this.buttons.download.ids.findIndex(
                (x) => x === val.image_id
              );
              if (indexId >= 0) this.buttons.download.ids.splice(indexId, 1);
            } catch (error) {
              let indexId = this.buttons.download.ids.findIndex(
                (x) => x === val.image_id
              );
              if (indexId >= 0) this.buttons.download.ids.splice(indexId, 1);
              this.$store.dispatch("setImagesDownload", [
                {
                  is_add: false,
                  image: image,
                },
              ]);
              if (error.response && error.response.status === 401) {
                await this.refreshToken(this.downloadImage, data);
              } else {
                let msg =
                  error.response &&
                  error.response.data &&
                  error.response.data.message
                    ? error.response.data.message
                    : null;
                this.message.push(
                  msg
                    ? msg
                    : this.$t("message_download_file_error", null, {
                        name: image.name,
                      })
                );
              }
            }
          }
        } else {
          this.buttons.download.disabled = false;
          this.buttons.download.processing = false;
        }
      }
    },
    shoDialogRemark(data) {
      this.dlgDialogRemark.value = data.remark;
      this.dlgDialogRemark.title = data.title;
      this.dlgDialogRemark.show = true;
    },
    showDialogCancel(item) {
      this.dlgCancelOrder.value = item;
      this.dlgCancelOrder.show = true;
    },
    showOrderDetail(item) {
      this.dlgOrderDetail.value = item;
      this.dlgOrderDetail.show = true;
      this.dlgOrderDetail.selected = null;
    },
    initSuccessPreviewImange(data) {
      let index = this.dlgOrderDetail.value.images.findIndex(
        (x) => x.id === data.id
      );
      if (index >= 0) {
        this.dlgOrderDetail.value.images[index].preview_src = data.val;
      }
    },
    nextPage() {
      if (!this.myOrder.is_bottom) {
        this.myOrder.search.page = this.myOrder.search.page + 1;
        this.getMyOrder();
      }
    },
    onSearch(data) {
      this.myOrder.search.create_from = data.create_from
        ? data.create_from
        : null;
      this.myOrder.search.create_to = data.create_to ? data.create_to : null;
      this.myOrder.search.status_id = data.status_id ? data.status_id : null;
      this.myOrder.items = [];
      this.myOrder.page = 0;
      this.myOrder.is_bottom = false;
      this.getMyOrder();
    },
    async onCancelOrder(content) {
      try {
        this.buttons.cancelOrder.disabled = true;
        this.buttons.cancelOrder.processing = true;
        let response = await meControllerFunc.cancelOrder(
          {
            content: content,
            orderId: this.dlgCancelOrder.value.id,
            order_id: this.dlgCancelOrder.value.id,
          },
          this.$store.getters.getAccessToken
        );
        let msg = null;
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            this.$toast.success({
              title: this.$t("title_message_info"),
              message: this.$t("message_cancel_order_success"),
              position: "top right",
            });

            this.onSearch({
              create_from: this.myOrder.search.create_from,
              create_to: this.myOrder.search.create_to,
              status_id: this.myOrder.search.status_id,
            });
            this.dlgCancelOrder.value = null;
            this.dlgCancelOrder.show = false;
          } else {
            msg = this.$t("message_cancel_order_error");
          }
        } else msg = this.$t("message_cancel_order_error");
        if (msg) this.message.push(msg);

        this.buttons.cancelOrder.disabled = false;
        this.buttons.cancelOrder.processing = false;
      } catch (error) {
        this.buttons.cancelOrder.disabled = false;
        this.buttons.cancelOrder.processing = false;
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.onCancelOrder);
        } else {
          let msge =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msge ? msge : this.$t("message_cancel_order_error")
          );
        }
      }
    },
    /*--------------- Meta key ------------------*/
    getOrderStatusByLang() {
      this.orderStatus.items = [];
      for (let i = 0; i < this.orderStatus.data.length; i++) {
        if (
          this.orderStatus.data[i].translation &&
          this.orderStatus.data[i].translation[this.lang] &&
          Object.keys(this.orderStatus.data[i].translation[this.lang]).length >
            0
        ) {
          this.orderStatus.items.push({
            id: this.orderStatus.data[i].id,
            key: this.orderStatus.data[i].key,
            name: this.orderStatus.data[i].name,
            value: this.orderStatus.data[i].translation[this.lang].value
              ? this.orderStatus.data[i].translation[this.lang].value
              : this.orderStatus.data[i].value,
            description: this.orderStatus.data[i].translation[this.lang]
              .description
              ? this.orderStatus.data[i].translation[this.lang].description
              : this.orderStatus.data[i].description,
          });
        } else {
          this.orderStatus.items.push({
            id: this.orderStatus.data[i].id,
            key: this.orderStatus.data[i].key,
            name: this.orderStatus.data[i].name,
            value: this.orderStatus.data[i].value,
            description: this.orderStatus.data[i].description,
          });
        }
      }
    },
    /*---------------End meta key ----------------*/
    /*--------------- Get data ------------------*/

    async getMyOrder() {
      let params = {
        create_from: this.myOrder.search.create_from
          ? new Date(this.myOrder.search.create_from)
          : null,
        create_to: this.myOrder.search.create_to
          ? new Date(this.myOrder.search.create_to)
          : null,
        size: this.myOrder.search.size,
        page: this.myOrder.search.page,
        status_id: this.myOrder.search.status_id
          ? this.myOrder.search.status_id
          : null,
        is_request_order: false,
      };
      this.myOrder.loading = true;
      this.buttons.filter.disabled = true;
      this.buttons.filter.processing = true;
      try {
        let response = await meControllerFunc.getMyOrder(
          params,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.myOrder.items = [
            ...this.myOrder.items,
            ...response.data.content_page,
          ];
          this.myOrder.page_number = response.data.page_number;
          this.myOrder.page_size = response.data.page_size;
          this.myOrder.total_page = response.data.total_page;
          this.myOrder.total_records = response.data.total_records;
          if (
            (this.myOrder.items.length === this.myOrder.total_records &&
              this.myOrder.total_page > 1) ||
            (response.data.total_page === 1 &&
              this.myOrder.items.length <= response.data.page_size)
          )
            this.myOrder.is_bottom = true;
        }

        this.myOrder.loading = false;
        this.buttons.filter.disabled = false;
        this.buttons.filter.processing = false;
      } catch (error) {
        this.myOrder.loading = false;
        this.buttons.filter.disabled = false;
        this.buttons.filter.processing = false;

        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getMyOrder);
        }
      }
    },
    async getAllOrderStatus() {
      try {
        let response = await metaKeyFunc.getByKey(
          "ORDER_STATUS",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.orderStatus.data =
            response.data && response.data.data ? response.data.data : [];
          this.getOrderStatusByLang();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllOrderStatus);
        }
      }
    },

    async getAllImageTypes() {
      try {
        let response = await metaKeyFunc.getByKey(
          "IMAGE_TYPES",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.imageTypes.items =
            response && response.data && response.data.data
              ? response.data.data
              : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllImageTypes);
        }
      }
    },
    async getAllBasemaps() {
      try {
        let response = await metaKeyFunc.getByKey(
          "BASEMAPS",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            let items = [];
            let data = response.data.data ? response.data.data : [];
            for (let i = 0; i < data.length; i++) {
              try {
                let obj = JSON.parse(data[i].value);
                if (obj && Object.keys(obj).length > 0) {
                  items.push({
                    id: data[i].id,
                    type:
                      obj.type && obj.type === "shapefile"
                        ? "JSON_FILE"
                        : "TILE_LAYER",
                    name: data[i].name,
                    title: obj.name ? obj.name : obj.NAME,
                    value: data[i].value,
                    description: data[i].description,
                    id:
                      new Date().getTime() -
                      Math.floor(Math.random() * 99999) +
                      Math.floor(Math.random() * 10000),
                    show:
                      (obj.is_show && obj.is_show + "" === "1") ||
                      (obj.IS_SHOW && obj.IS_SHOW + "" === "1")
                        ? true
                        : false,
                    opacity: 100,
                    basemap: true,
                    source: {
                      type: "TILE_IMAGE",
                      url: obj.path ? obj.path : obj.PATH,
                    },
                  });
                }
              } catch (error) {}
            }
            if (items && items.length > 0) {
              if (!this.basemap || Object.keys(this.basemap).length === 0) {
                let arrayBasemaps = items.filter(
                  (x) => x.show === true && (!x.type || x.type === "TILE_LAYER")
                );
                if (arrayBasemaps && arrayBasemaps.length > 0)
                  this.$store.dispatch(
                    "setBasemapDefault",
                    arrayBasemaps[arrayBasemaps.length - 1]
                  );
              }
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllBasemaps);
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    tableHeight() {
      return this.documentHeight - 56 - 96 - 2 * 42 - 38;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.my-order-content {
  margin: 0;
  padding: 0.5rem;
  .my-order__search {
    padding: 0;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .my-order__datatable {
    margin-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
}
</style>
