<template>
  <div>
    <div v-for="(item, index) in items" :key="item.path + index">
      <p class="mb-2 mt-2">{{ item.path }}</p>
      <div class="table-responsive">
        <table class="table-metadata w-100 p-0" cellspacing="0" cellpadding="0">
          <tbody>
            <tr
              class="w-100"
              v-for="child in item.children"
              :key="item.path + index + child.key"
            >
              <td style="width: 160px !important">
                {{
                  child.key ? jsUcfirst(child.key.replaceAll("_", " ")) : null
                }}
              </td>
              <td style="width: calc(100% - 160px)">{{ child.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    propField: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  created() {
    let array = [];
    this.recursiveMethod(
      this.propField.value,
      this.propField.key,
      this.propField.key,
      array
    );
    if (array && array.length > 0) {
      let ids = [...new Set(array.map((x) => x.parentId))];
      if (ids && ids.length > 0) {
        for (let i = 0; i < ids.length; i++) {
          let a = array.filter((x) => x.parentId === ids[i]);
          if (a && a.length > 0) {
            this.items.push({
              path: a[0].path ? a[0].path.replaceAll("_", " ") : null,
              children: a,
            });
          }
        }
      }
    }
  },
  methods: {
    jsUcfirst(string) {
      if (string && string.trim())
        return string.charAt(0).toUpperCase() + string.slice(1);
      else return "";
    },
    recursiveMethod(field, parentId, path, items) {
      if (Array.isArray(field)) {
        for (let i = 0; i < field.length; i++) {
          this.recursiveMethod(field[i], parentId + i, path, items);
        }
      } else {
        let array = [];
        for (const [key, value] of Object.entries(field)) {
          if (typeof value != "object") {
            items.push({
              parentId: parentId,
              path: path,
              key: key,
              value: value,
            });
          } else {
            this.recursiveMethod(
              value,
              parentId + "/" + key,
              path + " / " + key,
              items
            );
          }
        }
        if (array.length === 0) return items;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
table.table-metadata {
  border-collapse: separate;
  border-spacing: 0 0.125rem;
  padding: 0;
  tr {
    margin-bottom: 0.25rem;
    td {
      padding: 0.5rem 0.75rem;
      border: 1px solid white;
      &:first-child {
        background: $background-color-2;
        color: white;
        border-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        &.no-background {
          background-color: transparent;
          color: #212529;
        }
      }
    }
  }
}
</style>
