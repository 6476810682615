<template>
  <canvas :id="'myChart' + id" width="100%" height="100%"></canvas>
</template>
<script>
import * as Chart from "chart.js";
import "@/utils/commons/chartjs-plugin-labels";
export default {
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: "bar",
    },
  },
  data() {
    return {
      id: null,
      myLineChart: undefined,
    };
  },
  created() {
    this.id =
      new Date().getTime() -
      Math.floor(Math.random() * 1000) +
      Math.floor(Math.random() * 9999);
  },
  mounted() {
    var canvas = document.getElementById("myChart" + this.id);

    let i = 0;
    var options = {
      showLines: true,
      onClick: function (evt) {
        var element = myLineChart.getElementAtEvent(evt);
        if (element) {
          this.$emit("clickElement", element);
        }
      },
      onHover(evt) {
        var element = myLineChart.getElementAtEvent(evt);
        if (element) {
        }
      },
    };
    switch (this.type) {
      case "line":
        this.myLineChart = Chart.Line(canvas, {
          data: this.chartdata,
          options: this.options,
        });
        break;
      case "bar":
        this.myLineChart = Chart.Bar(canvas, {
          data: this.chartdata,
          options: this.options,
        });
        break;

      case "pie":
        this.myLineChart = new Chart(canvas, {
          type: "pie",
          data: this.chartdata,
          options: this.options,
        });
        break;
    }
  },
  methods: {
    updateChart() {
      if (this.myLineChart) this.myLineChart.update();
    },
  },
};
</script>