const geo_image = {
  download_preview: {
    url: "/geo-image/preview/d/{id}",
    method: "get",
    headers: {},
  },
  download_icon: {
    url: "/geo-image/icon/d/{id}",
    method: "get",
    headers: {},
  },
  download_earth: {
    url: "/geo-image/earth/d/{id}",
    method: "get",
    headers: {},
  },
  search_metadata: {
    url: "/geo-image/search-metadata",
    method: "post",
    headers: {},
  },
  download_my_zip_file: {
    url: "/geo-image/download/my-zip-file/{id}",
    method: "get",
    headers: {},
  },
};
const geo_image_radar = {
  download_preview: {
    url: "/geo-image-radar/preview/d/{id}",
    method: "get",
    headers: {},
  },
  download_icon: {
    url: "/geo-image-radar/icon/d/{id}",
    method: "get",
    headers: {},
  },
  download_earth: {
    url: "/geo-image-radar/earth/d/{id}",
    method: "get",
    headers: {},
  },
  search_metadata: {
    url: "/geo-image-radar/search-metadata",
    method: "post",
    headers: {},
  },
  download_my_zip_file: {
    url: "/geo-image-radar/download/my-zip-file/{id}",
    method: "get",
    headers: {},
  },
};

const exportfile = {
  kmz: {
    url: "/export/kmz",
    method: "GET",
    headers: {},
  },
  kml: {
    url: "/export/kml",
    method: "GET",
    headers: {},
  },
};

const ortho_image = {
  download_preview: {
    url: "/ortho-image/preview/d/{id}",
    method: "get",
    headers: {},
  },
  search_metadata: {
    url: "/ortho-image/search-metadata",
    method: "post",
    headers: {},
  },
};

const geo_image_vector = {
  search_metadata: {
    url: "/geo-image-vector/search-metadata",
    method: "post",
    headers: {},
  },
};
const model = {
  get_all: {
    url: "/model/all",
    method: "get",
    headers: {},
  },
};

const cart = {
  create: {
    url: "/cart/",
    method: "post",
    headers: {},
  },
  my_orderd: {
    url: "/cart/my-ordered",
    method: "get",
    headers: {},
  },
  add_item: {
    url: "/cart/add",
    method: "post",
    headers: {},
  },
  remove_item: {
    url: "/cart/remove",
    method: "delete",
    headers: {},
  },
};
const order = {
  create: {
    url: "/order/",
    method: "post",
    headers: {},
  },
  update: {
    url: "/order/{id}",
    method: "put",
    headers: {},
  },
  get_by_id: {
    url: "/order/{id}",
    method: "get",
    headers: {},
  },
  my_ordered: {
    //url: "/order/my-ordered",
    url: "/me/order/",
    method: "get",
    headers: {},
  },
};

const user = {
  get_user_by_user_key: {
    url: "/api/v1/user/key",
    method: "get",
    headers: {},
  },
  get_user_by_user_name: {
    url: "/user/findByUsername",
    method: "get",
    headers: {},
  },
  create: {
    url: "/user",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
  },
  update: {
    url: "/user",
    method: "put",
    headers: {
      "Content-Type": "application/json",
    },
  },
  delete: {
    url: "/api/v1/user/{id}",
    method: "delete",
    headers: {},
  },
  get_by_id: {
    url: "/user/{id}",
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  },
  get_all: {
    url: "/user",
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  },
};
const project = {
  get_all: {
    url: "/project/list",
    method: "get",
    headers: {},
  },
  add_image: {
    url: "/project/image/{project_id}",
    method: "post",
    headers: {},
  },
  download_attachment: {
    url: "/project/file/{project_id}",
    method: "get",
    headers: {},
  },
};

const sharing = {
  shared_satellite: {
    url: "/sharing1/shared-satellite",
    method: "get",
    headers: {},
  },
  shared_orthoimage: {
    url: "/sharing1/shared-orthoimage",
    method: "get",
    headers: {},
  },
  download: {
    url: "/sharing1/download/{id}",
    method: "get",
    headers: {},
  },
};

const administrative = {
  find_by_name: {
    url: "/administrative/search",
    method: "get",
    headers: {},
  },
};

const image = {
  public_search: {
    url: "/image/public/search",
    method: "post",
    headers: {},
  },
  search: {
    url: "/image/search",
    method: "post",
    headers: {},
  },
  create: {
    url: "/image",
    method: "post",
    headers: {},
  },
  update: {
    url: "/image/{id}",
    method: "put",
    headers: {},
  },
  delete: {
    url: "/image/{id}",
    method: "post",
    headers: {},
  },
  downoad: {
    url: "/image/downoad/{id}",
    method: "get",
    headers: {},
  },
  get_by_id: {
    url: "/image/{id}",
    method: "get",
    headers: {},
  },
  upload_image: {
    url: "/image/upload",
    method: "put",
    headers: {},
  },
};

const file = {
  download_preview: {
    url: "/files/preview/{key}",
    method: "get",
    headers: {},
  },
  get_metadata: {
    url: "/files/get_metadata",
    method: "get",
    headers: {},
  },
};

const me_controller = {
  share_for_me: {
    url: "/me/sharing/forme",
    method: "post",
    headers: {},
  },
  my_order: {
    url: "/me/order",
    method: "get",
    headers: {},
  },
  cancel_order: {
    url: "/me/order/cancel",
    method: "put",
    headers: {},
  },
  my_cart: {
    url: "/me/cart",
    method: "get",
    headers: {},
  },
  my_project: {
    url: "/me/project",
    method: "get",
    headers: {},
  },
  my_images: {
    url: "/me/image",
    method: "get",
    headers: {},
  },
  create_share: {
    url: "/me/sharing",
    method: "post",
    headers: {},
  },
  my_logs: {
    url: "/me/logs",
    method: "get",
    headers: {},
  },
  share_by_me: {
    url: "/me/sharing/me",
    method: "get",
    headers: {},
  },
  statistic: {
    url: "/me/statistic",
    method: "get",
    headers: {},
  },
  cancel_order: {
    url: "/me/order/cancel",
    method: "put",
    headers: {},
  },
  remove_user_from_share: {
    url: "/me/sharing/remove/user",
    method: "delete",
    headers: {},
  },
  get_request_free_image: {
    url: "/me/rfi/search",
    method: "post",
    headers: {},
  },
  cancel_request_free_image: {
    url: "/me/rfi/{id}",
    method: "delete",
    headers: {},
  },
  reset_password: {
    url: "/me/reset-password",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
  },
};

const meta_key = {
  get_list: {
    url: "/meta-key",
    method: "get",
    headers: {},
  },
  get_by_key: {
    url: "/meta-key/getByKey",
    method: "get",
    headers: {},
  },
  create: {
    url: "/meta-key",
    method: "post",
    headers: {},
  },
  delete: {
    url: "/meta-key/{id}",
    method: "delete",
    headers: {},
  },
};

const user_me = {
  info: {
    url: "/me",
    method: "get",
    headers: {},
  },
  create: {
    url: "/me",
    method: "post",
    headers: {},
  },
  update: {
    url: "/me",
    method: "put",
    headers: {},
  },
  change_pass: {
    url: "/me/password",
    method: "put",
    headers: {},
  },
};

const image_sharing = {
  create: {
    url: "/share/",
    method: "post",
    headers: {},
  },
  download: {
    url: "/share/d",
    method: "get",
    headers: {},
  },
  get_all: {
    url: "/share",
    method: "get",
    headers: {},
  },
  get_by_page: {
    url: "/share",
    method: "get",
    headers: {},
  },
  get_by_id: {
    url: "/share/{id}",
    method: "get",
    headers: {},
  },
  delete: {
    url: "/share/{id}",
    method: "delete",
    headers: {},
  },
  remove_user: {
    url: "/share/remove/user",
    method: "delete",
    headers: {},
  },
};

const statistic = {
  get_statistic: {
    url: "/statistic",
    method: "get",
    headers: {},
  },
  get_summary: {
    url: "/statistic/satellite",
    method: "get",
    headers: {},
  },
};

const log = {
  get_log: {
    url: "/log/",
    method: "get",
    headers: {},
  },
};

const misc = {
  get_country: {
    url: "/misc/country",
    method: "get",
    headers: {},
  },
  get_state_by_country: {
    url: "/misc/state",
    method: "get",
    headers: {},
  },
};

const contact = {
  create: {
    url: "/contact",
    method: "post",
    headers: {},
  },
};

const request_free_image = {
  create: {
    url: "/rfi",
    method: "post",
    headers: {},
  },
  get_all: {
    url: "/rfi",
    method: "post",
    headers: {},
  },
  update_file: {
    url: "/rfi",
    method: "put",
    headers: {},
  },
  delete: {
    url: "/rfi/{id}",
    method: "delete",
    headers: {},
  },
  download: {
    url: "/rfi/download/{id}",
    method: "get",
    headers: {},
  },
};

const cloud = {
  checkOrderPlanet: {
    url: "/cloud/planet/{id}",
    method: "get",
    headers: {},
  },
  searchPlanet: {
    url: "/cloud/planet/search",
    method: "post",
    headers: {},
  },
};
export default {
  geo_image,
  geo_image_radar,
  ortho_image,
  geo_image_vector,
  model,
  user,
  statistic,
  log,
  user_me,
  misc,
  exportfile,
  cart,
  order,
  project,
  sharing,
  administrative,
  image,
  file,
  me_controller,
  meta_key,
  image_sharing,
  contact,
  request_free_image,
  cloud,
};
