<template>
  <Dialog
    :header="$t('title_aoi_confirm')"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :maximizable="false"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <div class="aoi-confirm">
      <p
        v-if="
          this.propAoiFeature && Object.keys(this.propAoiFeature).length > 0
        "
      >
        {{ $t("label_area_larger_than_selected_images") }}
      </p>
      <p v-else>{{ $t("message_please_select_aoi_befor_confirm_request") }}</p>
      <div>
        <ul class="list-unstyled">
          <li class="Hywh39BJ2" v-for="item in items" :key="item.id">
            <RadioButton
              :id="item.id"
              :name="item.id"
              :value="item.id"
              v-model="selected"
            />
            <label :for="item.id"
              >&nbsp;{{ item.label }} &nbsp;
              <span v-html="item.area ? '( ' + item.area + ' )' : ''"></span
            ></label>
          </li>
        </ul>
      </div>
    </div>
    <template #footer>
      <Button
        :disabled="!selected"
        :label="$t('button_text_continue')"
        :class="'p-button-success p-button-sm mr-0'"
        @click="onConfirmedAoi"
      />
    </template>
  </Dialog>
</template>
<script>
var formatArea = function (polygon) {
  var area = getArea(polygon);
  var output;
  if (area > 10000) {
    output = ((area / 1000000) * 100) / 100 + " " + "km<sup>2</sup>";
  } else {
    output = (area * 100) / 100 + " " + "m<sup>2</sup>";
  }
  return output;
};
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import Checkbox from "@/components/commons/checkbox/Checkbox";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import RadioButton from "@/components/commons/radiobutton/RadioButton";
import Textarea from "@/components/commons/textarea/Textarea";
import { getArea } from "ol/sphere.js";
import { transform } from "ol/proj";
import Polygon from "ol/geom/Polygon";
export default {
  components: { Dialog, Button, Checkbox, Dropdown, RadioButton, Textarea },
  props: {
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "480px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propImages: {
      type: Array,
      default: () => [],
    },
    propAoiFeature: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      items: [],
      selected: null,
    };
  },
  async created() {
    this.show = this.propShow;

    if (this.propAoiFeature && Object.keys(this.propAoiFeature).length > 0) {
      this.items.push({
        id: "current_aoi",
        label: "Current AOI",
        area: null,
        geometry: null,
      });
      this.items[0].label = this.propAoiFeature.get("label");
      this.items[0].area = formatArea(this.propAoiFeature.getGeometry());
      this.items[0].geometry = this.propAoiFeature.getGeometry();

      this.selected = "current_aoi";
    }
    if (this.propImages) {
      for (let i = 0; i < this.propImages.length; i++) {
        let area = null,
          geometry = null;
        if (
          this.propImages[i].geom &&
          this.propImages[i].geom.coordinates &&
          this.propImages[i].geom.coordinates.length > 0
        ) {
          let points = [];
          for (
            let j = 0;
            j < this.propImages[i].geom.coordinates[0].length;
            j++
          ) {
            try {
              points.push(
                transform(
                  this.propImages[i].geom.coordinates[0][j],
                  "EPSG:4326",
                  "EPSG:3857"
                )
              );
            } catch (error) {}
          }
          if (points.length > 0) {
            geometry = new Polygon([points]);
            area = formatArea(geometry);
          }
        }

        this.items.push({
          id: this.propImages[i].id,
          label: this.$t("label_boundary_of_image", null, {
            imagename: this.propImages[i].name,
          }),
          area: area,
          geometry: geometry,
        });
      }
    }
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
  },
  methods: {
    onConfirmedAoi() {
      if (this.selected) {
        if (this.selected === "current_aoi") {
          this.$emit("onConfirmedAoi", this.items[0]);
        } else {
          this.$emit(
            "onConfirmedAoi",
            this.items.find((x) => x.id === this.selected)
          );
        }
        this.onClose();
      }
    },
    onClose() {
      this.show = false;
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.Hywh39BJ2 {
  margin-bottom: 0.5rem;
  label {
    margin: 0;
    padding-left: 0.5rem;
  }
  &:last-child {
    margin: 0;
  }
}
</style>
