<template>
  <CommonAccordion :propTitle="$t('title_request_list')">
    <div slot="content">
      <div class="datatable">
        <ContextMenu :model="menuModel" ref="cmOrderDatatable" />
        <DataTable
          :scrollable="true"
          :value="propItems"
          ref="dt"
          :loading="propLoading"
          :resizableColumns="false"
          columnResizeMode="expand"
          selectionMode="single"
          dataKey="id"
          contextMenu
          :selection.sync="propTable.selected"
          @row-contextmenu="onRowContextMenu"
          :scrollHeight="
            propTable.height - (propItems.length ? 40 : 0) - 38 + 'px'
          "
          :rowClass="rowClass"
          :virtualScroll="true"
          @virtual-scroll="onVirtualScroll"
          ><template #header v-if="propItems.length">
            <div class="text-left">
              <div>
                <div v-if="propItems.length" class="font-weight-normal">
                  {{
                    $t("label_show_from_to", null, {
                      from: 1,
                      to: propItems.length,
                      total: propTotal,
                    })
                  }}
                </div>
              </div>
            </div>
          </template>
          <template #empty v-if="propItems && propItems.length === 0">
            <span class="text-center">{{ $t("label_no_data") }}</span>
          </template>
          <Column
            header="No"
            headerStyle="width: 3rem; text-align: center!important"
            bodyStyle="width: 3rem;text-align: center;padding:0"
          >
            <template #body="slotProps">
              <div>
                {{ slotProps.index + 1 }}
              </div>
            </template></Column
          >
          <Column
            :header="$t('label_status')"
            headerStyle="width: 10rem;"
            bodyStyle="width:  10rem;text-align: center;"
          >
            <template #body="slotProps">
              <div class="oval" :style="columnStyle(slotProps.data)">
                <span>{{ getTextStatus(slotProps.data.current_status) }}</span>
              </div>
            </template></Column
          >
          <Column
            :header="$t('label_order_code')"
            field="order_code"
            headerStyle="width: 18rem;"
            bodyStyle="width:  18rem"
          >
          </Column>
          <Column
            :header="$t('label_order_time')"
            headerStyle="width: 12.5rem;"
            bodyStyle="width: 12.5rem;"
          >
            <template #body="slotProps">
              {{ formatUnixDateTime2String(slotProps.data.created_at) }}
            </template>
          </Column>

          <Column
            :header="$t('label_priority')"
            headerStyle="width: 12rem"
            bodyStyle="width: 12rem"
          >
            <template #body="slotProps">
              {{ getPriority(slotProps.data) }}
            </template>
          </Column>
          <Column
            :header="$t('label_order_note_new')"
            headerStyle="width: 25rem"
            bodyStyle="width: 25rem"
          >
            <template #body="slotProps">
              {{ slotProps.data.note ? slotProps.data.note : "" }}
            </template>
          </Column>
          <Column
            headerStyle="width: 7rem"
            bodyStyle="width: 7rem;overflow: visible"
            :headerClass="'p-frozen-column right'"
            :bodyClass="'p-frozen-column right'"
          >
            <template #body="slotProps">
              <Button
                :icon="
                  propButtonDetail &&
                  propButtonDetail.processing &&
                  slotProps.data.id === propButtonDetail.id
                    ? 'fas fa-spinner fa-spin'
                    : 'fas fa-clipboard-list'
                "
                type="button"
                class="p-button -button-sm p-button-info mr-1"
                :title="$t('header_detail')"
                @click="showOrderDetail(slotProps.data)"
                :disabled="
                  propButtonDetail &&
                  propButtonDetail.disable &&
                  slotProps.data.id === propButtonDetail.id
                    ? propButtonDetail.disable
                    : false
                "
              >
              </Button>
              <Button
                style="color: white"
                v-if="
                  getRemark(slotProps.data) != null &&
                  slotProps.data.current_status.name === 'ORDER_REJECTED'
                "
                icon="fas fa-sticky-note text-white"
                type="button"
                class="p-button p-button-sm p-button-warning mr-1"
                :title="getTitleRemark(slotProps.data)"
                @click="shoDialogRemark(slotProps.data)"
              >
              </Button>
              <Button
                v-if="
                  slotProps.data.current_status &&
                  slotProps.data.current_status.name === 'ORDER_CREATED'
                "
                icon="fas fa-trash"
                type="button"
                class="p-button-sm p-button-danger"
                :title="$t('button_text_cancel_order')"
                @click="shoDialogCancelOrder(slotProps.data)"
              >
              </Button>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion3";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import Button from "@/components/commons/button/Button";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
import moment from "moment";
export default {
  components: {
    CommonAccordion,
    DataTable,
    Column,
    Button,
    ContextMenu,
  },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propTable: {
      type: Object,
      default: () => {},
    },
    propLoading: {
      type: Boolean,
      default: false,
    },
    propTotal: {
      type: Number,
      default: 0,
    },
    propButtonDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      menuModel: [
        {
          label: this.$t("title_image_list"),
          icon: "fas fa-list",
          command: () => this.showOrderDetail(this.propTable.selected),
          visible: true,
        },
        {
          label: this.$t("button_text_cancel_order"),
          icon: "fas fa-trash",
          command: () => this.shoDialogCancelOrder(this.propTable.selected),
          visible: true,
        },
      ],
    };
  },
  methods: {
    showOrderDetail(item) {
      this.$emit("showOrderDetail", item);
    },
    shoDialogCancelOrder(item) {
      this.$emit("showDialogCancel", item);
    },
    shoDialogRemark(item) {
      this.$emit("shoDialogRemark", {
        title: this.getTitleRemark(item),
        remark: this.getRemark(item),
      });
    },
    onVirtualScroll(e) {
      this.$emit("nextPage");
    },
    onRowContextMenu(event) {
      this.propTable.selected = event.data;
      if (
        this.propTable.selected &&
        Object.keys(this.propTable.selected).length > 0
      ) {
        if (
          this.propTable.selected.images &&
          this.propTable.selected.images.length > 0
        )
          this.menuModel[0].visible = true;
        else this.menuModel[0].visible = false;
        if (
          this.propTable.selected.current_status &&
          Object.keys(this.propTable.selected.current_status).length > 0
        ) {
          if (this.propTable.selected.current_status.name === "ORDER_CREATED")
            this.menuModel[1].visible = true;
          else this.menuModel[1].visible = false;
        } else this.menuModel[1].visible = false;
        if (this.menuModel.filter((x) => x.visible).length > 0)
          this.$refs.cmOrderDatatable.show(event.originalEvent);
      }
    },
    getTextStatus(item) {
      let result = "";
      if (item) {
        if (item.translation && Object.keys(item.translation).length > 0) {
          result =
            item.translation[this.lang] && item.translation[this.lang].value
              ? item.translation[this.lang].value
              : null;
        }
        if (!result) result = item.value ? item.value : null;
      }
      return result.trim().replace(/\s\s+/g, " ").replace("ORDER_", "");
    },
    getTitleRemark(data) {
      let curentStatus = data.current_status;
      if (curentStatus && Object.keys(curentStatus).length > 0) {
        switch (curentStatus.name) {
          case "ORDER_REJECTED":
            return this.$t("label_order_reason");
            break;
          case "ORDER_PROCESSING":
            return this.$t("label_note");
            break;
          case "ORDER_COMPLETED":
            return this.$t("label_note");
            break;
          case "ORDER_CANCELED":
            return this.$t("label_order_reason");
            break;
          default:
            return this.$t("label_note");
            break;
        }
      }
      return null;
    },
    getPriority(data) {
      let priority = data.priority ? data.priority : null;
      let result = null;
      if (priority) {
        result = priority.value ? priority.value : null;
        if (priority.translation) {
          if (priority.translation[this.lang]) {
            result = priority.translation[this.lang].value;
          }
        }
      }
      return result;
    },
    getRemark(data) {
      let curentStatus = data.current_status;
      if (curentStatus && Object.keys(curentStatus).length > 0) {
        if (curentStatus.name != "ORDER_CREATED") {
          let item = data.order_status.find(
            (x) => x.order_status.id === curentStatus.id
          );
          if (item && Object.keys(item).length > 0)
            return item.remark.trim().replace(/\s\s+/g, " ") != ".."
              ? item.remark.trim().replace(/\s\s+/g, " ")
              : null;
          else return null;
        }
      }
      return null;
    },
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },

    rowClass() {},
    columnStyle(val) {
      let result = "";
      let currentStatus = val.current_status;
      if (currentStatus && Object.keys(currentStatus).length > 0) {
        switch (currentStatus.name) {
          case "ORDER_PROCESSING":
            result = "background:#0d6efd";
            break;
          case "ORDER_CREATED":
            result = "background:#F68E1E";
            break;
          case "ORDER_COMPLETED":
            result = "background:#27AE60";
            break;
          case "ORDER_CANCELED":
            result = "background:#ffc107";
            break;
          case "ORDER_REJECTED":
            result = "background:#F1416C";
            break;
          case "ORDER_CONFIRMED":
            result = "background:#0dcaf0";
            break;
          default:
            result = "background:#9da5b1";
            break;
        }
        return result;
      }
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.datatable {
  .oval {
    height: 36px;
    width: 128px;
    margin: 0 auto;
    border-radius: 20px;
    text-align: center;
    span {
      text-align: center;
      vertical-align: middle;
      line-height: 36px;
      color: white;
      font-size: 10px;
    }
  }
}
</style>

<style lang="scss">
.my-order__datatable {
  @import "@/assets/scss/_datatable.scss";
}
</style>
