<template>
  <div class="relative flex items-top justify-center" id="notfound">
    <div class="max-w-xl mx-auto notfound">
      <div class="flex items-center justify-start">
        <div
          class="pr-4 text-lg text-gray-500 border-r border-gray-400 tracking-wider"
        >
          404
        </div>

        <div class="ml-4 text-lg text-gray-500 uppercase tracking-wider">
          Not Found
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    go2Home() {
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss" scoped>
.relative {
  position: relative;
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.min-h-screen {
  min-height: 100vh;
}
.max-w-xl {
  max-width: 36rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.items-center {
  align-items: center;
}
.justify-start {
  justify-content: flex-start;
}
.tracking-wider {
  letter-spacing: 0.05em;
}
.uppercase {
  text-transform: uppercase;
}
.text-gray-500 {
  --text-opacity: 1;
  color: #a0aec0;
  color: rgba(160, 174, 192, var(--text-opacity));
}
.ml-4 {
  margin-left: 1rem;
}
.text-lg {
  font-size: 1.125rem;
}
.border-r {
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-right: 1px solid rgba(203, 213, 224, var(--border-opacity));
}
.cursor-pointer {
  cursor: pointer;
}
.text-base {
  font-size: 16px;
}
#notfound {
  height: calc(100vh - 56px);
}
.notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
