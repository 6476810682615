var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"attempts"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"row filter"},[_c('p',{staticClass:"m-0"},[_c('label',{staticClass:"date-field grid grid-cols-1 cursor-pointer"},[_c('date-picker',{staticClass:"order-1 control-date w-100",attrs:{"format":"DD/MM/YYYY HH:mm","disabled-date":_vm.notBeforeToday,"value-type":"timestamp","lang":_vm.lang,"clearable":false,"disabled":_vm.propAttempts && _vm.propAttempts.length && _vm.propAttempts[0].loading},model:{value:(_vm.propEntity.startDate),callback:function ($$v) {_vm.$set(_vm.propEntity, "startDate", $$v)},expression:"propEntity.startDate"}}),_c('span',[_vm._v(_vm._s(_vm.$t("label_date")))])],1)]),(
          _vm.propProductTypeSelected.technicalProduct.target.id.toLowerCase() ===
          'oneday'
        )?_c('p',{staticClass:"m-0"},[_c('label',{staticClass:"sort-field"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.sort),expression:"sort"}],staticClass:"order-1 form-control",attrs:{"disabled":_vm.propAttempts && _vm.propAttempts.length && _vm.propAttempts[0].loading},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.sort=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{staticClass:"ng-binding",attrs:{"value":"accessToTarget,up"}},[_vm._v(" UTC access to target (Ascending) ")]),_c('option',{staticClass:"ng-binding",attrs:{"value":"accessToTarget,down"}},[_vm._v(" UTC access to target (Descending) ")]),_c('option',{staticClass:"ng-binding",attrs:{"value":"mission,up"}},[_vm._v(" Satellite (Ascending) ")]),_c('option',{staticClass:"ng-binding",attrs:{"value":"mission,down"}},[_vm._v(" Satellite (Descending) ")]),_c('option',{staticClass:"ng-binding",attrs:{"value":"orderDeadline,up"}},[_vm._v(" Order deadline (Ascending) ")]),_c('option',{staticClass:"ng-binding",attrs:{"value":"orderDeadline,down"}},[_vm._v(" Order deadline (Descending) ")]),_c('option',{staticClass:"ng-binding",attrs:{"value":"viewingAngle,up"}},[_vm._v(" Incidence angle (Ascending) ")]),_c('option',{staticClass:"ng-binding",attrs:{"value":"viewingAngle,down"}},[_vm._v(" Incidence angle (Descending) ")])]),_c('span',{staticClass:"label-sort"},[_vm._v(_vm._s(_vm.$t("label_sort")))])])]):_vm._e()]),(_vm.propAttempts && _vm.propAttempts.length && _vm.propAttempts[0].loading)?_c('div',{staticClass:"loading-description"},[_vm._m(0)]):_vm._e(),(_vm.propAttempts && _vm.propAttempts.length && _vm.propAttempts[0].progTypes)?_c('div',{staticClass:"row options"},[(_vm.errors && _vm.errors.length)?_c('div',{staticClass:"col-12 p-0 m-0"},[_c('ul',{staticClass:"tasking-errors-list"},[([
              'ERROR_AOI_MAX_HEIGHT',
              'ERR_AOI_MAX_HEIGHT',
              'ERR_AOI_MAX_WIDTH',
              'ERROR_AOI_MAX_WIDTH',

              'ERROR_AOI_MIN_HEIGHT',
              'ERR_AOI_MIN_HEIGHT',
              'ERR_AOI_MIN_WIDTH',
              'ERROR_AOI_MIN_WIDTH' ])?_c('li',{staticClass:"well-area-error"},[_c('p',{staticClass:"well-error-tasking"},[_vm._v(" Some polygons are not eligible for the following reasons. Please contact with technical support ")]),_c('button',{staticClass:"btn btn-modify-polygon",on:{"click":_vm.modifyPolygon}},[_c('i',{staticClass:"fas fa-draw-polygon"}),_vm._v(" Modify polygon ")])]):_vm._e(),_vm._l((_vm.errors),function(error){return _c('li',{key:error.code},[(
                ['ERROR_AOI_MAX_HEIGHT', 'ERR_AOI_MAX_HEIGHT'].includes(
                  error.code
                )
              )?_c('div',{staticClass:"error-height-max"},[_vm._m(1,true),_c('br'),_vm._m(2,true),_vm._v(" "),_c('span',{staticClass:"tasking-error-message"},[_vm._v(_vm._s(error.message)+" km")])]):(
                ['ERR_AOI_MAX_WIDTH', 'ERROR_AOI_MAX_WIDTH'].includes(
                  error.code
                )
              )?_c('div',{staticClass:"error-width-max"},[_vm._m(3,true),_c('br'),_vm._m(4,true),_vm._v(" "),_c('span',{staticClass:"tasking-error-message"},[_vm._v(_vm._s(error.message)+" km")])]):(
                ['ERR_MIN_AOI_AREA', 'ERROR_MIN_AOI_AREA'].includes(
                  error.code
                )
              )?_c('div',{staticClass:"error-area-min"},[_vm._m(5,true),_c('br'),_vm._m(6,true),_vm._v(" "),_c('span',{staticClass:"tasking-error-message"},[_vm._v(_vm._s(error.message)+" km")])]):_c('div',[_c('span',[_c('i',{staticClass:"fas fa-exclamation-triangle"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(error.code))])]),_c('br'),_c('span',{staticClass:"tasking-error-message"},[_vm._v(_vm._s(error.message))])])])})],2)]):_vm._e(),_c('div',{staticClass:"col-12 p-0 m-0"},[(
            _vm.propProductTypeSelected.technicalProduct.target.id.toLowerCase() ===
            'oneday'
          )?_c('OneDay',{attrs:{"prop-attempts":_vm.propAttempts,"prop-selected":_vm.selected,"prop-footprints":_vm.footprints,"prop-sort":_vm.sort},on:{"mouseOverItem":_vm.mouseOverItem,"mouseLeaveItem":_vm.mouseLeaveItem,"selectAttempt":_vm.selectAttempt,"addFootprint":_vm.addFootprint}}):(
            _vm.propProductTypeSelected.technicalProduct.target.id.toLowerCase() ===
            'onenow'
          )?_c('OneNow',{attrs:{"prop-attempts":_vm.propAttempts,"prop-selected":_vm.selected,"prop-footprints":_vm.footprints},on:{"mouseOverItem":_vm.mouseOverItem,"mouseLeaveItem":_vm.mouseLeaveItem,"selectAttempt":_vm.selectAttempt,"addFootprint":_vm.addFootprint}}):_vm._e()],1)]):_vm._e()]),_c('div',{staticClass:"right"},[_c('AttemptsMap',{attrs:{"prop-content-height":_vm.propContentHeight},on:{"initMapSuccess":_vm.initMapSuccess}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('i',{staticClass:"fas fa-spinner fa-spin"}),_vm._v(" Please wait while loading products ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"fas fa-arrows-alt-v"}),_vm._v(" "),_c('span',[_vm._v("Height")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"tasking-error-label"},[_c('span',[_vm._v("max.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"fas fa-arrows-alt-h"}),_vm._v(" "),_c('span',[_vm._v("Width")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"tasking-error-label"},[_c('span',[_vm._v("max.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"fas fa-vector-square"}),_vm._v(" "),_c('span',[_vm._v("Area")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"tasking-error-label"},[_c('span',[_vm._v("min.")])])}]

export { render, staticRenderFns }