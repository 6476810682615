import { render, staticRenderFns } from "./MyOrdered.vue?vue&type=template&id=a3c88778&scoped=true&"
import script from "./MyOrdered.vue?vue&type=script&lang=js&"
export * from "./MyOrdered.vue?vue&type=script&lang=js&"
import style0 from "./MyOrdered.vue?vue&type=style&index=0&id=a3c88778&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3c88778",
  null
  
)

export default component.exports