var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"confirmation-tasking-time-optical"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col"},[_c('fieldset',{staticClass:"p-0"},[_c('legend',{staticClass:"w-auto"},[_vm._v(" "+_vm._s(_vm.$t("label_request_collection_date_range"))+" ")]),_c('div',{staticClass:"w-100 d-flex mb-3"},[_c('label',{staticClass:"range-time-label",staticStyle:{"width":"64px"}},[_vm._v(_vm._s(_vm.$t("label_from")))]),_c('InputText',{staticClass:"form-control new-style",staticStyle:{"width":"calc(100% - 64px)"},attrs:{"value":_vm.formatUnixDateTime2String(
                _vm.propDto.order_request_info.date_from
                  ? new Date(_vm.propDto.order_request_info.date_from).getTime()
                  : ''
              ),"readonly":"","maxlength":"25"}})],1),_c('div',{staticClass:"w-100 d-flex"},[_c('label',{staticClass:"range-time-label",staticStyle:{"width":"64px"}},[_vm._v(_vm._s(_vm.$t("label_to")))]),_c('InputText',{staticClass:"form-control new-style",staticStyle:{"width":"calc(100% - 64px)"},attrs:{"value":_vm.formatUnixDateTime2String(
                _vm.propDto.order_request_info.date_to
                  ? new Date(_vm.propDto.order_request_info.date_to).getTime()
                  : ''
              ),"readonly":"","maxlength":"25"}})],1)])]),_c('div',{staticClass:"col"},[_c('fieldset',{staticClass:"p-0"},[_c('legend',{staticClass:"w-auto"},[_vm._v(_vm._s(_vm.$t("label_incidence_angle"))+" (°)")]),_c('div',{staticClass:"w-100 d-flex mb-3"},[_c('label',{staticClass:"range-time-label",staticStyle:{"width":"64px"}},[_vm._v(_vm._s(_vm.$t("label_from")))]),_c('InputText',{staticClass:"form-control new-style",staticStyle:{"width":"calc(100% - 64px)"},attrs:{"value":_vm.propDto.order_request_info.incidence_angle_min,"readonly":"","maxlength":"255"}})],1),_c('div',{staticClass:"w-100 d-flex"},[_c('label',{staticClass:"range-time-label",staticStyle:{"width":"64px"}},[_vm._v(_vm._s(_vm.$t("label_to")))]),_c('InputText',{staticClass:"form-control new-style",staticStyle:{"width":"calc(100% - 64px)"},attrs:{"value":_vm.propDto.order_request_info.incidence_angle_max,"readonly":"","maxlength":"255"}})],1)])]),_c('div',{staticClass:"col"},[_c('fieldset',{staticClass:"p-0"},[_c('legend',{staticClass:"w-auto"},[_vm._v(_vm._s(_vm.$t("label_cloud_cove"))+" (%)")]),_c('div',{staticClass:"w-100 d-flex"},[_c('label',{staticClass:"range-time-label",staticStyle:{"width":"64px"}},[_vm._v(_vm._s(_vm.$t("label_to")))]),_c('InputText',{staticClass:"form-control new-style",staticStyle:{"width":"calc(100% - 64px)"},attrs:{"value":_vm.propDto.order_request_info.cloud_coverage,"readonly":"","maxlength":"255"}})],1)])])]),_c('div',{staticClass:"row no-gutters"},[_c('label',{staticClass:"col-12 col-md-2 col-form-label"},[_vm._v(_vm._s(_vm.$t("label_collection_order_type")))]),_c('div',{staticClass:"col-12 col-md-10"},[_c('InputText',{staticClass:"form-control new-style",attrs:{"maxlength":"255","value":_vm.propDto.order_request_info.collection_order_type
            ? _vm.propDto.order_request_info.collection_order_type.translation &&
              _vm.propDto.order_request_info.collection_order_type.translation[
                _vm.lang
              ] &&
              _vm.propDto.order_request_info.collection_order_type.translation[
                _vm.lang
              ].value
              ? _vm.propDto.order_request_info.collection_order_type.translation[
                  _vm.lang
                ].value
              : _vm.propDto.order_request_info.collection_order_type.value
            : null,"readonly":""}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }