<template>
  <ul class="capella-summary-list">
    <li class="summary-item" v-for="p in arraySatelliteName" :key="p.name">
      <div class="item-header">
        <svg
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-do1tfz"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="SatelliteIcon"
        >
          <path
            fill-rule="evenodd"
            d="m17.714 2.3597 4.0406 4.0406c.4463.4463.4463 1.17 0 1.6162l-4.0405 4.0404.808.8084c.4463.4463.4463 1.1699 0 1.6162l-4.0406 4.0406c-.4463.4463-1.17.4463-1.6162 0l-.8084-.808-4.0404 4.0405c-.4463.4463-1.1699.4463-1.6162 0L2.3597 17.714c-.4463-.4463-.4463-1.17 0-1.6162l4.0401-4.0409-.8076-.8079c-.4463-.4463-.4463-1.17 0-1.6162l4.0406-4.0406c.4463-.4463 1.17-.4463 1.6162 0l.808.8076 4.0408-4.0401c.4463-.4463 1.1699-.4463 1.6162 0ZM4.7838 15.2889l-1.616 1.617 4.0406 4.0406L8.8238 19.33l-4.04-4.0412Zm2.4229-2.4251-1.6149 1.6171 4.04 4.0412 1.616-1.6172-4.0411-4.0411Zm3.2342-6.4635-4.0406 4.0406 7.273 7.2731 4.0407-4.0406-7.2731-7.2731Zm4.04-.8085-1.6171 1.6149 4.0411 4.0411 1.6172-1.616-4.0412-4.04Zm2.425-2.424-1.617 1.616 4.0412 4.04 1.6164-1.6154-4.0406-4.0406Z"
          ></path>
        </svg>
        <p
          class="d-flex justify-content-between align-items-center m-0 w-full w-100"
        >
          <span> {{ p.value }}</span>
          <strong
            class="text-success text-right font-weight-bold"
            style="font-size: 14px"
            >(C)</strong
          >
        </p>
      </div>
      <div class="item-card">
        <div class="px-2 py-3">
          <div class="item-count">
            <svg
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="BurstModeOutlinedIcon"
            >
              <path
                d="M1 5h2v14H1zm4 0h2v14H5zm17 0H10c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1zm-1 12H11V7h10v10zm-3.57-4.38-2 2.57L14 13.47l-2 2.52h8z"
              ></path></svg
            ><span class="pl-1 pt-1">&nbsp; {{ p.count }} items </span>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
export default {
  props: {
    propImages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      satellites: [],
      arraySatelliteName: [],
    };
  },
  created() {
    this.satellites = this.propImages.map((x) => x.properties.platform);
    for (let i = 0; i < this.satellites.length; i++) {
      let index = this.arraySatelliteName.findIndex(
        (x) => x.name === this.satellites[i].name
      );
      if (index >= 0) {
        this.arraySatelliteName[index].count++;
      } else {
        this.arraySatelliteName.push({
          name: this.satellites[i],
          value: this.satellites[i],
          count: 1,
        });
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

ul.capella-summary-list {
  list-style: none;
  margin: 0;
  padding: 0;
  li.summary-item {
    padding: 0.75rem;
    border-bottom: 1px solid #eff2f5;
    &:last-child {
      border: 0;
    }
    .item-header {
      display: flex;
      box-sizing: border-box;
      list-style: none;
      font-weight: 500;
      font-size: 1rem;
      margin-bottom: 0.25rem;
      background-color: transparent;
      svg {
        user-select: none;
        width: 1em;
        height: 1em;
        display: inline-block;
        fill: currentcolor;
        flex-shrink: 0;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-size: 1.5rem;
        margin-right: 4px;
      }
    }
    .item-card {
      width: 100%;
      color: rgb(0, 31, 35);
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-radius: 4px;
      border: 1px solid rgb(189, 197, 198);
      overflow: hidden;
      background-color: white;
      flex-shrink: 0;
      .item-count {
        max-width: 100%;
        font-family: Inter, sans-serif;
        font-size: 0.8125rem;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        height: 24px;
        color: rgb(0, 31, 35);
        white-space: nowrap;
        transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        cursor: default;
        outline: 0px;
        text-decoration: none;
        padding: 0px;
        vertical-align: middle;
        box-sizing: border-box;
        background-color: transparent;
        border-radius: 4px;
        border: none;
        svg {
          user-select: none;
          width: 1em;
          height: 1em;
          display: inline-block;
          fill: currentcolor;
          flex-shrink: 0;
          transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          font-size: 1.5rem;
        }
      }
    }
  }
}
</style>
