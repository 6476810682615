<template>
  <vue-tabs :value="tabIndex" @tab-change="handleTabChange">
    <v-tab title="Inprogress"
      ><InprogressTab
        :prop-tabs-height="propTabsHeight"
        :prop-data="propData.inprogress"
        :prop-footprint-ids="propFootprintIds"
        @pagechanged="pagechanged"
        @mouseOverItem="mouseOverItem"
        @mouseLeaveItem="mouseLeaveItem"
        @addFootprint="addFootprint"
        @pan2Location="pan2Location"
        @showDetail="showDetail"
        @cancelTasking="cancelTasking"
    /></v-tab>
    <v-tab title="Completed"
      ><CompletedTab
        :prop-tabs-height="propTabsHeight"
        :prop-data="propData.completed"
        :prop-footprint-ids="propFootprintIds"
        @pagechanged="pagechanged"
        @mouseOverItem="mouseOverItem"
        @mouseLeaveItem="mouseLeaveItem"
        @addFootprint="addFootprint"
        @pan2Location="pan2Location"
        @showDetail="showDetail"
    /></v-tab>
    <v-tab title="Cancelled"
      ><CancelledTab
        :prop-tabs-height="propTabsHeight"
        :prop-data="propData.cancelled"
        :prop-footprint-ids="propFootprintIds"
        @pagechanged="pagechanged"
        @mouseOverItem="mouseOverItem"
        @mouseLeaveItem="mouseLeaveItem"
        @addFootprint="addFootprint"
        @pan2Location="pan2Location"
        @showDetail="showDetail"
    /></v-tab>
  </vue-tabs>
</template>
<script>
import { VueTabs, VTab } from "vue-nav-tabs";
import InprogressTab from "./tabs/Inprogress.vue";
import CompletedTab from "./tabs/Completed.vue";
import CancelledTab from "./tabs/Cancelled.vue";
export default {
  components: { VueTabs, VTab, InprogressTab, CompletedTab, CancelledTab },
  props: {
    propTabsHeight: {
      type: Number,
      default: 0,
    },
    propData: {
      type: Object,
      default: () => {},
    },
    propFootprintIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  methods: {
    handleTabChange(e) {
      this.tabIndex = e;
      this.$emit("tabChanged", e);
    },
    pagechanged(data) {
      this.$emit("pagechanged", data);
    },
    mouseOverItem(item) {
      this.$emit("mouseOverItem", item);
    },
    mouseLeaveItem(item) {
      this.$emit("mouseLeaveItem", item);
    },
    addFootprint(item) {
      this.$emit("addFootprint", item);
    },
    pan2Location(item) {
      this.$emit("pan2Location", item);
    },
    showDetail(item) {
      this.$emit("showDetail", item);
    },
    cancelTasking(item) {
      this.$emit("cancelTasking", item);
    },
  },
};
</script>
<style lang="scss" scoped></style>
