<template>
  <nav id="sidebar" class="sidebar-wrapper">
    <div class="sidebar-content">
      <div class="sidebar-brand">
        <a class="text-left" @click="goHome">
          <img src="/img/logo.png" class="d-inline-block align-top" alt />
          <span class="text-white">Geospatial Hub</span>
        </a>
        <div id="close-sidebar">
          <i class="fas fa-times"></i>
        </div>
      </div>
      <div class="sidebar-header">
        <div class="user-pic">
          <img
            class="img-responsive img-rounded"
            src="/img/user.jpg"
            alt="User picture"
          />
        </div>
        <div class="user-info">
          <span class="user-name">
            <strong>{{ userMe.full_name ? userMe.full_name : "" }}</strong>
          </span>
          <span class="user-status">
            <i class="fa fa-circle"></i>
            <span class="text-white">Online</span>
          </span>
        </div>
      </div>
      <div class="sidebar-menu">
        <ul>
          <li
            class="sidebar-dropdown"
            :class="
              ['USER_INFO', 'USER_CHANGE_PASS'].includes(menuActive)
                ? 'active'
                : ''
            "
          >
            <a>
              <i class="fas fa-user"></i>
              <span>{{ $t("menu_sidebar_account") }}</span>
            </a>
            <div
              class="sidebar-submenu menu-children"
              :style="{
                display: ['USER_INFO', 'USER_CHANGE_PASS'].includes(menuActive)
                  ? 'block'
                  : '',
              }"
            >
              <ul>
                <li
                  :class="menuActive === 'USER_INFO' ? 'active' : ''"
                  @click="goUserInfoPage"
                >
                  <a>{{ $t("label_my_info") }}</a>
                </li>
                <li
                  :class="menuActive === 'USER_CHANGE_PASS' ? 'active' : ''"
                  @click="goUserChangePassPage"
                >
                  <a>{{ $t("menu_sidebar_change_pass") }}</a>
                </li>
              </ul>
            </div>
          </li>
          <li
            class="sidebar-dropdown"
            :class="
              [
                'MY_IMAGES',
                'UPLOAD_HISTORY',
                'MY_SHARE',
                'USER_SHARED',
                'UPLOAD',
              ].includes(menuActive)
                ? 'active'
                : ''
            "
          >
            <a>
              <i class="fas fa-images"></i>
              <span>{{ $t("menu_sidebar_data_management") }}</span>
            </a>
            <div
              class="sidebar-submenu menu-children"
              :style="{
                display: [
                  'MY_IMAGES',
                  'UPLOAD_HISTORY',
                  'MY_SHARE',
                  'USER_SHARED',
                  'UPLOAD',
                ].includes(menuActive)
                  ? 'block'
                  : '',
              }"
            >
              <ul>
                <li
                  :class="menuActive === 'USER_SHARED' ? 'active' : ''"
                  @click="goUserSharedPage"
                >
                  <a>{{ $t("menu_sidebar_image_shared_with_me") }}</a>
                </li>
              </ul>
            </div>
          </li>
          <li
            class="sidebar-dropdown"
            :class="
              ['MY_ORDER', 'MY_ORDER_TASKING'].includes(menuActive)
                ? 'active'
                : ''
            "
          >
            <a>
              <i class="fas fa-clipboard-list"></i>
              <span>{{ $t("menu_sidebar_orders") }} </span>
            </a>
            <div
              class="sidebar-submenu menu-children"
              :style="[
                !menuActive ||
                ['MY_ORDER', 'MY_ORDER_TASKING'].includes(menuActive)
                  ? { display: 'block' }
                  : {},
              ]"
            >
              <ul>
                <li
                  :class="menuActive === 'MY_ORDER_TASKING' ? 'active' : ''"
                  @click="goOrderTaskingPage"
                >
                  <a>{{ $t("menu_sidebar_my_ordered_tasking") }}</a>
                </li>
                <li
                  :class="menuActive === 'MY_ORDER' ? 'active' : ''"
                  @click="goMyOrderPage"
                >
                  <a>{{ $t("menu_sidebar_my_request") }}</a>
                </li>
              </ul>
            </div>
          </li>
          <li
            class="sidebar-dropdown"
            :class="
              [
                'INTEGRATE_DATA',
                'INTEGRATE_PLANET',
                'INTEGRATE_ONEATLAS',
              ].includes(menuActive)
                ? 'active'
                : ''
            "
          >
            <a>
              <i class="fas fa-images"></i>
              <span>{{ $t("menu_sidebar_integrate_data") }}</span>
            </a>
            <div
              class="sidebar-submenu menu-children"
              :style="{
                display: [
                  'INTEGRATE_DATA',
                  'INTEGRATE_PLANET',
                  'INTEGRATE_ONEATLAS',
                ].includes(menuActive)
                  ? 'block'
                  : '',
              }"
            >
              <ul>
                <li
                  :class="menuActive === 'INTEGRATE_PLANET' ? 'active' : ''"
                  @click="go2IntegratePlanetPage"
                >
                  <a>{{ $t("menu_sidebar_integrate_planet") }}</a>
                </li>
                <li
                  :class="menuActive === 'INTEGRATE_ONEATLAS' ? 'active' : ''"
                  @click="go2IntegrateAirbusPage"
                >
                  <a>{{ $t("menu_sidebar_integrate_oneatlas") }}</a>
                </li>
              </ul>
            </div>
          </li>

          <li
            v-if="isAdmin"
            class="sidebar-dropdown"
            :class="
              ['TASKING_DATA', 'TASKING_PLANET', 'TASKING_ONEATLAS'].includes(
                menuActive
              )
                ? 'active'
                : ''
            "
          >
            <a>
              <i class="fas fa-images"></i>
              <span>{{ $t("menu_sidebar_tasking") }}</span>
            </a>
            <div
              class="sidebar-submenu menu-children"
              :style="{
                display: [
                  'TASKING_DATA',
                  'TASKING_PLANET',
                  'TASKING_ONEATLAS',
                ].includes(menuActive)
                  ? 'block'
                  : '',
              }"
            >
              <ul>
                <li
                  :class="menuActive === 'TASKING_PLANET' ? 'active' : ''"
                  @click="go2IntegratePlanetPage"
                >
                  <a>{{ $t("menu_sidebar_integrate_planet") }}</a>
                </li>
                <li
                  :class="menuActive === 'TASKING_ONEATLAS' ? 'active' : ''"
                  @click="go2TaskingAirbusPage"
                >
                  <a>{{ $t("menu_sidebar_integrate_oneatlas") }}</a>
                </li>
              </ul>
            </div>
          </li>
          <li
            :class="['STATISTIC_REPORT'].includes(menuActive) ? 'active' : ''"
            @click="goStatisticReportPage"
            data-type="no-sub"
          >
            <a>
              <i class="fas fa-chart-line"></i
              >{{ $t("menu_sidebar_statistic") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="sidebar-footer text-center">
      <button class="btn btn-sm" @click="onLogout">
        <i class="fa fa-power-off"></i>
      </button>
    </div>
  </nav>
</template>
<script>
import oauthFunc from "@/utils/functions/oauth";
export default {
  data() {
    return {
      menuActive: "",
      language: null,
    };
  },
  async created() {
    this.language = this.lang;
    this.getMenuActive(this.$route.path);
  },
  watch: {
    route: {
      handler(val) {
        this.getMenuActive(val.path);
      },
      deep: true,
    },
    language(val) {
      localStorage.setItem("language", val);
      this.$store.dispatch("setLang", val);
    },
    loggedIn: {
      handler(val) {
        if (val) {
          this.getMyInfo();
        } else {
          this.cartCount = 0;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getMyInfo() {
      try {
        let response = await oauthFunc.userMe(
          this.$store.getters.getAccessToken
        );
        if (response.status === 200) {
          this.$store.dispatch("setUser", response.data);
        }
      } catch (error) {}
    },
    getMenuActive(val) {
      switch (val) {
        case "/":
          this.menuActive = "HOME";
          break;
        case "/myself":
        case "/myself/":
          this.menuActive = "USER_INFO";
          break;
        case "/myself/change-pass":
          this.menuActive = "USER_CHANGE_PASS";
          break;
        case "/myself/shared-for-me":
          this.menuActive = "USER_SHARED";
          break;
        case "/myself/my-cart":
          this.menuActive = "MY_CART";
          break;
        case "/myself/my-project":
          this.menuActive = "MY_PROJECT";
          break;
        case "/myself/my-images":
          this.menuActive = "MY_IMAGES";
          break;
        case "/myself/my-order-tasking":
          this.menuActive = "MY_ORDER_TASKING";
          break;
        case "/myself/my-order-free":
          this.menuActive = "MY_ORDER_FREE";
          break;
        case "/myself/my-order":
          this.menuActive = "MY_ORDER";
          break;
        case "/myself/statistic-upload":
          this.menuActive = "STATISTIC_UPLOAD";
          break;
        case "/myself/statistic-download":
          this.menuActive = "STATISTIC_DOWNLOAD";
          break;
        case "/myself/statistic":
          this.menuActive = "STATISTIC_REPORT";
          break;
        case "/myself/upload-history":
          this.menuActive = "UPLOAD_HISTORY";
          break;
        case "/myself/upload":
          this.menuActive = "UPLOAD";
          break;
        case "/myself/my-share":
          this.menuActive = "MY_SHARE";
          break;
        case "/myself/integrate-planet":
          this.menuActive = "INTEGRATE_PLANET";
          break;
        case "/myself/integrate-oneatlas":
          this.menuActive = "INTEGRATE_ONEATLAS";
          break;
        case "/myself/tasking/airbus":
          this.menuActive = "TASKING_ONEATLAS";
          break;
        default:
          this.menuActive = "";
          break;
      }
      if (!this.menuActive) {
        if (val.startsWith("/myself/my-order/")) {
          this.menuActive = "MY_ORDER";
        }
      }
      if (!this.menuActive) {
        if (val.startsWith("/myself/integrate-planet/")) {
          this.menuActive = "INTEGRATE_PLANET";
        }
      }
    },
    goMySharePage() {
      if (this.$route.path != "/myself/my-share")
        this.$router.push({
          path: "/myself/my-share",
        });
    },
    goUploadPage() {
      if (this.$route.path != "/myself/upload")
        this.$router.push({
          path: "/myself/upload",
        });
    },
    goUploadHistoryPage() {
      if (this.$route.path != "/myself/upload-history")
        this.$router.push({
          path: "/myself/upload-history",
        });
    },
    goStatisticUploadPage() {
      if (this.$route.path != "/myself/statistic-upload")
        this.$router.push({
          path: "/myself/statistic-upload",
        });
    },
    goStatisticReportPage() {
      if (this.$route.path != "/myself/statistic")
        this.$router.push({
          path: "/myself/statistic",
        });
    },
    goStatisticDownloadPage() {
      if (this.$route.path != "/myself/statistic-download")
        this.$router.push({
          path: "/myself/statistic-download",
        });
    },
    goUserSharedPage() {
      if (this.$route.path != "/myself/shared-for-me")
        this.$router.push({
          path: "/myself/shared-for-me",
        });
    },
    goMyCartPage() {
      if (this.$route.path != "/myself/my-cart")
        this.$router.push({
          path: "/myself/my-cart",
        });
    },
    goMyProjectPage() {
      if (this.$route.path != "/myself/my-project")
        this.$router.push({
          path: "/myself/my-project",
        });
    },
    goMyImagePage() {
      if (this.$route.path != "/myself/my-images")
        this.$router.push({
          path: "/myself/my-images",
        });
    },
    goMyOrderPage() {
      if (this.$route.path != "/myself/my-order")
        this.$router.push({
          path: "/myself/my-order",
        });
    },
    goOrderTaskingPage() {
      if (this.$route.path != "/myself/my-order-tasking")
        this.$router.push({
          path: "/myself/my-order-tasking",
        });
    },
    go2IntegratePlanetPage() {
      if (this.$route.path != "/myself/integrate-planet")
        this.$router.push({
          path: "/myself/integrate-planet",
        });
    },
    go2IntegrateAirbusPage() {
      if (this.$route.path != "/myself/integrate-oneatlas")
        this.$router.push({
          path: "/myself/integrate-oneatlas",
        });
    },
    go2TaskingAirbusPage() {
      if (this.$route.path != "/myself/tasking/airbus")
        this.$router.push({
          path: "/myself/tasking/airbus",
        });
    },
    goUserInfoPage() {
      if (this.$route.path != "/myself" || this.$route.path != "/myself/")
        this.$router.push({
          path: "/myself",
        });
    },
    goUserChangePassPage() {
      if (this.$route.path != "/myself/change-pass")
        this.$router.push({
          path: "/myself/change-pass",
        });
    },
    goHome() {
      if (this.$route.name != "Home") this.$router.push({ name: "Home" });
    },
    onLogout: function () {
      this.$store.dispatch("clearToken").then((r) => {
        this.$router.push({ path: "/" });
      });
    },
  },
  computed: {
    route() {
      return this.$route;
    },
    lang() {
      return this.$store.getters.getLang;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    userMe() {
      return this.$store.getters.getUser;
    },
    roles() {
      return this.$store.getters.getRoles;
    },
    isAdmin() {
      return this.roles && this.roles.map((x) => x.name).includes("ROLE_ADMIN");
    },
  },
  mounted() {
    jQuery(function ($) {
      $(".sidebar-dropdown > a").click(function () {
        $(".sidebar-submenu").slideUp(200);
        if ($(this).parent().hasClass("active")) {
          $(".sidebar-dropdown").removeClass("active");
          $(this).parent().removeClass("active");
        } else {
          $(".sidebar-dropdown").removeClass("active");
          $(this).next(".sidebar-submenu").slideDown(200);
          $(this).parent().addClass("active");
        }
      });

      $("#close-sidebar").click(function () {
        $(".page-wrapper").removeClass("toggled");
      });
      $("#show-sidebar").click(function () {
        $(".page-wrapper").addClass("toggled");
      });
    });
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.sidebar-wrapper .sidebar-menu ul li a,
#close-sidebar {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sidebar-submenu li {
  padding-left: 1rem;
  a {
    color: #9499ac;
  }
}
.sidebar-submenu li.active {
  background: #0083ca;
}

.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
}
.sidebar-wrapper {
  width: 280px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 999;
  font-size: 14px;
}
.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.sidebar-wrapper a {
  text-decoration: none;
}
.sidebar-content {
  overflow-y: auto;
  position: relative;
}
.sidebar-content.desktop {
  overflow-y: hidden;
}
.sidebar-wrapper .sidebar-brand {
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  background: #1a1a27;
  &:hover {
    cursor: pointer;
  }
  a {
    display: flex;
    img {
      width: 30px;
      height: 30px;
    }
    span {
      height: 100%;
      margin: auto 0;
      margin-left: 0.5rem;
      text-transform: uppercase;
      font-size: 12px;
    }
  }
}
.sidebar-wrapper .sidebar-brand > a {
  font-size: 16px;
  flex-grow: 1;
}
.sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 20px;
  padding: 0.25rem;
}
.sidebar-wrapper .sidebar-header {
  padding: 1rem 0.75rem;
  overflow: hidden;
}
.sidebar-wrapper .sidebar-header .user-pic {
  float: left;
  width: 60px;
  padding: 2px;
  border-radius: 12px;
  margin-right: 15px;
  overflow: hidden;
}
.sidebar-wrapper .sidebar-header .user-pic img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.sidebar-wrapper .sidebar-header .user-info {
  float: left;
  background: #1e1e2d;
  .user-name {
    color: white;
  }
  .select-language {
    display: block;
    margin: auto 0;
    select {
      background: transparent;
      color: white;
      border: 0px;
      outline: 0px;
      max-width: 6.5rem;
      font-size: 12px;
      option {
        color: #495057;
      }
      &:active {
        border-color: transparent !important;
      }
      &:hover {
        border-color: transparent !important;
      }
      &:focus-visible {
        border-color: transparent !important;
      }
    }
  }
}
.sidebar-wrapper .sidebar-header .user-info > span {
  display: block;
}
.sidebar-wrapper .sidebar-header .user-info .user-role {
  font-size: 12px;
}
.sidebar-wrapper .sidebar-header .user-info .user-status {
  font-size: 11px;
  margin-top: 4px;
}
.sidebar-wrapper .sidebar-header .user-info .user-status i {
  font-size: 8px;
  margin-right: 4px;
  color: #5cb85c;
}
.sidebar-wrapper .sidebar-menu {
  padding-bottom: 10px;
}
.sidebar-wrapper .sidebar-menu .header-menu span {
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px 5px 20px;
  display: inline-block;
}
.sidebar-wrapper .sidebar-menu ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 0.5rem 1.5rem 0.5rem 0.75rem;
  cursor: pointer;
}
.sidebar-menu .menu-children ul li a {
  padding: 0.5rem 1.5rem 0.5rem 1.25rem;
}
.sidebar-wrapper .sidebar-menu ul li a i {
  margin-right: 10px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}
.sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  font-style: normal;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 14px;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
  font-size: 0.825rem;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a {
  &:before {
    content: "\f111";
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    font-style: normal;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
    font-size: 8px;
  }
  &:hover {
    cursor: pointer;
  }
}
.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
  float: right;
  margin-top: 0px;
}
.sidebar-wrapper .sidebar-menu .sidebar-submenu {
  display: none;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
  transform: rotate(90deg);
  right: 17px;
}
.btn-logout {
  margin-top: 0.75rem;
  background: #000;
  color: white;
}
// /*--------------------------side-footer------------------------------*/
.sidebar-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
}
.sidebar-footer > a {
  flex-grow: 1;
  text-align: center;
  height: 30px;
  line-height: 30px;
  position: relative;
  cursor: pointer;
}
.sidebar-footer > a .notification {
  position: absolute;
  top: 0;
}
.sidebar-wrapper {
  background: #1e1e2d;
}
.sidebar-wrapper .sidebar-header .user-info .user-role,
.sidebar-wrapper .sidebar-header .user-info .user-status,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-menu ul li a,
.sidebar-footer > a {
  color: #9499ac;
}

#close-sidebar {
  color: #bdbdbd;
}
#close-sidebar:hover {
  color: #ffffff;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown div {
  background: #1b1b28; // #2b2b42;
}

.sidebar-wrapper .sidebar-menu ul li a i {
  background: #2b2b42;
}
.sidebar-wrapper .sidebar-menu .header-menu span {
  color: #6c7b88;
}
.sidebar-footer {
  background: #1e1e2d;
  button {
    margin: 0.5rem auto;
    background: #2a2b3a;
    color: white;
    padding: 0.5rem 5rem;
    border-radius: 0.375rem;
    height: 44px;
    width: 256px;
    box-shadow: none;
    i {
      font-size: 1.25rem;
    }
  }
}
.sidebar-footer > a:first-child {
  border-left: none;
}
.sidebar-footer > a:last-child {
  border-right: none;
}
.sidebar-menu li[data-type="no-sub"].active {
  background: #1b1b28;
  a {
    color: #ffffff;
    i {
      background: #009ef7;
    }
  }
}

.sidebar-menu li.active {
  background: #1b1b28;
  a {
    color: white !important;
  }
  .sidebar-submenu {
    li {
      a {
        color: #9499ac !important;
      }
      &.active {
        a {
          color: white !important;
          &::before {
            color: #009ef7;
          }
        }
      }
    }
  }
}
</style>
