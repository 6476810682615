<template>
  <div>
    <spinner
      :propLoading="loading"
      :propShape="'tile'"
      v-if="loading"
    ></spinner>
    <layout-admin v-else :breadcrumbitems="breadcrumbItems">
      <user-index @initBreadcrumb="initBreadcrumb"></user-index>
    </layout-admin>
  </div>
</template>

<script>
import Spinner from "@/components/commons/spinners/Index";
import LayoutAdmin from "@/components/pages/layouts/Admin";
import UserIndex from "@/components/pages/user/Index";
import cryptoFunc from "@/utils/functions/crypto";
export default {
  name: "user",
  components: {
    LayoutAdmin,
    UserIndex,
    Spinner,
  },
  data() {
    return {
      breadcrumbItems: [],
      dataBreadcrumb: [],
      loading: true,
    };
  },
  watch: {
    loggedIn: {
      handler(val) {
        if (val) {
          this.loading = false;
        }
      },
      immediate: true,
    },
    lang(val) {
      this.breadcrumbItems = [];
      for (let i = 0; i < this.dataBreadcrumb.length; i++) {
        this.breadcrumbItems.push({
          label: this.$t(this.dataBreadcrumb[i]),
        });
      }
    },
  },
  created() {
    setTimeout(() => {
      if (!this.loggedIn) {
        if (this.$route.path != "/login") {
          this.$router.push({
            name: "Login",
            query: {
              next: btoa(cryptoFunc.encrypt(this.$route.path)),
            },
          });
        }
      }
    }, 1500);
  },
  methods: {
    initBreadcrumb(data) {
      this.breadcrumbItems = [];
      this.dataBreadcrumb = data;
      for (let i = 0; i < this.dataBreadcrumb.length; i++) {
        this.breadcrumbItems.push({
          label: this.$t(this.dataBreadcrumb[i]),
        });
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
