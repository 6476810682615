<template>
  <div class="Huieh238bBde">
    <div class="Khu783hgHmwv">
      <Criteria
        :prop-show="widgets.criteria.show"
        :propCriteria="propCriteria"
        :prop-provider="provider"
        :prop-processing-level="processingLevel"
        :prop-imaging-mode="imagingMode"
        :prop-direction="direction"
        :prop-image-types="imageTypes"
        :prop-pol-layer="polLayer"
        v-if="widgets.criteria.show2"
        @close="closeWidgetCriteria()"
        @getAllProvider="getAllProvider()"
        @getAllProcessingLevel="getAllProcessingLevel()"
        @getAllDirection="getAllDirection()"
        @getAllImageTypes="getAllImageTypes()"
        @getAllImagingMode="getAllImagingMode()"
        @getAllPolLayer="getAllPolLayer()"
      />
    </div>

    <div class="Khu783hgHmwv search-results">
      <div
        v-show="widgets.results.show"
        class="Hu38Hh3mD23 animate__animated"
        :class="
          widgets.results.show2 ? 'animate__fadeInLeft' : 'animate__fadeOutLeft'
        "
      >
        <h6 class="title">{{ $t("title_search_results") }}</h6>
        <button
          type="button"
          class="close"
          aria-label="Close"
          @click="closeWidgetResults"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <vue-tabs
          :value="tabIndex"
          :style="{ height: tabsHeight + 'px' }"
          @tab-change="handleTabChange"
        >
          <v-tab
            v-if="this.propCriteria.partners.includes('PLANET')"
            :icon="propData.planet.searching ? 'fas fa-spinner fa-spin' : ''"
            :title="
              'Planet' +
              (propData.planet.items && propData.planet.items.length
                ? ' (' + formatNumber(propData.planet.items.length) + ')'
                : '')
            "
          >
            <Planet
              :map="map"
              :propData="propData.planet"
              :propFootprintClick="propData.footprint.clicked"
              :propCarts="propCart.planet.items"
              :prop-show="widgets.planet.show"
              :propImageTypes="imageTypes.data"
              :propSearching="propData.planet.searching"
              ref="tabPlanet"
              @actionClick="actionClick"
              @close="closeWidgetResults()"
              @pagechanged="pagechanged"
              @message="onMessage"
              @sortChangeSatellite="sortChangeSatellite"
            />
          </v-tab>
          <v-tab
            v-if="this.propCriteria.partners.includes('ONEATLAS')"
            :icon="propData.oneAtlas.searching ? 'fas fa-spinner fa-spin' : ''"
            :title="
              'OneAtlas' +
              (propData.oneAtlas.items && propData.oneAtlas.items.length
                ? ' (' +
                  (propData.oneAtlas.value &&
                  propData.oneAtlas.value.pages &&
                  propData.oneAtlas.value.pages.totalRecords
                    ? formatNumber(propData.oneAtlas.value.pages.totalRecords)
                    : '') +
                  ')'
                : '')
            "
          >
            <OneAtlas
              :map="map"
              :propData="propData.oneAtlas"
              :propFootprintClick="propData.footprint.clicked"
              :propCarts="propCart.oneAtlas.items"
              :prop-show="widgets.oneAtlas.show"
              :propImageTypes="imageTypes.data"
              :propSearching="propData.oneAtlas.searching"
              ref="tabOneAtlas"
              @actionClick="actionClick"
              @close="closeWidgetResults()"
              @pagechanged="pagechanged"
              @message="onMessage"
              @sortChangeSatellite="sortChangeSatellite"
            />
          </v-tab>
          <v-tab
            v-if="this.propCriteria.partners.includes('CAPELLA')"
            :icon="propData.capella.searching ? 'fas fa-spinner fa-spin' : ''"
            :title="
              'Capella' +
              (propData.capella.items && propData.capella.items.length
                ? ' (' +
                  (propData.capella.value &&
                  propData.capella.value.pages &&
                  propData.capella.value.pages.totalRecords
                    ? formatNumber(propData.capella.value.pages.totalRecords)
                    : '') +
                  ')'
                : '')
            "
          >
            <Capella
              :map="map"
              :propData="propData.capella"
              :propFootprintClick="propData.footprint.clicked"
              :propCarts="propCart.capella.items"
              :prop-show="widgets.capella.show"
              :propImageTypes="imageTypes.data"
              :propSearching="propData.capella.searching"
              ref="tabCapella"
              @pagechanged="pagechanged"
              @actionClick="actionClick"
              @close="closeWidgetResults()"
              @message="onMessage"
            />
          </v-tab>
          <v-tab
            v-if="this.propCriteria.partners.includes('GEOHUB')"
            :icon="propData.satellite.searching ? 'fas fa-spinner fa-spin' : ''"
            :title="
              'GeoHub' +
              (propData.satellite.items && propData.satellite.items.length
                ? ' (' +
                  (propData.satellite.value &&
                  propData.satellite.value.total_records
                    ? formatNumber(propData.satellite.value.total_records)
                    : '') +
                  ')'
                : '')
            "
          >
            <Satellite
              :map="map"
              :propData="propData.satellite"
              :propFootprintClick="propData.footprint.clicked"
              :propCarts="propCart.satellite.items"
              :prop-show="widgets.satellite.show"
              :propImageTypes="imageTypes.data"
              :propSearching="propData.satellite.searching"
              ref="tabSatellite"
              @actionClick="actionClick"
              @close="closeWidgetResults()"
              @pagechanged="pagechanged"
              @message="onMessage"
              @sortChangeSatellite="sortChangeSatellite"
          /></v-tab>
        </vue-tabs>
      </div>
    </div>
    <div class="Khu783hgHmwv">
      <Cart
        :map="map"
        :propItems="propCart.satellite.items"
        :prop-show="widgets.cart.show"
        :prop-image-types="imageTypes.data"
        v-if="widgets.cart.show2"
        ref="tabSatelliteCart"
        @actionClick="actionClick"
        @close="closeWidgetSatelliteCart()"
        @onConfirmOrder="onConfirmOrder"
      />
    </div>
  </div>
</template>
<script>
import { VueTabs, VTab } from "vue-nav-tabs";
import { TileSpinner } from "vue-spinners";
import Criteria from "./leftside/Criteria.vue";
import Satellite from "./leftside/Satellite.vue";
import Planet from "./leftside/Planet.vue";
import OneAtlas from "./leftside/OneAtlas.vue";
import Capella from "./leftside/Capella.vue";
import Cart from "./leftside/Cart.vue";
import metaKeyFunc from "@/utils/functions/metakey";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import moment from "moment";
export default {
  components: {
    Criteria,
    Satellite,
    Planet,
    OneAtlas,
    Capella,
    Cart,
    VueTabs,
    VTab,
    TileSpinner,
  },
  props: {
    map: {
      required: true,
    },
    propData: {
      type: Object,
      default: () => {
        return {
          satellite: {
            searching: false,
            values: {},
          },
        };
      },
    },
    propCart: {
      type: Object,
      default: () => {
        return {
          satellite: {
            items: [],
          },
        };
      },
    },
    propCriteria: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tabIndex: 0,
      widgets: {
        criteria: {
          show: false,
          show2: false,
        },
        satellite: {
          show: false,
          show2: false,
        },
        planet: {
          show: false,
          show2: false,
        },
        oneAtlas: {
          show: false,
          show2: false,
        },
        capella: {
          show: false,
          show2: false,
        },
        cart: {
          show: false,
          show2: false,
        },
        results: {
          show: false,
          show2: false,
        },
      },
      provider: {
        items: [],
        tree: [],
      },
      processingLevel: {
        items: [],
        data: [],
      },
      imageTypes: {
        items: [],
        data: [],
      },
      imagingMode: {
        items: [],
        data: [],
      },
      polLayer: {
        items: [],
        data: [],
      },
      direction: {
        items: [],
        data: [],
      },
    };
  },
  created() {
    this.getAllProvider();
    this.getAllImageTypes();
  },
  watch: {
    lang: {
      handler(val) {
        this.getDirectionByLang();
        this.getImagingModeByLang();
        this.getPolLayerByLang();
        this.getProcessingLevelByLang();
        this.getImageTypesByLang();
      },
      immediate: true,
    },
  },
  computed: {
    accessToken() {
      return this.$store.getters.getAccessToken;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    callBackFuncs() {
      return this.$store.getters.getCallBackFuncs;
    },
    objsImage() {
      return this.$store.getters.getObjectsImage;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    tabsHeight() {
      return (
        this.documentHeight -
        8 -
        54 -
        42 -
        6 +
        (!this.isHeader ? 3.5 * 16 : 0) -
        48
      );
    },
    isHeader() {
      return this.$store.getters.getIsHeader;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    formatNumber(val) {
      if (val) return Number(val).toLocaleString("vi");
      else return "";
    },

    sortChangeSatellite() {
      this.$emit("sortChangeSatellite");
    },
    handleTabChange(tabIndex, newTab, oldTab) {
      this.tabIndex = tabIndex;
      switch (this.tabIndex) {
        case 0:
          this.setVisibleLayerImages(true);
          break;
        default:
          break;
      }
    },
    setVisibleLayerImages(val) {
      if (this.map && Object.keys(this.map).length > 0) {
        var layers = this.map.getLayers().array_;
        if (layers.length > 0) {
          for (let i = 0; i < layers.length; i++) {
            if (layers[i].get("id") === "layerdrawsatellite") {
              layers[i].setVisible(val);
            }
            if (layers[i].get("id") === "layerdrawimage") {
              layers[i].setVisible(val);
            }
            if ((layers[i].get("id") + "").startsWith("LAYER_IMAGE_")) {
              layers[i].setVisible(val);
            }
          }
        }
      }
    },
    clearLayer() {
      if (this.$refs.tabSatellite) {
        this.$refs.tabSatellite.clearLayer();
      }
      if (this.$refs.tabPlanet) {
        this.$refs.tabPlanet.clearLayer();
      }
      if (this.$refs.tabOneAtlas) {
        this.$refs.tabOneAtlas.clearLayer();
      }
      this.setVisibleLayerImages(this.tabIndex === 0 ? true : false);
    },
    async saveQuery() {
      let result = {};
      let searchDto = this.$store.getters.getSearchDto;
      for (const [key, value] of Object.entries(searchDto)) {
        if (value != null && value != undefined) {
          switch (key) {
            case "image_time_from":
              result["image_time_from"] = {
                value: value,
              };
              try {
                result["image_time_from"].text = this.formatUnix(
                  new Date(value).getTime() / 1000
                );
              } catch (error) {}
              break;
            case "image_time_to":
              result["image_time_to"] = {
                value: value,
              };
              try {
                result["image_time_to"].text = this.formatUnix(
                  new Date(value).getTime() / 1000
                );
              } catch (error) {}
              break;
            case "resolution_from":
              result["resolution"] = {};
              result["resolution"].value = [
                searchDto["resolution_from"],
                searchDto["resolution_to"],
              ];
              result["resolution"].text = [
                searchDto["resolution_from"],
                searchDto["resolution_to"],
              ].join("-");
              break;
            case "incidence_angle_from":
              result["incidence_angle"] = {};
              result["incidence_angle"].value = [
                searchDto["incidence_angle_from"],
                searchDto["incidence_angle_to"],
              ];
              result["incidence_angle"].text = [
                searchDto["incidence_angle_from"],
                searchDto["incidence_angle_to"],
              ].join("-");
              break;
            case "cloud_coverage_from":
              result["cloud_cove"] = {};
              result["cloud_cove"].value = [
                searchDto["cloud_coverage_from"],
                searchDto["cloud_coverage_to"],
              ];
              result["cloud_cove"].text = [
                searchDto["cloud_coverage_from"],
                searchDto["cloud_coverage_to"],
              ].join("-");
              break;
            case "boundary":
              result["boundary"] = {};
              result["boundary"].value = searchDto["boundary"];
              result["boundary"].text = searchDto["boundary"];
              break;
            case "processing_level":
              result["processing_level"] = {};
              result["processing_level"].value = searchDto["processing_level"];
              let item1 = this.processingLevel.items.find(
                (x) => searchDto["processing_level"] === x.id
              );
              result["processing_level"].text = item1 ? item1.value : null;
              break;
            case "brand":
              result["brand"] = {};
              result["brand"].value = searchDto["brand"];
              result["brand"].text = searchDto["brand"];
              break;
            case "category":
              result["category"] = {};
              result["category"].value = searchDto["brand"];
              result["category"].text = searchDto["brand"];
              break;
            case "imaging_mode":
              result["imaging_mode"] = {};
              result["imaging_mode"].value = searchDto["imaging_mode"];
              let array1 = this.imagingMode.items.filter((x) =>
                searchDto["imaging_mode"].includes(x.id)
              );
              result["imaging_mode"].text = array1.map((x) => x.value);
              break;
            case "pol_layer":
              result["pol_layer"] = {};
              result["pol_layer"].value = searchDto["pol_layer"];
              let array2 = this.polLayer.items.filter((x) =>
                searchDto["pol_layer"].includes(x.id)
              );
              result["pol_layer"].text = array2.map((x) => x.value);
              break;
            case "direction":
              result["direction"] = {};
              result["direction"].value = searchDto["direction"];
              let item2 = this.direction.items.find(
                (x) => searchDto["direction"] === x.id
              );
              result["direction"].text = item2 ? item2.value : null;
              break;
            case "satellites":
              result["satellites"] = {};
              result["satellites"].value = searchDto["satellites"];
              result["satellites"].text = null;
              let items = this.provider.items.filter((x) =>
                searchDto["satellites"].includes(x.id)
              );
              let satellites = [];
              if (items && items.length > 0) {
                for (let i = 0; i < items.length; i++) {
                  if (items[i].sub_meta_key) {
                    let items2 = items[i].sub_meta_key.filter((x) =>
                      searchDto["satellites"].includes(x.id)
                    );
                    if (items2 && items2.length > 0) {
                      satellites = [...satellites, ...items2];
                    }
                  }
                }
              }
              result["satellites"].text = satellites.map((x) => x.value);
              break;
            case "selectedKey":
              result["selectedKey"] = {};
              result["selectedKey"].value = searchDto["selectedKey"];
              break;
            case "boundary":
              result["boundary"] = {};
              try {
                //result["boundary"].value=  JSON.parse( searchDto["boundary"]);
                result["boundary"].value = searchDto["boundary"];
              } catch (error) {}
              result["boundary"].text = searchDto["boundary"];
              break;
            default:
              break;
          }
        }
      }
      var saveData = (function () {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        return function (data, fileName) {
          var json = JSON.stringify(data),
            blob = new Blob([json], { type: "octet/stream" }),
            //let blob = new Blob([data], { type: "text/plain" }),
            url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        };
      })();
      await saveData(
        result,
        "new_query_" +
          this.formatUnixDateTime(new Date().getTime() / 1000)
            .split("/")
            .join("_") +
          ".json"
      );
    },
    formatUnix(value) {
      if (value) {
        return moment.unix(value).format("DD/MM/YYYY");
      } else return "";
    },
    formatUnixDateTime(value) {
      if (value) {
        return moment.unix(value).format("DD/MM/YYYY/HH/mm");
      } else return "";
    },
    onMessage(data) {
      this.$emit("message", data);
    },
    pagechanged(data) {
      this.$emit("pagechanged", data);
    },
    onConfirmOrder() {
      this.$emit("onConfirmOrder");
    },
    closeWidgetCriteria() {
      this.widgets.criteria.show = false;
      setTimeout(() => {
        this.widgets.criteria.show2 = false;
      }, 500);
      this.$emit("closeWidget", "CRITERIA");
    },
    closeWidgetResults() {
      this.widgets.results.show = false;
      setTimeout(() => {
        this.widgets.results.show2 = false;
      }, 500);
      this.$emit("closeWidget", "SATELLITE");
    },
    closeWidgetSatelliteCart() {
      this.widgets.cart.show = false;
      setTimeout(() => {
        this.widgets.cart.show2 = false;
      }, 500);
      this.$emit("closeWidget", "CART");
    },
    addWidget(val) {
      switch (val.code) {
        case "CRITERIA":
          if (val.show) {
            this.widgets.criteria.show = true;
            this.widgets.criteria.show2 = true;
            this.closeWidgetResults();
            this.closeWidgetSatelliteCart();
          } else this.closeWidgetCriteria();
          break;
        case "SATELLITE":
          if (val.show) {
            this.widgets.results.show = true;
            this.widgets.results.show2 = true;
            this.closeWidgetCriteria();
            this.closeWidgetSatelliteCart();
          } else this.closeWidgetResults();

          break;

        case "CART":
          if (val.show) {
            this.widgets.cart.show = true;
            this.widgets.cart.show2 = true;
            this.closeWidgetResults();
            this.closeWidgetCriteria();
          } else this.closeWidgetSatelliteCart();
          break;
      }
    },
    actionClick(val) {
      this.$emit("actionClick", val);
    },
    /*---------------- Meta key ----------------*/
    getImageTypesByLang() {
      this.imageTypes.items = [];
      for (let i = 0; i < this.imageTypes.data.length; i++) {
        if (
          this.imageTypes.data[i].translation &&
          this.imageTypes.data[i].translation[this.lang] &&
          Object.keys(this.imageTypes.data[i].translation[this.lang]).length > 0
        ) {
          this.imageTypes.items.push({
            id: this.imageTypes.data[i].id,
            key: this.imageTypes.data[i].key,
            name: this.imageTypes.data[i].name,
            value: this.imageTypes.data[i].translation[this.lang].value
              ? this.imageTypes.data[i].translation[this.lang].value
              : this.imageTypes.data[i].value,
            description: this.imageTypes.data[i].translation[this.lang]
              .description
              ? this.imageTypes.data[i].translation[this.lang].description
              : this.imageTypes.data[i].description,
            sub_meta_key: this.imageTypes.data[i].sub_meta_key,
          });
        } else {
          this.imageTypes.items.push({
            id: this.imageTypes.data[i].id,
            key: this.imageTypes.data[i].key,
            name: this.imageTypes.data[i].name,
            value: this.imageTypes.data[i].value,
            description: this.imageTypes.data[i].description,
            sub_meta_key: this.imageTypes.data[i].sub_meta_key,
          });
        }
      }
    },
    getDirectionByLang() {
      this.direction.items = [];
      for (let i = 0; i < this.direction.data.length; i++) {
        if (
          this.direction.data[i].translation &&
          this.direction.data[i].translation[this.lang] &&
          Object.keys(this.direction.data[i].translation[this.lang]).length > 0
        ) {
          this.direction.items.push({
            id: this.direction.data[i].id,
            key: this.direction.data[i].key,
            name: this.direction.data[i].name,
            value: this.direction.data[i].translation[this.lang].value
              ? this.direction.data[i].translation[this.lang].value
              : this.direction.data[i].value,
            description: this.direction.data[i].translation[this.lang]
              .description
              ? this.direction.data[i].translation[this.lang].description
              : this.direction.data[i].description,
          });
        } else {
          this.direction.items.push({
            id: this.direction.data[i].id,
            key: this.direction.data[i].key,
            name: this.direction.data[i].name,
            value: this.direction.data[i].value,
            description: this.direction.data[i].description,
          });
        }
      }
    },
    getImagingModeByLang() {
      this.imagingMode.items = [];
      for (let i = 0; i < this.imagingMode.data.length; i++) {
        if (
          this.imagingMode.data[i].translation &&
          this.imagingMode.data[i].translation[this.lang] &&
          Object.keys(this.imagingMode.data[i].translation[this.lang]).length >
            0
        ) {
          this.imagingMode.items.push({
            id: this.imagingMode.data[i].id,
            key: this.imagingMode.data[i].key,
            name: this.imagingMode.data[i].name,
            value: this.imagingMode.data[i].translation[this.lang].value
              ? this.imagingMode.data[i].translation[this.lang].value
              : this.imagingMode.data[i].value,
            description: this.imagingMode.data[i].translation[this.lang]
              .description
              ? this.imagingMode.data[i].translation[this.lang].description
              : this.imagingMode.data[i].description,
          });
        } else {
          this.imagingMode.items.push({
            id: this.imagingMode.data[i].id,
            key: this.imagingMode.data[i].key,
            name: this.imagingMode.data[i].name,
            value: this.imagingMode.data[i].value,
            description: this.imagingMode.data[i].description,
          });
        }
      }
    },
    getPolLayerByLang() {
      this.polLayer.items = [];
      for (let i = 0; i < this.polLayer.data.length; i++) {
        if (
          this.polLayer.data[i].translation &&
          this.polLayer.data[i].translation[this.lang] &&
          Object.keys(this.polLayer.data[i].translation[this.lang]).length > 0
        ) {
          this.polLayer.items.push({
            id: this.polLayer.data[i].id,
            key: this.polLayer.data[i].key,
            name: this.polLayer.data[i].name,
            value: this.polLayer.data[i].translation[this.lang].value
              ? this.polLayer.data[i].translation[this.lang].value
              : this.polLayer.data[i].value,
            description: this.polLayer.data[i].translation[this.lang]
              .description
              ? this.polLayer.data[i].translation[this.lang].description
              : this.polLayer.data[i].description,
          });
        } else {
          this.polLayer.items.push({
            id: this.polLayer.data[i].id,
            key: this.polLayer.data[i].key,
            name: this.polLayer.data[i].name,
            value: this.polLayer.data[i].value,
            description: this.polLayer.data[i].description,
          });
        }
      }
    },
    getProcessingLevelByLang() {
      this.processingLevel.items = [];
      for (let i = 0; i < this.processingLevel.data.length; i++) {
        if (
          this.processingLevel.data[i].translation &&
          this.processingLevel.data[i].translation[this.lang] &&
          Object.keys(this.processingLevel.data[i].translation[this.lang])
            .length > 0
        ) {
          this.processingLevel.items.push({
            id: this.processingLevel.data[i].id,
            key: this.processingLevel.data[i].key,
            name: this.processingLevel.data[i].name,
            value: this.processingLevel.data[i].translation[this.lang].value
              ? this.processingLevel.data[i].translation[this.lang].value
              : this.processingLevel.data[i].value,
            description: this.processingLevel.data[i].translation[this.lang]
              .description
              ? this.processingLevel.data[i].translation[this.lang].description
              : this.processingLevel.data[i].description,
          });
        } else {
          this.processingLevel.items.push({
            id: this.processingLevel.data[i].id,
            key: this.processingLevel.data[i].key,
            name: this.processingLevel.data[i].name,
            value: this.processingLevel.data[i].value,
            description: this.processingLevel.data[i].description,
          });
        }
      }
    },
    /*---------------- End metakey ------------------*/
    async getAllImageTypes() {
      try {
        let response = await metaKeyFunc.getByKey(
          "IMAGE_TYPES",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.imageTypes.data =
            response && response.data && response.data.data
              ? response.data.data
              : [];
          this.getImageTypesByLang();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllImageTypes);
        }
      }
    },
    async getAllDirection() {
      try {
        let response = await metaKeyFunc.getByKey(
          "DIRECTION",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.direction.data =
            response && response.data && response.data.data
              ? response.data.data
              : [];
          this.getDirectionByLang();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllDirection);
        }
      }
    },
    async getAllImagingMode() {
      try {
        let response = await metaKeyFunc.getByKey(
          "BEAM_MODE",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.imagingMode.data =
            response && response.data && response.data.data
              ? response.data.data
              : [];

          this.getImagingModeByLang();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllImagingMode);
        }
      }
    },
    async getAllPolLayer() {
      try {
        let response = await metaKeyFunc.getByKey(
          "POL_LAYER",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.polLayer.data =
            response && response.data && response.data.data
              ? response.data.data
              : [];
          this.getPolLayerByLang();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllPolLayer);
        }
      }
    },

    async getAllProcessingLevel() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PROCESSING_LEVEL",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.processingLevel.data =
            response && response.data && response.data.data
              ? response.data.data
              : [];
          await this.getProcessingLevelByLang();
          this.$emit("getAllProcessingLevel", this.processingLevel.items);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllProcessingLevel);
        }
      }
    },
    async getAllProvider() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PROVIDER",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.provider.items =
            response && response.data && response.data.data
              ? response.data.data
              : [];
          this.$emit("getAllProvider", this.provider.items);
          if (this.provider.items && this.provider.items.length > 0) {
            for (let i = 0; i < this.provider.items.length; i++) {
              if (
                this.provider.items[i].sub_meta_key &&
                this.provider.items[i].sub_meta_key.length > 0
              ) {
                let children = [];
                for (
                  let j = 0;
                  j < this.provider.items[i].sub_meta_key.length;
                  j++
                ) {
                  children.push({
                    id: this.provider.items[i].sub_meta_key[j].id,
                    key: this.provider.items[i].sub_meta_key[j].id,
                    name: this.provider.items[i].sub_meta_key[j].name,
                    code: this.provider.items[i].sub_meta_key[j].name,
                    label: this.provider.items[i].sub_meta_key[j].value,
                  });
                }
                this.provider.tree.push({
                  id: this.provider.items[i].id,
                  key: this.provider.items[i].id,
                  name: this.provider.items[i].name,
                  code: this.provider.items[i].name,
                  label: this.provider.items[i].value,
                  children: children,
                });
              }
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllProvider);
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {});
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {});
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>
<style lang="scss" scoped>
.Huieh238bBde {
  position: relative;
  .Khu783hgHmwv {
    position: absolute;
    top: 0;
    left: 0;
    .Hu38Hh3mD23 {
      border-radius: 0.25rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      height: 100%;
      width: auto;
      background: white;
      border-color: #ddd;
    }
  }
}
.search-results {
  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 12px 16px;
    border-bottom: 1px solid #eff2f5;
    margin: 0;
  }
}
</style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
.Huieh238bBde {
  .Hu38Hh3mD23 {
    button.close {
      position: absolute;
      right: 1rem;
      top: 0.5rem;
    }
    .vue-tabs .nav-tabs > li.active > a,
    .vue-tabs .nav-tabs > li.active > a:hover,
    .vue-tabs .nav-tabs > li.active > a:focus {
      border-bottom: 0;
    }
    .vue-tabs {
      .nav-tabs {
        border: 0;
        padding: 10px 0;
        width: 100%;
        display: flex;
        justify-content: center;
        li.tab {
          background-color: #eef3f7;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
            a {
              border-top-left-radius: 0.5rem;
              border-bottom-left-radius: 0.5rem;
              &.tabs__link {
                .title {
                  flex-direction: row-reverse;
                }
              }
            }
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            a {
              border-top-right-radius: 0.5rem;
              border-bottom-right-radius: 0.5rem;
            }
          }
          &.active {
            background-color: #009ef7;
            a {
              color: white;
              border: 0;
              background-color: #009ef7;
            }
          }
          a {
            color: #009ef7;
            padding: 0.5rem 0.5rem;
            border: 0;
            .title {
              font-size: 14px;
              font-weight: 500;
              display: flex;
              align-items: center;
              text-align: center;
              flex-direction: row-reverse;
              column-gap: 2px;
            }
          }
        }
      }
    }
  }
}
</style>
