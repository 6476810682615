<template>
  <div class="statistic-report">
    <div class="statistic-report__dialog">
      <DialogMessage
        v-if="message.length > 0"
        :propTitle="$t('title_message_info')"
        :prop-show="message.length > 0"
        :prop-message="message"
        @close="message = []"
      />
    </div>
    <div class="statistic-report__filter">
      <StatisticFilter
        :prop-search="statistic.search"
        :prop-button="buttons.filter"
        @onSearch="onSearch"
      />
    </div>

    <div class="statistic-report__upload">
      <CommonAccordion :propTitle="'Upload'">
        <div slot="content" class="accordion">
          <StatisticUpload
            :propProvider="provider.items"
            :propStatisticUpload="statistic.data.most_upload"
          /></div
      ></CommonAccordion>
    </div>

    <div class="statistic-report__download">
      <CommonAccordion :propTitle="'Download'">
        <div slot="content" class="accordion">
          <StatisticDownload
            :propProvider="provider.items"
            :propStatisticDownload="statistic.data.most_download"
          /></div
      ></CommonAccordion>
    </div>
  </div>
</template>
<script>
import StatisticFilter from "./statisticreport/Filter";
import StatisticUpload from "./statisticreport/StatisticUpload";
import StatisticDownload from "./statisticreport/StatisticDownload";
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import metaKeyFunc from "@/utils/functions/metakey";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import meControllerFunc from "@/utils/functions/mecontroller";
export default {
  components: {
    StatisticFilter,
    CommonAccordion,
    StatisticDownload,
    StatisticUpload,
    DialogMessage,
  },
  data() {
    return {
      message: [],
      statistic: {
        loading: false,
        data: {},
        search: {
          from: null,
          to: null,
        },
      },
      provider: {
        items: [],
      },
      buttons: {
        filter: {
          disabled: false,
          processing: false,
          visible: true,
        },
      },
    };
  },
  async created() {
    setTimeout(() => {
      this.$emit("initBreadcrumb", ["menu_sidebar_statistic"]);
    }, 200);
    let today = new Date(),
      dateMinus7 = new Date();
    today.setHours(23);
    today.setMinutes(59);
    today.setSeconds(59);
    dateMinus7.setHours(0);
    dateMinus7.setMinutes(0);
    dateMinus7.setSeconds(0);
    this.statistic.search.to = today;
    this.statistic.search.from = new Date(
      dateMinus7.setDate(dateMinus7.getDate() - 7)
    );
    this.getAllProvider();
    await this.getStatistic();
  },
  methods: {
    onSearch(data) {
      this.statistic.search.from = data.from;
      this.statistic.search.to = data.to;
      this.getStatistic();
    },
    async getStatistic() {
      this.statistic.loading = true;
      this.buttons.filter.disabled = true;
      this.buttons.filter.processing = true;
      try {
        let response = await meControllerFunc.getStatistic(
          {
            from: this.statistic.search.from,
            to: this.statistic.search.to,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            this.statistic.data = response.data.data;
          }
        }
        this.statistic.loading = false;
        this.buttons.filter.disabled = false;
        this.buttons.filter.processing = false;
      } catch (error) {
        this.statistic.loading = false;
        this.buttons.filter.disabled = false;
        this.buttons.filter.processing = false;
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getStatistic);
      }
    },
    async getAllProvider() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PROVIDER",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.provider.items =
            response.data && response.data.data ? response.data.data : [];

          this.provider.items.sort(function (a, b) {
            if (a.name < b.name) return -1;
            else if (a.name > b.name) return 1;
            return 0;
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getAllProvider);
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>
<style lang="scss" scoped>
.statistic-report {
  display: flex;
  flex-direction: column;
  .statistic-report__filter {
    padding: 0.5rem;
    width: 100%;
  }
  .statistic-report__upload {
    padding: 0.5rem;
    padding-top: 0;
    width: 100%;
  }
  .statistic-report__download {
    padding: 0.5rem;
    padding-top: 0;
    width: 100%;
  }
  .statistic-report__order {
    padding: 0.5rem;
    padding-top: 0;
  }
}
</style>