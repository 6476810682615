<template>
  <div class="layers-gallery">
    <div class="layers-gallery__header">
      <h6 class="title">
        {{ $t("title_basemaps") }}
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          @click="closeBasemapsLayer"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </h6>
    </div>
    <ul class="list-group" :style="{ maxHeight: widgetHeight + 'px' }">
      <li
        class="list-group-item"
        v-for="(layer, index) in propData"
        :key="index"
      >
        <item-layer
          :propLayer="layer"
          @changeOpacity="changeOpacity($event)"
          @showLayer="showLayer($event)"
        ></item-layer>
      </li>
    </ul>
  </div>
</template>
<script>
import ItemLayer from "./basemapgallery/ItemLayer.vue";
export default {
  props: {
    propData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    ItemLayer,
  },
  data() {
    return {
      sliderValue: 100,
    };
  },
  created() {},
  methods: {
    closeBasemapsLayer() {
      this.$emit("closeBasemapsLayer");
    },
    changeOpacity: function (data) {
      this.$emit("changeOpacity", data);
    },

    showLayer: function (data) {
      this.$emit("showLayer", data);
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    widgetHeight() {
      return this.documentHeight + (!this.isHeader ? 3.5 * 16 : 0) - 200;
    },
    isHeader() {
      return this.$store.getters.getIsHeader;
    },
  },
};
</script>
<style lang="scss" scoped>
.layers-gallery {
  padding: 0.5rem;
  width: 24rem;
  .layers-gallery__header {
    .title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin: 0;
      padding: 0.75rem 1rem;
      padding-right: 0.5rem;
    }
  }
}
.list-group {
  overflow-y: auto;
}
.list-group-item {
  padding: 0.5rem 0.5rem 0.25rem 0.5rem;
}
</style>
