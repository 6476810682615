var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{style:({ zIndex: 1050 }),attrs:{"header":_vm.propTitle,"visible":_vm.show,"maximizable":_vm.propItem.quicklook ? true : false,"modal":true,"contentStyle":_vm.propStyle},on:{"update:visible":function($event){_vm.show=$event},"hide":_vm.onClose,"maximizedChage":_vm.maximizedChage},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Button',{class:'p-button-danger mr-0',attrs:{"label":_vm.$t('button_text_close'),"icon":'fas fa-times'},on:{"click":_vm.onClose}})]},proxy:true}])},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},[_c('tbody',[_c('tr',[_c('td',[_c('CommonAccordion',{attrs:{"propTitle":_vm.$t('label_footprint'),"isChevronEnd":true}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"HuLJs83D"},[_c('MapOpenlayer',{attrs:{"prop-wkt":_vm.propItem.wkt}})],1)])])],1),_c('td',[_c('CommonAccordion',{attrs:{"propTitle":_vm.$t('label_quicklook'),"isChevronEnd":true}},[_c('div',{staticClass:"quicklook",attrs:{"slot":"content"},slot:"content"},[(_vm.propItem.quicklook && _vm.propItem.icon_src)?_c('quick-look',{attrs:{"prop-data":{
                    img: _vm.propItem.icon_src,
                  }}}):_c('div',{staticClass:"quicklook-img"},[_c('img',{attrs:{"width":"100%","src":"/img/icons/no-image.png","title":"No Image"}})])],1)])],1)]),_c('tr',[_c('td',[_c('CommonAccordion',{attrs:{"propTitle":'Summary',"isChevronEnd":true}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"table-responsive",style:([
                    _vm.maximized
                      ? { width: (_vm.documentWidth - 68) / 2 + 'px' }
                      : {
                          width:
                            parseFloat(
                              _vm.propStyle.width.replace('px', '') - 68
                            ) /
                              2 +
                            'px',
                        } ])},[_c('table',{staticClass:"table"},[_c('tbody',_vm._l((_vm.summary),function(item,index){return _c('tr',{key:'summary' + index},[_c('td',[_vm._v(_vm._s(item.name)+":")]),_c('td',[_vm._v(_vm._s(item.value))])])}),0)])])])])],1),_c('td',[_c('CommonAccordion',{attrs:{"propTitle":'Product',"isChevronEnd":true}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"table-responsive",style:([
                    _vm.maximized
                      ? { width: (_vm.documentWidth - 68) / 2 + 'px' }
                      : {
                          maxWidth:
                            parseFloat(
                              _vm.propStyle.width.replace('px', '') - 68
                            ) /
                              2 +
                            'px',
                        } ])},[_c('table',{staticClass:"table"},[_c('tbody',_vm._l((_vm.summary),function(item,index){return _c('tr',{key:'summary' + index},[_c('td',[_vm._v(_vm._s(item.name)+":")]),_c('td',[_vm._v(_vm._s(item.value))])])}),0)])])])])],1)]),_c('tr',[_c('td',[_c('CommonAccordion',{attrs:{"propTitle":'Instrument',"isChevronEnd":true}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"table-responsive",style:([
                    _vm.maximized
                      ? { width: (_vm.documentWidth - 68) / 2 + 'px' }
                      : {
                          width:
                            parseFloat(
                              _vm.propStyle.width.replace('px', '') - 68
                            ) /
                              2 +
                            'px',
                        } ])},[_c('table',{staticClass:"table"},[_c('tbody',_vm._l((_vm.instrument),function(item,index){return _c('tr',{key:'instrument' + index},[_c('td',[_vm._v(_vm._s(item.name)+":")]),_c('td',[_vm._v(_vm._s(item.value))])])}),0)])])])])],1),_c('td',[_c('CommonAccordion',{attrs:{"propTitle":'Platform',"isChevronEnd":true}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"table-responsive",style:([
                    _vm.maximized
                      ? { width: (_vm.documentWidth - 68) / 2 + 'px' }
                      : {
                          maxWidth:
                            parseFloat(
                              _vm.propStyle.width.replace('px', '') - 68
                            ) /
                              2 +
                            'px',
                        } ])},[_c('table',{staticClass:"table"},[_c('tbody',_vm._l((_vm.platform),function(item,index){return _c('tr',{key:'platform' + index},[_c('td',[_vm._v(_vm._s(item.name)+":")]),_c('td',[_vm._v(_vm._s(item.value))])])}),0)])])])])],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }