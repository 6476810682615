<template>
  <Dialog
    :header="propTitle"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :maximizable="imgPreview.src ? true : false"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
    @maximizedChage="maximizedChage"
  >
    <div class="table-responsive">
      <table class="table">
        <tbody>
          <tr>
            <td
              align="left"
              width="50%"
              style="vertical-align: top"
              v-if="maximized"
            >
              <p>{{ $t("label_map") }}</p>
              <div class="map-preview">
                <MapOpenlayer
                  v-if="imgPreview.src"
                  :prop-src="imgPreview.src"
                  :propItem="propItem"
                />
              </div>
            </td>
            <td
              align="left"
              width="35%"
              style="vertical-align: top"
              v-if="!maximized"
            >
              <p>{{ $t("label_quicklook") }}</p>
              <div class="GP0VF02CF0">
                <div class="Iybw1Bhj3Jhd8s pt-1">
                  <tile
                    v-if="imgPreview.loading"
                    :loading="imgPreview.loading"
                    :height="'40'"
                    :width="'40'"
                    :color="'fd853e'"
                    class="ehAbnmg4Jwb9BS"
                  ></tile>
                  <div v-else>
                    <quick-look
                      v-if="imgPreview.src"
                      :prop-data="{
                        img: imgPreview.src,
                      }"
                    ></quick-look>
                    <img
                      width="100%"
                      v-else
                      src="/img/icons/no-image.png"
                      title="No Image"
                    />
                  </div>
                </div>
              </div>
            </td>
            <td
              align="left"
              style="vertical-align: top"
              :style="[maximized ? { width: '50%' } : { width: '65%' }]"
            >
              <p>{{ $t("label_values") }}</p>
              <div class="table-responsive">
                <table
                  class="table-metadata w-100"
                  id="tableMetadata"
                  cellspacing="0"
                  cellpadding="0"
                >
                  <colgroup>
                    <col />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <td style="width: 150px">{{ $t("label_image_name") }}</td>
                      <td>
                        <div
                          :title="
                            propItem.id && propItem.id.length > 54
                              ? propItem.id
                              : ''
                          "
                        >
                          {{
                            propItem.id && propItem.id.length > 54
                              ? propItem.id.substring(0, 54) + "..."
                              : propItem.id
                          }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Start</td>
                      <td>
                        <div>
                          {{
                            propItem.properties &&
                            propItem.properties.start_datetime
                              ? propItem.properties.start_datetime
                              : ""
                          }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Geometry</td>
                      <td>
                        {{
                          propItem &&
                          propItem.geometry &&
                          propItem.geometry.coordinates &&
                          propItem.geometry.coordinates.length > 0
                            ? propItem.geometry.coordinates[0]
                            : null
                        }}
                      </td>
                    </tr>
                    <tr v-for="item in arrayFieldtypeObject" :key="item.id">
                      <td>{{ convertLabel(item.label) }}</td>
                      <td>{{ item.value }}</td>
                    </tr>
                    <tr v-show="maximized">
                      <td>{{ $t("label_quicklook") }}</td>
                      <td>
                        <img
                          class="gwt-Image GP0VF02CE0"
                          style="width: 100%"
                          :src="imgPreview.src"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <template #footer>
      <Button
        :label="$t('button_text_close')"
        :icon="'fas fa-times'"
        :class="'p-button-danger mr-0'"
        @click="onClose"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import MapOpenlayer from "./metadatacapella/Map";
import QuickLook from "./metadatacapella/QuickLook";
import { TileSpinner } from "vue-spinners";
import moment from "moment";
export default {
  components: {
    Dialog,
    Button,
    MapOpenlayer,
    QuickLook,
    Tile: TileSpinner,
  },
  props: {
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "480px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      fieldsIgnore: [],
      arrayFieldtypeObject: [],
      maximized: false,
      imgPreview: {
        loading: true,
        src: null,
      },
    };
  },
  created() {
    this.show = this.propShow;
    this.imgPreview.src = this.propItem.image_src;
    this.imgPreview.loading = false;
    if (
      this.propItem &&
      this.propItem.properties &&
      Object.keys(this.propItem.properties).length > 0
    ) {
      for (const [key, value] of Object.entries(this.propItem.properties)) {
        if (
          [
            "id",
            "acquired",
            "view_angle",
            "pixel_resolution",
            "cloud_percent",
          ].includes(key)
        )
          continue;
        this.arrayFieldtypeObject.push({
          key: key,
          label: key.split("_").join(" "),
          value: value,
          id: key,
        });
      }
    }
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
  },
  methods: {
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    maximizedChage(val) {
      this.maximized = val;
    },
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
    findIndexUnderlineCharacters(str) {
      var positions = [];
      for (var i = 0; i < str.length; i++) {
        if (str[i].match("_") != null) {
          positions.push(i);
        }
      }
      return positions;
    },
    convertLabel(str) {
      if (!str || !str.trim()) return null;
      let positions = this.findIndexUnderlineCharacters(str);
      let increment = 1;
      for (let i = 0; i < positions.length; i++) {
        str = [
          str.slice(str, positions[i] + increment),
          " ",
          str.slice(positions[i] + increment),
        ].join("");
        increment++;
      }
      return this.capitalizeFirstLetter(str);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.Iybw1Bhj3Jhd8s {
  position: relative;
  width: 100%;
  height: 100%;
  .ehAbnmg4Jwb9BS {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 3;
  }
}
table {
  margin-bottom: 0;
  td {
    border-top: none;
    p {
      background: #a1a5b7;
      font-size: 14px;
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      color: white;
      border-radius: 0.25rem;
    }
    .map-preview {
      position: relative;
    }
  }
}
table.table-metadata {
  border-collapse: separate;
  border-spacing: 0 0.125rem;
  tr {
    margin-bottom: 0.25rem;
    td {
      padding: 0.5rem 0.75rem;
      border: 1px solid white;
      &:first-child {
        background: $background-color-2;
        color: white;
        border-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        &.no-background {
          background-color: transparent;
          color: #212529;
        }
      }
    }
  }
}
</style>
