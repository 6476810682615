<template>
  <Dialog
    :header="title"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :maximizable="true"
    :modal="true"
    :contentStyle="{
      width: width + 'px',
      overflowY: !isOverflowScroll ? 'inherit!important' : 'auto',
    }"
    @hide="onClose"
    @maximizedChage="maximizedChage"
  >
    <div
      id="Ku7BNAmfHj2F"
      class="tasking-components"
      :style="{ minHeight: contentMinHeight + 'px' }"
    >
      <div v-if="step === 1">
        <TaskingCoordinates :propEntity="entity" />
      </div>
      <div v-else-if="step === 7">
        <TaskingConfirmation
          :propEntity="entity"
          :propProgramingServices="programingService.items"
          :propGranularities="granularity.items"
          :propCountry="country.items"
          :propProvinces="province.items"
          :propLookDirection="lookDirection.items"
          :propOrbitDirection="orbitDirection.items"
          @getAllCountry="getAllCountry"
          @getStateByCountryId="getStateByCountryId"
        />
      </div>
      <div v-else>
        <StepProgress
          :steps="steps"
          :current-step="step - 2"
          :activeColor="'#009EF7'"
          :activeTextColor="'#1E1E2D'"
          :passiveColor="'#B3C3D4'"
          :iconClass="'fas fa-check'"
        />
        <div>
          <div class="" v-if="step === 2">
            <AreaOfInterest
              :propEntity="entity"
              :prop-providers="provider.items"
              @getAllProvider="getAllProvider"
            />
          </div>
          <div class="" v-else-if="step === 3">
            <TaskingTime
              :propEntity="entity"
              :propOrderTypes="orderType.items"
              :propLookDirection="lookDirection.items"
              :propOrbitDirection="orbitDirection.items"
              @getAllTaskingOrderType="getAllTaskingOrderType"
              @getAllDirection="getAllDirection"
            />
          </div>
          <div class="" v-else-if="step === 4">
            <SensorMode
              :propEntity="entity"
              :propBeamMode="beamMode.items"
              :propGranularities="granularity.items"
              :propPolLayer="polLayer.items"
              @getAllTaskingGranularity="getAllTaskingGranularity"
              @getAllTaskingBeamMode="getAllTaskingBeamMode"
              @setPolLayer="polLayer.items = $event"
            />
          </div>
          <div class="" v-else-if="step === 5">
            <ProgrammingService
              :propEntity="entity"
              :propProgrammingService="programingService.items"
              @getAllTaskingProgrammingService="getAllTaskingProgrammingService"
            />
          </div>
          <div class="" v-else-if="step === 6">
            <ProductionParameters
              :propEntity="entity"
              :propProducts="products.items"
              :propBands="bands.items"
              :propLicenses="licenses.items"
              :propBitDepths="bitDepths.items"
              :propPixelResolutions="pixelResolutions.items"
              :propDRAContrasts="draContrasts.items"
              :propResamplingKernels="resamplingKernels.items"
              :propOrthoOptions="orthoOptions.items"
              :propFileFormats="fileFormatsOptical.items"
              :propTilings="tiling.items"
              :propDeliveriesMedia="deliveriesMedia.items"
              :propProductLevels="productLevels.items"
              :propDeliveriesService="deliveriesService.items"
              :propFileFormatsRadar="fileFormatsRadar.items"
              @getAllTaskingProduct="getAllTaskingProduct"
              @getAllTaskingBands="getAllTaskingBands"
              @getAllTaskingLicense="getAllTaskingLicense"
              @getAllTaskingBitDepth="getAllTaskingBitDepth"
              @getAllTaskingPixelResolution="getAllTaskingPixelResolution"
              @getAllTaskingDRAContrast="getAllTaskingDRAContrast"
              @getAllTaskingResamplingKernel="getAllTaskingResamplingKernel"
              @getAllTaskingOrthoOption="getAllTaskingOrthoOption"
              @getAllTaskingFileFormat="getAllTaskingFileFormat"
              @getAllTaskingTiling="getAllTaskingTiling"
              @getAllTaskingDeliveryMedia="getAllTaskingDeliveryMedia"
              @getAllTaskingFileFormatsRadar="getAllTaskingFileFormatsRadar"
              @getAllTaskingProductLevel="getAllTaskingProductLevel"
              @getAllTaskingDeliveryService="getAllTaskingDeliveryService"
            />
          </div>
        </div>
      </div>
    </div>
    <template #footer
      ><Button
        v-if="buttons.prev.visible"
        :label="buttons.prev.text"
        :icon="buttons.prev.icon"
        class="p-button-sm float-left"
        :class="buttons.prev.class"
        @click="onPrev"
      />
      <Button
        v-if="buttons.no.visible"
        :label="buttons.no.text"
        :icon="buttons.no.icon"
        :class="buttons.no.class"
        @click="onClose"
      />
      <Button
        v-if="buttons.yes.visible"
        :label="buttons.yes.text"
        :icon="buttons.yes.icon"
        :class="buttons.yes.class"
        class="mr-0"
        @click="onConfirm"
        :disabled="buttons.yes.disable"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import TaskingCoordinates from "./components/Coordinates";
import Steps from "@/components/commons/steps/Steps";
import StepProgress from "@/components/commons/steps/StepProgress";
import AreaOfInterest from "./components/AreaOfInterest";
import TaskingTime from "./components/TaskingTime";
import SensorMode from "./components/SensorMode";
import ProgrammingService from "./components/ProgrammingService";
import ProductionParameters from "./components/ProductionParameters";
import TaskingConfirmation from "./components/Confirmation";
import Feature from "ol/Feature";
import { fromLonLat, transform } from "ol/proj";
import Polygon from "ol/geom/Polygon";
import Circle from "ol/geom/Circle";
import { getCenter, getTopLeft } from "ol/extent";
import turf from "turf";
import metaKeyFunc from "@/utils/functions/metakey";
import miscFunc from "@/utils/functions/misc";
import orderFunc from "@/utils/functions/order";
import { makeid } from "@/utils/commons/common";
export default {
  components: {
    Dialog,
    Button,
    TaskingCoordinates,
    Steps,
    StepProgress,
    AreaOfInterest,
    TaskingTime,
    SensorMode,
    ProgrammingService,
    ProductionParameters,
    TaskingConfirmation,
  },
  props: {
    propShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      maximized: false,
      step: 1,
      isOverflowScroll: true,
      componentActive: null,
      steps: [
        "Area of interest",
        "Tasking",
        "Sensor mode & polatization",
        "Programming service",
        "Production parameters",
      ],
      stepsCode: [
        "label_step_area_of_interest",
        "label_step_tasking",
        "label_step_sensor_mode_and_polatization",
        "label_step_programming_service",
        "label_step_production_parameters",
      ],
      buttons: {
        yes: {
          visible: true,
          text: "Yes",
          icon: "",
          class: "",
          disable: false,
          processing: false,
        },
        no: {
          visible: true,
          text: "No",
          icon: "",
          class: "",
        },
        prev: {
          visible: false,
          text: this.$t("button_text_prev"),
          icon: "",
          class: "btn-prev",
        },
      },
      entity: {
        code: null,
        created_at: new Date(),
        area_name: null,
        number_of_scenes: 1,
        satellite: null,
        satellite_name: null,
        satellite_type: null,
        coordinates_form: "polygon",
        coordinates: [
          {
            lat: null,
            lng: null,
          },
          {
            lat: null,
            lng: null,
          },
          {
            lat: null,
            lng: null,
          },
          {
            lat: null,
            lng: null,
          },
        ],
        centre: {
          lat: null,
          lng: null,
          radius: null,
          feature: null,
        },
        feature: null,
        file_name: null,

        buyer_name: null,
        buyer_organization: null,
        buyer_address: null,
        buyer_zip_code: null,
        buyer_province_obj: null,
        buyer_province: null,
        buyer_province_id: null,
        buyer_country_obj: null,
        buyer_country: null,
        buyer_country_id: null,
        buyer_phone_number: null,
        buyer_email: null,
        programming_service: null,
        programming_service_name: null,
        note: null,
        radar: {
          earliest: null,
          earliest_hh: null,
          earliest_mm: null,
          latest: null,
          latest_hh: null,
          latest_mm: null,
          minimum: null,
          maximum: null,
          look_direction: null,
          look_direction_name: null,
          orbit_direction: null,
          orbit_direction_name: null,
          beam_mode: null,
          beam_mode_name: null,
          pol_layer: null,
          pol_layer_name: null,
          number_of_iterations: null,
          granularity: null,
          granularity_name: null,
          product_level: null,
          product_level_name: null,
          file_format: null,
          file_format_name: null,
          delivery_media: null,
          delivery_media_name: null,
          delivery_service: null,
          delivery_service_name: null,
          license: null,
          license_name: null,
          note: null,
        },
        optical: {
          start: null,
          start_str: null,
          end: null,
          end_str: null,
          order_type: null,
          order_type_name: null,
          inc_angle_from: null,
          inc_angle_to: null,
          cloud_coverage_from: null,
          cloud_coverage_to: null,

          product: null,
          product_name: null,
          resampling_kernel: null,
          resampling_kernel_name: null,
          bands: null,
          bands_name: null,
          license: null,
          license_name: null,
          bit_depth: null,
          bit_depth_name: null,
          ortho_option: null,
          ortho_option_name: null,
          pixel_resolution: null,
          pixel_resolution_name: null,
          file_format: null,
          file_format_name: null,
          dra_contrast: null,
          dra_contrast_name: null,
          tiling: null,
          tiling_name: null,
          delivery_media: null,
          delivery_media_name: null,
          note: null,
        },
      },
      // data combobox
      provider: {
        items: [],
      },
      satelliteType: {
        items: [],
      },
      orderType: { items: [], data: [] },
      beamMode: {
        items: [],
        data: [],
      },
      polLayer: {
        items: [],
        data: [],
      },
      granularity: {
        items: [],
        data: [],
      },
      programingService: {
        items: [],
        data: [],
      },

      products: {
        items: [],
        data: [],
      },
      bands: {
        items: [],
        data: [],
      },
      licenses: {
        items: [],
        data: [],
      },
      bitDepths: {
        items: [],
        data: [],
      },
      pixelResolutions: {
        items: [],
        data: [],
      },
      draContrasts: {
        items: [],
        data: [],
      },
      resamplingKernels: {
        items: [],
        data: [],
      },
      orthoOptions: {
        items: [],
        data: [],
      },
      fileFormatsOptical: {
        items: [],
        data: [],
      },
      tiling: {
        items: [],
        data: [],
      },
      deliveriesMedia: {
        items: [],
        data: [],
      },

      productLevels: {
        items: [],
        data: [],
      },
      fileFormatsRadar: {
        items: [],
        data: [],
      },
      deliveriesService: {
        items: [],
        data: [],
      },
      country: {
        items: [],
      },
      province: {
        items: [],
      },
      lookDirection: {
        items: [],
        data: [],
      },
      orbitDirection: {
        items: [],
        data: [],
      },
    };
  },
  created() {
    this.steps = [];
    for (let i = 0; i < this.stepsCode.length; i++) {
      this.steps.push(this.$t(this.stepsCode[i]));
    }
    this.show = this.propShow;
    this.entity.code = null;
    this.entity.created_at = new Date();

    this.componentActive = "TaskingCoordinates";
    let coors = [],
      pointsTest = [],
      points = [];
    if (this.coordinates && this.coordinates.length > 0) {
      for (let i = 0; i < this.coordinates.length; i++) {
        if (i === 4) break;
        coors.push(this.coordinates[i]);
        pointsTest.push(
          transform(
            [this.coordinates[i].lng, this.coordinates[i].lat],
            "EPSG:4326",
            "EPSG:3857"
          )
        );
      }
      let featureTest = new Feature({
        geometry: new Polygon([pointsTest]),
      });
      var centerTest = getCenter(featureTest.getGeometry().getExtent());

      centerTest = transform(centerTest, "EPSG:3857", "EPSG:4326");
      let upperPoints = coors.filter((x) => x.lat > centerTest[1]);
      let lowerPoints = coors.filter((x) => x.lat < centerTest[1]);
      if (upperPoints.length > 0) {
        let indexTopLeft = 0,
          valueTopLeft = upperPoints[0].lng;
        for (let i = 0; i < upperPoints.length; i++) {
          if (upperPoints[i].lng < valueTopLeft) {
            valueTopLeft = upperPoints[i].lng;
            indexTopLeft = i;
          }
        }
        if (indexTopLeft >= 0) {
          this.entity.coordinates[0] = upperPoints[indexTopLeft];
          points.push(
            transform(
              [upperPoints[indexTopLeft].lng, upperPoints[indexTopLeft].lat],
              "EPSG:4326",
              "EPSG:3857"
            )
          );
        }

        let indexTopRight = 0,
          valueTopRight = upperPoints[0].lng;
        for (let i = 0; i < upperPoints.length; i++) {
          if (upperPoints[i].lng > valueTopRight) {
            valueTopRight = upperPoints[i].lng;
            indexTopRight = i;
          }
        }
        if (indexTopRight >= 0) {
          this.entity.coordinates[1] = upperPoints[indexTopRight];
          points.push(
            transform(
              [upperPoints[indexTopRight].lng, upperPoints[indexTopRight].lat],
              "EPSG:4326",
              "EPSG:3857"
            )
          );
        }
      }
      if (lowerPoints.length > 0) {
        let indexBottomRight = 0,
          valueBottomRight = lowerPoints[0].lng;
        for (let i = 0; i < lowerPoints.length; i++) {
          if (lowerPoints[i].lng > valueBottomRight) {
            valueBottomRight = lowerPoints[i].lng;
            indexBottomRight = i;
          }
        }

        if (indexBottomRight >= 0) {
          this.entity.coordinates[2] = lowerPoints[indexBottomRight];
          points.push(
            transform(
              [
                lowerPoints[indexBottomRight].lng,
                lowerPoints[indexBottomRight].lat,
              ],
              "EPSG:4326",
              "EPSG:3857"
            )
          );
        }

        let indexBottomLeft = 0,
          valueBottomLeft = lowerPoints[0].lng;
        for (let i = 0; i < lowerPoints.length; i++) {
          if (lowerPoints[i].lng < valueBottomLeft) {
            valueBottomLeft = lowerPoints[i].lng;
            indexBottomLeft = i;
          }
        }
        if (indexBottomLeft >= 0) {
          this.entity.coordinates[3] = lowerPoints[indexBottomLeft];
          points.push(
            transform(
              [
                lowerPoints[indexBottomLeft].lng,
                lowerPoints[indexBottomLeft].lat,
              ],
              "EPSG:4326",
              "EPSG:3857"
            )
          );
        }
      }

      // let upperLeft = coors.find(
      //   (x) => x.lng < centerTest[0] && x.lat > centerTest[1]
      // );
      // this.entity.coordinates[0] = upperLeft;
      // points.push(
      //   transform([upperLeft.lng, upperLeft.lat], "EPSG:4326", "EPSG:3857")
      // );
      // let upperRight = coors.find(
      //   (x) => x.lng > centerTest[0] && x.lat > centerTest[1]
      // );
      // points.push(
      //   transform([upperRight.lng, upperRight.lat], "EPSG:4326", "EPSG:3857")
      // );
      // this.entity.coordinates[1] = upperRight;
      // let lowerLeft = coors.find(
      //   (x) => x.lng < centerTest[0] && x.lat < centerTest[1]
      // );
      // let lowerRight = coors.find(
      //   (x) => x.lng > centerTest[0] && x.lat < centerTest[1]
      // );
      // points.push(
      //   transform([lowerRight.lng, lowerRight.lat], "EPSG:4326", "EPSG:3857")
      // );
      // this.entity.coordinates[2] = lowerRight;
      // this.entity.coordinates[3] = lowerLeft;

      // points.push(
      //   transform([lowerLeft.lng, lowerLeft.lat], "EPSG:4326", "EPSG:3857")
      // );
      if (points.length >= 4) {
        let feature = new Feature({
          geometry: new Polygon([points]),
        });
        feature.setId("featuredrawtasking");
        this.entity.feature = feature;
        var ext = feature.getGeometry().getExtent();
        var center = getCenter(ext);
        if (center && Object.keys(center).length > 0) {
          center = transform(center, "EPSG:3857", "EPSG:4326");
          this.entity.centre.lat = parseFloat(center[1].toFixed(5));
          this.entity.centre.lng = parseFloat(center[0].toFixed(5));

          var topLeft = getTopLeft(ext);
          if (topLeft && Object.keys(topLeft).length > 0) {
            topLeft = transform(topLeft, "EPSG:3857", "EPSG:4326");

            var distance = turf.distance(
              turf.point(center),
              turf.point(topLeft),
              null
            );

            this.entity.centre.radius = parseFloat(distance.toFixed(2));
          }
        }
      }
    }
    this.calcButtons();
  },
  watch: {
    propShow(val) {
      this.show = val;
    },
    lang(val) {
      this.getSatelliteTypeByLang();
      this.getTaskingOrderTypeByLang();
      this.getLookDirectionByLang();
      this.getOrBitDirectionByLang();
      this.getTaskingProductByLang();
      this.getTaskingBandsByLang();
      this.getTaskingLicenseByLang();
      this.getTaskingBitDepthByLang();
      this.getTaskingPixelResolutionByLang();
      this.getTaskingDRAContrastByLang();
      this.getTaskingResamplingKernelByLang();
      this.getTaskingOrthoOptionByLang();
      this.getTaskingFileFormatByLang();
      this.getTaskingTilingByLang();
      this.getTaskingDeliveryMediaByLang();
      this.getTaskingFileFormatsRadarByLang();
      this.getTaskingProductLevelByLang();
      this.getTaskingDeliveryServiceByLang();
      this.getTaskingGranularityByLang();
      this.getTaskingBeamModeByLang();
      this.getTaskingProgrammingServiceByLang();
    },
  },
  methods: {
    async createOrder() {
      let satelliteId = this.entity.satellite ? this.entity.satellite : null;
      if (satelliteId.indexOf("__") >= 0)
        satelliteId = satelliteId.substring(
          satelliteId,
          satelliteId.indexOf("__")
        );
      try {
        let dto = {
          address: this.entity.buyer_address
            ? this.entity.buyer_address
                .trim()
                .replace(/\+/g, " ")
                .replace(/\s\s+/g, " ")
            : null,
          country: this.entity.buyer_country ? this.entity.buyer_country : null,
          email: this.entity.buyer_email
            ? this.entity.buyer_email
                .trim()
                .replace(/\+/g, " ")
                .replace(/\s\s+/g, " ")
            : null,
          full_name: this.entity.buyer_name
            ? this.entity.buyer_name
                .trim()
                .replace(/\+/g, " ")
                .replace(/\s\s+/g, " ")
            : null,
          image_ids: [],
          is_order_request: true,
          note: this.entity.note
            ? this.entity.note.replace(/\+/g, " ").replace(/\s\s+/g, " ")
            : null,
          order_request: {
            band_id: this.entity.optical.bands
              ? this.entity.optical.bands
              : null,
            bit_depth_id: this.entity.optical.bit_depth
              ? this.entity.optical.bit_depth
              : null,
            bounding_box: [{}],
            centre_point: [this.entity.centre.lng, this.entity.centre.lat],
            cloud_coverage: 0,
            coordinate_system: null,
            date_from: null,
            date_to: null,
            delivery_media_id: null,
            delivery_service_id: this.entity.radar.delivery_service
              ? this.entity.radar.delivery_service
              : null,
            dra_contrast_enhancement_id: this.entity.optical.dra_contrast
              ? this.entity.optical.dra_contrast
              : null,
            file_format_Id: null,
            granularity_id: this.entity.radar.granularity
              ? this.entity.radar.granularity
              : null,
            incidence_angle_max: 0,
            incidence_angle_min: 0,
            iterations: this.entity.radar.number_of_iterations
              ? this.entity.radar.number_of_iterations
              : null,
            license_id: null,
            location_name: this.entity.area_name
              ? this.entity.area_name
                  .trim()
                  .replace(/\+/g, " ")
                  .replace(/\s\s+/g, " ")
              : null,
            ortho_options_id: this.entity.optical.ortho_option
              ? this.entity.optical.ortho_option
              : null,
            pixel_resolution_id: this.entity.optical.pixel_resolution
              ? this.entity.optical.pixel_resolution
              : null,
            polarization_id: this.entity.radar.pol_layer
              ? this.entity.radar.pol_layer
              : null,
            processing_level_id: null,
            radius: 0,
            resampling_kernel_id: this.entity.optical.resampling_kernel
              ? this.entity.optical.resampling_kernel
              : null,
            satellite_id: satelliteId,
            scene_number: this.entity.number_of_scenes
              ? this.entity.number_of_scenes
              : null,
            sensor_mode_id: this.entity.radar.beam_mode
              ? this.entity.radar.beam_mode
              : null,
            tiling_id: this.entity.optical.tiling
              ? this.entity.optical.tiling
              : null,
          },
          organization: this.entity.buyer_organization
            ? this.entity.buyer_organization
                .trim()
                .replace(/\+/g, " ")
                .replace(/\s\s+/g, " ")
            : null,
          phone_number: this.entity.buyer_phone_number
            ? this.entity.buyer_phone_number
                .trim()
                .replace(/\+/g, " ")
                .replace(/\s\s+/g, " ")
            : null,
          priority_id: this.entity.programming_service
            ? this.entity.programming_service
            : null,
          state: this.entity.buyer_province ? this.entity.buyer_province : null,
          zipcode: this.entity.buyer_zip_code
            ? this.entity.buyer_zip_code
                .trim()
                .replace(/\+/g, " ")
                .replace(/\s\s+/g, " ")
            : null,
        };
        if (
          this.entity.satellite_type &&
          this.entity.satellite_type.trim().toLowerCase() === "radar"
        ) {
          dto.order_request.look_id = this.entity.radar.look_direction
            ? this.entity.radar.look_direction
            : null;
          dto.order_request.orbit_id = this.entity.radar.orbit_direction
            ? this.entity.radar.orbit_direction
            : null;
          dto.order_request.processing_level_id = this.entity.radar
            .product_level
            ? this.entity.radar.product_level
            : null;
          dto.order_request.license_id = this.entity.radar.license
            ? this.entity.radar.license
            : null;

          dto.order_request.file_format_Id = this.entity.radar.file_format
            ? this.entity.radar.file_format
            : null;
          dto.order_request.delivery_media_id = this.entity.radar.delivery_media
            ? this.entity.radar.delivery_media
            : null;

          let start = new Date(this.entity.radar.earliest);
          let end = new Date(this.entity.radar.latest);
          let earliest_hh = parseInt(this.entity.radar.earliest_hh);
          let earliest_mm = parseInt(this.entity.radar.earliest_mm);
          dto.order_request.date_from = start.setHours(
            earliest_hh,
            earliest_mm,
            0,
            0
          );

          let latest_hh = parseInt(this.entity.radar.latest_hh);
          let latest_mm = parseInt(this.entity.radar.latest_mm);
          dto.order_request.date_to = end.setHours(latest_hh, latest_mm, 0, 0);

          dto.order_request.incidence_angle_max = this.entity.radar.minimum;
          dto.order_request.incidence_angle_min = this.entity.radar.maximum;
        } else if (
          this.entity.satellite_type &&
          this.entity.satellite_type.trim().toLowerCase() === "optical"
        ) {
          dto.order_request.cloud_coverage =
            this.entity.optical.cloud_coverage_to;
          dto.order_request.incidence_angle_max =
            this.entity.optical.inc_angle_to;
          dto.order_request.incidence_angle_min =
            this.entity.optical.inc_angle_from;
          dto.order_request.processing_level_id = this.entity.optical.product
            ? this.entity.optical.product
            : null;
          dto.order_request.collection_order_type_id = this.entity.optical
            .order_type
            ? this.entity.optical.order_type
            : null;
          dto.order_request.license_id = this.entity.optical.license
            ? this.entity.optical.license
            : null;
          dto.order_request.file_format_Id = this.entity.optical.file_format
            ? this.entity.optical.file_format
            : null;

          dto.order_request.delivery_media_id = this.entity.optical
            .delivery_media
            ? this.entity.optical.delivery_media
            : null;
          let start = new Date(this.entity.optical.start);
          let end = new Date(this.entity.optical.end);

          dto.order_request.date_from = start.setHours(0, 0, 0, 0);
          dto.order_request.date_to = end.setHours(23, 59, 59, 0);
        }
        if (this.entity.coordinates_form === "polygon") {
          dto.order_request.bounding_box = [
            [this.entity.coordinates[0].lng, this.entity.coordinates[0].lat],
            [this.entity.coordinates[1].lng, this.entity.coordinates[1].lat],
            [this.entity.coordinates[2].lng, this.entity.coordinates[2].lat],
            [this.entity.coordinates[3].lng, this.entity.coordinates[3].lat],
            [this.entity.coordinates[0].lng, this.entity.coordinates[0].lat],
          ];
          dto.order_request.centre_point = null;
          dto.order_request.radius = 0;
        } else {
          dto.order_request.bounding_box = null;
          dto.order_request.centre_point = [
            this.entity.centre.lng,
            this.entity.centre.lat,
          ];
          dto.order_request.radius = this.entity.centre.radius;
        }
        this.buttons.yes.disable = true;
        this.buttons.yes.icon = "fas fa-spinner fa-spin";
        let response = await orderFunc.create(
          dto,
          this.$store.getters.getAccessToken
        );
        this.buttons.yes.disable = false;
        this.buttons.yes.icon = null;
        if (response && response.status === 200) {
          this.$toast.success({
            title: this.$t("title_message_info"),
            message: this.$t("message_tasking_request_success", null, {
              name: this.entity.area_name
                ? this.entity.area_name
                    .trim()
                    .replace(/\+/g, " ")
                    .replace(/\s\s+/g, " ")
                : null,
            }),
            position: "top right",
          });
          this.onClose();
        } else {
          this.$emit("message", [
            this.$t("message_tasking_request_error", null, {
              name: this.entity.area_name
                ? this.entity.area_name
                    .trim()
                    .replace(/\+/g, " ")
                    .replace(/\s\s+/g, " ")
                : null,
            }),
          ]);
        }
      } catch (error) {
        this.buttons.yes.disable = false;
        this.buttons.yes.icon = null;
        if (error.response && error.response.status === 401)
          this.refreshToken(createOrder);
        else {
          let msg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.$emit("message", [
            msg
              ? msg
              : this.$t("message_tasking_request_error", null, {
                  name: this.entity.area_name
                    ? this.entity.area_name
                        .trim()
                        .replace(/\+/g, " ")
                        .replace(/\s\s+/g, " ")
                    : null,
                }),
          ]);
        }
      }
    },
    maximizedChage(val) {
      this.maximized = val;
    },
    calcButtons() {
      switch (this.step) {
        case 1:
          this.buttons.yes.visible = true;
          this.buttons.yes.text = this.$t("button_text_confirm");
          this.buttons.yes.class = "btn-geostore2";
          this.buttons.no.visible = true;
          this.buttons.no.text = this.$t("button_text_cancel");
          this.buttons.no.class = "btn-cancel";
          break;
      }
    },
    onPrev() {
      this.isOverflowScroll = true;
      if (
        this.entity.satellite_type &&
        this.entity.satellite_type.trim().toLowerCase() === "optical" &&
        this.step === 5
      ) {
        this.step = 3;
      } else this.step = this.step - 1;
      if (this.step > 1) {
        this.buttons.prev.visible = true;
        this.buttons.yes.text = this.$t("button_text_next");
      } else {
        this.buttons.prev.visible = false;
        this.buttons.no.visible = true;
        this.buttons.no.disable = false;
        this.buttons.yes.text = this.$t("button_text_confirm");
      }
      setTimeout(() => {
        this.isOverflowScroll = this.checkOverflowScroll();
      }, 50);
    },
    onClose() {
      this.$emit("close");
    },
    onConfirm() {
      this.isOverflowScroll = true;
      let msg = [];
      if (this.step > 0) this.buttons.prev.visible = true;
      else this.buttons.prev.visible = false;
      switch (this.step) {
        case 1:
          if (this.entity.coordinates_form === "polygon") {
            if (this.entity.coordinates[0]) {
              if (
                this.entity.coordinates[0].lat === null ||
                this.entity.coordinates[0].lng === null
              ) {
                msg.push(this.$t("message_upper_left_coordinate_is_required"));
              }
            }
            if (this.entity.coordinates[1]) {
              if (
                this.entity.coordinates[1].lat === null ||
                this.entity.coordinates[1].lng === null
              ) {
                msg.push(this.$t("message_upper_right_coordinate_is_required"));
              }
            }
            if (this.entity.coordinates[2]) {
              if (
                this.entity.coordinates[2].lat === null ||
                this.entity.coordinates[2].lng === null
              ) {
                msg.push(this.$t("message_lower_right_coordinate_is_required"));
              }
            }
            if (this.entity.coordinates[3]) {
              if (
                this.entity.coordinates[3].lat === null ||
                this.entity.coordinates[3].lng === null
              ) {
                msg.push(this.$t("message_lower_left_coordinate_is_required"));
              }
            }
          } else {
            if (
              this.entity.centre.lat === null ||
              this.entity.centre.lng === null
            ) {
              msg.push(this.$t("message_centre_coordinate_is_required"));
            }
            if (!this.entity.centre.radius) {
              msg.push(this.$t("message_radius_is_required"));
            }
          }
          break;
        case 2:
          if (!this.entity.area_name || !this.entity.area_name.trim()) {
            msg.push(this.$t("message_area_name_is_required"));
          } else {
            this.entity.area_name = this.entity.area_name
              .trim()
              .replace(/\s\s+/g, " ");
          }
          if (!this.entity.number_of_scenes) {
            msg.push(this.$t("message_number_of_scenes_is_required"));
          }
          if (!this.entity.satellite) {
            msg.push(this.$t("message_satellite_is_required"));
          } else {
            if (
              this.satelliteType.items &&
              this.satelliteType.items.length > 0
            ) {
              for (let i = 0; i < this.satelliteType.items.length; i++) {
                if (this.satelliteType.items[i].sub_meta_key) {
                  if (
                    this.satelliteType.items[i].sub_meta_key
                      .map((x) => x.id)
                      .includes(
                        this.entity.satellite.substring(
                          0,
                          this.entity.satellite.indexOf("__") >= 0
                            ? this.entity.satellite.indexOf("__")
                            : this.entity.satellite.length
                        )
                      )
                  ) {
                    this.entity.satellite_type =
                      this.satelliteType.items[i].name;
                  }
                }
              }
            }
          }

          break;
        case 3:
          if (
            this.entity.satellite_type &&
            this.entity.satellite_type.trim().toLowerCase() === "radar"
          ) {
            let start = null,
              end = null;
            if (
              !this.entity.radar.earliest ||
              !this.entity.radar.earliest_hh ||
              !this.entity.radar.earliest_mm
            ) {
              msg.push(this.$t("message_radar_earliest_is_required"));
            } else {
              try {
                start = new Date(this.entity.radar.earliest);
                if (start) {
                  let earliest_hh = parseInt(this.entity.radar.earliest_hh);
                  if (earliest_hh < 0 || earliest_hh > 23) {
                    msg.push(this.$t("message_radar_earliest_invalid_time"));
                    start = null;
                  } else {
                    let earliest_mm = parseInt(this.entity.radar.earliest_mm);
                    if (earliest_mm < 0 || earliest_hh > 59) {
                      msg.push(this.$t("message_radar_earliest_invalid_time"));
                      start = null;
                    } else {
                      start.setHours(earliest_hh, earliest_mm, 0, 0);
                    }
                  }
                } else {
                  msg.push(this.$t("message_radar_earliest_invalid_time"));
                  start = null;
                }
              } catch (error) {
                msg.push(this.$t("message_radar_earliest_invalid_time"));
                start = null;
              }
            }
            if (
              !this.entity.radar.latest ||
              !this.entity.radar.latest_hh ||
              !this.entity.radar.latest_mm
            ) {
              msg.push(this.$t("message_radar_latest_is_required"));
            } else {
              try {
                end = new Date(this.entity.radar.latest);
                if (end) {
                  let latest_hh = parseInt(this.entity.radar.latest_hh);
                  if (latest_hh < 0 || latest_hh > 23) {
                    msg.push(this.$t("message_radar_earliest_invalid_time"));
                    end = null;
                  } else {
                    let latest_mm = parseInt(this.entity.radar.latest_mm);
                    if (latest_mm < 0 || latest_mm > 59) {
                      msg.push(this.$t("message_radar_latest_invalid_time"));
                      end = null;
                    } else {
                      end.setHours(latest_hh, latest_mm, 0, 0);
                    }
                  }
                } else {
                  msg.push(this.$t("message_radar_latest_invalid_time"));
                  end = null;
                }
              } catch (error) {
                msg.push(this.$t("message_radar_latest_invalid_time"));
                end = null;
              }
            }
            if (start && end) {
              if (start - end > 0) {
                msg.push(
                  this.$t(
                    "message_tasking_radar_earliest_must_less_than_latest"
                  )
                );
              }
            }

            if (this.entity.radar.minimum) {
              try {
                let minimum = parseInt(this.entity.radar.minimum);
                if (minimum < 20 || minimum > 59) {
                  msg.push(this.$t("message_radar_incidence_minimum_invalid"));
                }
              } catch (error) {}
            }
            if (this.entity.radar.maximum) {
              try {
                let maximum = parseInt(this.entity.radar.maximum);
                if (maximum < 20 || maximum > 59) {
                  msg.push(this.$t("message_radar_incidence_maximum_invalid"));
                }
              } catch (error) {}
            }
          } else if (
            this.entity.satellite_type &&
            this.entity.satellite_type.trim().toLowerCase() === "optical"
          ) {
            if (!this.entity.optical.start) {
              msg.push(this.$t("message_optical_start_time_is_required"));
            }
            if (!this.entity.optical.end) {
              msg.push(this.$t("message_optical_end_time_is_required"));
            }
            if (this.entity.optical.start && this.entity.optical.end) {
              let start = null,
                end = null;
              try {
                start = new Date(this.entity.optical.start);
                if (start) start = start.setHours(0, 0, 0, 0);
              } catch (error) {}
              try {
                end = new Date(this.entity.optical.end);
                if (end) end = end.setHours(23, 59, 59, 0);
              } catch (error) {}
              if (start && end) {
                if (start - end > 0) {
                  msg.push(
                    this.$t("message_tasking_optical_start_must_less_than_end")
                  );
                }
              }
            }
            if (!this.entity.optical.order_type) {
              msg.push(this.$t("message_optical_order_type_is_required"));
            }

            try {
              let incAngleFrom = parseInt(this.entity.optical.inc_angle_from);
              if (incAngleFrom < 0 || incAngleFrom > 90) {
                msg.push(this.$t("message_optical_inc_angle_from_invalid"));
              }
            } catch (error) {
              msg.push(this.$t("message_optical_inc_angle_from_invalid"));
            }

            try {
              let incAngleTo = parseInt(this.entity.optical.inc_angle_to);
              if (incAngleTo < 0 || incAngleTo > 90) {
                msg.push(this.$t("message_optical_inc_angle_to_invalid"));
              }
            } catch (error) {
              msg.push(this.$t("message_optical_inc_angle_to_invalid"));
            }

            // try {
            //   let cloudCoverageFrom = parseInt(
            //     this.entity.optical.cloud_coverage_from
            //   );
            //   if (cloudCoverageFrom < 0 || cloudCoverageFrom > 100) {
            //     msg.push(
            //       this.$t("message_optical_cloud_coverage_from_invalid")
            //     );
            //   }
            // } catch (error) {
            //   msg.push(this.$t("message_optical_cloud_coverage_from_invalid"));
            // }

            try {
              let cloudCoverageTo = parseInt(
                this.entity.optical.cloud_coverage_to
              );
              if (cloudCoverageTo < 0 || cloudCoverageTo > 100) {
                msg.push(this.$t("message_optical_cloud_coverage_to_invalid"));
              }
            } catch (error) {
              msg.push(this.$t("message_optical_cloud_coverage_to_invalid"));
            }
          }
          break;
        case 4:
          if (
            this.entity.satellite_type &&
            this.entity.satellite_type.trim().toLowerCase() === "radar"
          ) {
            if (!this.entity.radar.beam_mode) {
              msg.push(this.$t("message_radar_beam_mode_is_required"));
            }
            if (!this.entity.radar.pol_layer) {
              msg.push(this.$t("message_radar_pol_layer_is_required"));
            }
          }
          break;
        case 5:
          break;
        case 6:
          if (
            this.entity.satellite_type &&
            this.entity.satellite_type.trim().toLowerCase() === "radar"
          ) {
            if (!this.entity.radar.product_level) {
              msg.push(this.$t("message_optical_product_level_is_required"));
            }
            if (!this.entity.radar.file_format) {
              msg.push(this.$t("message_optical_file_format_is_required"));
            }
            if (!this.entity.radar.delivery_media) {
              msg.push(this.$t("message_optical_delivery_media_is_required"));
            }
            if (!this.entity.radar.delivery_service) {
              msg.push(this.$t("message_optical_delivery_service_is_required"));
            }
            if (!this.entity.radar.license) {
              msg.push(this.$t("message_optical_license_is_required"));
            }
          } else if (
            this.entity.satellite_type &&
            this.entity.satellite_type.trim().toLowerCase() === "optical"
          ) {
            if (!this.entity.optical.product) {
              msg.push(this.$t("message_optical_product_is_required"));
            }
            if (!this.entity.optical.resampling_kernel) {
              msg.push(
                this.$t("message_optical_resampling_kernel_is_required")
              );
            }
            if (!this.entity.optical.bands) {
              msg.push(this.$t("message_optical_bands_is_required"));
            }
            if (!this.entity.optical.license) {
              msg.push(this.$t("message_optical_license_is_required"));
            }
            if (!this.entity.optical.bit_depth) {
              msg.push(this.$t("message_optical_bit_depth_is_required"));
            }
            if (!this.entity.optical.pixel_resolution) {
              msg.push(this.$t("message_optical_pixel_resolution_is_required"));
            }
            if (!this.entity.optical.file_format) {
              msg.push(this.$t("message_optical_file_format_is_required"));
            }
            if (!this.entity.optical.dra_contrast) {
              msg.push(this.$t("message_optical_dra_contrast_is_required"));
            }
            if (!this.entity.optical.tiling) {
              msg.push(this.$t("message_optical_tiling_is_required"));
            }
            if (!this.entity.optical.delivery_media) {
              msg.push(this.$t("message_optical_delivery_media_is_required"));
            }
          }
          break;
        case 7:
          if (!this.entity.buyer_name) {
            msg.push(this.$t("message_tasking_buyer_name_is_required"));
          }
          if (!this.entity.buyer_organization) {
            msg.push(this.$t("message_tasking_buyer_organisation_is_required"));
          }
          if (!this.entity.buyer_address) {
            msg.push(this.$t("message_tasking_buyer_address_is_required"));
          }
          if (!this.entity.buyer_zip_code) {
            msg.push(this.$t("message_tasking_buyer_zip_code_is_required"));
          }
          if (!this.entity.buyer_country) {
            msg.push(this.$t("message_tasking_buyer_country_is_required"));
          }
          if (!this.entity.buyer_province) {
            msg.push(this.$t("message_tasking_buyer_province_is_required"));
          }
          if (!this.entity.buyer_phone_number) {
            msg.push(this.$t("message_tasking_buyer_phone_number_is_required"));
          }
          if (!this.entity.buyer_email) {
            msg.push(this.$t("message_tasking_buyer_email_is_required"));
          }
          break;
        default:
          break;
      }
      if (msg && msg.length > 0) {
        this.$emit("message", msg);
        return;
      }
      if (
        this.entity.satellite_type &&
        this.entity.satellite_type.trim().toLowerCase() === "optical" &&
        this.step === 3
      ) {
        this.step = 5;
      } else {
        if (this.step === 7) {
          this.$emit(
            "confirmOrderTasking",
            this.entity.area_name
              ? this.entity.area_name
                  .trim()
                  .replace(/\+/g, " ")
                  .replace(/\s\s+/g, " ")
              : ""
          );
        } else this.step = this.step + 1;
      }
      if (this.step === 2) {
        this.buttons.no.visible = false;
        this.buttons.yes.text = this.$t("button_text_next");
      } else if (this.step === 7) {
        this.buttons.yes.text = this.$t("button_text_complete");
        this.buttons.no.visible = false;
      } else {
        this.buttons.no.visible = false;
      }
      setTimeout(() => {
        this.isOverflowScroll = this.checkOverflowScroll();
      }, 50);
    }, // get data from DB
    async getAllProvider() {
      if (this.satelliteType.items.length === 0) this.getAllSatelliteType();
      try {
        let response = await metaKeyFunc.getByKey(
          "PROVIDER",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          let data =
            response.data && response.data.data ? response.data.data : [];
          if (data && data.length > 0) {
            data.sort(function (a, b) {
              if (a.value < b.value) return -1;
              else if (a.value > b.value) return 1;
              return 0;
            });
            for (let i = 0; i < data.length; i++) {
              if (data[i].sub_meta_key && data[i].sub_meta_key.length > 0) {
                let children = [];
                for (let j = 0; j < data[i].sub_meta_key.length; j++) {
                  children.push({
                    id:
                      data[i].sub_meta_key[j].id +
                      "__" +
                      (new Date().getTime() -
                        (Math.floor(Math.random() * 1000000) + 1) +
                        (Math.floor(Math.random() * 100000) + 1)),
                    code: data[i].sub_meta_key[j].name,
                    label: data[i].sub_meta_key[j].value,
                    description: data[i].sub_meta_key[j].description,
                  });
                }
                this.provider.items.push({
                  id: data[i].id,
                  code: data[i].name,
                  label: data[i].value,
                  description: data[i].description,
                  children: children,
                });
              }
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getAllProvider);
      }
    },
    /*---------------- Meta key ----------------*/
    getSatelliteTypeByLang() {
      this.satelliteType.items = [];
      for (let i = 0; i < this.satelliteType.data.length; i++) {
        if (
          this.satelliteType.data[i].translation &&
          this.satelliteType.data[i].translation[this.lang] &&
          Object.keys(this.satelliteType.data[i].translation[this.lang])
            .length > 0
        ) {
          this.satelliteType.items.push({
            id: this.satelliteType.data[i].id,
            key: this.satelliteType.data[i].key,
            name: this.satelliteType.data[i].name,
            value: this.satelliteType.data[i].translation[this.lang].value
              ? this.satelliteType.data[i].translation[this.lang].value
              : this.satelliteType.data[i].value,
            description: this.satelliteType.data[i].translation[this.lang]
              .description
              ? this.satelliteType.data[i].translation[this.lang].description
              : this.satelliteType.data[i].description,
            sub_meta_key: this.satelliteType.data[i].sub_meta_key,
          });
        } else {
          this.satelliteType.items.push({
            id: this.satelliteType.data[i].id,
            key: this.satelliteType.data[i].key,
            name: this.satelliteType.data[i].name,
            value: this.satelliteType.data[i].value,
            description: this.satelliteType.data[i].description,
            sub_meta_key: this.satelliteType.data[i].sub_meta_key,
          });
        }
      }
    },
    getTaskingOrderTypeByLang() {
      this.orderType.items = [];
      for (let i = 0; i < this.orderType.data.length; i++) {
        if (
          this.orderType.data[i].translation &&
          this.orderType.data[i].translation[this.lang] &&
          Object.keys(this.orderType.data[i].translation[this.lang]).length > 0
        ) {
          this.orderType.items.push({
            id: this.orderType.data[i].id,
            key: this.orderType.data[i].key,
            name: this.orderType.data[i].name,
            value: this.orderType.data[i].translation[this.lang].value
              ? this.orderType.data[i].translation[this.lang].value
              : this.orderType.data[i].value,
            description: this.orderType.data[i].translation[this.lang]
              .description
              ? this.orderType.data[i].translation[this.lang].description
              : this.orderType.data[i].description,
            sub_meta_key: this.orderType.data[i].sub_meta_key,
          });
        } else {
          this.orderType.items.push({
            id: this.orderType.data[i].id,
            key: this.orderType.data[i].key,
            name: this.orderType.data[i].name,
            value: this.orderType.data[i].value,
            description: this.orderType.data[i].description,
            sub_meta_key: this.orderType.data[i].sub_meta_key,
          });
        }
      }
    },
    getLookDirectionByLang() {
      this.lookDirection.items = [];
      for (let i = 0; i < this.lookDirection.data.length; i++) {
        if (
          this.lookDirection.data[i].translation &&
          this.lookDirection.data[i].translation[this.lang] &&
          Object.keys(this.lookDirection.data[i].translation[this.lang])
            .length > 0
        ) {
          this.lookDirection.items.push({
            id: this.lookDirection.data[i].id,
            key: this.lookDirection.data[i].key,
            name: this.lookDirection.data[i].name,
            value: this.lookDirection.data[i].translation[this.lang].value
              ? this.lookDirection.data[i].translation[this.lang].value
              : this.lookDirection.data[i].value,
            description: this.lookDirection.data[i].translation[this.lang]
              .description
              ? this.lookDirection.data[i].translation[this.lang].description
              : this.lookDirection.data[i].description,
            sub_meta_key: this.lookDirection.data[i].sub_meta_key,
          });
        } else {
          this.lookDirection.items.push({
            id: this.lookDirection.data[i].id,
            key: this.lookDirection.data[i].key,
            name: this.lookDirection.data[i].name,
            value: this.lookDirection.data[i].value,
            description: this.lookDirection.data[i].description,
            sub_meta_key: this.lookDirection.data[i].sub_meta_key,
          });
        }
      }
    },
    getOrBitDirectionByLang() {
      this.orbitDirection.items = [];
      for (let i = 0; i < this.orbitDirection.data.length; i++) {
        if (
          this.orbitDirection.data[i].translation &&
          this.orbitDirection.data[i].translation[this.lang] &&
          Object.keys(this.orbitDirection.data[i].translation[this.lang])
            .length > 0
        ) {
          this.orbitDirection.items.push({
            id: this.orbitDirection.data[i].id,
            key: this.orbitDirection.data[i].key,
            name: this.orbitDirection.data[i].name,
            value: this.orbitDirection.data[i].translation[this.lang].value
              ? this.orbitDirection.data[i].translation[this.lang].value
              : this.orbitDirection.data[i].value,
            description: this.orbitDirection.data[i].translation[this.lang]
              .description
              ? this.orbitDirection.data[i].translation[this.lang].description
              : this.orbitDirection.data[i].description,
            sub_meta_key: this.orbitDirection.data[i].sub_meta_key,
          });
        } else {
          this.orbitDirection.items.push({
            id: this.orbitDirection.data[i].id,
            key: this.orbitDirection.data[i].key,
            name: this.orbitDirection.data[i].name,
            value: this.orbitDirection.data[i].value,
            description: this.orbitDirection.data[i].description,
            sub_meta_key: this.orbitDirection.data[i].sub_meta_key,
          });
        }
      }
    },
    getTaskingProductByLang() {
      this.products.items = [];
      for (let i = 0; i < this.products.data.length; i++) {
        if (
          this.products.data[i].translation &&
          this.products.data[i].translation[this.lang] &&
          Object.keys(this.products.data[i].translation[this.lang]).length > 0
        ) {
          this.products.items.push({
            id: this.products.data[i].id,
            key: this.products.data[i].key,
            name: this.products.data[i].name,
            value: this.products.data[i].translation[this.lang].value
              ? this.products.data[i].translation[this.lang].value
              : this.products.data[i].value,
            description: this.products.data[i].translation[this.lang]
              .description
              ? this.products.data[i].translation[this.lang].description
              : this.products.data[i].description,
            sub_meta_key: this.products.data[i].sub_meta_key,
          });
        } else {
          this.products.items.push({
            id: this.products.data[i].id,
            key: this.products.data[i].key,
            name: this.products.data[i].name,
            value: this.products.data[i].value,
            description: this.products.data[i].description,
            sub_meta_key: this.products.data[i].sub_meta_key,
          });
        }
      }
    },
    getTaskingBandsByLang() {
      this.bands.items = [];
      for (let i = 0; i < this.bands.data.length; i++) {
        if (
          this.bands.data[i].translation &&
          this.bands.data[i].translation[this.lang] &&
          Object.keys(this.bands.data[i].translation[this.lang]).length > 0
        ) {
          this.bands.items.push({
            id: this.bands.data[i].id,
            key: this.bands.data[i].key,
            name: this.bands.data[i].name,
            value: this.bands.data[i].translation[this.lang].value
              ? this.bands.data[i].translation[this.lang].value
              : this.bands.data[i].value,
            description: this.bands.data[i].translation[this.lang].description
              ? this.bands.data[i].translation[this.lang].description
              : this.bands.data[i].description,
            sub_meta_key: this.bands.data[i].sub_meta_key,
          });
        } else {
          this.bands.items.push({
            id: this.bands.data[i].id,
            key: this.bands.data[i].key,
            name: this.bands.data[i].name,
            value: this.bands.data[i].value,
            description: this.bands.data[i].description,
            sub_meta_key: this.bands.data[i].sub_meta_key,
          });
        }
      }
    },
    getTaskingLicenseByLang() {
      this.licenses.items = [];
      for (let i = 0; i < this.licenses.data.length; i++) {
        if (
          this.licenses.data[i].translation &&
          this.licenses.data[i].translation[this.lang] &&
          Object.keys(this.licenses.data[i].translation[this.lang]).length > 0
        ) {
          this.licenses.items.push({
            id: this.licenses.data[i].id,
            key: this.licenses.data[i].key,
            name: this.licenses.data[i].name,
            value: this.licenses.data[i].translation[this.lang].value
              ? this.licenses.data[i].translation[this.lang].value
              : this.licenses.data[i].value,
            description: this.licenses.data[i].translation[this.lang]
              .description
              ? this.licenses.data[i].translation[this.lang].description
              : this.licenses.data[i].description,
            sub_meta_key: this.licenses.data[i].sub_meta_key,
          });
        } else {
          this.licenses.items.push({
            id: this.licenses.data[i].id,
            key: this.licenses.data[i].key,
            name: this.licenses.data[i].name,
            value: this.licenses.data[i].value,
            description: this.licenses.data[i].description,
            sub_meta_key: this.licenses.data[i].sub_meta_key,
          });
        }
      }
    },
    getTaskingBitDepthByLang() {
      this.bitDepths.items = [];
      for (let i = 0; i < this.bitDepths.data.length; i++) {
        if (
          this.bitDepths.data[i].translation &&
          this.bitDepths.data[i].translation[this.lang] &&
          Object.keys(this.bitDepths.data[i].translation[this.lang]).length > 0
        ) {
          this.bitDepths.items.push({
            id: this.bitDepths.data[i].id,
            key: this.bitDepths.data[i].key,
            name: this.bitDepths.data[i].name,
            value: this.bitDepths.data[i].translation[this.lang].value
              ? this.bitDepths.data[i].translation[this.lang].value
              : this.bitDepths.data[i].value,
            description: this.bitDepths.data[i].translation[this.lang]
              .description
              ? this.bitDepths.data[i].translation[this.lang].description
              : this.bitDepths.data[i].description,
            sub_meta_key: this.bitDepths.data[i].sub_meta_key,
          });
        } else {
          this.bitDepths.items.push({
            id: this.bitDepths.data[i].id,
            key: this.bitDepths.data[i].key,
            name: this.bitDepths.data[i].name,
            value: this.bitDepths.data[i].value,
            description: this.bitDepths.data[i].description,
            sub_meta_key: this.bitDepths.data[i].sub_meta_key,
          });
        }
      }
    },
    getTaskingPixelResolutionByLang() {
      this.pixelResolutions.items = [];
      for (let i = 0; i < this.pixelResolutions.data.length; i++) {
        if (
          this.pixelResolutions.data[i].translation &&
          this.pixelResolutions.data[i].translation[this.lang] &&
          Object.keys(this.pixelResolutions.data[i].translation[this.lang])
            .length > 0
        ) {
          this.pixelResolutions.items.push({
            id: this.pixelResolutions.data[i].id,
            key: this.pixelResolutions.data[i].key,
            name: this.pixelResolutions.data[i].name,
            value: this.pixelResolutions.data[i].translation[this.lang].value
              ? this.pixelResolutions.data[i].translation[this.lang].value
              : this.pixelResolutions.data[i].value,
            description: this.pixelResolutions.data[i].translation[this.lang]
              .description
              ? this.pixelResolutions.data[i].translation[this.lang].description
              : this.pixelResolutions.data[i].description,
            sub_meta_key: this.pixelResolutions.data[i].sub_meta_key,
          });
        } else {
          this.pixelResolutions.items.push({
            id: this.pixelResolutions.data[i].id,
            key: this.pixelResolutions.data[i].key,
            name: this.pixelResolutions.data[i].name,
            value: this.pixelResolutions.data[i].value,
            description: this.pixelResolutions.data[i].description,
            sub_meta_key: this.pixelResolutions.data[i].sub_meta_key,
          });
        }
      }
    },
    getTaskingDRAContrastByLang() {
      this.draContrasts.items = [];
      for (let i = 0; i < this.draContrasts.data.length; i++) {
        if (
          this.draContrasts.data[i].translation &&
          this.draContrasts.data[i].translation[this.lang] &&
          Object.keys(this.draContrasts.data[i].translation[this.lang]).length >
            0
        ) {
          this.draContrasts.items.push({
            id: this.draContrasts.data[i].id,
            key: this.draContrasts.data[i].key,
            name: this.draContrasts.data[i].name,
            value: this.draContrasts.data[i].translation[this.lang].value
              ? this.draContrasts.data[i].translation[this.lang].value
              : this.draContrasts.data[i].value,
            description: this.draContrasts.data[i].translation[this.lang]
              .description
              ? this.draContrasts.data[i].translation[this.lang].description
              : this.draContrasts.data[i].description,
            sub_meta_key: this.draContrasts.data[i].sub_meta_key,
          });
        } else {
          this.draContrasts.items.push({
            id: this.draContrasts.data[i].id,
            key: this.draContrasts.data[i].key,
            name: this.draContrasts.data[i].name,
            value: this.draContrasts.data[i].value,
            description: this.draContrasts.data[i].description,
            sub_meta_key: this.draContrasts.data[i].sub_meta_key,
          });
        }
      }
    },
    getTaskingResamplingKernelByLang() {
      this.resamplingKernels.items = [];
      for (let i = 0; i < this.resamplingKernels.data.length; i++) {
        if (
          this.resamplingKernels.data[i].translation &&
          this.resamplingKernels.data[i].translation[this.lang] &&
          Object.keys(this.resamplingKernels.data[i].translation[this.lang])
            .length > 0
        ) {
          this.resamplingKernels.items.push({
            id: this.resamplingKernels.data[i].id,
            key: this.resamplingKernels.data[i].key,
            name: this.resamplingKernels.data[i].name,
            value: this.resamplingKernels.data[i].translation[this.lang].value
              ? this.resamplingKernels.data[i].translation[this.lang].value
              : this.resamplingKernels.data[i].value,
            description: this.resamplingKernels.data[i].translation[this.lang]
              .description
              ? this.resamplingKernels.data[i].translation[this.lang]
                  .description
              : this.resamplingKernels.data[i].description,
            sub_meta_key: this.resamplingKernels.data[i].sub_meta_key,
          });
        } else {
          this.resamplingKernels.items.push({
            id: this.resamplingKernels.data[i].id,
            key: this.resamplingKernels.data[i].key,
            name: this.resamplingKernels.data[i].name,
            value: this.resamplingKernels.data[i].value,
            description: this.resamplingKernels.data[i].description,
            sub_meta_key: this.resamplingKernels.data[i].sub_meta_key,
          });
        }
      }
    },

    getTaskingOrthoOptionByLang() {
      this.orthoOptions.items = [];
      for (let i = 0; i < this.orthoOptions.data.length; i++) {
        if (
          this.orthoOptions.data[i].translation &&
          this.orthoOptions.data[i].translation[this.lang] &&
          Object.keys(this.orthoOptions.data[i].translation[this.lang]).length >
            0
        ) {
          this.orthoOptions.items.push({
            id: this.orthoOptions.data[i].id,
            key: this.orthoOptions.data[i].key,
            name: this.orthoOptions.data[i].name,
            value: this.orthoOptions.data[i].translation[this.lang].value
              ? this.orthoOptions.data[i].translation[this.lang].value
              : this.orthoOptions.data[i].value,
            description: this.orthoOptions.data[i].translation[this.lang]
              .description
              ? this.orthoOptions.data[i].translation[this.lang].description
              : this.orthoOptions.data[i].description,
            sub_meta_key: this.orthoOptions.data[i].sub_meta_key,
          });
        } else {
          this.orthoOptions.items.push({
            id: this.orthoOptions.data[i].id,
            key: this.orthoOptions.data[i].key,
            name: this.orthoOptions.data[i].name,
            value: this.orthoOptions.data[i].value,
            description: this.orthoOptions.data[i].description,
            sub_meta_key: this.orthoOptions.data[i].sub_meta_key,
          });
        }
      }
    },
    getTaskingFileFormatByLang() {
      this.fileFormatsOptical.items = [];
      for (let i = 0; i < this.fileFormatsOptical.data.length; i++) {
        if (
          this.fileFormatsOptical.data[i].translation &&
          this.fileFormatsOptical.data[i].translation[this.lang] &&
          Object.keys(this.fileFormatsOptical.data[i].translation[this.lang])
            .length > 0
        ) {
          this.fileFormatsOptical.items.push({
            id: this.fileFormatsOptical.data[i].id,
            key: this.fileFormatsOptical.data[i].key,
            name: this.fileFormatsOptical.data[i].name,
            value: this.fileFormatsOptical.data[i].translation[this.lang].value
              ? this.fileFormatsOptical.data[i].translation[this.lang].value
              : this.fileFormatsOptical.data[i].value,
            description: this.fileFormatsOptical.data[i].translation[this.lang]
              .description
              ? this.fileFormatsOptical.data[i].translation[this.lang]
                  .description
              : this.fileFormatsOptical.data[i].description,
            sub_meta_key: this.fileFormatsOptical.data[i].sub_meta_key,
          });
        } else {
          this.fileFormatsOptical.items.push({
            id: this.fileFormatsOptical.data[i].id,
            key: this.fileFormatsOptical.data[i].key,
            name: this.fileFormatsOptical.data[i].name,
            value: this.fileFormatsOptical.data[i].value,
            description: this.fileFormatsOptical.data[i].description,
            sub_meta_key: this.fileFormatsOptical.data[i].sub_meta_key,
          });
        }
      }
    },
    getTaskingTilingByLang() {
      this.tiling.items = [];
      for (let i = 0; i < this.tiling.data.length; i++) {
        if (
          this.tiling.data[i].translation &&
          this.tiling.data[i].translation[this.lang] &&
          Object.keys(this.tiling.data[i].translation[this.lang]).length > 0
        ) {
          this.tiling.items.push({
            id: this.tiling.data[i].id,
            key: this.tiling.data[i].key,
            name: this.tiling.data[i].name,
            value: this.tiling.data[i].translation[this.lang].value
              ? this.tiling.data[i].translation[this.lang].value
              : this.tiling.data[i].value,
            description: this.tiling.data[i].translation[this.lang].description
              ? this.tiling.data[i].translation[this.lang].description
              : this.tiling.data[i].description,
            sub_meta_key: this.tiling.data[i].sub_meta_key,
          });
        } else {
          this.tiling.items.push({
            id: this.tiling.data[i].id,
            key: this.tiling.data[i].key,
            name: this.tiling.data[i].name,
            value: this.tiling.data[i].value,
            description: this.tiling.data[i].description,
            sub_meta_key: this.tiling.data[i].sub_meta_key,
          });
        }
      }
    },
    getTaskingDeliveryMediaByLang() {
      this.deliveriesMedia.items = [];
      for (let i = 0; i < this.deliveriesMedia.data.length; i++) {
        if (
          this.deliveriesMedia.data[i].translation &&
          this.deliveriesMedia.data[i].translation[this.lang] &&
          Object.keys(this.deliveriesMedia.data[i].translation[this.lang])
            .length > 0
        ) {
          this.deliveriesMedia.items.push({
            id: this.deliveriesMedia.data[i].id,
            key: this.deliveriesMedia.data[i].key,
            name: this.deliveriesMedia.data[i].name,
            value: this.deliveriesMedia.data[i].translation[this.lang].value
              ? this.deliveriesMedia.data[i].translation[this.lang].value
              : this.deliveriesMedia.data[i].value,
            description: this.deliveriesMedia.data[i].translation[this.lang]
              .description
              ? this.deliveriesMedia.data[i].translation[this.lang].description
              : this.deliveriesMedia.data[i].description,
            sub_meta_key: this.deliveriesMedia.data[i].sub_meta_key,
          });
        } else {
          this.deliveriesMedia.items.push({
            id: this.deliveriesMedia.data[i].id,
            key: this.deliveriesMedia.data[i].key,
            name: this.deliveriesMedia.data[i].name,
            value: this.deliveriesMedia.data[i].value,
            description: this.deliveriesMedia.data[i].description,
            sub_meta_key: this.deliveriesMedia.data[i].sub_meta_key,
          });
        }
      }
    },
    getTaskingFileFormatsRadarByLang() {
      this.fileFormatsRadar.items = [];
      for (let i = 0; i < this.fileFormatsRadar.data.length; i++) {
        if (
          this.fileFormatsRadar.data[i].translation &&
          this.fileFormatsRadar.data[i].translation[this.lang] &&
          Object.keys(this.fileFormatsRadar.data[i].translation[this.lang])
            .length > 0
        ) {
          this.fileFormatsRadar.items.push({
            id: this.fileFormatsRadar.data[i].id,
            key: this.fileFormatsRadar.data[i].key,
            name: this.fileFormatsRadar.data[i].name,
            value: this.fileFormatsRadar.data[i].translation[this.lang].value
              ? this.fileFormatsRadar.data[i].translation[this.lang].value
              : this.fileFormatsRadar.data[i].value,
            description: this.fileFormatsRadar.data[i].translation[this.lang]
              .description
              ? this.fileFormatsRadar.data[i].translation[this.lang].description
              : this.fileFormatsRadar.data[i].description,
            sub_meta_key: this.fileFormatsRadar.data[i].sub_meta_key,
          });
        } else {
          this.fileFormatsRadar.items.push({
            id: this.fileFormatsRadar.data[i].id,
            key: this.fileFormatsRadar.data[i].key,
            name: this.fileFormatsRadar.data[i].name,
            value: this.fileFormatsRadar.data[i].value,
            description: this.fileFormatsRadar.data[i].description,
            sub_meta_key: this.fileFormatsRadar.data[i].sub_meta_key,
          });
        }
      }
    },
    getTaskingProductLevelByLang() {
      this.productLevels.items = [];
      for (let i = 0; i < this.productLevels.data.length; i++) {
        if (
          this.productLevels.data[i].translation &&
          this.productLevels.data[i].translation[this.lang] &&
          Object.keys(this.productLevels.data[i].translation[this.lang])
            .length > 0
        ) {
          this.productLevels.items.push({
            id: this.productLevels.data[i].id,
            key: this.productLevels.data[i].key,
            name: this.productLevels.data[i].name,
            value: this.productLevels.data[i].translation[this.lang].value
              ? this.productLevels.data[i].translation[this.lang].value
              : this.productLevels.data[i].value,
            description: this.productLevels.data[i].translation[this.lang]
              .description
              ? this.productLevels.data[i].translation[this.lang].description
              : this.productLevels.data[i].description,
            sub_meta_key: this.productLevels.data[i].sub_meta_key,
          });
        } else {
          this.productLevels.items.push({
            id: this.productLevels.data[i].id,
            key: this.productLevels.data[i].key,
            name: this.productLevels.data[i].name,
            value: this.productLevels.data[i].value,
            description: this.productLevels.data[i].description,
            sub_meta_key: this.productLevels.data[i].sub_meta_key,
          });
        }
      }
    },
    getTaskingDeliveryServiceByLang() {
      this.deliveriesService.items = [];
      for (let i = 0; i < this.deliveriesService.data.length; i++) {
        if (
          this.deliveriesService.data[i].translation &&
          this.deliveriesService.data[i].translation[this.lang] &&
          Object.keys(this.deliveriesService.data[i].translation[this.lang])
            .length > 0
        ) {
          this.deliveriesService.items.push({
            id: this.deliveriesService.data[i].id,
            key: this.deliveriesService.data[i].key,
            name: this.deliveriesService.data[i].name,
            value: this.deliveriesService.data[i].translation[this.lang].value
              ? this.deliveriesService.data[i].translation[this.lang].value
              : this.deliveriesService.data[i].value,
            description: this.deliveriesService.data[i].translation[this.lang]
              .description
              ? this.deliveriesService.data[i].translation[this.lang]
                  .description
              : this.deliveriesService.data[i].description,
            sub_meta_key: this.deliveriesService.data[i].sub_meta_key,
          });
        } else {
          this.deliveriesService.items.push({
            id: this.deliveriesService.data[i].id,
            key: this.deliveriesService.data[i].key,
            name: this.deliveriesService.data[i].name,
            value: this.deliveriesService.data[i].value,
            description: this.deliveriesService.data[i].description,
            sub_meta_key: this.deliveriesService.data[i].sub_meta_key,
          });
        }
      }
    },
    getTaskingGranularityByLang() {
      this.granularity.items = [];
      for (let i = 0; i < this.granularity.data.length; i++) {
        if (
          this.granularity.data[i].translation &&
          this.granularity.data[i].translation[this.lang] &&
          Object.keys(this.granularity.data[i].translation[this.lang]).length >
            0
        ) {
          this.granularity.items.push({
            id: this.granularity.data[i].id,
            key: this.granularity.data[i].key,
            name: this.granularity.data[i].name,
            value: this.granularity.data[i].translation[this.lang].value
              ? this.granularity.data[i].translation[this.lang].value
              : this.granularity.data[i].value,
            description: this.granularity.data[i].translation[this.lang]
              .description
              ? this.granularity.data[i].translation[this.lang].description
              : this.granularity.data[i].description,
            sub_meta_key: this.granularity.data[i].sub_meta_key,
          });
        } else {
          this.granularity.items.push({
            id: this.granularity.data[i].id,
            key: this.granularity.data[i].key,
            name: this.granularity.data[i].name,
            value: this.granularity.data[i].value,
            description: this.granularity.data[i].description,
            sub_meta_key: this.granularity.data[i].sub_meta_key,
          });
        }
      }
    },
    getTaskingBeamModeByLang() {
      this.beamMode.items = [];
      for (let i = 0; i < this.beamMode.data.length; i++) {
        if (
          this.beamMode.data[i].translation &&
          this.beamMode.data[i].translation[this.lang] &&
          Object.keys(this.beamMode.data[i].translation[this.lang]).length > 0
        ) {
          this.beamMode.items.push({
            id: this.beamMode.data[i].id,
            key: this.beamMode.data[i].key,
            name: this.beamMode.data[i].name,
            value: this.beamMode.data[i].translation[this.lang].value
              ? this.beamMode.data[i].translation[this.lang].value
              : this.beamMode.data[i].value,
            description: this.beamMode.data[i].translation[this.lang]
              .description
              ? this.beamMode.data[i].translation[this.lang].description
              : this.beamMode.data[i].description,
            sub_meta_key: this.beamMode.data[i].sub_meta_key,
          });
        } else {
          this.beamMode.items.push({
            id: this.beamMode.data[i].id,
            key: this.beamMode.data[i].key,
            name: this.beamMode.data[i].name,
            value: this.beamMode.data[i].value,
            description: this.beamMode.data[i].description,
            sub_meta_key: this.beamMode.data[i].sub_meta_key,
          });
        }
      }
    },
    getTaskingProgrammingServiceByLang() {
      this.programingService.items = [];
      for (let i = 0; i < this.programingService.data.length; i++) {
        if (
          this.programingService.data[i].translation &&
          this.programingService.data[i].translation[this.lang] &&
          Object.keys(this.programingService.data[i].translation[this.lang])
            .length > 0
        ) {
          this.programingService.items.push({
            id: this.programingService.data[i].id,
            key: this.programingService.data[i].key,
            name: this.programingService.data[i].name,
            value: this.programingService.data[i].translation[this.lang].value
              ? this.programingService.data[i].translation[this.lang].value
              : this.programingService.data[i].value,
            description: this.programingService.data[i].translation[this.lang]
              .description
              ? this.programingService.data[i].translation[this.lang]
                  .description
              : this.programingService.data[i].description,
            sub_meta_key: this.programingService.data[i].sub_meta_key,
          });
        } else {
          this.programingService.items.push({
            id: this.programingService.data[i].id,
            key: this.programingService.data[i].key,
            name: this.programingService.data[i].name,
            value: this.programingService.data[i].value,
            description: this.programingService.data[i].description,
            sub_meta_key: this.programingService.data[i].sub_meta_key,
          });
        }
      }
    },
    /*---------------- End meta key ----------------*/
    async getAllSatelliteType() {
      try {
        let response = await metaKeyFunc.getByKey(
          "IMAGE_TYPES",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.satelliteType.data =
            response.data && response.data.data ? response.data.data : [];
          this.getSatelliteTypeByLang();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllSatelliteType);
        }
      }
    },
    async getAllTaskingOrderType() {
      try {
        let response = await metaKeyFunc.getByKey(
          "ORDER_TYPES",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.orderType.data =
            response.data && response.data.data ? response.data.data : [];
          if (this.orderType.data && this.orderType.data.length > 0) {
            let item = this.orderType.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.optical.order_type = item.id ? item.id : null;
              this.entity.optical.order_type_name = item.value
                ? item.value
                : null;
            }
            this.getTaskingOrderTypeByLang();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingOrderType);
        }
      }
    },

    async getAllDirection() {
      try {
        let response = await metaKeyFunc.getByKey(
          "DIRECTION",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          let data =
            response.data && response.data.data ? response.data.data : [];
          this.lookDirection.data = data.filter(
            (x) => x.name != "ASCENDING" && x.name != "DESCENDING"
          );
          this.orbitDirection.data = data.filter(
            (x) => x.name != "LEFT" && x.name != "RIGHT"
          );

          if (this.lookDirection.data && this.lookDirection.data.length > 0) {
            let item = this.lookDirection.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.radar.look_direction = item.id ? item.id : null;
              this.entity.radar.look_direction_name = item.value
                ? item.value
                : null;
            }
            this.getLookDirectionByLang();
          }
          if (this.orbitDirection.data && this.orbitDirection.data.length > 0) {
            let item = this.orbitDirection.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.radar.orbit_direction = item.id ? item.id : null;
              this.entity.radar.orbit_direction_name = item.value
                ? item.value
                : null;
            }

            this.getOrBitDirectionByLang();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllDirection);
        }
      }
    },
    async getAllTaskingProduct() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PRODUCT",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.products.data =
            response.data && response.data.data ? response.data.data : [];
          if (this.products.data && this.products.data.length > 0) {
            let item = this.products.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.optical.product = item.id ? item.id : null;
              this.entity.optical.product_name = item.value ? item.value : null;
            }
            this.getTaskingProductByLang();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingProduct);
        }
      }
    },
    async getAllTaskingBands() {
      try {
        let response = await metaKeyFunc.getByKey(
          "BANDS",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.bands.data =
            response.data && response.data.data ? response.data.data : [];
          if (this.bands.data && this.bands.data.length > 0) {
            let item = this.bands.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.optical.bands = item.id ? item.id : null;
              this.entity.optical.bands_name = item.value ? item.value : null;
            }
            this.getTaskingBandsByLang();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingBands);
        }
      }
    },
    async getAllTaskingLicense() {
      try {
        let response = await metaKeyFunc.getByKey(
          "LICENSE",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.licenses.data =
            response.data && response.data.data ? response.data.data : [];
          if (this.licenses.data && this.licenses.data.length > 0) {
            let item = this.licenses.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.optical.license = item.id ? item.id : null;
              this.entity.optical.license_name = item.value ? item.value : null;

              this.entity.radar.license = item.id ? item.id : null;
              this.entity.radar.license_name = item.value ? item.value : null;
            }
            this.getTaskingLicenseByLang();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingLicense);
        }
      }
    },
    async getAllTaskingBitDepth() {
      try {
        let response = await metaKeyFunc.getByKey(
          "BIT_DEPTH",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.bitDepths.data =
            response.data && response.data.data ? response.data.data : [];
          if (this.bitDepths.data && this.bitDepths.data.length > 0) {
            let item = this.bitDepths.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.optical.bit_depth = item.id ? item.id : null;
              this.entity.optical.bit_depth_name = item.value
                ? item.value
                : null;
            }
            this.getTaskingBitDepthByLang();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingBitDepth);
        }
      }
    },
    async getAllTaskingPixelResolution() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PIXEL_RESOLUTION",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.pixelResolutions.data = response.data.data
            ? response.data.data
            : [];
          if (
            this.pixelResolutions.data &&
            this.pixelResolutions.data.length > 0
          ) {
            let item = this.pixelResolutions.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.optical.pixel_resolution = item.id ? item.id : null;
              this.entity.optical.pixel_resolution_name = item.value
                ? item.value
                : null;
            }
            this.getTaskingPixelResolutionByLang();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingPixelResolution);
        }
      }
    },
    async getAllTaskingDRAContrast() {
      try {
        let response = await metaKeyFunc.getByKey(
          "DRA_CONTRAST",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.draContrasts.data = response.data.data ? response.data.data : [];
          if (this.draContrasts.data && this.draContrasts.data.length > 0) {
            let item = this.draContrasts.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.optical.dra_contrast = item.id ? item.id : null;
              this.entity.optical.dra_contrast_name = item.value
                ? item.value
                : null;
            }
            this.getTaskingDRAContrastByLang();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingDRAContrast);
        }
      }
    },
    async getAllTaskingResamplingKernel() {
      try {
        let response = await metaKeyFunc.getByKey(
          "RESAMPLING_KERNEL",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.resamplingKernels.data = response.data.data
            ? response.data.data
            : [];
          if (
            this.resamplingKernels.data &&
            this.resamplingKernels.data.length > 0
          ) {
            let item = this.resamplingKernels.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.optical.resampling_kernel = item.id ? item.id : null;
              this.entity.optical.resampling_kernel_name = item.value
                ? item.value
                : null;
            }
            this.getTaskingResamplingKernelByLang();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingResamplingKernel);
        }
      }
    },
    async getAllTaskingOrthoOption() {
      try {
        let response = await metaKeyFunc.getByKey(
          "ORTHO_OPTION",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.orthoOptions.data = response.data.data ? response.data.data : [];
          if (this.orthoOptions.data && this.orthoOptions.data.length > 0) {
            let item = this.orthoOptions.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.optical.ortho_option = item.id ? item.id : null;
              this.entity.optical.ortho_option_name = item.value
                ? item.value
                : null;
            }
            this.getTaskingOrthoOptionByLang();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingOrthoOption);
        }
      }
    },

    async getAllTaskingFileFormat() {
      try {
        let response = await metaKeyFunc.getByKey(
          "FILE_FORMAT_OPTICAL",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.fileFormatsOptical.data = response.data.data
            ? response.data.data
            : [];
          if (
            this.fileFormatsOptical.data &&
            this.fileFormatsOptical.data.length > 0
          ) {
            let item = this.fileFormatsOptical.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.optical.file_format = item.id ? item.id : null;
              this.entity.optical.file_format_name = item.value
                ? item.value
                : null;
            }
            this.getTaskingFileFormatByLang();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingFileFormat);
        }
      }
    },
    async getAllTaskingTiling() {
      try {
        let response = await metaKeyFunc.getByKey(
          "TILING",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.tiling.data = response.data.data ? response.data.data : [];
          if (this.tiling.data && this.tiling.data.length > 0) {
            let item = this.tiling.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.optical.tiling = item.id ? item.id : null;
              this.entity.optical.tiling_name = item.value ? item.value : null;
            }
            this.getTaskingTilingByLang();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingTiling);
        }
      }
    },

    async getAllTaskingDeliveryMedia() {
      try {
        let response = await metaKeyFunc.getByKey(
          "DELIVERY_MEDIA",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.deliveriesMedia.data = response.data.data
            ? response.data.data
            : [];
          if (
            this.deliveriesMedia.data &&
            this.deliveriesMedia.data.length > 0
          ) {
            let item = this.deliveriesMedia.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.optical.delivery_media = item.id ? item.id : null;
              this.entity.optical.delivery_media_name = item.value
                ? item.value
                : null;
              this.entity.radar.delivery_media = item.id ? item.id : null;
              this.entity.radar.delivery_media_name = item.value
                ? item.value
                : null;
            }
            this.getTaskingDeliveryMediaByLang();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingDeliveryMedia);
        }
      }
    },

    async getAllTaskingFileFormatsRadar() {
      try {
        let response = await metaKeyFunc.getByKey(
          "FILE_FORMAT_RADAR",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.fileFormatsRadar.data = response.data.data
            ? response.data.data
            : [];
          if (
            this.fileFormatsRadar.data &&
            this.fileFormatsRadar.data.length > 0
          ) {
            let item = this.fileFormatsRadar.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.radar.file_format = item.id ? item.id : null;
              this.entity.radar.file_format_name = item.value
                ? item.value
                : null;
            }
            this.getTaskingFileFormatsRadarByLang();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingFileFormatsRadar);
        }
      }
    },

    async getAllTaskingProductLevel() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PRODUCT_LEVEL",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.productLevels.data = response.data.data
            ? response.data.data
            : [];
          if (this.productLevels.data && this.productLevels.data.length > 0) {
            let item = this.productLevels.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.radar.product_level = item.id ? item.id : null;
              this.entity.radar.product_level_name = item.value
                ? item.value
                : null;
            }
            this.getTaskingProductLevelByLang();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingProductLevel);
        }
      }
    },

    async getAllTaskingDeliveryService() {
      try {
        let response = await metaKeyFunc.getByKey(
          "DELIVERY_SERVICE",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.deliveriesService.data = response.data.data
            ? response.data.data
            : [];
          if (
            this.deliveriesService.data &&
            this.deliveriesService.data.length > 0
          ) {
            let item = this.deliveriesService.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.radar.delivery_service = item.id ? item.id : null;
              this.entity.radar.delivery_service_name = item.value
                ? item.value
                : null;
            }
            this.getTaskingDeliveryServiceByLang();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingDeliveryService);
        }
      }
    },

    async getAllTaskingGranularity() {
      try {
        let response = await metaKeyFunc.getByKey(
          "GRANULARITY",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.granularity.data = response.data.data ? response.data.data : [];
          if (this.granularity.data && this.granularity.data.length > 0) {
            let item = this.granularity.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.radar.granularity = item.id ? item.id : null;
              this.entity.radar.granularity_name = item.value
                ? item.value
                : null;
            }
            this.getTaskingGranularityByLang();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingGranularity);
        }
      }
    },

    async getAllTaskingBeamMode() {
      try {
        let response = await metaKeyFunc.getByKey(
          "BEAM_MODE",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.beamMode.data = response.data.data ? response.data.data : [];

          if (this.beamMode.data && this.beamMode.data.length > 0) {
            this.beamMode.data = this.beamMode.data.filter(
              (x) => x.sub_meta_key && x.sub_meta_key.length > 0
            );
            let item = this.beamMode.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.radar.beam_mode = item.id ? item.id : null;
              this.entity.radar.beam_mode_name = item.value ? item.value : null;
            }
            this.getTaskingBeamModeByLang();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingBeamMode);
        }
      }
    },
    async getAllTaskingProgrammingService() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PROGRAMMING_SERVICE",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.programingService.data = response.data.data
            ? response.data.data
            : [];
          if (
            this.programingService.data &&
            this.programingService.data.length > 0
          ) {
            let item = this.programingService.data.find((x) => x.is_default);
            if (item && Object.keys(item).length > 0) {
              this.entity.programming_service = item.id ? item.id : null;
              this.entity.programming_service_name = item.value
                ? item.value
                : null;
            }
            this.getTaskingProgrammingServiceByLang();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingProgrammingService);
        }
      }
    },
    async getAllCountry() {
      try {
        let response = await miscFunc.getCountry(
          {},
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.country.items =
            response.data && response.data.data ? response.data.data : [];
          if (this.country.items) {
            this.country.items.sort(function (a, b) {
              if (a.name < b.name) return -1;
              else if (a.name > b.name) return 1;
              return 0;
            });
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllCountry);
        }
      }
    },
    async getStateByCountryId(id) {
      this.province.items = [];
      try {
        let response = await miscFunc.getStateByCountry(
          id,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success)
            this.province.items = response.data.data ? response.data.data : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getStateByCountryId, id);
        }
      }
    },
    checkOverflowScroll() {
      let element = document.getElementById("Ku7BNAmfHj2F");
      if (element) return element.offsetHeight > this.contentMinHeight;
      else return true;
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
    // end get data from DB
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    width() {
      if (this.documentWidth > 992) {
        return 960;
      } else {
        return this.documentWidth;
      }
    },
    contentMinHeight() {
      // 52 là footer; 80= header 2*32 la khoang cach tren va duoi; *16 là padding dialog content
      return this.documentHeight - 52 - 80 - 2 * 34 - 2 * 16;
    },
    title() {
      if (this.step === 1) return this.$t("title_enter_coordinates");
      else return this.$t("title_tasking");
    },
    criteria() {
      return this.$store.getters.getSearchDto;
    },
    coordinates() {
      if (this.criteria && Object.keys(this.criteria).length > 0) {
        try {
          let obj = JSON.parse(this.criteria.boundary);
          if (obj && Object.keys(obj).length > 0) {
            if (obj.coordinates && obj.coordinates[0]) {
              let results = [];
              for (let i = 0; i < obj.coordinates[0].length; i++) {
                if (i === 4) break;
                results.push({
                  lat: parseFloat(obj.coordinates[0][i][1].toFixed(5)),
                  lng: parseFloat(obj.coordinates[0][i][0].toFixed(5)),
                });
              }
              return results;
            }
          }
        } catch (error) {}
      }
      return [];
    },
  },
  beforeDestroy() {
    this.$store.dispatch("setIsTasking", false);
  },
};
</script>
<style lang="scss" scoped>
.dialog-tasking {
  .p-dialog-footer button {
    min-width: 7rem !important;
  }
}
</style>
