<template>
  <div v-if="show">
    <div class="popup-panel" :style="style">
      <div class="popup-panel__header">
        <table
          cellspacing="2"
          cellpadding="0"
          style="width: 100%; height: 100%"
        >
          <tbody>
            <tr>
              <td align="left" style="vertical-align: middle">
                <div class="gwt-Label GP 0VF02CCEB" style="white-space: nowrap">
                  <span
                    style="
                      padding-left: 3px;
                      vertical-align: middle;
                      white-space: normal;
                    "
                  >
                    {{ $t("title_aoi_info") }}</span
                  >
                </div>
              </td>
              <td
                align="right"
                style="vertical-align: middle"
                class="close-popup"
              >
                <i class="fas fa-times" @click="onClose"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="popup-panel__body">
        <keep-alive v-if="componentActive">
          <component :is="componentActive" :prop-info="propInfo"></component>
        </keep-alive>
      </div>
      <div class="popup-panel__footer">
        <div class="group-left">
          <button
            type="button"
            class="btn btn-remove-aoi btn-sm mr-1"
            @click="removeFeature"
          >
            <i class="fas fa-trash-alt"></i>&nbsp;{{ $t("button_text_remove") }}
          </button>
          <button
            type="button"
            class="btn btn-export-aoi btn-sm"
            @click="exportShapeFile"
          >
            <i class="fas fa-file-export"></i>&nbsp;{{
              $t("button_text_export")
            }}
          </button>
        </div>
        <div class="group-right float-right">
          <button
            type="button"
            class="btn btn-sm btn-geostore2"
            @click="onClose"
          >
            <i class="fas fa-times"></i>&nbsp;{{ $t("button_text_close") }}
          </button>
        </div>
      </div>
    </div>
    <div
      class="GP0VF02CACB GP0VF02CDCB"
      :style="[
        { display: 'block' },
        { top: clientY - 64 + 'px' },
        { left: clientX + 8 + 'px' },
      ]"
    >
      <div class="GP0VF02CICB"></div>
      <div class="GP0VF02CGCB"></div>
    </div>
  </div>
</template>
<script>
import RectangleComponent from "./aoiinfo/Rectangle";
import PolygonComponent from "./aoiinfo/Polygon";
export default {
  components: { RectangleComponent, PolygonComponent },
  props: {
    propShow: {
      type: Boolean,
      default: false,
    },
    clientX: {
      type: Number,
      default: 0,
    },
    clientY: {
      type: Number,
      default: 0,
    },
    propInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
      componentActive: null,
    };
  },
  created() {
    this.show = this.propShow;
  },
  computed: {
    style() {
      return {
        zIndex: 1000,
        visibility: "visible",
        overflow: "visible",
        position: "absolute",
        left: this.clientX + 16 + "px",
        top: this.clientY - 100 + "px",
      };
    },
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = this.propShow;
      },
      immediate: true,
    },
    "propInfo.tool": {
      handler(val) {
        switch (val) {
          case "Box":
            this.componentActive = "RectangleComponent";
            break;
          case "Polygon":
            this.componentActive = "PolygonComponent";
            break;
          default:
            this.componentActive = "PolygonComponent";
            break;
        }
      },
      immediate: true,
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    exportShapeFile() {
      this.$emit("showDialogExport", this.propInfo.feature);
    },
    removeFeature() {
      this.$emit("removeFeature", this.propInfo.id);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.popup-panel {
  background: white;
  background-clip: padding-box;
  border: 1px solid #acacac;
  outline: 0;
  z-index: 1;
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  filter: progid;
  width: 30rem;
  height: auto;
  padding: 0.25rem;
  .popup-panel__header {
    background: $background-color;
    color: white;
    padding: 0.375rem;
    .close-popup {
      i:hover {
        cursor: pointer;
        color: #dc3545;
      }
    }
  }
  .popup-panel__body {
    border: 1px solid #bfbfbf;
    margin: 0.5rem 0.25rem;
    padding: 0.5rem;
  }
  .popup-panel__footer {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 0.25rem;
    button {
      &.btn-remove-aoi {
        background: #fde8ee;
        border-radius: 4px;
        color: #f1416c;
        &:hover {
          background: #fae2e9;
        }
      }
      &.btn-export-aoi {
        background: #eef3f7;
        border-radius: 4px;
        color: #009ef7;
        &:hover {
          background: #e2f0f8;
        }
      }
    }
  }
}
.GP0VF02CACB {
  position: absolute;
  width: 0;
  height: 0;
  border-color: white;
  z-index: 1000;
}
.GP0VF02CDCB {
  top: 50%;
  width: 10px;
  height: 20px;
  left: 2px;
}
.GP0VF02CDCB .GP0VF02CICB {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  top: -1px;
  left: -1px;
  border-right: 10px solid #aaa;
  position: absolute;
}
.GP0VF02CDCB .GP0VF02CGCB {
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  left: 0;
  top: 0;
  border-right: 9px solid white;
  position: absolute;
}
</style>
