<template>
  <div
    class="group"
    role="group"
    aria-label="Button group with nested dropdown"
  >
    <h6 class="box-title">
      <strong>Bộ lọc</strong>
    </h6>
    <div class="button-group">
      <button
        type="button"
        class="btn btn-primary"
        @click="actionClick('MODELS')"
      >
        <i class="fas fa-satellite-dish"></i>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click="actionClick('CRITERIA')"
      >
        <i class="fas fa-bars"></i>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  methods: {
    actionClick(val) {
      this.$emit("actionClick", {
        code: val,
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
.group {
  padding: 0.5rem 1rem;
  background-color: white;
  text-align: center;
  display: inline-block;
  opacity: 0.9;
}
.button-group {
  display: flex;
}
button {
  padding: 0 0.5rem;
  margin: 0 0.25rem;
  background-color: $background-color;
  border-color: $background-color;
}
.box-title {
  width: 100%;
  text-align: center;
  font-size: 14px;
}
</style>
