<template>
  <div
    class="inprogress-tab"
    @scroll="onScroll"
    :style="{ height: propTabsHeight - 42 - 60 - 8 + 'px' }"
  >
    <div
      class="media"
      v-for="item in propData.items"
      :key="item.icr.id"
      @mouseover="mouseOverItem(item)"
      @mouseleave="mouseLeaveItem(item)"
    >
      <div class="media-body">
        <div class="title d-flex justify-content-between align-items-center">
          <p class="mt-0">
            <span class="tasking-type">{{ item.icr.progTypeName }}</span
            >&nbsp;{{ item.sal }}
          </p>
          <div class="actions">
            <button @click="addFootprint(item)">
              <i
                class="custome-font"
                :class="
                  propFootprintIds.includes(item.icr.id)
                    ? 'custome-polygon-blue'
                    : 'custome-polygon-black'
                "
              ></i>
            </button>
            <button>
              <i class="fas fa-crosshairs" @click="pan2Location(item)"></i>
            </button>
            <div class="dropdown">
              <button
                type="button"
                id="dropdownMenuButtonActions"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-ellipsis-v"></i>
              </button>
              <div
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButtonActions"
              >
                <a class="dropdown-item" @click="showDetail(item)">Detail</a>
                <a class="dropdown-item" @click="cancelTasking(item)">Cancel</a>
              </div>
            </div>
          </div>
        </div>
        <h6 class="customer-reference font-weight-bold">
          {{ item.icr.customerRef }}
        </h6>
        <ul class="list-group">
          <li class="list-group-item">
            <span
              ><i class="fad fa-spinner-third"></i
              >{{
                getDateTimeAttempt(item.icr.progTypeName, item.icr.attempts)
              }}</span
            >
          </li>
          <li class="list-group-item">
            <span>{{ item.icr.id }}&nbsp;|&nbsp;{{ item.icr.aoiName }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { TileSpinner } from "vue-spinners";
import moment from "moment";
export default {
  components: { TileSpinner },
  props: {
    propTabsHeight: {
      type: Number,
      default: 0,
    },
    propData: {
      type: Object,
      default: () => {},
    },
    propFootprintIds: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (
        scrollTop > 0 &&
        Math.ceil(scrollTop + clientHeight) >= scrollHeight
      ) {
        if (!this.propData.isBottom) {
          this.$emit("pagechanged", {
            tab: "INPROGRESS",
            val: this.propData.page + 1,
          });
        }
      }
    },
    getDateTimeAttempt(type, attempt) {
      if (!attempt) return null;
      if (type.toLowerCase() === "oneday") {
        let array = attempt.split(" ");
        if (array && array.length > 0) {
          try {
            moment.locale(this.lang);
            let date = new Date(array[0]);
            return moment(date).utc().format("lll");
          } catch (error) {}
          return null;
        }
      }
    },
    showDetail(item) {
      this.$emit("showDetail", item);
    },
    cancelTasking(item) {
      this.$emit("cancelTasking", item);
    },
    mouseOverItem(item) {
      this.$emit("mouseOverItem", item);
    },
    mouseLeaveItem(item) {
      this.$emit("mouseLeaveItem", item);
    },
    addFootprint(item) {
      this.$emit("addFootprint", item);
    },
    pan2Location(item) {
      this.$emit("pan2Location", item);
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.inprogress-tab {
  overflow-y: auto;
  position: relative;
  .Iybw1Bhj3Jhd8s {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
    .ehAbnmg4Jwb9BS {
      display: table-cell;
      vertical-align: middle;
    }
  }
  .media {
    padding: 0.5rem 0;
    border-bottom: 1px dotted #eee;
    background-color: white;
    &:hover {
      cursor: pointer;
      background-color: rgba($color: #c7e7f9, $alpha: 0.5);
    }
    .media-body {
      .title {
        color: #666;
        .tasking-type {
          color: #009ef7;
        }
        .actions {
          display: flex;
          gap: 0 0.25rem;
          button {
            border: 0;
            background-color: transparent;
            outline: none;
          }
          .dropdown {
            a {
              font-size: 14px;
              color: #333;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
      p {
        margin: 0;
      }
      h6.customer-reference {
        margin-bottom: 0;
        color: #333;
      }
      .list-group {
        background-color: transparent;
        .list-group-item {
          border: none;
          padding: 0;
          background-color: transparent;
          span {
            color: #666;
            font-size: 13px;
          }
          a.dropdown-item {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
