<template>
  <Dialog
    id="dialogOrderDetail"
    :header="$t('header_detail')"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <OrderInfo :prop-item="propOrder" />
    <AoiInfo :prop-item="propOrder" :prop-images="propOrder.images" />
    <div class="order-images">
      <h6 class="px-2 font-weight-bold m-0" style="font-size: 14px">
        {{ $t("label_order_images") }}
      </h6>
      <div v-show="planetImages && planetImages.length">
        <PlanetImages
          :propItems="planetImages"
          :propImageTypes="propImageTypes"
          @showMetadata="showPlanetMetadata"
        />
      </div>
      <div v-show="oneAtlasImages && oneAtlasImages.length">
        <OneAtlasImages
          :propItems="oneAtlasImages"
          :propImageTypes="propImageTypes"
          @showMetadata="showOneAtlasMetadata"
        />
      </div>
      <div v-show="capellaImages && capellaImages.length">
        <CapellaImages
          :propItems="capellaImages"
          :propImageTypes="propImageTypes"
          @showMetadata="showCapellaMetadata"
        />
      </div>
      <div v-show="propItems && propItems.length">
        <GeohubImages
          :propOrder="propOrder"
          :propItems="propItems"
          :propImageTypes="propImageTypes"
          @showMetadata="showMetadata"
        />
      </div>
    </div>
    <div class="received-images" v-if="sharedImages && sharedImages.length > 0">
      <CommonAccordion
        :isChevronEnd="true"
        :propTitle="$t('label_received_images')"
        ><div slot="content" class="accordion w-100">
          <div class="datatable">
            <ContextMenu
              :model="menuModel"
              ref="cmDlgOrderDetailDatatable"
              :appendTo="'dialogOrderDetail'"
            />
            <DataTable
              :value="sharedImages"
              ref="dt"
              :resizableColumns="true"
              columnResizeMode="expand"
              selectionMode="single"
              dataKey="id"
              contextMenu
              :selection.sync="selected2"
              @row-contextmenu="onRowContextMenu2"
              @row-click="rowClick2"
            >
              <Column>
                <template #body="slotProps">
                  <div class="d-flex">
                    <div class="media" style="width: calc(100% - 104px)">
                      <icon-preview
                        :propId="
                          slotProps.data.image &&
                          slotProps.data.image.preview &&
                          slotProps.data.image.preview.icon_path
                            ? slotProps.data.image.preview.icon_path
                            : slotProps.data.image &&
                              slotProps.data.image.preview &&
                              slotProps.data.image.preview.preview_path
                            ? slotProps.data.image.preview.preview_path
                            : null
                        "
                        :prop-src="
                          slotProps.data.image &&
                          slotProps.data.image.preview &&
                          slotProps.data.image.preview.icon_path
                            ? slotProps.data.image.preview.icon_path
                            : slotProps.data.image &&
                              slotProps.data.image.preview &&
                              slotProps.data.image.preview.preview_path
                            ? slotProps.data.image.preview.preview_path
                            : null
                        "
                        :propPreviewImages="
                          slotProps.data.image &&
                          slotProps.data.image.preview &&
                          slotProps.data.image.preview.preview_images
                            ? slotProps.data.image.preview.preview_images
                            : []
                        "
                      ></icon-preview>
                      <div class="media-body">
                        <ul>
                          <li class="lbl-geostore">
                            <strong>{{
                              slotProps.data &&
                              slotProps.data.image &&
                              slotProps.data.image.name
                                ? slotProps.data.image.name
                                : null
                            }}</strong>
                          </li>
                          <li class="mt-1">
                            <div class="">
                              <span
                                ><strong>{{ $t("label_image_time") }}:</strong>
                                {{
                                  formatUnixDateTime2String(
                                    slotProps.data.image &&
                                      slotProps.data.image.info &&
                                      slotProps.data.image.info.imaging_date
                                  )
                                }}</span
                              >
                              <span class="pl-2 pr-2">|</span>
                              <span
                                ><strong>{{ $t("label_satellite") }}: </strong>
                                {{
                                  slotProps.data.image &&
                                  slotProps.data.image.info &&
                                  slotProps.data.image.info.satellite
                                    ? slotProps.data.image.info.satellite.value
                                    : ""
                                }}</span
                              >
                            </div>
                          </li>
                          <ItemMetadata :prop-item="slotProps.data.image" />
                        </ul>
                      </div>
                    </div>
                    <div style="width: 104px">
                      <button
                        :disabled="
                          propButtonDownload &&
                          propButtonDownload.disabled &&
                          propButtonDownload.ids.includes(
                            slotProps.data.image
                              ? slotProps.data.image.id
                              : null
                          )
                        "
                        class="btn btn-primary btn-sm"
                        @click="
                          downloadImage(
                            slotProps.data.image
                              ? slotProps.data.image.id
                              : null
                          )
                        "
                      >
                        <i
                          :class="
                            propButtonDownload &&
                            propButtonDownload.processing &&
                            propButtonDownload.ids.includes(
                              slotProps.data.image
                                ? slotProps.data.image.id
                                : null
                            )
                              ? 'fas fa-spinner fa-spin'
                              : 'fas fa-download'
                          "
                        ></i
                        >&nbsp;{{ $t("button_text_download") }}
                      </button>
                    </div>
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </CommonAccordion>
    </div>
    <template #footer>
      <Button
        :label="$t('button_text_close')"
        :icon="'fas fa-times'"
        :class="'p-button-danger mr-0'"
        @click="onClose"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import IconPreview from "./dialogorderdetail/geohub/IconPreview.vue";
import ItemMetadata from "./dialogorderdetail/geohub/ItemMetadata.vue";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import OrderInfo from "./detail/OrderInfo";
import BuyerInfo from "./detail/BuyerInfo";
import AoiInfo from "./detail/AoiInfo";
import moment from "moment";

import PlanetImages from "./dialogorderdetail/PlanetImages";
import OneAtlasImages from "./dialogorderdetail/OneAtlasImages";
import CapellaImages from "./dialogorderdetail/CapellaImages";
import GeohubImages from "./dialogorderdetail/GeohubImages";

import capellaFunc from "@/utils/functions/capella";
import oneAtlasFunc from "@/utils/functions/oneatlas";
import planetFunc from "@/utils/functions/planet";
import cryptoFunc from "@/utils/functions/crypto";

export default {
  components: {
    Dialog,
    Button,
    IconPreview,
    ItemMetadata,
    DataTable,
    Column,
    ContextMenu,
    CommonAccordion,
    OrderInfo,
    BuyerInfo,
    AoiInfo,
    PlanetImages,
    OneAtlasImages,
    CapellaImages,
    GeohubImages,
  },
  props: {
    propOrder: {
      type: Object,
      default: () => {},
    },
    propItems: {
      type: Array,
      default: () => [],
    },
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "480px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propButtonDownload: {
      type: Object,
      default: () => {},
    },
    propImageTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: null,
      selected2: null,
      menuModel: [
        {
          label: "Metadata",
          icon: "fas fa-list-alt",
          command: () =>
            this.showMetadata(this.selected ? this.selected.image : null),
          visible: true,
        },
      ],
      time: new Date().getTime(),
      oneAtlasImages: [],
      planetImages: [],
      capellaImages: [],
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = val;
        if (this.show) {
          this.getOneAtlasImages();
          this.getPlanetImages();
          this.getCapellaImages();
        }
      },
      immediate: true,
    },
  },
  methods: {
    showOneAtlasMetadata(data) {
      this.$emit("showOneAtlasMetadata", data);
    },
    showPlanetMetadata(data) {
      this.$emit("showPlanetMetadata", data);
    },
    showCapellaMetadata(data) {
      this.$emit("showCapellaMetadata", data);
    },
    showMetadata(data) {
      this.$emit("showMetadata", data);
    },
    async getOneAtlasImages() {
      if (
        this.propOrder &&
        this.propOrder.image_ids &&
        this.propOrder.image_ids.one_atlas &&
        this.propOrder.image_ids.one_atlas.length
      ) {
        let response = await oneAtlasFunc.searchByIds(
          this.propOrder.image_ids.one_atlas,
          this.$store.getters.getAccessToken
        );
        if (response && response.status == 200) {
          this.oneAtlasImages = response?.data?.data?.oneAtlas?.results;
        }
      }
    },
    async getPlanetImages() {
      if (
        this.propOrder &&
        this.propOrder.image_ids &&
        this.propOrder.image_ids.planet &&
        this.propOrder.image_ids.planet.length
      ) {
        let response = await planetFunc.searchByIds(
          this.propOrder.image_ids.planet,
          this.$store.getters.getAccessToken
        );
        if (response && response.status == 200) {
          this.planetImages = response?.data?.data?.planet?.results;
        }
      }
    },
    async getCapellaImages() {
      if (
        this.propOrder &&
        this.propOrder.image_ids &&
        this.propOrder.image_ids.capella &&
        this.propOrder.image_ids.capella.length
      ) {
        setTimeout(async () => {
          let response = await capellaFunc.searchByIds(
            this.propOrder.image_ids.capella,
            this.$store.getters.getAccessToken
          );
          if (response && response.status == 200) {
            this.capellaImages = response?.data?.data?.capella?.results;
          }
        }, 100);
      }
    },
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    showMetadata(item) {
      this.$emit("showMetadata", item);
    },
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
    downloadImage(id) {
      this.$emit("onDownloadImage", {
        image_id: id,
      });
    },
  },
  computed: {
    sharedImages() {
      let results = [];
      if (this.propItems && this.propItems.length > 0) {
        let items = [];
        // let items = this.propItems.filter(
        //   (x) => x && x.image && x.user_add != this.propOrder.created_by
        // );
        for (let i = 0; i < this.propItems.length; i++) {
          if (
            this.propItems[i] &&
            this.propItems[i].image &&
            this.propItems[i].user_add != this.propOrder.created_by
          ) {
            if (
              items.findIndex(
                (x) => x.image.id === this.propItems[i].image.id
              ) < 0
            )
              items.push(this.propItems[i]);
          }
        }
        results = items.filter(
          (x) =>
            x.image &&
            this.propOrder.shared &&
            this.propOrder.shared.map((y) => y.image_id).includes(x.image.id) &&
            this.propOrder.shared
              .map((y) => y.order_id)
              .includes(this.propOrder.id)
        );
      }
      return results;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.list-group {
  overflow-y: auto;
}
.list-group-item {
  padding: 0.5rem;
  border: 0;
  border-radius: 0;
  border-top: 1px solid rgba($color: $background-color, $alpha: 0.4);
  &:first-child {
    border-top: 0;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba($color: #c7e7f9, $alpha: 0.5);
  }
}
.media {
  position: relative;
  img {
    border: 1px solid rgba($color: $background-color, $alpha: 0.5);
    width: 64px;
    height: 64px;
    &:hover {
      cursor: pointer;
      border: 1px solid rgba($color: $background-color, $alpha: 0.8);
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 13px;
  }
  button.remove-item {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
</style>
<style lang="scss">
.my-order__dialog {
  .datatable {
    .p-datatable-thead {
      display: none;
    }
  }
}
.order-images {
  .accordion {
    padding: 0.5rem;
    .accordion-header a {
      padding-left: 0;
    }
  }
}
.received-images {
  .accordion {
    padding: 0.5rem;
    .accordion-header a {
      padding-left: 0;
    }
  }
}
</style>
