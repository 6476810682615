<template>
  <div class="tasking-time__optical">
    <div class="date-range">
      <div class="row no-gutters">
        <div class="col">
          <label
            >{{ $t("label_request_collection_date_range") }}&nbsp;<span
              class="required"
              >*</span
            ></label
          >
        </div>
        <div class="col">
          <label style="width: 64px" class="range-time-label">{{
            $t("label_from")
          }}</label>
          <date-picker
            style="width: calc(100% - 64px)"
            :format="'DD/MM/YYYY'"
            valuetype="timestamp"
            :input-class="'form-control'"
            v-model="propEntity.optical.start"
            :disabled-date="notBeforeToday"
            :disabled-time="notBeforeTodayTimeClock"
          >
          </date-picker>
        </div>
        <div class="col">
          <label style="width: 64px" class="range-time-label">{{
            $t("label_to")
          }}</label>
          <date-picker
            style="width: calc(100% - 64px)"
            :format="'DD/MM/YYYY'"
            valuetype="timestamp"
            :input-class="'form-control'"
            v-model="propEntity.optical.end"
            :disabled-date="notBeforeToday"
            :disabled-time="notBeforeTodayTimeClock"
          >
          </date-picker>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-4">
          <label
            >{{ $t("label_collection_order_type") }}&nbsp;<span class="required"
              >*</span
            ></label
          >
        </div>
        <div class="col-8">
          <Dropdown
            class="new-style w-100"
            :options="propOrderTypes"
            v-model="propEntity.optical.order_type"
            :showClear="false"
            :filter="false"
            optionValue="id"
            optionLabel="value"
            @change="orderTypeChange"
          />
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col">
          <label class="col-form-label"
            >{{ $t("label_incidence_angle") }} (°)</label
          >
        </div>
        <div class="col d-flex">
          <label style="width: 64px" class="range-time-label">{{
            $t("label_from")
          }}</label>
          <AutoComplete
            :style="{ width: 'calc(100% - 64px)' }"
            v-model="propEntity.optical.inc_angle_from"
            :suggestions="suggestionsIncAngleFrom"
            @complete="searchIncAngleFrom($event)"
            :dropdown="false"
            class="new-style"
            placeholder="[0-90]"
            :inputClass="'w-100'"
            id="inputIncAngleFrom"
          />
        </div>
        <div class="col d-flex">
          <label style="width: 64px" class="range-time-label">{{
            $t("label_to")
          }}</label>
          <AutoComplete
            :style="{ width: 'calc(100% - 64px)' }"
            v-model="propEntity.optical.inc_angle_to"
            :suggestions="suggestionsIncAngleTo"
            @complete="searchIncAngleTo($event)"
            :dropdown="false"
            class="new-style"
            placeholder="[0-90]"
            :inputClass="'w-100'"
            id="inputIncAngleTo"
          />
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-4">
          <label class="col-form-label">{{ $t("label_cloud_cove") }} (%)</label>
        </div>
        <!-- <div class="col d-flex">
          <label style="width: 64px" class="range-time-label">Từ</label>
          <AutoComplete
            :style="{ width: 'calc(100% - 64px)' }"
            v-model="propEntity.optical.cloud_coverage_from"
            :suggestions="suggestionsCloudCoverageFrom"
            @complete="searchCloudCoverageFrom($event)"
            :dropdown="false"
            class="new-style"
            placeholder="[0-100]"
            :inputClass="'w-100'"
            id="inputCloudCoverageFrom"
          />
        </div> -->
        <div class="col-8 d-flex" style="padding-left: 0.075rem">
          <label style="width: 64px" class="range-time-label">{{
            $t("label_to")
          }}</label>
          <AutoComplete
            :style="{ width: 'calc(100% - 64px)' }"
            v-model="propEntity.optical.cloud_coverage_to"
            :suggestions="suggestionsCloudCoverageTo"
            @complete="searchCloudCoverageTo($event)"
            :dropdown="false"
            class="new-style"
            placeholder="[0-100]"
            :inputClass="'w-100'"
            id="inputCloudCoverageTo"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import InputText from "@/components/commons/inputtext/InputText";
import RadioButton from "@/components/commons/radiobutton/RadioButton";
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import AutoComplete from "@/components/commons/autocomplete/AutoComplete";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import { change_alias } from "@/utils/commons/common";
export default {
  components: {
    DatePicker,
    InputText,
    CommonAccordion,
    RadioButton,
    AutoComplete,
    Dropdown,
  },
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },
    propOrderTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      arrayIncAngle: [],
      suggestionsIncAngleFrom: [],
      suggestionsIncAngleTo: [],

      arrayCloudCoverage: [],
      suggestionsCloudCoverageFrom: [],
      suggestionsCloudCoverageTo: [],
    };
  },
  created() {
    for (let i = 0; i <= 90; i++) {
      if (i < 10)
        this.arrayIncAngle.push({
          id: "0" + i,
          value: "0" + i,
          label: "0" + i,
        });
      else
        this.arrayIncAngle.push({
          id: "" + i,
          value: "" + i,
          label: "" + i,
        });
    }
    for (let i = 0; i <= 100; i++) {
      if (i < 10)
        this.arrayCloudCoverage.push({
          id: "0" + i,
          value: "0" + i,
          label: "0" + i,
        });
      else
        this.arrayCloudCoverage.push({
          id: "" + i,
          value: "" + i,
          label: "" + i,
        });
    }
  },
  mounted() {
    this.setInputFilter(
      document.getElementById("inputIncAngleFrom"),
      function (value) {
        return (
          /^\d*$/.test(value) &&
          (value === "" || (parseInt(value) >= 0 && parseInt(value) <= 90))
        );
      }
    );
    this.setInputFilter(
      document.getElementById("inputIncAngleTo"),
      function (value) {
        return (
          /^\d*$/.test(value) &&
          (value === "" || (parseInt(value) >= 0 && parseInt(value) <= 90))
        );
      }
    );

    // this.setInputFilter(
    //   document.getElementById("inputCloudCoverageFrom"),
    //   function(value) {
    //     return (
    //       /^\d*$/.test(value) &&
    //       (value === "" || (parseInt(value) >= 0 && parseInt(value) <= 100))
    //     );
    //   }
    // );
    this.setInputFilter(
      document.getElementById("inputCloudCoverageTo"),
      function (value) {
        return (
          /^\d*$/.test(value) &&
          (value === "" || (parseInt(value) >= 0 && parseInt(value) <= 100))
        );
      }
    );
  },
  methods: {
    orderTypeChange(event) {
      let item = this.propOrderTypes.find((x) => x.id === event.value);
      this.propEntity.optical.order_type_name =
        item && item.value ? item.value.trim() : null;
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    notBeforeTodayTimeClock(date) {
      let now = new Date();
      return (
        date <
        new Date(new Date().setHours(now.getHours(), now.getMinutes(), 0, 0))
      );
    },
    searchIncAngleFrom(e) {
      this.suggestionsIncAngleFrom = this.arrayIncAngle
        .filter(
          (x) =>
            change_alias(x.value.trim().split(" ").join("")).startsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            ) ||
            change_alias(x.value.trim().split(" ").join("")).endsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            )
        )
        .map((x) => x.value);
    },
    searchIncAngleTo(e) {
      this.suggestionsIncAngleTo = this.arrayIncAngle
        .filter(
          (x) =>
            change_alias(x.value.trim().split(" ").join("")).startsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            ) ||
            change_alias(x.value.trim().split(" ").join("")).endsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            )
        )
        .map((x) => x.value);
    },

    searchCloudCoverageFrom(e) {
      this.suggestionsCloudCoverageFrom = this.arrayCloudCoverage
        .filter(
          (x) =>
            change_alias(x.value.trim().split(" ").join("")).startsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            ) ||
            change_alias(x.value.trim().split(" ").join("")).endsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            )
        )
        .map((x) => x.value);
    },
    searchCloudCoverageTo(e) {
      this.suggestionsCloudCoverageTo = this.arrayCloudCoverage
        .filter(
          (x) =>
            change_alias(x.value.trim().split(" ").join("")).startsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            ) ||
            change_alias(x.value.trim().split(" ").join("")).endsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            )
        )
        .map((x) => x.value);
    },

    setInputFilter(textbox, inputFilter) {
      [
        "input",
        "keydown",
        "keyup",
        "mousedown",
        "mouseup",
        "select",
        "contextmenu",
        "drop",
      ].forEach(function (event) {
        textbox.addEventListener(event, function () {
          if (inputFilter(this.value)) {
            this.oldValue = this.value;
            this.oldSelectionStart = this.selectionStart;
            this.oldSelectionEnd = this.selectionEnd;
          } else if (this.hasOwnProperty("oldValue")) {
            this.value = this.oldValue;
            this.setSelectionRange(
              this.oldSelectionStart,
              this.oldSelectionEnd
            );
          } else {
            this.value = "";
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tasking-time__optical {
  padding: 0 0.5rem;
  .date-range {
    .row {
      padding: 0;
      margin: 0;
      margin-bottom: 1rem;
      .col {
        padding-right: 0.25rem;
        padding-left: 0.25rem;
        &:last-child {
          padding-right: 0;
        }
        &:first-child {
          padding-left: 0;
        }
      }
      .row {
        margin: 0;
      }

      .range-time-label {
        line-height: 2.105rem;
        text-align: center;
        background-color: #009ef7; //#eee;
        border: 1px solid #009ef7; //#ccc;
        border-right: 0;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        color: white;
        font-size: 14px;
        margin: 0;
      }
    }
  }
}
</style>
<style lang="scss">
.tasking-time__optical {
  .mx-input-wrapper {
    input {
      font-size: 14px;
      background: #eef3f7;
      border-radius: 4px;
      border-color: #eef3f7;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      &:hover {
        border-color: #212121 !important;
      }
      &:disabled,
      .disabled {
        background-color: transparent;
      }
    }
  }
  .accordion-header a {
    padding-left: 0 !important;
  }
}
#inputIncAngleFrom {
  width: 100%;
}
#inputIncAngleTo {
  width: 100%;
}
#inputCloudCoverageFrom {
  width: 100%;
}
#inputCloudCoverageTo {
  width: 100%;
}
</style>
