<template>
  <table>
    <tbody>
      <tr>
        <td>Coordinate system:</td>
        <td class="plaintext">{{ propCoordinateSystem }}</td>
      </tr>
    </tbody>
    <tbody
      :class="propEntity.attempts.length > 1 ? 'group' : ''"
      v-for="(attempt, index) in propEntity.attempts"
      :key="'attempt' + (index + 1)"
    >
      <tr v-if="propEntity.attempts.length > 1">
        <td class="text-left px-4" colspan="2">{{ attempt.id }}</td>
      </tr>
      <tr>
        <td>UTC order deadline:</td>
        <td class="plaintext">
          {{ attempt ? formatUTCOrderDeadline(attempt.orderDeadline) : "" }}
        </td>
      </tr>
      <tr>
        <td>UTC access to target:</td>
        <td class="plaintext">
          {{
            attempt
              ? formatUTCAcquisitionStartDate(attempt.acquisitionStartDate)
              : ""
          }}
        </td>
      </tr>
      <tr>
        <td>Viewing angle:</td>
        <td class="plaintext">
          {{
            attempt ? attempt.incidenceAngle.toFixed(2) : ""
          }}&deg;&nbsp;-&nbsp;{{ maxIncidenceAngle }}&deg;
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import moment from "moment";
import {
  maxCloudCoverOneAtlasOneDay,
  maxIncidenceAngleOneAtlasOneDay,
} from "@/utils/commons/environment";
export default {
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },
    propCoordinateSystem: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      maxIncidenceAngle: maxIncidenceAngleOneAtlasOneDay,
    };
  },
  methods: {
    formatUTCOrderDeadline(val) {
      try {
        moment.locale(this.lang);
        let date = new Date(val);
        return moment(date).utc().format("lll");
      } catch (error) {}
      return null;
    },
    formatUTCAcquisitionStartDate(val) {
      try {
        moment.locale(this.lang);
        let date = new Date(val);
        return moment(date).utc().format("lll");
      } catch (error) {}
      return null;
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.plaintext {
  font-family: "Helvetica Neue Light";
}

table {
  width: 100%;
  background-color: white;
  border-radius: 0.25rem;
  padding: 0.5rem;
  tbody {
    tr {
      td {
        padding: 0.5rem 0.75rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.075);
        &:first-child {
          text-align: right;
          width: 11rem;
        }
        &:last-child {
          padding-left: 0;
        }
        &.plaintext {
          font-family: "Helvetica Neue Light";
        }
      }
    }
    &.group {
      tr {
        td {
          //border-bottom: 0;
          padding: 0.25rem 0.5rem;
        }
        &:first-child {
          td {
            padding: 0.5rem;
            background-color: #eef3f7;
            border-top: 1px solid rgba(0, 0, 0, 0.075);
            border-bottom: 1px solid rgba(0, 0, 0, 0.075);
          }
        }
      }
    }
  }
}
</style>
