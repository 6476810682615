<template>
  <Dialog
    :header="title"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :maximizable="true"
    :modal="true"
    :contentStyle="{
      width: width + 'px',
      overflowY: !isOverflowScroll ? 'inherit!important' : 'auto',
    }"
    @hide="onClose"
    @maximizedChage="maximizedChage"
  >
    <div
      class="tasking-content"
      :style="[
        step < 3
          ? {
              minHeight: height + 'px',
              overflowY: 'auto',
            }
          : {},
      ]"
    >
      <CoordinatesComponent
        ref="coorsComponet"
        :prop-content-height="height"
        :prop-feature="entity.feature"
        v-if="step === 1"
        @updateEntity="updateEntity"
        @clearAttempts="clearAttempts"
        @message="onMessage"
      />
      <PartnersComponent
        ref="partnersComponet"
        :prop-content-height="height"
        v-else-if="step === 2"
        @updateEntity="updateEntity"
      />
      <div v-else-if="step === 3">
        <div v-if="entity.partner === 'PLANET'"></div>
        <div v-else-if="entity.partner === 'ONEATLAS'">
          <OneAtlasComponent
            ref="oneatlasComponent"
            :prop-feature="entity.feature"
            :prop-content-height="height"
            :prop-product-types="oneAtlas.productTypes"
            :prop-attempts="oneAtlas.attempts"
            :prop-product-options="oneAtlas.options"
            :prop-price="oneAtlas.price"
            :prop-delivery-types="oneAtlas.deliveryTypes"
            :prop-markets="oneAtlas.markets"
            :prop-contract="oneAtlasContract"
            :prop-product-type-selected="productTypeSelected"
            :propFeasibilityOneAtlas="oneAtlas.feasibility"
            :prop-entity="entity.oneAtlas"
            @onPrev="onPrev(true)"
            @getProductTypes="getProductTypes"
            @getAttempts="getAttempts"
            @getProductOptions="getProductOptions"
            @getDeliveryTypes="getDeliveryTypes"
            @getPrice="getPrice"
            @getMarkets="getMarkets"
            @selectedProductType="entity.oneAtlas.productType = $event"
            @selectedAttempt="selectedAttempt"
            @checkFeasibility="checkFeasibility"
            @modifyPolygon="modifyPolygon"
            @message="onMessage"
            @createOrder="createOrder"
            @changeLabelNext="changeLabelNext"
            @clearPrice="clearPrice"
          />
        </div>
        <div v-else-if="entity.partner === 'CAPELLA'"></div>
        <div v-else-if="entity.partner === 'GEOHUB'"></div>
      </div>
    </div>
    <div class="footer">
      <Button
        :label="buttons.prev.text"
        :icon="buttons.prev.icon"
        class="mr-auto float-left"
        :class="buttons.prev.class"
        :disabled="buttons.yes.disable"
        @click="onPrev(false)"
        v-if="step > 1"
      />
      <Button
        v-if="buttons.no.visible"
        :label="buttons.no.text"
        :icon="buttons.no.icon"
        class="p-button-danger p-button-text mr-2"
        :class="buttons.no.class"
        @click="onClose"
      />
      <Button
        v-if="buttons.yes.visible"
        :label="buttons.yes.text"
        :icon="buttons.yes.icon"
        :class="buttons.yes.class"
        class="mr-0"
        :disabled="buttons.yes.disable"
        @click="onNext"
      />
    </div>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import CoordinatesComponent from "./taskingnew/Coordinates";
import PartnersComponent from "./taskingnew/Partners";
import OneAtlasComponent from "./taskingnew/OneAtlas";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
import { transform } from "ol/proj";
import oneAtlasFunc from "@/utils/functions/oneatlas";
import cryptoFunc from "@/utils/functions/crypto";
import {
  maxCloudCoverOneAtlasOneDay,
  maxIncidenceAngleOneAtlasOneDay,
} from "@/utils/commons/environment";
export default {
  components: {
    Dialog,
    Button,
    CoordinatesComponent,
    PartnersComponent,
    OneAtlasComponent,
  },
  props: {
    propShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      step: 1,
      show: false,
      maximized: false,
      isOverflowScroll: true,
      title: "Tasking",
      buttons: {
        yes: {
          visible: true,
          text: this.$t("button_text_next"),
          icon: "",
          class: "btn-geostore2",
          disable: false,
          processing: false,
        },
        no: {
          visible: true,
          text: this.$t("button_text_close"),
          icon: "",
          class: "",
          disable: false,
          processing: false,
        },
        prev: {
          visible: false,
          text: this.$t("button_text_prev"),
          icon: "",
          class: "btn-prev",
        },
      },
      entity: {
        feature: null,
        partner: null,
        area: null,
        perimeter: null,
        oneAtlas: {
          startDate: new Date().getTime(),
          sort: null,
          feasibilityOneSeries: {
            sensor: null,
            temporalMode: "MULTIPERIOD",
            startDate: null,
            endDate: null,
            dateRange: [],
            numberOfObservations: 2,
            periodOfTime: 7,
            maxIncidenceAngle: 30,
            maxCloudCover: 10,
            notificationThreshold: 10,
            acquisitionMode: "MONO",
            minBH: 0.4,
            maxBH: 0.7,
          },
          feasibility: {
            type: "standard",
            sensor: null,
            startDate: null,
            endDate: null,
            maxIncidenceAngle: 30,
            maxCloudCover: 10,
            comments: null,
            capacities: [],
            customMaxIncidenceAngle: 30,
            customMaxCloudCover: 10,
            customNotificationThreshold: 10,
            customAcquisitionMode: "MONO",
            customMinBH: 0.4,
            customMaxBH: 0.7,
          },
          comment: null,
          name: null,
          payment: "purchase_order",
          deliveryType: null,
          productType: null,
          productTypeOld: null,
          attempts: [],
          segmentIdsOld: [], // For OneDay and OneNow
          segmentIds: [],
          attempt: null,
          segmentIdOld: null, // For OneDay and OneNow
          segmentId: null, // For OneDay and OneNow
          segmentKey: null, // For OneDay and OneNow
          options: [],
          primaryMarket: null,
          secondaryMarket: null,
          area: null,
          perimeter: null,
          notifications: [
            {
              name: "",
              channels: [
                {
                  type: "email",
                  parameters: {
                    addresses: [],
                  },
                },
              ],
              filter: {
                events: ["ACQUISITION_RECEIVED"],
              },
            },
          ],
          emails: [
            {
              label: "Name",
              value: "",
            },
          ],
          acceptLicenses: false,
          acceptTerms: false,
        },
      },
      contracts: [
        {
          contractId: "Vegastar_OneAtlastasking_Orders",
          customerId: "C501325",
          name: "Vegastar_OneAtlastasking_Orders",
          tradeAgreementUrl: "https://www.intelligence-airbusds.com/cgf/",
          creditRemaining: "12000.0",
          currency: "EUR",
          language: "en",
          contractType: "ONEATLASDATA",
          backendSource: "legacy",
        },
      ],

      oneAtlas: {
        feasibility: [],
        markets: [],
        deliveryTypes: [],
        price: {},
        options: [],
        attempts: [
          {
            mission: "PLEIADES",
            progTypes: [
              {
                name: "ONEDAY",
                mission: "PLEIADES",
                errors: [
                  {
                    code: "ERR_AOI_MAX_WIDTH",
                    locator: "AOI",
                    message: "18.9",
                  },
                ],
                segments: [],
                available: false,
              },
            ],
          },
        ],
        productTypes: {
          loading: false,
          items: [
            {
              productTypeId: "PleiadesTaskingOneDay",
              technicalProduct: {
                id: "PleiadesTaskingOneDay",
                label: "Pleiades OneDay",
                source: {
                  id: "tasking",
                  label: "One Tasking",
                },
                sensor: {
                  id: "pleiades",
                  label: "Pléiades",
                },
                target: {
                  id: "oneday",
                  label: "One Day",
                },
                licences: null,
              },
              minimumOrderAreaApplied: 100,
              descriptionUrl:
                "https://www.intelligence-airbusds.com/one-tasking/",
            },
            {
              productTypeId: "PleiadesTaskingOneNow",
              technicalProduct: {
                id: "PleiadesTaskingOneNow",
                label: "Pleiades OneNow",
                source: {
                  id: "tasking",
                  label: "One Tasking",
                },
                sensor: {
                  id: "pleiades",
                  label: "Pléiades",
                },
                target: {
                  id: "onenow",
                  label: "One Now",
                },
                licences: null,
              },
              minimumOrderAreaApplied: 100,
              descriptionUrl:
                "https://www.intelligence-airbusds.com/one-tasking/",
            },
            {
              productTypeId: "PleiadesTaskingOnePlan",
              technicalProduct: {
                id: "PleiadesTaskingOnePlan",
                label: "Pleiades OnePlan",
                source: {
                  id: "tasking",
                  label: "One Tasking",
                },
                sensor: {
                  id: "pleiades",
                  label: "Pléiades",
                },
                target: {
                  id: "oneplan",
                  label: "One Plan",
                },
                licences: null,
              },
              minimumOrderAreaApplied: 100,
              descriptionUrl:
                "https://www.intelligence-airbusds.com/one-tasking/",
            },
            {
              productTypeId: "PleiadesTaskingOneSeries",
              technicalProduct: {
                id: "PleiadesTaskingOneSeries",
                label: "Pleiades OneSeries",
                source: {
                  id: "tasking",
                  label: "One Tasking",
                },
                sensor: {
                  id: "pleiades",
                  label: "Pléiades",
                },
                target: {
                  id: "oneseries",
                  label: "One Series",
                },
                licences: null,
              },
              minimumOrderAreaApplied: 50,
              descriptionUrl:
                "https://www.intelligence-airbusds.com/one-tasking/",
            },
            {
              productTypeId: "SpotTaskingOneDay",
              technicalProduct: {
                id: "SpotTaskingOneDay",
                label: "SPOT OneDay",
                source: {
                  id: "tasking",
                  label: "One Tasking",
                },
                sensor: {
                  id: "spot",
                  label: "SPOT",
                },
                target: {
                  id: "oneday",
                  label: "One Day",
                },
                licences: null,
              },
              minimumOrderAreaApplied: 500,
              descriptionUrl:
                "https://www.intelligence-airbusds.com/one-tasking/",
            },
            {
              productTypeId: "SpotTaskingOneNow",
              technicalProduct: {
                id: "SpotTaskingOneNow",
                label: "SPOT OneNow",
                source: {
                  id: "tasking",
                  label: "One Tasking",
                },
                sensor: {
                  id: "spot",
                  label: "SPOT",
                },
                target: {
                  id: "onenow",
                  label: "One Now",
                },
                licences: null,
              },
              minimumOrderAreaApplied: 500,
              descriptionUrl:
                "https://www.intelligence-airbusds.com/en/4596-spot-6-tasking-services",
            },
            {
              productTypeId: "SpotTaskingOnePlan",
              technicalProduct: {
                id: "SpotTaskingOnePlan",
                label: "SPOT OnePlan",
                source: {
                  id: "tasking",
                  label: "One Tasking",
                },
                sensor: {
                  id: "spot",
                  label: "SPOT",
                },
                target: {
                  id: "oneplan",
                  label: "One Plan",
                },
                licences: null,
              },
              minimumOrderAreaApplied: 500,
              descriptionUrl:
                "https://www.intelligence-airbusds.com/one-tasking/",
            },
            {
              productTypeId: "SpotTaskingOneSeries",
              technicalProduct: {
                id: "SpotTaskingOneSeries",
                label: "SPOT OneSeries",
                source: {
                  id: "tasking",
                  label: "One Tasking",
                },
                sensor: {
                  id: "spot",
                  label: "SPOT",
                },
                target: {
                  id: "oneseries",
                  label: "One Series",
                },
                licences: null,
              },
              minimumOrderAreaApplied: 250,
              descriptionUrl:
                "https://www.intelligence-airbusds.com/one-tasking/",
            },
          ],
        },
      },
    };
  },
  async created() {
    this.show = this.propShow;
    const d = new Date();
    this.entity.oneAtlas.name =
      "My Order - " +
      d.toLocaleString("default", { month: "short" }) +
      " " +
      d.getDate() +
      ", " +
      d.getFullYear();
    this.entity.oneAtlas.startDate = d.getTime();
  },
  async mounted() {
    if (this.coordinates && this.coordinates.length) {
      let points = [];
      for (var i = 0; i < this.coordinates.length; i++) {
        points[i] = transform(
          [this.coordinates[i].lng, this.coordinates[i].lat],
          "EPSG:4326",
          "EPSG:3857"
        );
      }
      if (
        points[0][0] != points[points.length - 1][0] &&
        points[0][1] != points[points.length - 1][1]
      )
        points.push(points[0]);
      this.entity.feature = new Feature({
        geometry: new Polygon([points]),
        id: "featureTasking",
      });
      this.entity.feature.set("id", "featureTasking");
      this.entity.feature.id = "featureTasking";
      this.entity.feature.id_ = "featureTasking";
    }
  },
  watch: {
    propShow(val) {
      this.show = val;
    },
    step(val) {
      switch (val) {
        case 1:
          this.buttons.yes.text = this.$t("button_text_next");
          this.buttons.no.text = this.$t("button_text_cancel");
          break;
        case 2:
          break;
        case 3:
          break;
        case 4:
          break;
        case 5:
          break;
        default:
          break;
      }
    },
    "entity.area"(val) {
      this.entity.oneAtlas.area = val;
    },
    "entity.perimeter"(val) {
      this.entity.oneAtlas.perimeter = val;
    },
    "entity.attempts"(newVal, OldVal) {},
  },
  methods: {
    onMessage(msg) {
      this.$emit("message", msg);
    },
    maximizedChage(val) {
      this.maximized = val;
      if (this.$refs.coorsComponet) this.$refs.coorsComponet.refrestMap();
    },
    onClose() {
      this.$emit("close");
      this.show = false;
      this.$store.dispatch("setIsTasking", false);
    },
    async onNext() {
      if (this.step === 1) {
        if (
          !this.entity.feature ||
          Object.keys(this.entity.feature).length === 0
        ) {
          this.$emit("message", ["Please draw your area of interest"]);
          return;
        }
      }
      if (this.entity.partner === "ONEATLAS" && this.step === 2) {
        if (this.contracts && this.contracts.length === 0) {
          if (this.$store.getters.checkOneAtlasTokenExpired) {
            this.$store.dispatch("clearOneAtlasToken");
            let r = await this.getOneAtlasTokenData();
            if (!r) return;
          }

          await this.getContracts();
        }
        if (this.oneAtlasData || Object.keys(this.oneAtlasData).length === 0) {
          let r = await this.getOneAtlasUserInfo();
        }
      }

      if (this.step < 3) this.step++;

      if (this.entity.partner === "ONEATLAS" && this.step === 3) {
        if (this.$refs.oneatlasComponent) {
          this.$refs.oneatlasComponent.onNext();
        }
      }
      //console.log(this.entity);
    },
    onPrev(forback = false) {
      if (this.step < 3 || forback) {
        if (this.step === 1) return;
        this.step--;
      } else {
        if (this.entity.partner === "ONEATLAS") {
          if (this.$refs.oneatlasComponent) {
            this.$refs.oneatlasComponent.onPrev();
          }
        }
      }
    },
    clearPrice() {
      this.oneAtlas.price = {};
    },
    clearAttempts() {
      this.entity.oneAtlas.attempts = [];
      this.entity.oneAtlas.segmentIdsOld = [];
      this.entity.oneAtlas.segmentIds = [];
      this.entity.oneAtlas.attempt = null;
      this.entity.oneAtlas.segmentIdOld = null;
      this.entity.oneAtlas.segmentId = null;
      this.entity.oneAtlas.segmentKey = null;
      this.oneAtlas.attempts = [];
    },
    updateEntity(data) {
      this.entity[data.field] = data.value;
    },
    // For OneAtlas
    modifyPolygon() {
      this.step = 1;
    },
    changeLabelNext(txt) {
      this.buttons.yes.text = txt;
    },
    async createOrder() {
      if (!this.buttons.yes.data_loading) {
        this.buttons.yes.disable = true;
        this.buttons.yes.processing = true;
        this.buttons.yes.icon = "fas fa-spinner fa-spin";
        this.buttons.yes.data_loading = "oneatlas_create_order";
      }
      try {
        let coors = this.entity.feature.getGeometry().getCoordinates();
        let coordinates4Api = [];
        for (let i = 0; i < coors[0].length; i++) {
          coordinates4Api.push(
            transform(coors[0][i], "EPSG:3857", "EPSG:4326")
          );
        }
        let payload = {
          aoi: [
            {
              id: 1,
              name: "Polygon 1",
              geometry: {
                type: "Polygon",
                coordinates: [coordinates4Api],
              },
            },
          ],
          contractId: this.oneAtlasContractId,
          items: [],
          primaryMarket: this.entity.oneAtlas.primaryMarket,
          secondaryMarket: this.entity.oneAtlas.secondaryMarket,
          customerReference: "Buon Kuop",
          delivery: {
            type: this.entity.oneAtlas.deliveryType,
            workspace: this.oneAtlasWorkspaceId,
          },
          optionsPerProductType: [
            {
              licence: this.optionLicenseLabel,
              productTypeId: this.productTypeSelected
                ? this.productTypeSelected.productTypeId
                : null,
              options: [],
            },
          ],
        };
        if (
          this.entity.oneAtlas.options &&
          this.entity.oneAtlas.options.length
        ) {
          for (let i = 0; i < this.entity.oneAtlas.options.length; i++) {
            payload.optionsPerProductType[0].options.push({
              key: this.entity.oneAtlas.options[i].name,
              value: this.entity.oneAtlas.options[i].selectedValue,
            });
          }
        }
        if (
          payload.optionsPerProductType[0].options.findIndex(
            (x) => x.key === "projection_1"
          ) < 0
        ) {
          payload.optionsPerProductType[0].options.push({
            key: "projection_1",
            value: "4326",
          });
        }
        if (["oneday", "onenow"].includes(this.target)) {
          if (
            this.entity.oneAtlas.attempts &&
            this.entity.oneAtlas.attempts.length
          ) {
            for (let i = 0; i < this.entity.oneAtlas.attempts.length; i++) {
              let obj = {
                notifications: [
                  {
                    name: "",
                    channels: [
                      {
                        type: "email",
                        parameters: {
                          addresses: [],
                        },
                      },
                    ],
                    filter: {
                      events: ["ACQUISITION_RECEIVED"],
                    },
                  },
                ],
                productTypeId: this.productTypeSelected
                  ? this.productTypeSelected.productTypeId
                  : null,
                aoiId: 1,
                properties: [],
                comments: this.entity.oneAtlas.name,
                segmentKey: this.entity.oneAtlas.attempts[i].segmentKey,
              };
              obj.properties.push({
                key: "extendedAngle",
                value: this.entity.oneAtlas.attempts[i].extendedAngle,
              });
              obj.properties.push({
                key: "orderDeadLine",
                value: this.entity.oneAtlas.attempts[i].orderDeadline,
              });
              obj.properties.push({
                key: "maxCloudCover",
                value: maxCloudCoverOneAtlasOneDay,
              });
              if (
                this.productTypeSelected &&
                Object.keys(this.productTypeSelected).length > 0
              ) {
                obj.properties.push({
                  key: "comments",
                  value:
                    this.productTypeSelected.technicalProduct.label +
                    " - check if properties relevant between segmentKey",
                });
              }

              if (
                this.entity.oneAtlas.emails &&
                this.entity.oneAtlas.emails.filter(
                  (x) => x.value && x.value.trim()
                ).length > 0
              ) {
                for (let i = 0; i < this.entity.oneAtlas.emails.length; i++) {
                  if (
                    this.entity.oneAtlas.emails[i].value &&
                    this.entity.oneAtlas.emails[i].value.trim()
                  ) {
                    obj.notifications[0].channels[0].parameters.addresses.push(
                      this.entity.oneAtlas.emails[i].value.trim()
                    );
                  }
                }
              }
              payload.items.push(obj);
            }
          }
        } else if (["oneplan"].includes(this.target)) {
          let obj = {
            notifications: [
              {
                name: "",
                channels: [
                  {
                    type: "email",
                    parameters: {
                      addresses: [],
                    },
                  },
                ],
                filter: {
                  events: ["ACQUISITION_RECEIVED"],
                },
              },
            ],
            productTypeId: this.productTypeSelected
              ? this.productTypeSelected.productTypeId
              : null,
            dataSourceIds: [],
            aoiId: 1,
            properties: [],
            comments: this.entity.oneAtlas.feasibility.comments
              ? this.entity.oneAtlas.feasibility.comments.trim()
              : null,
          };
          if (this.entity.oneAtlas.feasibility.type === "standard") {
            obj.properties.push({
              key: "acquisitionMode",
              value: "MONO",
            });
            obj.properties.push({
              key: "maxCloudCover",
              value: this.entity.oneAtlas.feasibility.maxCloudCover,
            });
            obj.properties.push({
              key: "maxIncidenceAngle",
              value: this.entity.oneAtlas.feasibility.maxIncidenceAngle,
            });
          } else if (this.entity.oneAtlas.feasibility.type === "custom") {
            obj.properties.push({
              key: "acquisitionMode",
              value: this.entity.oneAtlas.feasibility.customAcquisitionMode,
            });
            obj.properties.push({
              key: "maxIncidenceAngle",
              value: this.entity.oneAtlas.feasibility.customMaxIncidenceAngle,
            });
            obj.properties.push({
              key: "maxCloudCover",
              value: this.entity.oneAtlas.feasibility.customMaxCloudCover,
            });
            obj.properties.push({
              key: "notificationThreshold",
              value:
                this.entity.oneAtlas.feasibility.customNotificationThreshold,
            });
            if (
              ["STEREO", "TRISTEREO"].includes(
                this.entity.oneAtlas.feasibility.customAcquisitionMode
                  ? this.entity.oneAtlas.feasibility.customAcquisitionMode
                  : null
              )
            ) {
              if (this.entity.oneAtlas.feasibility.customMinBH) {
                obj.properties.push({
                  key: "minBH",
                  value: this.entity.oneAtlas.feasibility.customMinBH,
                });

                obj.properties.push({
                  key: "acquisitionModeMinBH",
                  value: this.entity.oneAtlas.feasibility.customMinBH,
                });
              }
              if (this.entity.oneAtlas.feasibility.customMaxBH) {
                obj.properties.push({
                  key: "maxBH",
                  value: this.entity.oneAtlas.feasibility.customMaxBH,
                });

                obj.properties.push({
                  key: "acquisitionModeMaxBH",
                  value: this.entity.oneAtlas.feasibility.customMaxBH,
                });
              }
            }
          }
          if (
            this.entity.oneAtlas.feasibility.startDate &&
            this.entity.oneAtlas.feasibility.endDate
          ) {
            let start = new Date(this.entity.oneAtlas.feasibility.startDate);
            let end = new Date(this.entity.oneAtlas.feasibility.endDate);
            let startMonth = start.getUTCMonth() + 1;
            let startD = start.getUTCDate();
            let startHour = start.getUTCHours();
            let startMinute = start.getUTCMinutes();
            let startSecond = start.getUTCSeconds();
            let startMiniSecond = start.getUTCMilliseconds();
            if ((startMiniSecond + "").length === 1)
              startMiniSecond = "00" + startMiniSecond;
            else if ((startMiniSecond + "").length === 2)
              startMiniSecond = "0" + startMiniSecond;
            if (startHour < 10) startHour = "0" + startHour;
            if (startMinute < 10) startMinute = "0" + startMinute;
            if (startSecond < 10) startSecond = "0" + startSecond;
            if (startMonth < 10) startMonth = "0" + startMonth;
            if (startD < 10) startD = "0" + startD;
            let strStart =
              start.getUTCFullYear() +
              "-" +
              startMonth +
              "-" +
              startD +
              "T" +
              startHour +
              ":" +
              startMinute +
              ":" +
              startSecond +
              "." +
              startMiniSecond +
              "Z";
            let endMonth = end.getUTCMonth() + 1;
            let endD = end.getUTCDate();
            let endHour = end.getUTCHours();
            let endMinute = end.getUTCMinutes();
            let endSecond = end.getUTCSeconds();
            let endMiniSecond = end.getUTCMilliseconds();
            if ((endMiniSecond + "").length === 1)
              endMiniSecond = "00" + endMiniSecond;
            else if ((endMiniSecond + "").length === 2)
              endMiniSecond = "0" + endMiniSecond;
            if (endHour < 10) endHour = "0" + endHour;
            if (endMinute < 10) endMinute = "0" + endMinute;
            if (endSecond < 10) endSecond = "0" + endSecond;
            if (endMonth < 10) endMonth = "0" + endMonth;
            if (endD < 10) endD = "0" + endD;
            let strEnd =
              end.getUTCFullYear() +
              "-" +
              endMonth +
              "-" +
              endD +
              "T" +
              endHour +
              ":" +
              endMinute +
              ":" +
              endSecond +
              "." +
              endMiniSecond +
              "Z";
            obj.properties.push({
              key: "acqPeriod",
              value: strStart + " " + strEnd,
            });
          }
          if (
            this.productTypeSelected &&
            Object.keys(this.productTypeSelected).length > 0
          ) {
            obj.properties.push({
              key: "comments",
              value: this.productTypeSelected.technicalProduct.label,
            });
          }
          if (
            this.oneAtlas.feasibility &&
            this.oneAtlas.feasibility.length > 0
          ) {
            if (
              this.oneAtlas.feasibility[0].progTypes &&
              this.oneAtlas.feasibility[0].progTypes.length > 0
            ) {
              if (
                this.oneAtlas.feasibility[0].progTypes[0].feasibility &&
                Object.keys(
                  this.oneAtlas.feasibility[0].progTypes[0].feasibility
                ).length > 0
              ) {
                if (
                  this.oneAtlas.feasibility[0].progTypes[0].feasibility
                    .classification
                )
                  obj.properties.push({
                    key: "classification",
                    value: this.oneAtlas.feasibility[0].progTypes[0].feasibility
                      .classification
                      ? this.oneAtlas.feasibility[0].progTypes[0].feasibility
                          .classification
                      : null,
                  });
                if (
                  this.oneAtlas.feasibility[0].progTypes[0].feasibility
                    .automation
                )
                  obj.properties.push({
                    key: "feasibilityAutomation",
                    value: this.oneAtlas.feasibility[0].progTypes[0].feasibility
                      .automation
                      ? this.oneAtlas.feasibility[0].progTypes[0].feasibility
                          .automation
                      : null,
                  });
                if (
                  this.oneAtlas.feasibility[0].progTypes[0].feasibility
                    .automationName
                )
                  obj.properties.push({
                    key: "feasibilityAutomationName",
                    value: this.oneAtlas.feasibility[0].progTypes[0].feasibility
                      .automationName
                      ? this.oneAtlas.feasibility[0].progTypes[0].feasibility
                          .automationName
                      : null,
                  });
              }
            }
          }
          if (
            this.entity.oneAtlas.emails &&
            this.entity.oneAtlas.emails.filter((x) => x.value && x.value.trim())
              .length > 0
          ) {
            for (let i = 0; i < this.entity.oneAtlas.emails.length; i++) {
              if (
                this.entity.oneAtlas.emails[i].value &&
                this.entity.oneAtlas.emails[i].value.trim()
              ) {
                obj.notifications[0].channels[0].parameters.addresses.push(
                  this.entity.oneAtlas.emails[i].value.trim()
                );
              }
            }
          }
          payload.items.push(obj);
        } else if (["oneseries"].includes(this.target)) {
          let obj = {
            productTypeId: this.productTypeSelected
              ? this.productTypeSelected.productTypeId
              : null,
            dataSourceIds: [],
            aoiId: 1,
            properties: [],
            comments: this.entity.oneAtlas.feasibilityOneSeries.comments
              ? this.entity.oneAtlas.feasibilityOneSeries.comments.trim()
              : null,
          };
          obj.properties.push({
            key: "temporalMode",
            value: this.entity.oneAtlas.feasibilityOneSeries.temporalMode,
          });
          obj.properties.push({
            key: "acquisitionMode",
            value: this.entity.oneAtlas.feasibilityOneSeries.acquisitionMode,
          });
          obj.properties.push({
            key: "maxIncidenceAngle",
            value: this.entity.oneAtlas.feasibilityOneSeries.maxIncidenceAngle,
          });
          obj.properties.push({
            key: "maxCloudCover",
            value: this.entity.oneAtlas.feasibilityOneSeries.maxCloudCover,
          });
          obj.properties.push({
            key: "notificationThreshold",
            value:
              this.entity.oneAtlas.feasibilityOneSeries.notificationThreshold,
          });
          if (
            ["STEREO", "TRISTEREO"].includes(
              this.entity.oneAtlas.feasibilityOneSeries.acquisitionMode
                ? this.entity.oneAtlas.feasibilityOneSeries.acquisitionMode
                : null
            )
          ) {
            if (this.entity.oneAtlas.feasibilityOneSeries.minBH) {
              obj.properties.push({
                key: "minBH",
                value: this.entity.oneAtlas.feasibilityOneSeries.minBH,
              });

              obj.properties.push({
                key: "acquisitionModeMinBH",
                value: this.entity.oneAtlas.feasibilityOneSeries.minBH,
              });
            }
            if (this.entity.oneAtlas.feasibilityOneSeries.maxBH) {
              obj.properties.push({
                key: "maxBH",
                value: this.entity.oneAtlas.feasibilityOneSeries.maxBH,
              });

              obj.properties.push({
                key: "acquisitionModeMaxBH",
                value: this.entity.oneAtlas.feasibilityOneSeries.maxBH,
              });
            }
          }

          if (
            this.entity.oneAtlas.feasibilityOneSeries.temporalMode ===
            "MULTIPERIOD"
          ) {
            let acqPeriods = "";
            let acquisitionPeriods = [];
            for (
              let i = 0;
              i < this.entity.oneAtlas.feasibilityOneSeries.dateRange.length;
              i++
            ) {
              let range =
                this.entity.oneAtlas.feasibilityOneSeries.dateRange[i];
              let start = new Date(range[0]);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              let startMonth = start.getMonth() + 1;
              startMonth = startMonth < 10 ? "0" + startMonth : startMonth;
              let startD = start.getDate();
              startD = startD < 10 ? "0" + startD : startD;
              let end = new Date(range[1]);
              let endMonth = end.getMonth() + 1;
              endMonth = endMonth < 10 ? "0" + endMonth : endMonth;
              let endD = end.getDate();
              endD = endD < 10 ? "0" + endD : endD;
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);

              acquisitionPeriods.push({
                start:
                  start.getFullYear() +
                  "-" +
                  startMonth +
                  "-" +
                  startD +
                  "T00:00:00.000Z",
                end:
                  end.getFullYear() +
                  "-" +
                  endMonth +
                  "-" +
                  endD +
                  "T23:59:59.000Z",
                id: i + 1,
                content: "Period " + (i + 1),
              });

              acqPeriods +=
                start.getFullYear() +
                "-" +
                startMonth +
                "-" +
                startD +
                "T00:00:00.000Z" +
                " " +
                end.getFullYear() +
                "-" +
                endMonth +
                "-" +
                endD +
                "T23:59:59.000Z" +
                ",";
              if (acqPeriods) acqPeriods = acqPeriods.trim();
            }
            if (
              acqPeriods &&
              acqPeriods.substring(acqPeriods.length - 1, acqPeriods.length) ===
                ","
            ) {
              acqPeriods = acqPeriods.substring(0, acqPeriods.length - 1);
            }
            obj.properties.push({
              key: "acquisitionPeriods",
              value: acqPeriods, //JSON.stringify(acquisitionPeriods),
            });
            obj.properties.push({
              key: "acqPeriods",
              value: acqPeriods,
            });
          } else if (
            this.entity.oneAtlas.feasibilityOneSeries.temporalMode === "REGULAR"
          ) {
            let start = new Date(
              this.entity.oneAtlas.feasibilityOneSeries.startDate
            );
            let end = new Date(
              this.entity.oneAtlas.feasibilityOneSeries.endDate
            );
            let startMonth = start.getMonth() + 1;
            let startD = start.getDate();
            let startHour = start.getUTCHours();
            let startMinute = start.getUTCMinutes();
            let startSecond = start.getUTCSeconds();
            let startMiniSecond = start.getUTCMilliseconds();
            if ((startMiniSecond + "").length === 1)
              startMiniSecond = "00" + startMiniSecond;
            else if ((startMiniSecond + "").length === 2)
              startMiniSecond = "0" + startMiniSecond;
            if (startHour < 10) startHour = "0" + startHour;
            if (startMinute < 10) startMinute = "0" + startMinute;
            if (startSecond < 10) startSecond = "0" + startSecond;
            if (startMonth < 10) startMonth = "0" + startMonth;
            if (startD < 10) startD = "0" + startD;
            let strStart =
              start.getFullYear() +
              "-" +
              startMonth +
              "-" +
              startD +
              "T00:00:00.000Z"; //+
            // startHour +
            // ":" +
            // startMinute +
            // ":" +
            // startSecond +
            // "." +
            // startMiniSecond +
            // "Z";
            let endMonth = end.getMonth() + 1;
            let endD = end.getDate();
            let endHour = end.getUTCHours();
            let endMinute = end.getUTCMinutes();
            let endSecond = end.getUTCSeconds();
            let endMiniSecond = end.getUTCMilliseconds();
            if ((endMiniSecond + "").length === 1)
              endMiniSecond = "00" + endMiniSecond;
            else if ((endMiniSecond + "").length === 2)
              endMiniSecond = "0" + endMiniSecond;
            if (endHour < 10) endHour = "0" + endHour;
            if (endMinute < 10) endMinute = "0" + endMinute;
            if (endSecond < 10) endSecond = "0" + endSecond;
            if (endMonth < 10) endMonth = "0" + endMonth;
            if (endD < 10) endD = "0" + endD;

            //console.log(endMiniSecond);
            let strEnd =
              end.getFullYear() +
              "-" +
              endMonth +
              "-" +
              endD +
              "T23:59:59.000Z"; //+
            // endHour +
            // ":" +
            // endMinute +
            // ":" +
            // endSecond +
            // "." +
            // endMiniSecond +
            // "Z";
            obj.properties.push({
              key: "acqPeriod",
              value: strStart + " " + strEnd,
            });
            obj.properties.push({
              key: "deadPeriod",
              value: this.entity.oneAtlas.feasibilityOneSeries.periodOfTime,
            });
            obj.properties.push({
              key: "numberOfObservations",
              value:
                this.entity.oneAtlas.feasibilityOneSeries.numberOfObservations,
            });
          }
          payload.items.push(obj);
        }

        console.log(payload);
        //return;
        let response = await oneAtlasFunc.createOrderTasking(
          payload,
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );
        console.log(response);
        if (response && (response.status === 200 || response.status === 201)) {
          this.$toast.success({
            title: this.$t("title_message_info"),
            message: this.$t("message_send_order_image_success"),
            position: "top right",
          });
          this.onClose();
        } else this.$emit("message", this.$t("message_send_order_image_error"));
      } catch (error) {
        console.log(error);

        if (this.buttons.yes.data_loading === "oneatlas_create_order") {
          this.buttons.yes.disable = false;
          this.buttons.yes.processing = false;
          this.buttons.yes.icon = null;
          this.buttons.yes.data_loading = null;
        }
        if (error && error.response && error.response.status === 401) {
          this.$store.dispatch("clearOneAtlasToken");
          let r = await this.getOneAtlasTokenData();
          if (r) {
            this.createOrder();
          }
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (
            error.response.data.message ===
            "malformed authorization header, we found an Authorization header but it did not have the correct format"
          ) {
            this.$store.dispatch("clearOneAtlasToken");
            let r = await this.getOneAtlasTokenData();
            if (r) {
              this.createOrder();
            }
          } else this.$emit("message", [error.response.data.message]);
        } else {
          this.$emit("message", this.$t("message_send_order_image_error"));
        }
      }
      if (this.buttons.yes.data_loading === "oneatlas_create_order") {
        this.buttons.yes.disable = false;
        this.buttons.yes.processing = false;
        this.buttons.yes.icon = null;
        this.buttons.yes.data_loading = null;
      }
    },
    selectedAttempt(data) {
      this.entity.oneAtlas.segments_group = data.groups;
      this.entity.oneAtlas.attempts = data.selected;
      this.entity.oneAtlas.segmentIds = this.entity.oneAtlas.attempts.map(
        (x) => x.id
      );
    },
    async getContracts() {
      if (!this.buttons.yes.data_loading) {
        this.buttons.yes.disable = true;
        this.buttons.yes.processing = true;
        this.buttons.yes.icon = "fas fa-spinner fa-spin";
        this.buttons.yes.data_loading = "oneatlas_get_contract";
      }
      try {
        let response = await oneAtlasFunc.getContracts(
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );
        if (response && response.status === 200) {
          this.contracts =
            response.data && response.data.contracts
              ? response.data.contracts
              : [];
        }
      } catch (error) {
        if (this.buttons.yes.data_loading === "oneatlas_get_contract") {
          this.buttons.yes.disable = false;
          this.buttons.yes.processing = false;
          this.buttons.yes.icon = null;
          this.buttons.yes.data_loading = null;
        }

        if (error && error.response && error.response.status === 401) {
          this.$store.dispatch("clearOneAtlasToken");
          let r = await this.getOneAtlasTokenData();
          if (r) {
            this.getContracts();
          }
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (
            error.response.data.message ===
            "malformed authorization header, we found an Authorization header but it did not have the correct format"
          ) {
            this.$store.dispatch("clearOneAtlasToken");
            let r = await this.getOneAtlasTokenData();
            if (r) {
              this.createOrder();
            }
          }
        }
      }

      if (this.buttons.yes.data_loading === "oneatlas_get_contract") {
        this.buttons.yes.disable = false;
        this.buttons.yes.processing = false;
        this.buttons.yes.icon = null;
        this.buttons.yes.data_loading = null;
      }
    },
    async getPrice() {
      if (!this.buttons.yes.data_loading) {
        this.buttons.yes.disable = true;
        this.buttons.yes.processing = true;
        this.buttons.yes.icon = "fas fa-spinner fa-spin";
        this.buttons.yes.data_loading = "oneatlas_get_price";
      }
      this.oneAtlas.price = {
        loading: true,
      };
      let coors = this.entity.feature.getGeometry().getCoordinates();
      let coordinates4Api = [];
      for (let i = 0; i < coors[0].length; i++) {
        coordinates4Api.push(transform(coors[0][i], "EPSG:3857", "EPSG:4326"));
      }
      let payload = {
        aoi: [
          {
            id: 1,
            name: "Polygon 1",
            geometry: {
              type: "Polygon",
              coordinates: [coordinates4Api],
            },
          },
        ],
        contractId: this.oneAtlasContractId,
        items: [],
        primaryMarket: null,
        secondaryMarket: null,
        customerReference: "Buon Kuop",
        optionsPerProductType: [
          {
            productTypeId: this.productTypeSelected
              ? this.productTypeSelected.productTypeId
              : null,
            options: [],
          },
        ],
      };
      if (["oneday", "onenow"].includes(this.target)) {
        if (
          this.entity.oneAtlas.attempts &&
          this.entity.oneAtlas.attempts.length
        ) {
          for (let i = 0; i < this.entity.oneAtlas.attempts.length; i++) {
            let obj = {
              productTypeId: this.productTypeSelected
                ? this.productTypeSelected.productTypeId
                : null,
              aoiId: 1,
              properties: [],
              comments: this.entity.oneAtlas.name,
              segmentKey: this.entity.oneAtlas.attempts[i].segmentKey,
            };
            obj.properties.push({
              key: "extendedAngle",
              value: this.entity.oneAtlas.attempts[i].extendedAngle,
            });
            if (
              this.productTypeSelected &&
              Object.keys(this.productTypeSelected).length > 0
            ) {
              obj.properties.push({
                key: "comments",
                value: this.productTypeSelected.technicalProduct.label,
              });
            }
            payload.items.push(obj);
          }
        }
      } else if (["oneplan"].includes(this.target)) {
        let obj = {
          productTypeId: this.productTypeSelected
            ? this.productTypeSelected.productTypeId
            : null,
          dataSourceIds: [],
          aoiId: 1,
          properties: [],
          comments: this.entity.oneAtlas.feasibility.comments
            ? this.entity.oneAtlas.feasibility.comments.trim()
            : null,
        };
        if (this.entity.oneAtlas.feasibility.type === "standard") {
          obj.properties.push({
            key: "acquisitionMode",
            value: "MONO",
          });
          obj.properties.push({
            key: "maxCloudCover",
            value: this.entity.oneAtlas.feasibility.maxCloudCover,
          });
          obj.properties.push({
            key: "maxIncidenceAngle",
            value: this.entity.oneAtlas.feasibility.maxIncidenceAngle,
          });
        } else if (this.entity.oneAtlas.feasibility.type === "custom") {
          obj.properties.push({
            key: "acquisitionMode",
            value: this.entity.oneAtlas.feasibility.customAcquisitionMode,
          });
          obj.properties.push({
            key: "maxIncidenceAngle",
            value: this.entity.oneAtlas.feasibility.customMaxIncidenceAngle,
          });
          obj.properties.push({
            key: "maxCloudCover",
            value: this.entity.oneAtlas.feasibility.customMaxCloudCover,
          });
          obj.properties.push({
            key: "notificationThreshold",
            value: this.entity.oneAtlas.feasibility.customNotificationThreshold,
          });
          if (
            ["STEREO", "TRISTEREO"].includes(
              this.entity.oneAtlas.feasibility.customAcquisitionMode
                ? this.entity.oneAtlas.feasibility.customAcquisitionMode
                : null
            )
          ) {
            if (this.entity.oneAtlas.feasibility.customMinBH) {
              obj.properties.push({
                key: "minBH",
                value: this.entity.oneAtlas.feasibility.customMinBH,
              });

              obj.properties.push({
                key: "acquisitionModeMinBH",
                value: this.entity.oneAtlas.feasibility.customMinBH,
              });
            }
            if (this.entity.oneAtlas.feasibility.customMaxBH) {
              obj.properties.push({
                key: "maxBH",
                value: this.entity.oneAtlas.feasibility.customMaxBH,
              });

              obj.properties.push({
                key: "acquisitionModeMaxBH",
                value: this.entity.oneAtlas.feasibility.customMaxBH,
              });
            }
          }
        }

        if (
          this.entity.oneAtlas.feasibility.startDate &&
          this.entity.oneAtlas.feasibility.endDate
        ) {
          let start = new Date(this.entity.oneAtlas.feasibility.startDate);
          let end = new Date(this.entity.oneAtlas.feasibility.endDate);
          let startMonth = start.getMonth() + 1;
          let startD = start.getDate();
          let startHour = start.getUTCHours();
          let startMinute = start.getUTCMinutes();
          let startSecond = start.getUTCSeconds();
          let startMiniSecond = start.getUTCMilliseconds();
          if ((startMiniSecond + "").length === 1)
            startMiniSecond = "00" + startMiniSecond;
          else if ((startMiniSecond + "").length === 2)
            startMiniSecond = "0" + startMiniSecond;
          if (startHour < 10) startHour = "0" + startHour;
          if (startMinute < 10) startMinute = "0" + startMinute;
          if (startSecond < 10) startSecond = "0" + startSecond;
          if (startMonth < 10) startMonth = "0" + startMonth;
          if (startD < 10) startD = "0" + startD;
          let strStart =
            start.getFullYear() +
            "-" +
            startMonth +
            "-" +
            startD +
            "T00:00:00.000Z"; //+
          // startHour +
          // ":" +
          // startMinute +
          // ":" +
          // startSecond +
          // "." +
          // startMiniSecond +
          // "Z";
          let endMonth = end.getMonth() + 1;
          let endD = end.getDate();
          let endHour = end.getUTCHours();
          let endMinute = end.getUTCMinutes();
          let endSecond = end.getUTCSeconds();
          let endMiniSecond = end.getUTCMilliseconds();
          if ((endMiniSecond + "").length === 1)
            endMiniSecond = "00" + endMiniSecond;
          else if ((endMiniSecond + "").length === 2)
            endMiniSecond = "0" + endMiniSecond;
          if (endHour < 10) endHour = "0" + endHour;
          if (endMinute < 10) endMinute = "0" + endMinute;
          if (endSecond < 10) endSecond = "0" + endSecond;
          if (endMonth < 10) endMonth = "0" + endMonth;
          if (endD < 10) endD = "0" + endD;
          let strEnd =
            end.getFullYear() + "-" + endMonth + "-" + endD + "T23:59:59.000Z"; //+
          // endHour +
          // ":" +
          // endMinute +
          // ":" +
          // endSecond +
          // "." +
          // endMiniSecond +
          // "Z";
          obj.properties.push({
            key: "acqPeriod",
            value: strStart + " " + strEnd,
          });
          obj.properties.push({
            key: "acqPeriods",
            value: strStart + " " + strEnd,
          });
        }
        if (
          this.productTypeSelected &&
          Object.keys(this.productTypeSelected).length > 0
        ) {
          obj.properties.push({
            key: "comments",
            value: this.productTypeSelected.technicalProduct.label,
          });
        }
        if (this.oneAtlas.feasibility && this.oneAtlas.feasibility.length > 0) {
          if (
            this.oneAtlas.feasibility[0].progTypes &&
            this.oneAtlas.feasibility[0].progTypes.length > 0
          ) {
            if (
              this.oneAtlas.feasibility[0].progTypes[0].feasibility &&
              Object.keys(this.oneAtlas.feasibility[0].progTypes[0].feasibility)
                .length > 0
            ) {
              if (
                this.oneAtlas.feasibility[0].progTypes[0].feasibility
                  .classification
              )
                obj.properties.push({
                  key: "classification",
                  value: this.oneAtlas.feasibility[0].progTypes[0].feasibility
                    .classification
                    ? this.oneAtlas.feasibility[0].progTypes[0].feasibility
                        .classification
                    : null,
                });
              if (
                this.oneAtlas.feasibility[0].progTypes[0].feasibility.automation
              )
                obj.properties.push({
                  key: "feasibilityAutomation",
                  value: this.oneAtlas.feasibility[0].progTypes[0].feasibility
                    .automation
                    ? this.oneAtlas.feasibility[0].progTypes[0].feasibility
                        .automation
                    : null,
                });
              if (
                this.oneAtlas.feasibility[0].progTypes[0].feasibility
                  .automationName
              )
                obj.properties.push({
                  key: "feasibilityAutomationName",
                  value: this.oneAtlas.feasibility[0].progTypes[0].feasibility
                    .automationName
                    ? this.oneAtlas.feasibility[0].progTypes[0].feasibility
                        .automationName
                    : null,
                });
            }
          }
        }
        payload.items.push(obj);
      } else if (["oneseries"].includes(this.target)) {
        let obj = {
          productTypeId: this.productTypeSelected
            ? this.productTypeSelected.productTypeId
            : null,
          dataSourceIds: [],
          aoiId: 1,
          properties: [],
          comments: this.entity.oneAtlas.feasibilityOneSeries.comments
            ? this.entity.oneAtlas.feasibilityOneSeries.comments.trim()
            : null,
        };
        obj.properties.push({
          key: "temporalMode",
          value: this.entity.oneAtlas.feasibilityOneSeries.temporalMode,
        });
        obj.properties.push({
          key: "acquisitionMode",
          value: this.entity.oneAtlas.feasibilityOneSeries.acquisitionMode,
        });
        obj.properties.push({
          key: "maxIncidenceAngle",
          value: this.entity.oneAtlas.feasibilityOneSeries.maxIncidenceAngle,
        });
        obj.properties.push({
          key: "maxCloudCover",
          value: this.entity.oneAtlas.feasibilityOneSeries.maxCloudCover,
        });
        obj.properties.push({
          key: "notificationThreshold",
          value:
            this.entity.oneAtlas.feasibilityOneSeries.notificationThreshold,
        });
        if (
          ["STEREO", "TRISTEREO"].includes(
            this.entity.oneAtlas.feasibilityOneSeries.acquisitionMode
              ? this.entity.oneAtlas.feasibilityOneSeries.acquisitionMode
              : null
          )
        ) {
          if (this.entity.oneAtlas.feasibilityOneSeries.minBH) {
            obj.properties.push({
              key: "minBH",
              value: this.entity.oneAtlas.feasibilityOneSeries.minBH,
            });

            obj.properties.push({
              key: "acquisitionModeMinBH",
              value: this.entity.oneAtlas.feasibilityOneSeries.minBH,
            });
          }
          if (this.entity.oneAtlas.feasibilityOneSeries.maxBH) {
            obj.properties.push({
              key: "maxBH",
              value: this.entity.oneAtlas.feasibilityOneSeries.maxBH,
            });

            obj.properties.push({
              key: "acquisitionModeMaxBH",
              value: this.entity.oneAtlas.feasibilityOneSeries.maxBH,
            });
          }
        }

        if (
          this.entity.oneAtlas.feasibilityOneSeries.temporalMode ===
          "MULTIPERIOD"
        ) {
          let acqPeriods = "";
          let acquisitionPeriods = [];
          for (
            let i = 0;
            i < this.entity.oneAtlas.feasibilityOneSeries.dateRange.length;
            i++
          ) {
            let range = this.entity.oneAtlas.feasibilityOneSeries.dateRange[i];
            let start = new Date(range[0]);
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            let startMonth = start.getMonth() + 1;
            startMonth = startMonth < 10 ? "0" + startMonth : startMonth;
            let startD = start.getDate();
            startD = startD < 10 ? "0" + startD : startD;
            let end = new Date(range[1]);
            let endMonth = end.getMonth() + 1;
            endMonth = endMonth < 10 ? "0" + endMonth : endMonth;
            let endD = end.getDate();
            endD = endD < 10 ? "0" + endD : endD;
            end.setHours(23);
            end.setMinutes(59);
            end.setSeconds(59);
            acquisitionPeriods.push({
              start:
                start.getFullYear() +
                "-" +
                startMonth +
                "-" +
                startD +
                "T00:00:00.000Z",
              end:
                end.getFullYear() +
                "-" +
                endMonth +
                "-" +
                endD +
                "T23:59:59.000Z",
              id: i + 1,
              content: "Period " + (i + 1),
            });
            acqPeriods +=
              start.getFullYear() +
              "-" +
              startMonth +
              "-" +
              startD +
              "T00:00:00.000Z" +
              " " +
              end.getFullYear() +
              "-" +
              endMonth +
              "-" +
              endD +
              "T23:59:59.000Z" +
              ",";
            if (acqPeriods) acqPeriods = acqPeriods.trim();
          }
          if (
            acqPeriods &&
            acqPeriods.substring(acqPeriods.length - 1, acqPeriods.length) ===
              ","
          ) {
            acqPeriods = acqPeriods.substring(0, acqPeriods.length - 1);
          }
          obj.properties.push({
            key: "acquisitionPeriods",
            value: acqPeriods, //JSON.stringify(acquisitionPeriods),
          });
          obj.properties.push({
            key: "acqPeriods",
            value: acqPeriods,
          });
        } else if (
          this.entity.oneAtlas.feasibilityOneSeries.temporalMode === "REGULAR"
        ) {
          let start = new Date(
            this.entity.oneAtlas.feasibilityOneSeries.startDate
          );
          let end = new Date(this.entity.oneAtlas.feasibilityOneSeries.endDate);
          let startMonth = start.getMonth() + 1;
          let startD = start.getDate();
          let startHour = start.getUTCHours();
          let startMinute = start.getUTCMinutes();
          let startSecond = start.getUTCSeconds();
          let startMiniSecond = start.getUTCMilliseconds();
          if ((startMiniSecond + "").length === 1)
            startMiniSecond = "00" + startMiniSecond;
          else if ((startMiniSecond + "").length === 2)
            startMiniSecond = "0" + startMiniSecond;
          if (startHour < 10) startHour = "0" + startHour;
          if (startMinute < 10) startMinute = "0" + startMinute;
          if (startSecond < 10) startSecond = "0" + startSecond;
          if (startMonth < 10) startMonth = "0" + startMonth;
          if (startD < 10) startD = "0" + startD;
          let strStart =
            start.getFullYear() +
            "-" +
            startMonth +
            "-" +
            startD +
            "T00:00:00.000Z"; //+
          // startHour +
          // ":" +
          // startMinute +
          // ":" +
          // startSecond +
          // "." +
          // startMiniSecond +
          // "Z";
          let endMonth = end.getMonth() + 1;
          let endD = end.getDate();
          let endHour = end.getUTCHours();
          let endMinute = end.getUTCMinutes();
          let endSecond = end.getUTCSeconds();
          let endMiniSecond = end.getUTCMilliseconds();
          if ((endMiniSecond + "").length === 1)
            endMiniSecond = "00" + endMiniSecond;
          else if ((endMiniSecond + "").length === 2)
            endMiniSecond = "0" + endMiniSecond;
          if (endHour < 10) endHour = "0" + endHour;
          if (endMinute < 10) endMinute = "0" + endMinute;
          if (endSecond < 10) endSecond = "0" + endSecond;
          if (endMonth < 10) endMonth = "0" + endMonth;
          if (endD < 10) endD = "0" + endD;

          console.log(endMiniSecond);
          let strEnd =
            end.getFullYear() + "-" + endMonth + "-" + endD + "T23:59:59.000Z"; //+
          // endHour +
          // ":" +
          // endMinute +
          // ":" +
          // endSecond +
          // "." +
          // endMiniSecond +
          // "Z";
          obj.properties.push({
            key: "acqPeriod",
            value: strStart + " " + strEnd,
          });
          obj.properties.push({
            key: "deadPeriod",
            value: this.entity.oneAtlas.feasibilityOneSeries.periodOfTime,
          });
          obj.properties.push({
            key: "numberOfObservations",
            value:
              this.entity.oneAtlas.feasibilityOneSeries.numberOfObservations,
          });
        }
        payload.items.push(obj);
      }

      if (this.entity.oneAtlas.options && this.entity.oneAtlas.options.length) {
        for (let i = 0; i < this.entity.oneAtlas.options.length; i++) {
          payload.optionsPerProductType[0].options.push({
            key: this.entity.oneAtlas.options[i].name,
            value: this.entity.oneAtlas.options[i].selectedValue,
          });
        }
      }
      if (
        payload.optionsPerProductType[0].options.findIndex(
          (x) => x.key === "projection_1"
        ) < 0
      ) {
        payload.optionsPerProductType[0].options.push({
          key: "projection_1",
          value: "4326",
        });
      }
      console.log(payload);
      //return;
      try {
        let response = await oneAtlasFunc.calculatePrice4Tasking(
          payload,
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );
        if (response && response.status === 200)
          this.oneAtlas.price = response.data ? response.data : {};
      } catch (error) {
        this.oneAtlas.price.loading = false;
        if (this.buttons.yes.data_loading === "oneatlas_get_price") {
          this.buttons.yes.disable = false;
          this.buttons.yes.processing = false;
          this.buttons.yes.icon = null;
          this.buttons.yes.data_loading = null;
        }
        if (error && error.response && error.response.status === 401) {
          this.$store.dispatch("clearOneAtlasToken");
          let r = await this.getOneAtlasTokenData();
          if (r) {
            this.getPrice();
          }
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (
            error.response.data.message ===
            "malformed authorization header, we found an Authorization header but it did not have the correct format"
          ) {
            this.$store.dispatch("clearOneAtlasToken");
            let r = await this.getOneAtlasTokenData();
            if (r) {
              this.getPrice();
            }
          } else this.$emit("message", [error.response.data.message]);
        }
      }

      this.oneAtlas.price.loading = false;
      if (this.buttons.yes.data_loading === "oneatlas_get_price") {
        this.buttons.yes.disable = false;
        this.buttons.yes.processing = false;
        this.buttons.yes.icon = null;
        this.buttons.yes.data_loading = null;
      }
    },
    async checkFeasibility(data) {
      if (["oneplan"].includes(this.target)) {
        this.entity.oneAtlas.feasibility.type = data.type
          ? data.type.trim()
          : null;
        this.entity.oneAtlas.feasibility.sensor = data.sensor
          ? data.sensor
          : null;
        this.entity.oneAtlas.feasibility.startDate = data.startDate
          ? data.startDate
          : null;
        this.entity.oneAtlas.feasibility.endDate = data.endDate
          ? data.endDate
          : null;
        this.entity.oneAtlas.feasibility.maxIncidenceAngle =
          data.maxIncidenceAngle ? data.maxIncidenceAngle : null;
        this.entity.oneAtlas.feasibility.maxCloudCover = data.maxCloudCover
          ? data.maxCloudCover
          : null;
        this.entity.oneAtlas.feasibility.comments = data.comments
          ? data.comments.trim()
          : null;

        this.entity.oneAtlas.feasibility.customMaxIncidenceAngle =
          data.customMaxIncidenceAngle ? data.customMaxIncidenceAngle : null;
        this.entity.oneAtlas.feasibility.customMaxCloudCover =
          data.customMaxCloudCover ? data.customMaxCloudCover : null;
        this.entity.oneAtlas.feasibility.customNotificationThreshold =
          data.customNotificationThreshold
            ? data.customNotificationThreshold
            : null;
        this.entity.oneAtlas.feasibility.customAcquisitionMode =
          data.customAcquisitionMode ? data.customAcquisitionMode : null;
        this.entity.oneAtlas.feasibility.customMinBH = data.customMinBH
          ? data.customMinBH
          : null;
        this.entity.oneAtlas.feasibility.customMaxBH = data.customMaxBH
          ? data.customMaxBH
          : null;
      } else if (["oneseries"].includes(this.target)) {
        this.entity.oneAtlas.feasibilityOneSeries.sensor = data.sensor
          ? data.sensor
          : null;
        this.entity.oneAtlas.feasibilityOneSeries.temporalMode =
          data.temporalMode ? data.temporalMode : null;
        this.entity.oneAtlas.feasibilityOneSeries.startDate = data.startDate
          ? data.startDate
          : null;
        this.entity.oneAtlas.feasibilityOneSeries.endDate = data.endDate
          ? data.endDate
          : null;
        this.entity.oneAtlas.feasibilityOneSeries.dateRange = data.dateRange
          ? data.dateRange
          : null;
        this.entity.oneAtlas.feasibilityOneSeries.numberOfObservations =
          data.numberOfObservations ? data.numberOfObservations : null;
        this.entity.oneAtlas.feasibilityOneSeries.periodOfTime =
          data.periodOfTime ? data.periodOfTime : null;
        this.entity.oneAtlas.feasibilityOneSeries.maxIncidenceAngle =
          data.maxIncidenceAngle ? data.maxIncidenceAngle : null;
        this.entity.oneAtlas.feasibilityOneSeries.maxCloudCover =
          data.maxCloudCover ? data.maxCloudCover : null;
        this.entity.oneAtlas.feasibilityOneSeries.notificationThreshold =
          data.notificationThreshold ? data.notificationThreshold : null;
        this.entity.oneAtlas.feasibilityOneSeries.acquisitionMode =
          data.acquisitionMode ? data.acquisitionMode : null;
        this.entity.oneAtlas.feasibilityOneSeries.minBH = data.minBH
          ? data.minBH
          : null;
        this.entity.oneAtlas.feasibilityOneSeries.maxBH = data.maxBH
          ? data.maxBH
          : null;
      }

      this.oneAtlas.price = {
        loading: true,
      };
      try {
        this.oneAtlas.feasibility = [];
        let coors = this.entity.feature.getGeometry().getCoordinates();
        let coordinates4Api = [];
        for (let i = 0; i < coors[0].length; i++) {
          coordinates4Api.push(
            transform(coors[0][i], "EPSG:3857", "EPSG:4326")
          );
        }

        let payload = {
          acquisitionPeriods: [],
          acquisitionDate: {},
          contractId: this.oneAtlasContractId,
          missions: [
            this.productTypeSelected.technicalProduct.sensor.id.toUpperCase(),
          ],
          optical: {},
          progTypeNames: [
            this.productTypeSelected.technicalProduct.target.id.toUpperCase(),
          ],
          aoi: {
            type: "Polygon",
            coordinates: [coordinates4Api],
          },
        };
        if (["oneplan"].includes(this.target)) {
          let start = new Date(data.startDate);
          start.setHours(0);
          start.setMinutes(0);
          start.setSeconds(0);
          let end = new Date(data.endDate);
          end.setHours(0);
          end.setMinutes(0);
          end.setSeconds(0);
          let startMonth = start.getMonth() + 1;
          if (startMonth < 10) startMonth = "0" + startMonth;
          let startD = start.getDate();
          if (startD < 10) startD = "0" + startD;
          let endMonth = end.getMonth() + 1;
          if (endMonth < 10) endMonth = "0" + endMonth;
          let endD = end.getDate();
          if (endD < 10) endD = "0" + endD;

          payload.acquisitionDate.endDate =
            end.getFullYear() + "-" + endMonth + "-" + endD + "T00:00:00.000Z"; //new Date(data.endDate);
          payload.acquisitionDate.startDate =
            start.getFullYear() +
            "-" +
            startMonth +
            "-" +
            startD +
            "T00:00:00.000Z";
          //new Date(data.startDate);

          payload.optical.acquisitionMode = data.customAcquisitionMode
            ? data.customAcquisitionMode
            : null;

          if (data.type === "standard") {
            payload.optical.maxCloudCover = data.maxCloudCover
              ? data.maxCloudCover
              : null;
            payload.optical.maxIncidenceAngle = data.maxIncidenceAngle
              ? data.maxIncidenceAngle
              : null;
          } else if (data.type === "custom") {
            payload.optical.maxCloudCover = data.customMaxCloudCover
              ? data.customMaxCloudCover
              : null;
            payload.optical.maxIncidenceAngle = data.customMaxIncidenceAngle
              ? data.customMaxIncidenceAngle
              : null;

            payload.optical.notificationThreshold =
              data.customNotificationThreshold
                ? data.customNotificationThreshold
                : null;
            if (
              ["STEREO", "TRISTEREO"].includes(
                data.customAcquisitionMode ? data.customAcquisitionMode : null
              )
            ) {
              payload.optical.minBH = data.customMinBH
                ? data.customMinBH
                : null;
              payload.optical.maxBH = data.customMaxBH
                ? data.customMaxBH
                : null;
            }
          }
        } else if (["oneseries"].includes(this.target)) {
          payload.taskingOffer = "ONESERIES";
          if (data.temporalMode === "MULTIPERIOD") {
            for (let i = 0; i < data.dateRange.length; i++) {
              let range = data.dateRange[i];
              let start = new Date(range[0]);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              let startMonth = start.getMonth() + 1;
              startMonth = startMonth < 10 ? "0" + startMonth : startMonth;
              let startD = start.getDate();
              startD = startD < 10 ? "0" + startD : startD;
              let end = new Date(range[1]);
              let endMonth = end.getMonth() + 1;
              endMonth = endMonth < 10 ? "0" + endMonth : endMonth;
              let endD = end.getDate();
              endD = endD < 10 ? "0" + endD : endD;
              end.setHours(23);
              end.setMinutes(59);
              end.setSeconds(59);
              payload.acquisitionPeriods.push({
                start:
                  start.getFullYear() +
                  "-" +
                  startMonth +
                  "-" +
                  startD +
                  "T00:00:00.000Z",
                end:
                  end.getFullYear() +
                  "-" +
                  endMonth +
                  "-" +
                  endD +
                  "T23:59:59.000Z",
                id: i + 1,
                content: "Period " + (i + 1),
              });
              if (!payload.acqPeriods) payload.acqPeriods = "";
              payload.acqPeriods +=
                start.getFullYear() +
                "-" +
                startMonth +
                "-" +
                startD +
                "T00:00:00.000Z" +
                " " +
                end.getFullYear() +
                "-" +
                endMonth +
                "-" +
                endD +
                "T23:59:59.000Z" +
                ",";
              if (payload.acqPeriods)
                payload.acqPeriods = payload.acqPeriods.trim();
            }
            if (
              payload.acqPeriods &&
              payload.acqPeriods.substring(
                payload.acqPeriods.length - 1,
                payload.acqPeriods.length
              ) === ","
            ) {
              payload.acqPeriods = payload.acqPeriods.substring(
                0,
                payload.acqPeriods.length - 1
              );
            }
          } else if (data.temporalMode === "REGULAR") {
            payload.acquisitionDate.endDate = new Date(data.endDate);
            payload.acquisitionDate.startDate = new Date(data.startDate);
          }
          payload.temporalMode = data.temporalMode ? data.temporalMode : null;
          payload.optical.acquisitionMode = data.acquisitionMode
            ? data.acquisitionMode
            : null;
          payload.optical.maxIncidenceAngle = data.maxIncidenceAngle
            ? data.maxIncidenceAngle
            : null;
          payload.optical.maxCloudCover = data.maxCloudCover
            ? data.maxCloudCover
            : null;
          payload.optical.notificationThreshold = data.notificationThreshold
            ? data.notificationThreshold
            : null;
          if (
            ["STEREO", "TRISTEREO"].includes(
              data.acquisitionMode ? data.acquisitionMode : null
            )
          ) {
            payload.optical.acquisitionModeMinBH = payload.optical.minBH =
              data.minBH ? data.minBH : null;
            payload.optical.acquisitionModeMaxBH = payload.optical.maxBH =
              data.maxBH ? data.maxBH : null;

            payload.acquisitionModeMaxBH = payload.maxBH = data.maxBH
              ? data.maxBH
              : null;
            payload.acquisitionModeMinBH = payload.minBH = data.minBH
              ? data.minBH
              : null;
          }
          await this.getPrice();
          return;
        }

        let response = await oneAtlasFunc.checkFeasibility(
          payload,
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );

        this.oneAtlas.price = {
          loading: false,
        };
        console.log(response);
        if (response && response.status === 200) {
          this.oneAtlas.feasibility =
            response.data && response.data.progCapacities
              ? response.data.progCapacities
              : [];
          await this.getPrice();
        }
      } catch (error) {
        this.oneAtlas.price = {
          loading: false,
        };
        console.log(error);
        if (error && error.response && error.response.status === 401) {
          this.$store.dispatch("clearOneAtlasToken");
          let r = await this.getOneAtlasTokenData();
          if (r) {
            this.checkFeasibility();
          }
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (
            error.response.data.message ===
            "malformed authorization header, we found an Authorization header but it did not have the correct format"
          ) {
            this.$store.dispatch("clearOneAtlasToken");
            let r = await this.getOneAtlasTokenData();
            if (r) {
              this.checkFeasibility();
            }
          } else this.$emit("message", [error.response.data.message]);
        }
      }
    },
    async getMarkets() {
      try {
        let response = await oneAtlasFunc.getMarkets(
          this.oneAtlasContractId,
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );
        if (response && response.status === 200)
          this.oneAtlas.markets =
            response.data && response.data.markets ? response.data.markets : [];
      } catch (error) {
        console.log(error);
        if (error && error.response && error.response.status === 401) {
          this.$store.dispatch("clearOneAtlasToken");
          let r = await this.getOneAtlasTokenData();
          if (r) {
            this.getMarkets();
          }
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (
            error.response.data.message ===
            "malformed authorization header, we found an Authorization header but it did not have the correct format"
          ) {
            this.$store.dispatch("clearOneAtlasToken");
            let r = await this.getOneAtlasTokenData();
            if (r) {
              this.getMarkets();
            }
          }
        }
      }
    },
    async getDeliveryTypes() {
      try {
        let response = await oneAtlasFunc.getDeliveryTypes(
          this.oneAtlasContractId,
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );
        if (response && response.status === 200)
          this.oneAtlas.deliveryTypes =
            response.data && response.data.deliveryTypes
              ? response.data.deliveryTypes
              : [];
        if (
          this.oneAtlas.deliveryTypes &&
          this.oneAtlas.deliveryTypes.length > 0
        ) {
          this.entity.oneAtlas.deliveryType =
            this.oneAtlas.deliveryTypes[0].deliveryTypeId;
        }
      } catch (error) {
        console.log(error);
        if (error && error.response && error.response.status === 401) {
          this.$store.dispatch("clearOneAtlasToken");
          let r = await this.getOneAtlasTokenData();
          if (r) {
            this.getDeliveryTypes();
          }
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (
            error.response.data.message ===
            "malformed authorization header, we found an Authorization header but it did not have the correct format"
          ) {
            this.$store.dispatch("clearOneAtlasToken");
            let r = await this.getOneAtlasTokenData();
            if (r) {
              this.getDeliveryTypes();
            }
          }
        }
      }
    },
    async getProductOptions() {
      let productType = this.oneAtlas.productTypes.items.find(
        (x) => x.productTypeId === this.entity.oneAtlas.productType
      );
      let coors = this.entity.feature.getGeometry().getCoordinates();
      let coordinates4Api = [];
      for (let i = 0; i < coors[0].length; i++) {
        coordinates4Api.push(transform(coors[0][i], "EPSG:3857", "EPSG:4326"));
      }
      let payload = {
        aoi: [
          {
            polygonId: 1,
            geometry: {
              type: "Polygon",
              coordinates: [coordinates4Api],
            },
          },
        ],
      };
      try {
        let response = await oneAtlasFunc.getProductOptions(
          payload,
          this.oneAtlasContractId,
          productType ? productType.productTypeId : null,
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );
        if (response && response.status === 200) {
          this.oneAtlas.options =
            response.data && response.data.availableOptions
              ? response.data.availableOptions
              : [];
        }
        // Get option is mandatory
        if (this.oneAtlas.options && this.oneAtlas.options.length > 0) {
          this.entity.oneAtlas.options = this.oneAtlas.options.filter(
            (x) =>
              //x.mandatory === "true" &&
              x.values &&
              x.values.length &&
              x.category === "production_option" &&
              x.type === "list" &&
              x.name != "projection_1"
          );
        }
        if (
          this.entity.oneAtlas.options &&
          this.entity.oneAtlas.options.length > 0
        ) {
          for (let i = 0; i < this.entity.oneAtlas.options.length; i++) {
            this.entity.oneAtlas.options[i].selectedValue =
              this.entity.oneAtlas.options[i].defaultValue;
            if (
              !this.entity.oneAtlas.options[i].selectedValue &&
              this.entity.oneAtlas.options[i].values &&
              this.entity.oneAtlas.options[i].values.length > 0
            ) {
              this.entity.oneAtlas.options[i].selectedValue =
                this.entity.oneAtlas.options[i].values[0].id;
            }
          }
        }
      } catch (error) {
        if (error && error.response && error.response.status === 401) {
          this.$store.dispatch("clearOneAtlasToken");
          let r = await this.getOneAtlasTokenData();
          if (r) {
            this.getProductOptions();
          }
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (
            error.response.data.message ===
            "malformed authorization header, we found an Authorization header but it did not have the correct format"
          ) {
            this.$store.dispatch("clearOneAtlasToken");
            let r = await this.getOneAtlasTokenData();
            if (r) {
              this.getProductOptions();
            }
          }
        }
      }
    },
    async getAttempts(startDate) {
      if (!this.buttons.yes.data_loading) {
        this.buttons.yes.disable = true;
        this.buttons.yes.processing = true;
        this.buttons.yes.icon = "fas fa-spinner fa-spin";
        this.buttons.yes.data_loading = "oneatlas_get_attempts";
      }
      let productType = this.oneAtlas.productTypes.items.find(
        (x) => x.productTypeId === this.entity.oneAtlas.productType
      );
      let coors = this.entity.feature.getGeometry().getCoordinates();
      let coordinates4Api = [];
      for (let i = 0; i < coors[0].length; i++) {
        coordinates4Api.push(transform(coors[0][i], "EPSG:3857", "EPSG:4326"));
      }

      let payload = {
        progTypeNames: [
          productType && productType.technicalProduct
            ? productType.technicalProduct.target.id.toUpperCase()
            : null,
        ],
        missions: [
          productType && productType.technicalProduct
            ? productType.technicalProduct.sensor.id.toUpperCase()
            : null,
        ],
        contractId: this.oneAtlasContractId,
        acquisitionDate: {
          startDate: startDate ? startDate : new Date(),
          endDate: null,
        },
        optical: {
          acquisitionMode: "MONO",
          maxCloudCover: maxCloudCoverOneAtlasOneDay,
          maxIncidenceAngle: maxIncidenceAngleOneAtlasOneDay,
        },
        aoi: {
          type: "Polygon",
          coordinates: [coordinates4Api],
        },
      };
      this.oneAtlas.attempts = [
        {
          loading: true,
        },
      ];
      this.entity.oneAtlas.attempts = [];
      this.entity.oneAtlas.segmentIdsOld = [];
      this.entity.oneAtlas.segmentIds = [];
      this.entity.oneAtlas.attempt = null;
      this.entity.oneAtlas.segmentIdOld = null;
      this.entity.oneAtlas.segmentId = null;
      this.entity.oneAtlas.segmentKey = null;
      try {
        let response = await oneAtlasFunc.getAttempts(
          payload,
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );
        if (response && response.status === 200) {
          this.oneAtlas.attempts =
            response.data && response.data.progCapacities
              ? response.data.progCapacities
              : [];

          if (
            this.oneAtlas.attempts &&
            this.oneAtlas.attempts.length > 0 &&
            this.oneAtlas.attempts[0].progTypes &&
            this.oneAtlas.attempts[0].progTypes.length > 0
          ) {
            if (
              this.oneAtlas.attempts[0].progTypes[0].errors &&
              this.oneAtlas.attempts[0].progTypes[0].errors.length > 0
            ) {
              this.$emit(
                "message",
                this.oneAtlas.attempts[0].progTypes[0].errors.map((x) => x.code)
              );
            }
          }
        }
      } catch (error) {
        if (this.buttons.yes.data_loading === "oneatlas_get_attempts") {
          this.buttons.yes.disable = false;
          this.buttons.yes.processing = false;
          this.buttons.yes.icon = null;
          this.buttons.yes.data_loading = null;
        }
        if (this.oneAtlas.attempts && this.oneAtlas.attempts.length > 0)
          this.oneAtlas.attempts[0].loading = false;
        if (error && error.response && error.response.status === 401) {
          this.$store.dispatch("clearOneAtlasToken");
          let r = await this.getOneAtlasTokenData();
          if (r) {
            this.getAttempts(startDate);
          }
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (
            error.response.data.message ===
            "malformed authorization header, we found an Authorization header but it did not have the correct format"
          ) {
            this.$store.dispatch("clearOneAtlasToken");
            let r = await this.getOneAtlasTokenData();
            if (r) {
              this.getAttempts(startDate);
            }
          } else this.$emit("message", [error.response.data.message]);
        }
      }
      if (this.oneAtlas.attempts && this.oneAtlas.attempts.length > 0)
        this.oneAtlas.attempts[0].loading = false;

      if (this.buttons.yes.data_loading === "oneatlas_get_attempts") {
        this.buttons.yes.disable = false;
        this.buttons.yes.processing = false;
        this.buttons.yes.icon = null;
        this.buttons.yes.data_loading = null;
      }
    },
    async getProductTypes() {
      if (!this.buttons.yes.data_loading) {
        this.buttons.yes.disable = true;
        this.buttons.yes.processing = true;
        this.buttons.yes.icon = "fas fa-spinner fa-spin";
        this.buttons.yes.data_loading = "oneatlas_product_types";
      }
      this.oneAtlas.productTypes.loading = true;
      this.oneAtlas.productTypes.items = [];
      try {
        let response = await oneAtlasFunc.getProductTypes(
          this.oneAtlasContractId,
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );
        if (response && response.status === 200) {
          this.oneAtlas.productTypes.items =
            response.data && response.data.productTypes
              ? response.data.productTypes
              : [];
        }
      } catch (error) {
        this.oneAtlas.productTypes.loading = false;
        if (this.buttons.yes.data_loading === "oneatlas_product_types") {
          this.buttons.yes.disable = false;
          this.buttons.yes.processing = false;
          this.buttons.yes.icon = null;
          this.buttons.yes.data_loading = null;
        }
        if (error && error.response && error.response.status === 401) {
          this.$store.dispatch("clearOneAtlasToken");
          let r = await this.getOneAtlasTokenData();
          if (r) {
            this.getProductTypes();
          }
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (
            error.response.data.message ===
            "malformed authorization header, we found an Authorization header but it did not have the correct format"
          ) {
            this.$store.dispatch("clearOneAtlasToken");
            let r = await this.getOneAtlasTokenData();
            if (r) {
              this.getProductTypes();
            }
          } else this.$emit("message", [error.response.data.message]);
        }
      }
      this.oneAtlas.productTypes.loading = false;
      if (this.buttons.yes.data_loading === "oneatlas_product_types") {
        this.buttons.yes.disable = false;
        this.buttons.yes.processing = false;
        this.buttons.yes.icon = null;
        this.buttons.yes.data_loading = null;
      }
    },
    async getOneAtlasUserInfo() {
      var vm = this;
      return new Promise(async function (resolve, reject) {
        try {
          let response = await oneAtlasFunc.getUserMe(
            vm.$store.getters.getOneAtlasTokenType +
              " " +
              vm.$store.getters.getOneAtlasToken
          );
          if (response && response.status === 200) {
            await vm.$store.dispatch("setOneAtlasData", response.data);
            resolve(response.data);
          } else return resolve(null);
        } catch (error) {
          console.log(error);
          return resolve(null);
        }
      });
    },
    async getOneAtlasTokenData() {
      var vm = this;
      return new Promise(async function (resolve, reject) {
        try {
          let res = await oneAtlasFunc.getAccessToken();
          if (res && res.status === 200) {
            res.data.expires_time =
              new Date().getTime() +
              (res.data.expires_in ? res.data.expires_in * 1000 : 0);

            await localStorage.setItem(
              "oneatlas-token",
              btoa(cryptoFunc.encrypt(JSON.stringify(res.data)).toString())
            );
            await vm.$store.dispatch("setOneAtlasToken", res.data);
            resolve(1);
          } else {
            return resolve(null);
          }
        } catch (err) {
          console.log(err);
          resolve(null);
        }
      });
    },
    // End For OneAtlas
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    width() {
      if (this.documentWidth > 1280) {
        return 1200;
      } else {
        return this.documentWidth;
      }
    },
    height() {
      return (
        this.documentHeight - 80 - (this.maximized ? 0 : 2 * 48) - 66 - 4 // 66 is footer
      );
    },
    criteria() {
      return this.$store.getters.getSearchDto;
    },
    coordinates() {
      if (this.criteria && Object.keys(this.criteria).length > 0) {
        try {
          let obj = JSON.parse(this.criteria.boundary);
          if (obj && Object.keys(obj).length > 0) {
            if (obj.coordinates && obj.coordinates[0]) {
              let results = [];
              for (let i = 0; i < obj.coordinates[0].length; i++) {
                results.push({
                  lat: parseFloat(obj.coordinates[0][i][1].toFixed(5)),
                  lng: parseFloat(obj.coordinates[0][i][0].toFixed(5)),
                });
              }
              return results;
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
      return [];
    },
    oneAtlasData() {
      return this.$store.getters.getOneAtlasData;
    },
    oneAtlasContract() {
      let result = null;
      if (this.contracts && this.contracts.length > 0) {
        result = this.contracts[0];
      }
      try {
        if (!result) result = this.contracts.find((x) => x.contractId);
      } catch (error) {}
      return result;
    },
    oneAtlasContractId() {
      return this.oneAtlasContract ? this.oneAtlasContract.contractId : null;
    },
    oneAtlasWorkspaceId() {
      if (!this.oneAtlasData || Object.keys(this.oneAtlasData).length === 0)
        return null;
      else {
        return this.oneAtlasData.contract &&
          this.oneAtlasData.contract.workspaceId
          ? this.oneAtlasData.contract.workspaceId
          : null;
      }
    },
    productTypeSelected() {
      return this.oneAtlas.productTypes.items.find(
        (x) => x.productTypeId === this.entity.oneAtlas.productType
      );
    },
    optionLicenseLabel() {
      let option = this.entity.oneAtlas.options.find(
        (x) => x.name === "licence"
      );
      if (option && Object.keys(option).length && option.values) {
        let value = option.values.find((x) => x.id === option.selectedValue);
        return value ? value.label : null;
      }
      return null;
    },
    target() {
      if (
        this.productTypeSelected &&
        Object.keys(this.productTypeSelected).length > 0
      ) {
        return this.productTypeSelected.technicalProduct.target.id;
      }
      return null;
    },
  },
};
</script>
<style lang="scss" scoped>
.tasking-content {
  padding-bottom: 1px;
}
.footer {
  border-top: 0 none;
  background: #ffffff;
  color: #495057;
  padding: 1rem;
  text-align: right;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  position: sticky;
  bottom: 0;
}
</style>
<style lang="scss">
.dialog-tasking {
  .p-dialog-content {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}
</style>
