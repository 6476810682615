<template>
  <li>
    <div class="mt-1" v-if="imageType === 'RADAR'">
      <span
        >Look dir:<strong>{{ direction }}</strong></span
      >
      <span class="pl-1 pr-1">|</span>
      <span
        >Pol: <strong>{{ polLayer }}</strong></span
      >
      <span class="pl-1 pr-1">|</span>
      <span
        >Ord dir: <strong>{{ orbitDirection }}</strong></span
      >
    </div>
    <div class="mt-1" v-else>
      <span
        >Res ({{ resolutionUnit }}):<strong
          >&nbsp;{{
            propItem.properties ? propItem.properties.pixel_resolution : ""
          }}</strong
        ></span
      >
      <span class="pl-1 pr-1">|</span>
      <span
        >Inc Ang ({{ incidenceAngleUnit }}):
        <strong
          >&nbsp;{{
            propItem.properties
              ? propItem.properties["view:incidence_angle"]
              : ""
          }}</strong
        ></span
      >
      <span class="pl-1 pr-1">|</span>
      <span
        >Cloud (%):
        <strong
          >&nbsp;{{
            propItem.properties ? propItem.properties.cloud_percent : ""
          }}</strong
        ></span
      >
    </div>
  </li>
</template>
<script>
export default {
  props: {
    propItem: {
      type: Object,
      default: () => {},
    },
    propImageTypes: {
      type: Array,
      default: () => [],
    },
  },
  created() {},
  computed: {
    incidenceAngle() {
      let result = "";
      if (
        this.propItem &&
        Object.keys(this.propItem).length > 0 &&
        this.propItem.metadata
      ) {
        let incidenceAngle =
          this.propItem.info &&
          this.propItem.info.incidence_angle != null &&
          this.propItem.info.incidence_angle != undefined
            ? this.propItem.info.incidence_angle
            : null;
        if (!incidenceAngle) {
          let index = this.propItem.metadata.findIndex(
            (x) => x.key === "incidence_angle"
          );
          if (index >= 0) {
            incidenceAngle = this.propItem.metadata[index].value
              ? (this.propItem.metadata[index].value + "")
                  .trim()
                  .replace(/\s\s+/g, " ")
              : null;
          }
        }

        if (incidenceAngle) result = incidenceAngle;
      }
      return result;
    },

    incidenceAngleUnit() {
      let result = "°";
      if (
        this.propItem &&
        Object.keys(this.propItem).length > 0 &&
        this.propItem.metadata
      ) {
        let object = this.propItem.metadata
          ? this.propItem.metadata.find((x) => x.key === "incidence_angle")
          : null;
        result =
          object && object.unit && object.unit.trim().replace(/\s\s+/g, " ")
            ? object.unit.trim().replace(/\s\s+/g, " ")
            : "°";
      }
      return result;
    },
    cloudCoverage() {
      let result = "";
      if (
        this.propItem &&
        Object.keys(this.propItem).length > 0 &&
        this.propItem.metadata
      ) {
        let cloudCover =
          this.propItem.info &&
          this.propItem.info.cloud_coverage != null &&
          this.propItem.info.cloud_coverage != undefined
            ? this.propItem.info.cloud_coverage
            : null;
        if (!cloudCover) {
          let index = this.propItem.metadata.findIndex(
            (x) => x.key === "cloud_coverage"
          );
          if (index >= 0) {
            cloudCover = this.propItem.metadata[index].value
              ? (this.propItem.metadata[index].value + "")
                  .trim()
                  .replace(/\s\s+/g, " ")
              : null;
          }
        }

        if (cloudCover) result = cloudCover;
      }
      return result;
    },

    cloudCoverageUnit() {
      let result = "%";
      if (
        this.propItem &&
        Object.keys(this.propItem).length > 0 &&
        this.propItem.metadata
      ) {
        let object = this.propItem.metadata
          ? this.propItem.metadata.find((x) => x.key === "cloud_coverage")
          : null;
        result =
          object && object.unit && object.unit.trim().replace(/\s\s+/g, " ")
            ? object.unit.trim().replace(/\s\s+/g, " ")
            : "%";
      }
      return result;
    },
    resolution() {
      let result = "";
      if (
        this.propItem &&
        Object.keys(this.propItem).length > 0 &&
        this.propItem.metadata
      ) {
        let res =
          this.propItem.info &&
          this.propItem.info.resolution != null &&
          this.propItem.info.resolution != undefined
            ? this.propItem.info.resolution
            : null;
        if (!res) {
          let index = this.propItem.metadata.findIndex(
            (x) => x.key === "resolution"
          );
          if (index >= 0) {
            res = this.propItem.metadata[index].value
              ? (this.propItem.metadata[index].value + "")
                  .trim()
                  .replace(/\s\s+/g, " ")
              : null;
          }
        }

        if (res) result = res;
      }
      return result;
    },
    resolutionUnit() {
      let result = "m";
      if (
        this.propItem &&
        Object.keys(this.propItem).length > 0 &&
        this.propItem.metadata
      ) {
        let object = this.propItem.metadata
          ? this.propItem.metadata.find((x) => x.key === "resolution")
          : null;
        result =
          object && object.unit && object.unit.trim().replace(/\s\s+/g, " ")
            ? object.unit.trim().replace(/\s\s+/g, " ")
            : "m";
      }
      return result;
    },
    polLayer() {
      let result = null;
      if (
        this.propItem &&
        Object.keys(this.propItem).length > 0 &&
        this.propItem.metadata
      ) {
        let object = this.propItem.metadata
          ? this.propItem.metadata.find(
              (x) => x.key === "pol_layer" || x.key === "polarisation"
            )
          : null;
        result =
          object && object.value
            ? (object.value + "").trim().replace(/\s\s+/g, " ")
            : null;
      }
      return result;
    },

    direction() {
      let result = null;
      if (
        this.propItem &&
        Object.keys(this.propItem).length > 0 &&
        this.propItem.metadata
      ) {
        let object = this.propItem.metadata
          ? this.propItem.metadata.find(
              (x) => x.key === "direction" || x.key === "look_direction"
            )
          : null;
        result =
          object && object.value
            ? (object.value + "").trim().replace(/\s\s+/g, " ")
            : null;
      }
      return result;
    },

    beamMode() {
      let result = null;
      if (
        this.propItem &&
        Object.keys(this.propItem).length > 0 &&
        this.propItem.metadata
      ) {
        let object = this.propItem.metadata
          ? this.propItem.metadata.find(
              (x) => x.key === "beam_mode" || x.key === "mode"
            )
          : null;
        result =
          object && object.value
            ? (object.value + "").trim().replace(/\s\s+/g, " ")
            : null;
      }
      return result;
    },

    orbitDirection() {
      let result = null;
      if (
        this.propItem &&
        Object.keys(this.propItem).length > 0 &&
        this.propItem.metadata
      ) {
        let object = this.propItem.metadata
          ? this.propItem.metadata.find(
              (x) => x.key === "orbit_direction" || x.key === "orbit"
            )
          : null;
        result =
          object && object.value
            ? (object.value + "").trim().replace(/\s\s+/g, " ")
            : null;
      }
      return result;
    },
    satellite() {},
    imageType() {
      let result = "OPTICAL";
      if (
        this.propItem &&
        Object.keys(this.propItem).length > 0 &&
        this.propItem.info &&
        this.propItem.info.satellite
      ) {
        let type = this.propImageTypes.find(
          (x) =>
            x.sub_meta_key &&
            x.sub_meta_key.findIndex(
              (y) => y.id === this.propItem.info.satellite.id
            ) >= 0
        );
        result = type && type.name ? type.name : "OPTICAL";
      }
      return result;
    },
  },
};
</script>
<style lang="scss" scoped></style>
