<template>
  <div>
    <spinner :loading="loading" :color="'#000'" v-if="loading"></spinner>
    <pdf
      src="data/user_manual.pdf"
      v-show="loading"
      @num-pages="pageCount = new Array($event)"
    />
    <pdf
      v-for="(page, index) in pageCount"
      :key="index"
      @page-loaded="loading = false"
      src="data/user_manual.pdf"
      :page="index + 1"
    />
  </div>
</template>
<script>
import Spinner from "@/components/commons/spinners/Index";
import pdf from "vue-pdf";
export default {
  components: {
    pdf,
    Spinner,
  },
  data() {
    return {
      loading: true,
      pageCount: [],
    };
  },
  created() {
    this.$store.dispatch("setSpinnerApp", { loading: true });
  },
  watch: {
    loading(val) {
      if (!val) {
        this.$store.dispatch("setSpinnerApp", { loading: false });
      }
    },
  },
};
</script>
