<template>
  <div class="tasking-programing-service">
    <div class="row no-gutters">
      <div class="flex-container">
        <div
          :style="{ minWidth: '9rem' }"
          class="flex-item d-flex"
          v-for="item in propProgrammingService"
          :key="item.id"
        >
          <RadioButton
            :id="'radio' + item.id"
            :name="item.id"
            :value="item.id"
            v-model="propEntity.programming_service"
          />
          <label :for="'radio' + item.id" class="p-radiobutton-label pr-2">
            {{ item.value }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RadioButton from "@/components/commons/radiobutton/RadioButton";
export default {
  components: {
    RadioButton,
  },
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },
    propProgrammingService: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  created() {
    if (
      !this.propProgrammingService ||
      this.propProgrammingService.length === 0
    ) {
      this.$emit("getAllTaskingProgrammingService");
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.tasking-programing-service {
  padding: 0 0.5rem;
  width: 100%;
  color: $text-color;
  .flex-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1rem 0;
    .flex-item {
      text-align: center;
    }
  }
}
</style>
