const bundles = {
  Landsat8L1G: [
    {
      name: "Visual",
      description: "Optimized for visual analysis - RGB only",
      bands: "3-band",
      rectification: "orthorectified",
      radiometry: "visual",
      fileType: "GeoTIFF",
      assets: ["visual"],
      id: "visual",
      uniqueId: "Landsat8L1G::visual",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      auxiliaryFiles: "",
    },
    {
      name: "Analytic radiance (TOAR) - 4 band",
      description:
        "Calibrated to top of atmosphere radiance - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "",
      assets: [
        "analytic_b1",
        "analytic_b2",
        "analytic_b3",
        "analytic_b4",
        "analytic_b5",
        "analytic_b6",
        "analytic_b7",
        "analytic_b8",
        "analytic_b9",
        "analytic_b10",
        "analytic_b11",
        "analytic_bqa",
        "metadata_txt",
      ],
      id: "analytic",
      uniqueId: "Landsat8L1G::analytic",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
  ],
  PSOrthoTile: [
    {
      name: "Visual",
      description: "Optimized for visual analysis - RGB only",
      bands: "3-band",
      rectification: "orthorectified",
      radiometry: "visual",
      fileType: "GeoTIFF",
      assets: ["visual", "visual_xml"],
      id: "visual",
      uniqueId: "PSOrthoTile::visual",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      auxiliaryFiles: "",
    },
    {
      name: "Surface reflectance - 4 band",
      description:
        "Corrected for surface reflectance: recommended for most analytic applications - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "surface reflectance",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: ["analytic_sr", "udm", "udm2", "analytic_xml"],
      id: "analytic_sr_udm2",
      uniqueId: "PSOrthoTile::analytic_sr_udm2",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      fallback: "analytic_sr",
    },
    {
      name: "Surface reflectance - 4 band",
      description:
        "Corrected for surface reflectance: recommended for most analytic applications - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "surface reflectance",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["analytic_sr", "udm", "analytic_xml"],
      id: "analytic_sr",
      uniqueId: "PSOrthoTile::analytic_sr",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Analytic radiance (TOAR) - 4 band",
      description:
        "Calibrated to top of atmosphere radiance - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: ["analytic", "udm", "udm2", "analytic_xml"],
      id: "analytic_udm2",
      uniqueId: "PSOrthoTile::analytic_udm2",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      fallback: "analytic",
    },
    {
      name: "Analytic radiance (TOAR) - 4 band",
      description:
        "Calibrated to top of atmosphere radiance - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["analytic", "udm", "analytic_xml"],
      id: "analytic",
      uniqueId: "PSOrthoTile::analytic",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Analytic radiance (TOAR) - 5 band",
      description:
        "Calibrated to top of atmosphere radiance - includes RGB NIR Red-Edge",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: ["analytic_5b", "analytic_5b_xml", "udm", "udm2"],
      id: "analytic_5b_udm2",
      uniqueId: "PSOrthoTile::analytic_5b_udm2",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      fallback: "analytic_5b",
    },
    {
      name: "Analytic radiance (TOAR) - 5 band",
      description:
        "Calibrated to top of atmosphere radiance - includes RGB NIR Red-Edge",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["analytic_5b", "analytic_5b_xml", "udm"],
      id: "analytic_5b",
      uniqueId: "PSOrthoTile::analytic_5b",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Uncalibrated DN",
      description:
        "Uncalibrated digital numbers, suitable for custom radiometric processing",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "digital numbers",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: ["analytic_dn", "analytic_dn_xml", "udm", "udm2"],
      id: "uncalibrated_dn_udm2",
      uniqueId: "PSOrthoTile::uncalibrated_dn_udm2",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      fallback: "uncalibrated_dn",
    },
    {
      name: "Uncalibrated DN",
      description:
        "Uncalibrated digital numbers, suitable for custom radiometric processing",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "digital numbers",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["analytic_dn", "udm", "analytic_dn_xml"],
      id: "uncalibrated_dn",
      uniqueId: "PSOrthoTile::uncalibrated_dn",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
  ],
  PSScene3Band: [
    {
      name: "Visual",
      description: "Optimized for visual analysis - RGB only",
      bands: "3-band",
      rectification: "orthorectified",
      radiometry: "visual",
      fileType: "GeoTIFF",
      assets: ["visual", "visual_xml"],
      id: "visual",
      uniqueId: "PSScene3Band::visual",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      auxiliaryFiles: "",
    },
    {
      name: "Analytic radiance (TOAR) - 4 band",
      description:
        "Calibrated to top of atmosphere radiance - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["analytic", "analytic_xml", "udm"],
      id: "analytic",
      uniqueId: "PSScene3Band::analytic",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Uncalibrated DN",
      description:
        "Uncalibrated digital numbers, suitable for custom radiometric processing",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "digital numbers",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["analytic_dn", "analytic_dn_xml", "udm"],
      id: "uncalibrated_dn",
      uniqueId: "PSScene3Band::uncalibrated_dn",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Basic analytic radiance (TOAR)",
      description:
        "Calibrated to top of atmosphere radiance - includes RPC text file for georeferencing",
      bands: "all",
      rectification: "non-orthorectified",
      radiometry: "at-sensor",
      fileType: "TIFF and RPCs",
      auxiliaryFiles: "UDM",
      assets: [
        "basic_analytic",
        "basic_udm",
        "basic_analytic_rpc",
        "basic_analytic_xml",
      ],
      id: "basic_analytic",
      uniqueId: "PSScene3Band::basic_analytic",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
    },
    {
      name: "Basic uncalibrated DN",
      description:
        "Uncalibrated digital numbers - includes RPC text file for georeferencing",
      bands: "all",
      rectification: "non-orthorectified",
      radiometry: "digital numbers",
      fileType: "TIFF and RPCs",
      auxiliaryFiles: "UDM",
      assets: [
        "basic_analytic_dn",
        "basic_analytic_dn_xml",
        "basic_udm",
        "basic_analytic_dn_rpc",
      ],
      id: "basic_uncalibrated_dn",
      uniqueId: "PSScene3Band::basic_uncalibrated_dn",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
    },
  ],
  PSScene4Band: [
    {
      name: "Surface reflectance - 4 band",
      description:
        "Corrected for surface reflectance: recommended for most analytic applications - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "surface reflectance",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: ["analytic_sr", "analytic_xml", "udm", "udm2"],
      id: "analytic_sr_udm2",
      uniqueId: "PSScene4Band::analytic_sr_udm2",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      fallback: "analytic_sr",
    },
    {
      name: "Surface reflectance - 4 band",
      description:
        "Corrected for surface reflectance: recommended for most analytic applications - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "surface reflectance",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["analytic_sr", "udm", "analytic_xml"],
      id: "analytic_sr",
      uniqueId: "PSScene4Band::analytic_sr",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Analytic radiance (TOAR) - 4 band",
      description:
        "Calibrated to top of atmosphere radiance - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: ["analytic", "analytic_xml", "udm", "udm2"],
      id: "analytic_udm2",
      uniqueId: "PSScene4Band::analytic_udm2",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      fallback: "analytic",
    },
    {
      name: "Analytic radiance (TOAR) - 4 band",
      description:
        "Calibrated to top of atmosphere radiance - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["analytic", "analytic_xml", "udm"],
      id: "analytic",
      uniqueId: "PSScene4Band::analytic",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Uncalibrated DN",
      description:
        "Uncalibrated digital numbers, suitable for custom radiometric processing",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "digital numbers",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: ["analytic_dn", "analytic_dn_xml", "udm", "udm2"],
      id: "uncalibrated_dn_udm2",
      uniqueId: "PSScene4Band::uncalibrated_dn_udm2",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      fallback: "uncalibrated_dn",
    },
    {
      name: "Uncalibrated DN",
      description:
        "Uncalibrated digital numbers, suitable for custom radiometric processing",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "digital numbers",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["analytic_dn", "analytic_dn_xml", "udm"],
      id: "uncalibrated_dn",
      uniqueId: "PSScene4Band::uncalibrated_dn",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Basic analytic radiance (TOAR) - 4 band",
      description:
        "Calibrated to top of atmosphere radiance: includes RPC text file for georeferencing - includes RGB NIR",
      bands: "all",
      rectification: "non-orthorectified",
      radiometry: "at-sensor",
      fileType: "TIFF and RPCs",
      auxiliaryFiles: "UDM2",
      assets: [
        "basic_analytic",
        "basic_udm",
        "basic_udm2",
        "basic_analytic_rpc",
        "basic_analytic_xml",
      ],
      id: "basic_analytic_udm2",
      uniqueId: "PSScene4Band::basic_analytic_udm2",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
      fallback: "basic_analytic",
    },
    {
      name: "Basic analytic radiance (TOAR)",
      description:
        "Calibrated to top of atmosphere radiance - includes RPC text file for georeferencing",
      bands: "all",
      rectification: "non-orthorectified",
      radiometry: "at-sensor",
      fileType: "TIFF and RPCs",
      auxiliaryFiles: "UDM",
      assets: [
        "basic_analytic",
        "basic_udm",
        "basic_analytic_rpc",
        "basic_analytic_xml",
      ],
      id: "basic_analytic",
      uniqueId: "PSScene4Band::basic_analytic",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
    },
    {
      name: "Basic uncalibrated DN",
      description:
        "Uncalibrated digital numbers - includes RPC text file for georeferencing",
      bands: "all",
      rectification: "non-orthorectified",
      radiometry: "digital numbers",
      fileType: "TIFF and RPCs",
      auxiliaryFiles: "UDM2",
      assets: [
        "basic_analytic_dn",
        "basic_analytic_dn_xml",
        "basic_udm",
        "basic_udm2",
        "basic_analytic_dn_rpc",
      ],
      id: "basic_uncalibrated_dn_udm2",
      uniqueId: "PSScene4Band::basic_uncalibrated_dn_udm2",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
      fallback: "basic_uncalibrated_dn",
    },
    {
      name: "Basic uncalibrated DN",
      description:
        "Uncalibrated digital numbers - includes RPC text file for georeferencing",
      bands: "all",
      rectification: "non-orthorectified",
      radiometry: "digital numbers",
      fileType: "TIFF and RPCs",
      auxiliaryFiles: "UDM",
      assets: [
        "basic_analytic_dn",
        "basic_analytic_dn_xml",
        "basic_udm",
        "basic_analytic_dn_rpc",
      ],
      id: "basic_uncalibrated_dn",
      uniqueId: "PSScene4Band::basic_uncalibrated_dn",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
    },
    {
      name: "Basic Uncalibrated DN - NITF",
      description:
        "Uncalibrated digital numbers - includes RPC text file for georeferencing",
      bands: "all",
      rectification: "non-orthorectified",
      radiometry: "digital numbers",
      fileType: "NITF",
      auxiliaryFiles: "UDM2",
      assets: [
        "basic_analytic_dn_nitf",
        "basic_analytic_dn_xml_nitf",
        "basic_analytic_dn_rpc_nitf",
        "basic_udm",
        "basic_udm2",
      ],
      id: "basic_uncalibrated_dn_nitf_udm2",
      uniqueId: "PSScene4Band::basic_uncalibrated_dn_nitf_udm2",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
      fallback: "basic_uncalibrated_dn_nitf",
    },
    {
      name: "Basic Uncalibrated DN - NITF",
      description:
        "Uncalibrated digital numbers - includes RPC text file for georeferencing",
      bands: "all",
      rectification: "non-orthorectified",
      radiometry: "digital numbers",
      fileType: "NITF",
      auxiliaryFiles: "UDM",
      assets: [
        "basic_analytic_dn_nitf",
        "basic_analytic_dn_xml_nitf",
        "basic_analytic_dn_rpc_nitf",
        "basic_udm",
      ],
      id: "basic_uncalibrated_dn_nitf",
      uniqueId: "PSScene4Band::basic_uncalibrated_dn_nitf",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
    },
    {
      name: "Basic Analytic Radiance (TOAR) – NITF",
      description:
        " Calibrated to top of atmosphere radiance - includes RPC text file for georeferencing",
      bands: "all",
      rectification: "non-orthorectified",
      radiometry: "at-sensor",
      fileType: "NITF",
      auxiliaryFiles: "UDM2",
      assets: [
        "basic_analytic_nitf",
        "basic_analytic_rpc_nitf",
        "basic_analytic_xml_nitf",
        "basic_udm",
        "basic_udm2",
      ],
      id: "basic_analytic_nitf_udm2",
      uniqueId: "PSScene4Band::basic_analytic_nitf_udm2",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
      fallback: "basic_analytic_nitf",
    },
    {
      name: "Basic Analytic Radiance (TOAR) – NITF",
      description:
        " Calibrated to top of atmosphere radiance - includes RPC text file for georeferencing",
      bands: "all",
      rectification: "non-orthorectified",
      radiometry: "at-sensor",
      fileType: "NITF",
      auxiliaryFiles: "UDM",
      assets: [
        "basic_analytic_nitf",
        "basic_analytic_rpc_nitf",
        "basic_analytic_xml_nitf",
        "basic_udm",
      ],
      id: "basic_analytic_nitf",
      uniqueId: "PSScene4Band::basic_analytic_nitf",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
    },
  ],
  REOrthoTile: [
    {
      name: "Visual",
      description: "Optimized for visual analysis - RGB only",
      bands: "3-band",
      rectification: "orthorectified",
      radiometry: "visual",
      fileType: "GeoTIFF",
      assets: ["visual", "visual_xml"],
      id: "visual",
      uniqueId: "REOrthoTile::visual",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      auxiliaryFiles: "",
    },
    {
      name: "Surface reflectance - 4 band",
      description:
        "Corrected for surface reflectance: recommended for most analytic applications - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "surface reflectance",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["analytic_sr", "udm", "analytic_xml"],
      id: "analytic_sr",
      uniqueId: "REOrthoTile::analytic_sr",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Analytic radiance (TOAR) - 4 band",
      description:
        "Calibrated to top of atmosphere radiance - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["analytic", "analytic_xml", "udm"],
      id: "analytic",
      uniqueId: "REOrthoTile::analytic",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
  ],
  Sentinel1: [
    {
      name: "Analytic radiance (TOAR) - 4 band",
      description:
        "Calibrated to top of atmosphere radiance - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "",
      assets: ["ortho_analytic_vv", "ortho_analytic_vh"],
      id: "analytic",
      uniqueId: "Sentinel1::analytic",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
  ],
  Sentinel2L1C: [
    {
      name: "Visual",
      description: "Optimized for visual analysis - RGB only",
      bands: "3-band",
      rectification: "orthorectified",
      radiometry: "visual",
      fileType: "GeoTIFF",
      assets: ["visual"],
      id: "visual",
      uniqueId: "Sentinel2L1C::visual",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      auxiliaryFiles: "",
    },
    {
      name: "Analytic radiance (TOAR) - 4 band",
      description:
        "Calibrated to top of atmosphere radiance - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "",
      assets: [
        "analytic_b1",
        "analytic_b2",
        "analytic_b3",
        "analytic_b4",
        "analytic_b5",
        "analytic_b6",
        "analytic_b7",
        "analytic_b8",
        "analytic_b8a",
        "analytic_b9",
        "analytic_b10",
        "analytic_b11",
        "analytic_b12",
        "metadata_aux",
      ],
      id: "analytic",
      uniqueId: "Sentinel2L1C::analytic",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
  ],
  SkySatCollect: [
    {
      name: "Visual",
      description: "Optimized for visual analysis - RGB only",
      bands: "3-band",
      rectification: "orthorectified",
      radiometry: "visual",
      fileType: "GeoTIFF",
      assets: ["ortho_visual"],
      id: "visual",
      uniqueId: "SkySatCollect::visual",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      auxiliaryFiles: "",
    },
    {
      name: "Ortho-pansharpened",
      description: "Pansharpened, color corrected, 4-band multispectral data",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "digital numbers",
      fileType: "GeoTIFF",
      enhancements: "pansharpen",
      auxiliaryFiles: "UDM2",
      assets: [
        "ortho_pansharpened",
        "ortho_pansharpened_udm",
        "ortho_pansharpened_udm2",
      ],
      id: "pansharpened_udm2",
      uniqueId: "SkySatCollect::pansharpened_udm2",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      fallback: "pansharpened",
    },
    {
      name: "Ortho-pansharpened",
      description: "Pansharpened, color corrected, 4-band multispectral data",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "digital numbers",
      fileType: "GeoTIFF",
      enhancements: "pansharpen",
      auxiliaryFiles: "UDM",
      assets: ["ortho_pansharpened", "ortho_pansharpened_udm"],
      id: "pansharpened",
      uniqueId: "SkySatCollect::pansharpened",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Surface reflectance - 4 band",
      description:
        "Corrected for surface reflectance: recommended for most analytic applications - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "surface reflectance",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: [
        "ortho_analytic_sr",
        "ortho_analytic_udm",
        "ortho_analytic_udm2",
      ],
      id: "analytic_sr_udm2",
      uniqueId: "SkySatCollect::analytic_sr_udm2",
      canClip: false,
      canHarmonize: false,
      canReproject: true,
      fallback: "analytic_sr",
    },
    {
      name: "Surface reflectance - 4 band",
      description:
        "Corrected for surface reflectance: recommended for most analytic applications - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "surface reflectance",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["ortho_analytic_sr", "ortho_analytic_udm"],
      id: "analytic_sr",
      uniqueId: "SkySatCollect::analytic_sr",
      canClip: false,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Analytic radiance (TOAR) - 4 band",
      description:
        "Calibrated to top of atmosphere radiance - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: ["ortho_analytic", "ortho_analytic_udm", "ortho_analytic_udm2"],
      id: "analytic_udm2",
      uniqueId: "SkySatCollect::analytic_udm2",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      fallback: "analytic",
    },
    {
      name: "Analytic radiance (TOAR) - 4 band",
      description:
        "Calibrated to top of atmosphere radiance - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["ortho_analytic", "ortho_analytic_udm"],
      id: "analytic",
      uniqueId: "SkySatCollect::analytic",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Panchromatic",
      description:
        "Panchromatic band, calibrated to top of atmosphere radiance",
      bands: "panchromatic",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["ortho_panchromatic", "ortho_panchromatic_udm"],
      id: "panchromatic",
      uniqueId: "SkySatCollect::panchromatic",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Panchromatic DN",
      description:
        "Uncalibrated panchromatic band - suitable for custom radiometric processing",
      bands: "panchromatic",
      rectification: "orthorectified",
      radiometry: "digital numbers",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: [
        "ortho_panchromatic_dn",
        "ortho_panchromatic_udm",
        "ortho_panchromatic_udm2",
      ],
      id: "panchromatic_dn_udm2",
      uniqueId: "SkySatCollect::panchromatic_dn_udm2",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      fallback: "panchromatic_dn",
    },
    {
      name: "Panchromatic DN",
      description:
        "Uncalibrated panchromatic band - suitable for custom radiometric processing",
      bands: "panchromatic",
      rectification: "orthorectified",
      radiometry: "digital numbers",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["ortho_panchromatic_dn", "ortho_panchromatic_udm"],
      id: "panchromatic_dn",
      uniqueId: "SkySatCollect::panchromatic_dn",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Uncalibrated DN",
      description:
        "Uncalibrated digital numbers, suitable for custom radiometric processing",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "digital numbers",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: [
        "ortho_analytic_dn",
        "ortho_analytic_udm",
        "ortho_analytic_udm2",
      ],
      id: "uncalibrated_dn_udm2",
      uniqueId: "SkySatCollect::uncalibrated_dn_udm2",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      fallback: "uncalibrated_dn",
    },
    {
      name: "Uncalibrated DN",
      description:
        "Uncalibrated digital numbers, suitable for custom radiometric processing",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "digital numbers",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["ortho_analytic_dn", "ortho_analytic_udm"],
      id: "uncalibrated_dn",
      uniqueId: "SkySatCollect::uncalibrated_dn",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
  ],
  SkySatScene: [
    {
      name: "Visual",
      description: "Optimized for visual analysis - RGB only",
      bands: "3-band",
      rectification: "orthorectified",
      radiometry: "visual",
      fileType: "GeoTIFF",
      assets: ["ortho_visual"],
      id: "visual",
      uniqueId: "SkySatScene::visual",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      auxiliaryFiles: "",
    },
    {
      name: "Ortho-pansharpened",
      description: "Pansharpened, color corrected, 4-band multispectral data",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "digital numbers",
      fileType: "GeoTIFF",
      enhancements: "pansharpen",
      auxiliaryFiles: "UDM2",
      assets: [
        "ortho_pansharpened",
        "ortho_pansharpened_udm",
        "ortho_pansharpened_udm2",
      ],
      id: "pansharpened_udm2",
      uniqueId: "SkySatScene::pansharpened_udm2",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      fallback: "pansharpened",
    },
    {
      name: "Ortho-pansharpened",
      description: "Pansharpened, color corrected, 4-band multispectral data",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "digital numbers",
      fileType: "GeoTIFF",
      enhancements: "pansharpen",
      auxiliaryFiles: "UDM",
      assets: ["ortho_pansharpened", "ortho_pansharpened_udm"],
      id: "pansharpened",
      uniqueId: "SkySatScene::pansharpened",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Surface reflectance - 4 band",
      description:
        "Corrected for surface reflectance: recommended for most analytic applications - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "surface reflectance",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: [
        "ortho_analytic_sr",
        "ortho_analytic_udm",
        "ortho_analytic_udm2",
      ],
      id: "analytic_sr_udm2",
      uniqueId: "SkySatScene::analytic_sr_udm2",
      canClip: false,
      canHarmonize: false,
      canReproject: true,
      fallback: "analytic_sr",
    },
    {
      name: "Surface reflectance - 4 band",
      description:
        "Corrected for surface reflectance: recommended for most analytic applications - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "surface reflectance",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["ortho_analytic_sr", "ortho_analytic_udm"],
      id: "analytic_sr",
      uniqueId: "SkySatScene::analytic_sr",
      canClip: false,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Analytic radiance (TOAR) - 4 band",
      description:
        "Calibrated to top of atmosphere radiance - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: ["ortho_analytic", "ortho_analytic_udm", "ortho_analytic_udm2"],
      id: "analytic_udm2",
      uniqueId: "SkySatScene::analytic_udm2",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      fallback: "analytic",
    },
    {
      name: "Analytic radiance (TOAR) - 4 band",
      description:
        "Calibrated to top of atmosphere radiance - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["ortho_analytic", "ortho_analytic_udm"],
      id: "analytic",
      uniqueId: "SkySatScene::analytic",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Panchromatic",
      description:
        "Panchromatic band, calibrated to top of atmosphere radiance",
      bands: "panchromatic",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["ortho_panchromatic", "ortho_panchromatic_udm"],
      id: "panchromatic",
      uniqueId: "SkySatScene::panchromatic",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Panchromatic DN",
      description:
        "Uncalibrated panchromatic band - suitable for custom radiometric processing",
      bands: "panchromatic",
      rectification: "orthorectified",
      radiometry: "digital numbers",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: [
        "ortho_panchromatic_dn",
        "ortho_panchromatic_udm",
        "ortho_panchromatic_udm2",
      ],
      id: "panchromatic_dn_udm2",
      uniqueId: "SkySatScene::panchromatic_dn_udm2",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      fallback: "panchromatic_dn",
    },
    {
      name: "Panchromatic DN",
      description:
        "Uncalibrated panchromatic band - suitable for custom radiometric processing",
      bands: "panchromatic",
      rectification: "orthorectified",
      radiometry: "digital numbers",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["ortho_panchromatic_dn", "ortho_panchromatic_udm"],
      id: "panchromatic_dn",
      uniqueId: "SkySatScene::panchromatic_dn",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Uncalibrated DN",
      description:
        "Uncalibrated digital numbers, suitable for custom radiometric processing",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "digital numbers",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: [
        "ortho_analytic_dn",
        "ortho_analytic_udm",
        "ortho_analytic_udm2",
      ],
      id: "uncalibrated_dn_udm2",
      uniqueId: "SkySatScene::uncalibrated_dn_udm2",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      fallback: "uncalibrated_dn",
    },
    {
      name: "Uncalibrated DN",
      description:
        "Uncalibrated digital numbers, suitable for custom radiometric processing",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "digital numbers",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM",
      assets: ["ortho_analytic_dn", "ortho_analytic_udm"],
      id: "uncalibrated_dn",
      uniqueId: "SkySatScene::uncalibrated_dn",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Basic analytic radiance (TOAR) - 4 band",
      description:
        "Calibrated to top of atmosphere radiance: includes RPC text file for georeferencing - includes RGB NIR",
      bands: "all",
      rectification: "non-orthorectified",
      radiometry: "at-sensor",
      fileType: "TIFF and RPCs",
      auxiliaryFiles: "UDM2",
      assets: [
        "basic_analytic",
        "basic_analytic_rpc",
        "basic_analytic_udm",
        "basic_analytic_udm2",
      ],
      id: "basic_analytic_udm2",
      uniqueId: "SkySatScene::basic_analytic_udm2",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
      fallback: "basic_analytic",
    },
    {
      name: "Basic analytic radiance (TOAR)",
      description:
        "Calibrated to top of atmosphere radiance - includes RPC text file for georeferencing",
      bands: "all",
      rectification: "non-orthorectified",
      radiometry: "at-sensor",
      fileType: "TIFF and RPCs",
      auxiliaryFiles: "UDM",
      assets: ["basic_analytic", "basic_analytic_rpc", "basic_analytic_udm"],
      id: "basic_analytic",
      uniqueId: "SkySatScene::basic_analytic",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
    },
    {
      name: "Basic uncalibrated DN",
      description:
        "Uncalibrated digital numbers - includes RPC text file for georeferencing",
      bands: "all",
      rectification: "non-orthorectified",
      radiometry: "digital numbers",
      fileType: "TIFF and RPCs",
      auxiliaryFiles: "UDM2",
      assets: [
        "basic_analytic_dn",
        "basic_analytic_dn_rpc",
        "basic_analytic_udm",
        "basic_analytic_udm2",
      ],
      id: "basic_uncalibrated_dn_udm2",
      uniqueId: "SkySatScene::basic_uncalibrated_dn_udm2",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
      fallback: "basic_uncalibrated_dn",
    },
    {
      name: "Basic uncalibrated DN",
      description:
        "Uncalibrated digital numbers - includes RPC text file for georeferencing",
      bands: "all",
      rectification: "non-orthorectified",
      radiometry: "digital numbers",
      fileType: "TIFF and RPCs",
      auxiliaryFiles: "UDM",
      assets: [
        "basic_analytic_dn",
        "basic_analytic_dn_rpc",
        "basic_analytic_udm",
      ],
      id: "basic_uncalibrated_dn",
      uniqueId: "SkySatScene::basic_uncalibrated_dn",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
    },
    {
      name: "Basic panchromatic",
      description:
        "Unrectified panchromatic band - includes RPC text file for georeferencing",
      bands: "panchromatic",
      rectification: "non-orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      assets: [
        "basic_panchromatic",
        "basic_panchromatic_rpc",
        "basic_panchromatic_udm2",
      ],
      id: "basic_panchromatic",
      uniqueId: "SkySatScene::basic_panchromatic",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
      auxiliaryFiles: "UDM2",
    },
    {
      name: "Basic panchromatic DN",
      description:
        "Unrectified, panchromatic band, uncalibrated digital numbers - includes RPC text file for georeferencing",
      bands: "panchromatic",
      rectification: "non-orthorectified",
      radiometry: "digital numbers",
      fileType: "TIFF and RPCs",
      assets: [
        "basic_panchromatic_dn",
        "basic_panchromatic_dn_rpc",
        "basic_panchromatic_udm2",
      ],
      id: "basic_panchromatic_dn",
      uniqueId: "SkySatScene::basic_panchromatic_dn",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
      auxiliaryFiles: "UDM2",
    },
    {
      name: "Basic L1A",
      description:
        "Unrectified, uncalibrated panchromatic band only, no super resolution applied - includes RPC text file for georeferencing",
      bands: "panchromatic",
      rectification: "non-orthorectified",
      radiometry: "digital numbers",
      fileType: "TIFF and RPCs",
      assets: ["basic_l1a_panchromatic_dn", "basic_l1a_panchromatic_dn_rpc"],
      id: "basic_l1a_dn",
      uniqueId: "SkySatScene::basic_l1a_dn",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
      auxiliaryFiles: "",
    },
  ],
  PSScene: [
    {
      name: "Visual",
      description: "Optimized for visual analysis - RGB only",
      bands: "3-band",
      rectification: "orthorectified",
      radiometry: "visual",
      fileType: "GeoTIFF",
      assets: ["ortho_visual"],
      id: "visual",
      uniqueId: "PSScene::visual",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
      auxiliaryFiles: "",
    },
    {
      name: "Surface reflectance - 4 band",
      description:
        "Corrected for surface reflectance: recommended for most analytic applications - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "surface reflectance",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: ["ortho_analytic_4b_sr", "ortho_analytic_4b_xml", "ortho_udm2"],
      id: "analytic_sr_udm2",
      uniqueId: "PSScene::analytic_sr_udm2",
      canClip: true,
      canHarmonize: true,
      canReproject: true,
    },
    {
      name: "Surface reflectance - 8 band",
      description:
        "Corrected for surface reflectance: recommended for most analytic applications - also includes coastal blue, green II, yellow, red edge",
      bands: "8-band",
      rectification: "orthorectified",
      radiometry: "surface reflectance",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: ["ortho_analytic_8b_sr", "ortho_analytic_8b_xml", "ortho_udm2"],
      id: "analytic_8b_sr_udm2",
      uniqueId: "PSScene::analytic_8b_sr_udm2",
      canClip: true,
      canHarmonize: true,
      canReproject: true,
    },
    {
      name: "Analytic radiance (TOAR) - 3 band",
      description: "Calibrated to top of atmosphere radiance - RGB only",
      bands: "3-band",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: ["ortho_analytic_3b", "ortho_analytic_3b_xml", "ortho_udm2"],
      id: "analytic_3b_udm2",
      uniqueId: "PSScene::analytic_3b_udm2",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Analytic radiance (TOAR) - 4 band",
      description:
        "Calibrated to top of atmosphere radiance - includes RGB NIR",
      bands: "all",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: ["ortho_analytic_4b", "ortho_analytic_4b_xml", "ortho_udm2"],
      id: "analytic_udm2",
      uniqueId: "PSScene::analytic_udm2",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Analytic radiance (TOAR) - 8 band",
      description:
        "Calibrated to top of atmosphere radiance - also includes coastal blue, green II, yellow, red edge",
      bands: "8-band",
      rectification: "orthorectified",
      radiometry: "at-sensor",
      fileType: "GeoTIFF",
      auxiliaryFiles: "UDM2",
      assets: ["ortho_analytic_8b", "ortho_analytic_8b_xml", "ortho_udm2"],
      id: "analytic_8b_udm2",
      uniqueId: "PSScene::analytic_8b_udm2",
      canClip: true,
      canHarmonize: false,
      canReproject: true,
    },
    {
      name: "Basic analytic radiance (TOAR) - 4 band",
      description:
        "Calibrated to top of atmosphere radiance: includes RPC text file for georeferencing - includes RGB NIR",
      bands: "all",
      rectification: "non-orthorectified",
      radiometry: "at-sensor",
      fileType: "TIFF and RPCs",
      auxiliaryFiles: "UDM2",
      assets: [
        "basic_analytic_4b",
        "basic_udm2",
        "basic_analytic_4b_rpc",
        "basic_analytic_4b_xml",
      ],
      id: "basic_analytic_udm2",
      uniqueId: "PSScene::basic_analytic_udm2",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
    },
    {
      name: "Basic analytic radiance (TOAR) - 8 band",
      description:
        "Calibrated to top of atmosphere radiance: includes RPC text file for georeferencing - also includes coastal blue, green II, yellow, red edge",
      bands: "8-band",
      rectification: "non-orthorectified",
      radiometry: "at-sensor",
      fileType: "TIFF and RPCs",
      auxiliaryFiles: "UDM2",
      assets: [
        "basic_analytic_8b",
        "basic_udm2",
        "basic_analytic_4b_rpc",
        "basic_analytic_8b_xml",
      ],
      id: "basic_analytic_8b_udm2",
      uniqueId: "PSScene::basic_analytic_8b_udm2",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
    },
  ],
  REScene: [
    {
      name: "Basic analytic radiance (TOAR)",
      description:
        "Calibrated to top of atmosphere radiance - includes RPC text file for georeferencing",
      bands: "all",
      rectification: "non-orthorectified",
      radiometry: "at-sensor",
      fileType: "TIFF and RPCs",
      auxiliaryFiles: "UDM",
      assets: [
        "basic_analytic_b1",
        "basic_analytic_b2",
        "basic_analytic_b3",
        "basic_analytic_b4",
        "basic_analytic_b5",
        "basic_analytic_xml",
        "basic_analytic_rpc",
        "basic_udm",
        "basic_analytic_sci",
        "browse",
      ],
      id: "basic_analytic",
      uniqueId: "REScene::basic_analytic",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
    },
    {
      name: "Basic Analytic Radiance (TOAR) – NITF",
      description:
        " Calibrated to top of atmosphere radiance - includes RPC text file for georeferencing",
      bands: "all",
      rectification: "non-orthorectified",
      radiometry: "at-sensor",
      fileType: "NITF",
      auxiliaryFiles: "UDM",
      assets: [
        "basic_analytic_b1_nitf",
        "basic_analytic_b2_nitf",
        "basic_analytic_b3_nitf",
        "basic_analytic_b4_nitf",
        "basic_analytic_b5_nitf",
        "basic_analytic_xml_nitf",
        "basic_analytic_rpc",
        "basic_udm",
        "basic_analytic_sci",
        "browse",
      ],
      id: "basic_analytic_nitf",
      uniqueId: "REScene::basic_analytic_nitf",
      canClip: false,
      canHarmonize: false,
      canReproject: false,
    },
  ],
};
export default bundles;
