var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid tasking-content"},[_c('div',{staticClass:"dialog"},[(_vm.dialogDetail.show)?_c('DialogDetail',{attrs:{"prop-show":_vm.dialogDetail.show,"prop-item":_vm.dialogDetail.item},on:{"close":function($event){_vm.dialogDetail.show = false;
        _vm.dialogDetail.item = null;}}}):_vm._e(),(_vm.confirmDelete.show)?_c('DialogConfirmation',{attrs:{"prop-show":_vm.confirmDelete.show,"prop-title":_vm.$t('title_message_confirm'),"prop-icon":_vm.confirmDelete.icon,"prop-message":_vm.confirmDelete.message,"textNo":_vm.$t('button_text_close'),"textYes":_vm.$t('button_text_confirm')},on:{"cancel":_vm.onCancelConfirmDelete,"confirmed":_vm.onConfirmedDelete}}):_vm._e(),(_vm.message.length > 0)?_c('DialogMessage',{attrs:{"propTitle":_vm.$t('title_message_info'),"prop-show":_vm.message.length > 0,"prop-message":_vm.message},on:{"close":function($event){_vm.message = []}}}):_vm._e()],1),_c('div',{staticClass:"tasking-oneatlats"},[_c('div',{staticClass:"oneatlats-tabs"},[_c('div',{staticClass:"tabs-search"},[_c('label',{staticClass:"search-field relative flex items-center cursor-pointer self-center justify-between"},[_c('i',{staticClass:"fas fa-search"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customerRef),expression:"customerRef"}],staticClass:"form-control",attrs:{"type":"search","placeholder":"Customer reference","maxlength":"50"},domProps:{"value":(_vm.customerRef)},on:{"input":function($event){if($event.target.composing){ return; }_vm.customerRef=$event.target.value}}})])]),_c('div',{staticClass:"relative"},[(
            _vm.dataInprogress.loading ||
            _vm.dataCompleted.loading ||
            _vm.dataCancelled.loading
          )?_c('div',{staticClass:"images-spinners Iybw1Bhj3Jhd8s"},[_c('TileSpinner',{staticClass:"ehAbnmg4Jwb9BS",attrs:{"loading":_vm.dataInprogress.loading ||
              _vm.dataCompleted.loading ||
              _vm.dataCancelled.loading,"height":'40',"width":'40',"color":'fd853e'}})],1):_vm._e(),_c('Tabs',{attrs:{"prop-tabs-height":_vm.mapComponentHeight,"prop-data":{
            inprogress: _vm.dataInprogress,
            completed: _vm.dataCompleted,
            cancelled: _vm.dataCancelled,
          },"prop-footprint-ids":_vm.footprintIds},on:{"tabChanged":_vm.tabChanged,"pagechanged":_vm.pagechanged,"mouseOverItem":_vm.mouseOverItem,"mouseLeaveItem":_vm.mouseLeaveItem,"addFootprint":_vm.addFootprint,"pan2Location":_vm.pan2Location,"showDetail":_vm.showDetail,"cancelTasking":_vm.cancelTasking}})],1)]),_c('div',{staticClass:"oneatlats-map",style:({ height: _vm.mapComponentHeight + 'px' })},[_c('TaskingMap',{on:{"initMap":function($event){_vm.map = $event.map;
          _vm.layer = $event.layer;}}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }