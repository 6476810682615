const SET_SPINNER_APP = "SET_SPINNER_APP";
const SET_HEIGHT_DOCUMENT = "SET_HEIGHT_DOCUMENT";
const SET_WIDTH_DOCUMENT = "SET_WIDTH_DOCUMENT";
const SET_IS_LOGIN_FORM = "SET_IS_LOGIN_FORM";
const SET_IS_RESET_FORM = "SET_IS_RESET_FORM";
const SET_CALL_BACK_FUNCS = "SET_CALL_BACK_FUNCS";
const REMOVE_CALL_BACK_FUNC = "REMOVE_CALL_BACK_FUNC";
const SET_LANG = "SET_LANG";
const SET_SHOW_HEADER = "SET_SHOW_HEADER";
const SET_MY_CART = "SET_MY_CART";
const SET_IMAGES_DOWNLOAD = "SET_IMAGES_DOWNLOAD";
const SET_FILES_DOWNLOAD = "SET_FILES_DOWNLOAD";
const SET_IS_WIDGET_UPLOAD_PROGRESS_BAR = "SET_IS_WIDGET_UPLOAD_PROGRESS_BAR";
const SET_BASEMAP_DEFAULT = "SET_BASEMAP_DEFAULT";
import app from "./../../main";
import en from "vee-validate/dist/locale/en.json";
import ja from "vee-validate/dist/locale/ja.json";
import vi from "vee-validate/dist/locale/vi.json";
import { extend, localize } from "vee-validate";
export default {
  state: {
    document: {
      width: 0,
      height: 0,
    },
    spinner: {
      show: false,
      shape: "square",
    },
    isLoginForm: false,
    isResetForm: false,
    callBackFuncs: [],
    lang: "en",
    isHeader: true,
    myCart: [],
    imagesDownload: [],
    filesDownload: [],
    isWidgetUploadProgress: false,
    basemap: {},
  },
  getters: {
    getSpinnerApp: (state) => {
      return state.spinner;
    },
    getDocumentHeight: (state) => {
      return state.document.height;
    },
    getDocumentWidth: (state) => {
      return state.document.width;
    },
    getIsLoginForm: (state) => {
      return state.isLoginForm;
    },
    getIsResetForm: (state) => {
      return state.isResetForm;
    },
    getCallBackFuncs: (state) => {
      return state.callBackFuncs;
    },
    getLang: (state) => {
      return state.lang;
    },
    getIsHeader: (state) => {
      return state.isHeader;
    },
    getMyCart: (state) => {
      return state.myCart;
    },
    getImagesDownload: (state) => {
      return state.imagesDownload;
    },
    getFilesDownload: (state) => {
      return state.filesDownload;
    },
    getIsWidgetUploadProgress: (state) => {
      return state.isWidgetUploadProgress;
    },
    getBasemap: (state) => {
      return state.basemap;
    },
  },
  mutations: {
    [SET_HEIGHT_DOCUMENT](state, payload) {
      state.document.height = payload;
    },
    [SET_WIDTH_DOCUMENT](state, payload) {
      state.document.width = payload;
    },
    [SET_SPINNER_APP](state, payload) {
      state.spinner.show = payload.show;
      if (payload.shape) state.spinner.shape = payload.shape;
      if (!payload.show) state.spinner.shape = "square";
      if (payload.text) state.spinner.text = payload.text;
      else state.spinner.text = "";
    },
    [SET_IS_LOGIN_FORM](state, payload) {
      state.isLoginForm = payload;
    },
    [SET_IS_RESET_FORM](state, payload) {
      state.isResetForm = payload;
    },
    [SET_CALL_BACK_FUNCS](state, payload) {
      if (payload) {
        for (let index = 0; index < payload.length; index++) {
          if (!state.callBackFuncs.includes(payload[index]))
            state.callBackFuncs.push(payload[index]);
        }
      } else state.callBackFuncs = [];
    },
    [REMOVE_CALL_BACK_FUNC](state, payload) {
      if (payload) {
        let index = state.callBackFuncs.findIndex((x) => x.name === payload);
        if (index >= 0) state.callBackFuncs.splice(index, 1);
      }
    },
    [SET_LANG](state, payload) {
      state.lang = payload;
      app.$i18n.locale = payload;
      switch (payload) {
        case "vi":
          localize("vi", vi);
          break;
        case "en":
          localize("en", en);
          break;
        default:
          localize("en", en);
          break;
      }
    },
    [SET_SHOW_HEADER](state, payload) {
      state.isHeader = payload;
    },

    [SET_SHOW_HEADER](state, payload) {
      state.isHeader = payload;
    },
    [SET_MY_CART](state, payload) {
      if (payload && payload.length > 0) {
        for (let i = 0; i < payload.length; i++) {
          if (!state.myCart.includes(payload[i])) {
            state.myCart.push(payload[i]);
          }
        }
      }
    },

    [SET_IMAGES_DOWNLOAD](state, payload) {
      if (payload && payload.length > 0) {
        for (let i = 0; i < payload.length; i++) {
          if (payload[i].is_add) {
            //if(state.imagesDownload.findIndex(x=>x.id === payload[i].image.id) < 0)
            state.imagesDownload.push(payload[i].image);
          } else {
            let index = state.imagesDownload.findIndex(
              (x) => x.id === payload[i].image.id
            );
            if (index >= 0) state.imagesDownload.splice(index, 1);
          }
        }
      }
    },

    [SET_FILES_DOWNLOAD](state, payload) {
      if (payload && payload.length > 0) {
        for (let i = 0; i < payload.length; i++) {
          if (payload[i].is_add) {
            state.filesDownload.push(payload[i].file);
          } else {
            let index = state.filesDownload.findIndex(
              (x) => x.id === payload[i].file.id
            );
            if (index >= 0) state.filesDownload.splice(index, 1);
          }
        }
      }
    },
    [SET_IS_WIDGET_UPLOAD_PROGRESS_BAR](state, payload) {
      state.isWidgetUploadProgress = payload;
    },
    [SET_BASEMAP_DEFAULT](state, payload) {
      state.basemap = payload;
    },
  },
  actions: {
    setSpinnerApp({ commit }, payload) {
      commit(SET_SPINNER_APP, payload);
    },
    setDocumentHeight({ commit }, payload) {
      commit(SET_HEIGHT_DOCUMENT, payload);
    },
    setDocumentWidth({ commit }, payload) {
      commit(SET_WIDTH_DOCUMENT, payload);
    },
    setIsLoginForm({ commit }, payload) {
      commit(SET_IS_LOGIN_FORM, payload);
    },
    setIsResetForm({ commit }, payload) {
      commit(SET_IS_RESET_FORM, payload);
    },
    setCallBackFuncs({ commit }, payload) {
      commit(SET_CALL_BACK_FUNCS, payload);
    },
    removeCallBackFunc({ commit }, payload) {
      commit(REMOVE_CALL_BACK_FUNC, payload);
    },
    setLang({ commit }, payload) {
      commit(SET_LANG, payload);
    },
    setIsHeader({ commit }, payload) {
      commit(SET_SHOW_HEADER, payload);
    },
    setMyCart({ commit }, payload) {
      commit(SET_MY_CART, payload);
    },
    setImagesDownload({ commit }, payload) {
      commit(SET_IMAGES_DOWNLOAD, payload);
    },
    setFilesDownload({ commit }, payload) {
      commit(SET_FILES_DOWNLOAD, payload);
    },
    showWidgetUploadProgressBar({ commit }, payload) {
      commit(SET_IS_WIDGET_UPLOAD_PROGRESS_BAR, payload);
    },
    setBasemapDefault({ commit }, payload) {
      commit(SET_BASEMAP_DEFAULT, payload);
    },
  },
};
