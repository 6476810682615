<template>
  <Dialog
    :header="$t('header_detail')"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :maximizable="false"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <div class="datatable">
      <DataTable
        :scrollable="true"
        :value="propItems"
        ref="dt"
        :resizableColumns="true"
        columnResizeMode="expand"
        selectionMode="single"
        dataKey="id"
        contextMenu
      >
        <template #empty v-if="propItems && propItems.length === 0">
          <span class="text-center">{{ $t("label_no_data") }}</span>
        </template>
        <Column
          headerStyle="width: 3rem; text-align: center!important"
          bodyStyle="text-align: center;"
        >
          <template #body="slotProps">
            {{ slotProps.index + 1 }}
          </template></Column
        >
        <Column :header="$t('label_image_name')">
          <template #body="slotProps">
            {{ slotProps.data.image.name }}
          </template>
        </Column>
        <!-- <Column
          field="date_str"
          :header="$t('lable_date_time')"
          headerStyle="width: 12rem"
        ></Column> -->
        <Column
          field="total"
          :header="$t('label_amount')"
          headerStyle="width: 6rem;"
        ></Column>
      </DataTable>
    </div>
    <template #footer>
      <Button
        label="Export CSV"
        icon="fas fa-file-csv"
        class="p-button-sm float-left"
        @click="exportCsv"
      ></Button>
      <Button
        :label="$t('button_text_close')"
        :icon="'fas fa-times'"
        :class="'p-button-danger mr-0'"
        @click="onClose"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import {
  formatUnixDateTime2String,
  change_alias,
} from "@/utils/commons/common";
import moment from "moment";
export default {
  components: {
    Dialog,
    Button,
    DataTable,
    Column,
  },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "400px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
  },
  methods: {
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
      // return formatUnixDateTime2String(value).replace(
      //   " ngày ",
      //   this.lang === "vi" ? " ngày " : " "
      // );
    },
    exportCsv() {
      if (this.propItems && this.propItems.length > 0) {
        let dataExport = [];
        for (let i = 0; i < this.propItems.length; i++) {
          if (this.propItems[i].image) {
            if (
              dataExport.findIndex(
                (x) => x.name === this.propItems[i].image.name
              ) < 0
            ) {
              dataExport.push({
                name: this.propItems[i].image.name,
              });
            }
          }
        }
        var vm = this;

        const convertArrayOfObjectsToCSV = (args) => {
          const data = args.data;
          if (!data || !data.length) return;

          const columnDelimiter = args.columnDelimiter || ",";
          const lineDelimiter = args.lineDelimiter || "\n";

          const keys = Object.keys(data[0]);

          let result = "",
            ctr = 0;
          result += keys.join(columnDelimiter);
          result += lineDelimiter;

          data.forEach((item) => {
            ctr = 0;
            keys.forEach((key) => {
              if (ctr > 0) result += columnDelimiter;
              result += item[key];
              ctr++;
            });
            result += lineDelimiter;
          });

          return result;
        };
        const downloadCSV = (args) => {
          let csv = convertArrayOfObjectsToCSV({
            data: dataExport,
          });
          if (!csv) return;

          const filename = args.filename || "export.csv";

          if (!csv.match(/^data:text\/csv/i)) {
            csv = "data:text/csv;charset=utf-8," + csv;
          }

          const data = encodeURI(csv);

          const link = document.createElement("a");
          link.setAttribute("href", data);
          link.setAttribute("download", filename);
          link.click();
          //document.body.removeChild(link);
        };
        downloadCSV({
          filename:
            "export_" +
            change_alias(
              vm
                .formatUnixDateTime2String(new Date().getTime())
                .split("/")
                .join("_")
            ) +
            ".csv",
        });
      }
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>