<template>
  <div class="preview-icon mr-2 mt-2 border">
    <div
      class="spinner-border spinner-border-sm align-items-center mt-4"
      role="status"
      v-if="loading"
    >
      <span class="sr-only">{{ $t("label_loading") }}</span>
    </div>
    <div v-else>
      <img
        v-if="!fullPath"
        :src="'/img/icons/no-image.png'"
        class="preview-image no-image"
      />
      <img
        :src="iconSrc"
        class="preview-image"
        @click.stop="showPreviewImage"
        v-else
        onerror="this.src='/img/icons/no-image.png'"
      />
    </div>
  </div>
</template>
<script>
import planetFunc from "@/utils/functions/planet";
export default {
  props: {
    propItem: {
      required: true,
    },
  },
  data() {
    return {
      iconSrc: null,
      loading: false,
      fullPath: null,
    };
  },
  created() {
    if (this.propItem.image_src && this.propItem.is_image) {
      this.fullPath = this.propItem.image_src;
    } else {
      // if (this.propItem && this.propItem.links && this.propItem.links.length > 0) {
      //   let thumbnail = this.propItem.links.find((e) => {
      //     return e.rel == "thumbnail";
      //   });

      //   if (thumbnail && thumbnail.href) {
      //     this.fullPath = thumbnail.href;
      //   }
      // }

      if (
        this.propItem &&
        this.propItem.assets &&
        this.propItem.assets.thumbnail
      ) {
        this.fullPath = this.propItem.assets.thumbnail?.href || null;
      }
    }

    this.getIconPreview();
  },
  mounted() {},
  watch: {
    propId: {
      handler(val) {},
      immediate: true,
    },
  },
  methods: {
    showPreviewImage() {
      this.$emit("showPreviewImage");
    },
    async getIconPreview() {
      try {
        let response = await planetFunc.getImageThumb(this.fullPath);
        if (response.status === 200) {
          let contentType =
            response.headers && response.headers["content-type"]
              ? response.headers["content-type"]
              : "image/png";
          let blob = new Blob([response.data], {
            type: contentType,
          });
          let arrSplit = contentType.split("/");
          if (blob.size != 0) {
            if (!window.navigator.msSaveOrOpenBlob) {
              // BLOB NAVIGATOR
              this.iconSrc = window.URL.createObjectURL(blob);
            } else {
              // BLOB FOR EXPLORER 11
              this.iconSrc = window.navigator.msSaveOrOpenBlob(
                blob,
                this.propItem.id + "." + arrSplit[arrSplit.length - 1]
                  ? arrSplit[arrSplit.length - 1]
                  : "png"
              );
            }
            this.$emit("initSuccessIconImage", {
              id: this.propItem.id,
              val: this.iconSrc,
            });
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.iconSrc = "/img/icons/no-image.png";
          this.$emit("initSuccessIconImage", {
            id: this.propId,
            val: this.iconSrc,
          });
        } else {
          this.iconSrc = "/img/icons/no-image.png";
          this.$emit("initSuccessIconImage", {
            id: this.propId,
            val: this.iconSrc,
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.preview-icon {
  text-align: center;
  width: 66px;
  height: 66px;
  background-color: #eceff1;
  border-radius: 0.25rem;
  img {
    object-fit: cover;
    width: 64px;
    height: 64px;
    border-radius: 0.25rem;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
