<template>
  <div class="tasking-coordinates">
    <div class="select-option">
      <div class="row no-gutters">
        <div class="d-flex">
          <div
            class="p-field p-col pr-3"
            v-for="item in options"
            :key="item.id"
          >
            <RadioButton
              :id="'radio' + item.id"
              :name="item.id"
              :value="item.id"
              v-model="propEntity.coordinates_form"
            />
            <label :for="'radio' + item.id" class="p-radiobutton-label pr-2">
              {{ $t(item.label) }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="select-results row no-gutters">
      <div class="select-results__coors col-12 col-md-6">
        <div v-if="propEntity.coordinates_form === 'polygon'">
          <PolygonPoints
            :propCoordinates="propEntity.coordinates"
            @inputUpperLeftChange="inputUpperLeftChange"
            @inputUpperRightChange="inputUpperRightChange"
            @inputLowerLeftChange="inputLowerLeftChange"
            @inputLowerRightChange="inputLowerRightChange"
          />
        </div>
        <div v-else-if="propEntity.coordinates_form === 'radius'">
          <RadiusPoint
            :propCentre="propEntity.centre"
            @pointChange="pointChange"
            @radiusChange="radiusChange"
            @centreChange="centreChange"
          />
        </div>
      </div>
      <div class="select-results__map col-12 col-md-6">
        <TaskingMap
          :prop-feature="
            propEntity.coordinates_form === 'polygon'
              ? propEntity.feature
              : propEntity.centre.feature
          "
          @initMap="
            map = $event.map;
            layer = $event.layer;
          "
        />
      </div>
    </div>
    <p class="font-italic mt-3 pl-1">
      <strong>{{ $t("label_tips") }}:</strong>
      {{ $t("label_tips_coordinates") }}
    </p>
  </div>
</template>
<script>
import Checkbox from "@/components/commons/checkbox/Checkbox";
import RadioButton from "@/components/commons/radiobutton/RadioButton";
import RadiusPoint from "./coordinates/Radius";
import PolygonPoints from "./coordinates/Polygon";
import TaskingMap from "./coordinates/Map";
import Circle from "ol/geom/Circle";
import Feature from "ol/Feature";
import { fromLonLat, transform, getPointResolution } from "ol/proj";
import { METERS_PER_UNIT } from "ol/proj";
import { getCenter } from "ol/extent";
import Polygon from "ol/geom/Polygon";
export default {
  components: {
    Checkbox,
    RadioButton,
    RadiusPoint,
    TaskingMap,
    PolygonPoints,
  },
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      map: Object,
      layer: Object,
      options: [
        {
          id: "polygon",
          label: "label_input_points_polygon",
        },
        {
          id: "radius",
          label: "label_input_point_radius",
        },
      ],
    };
  },
  created() {},
  watch: {
    "propEntity.coordinates_form"(val) {
      this.propEntity.coordinates_form = val;
      if (val === "radius") {
        if (this.propEntity.centre && !this.propEntity.centre.feature) {
          if (
            this.propEntity.centre.radius &&
            this.propEntity.centre.lat &&
            this.propEntity.centre.lng
          ) {
            var view = this.map.getView();
            var projection = view.getProjection();
            var resolutionAtEquator = view.getResolution();
            var center = this.map.getView().getCenter();
            var pointResolution = getPointResolution(
              projection,
              resolutionAtEquator,
              center
            );
            var resolutionFactor = resolutionAtEquator / pointResolution;
            var radius =
              ((this.propEntity.centre.radius * 1000) / METERS_PER_UNIT.m) *
              resolutionFactor;

            var circle = new Circle(
              fromLonLat([
                this.propEntity.centre.lng,
                this.propEntity.centre.lat,
              ]),
              radius
            );
            this.propEntity.centre.feature = new Feature(circle);
            this.propEntity.centre.feature.setId("featuredrawtasking");
          }
        }
      }
    },
  },
  methods: {
    inputUpperLeftChange(data) {
      if (
        !this.propEntity.coordinates[0] ||
        Object.keys(this.propEntity.coordinates[0]).length === 0
      ) {
        this.propEntity.coordinates[0] = { lat: null, lng: null };
      }
      this.propEntity.coordinates[0].lat = data.lat ? data.lat : null;
      this.propEntity.coordinates[0].lng = data.lng ? data.lng : null;
      this.polygonChange();
    },
    inputUpperRightChange(data) {
      if (
        !this.propEntity.coordinates[1] ||
        Object.keys(this.propEntity.coordinates[1]).length === 0
      ) {
        this.propEntity.coordinates[1] = { lat: null, lng: null };
      }
      this.propEntity.coordinates[1].lat = data.lat ? data.lat : null;
      this.propEntity.coordinates[1].lng = data.lng ? data.lng : null;
      this.polygonChange();
    },
    inputLowerLeftChange(data) {
      if (
        !this.propEntity.coordinates[3] ||
        Object.keys(this.propEntity.coordinates[3]).length === 0
      ) {
        this.propEntity.coordinates[3] = { lat: null, lng: null };
      }
      this.propEntity.coordinates[3].lat = data.lat ? data.lat : null;
      this.propEntity.coordinates[3].lng = data.lng ? data.lng : null;
      this.polygonChange();
    },
    inputLowerRightChange(data) {
      if (
        !this.propEntity.coordinates[2] ||
        Object.keys(this.propEntity.coordinates[2]).length === 0
      ) {
        this.propEntity.coordinates[2] = { lat: null, lng: null };
      }
      this.propEntity.coordinates[2].lat = data.lat ? data.lat : null;
      this.propEntity.coordinates[2].lng = data.lng ? data.lng : null;
      this.polygonChange();
    },
    polygonChange() {
      if (
        this.propEntity.coordinates[0].lat != undefined &&
        this.propEntity.coordinates[0].lat != null &&
        this.propEntity.coordinates[0].lng != undefined &&
        this.propEntity.coordinates[0].lng != null &&
        this.propEntity.coordinates[1].lat != undefined &&
        this.propEntity.coordinates[1].lat != null &&
        this.propEntity.coordinates[1].lng != undefined &&
        this.propEntity.coordinates[1].lng != null &&
        this.propEntity.coordinates[2].lat != undefined &&
        this.propEntity.coordinates[2].lat != null &&
        this.propEntity.coordinates[2].lng != undefined &&
        this.propEntity.coordinates[2].lng != null &&
        this.propEntity.coordinates[3].lat != undefined &&
        this.propEntity.coordinates[3].lat != null &&
        this.propEntity.coordinates[3].lng != undefined &&
        this.propEntity.coordinates[3].lng != null
      ) {
        let points = [];
        points.push(
          transform(
            [
              this.propEntity.coordinates[0].lng,
              this.propEntity.coordinates[0].lat,
            ],
            "EPSG:4326",
            "EPSG:3857"
          )
        );

        points.push(
          transform(
            [
              this.propEntity.coordinates[1].lng,
              this.propEntity.coordinates[1].lat,
            ],
            "EPSG:4326",
            "EPSG:3857"
          )
        );

        points.push(
          transform(
            [
              this.propEntity.coordinates[2].lng,
              this.propEntity.coordinates[2].lat,
            ],
            "EPSG:4326",
            "EPSG:3857"
          )
        );

        points.push(
          transform(
            [
              this.propEntity.coordinates[3].lng,
              this.propEntity.coordinates[3].lat,
            ],
            "EPSG:4326",
            "EPSG:3857"
          )
        );
        let feature = this.layer
          .getSource()
          .getFeatureById("featuredrawtasking");
        if (feature && Object.keys(feature).length > 0) {
          feature.getGeometry().setCoordinates([points]);
        } else {
          feature = new Feature({
            geometry: new Polygon([points]),
          });
          feature.setId("featuredrawtasking");
          this.propEntity.feature = feature;
          this.layer.getSource().addFeature(feature);
        }
        var ext = feature.getGeometry().getExtent();
        this.map.getView().fit(ext, {
          size: this.map.getSize(),
        });
        var center = getCenter(ext);
        this.map.getView().animate({
          center: [center[0], center[1]],
          duration: 100,
          zoom:
            this.map.getView().getZoom() < 2
              ? 2
              : this.map.getView().getZoom() - 1,
        });
      } else {
        this.layer.getSource().clear();
      }
    },
    pointChange(data) {
      this.propEntity.centre.lat = data && data.lat ? data.lat : null;
      this.propEntity.centre.lng = data && data.lng ? data.lng : null;
      if (
        this.propEntity.centre.lat != undefined &&
        this.propEntity.centre.lat != null &&
        this.propEntity.centre.lng != undefined &&
        this.propEntity.centre.lng != null &&
        this.layer &&
        Object.keys(this.layer).length > 0
      ) {
        let feature = this.layer
          .getSource()
          .getFeatureById("featuredrawtasking");
        if (feature && Object.keys(feature).length > 0) {
          let geometry = feature.getGeometry();
          geometry.setCenter(
            fromLonLat([this.propEntity.centre.lng, this.propEntity.centre.lat])
          );
        } else {
          if (
            this.propEntity.centre.lat != undefined &&
            this.propEntity.centre.lat != null &&
            this.propEntity.centre.lng != undefined &&
            this.propEntity.centre.lng != null &&
            this.propEntity.centre.radius != undefined &&
            this.propEntity.centre.radius != null &&
            this.layer &&
            Object.keys(this.layer).length > 0
          ) {
            var view = this.map.getView();
            var projection = view.getProjection();
            var resolutionAtEquator = view.getResolution();
            var center = this.map.getView().getCenter();
            var pointResolution = getPointResolution(
              projection,
              resolutionAtEquator,
              center
            );
            var resolutionFactor = resolutionAtEquator / pointResolution;
            var radius =
              ((this.propEntity.centre.radius * 1000) / METERS_PER_UNIT.m) *
              resolutionFactor;

            var circle = new Circle(
              fromLonLat([
                this.propEntity.centre.lng,
                this.propEntity.centre.lat,
              ]),
              radius
            );
            this.propEntity.centre.feature = new Feature(circle);
            this.propEntity.centre.feature.setId("featuredrawtasking");
          }
        }
      }
    },
    radiusChange(val) {
      this.propEntity.centre.radius = val ? val : 0;
      if (
        this.propEntity.centre.radius != undefined &&
        this.propEntity.centre.radius != null &&
        this.layer &&
        Object.keys(this.layer).length > 0
      ) {
        let feature = this.layer
          .getSource()
          .getFeatureById("featuredrawtasking");
        if (feature && Object.keys(feature).length > 0) {
          let geometry = feature.getGeometry();
          var view = this.map.getView();
          var projection = view.getProjection();
          var resolutionAtEquator = view.getResolution();
          var center = this.map.getView().getCenter();
          var pointResolution = getPointResolution(
            projection,
            resolutionAtEquator,
            center
          );
          var resolutionFactor = resolutionAtEquator / pointResolution;
          var radius =
            ((this.propEntity.centre.radius * 1000) / METERS_PER_UNIT.m) *
            resolutionFactor;
          geometry.setRadius(radius);
        } else {
          if (
            this.propEntity.centre.lat != undefined &&
            this.propEntity.centre.lat != null &&
            this.propEntity.centre.lng != undefined &&
            this.propEntity.centre.lng != null &&
            this.propEntity.centre.radius != undefined &&
            this.propEntity.centre.radius != null &&
            this.layer &&
            Object.keys(this.layer).length > 0
          ) {
            var view = this.map.getView();
            var projection = view.getProjection();
            var resolutionAtEquator = view.getResolution();
            var center = this.map.getView().getCenter();
            var pointResolution = getPointResolution(
              projection,
              resolutionAtEquator,
              center
            );
            var resolutionFactor = resolutionAtEquator / pointResolution;
            var radius =
              ((this.propEntity.centre.radius * 1000) / METERS_PER_UNIT.m) *
              resolutionFactor;

            var circle = new Circle(
              fromLonLat([
                this.propEntity.centre.lng,
                this.propEntity.centre.lat,
              ]),
              radius
            );
            this.propEntity.centre.feature = new Feature(circle);
            this.propEntity.centre.feature.setId("featuredrawtasking");
          }
        }
      }
    },
    centreChange(data) {
      this.propEntity.centre.radius = data && data.radius ? data.radius : null;
      this.propEntity.centre.lat = data && data.lat ? data.lat : null;
      this.propEntity.centre.lng = data && data.lng ? data.lng : null;
      if (
        this.propEntity.centre.lat != undefined &&
        this.propEntity.centre.lat != null &&
        this.propEntity.centre.lng != undefined &&
        this.propEntity.centre.lng != null &&
        this.propEntity.centre.radius != undefined &&
        this.propEntity.centre.radius != null &&
        this.layer &&
        Object.keys(this.layer).length > 0
      ) {
        let feature = this.layer
          .getSource()
          .getFeatureById("featuredrawtasking");
        if (feature && Object.keys(feature).length > 0) {
          let geometry = feature.getGeometry();
          geometry.setCenter(
            fromLonLat([this.propEntity.centre.lng, this.propEntity.centre.lat])
          );
          var view = this.map.getView();
          var projection = view.getProjection();
          var resolutionAtEquator = view.getResolution();
          var center = this.map.getView().getCenter();
          var pointResolution = getPointResolution(
            projection,
            resolutionAtEquator,
            center
          );
          var resolutionFactor = resolutionAtEquator / pointResolution;
          var radius =
            ((this.propEntity.centre.radius * 1000) / METERS_PER_UNIT.m) *
            resolutionFactor;
          geometry.setRadius(radius);
          let ext = geometry.getExtent();
          this.map.getView().fit(ext, this.map.getSize());
          var center = getCenter(ext);
          this.map.getView().animate({
            center: [center[0], center[1]],
            duration: 100,
            zoom:
              this.map.getView().getZoom() < 2
                ? 2
                : this.map.getView().getZoom() - 1,
          });
        } else {
          if (this.layer && Object.keys(this.layer).length > 0) {
            var view = this.map.getView();
            var projection = view.getProjection();
            var resolutionAtEquator = view.getResolution();
            var center = this.map.getView().getCenter();
            var pointResolution = getPointResolution(
              projection,
              resolutionAtEquator,
              center
            );
            var resolutionFactor = resolutionAtEquator / pointResolution;
            var radius =
              ((this.propEntity.centre.radius * 1000) / METERS_PER_UNIT.m) *
              resolutionFactor;

            var circle = new Circle(
              fromLonLat([
                this.propEntity.centre.lng,
                this.propEntity.centre.lat,
              ]),
              radius
            );
            this.propEntity.centre.feature = new Feature(circle);
            this.propEntity.centre.feature.setId("featuredrawtasking");

            let ext = this.propEntity.centre.feature.getGeometry().getExtent();
            this.map.getView().fit(ext, this.map.getSize());
            var center = getCenter(ext);
            this.map.getView().animate({
              center: [center[0], center[1]],
              duration: 100,
              zoom:
                this.map.getView().getZoom() < 2
                  ? 2
                  : this.map.getView().getZoom() - 1,
            });
          }
        }
      } else {
        this.layer.getSource().clear();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.tasking-coordinates {
  padding: 0 0.5rem;
  .select-results {
    padding-top: 1.5rem;
  }
}
</style>
