<template>
  <div class="G934J83hGsfda">
    <div class="form-row">
      <div class="col-12 col-md-4">
        <InputText
          type="search"
          v-model="propSearch.name"
          :placeholder="$t('label_project_name') + '...'"
          class="form-control new-style w-100"
          maxLength="255"
        />
      </div>
      <div class="col">
        <button
          :disabled="
            propButtons && propButtons.filter && propButtons.filter.disabled
          "
          type="button"
          class="btn btn-geostore2 btn-sm btn-w120-h36"
          @click="onSearch"
        >
          <i
            :class="
              propButtons && propButtons.filter && propButtons.filter.processing
                ? 'fas fa-spinner fa-spin'
                : 'fas fa-search'
            "
          ></i
          >&nbsp;{{ $t("button_text_search") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import InputText from "@/components/commons/inputtext/InputText";
export default {
  components: {
    DatePicker,
    InputText,
  },
  props: {
    propSearch: {
      type: Object,
      default: () => {
        return {
          name: null,
        };
      },
    },
    propButtons: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search: {
        name: null,
      },
    };
  },
  created() {
    if (this.propSearch && Object.keys(this.propSearch).length > 0) {
      this.search.name = this.propSearch.name ? this.propSearch.name : null;
    }
  },
  watch: {
    "propSearch.name": {
      handler(val) {
        this.search.name = val ? val : null;
      },
      immediate: true,
    },
  },
  methods: {
    onSearch() {
      this.$emit("onSearch", {
        name: this.search.name,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.G934J83hGsfda {
  font-size: 14px;
  input {
    background: #f5f8fa;
    border-radius: 4px;
    border-color: #f5f8fa;
    &::placeholder {
      color: #b3c3d4;
    }
  }
  button {
    font-size: 14px;
  }
}
</style>
