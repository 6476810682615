import axios from "axios";
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { userServiceApiURL } from "@/utils/commons/environment"
export default {
    
    async searchMetadata(objSearch, params, access_token) {
        let headers = basicApi.geo_image.search_metadata.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers["Content-Type"] = "application/json";
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        const object = axios({
            method:  basicApi.geo_image.search_metadata.method,
            url: userServiceApiURL  +
            basicApi.geo_image.search_metadata.url,
            data: objSearch,
            params: params,
            headers: headers
        });
        let response = await object;
        return response;
    },

    async getById(id, access_token) {
        const object = axios({
            method: "get",
            url: system.endPointSub + system.config.geoImage.id + id,
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + access_token,
                'Accept-Language':app.$store.getters.getLang
            },
            params: {
            }
        });
        let response = await object;
        return response;
    },
  
    async downloadIcon(id, access_token) {
        let headers = basicApi.geo_image.download_icon.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.geo_image.download_icon.method,
            url:
                userServiceApiURL  +
            basicApi.geo_image.download_icon.url.replace("{id}", id),
            headers: headers,
            responseType: "blob", // important
            params: {
            }
        });
        let response = await object;
        return response;
    },
    async downloadPreview(id, access_token) {
        let headers = basicApi.geo_image.download_preview.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.geo_image.download_preview.method,
            url:
                userServiceApiURL  +
            basicApi.geo_image.download_preview.url.replace("{id}", id),
            headers: headers,
            responseType: "blob", // important
            params: {
            }
        });
        let response = await object;
        return response;
    },
    async downloadGoogleEarth(id, access_token) {
        let headers = basicApi.geo_image.download_earth.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.geo_image.download_earth.method,
            url:
                userServiceApiURL  +
            basicApi.geo_image.download_earth.url.replace("{id}", id),
            headers: headers,
            responseType: "blob", // important
            params: {
            }
        });
        let response = await object;
        return response;
    },
    async exportGoogleEarth(images, access_token) {
        let headers = basicApi.exportfile.kmz.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
        method: basicApi.exportfile.kmz.method,
        url:
            userServiceApiURL  +
        basicApi.exportfile.kmz.url,
        headers: headers,
        params:{
            images: images,
        },
        responseType: "blob" // important
        });
        let response = await object;
        return response;
    },
    async downloadMyZipFile(id, access_token) {
        let headers = basicApi.geo_image.download_my_zip_file.headers;
        headers["Authorization"] = "bearer " + access_token;        
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.geo_image.download_my_zip_file.method,
            url:
                userServiceApiURL  +
                basicApi.geo_image.download_my_zip_file.url.replace("{id}",id),
            headers: headers,
            responseType: "blob", // important
            params: {
            }
        });
        
        let response = await object;
        return response;
    },
}