<template>
  <div class="aoi">
    <div class="aoi-preview"><div :id="propFeature.id"></div></div>
    <div class="aoi-info pt-2">
      <div class="aoi-title">
        <span class="aoi-name">{{
          propFeature && propFeature.label ? propFeature.label : null
        }}</span>
      </div>
      <div class="aoi-metadata d-flex flex-column">
        <span
          class="aoi-area"
          title="Area"
          v-html="propFeature && propFeature.area ? propFeature.area : null"
        ></span>
      </div>
    </div>
  </div>
</template>
<script>
import { transform } from "ol/proj";
export default {
  props: {
    propFeature: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  watch: {},
  async mounted() {
    if (this.propFeature && Object.keys(this.propFeature).length > 0) {
      let coordinates = [];
      let coors = this.propFeature.geometry.getCoordinates();
      if (coors && coors.length > 0) {
        for (let i = 0; i < coors[0].length; i++) {
          coordinates.push(transform(coors[0][i], "EPSG:3857", "EPSG:4326"));
        }
      }
      this.initImagePreview(coordinates);
    }
  },
  methods: {
    hexToRgbA(hex, opacity) {
      if (opacity === null || opacity === undefined) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
    async initImagePreview(coordinates) {
      var lng = coordinates.map(function (p) {
        return p[0];
      });
      var lat = coordinates.map(function (p) {
        return p[1];
      });
      var min_coords = {
        lat: Math.min.apply(null, lat),
        lng: Math.min.apply(null, lng),
      };
      var max_coords = {
        lat: Math.max.apply(null, lat),
        lng: Math.max.apply(null, lng),
      };

      let distanceXMinMax = max_coords.lng - min_coords.lng;
      let distanceYMinMax = max_coords.lat - min_coords.lat;
      distanceXMinMax =
        distanceXMinMax < distanceXMinMax
          ? Math.abs(distanceXMinMax)
          : distanceXMinMax;
      distanceYMinMax =
        distanceYMinMax < 0 ? Math.abs(distanceYMinMax) : distanceYMinMax;
      let scaleYX = distanceYMinMax / distanceXMinMax;
      if (scaleYX > 1) scaleYX = 1;
      let newCoors = [],
        locatations = [],
        ymax = 0;

      for (let i = 0; i < coordinates.length; i++) {
        let x = null,
          y = null;
        let dx = coordinates[i][0] - min_coords.lng;
        dx = dx < 0 ? Math.abs(dx) : dx;
        x = (dx / distanceXMinMax) * 64;
        let dy = coordinates[i][1] - min_coords.lat;
        dy = dy < 0 ? Math.abs(dy) : dy;
        if ((dy / distanceYMinMax) * 64 * scaleYX > ymax)
          ymax = (dy / distanceYMinMax) * 64 * scaleYX;
        y = 64 - (dy / distanceYMinMax) * 64 * scaleYX;

        newCoors.push([x, y]);
      }
      for (let i = 0; i < newCoors.length; i++) {
        newCoors[i][1] = newCoors[i][1] - Math.abs((64 - ymax) / 2);
        locatations.push(newCoors[i].join(","));
      }
      var svg = await this.createSVG(this.propFeature.id);
      var poly = this.drawPoly(locatations.join(" "), "", "#fff");
      svg.appendChild(poly);
    },
    createSVG(id) {
      let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
      svg.setAttribute("id", "svg" + id);
      var canvas = document.getElementById(id);
      let width = canvas.getBoundingClientRect().width;
      let height = canvas.getBoundingClientRect().height;
      width = width ? width : "64";
      height = height ? height : "64";
      svg.setAttribute("height", height);
      svg.setAttribute("width", width);
      canvas.appendChild(svg);
      return svg;
    },
    drawPoly(points, fill, stroke) {
      var poly = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "polygon"
      );
      poly.setAttribute("id", "polygon" + this.propFeature.id);
      poly.setAttribute("points", points);
      poly.setAttribute("stroke", this.hexToRgbA("#ff0000", 0.7));
      poly.setAttribute("fill", this.hexToRgbA("#ff0000", 0.6));
      return poly;
    },
  },
};
</script>
<style lang="scss" scoped>
.aoi {
  display: flex;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
  width: 100%;
  .aoi-preview {
    width: 64px;
    height: 64px;
    margin-right: 15px;
    flex-shrink: 0;
    svg {
      background-repeat: no-repeat;
      display: inline-block;
      fill: currentColor;
      width: 64px;
      height: 64px;
    }
  }
  .aoi-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    .aoi-title {
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
    .aoi-metadata {
      margin-top: 0.125rem;
      width: 100%;
    }
  }
}
</style>