<template>
  <div class="form-search">
    <div class="form-row">
      <div class="col-12 col-md-4">
        <label class="control-label">{{ $t("label_image_name") }}</label>
        <InputText
          type="search"
          v-model="search.image_name"
          class="form-control w-100"
          maxLength="255"
        />
      </div>
      <div class="col-12 col-md-2">
        <label class="control-label">{{ $t("label_date_from") }}</label>
        <date-picker
          v-model="search.from"
          class="w-100"
          :format="'DD/MM/YYYY'"
          valuetype="timestamp"
          :disabled-date="notAfterToday"
          :input-class="'form-control'"
          :lang="lang"
        >
        </date-picker>
      </div>
      <div class="col-12 col-md-2">
        <label class="control-label">{{ $t("label_date_to") }}</label>
        <date-picker
          v-model="search.to"
          class="w-100"
          :format="'DD/MM/YYYY'"
          valuetype="timestamp"
          :disabled-date="notAfterToday"
          :input-class="'form-control'"
          :lang="lang"
        >
        </date-picker>
      </div>
      <div class="col">
        <button
          type="button"
          class="btn btn-geostore2 btn-sm btn-w120-h36"
          v-if="propButton.visible"
          :disabled="propButton.disabled"
          @click="onSearch"
        >
          <i
            :class="
              propButton.processing ? 'fas fa-spinner fa-spin' : 'fas fa-search'
            "
          ></i
          >&nbsp;{{ $t("button_text_search") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import InputText from "@/components/commons/inputtext/InputText";
export default {
  components: {
    DatePicker,
    InputText,
  },
  props: {
    propSearch: {
      type: Object,
      default: () => {},
    },
    propButton: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search: {
        image_name: null,
        from: null,
        to: null,
      },
    };
  },
  created() {
    if (this.propSearch && Object.keys(this.propSearch).length > 0) {
      this.search.image_name = this.propSearch.image_name
        ? this.propSearch.image_name.trim()
        : null;
      this.search.from = this.propSearch.from ? this.propSearch.from : null;
      this.search.to = this.propSearch.to ? this.propSearch.to : null;
    }
  },
  watch: {
    propSearch(val) {
      if (this.propSearch && Object.keys(this.propSearch).length > 0) {
        this.search.image_name = this.propSearch.image_name
          ? this.propSearch.image_name.trim()
          : null;
        this.search.from = this.propSearch.from ? this.propSearch.from : null;
        this.search.to = this.propSearch.to ? this.propSearch.to : null;
      }
    },
  },
  methods: {
    onSearch() {
      if (!this.search.from && this.search.to) {
        this.$emit("message", [
          this.$t("message_search_from_time_is_required"),
        ]);
        return;
      } else if (this.search.from && !this.search.to) {
        this.$emit("message", [this.$t("message_search_to_time_is_required")]);
        return;
      } else if (this.search.from && this.search.to) {
        let from = new Date(this.search.from).setHours(0, 0, 0, 0);
        let to = new Date(this.search.to).setHours(23, 59, 59, 0);
        if (from - to > 0) {
          this.$emit("message", [
            this.$t(
              "message_search_to_time_must_greater_than_or_equal_from_time"
            ),
          ]);
          return;
        }
        this.$emit("onSearch", {
          image_name: this.search.image_name
            ? this.search.image_name.trim().replace(/\s\s+/g, " ")
            : null,
          from: from,
          to: to,
        });
      } else {
        this.$emit("onSearch", {
          image_name: this.search.image_name
            ? this.search.image_name.trim().replace(/\s\s+/g, " ")
            : null,
          from: null,
          to: null,
        });
      }
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.form-search {
  button {
    margin-top: 1.25rem;
    font-size: 14px;
  }
  label {
    margin: 0;
    padding: 0;
    padding-left: 0.25rem;
    color: $text-color;
  }
  input {
    background: #eef3f7;
    border-radius: 4px;
    border-color: #eef3f7;
    &::placeholder {
      color: #b3c3d4;
    }
  }
}
.form-group {
  margin-bottom: 0.5rem;
  margin: 0;
  label {
    padding-left: 0.25rem;
  }
}
</style>
<style lang="scss">
.shared4me__search {
  .mx-input-wrapper {
    input {
      font-size: 14px;
      background: #eef3f7;
      border-radius: 4px;
      border-color: #eef3f7;
      &:hover {
        border-color: #212121 !important;
      }
      &:disabled,
      .disabled {
        background-color: transparent;
      }
    }
  }
}
</style>
