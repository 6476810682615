<template>
  <div class="datatable">
    <ContextMenu :model="menuModel" ref="cmMyShareDatatable" />
    <DataTable
      :value="propData"
      ref="dt"
      :resizableColumns="false"
      columnResizeMode="expand"
      dataKey="id"
      contextMenu
      :selection.sync="selected"
      selectionMode="single"
      @row-click="rowClick"
      :loading="propLoading"
      :scrollable="propData.length > 10"
      :scrollHeight="propData.length > 10 ? propTable.height + 'px' : ''"
      :virtualScroll="true"
      @virtual-scroll="onVirtualScroll"
    >
      <template #header v-if="propData && propData.length > 0">
        <div class="text-left">
          <div class="font-weight-normal">
            {{
              $t("label_show_from_to", null, {
                from: 1,
                to: propData.length,
                total: propTotal,
              })
            }}
          </div>
        </div>
      </template>
      <template #empty v-if="propData && propData.length === 0">
        <span class="text-center">{{ $t("label_no_data") }}</span>
      </template>
      <Column
        header="No"
        headerStyle="width: 3rem; text-align: center!important"
        bodyStyle="text-align: center;"
      >
        <template #body="slotProps">
          {{ slotProps.index + 1 }}
        </template></Column
      >
      <Column :header="$t('label_project_name')">
        <template #body="slotProps">
          {{ slotProps.data.title }}
        </template></Column
      >
      <Column
        :header="$t('label_project_start_date')"
        headerStyle="width: 12rem;"
      >
        <template #body="slotProps">
          {{ formatUnixDateTime2String(slotProps.data.start_date) }}
        </template>
      </Column>
      <Column
        :header="$t('label_project_end_date')"
        headerStyle="width: 12rem;"
      >
        <template #body="slotProps">
          {{ formatUnixDateTime2String(slotProps.data.end_date) }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
import moment from "moment";
export default {
  components: {
    DataTable,
    Column,
    ContextMenu,
  },
  props: {
    propData: {
      type: Array,
      default: () => [],
    },
    propTotal: {
      type: Number,
      default: 0,
    },
    propLoading: {
      type: Boolean,
      default: false,
    },
    propTable: { type: Object, default: () => {} },
  },
  data() {
    return {
      selected: null,
      menuModel: [],
      time: null,
      rowSelected: null,
    };
  },
  created() {},
  watch: {
    selected(val) {
      this.$emit("selectChanged", val);
    },
  },
  methods: {
    clearSelect() {
      this.selected = null;
    },
    onVirtualScroll(e) {
      this.$emit("nextPage");
    },
    pagechanged(val) {
      this.$emit("pagechanged", val);
    },
    pageSizechanged(val) {
      this.$emit("pageSizechanged", val);
    },
    async downloadItem(item) {
      let strPath = item.image.filePath;
      let paths = strPath.split("/");
      await this.downloadImage({
        id: item.image.id,
        name: paths[paths.length - 1],
      });
    },
    showDlgMetadata(item) {
      this.$emit("showDlgMetadata", item.image);
    },
    onRowContextMenu(event) {
      // if (this.selected && Object.keys(this.selected).length > 0) {
      //     this.$refs.cmMyShareDatatable.show(event.originalEvent);
      // }
    },
    clearSelected() {
      this.selected = [];
    },
    rowClick(e) {
      if (this.rowSelected) {
        if (e.data.id == this.rowSelected.id) {
          if (this.time) {
            if (new Date().getTime() - this.time <= 300) {
              this.$emit("dbClick", e.data);
            }
          }
        }
      }
      this.rowSelected = e.data;
      this.time = new Date().getTime();
    },
    formatUnixDateTime2String(value) {
      let result = "---";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
.my-project__datatable {
  .p-datatable {
    border-radius: 0.25rem;
    .p-datatable-header {
      background-color: white !important;
      border: 1px solid white;
    }
    .p-datatable-thead {
      tr {
        border-bottom: 1px solid #eff2f5;
        th {
          background: white;
          border: none;
          color: #a1a5b7;
        }
      }
    }
    .p-datatable-thead {
      tr {
        border-bottom: 1px solid #eff2f5;
        th {
          background: white;
          border: none;
          color: #a1a5b7;
        }
      }
    }
    .p-datatable-tbody {
      tr {
        border-bottom: 1px solid #eff2f5;
        &.p-highlight {
          background: $background-color-2 !important;
          td {
            color: white;
          }
        }
        td {
          border: none;
          color: $text-color;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
