<template>
  <ul class="planet-summary-list">
    <li class="summary-item" v-for="bundle in cart.bundles" :key="bundle.id">
      <div class="item-header">
        <svg
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-do1tfz"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="SatelliteIcon"
        >
          <path
            fill-rule="evenodd"
            d="m17.714 2.3597 4.0406 4.0406c.4463.4463.4463 1.17 0 1.6162l-4.0405 4.0404.808.8084c.4463.4463.4463 1.1699 0 1.6162l-4.0406 4.0406c-.4463.4463-1.17.4463-1.6162 0l-.8084-.808-4.0404 4.0405c-.4463.4463-1.1699.4463-1.6162 0L2.3597 17.714c-.4463-.4463-.4463-1.17 0-1.6162l4.0401-4.0409-.8076-.8079c-.4463-.4463-.4463-1.17 0-1.6162l4.0406-4.0406c.4463-.4463 1.17-.4463 1.6162 0l.808.8076 4.0408-4.0401c.4463-.4463 1.1699-.4463 1.6162 0ZM4.7838 15.2889l-1.616 1.617 4.0406 4.0406L8.8238 19.33l-4.04-4.0412Zm2.4229-2.4251-1.6149 1.6171 4.04 4.0412 1.616-1.6172-4.0411-4.0411Zm3.2342-6.4635-4.0406 4.0406 7.273 7.2731 4.0407-4.0406-7.2731-7.2731Zm4.04-.8085-1.6171 1.6149 4.0411 4.0411 1.6172-1.616-4.0412-4.04Zm2.425-2.424-1.617 1.616 4.0412 4.04 1.6164-1.6154-4.0406-4.0406Z"
          ></path>
        </svg>
        <p
          class="d-flex justify-content-between align-items-center m-0 w-full w-100"
        >
          <span> {{ bundle.id }}</span>
          <strong
            class="text-success text-right font-weight-bold"
            style="font-size: 14px"
            >(P)</strong
          >
        </p>
      </div>

      <div class="item-bundle-card">
        <div class="px-2 py-3">
          <div class="item-count">
            <svg
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="BurstModeOutlinedIcon"
            >
              <path
                d="M1 5h2v14H1zm4 0h2v14H5zm17 0H10c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1zm-1 12H11V7h10v10zm-3.57-4.38-2 2.57L14 13.47l-2 2.52h8z"
              ></path></svg
            ><span class="pl-1 pt-1"
              >&nbsp; {{ bundle.items_length }} items</span
            >
          </div>
          <h6 class="bundle-name mb-0">{{ bundle.bundle_name }}</h6>
          <div
            class="chip-container flex"
            v-if="
              bundle.assets_item && bundle.assets_item.auxiliaryFiles === 'UDM2'
            "
          >
            <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24">
              <path
                d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16-7-8v8H5l7-8V5h7v14z"
              ></path>
            </svg>
            <span> UDM2 </span>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
import bundles from "@/utils/commons/bundles";
export default {
  props: {
    propImages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      cart: {
        name: null,
        order_type: "full",
        bundles: [],
      },
    };
  },
  created() {},
  mounted() {
    setTimeout(() => {
      for (let i = 0; i < this.bundles4Select.length; i++) {
        this.cart.bundles.push({
          id: this.bundles4Select[i].id,
          key: this.bundles4Select[i].id,
          assets:
            this.bundles4Select[i].values &&
            this.bundles4Select[i].values.length > 0
              ? this.bundles4Select[i].values[0].id
              : null,
          assets_item:
            this.bundles4Select[i].values &&
            this.bundles4Select[i].values.length > 0
              ? this.bundles4Select[i].values[0]
              : null,
          bundle_name:
            this.bundles4Select[i].values &&
            this.bundles4Select[i].values.length > 0
              ? this.bundles4Select[i].values[0].name
              : null,
          items_length: this.propImages.filter(
            (x) => x.properties.item_type === this.bundles4Select[i].id
          ).length,
        });
      }
    }, 500);
  },
  computed: {
    bundles4Select() {
      let results = [];
      let planetItemTypesSelected = [
        ...new Set(this.propImages.map((x) => x.properties.item_type)),
      ];
      for (const [key, value] of Object.entries(bundles)) {
        if (planetItemTypesSelected.includes(key)) {
          let obj = {
            key: key,
            id: key,
            length: this.propImages.filter(
              (x) => x.properties.item_type === key
            ).length,
            values: [],
          };
          for (let i = 0; i < value.length; i++) {
            if (obj.values.findIndex((x) => x.id === value[i].id) < 0) {
              //remove same assets
              let index = value.findIndex(
                (x) => x.id != value[i].id && x.name === value[i].name
              );
              if (
                index >= 0 &&
                value[i].assets &&
                value[index].assets &&
                value[i].assets.length > value[index].assets.length
              ) {
                for (let k = 0; k < value[index].assets.length; k++) {
                  if (
                    value[i].assets.findIndex(
                      (x) => x === value[index].assets[k]
                    ) >= 0
                  ) {
                    value[index].assets.splice(k, 1);
                    k = k - 1;
                  }
                }
                if (value[index].assets.length === 0) {
                  value.splice(index, 1);
                  i = i - 1;
                  continue;
                }
              } else if (
                index >= 0 &&
                value[i].assets &&
                value[index].assets &&
                value[i].assets.length < value[index].assets.length
              ) {
                for (let k = 0; k < value[i].assets.length.length; k++) {
                  if (
                    value[index].assets.findIndex(
                      (x) => x === value[i].assets[k]
                    ) >= 0
                  ) {
                    value[i].assets.splice(k, 1);
                    k = k - 1;
                  }
                }
                if (value[i].assets.length === 0) {
                  value.splice(i, 1);
                  isSecureContext = i - 1;
                  continue;
                }
              }
              obj.values.push(value[i]);
            }
          }
          results.push(obj);
        }
      }
      return results;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
ul.planet-summary-list {
  list-style: none;
  margin: 0;
  padding: 0;
  li.summary-item {
    padding: 0.75rem;
    border-bottom: 1px solid #eff2f5;
    &:last-child {
      border: 0;
    }
    .item-header {
      display: flex;
      box-sizing: border-box;
      list-style: none;
      font-weight: 500;
      font-size: 1rem;
      margin-bottom: 0.25rem;
      background-color: transparent;
      svg {
        user-select: none;
        width: 1em;
        height: 1em;
        display: inline-block;
        fill: currentcolor;
        flex-shrink: 0;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-size: 1.5rem;
        margin-right: 4px;
      }
    }
    .item-bundle-card {
      width: 100%;
      color: rgb(0, 31, 35);
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-radius: 4px;
      border: 1px solid rgb(189, 197, 198);
      overflow: hidden;
      background-color: white;
      flex-shrink: 0;
      .item-count {
        max-width: 100%;
        font-family: Inter, sans-serif;
        font-size: 0.8125rem;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        height: 24px;
        color: rgb(0, 31, 35);
        white-space: nowrap;
        transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        cursor: default;
        outline: 0px;
        text-decoration: none;
        padding: 0px;
        vertical-align: middle;
        box-sizing: border-box;
        background-color: transparent;
        border-radius: 4px;
        border: none;
        svg {
          user-select: none;
          width: 1em;
          height: 1em;
          display: inline-block;
          fill: currentcolor;
          flex-shrink: 0;
          transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          font-size: 1.5rem;
        }
      }
      .bundle-name {
        margin: 0px 0px 0.35em;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1.57;
      }
      .chip-container {
        margin-top: 0.5rem;
        font-size: 0.8125rem;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        height: 24px;
        color: rgb(0, 31, 35);
        background-color: rgba(0, 0, 0, 0.08);
        white-space: nowrap;
        transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        cursor: default;
        outline: 0px;
        text-decoration: none;
        border: 0px;
        padding: 0px 0.375rem;
        vertical-align: middle;
        box-sizing: border-box;
        border-radius: 4px;
        svg {
          width: 16px;
          height: 16px;
          display: inline-block;
        }
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-left: 4px;
          padding-top: 2px;
          margin: auto 0;
          text-align: center;
        }
      }
    }
  }
}
</style>
