<template>
  <div></div>
  <!-- <div
    class="widget-upload-progress-bar animate__animated"
    :class="show ? 'animate__fadeInBottomRight' : 'animate__fadeOutBottomRight'"
    :style="style"
  >
    <div class="widget-header">
      <h6 class="title">
        Uploading...
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          @click="onClose"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </h6>
    </div>
    <div class="widget-body">
      <ul class="list-group images-upload" v-if="items.length > 0">
        <li
          class="list-group-item image-item"
          v-for="(item, index) in items"
          :key="index"
        >
          <div class="media">
            <img
              class="mr-3"
              src="/img/icons/zip232x32.png"
              alt="Generic placeholder image"
            />
            <div class="media-body">
              <h6 class="mt-0" :title="item.name.length > 36 ? item.name : ''">
                {{
                  item.name.length > 36
                    ? item.name.substring(0, 34) + "..."
                    : item.name
                }}
              </h6>
              <ProgressBar :value="item.value">{{ item.value }}%</ProgressBar>
            </div>
          </div>
        </li>
      </ul>
      <div class="Iybw1Bhj3Jhd8s" v-else>
        <tile
          :loading="true"
          :height="'40'"
          :width="'40'"
          :color="'fd853e'"
          class="ehAbnmg4Jwb9BS"
        ></tile>
      </div>
    </div>
  </div> -->
</template>
<script>
import ProgressBar from "@/components/commons/progressbar/ProgressBar";

import { TileSpinner } from "vue-spinners";
export default {
  components: { ProgressBar, Tile: TileSpinner },
  props: {
    propShow: {
      type: Boolean,
      default: false,
    },
    isLayoutDefault: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
      items: [],
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    show(val) {
      this.show = val;
    },
  },
  methods: {
    onClose() {
      this.show = false;
      this.$emit("close");
      this.$store.dispatch("showWidgetUploadProgressBar", false);
    },
    addFile(file) {
      let index = this.items.findIndex((x) => x.id === file.id);
      if (index >= 0) {
        this.items[index].value = file.value;
      } else {
        this.items.push(file);
      }
    },
  },
  computed: {
    style() {
      return {
        bottom: this.isLayoutDefault ? "1.8125rem" : "0.25rem",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.widget-upload-progress-bar {
  width: 22rem;
  position: fixed;
  right: 0.25rem;
  z-index: 2;
  background: white;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  .widget-header {
    .title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin: 0;
      padding: 0.5rem 0.75rem;
      padding-right: 0.5rem;
      background: $background-color;
      color: white;
      font-size: 14px;
      button {
        color: white;
      }
    }
  }
  .widget-body {
    max-height: 16rem;
    min-height: 3rem;
    overflow-y: auto;
    h6 {
      font-size: 14px;
    }
  }
  ul.images-upload {
    margin: 0;
    padding: 0;
    list-style: none;
    .list-group-item {
      padding: 0.5rem 0.75rem;
    }
  }
}
.Iybw1Bhj3Jhd8s {
  position: relative;
  width: 100%;
  height: 100%;
  .ehAbnmg4Jwb9BS {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 3;
  }
}
</style>
