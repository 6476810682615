import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { geoServiceApiURL } from "@/utils/commons/environment"
export default {
    async create(entity,access_token) {
        let headers = basicApi.request_free_image.create.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.request_free_image.create.method,
            url: geoServiceApiURL + basicApi.request_free_image.create.url,
            headers: headers,
            params: {
            },
            data:entity
        });

        let response = await object;
        return response;
    },
    
    async download(id,access_token){
        let headers = basicApi.request_free_image.download.headers;
        if(!headers)
        headers= {}
        headers["Authorization"] = "bearer " + access_token;
        headers["Content-Type"] = "application/json";
        headers['Accept-Language'] = app.$store.getters.getLang;
        let params = {};
        const object =  axios({
            method: basicApi.request_free_image.download.method,
            url:geoServiceApiURL + basicApi.request_free_image.download.url.replace("{id}",id),
            params:params,
            headers: headers,
        //responseType:'blob'
        });

        let response = await object;
        return response;
    }
}