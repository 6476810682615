<template>
  <CommonAccordion :propTitle="'Tìm kiếm'">
    <div slot="content" class="accordion">
      <div class="form-search">
        <div class="form-row no-gutters">
          <div class="col-3">
            <label class="control-label">{{ $t("label_date_from") }}</label>
            <date-picker
              v-model="search.from"
              class="w-100"
              :format="'DD/MM/YYYY'"
              valuetype="timestamp"
              :disabled-date="notAfterToday"
            >
            </date-picker>
          </div>
          <div class="col-3">
            <label class="control-label">{{ $t("label_date_to") }}</label>
            <date-picker
              v-model="search.to"
              class="w-100"
              :format="'DD/MM/YYYY'"
              valuetype="timestamp"
              :disabled-date="notAfterToday"
            >
            </date-picker>
          </div>
          <div class="col-3">
            <Button
              type="button"
              class="p-button p-button-success p-button-sm"
              @click="onSearch"
              :label="$t('button_text_search')"
              icon="fas fa-search"
            >
              <i class="fas fa-search"></i>&nbsp;{{ $t("button_text_search") }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import CommonAccordion from "@/components/commons/accordion/Accordion";
import Button from "@/components/commons/button/Button";
export default {
  components: {
    DatePicker,
    Dropdown,
    CommonAccordion,
    Button,
  },
  props: {
    propSearch: {
      type: Object,
      default: () => {
        return {
          from: null,
          to: null,
        };
      },
    },
  },
  data() {
    return {
      search: {
        from: null,
        to: null,
      },
    };
  },
  created() {
    this.search.from = this.propSearch.from;
    this.search.to = this.propSearch.to;
  },
  watch: {
    propSearch: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          this.search.from = val.from;
          this.search.to = val.to;
        }
      },
      immediate: true,
    },
  },
  methods: {
    onSearch() {
      if (!this.search.from && this.search.to) {
        this.$emit("message", [
          this.$t("message_search_from_time_is_required"),
        ]);
        return;
      } else if (this.search.from && !this.search.to) {
        this.$emit("message", [this.$t("message_search_to_time_is_required")]);
        return;
      } else if (this.search.from && this.search.to) {
        let from = new Date(this.search.from).setHours(0, 0, 0, 0);
        let to = new Date(this.search.to).setHours(23, 59, 59, 0);
        if (from - to > 0) {
          this.$emit("message", [
            this.$t(
              "message_search_to_time_must_greater_than_or_equal_from_time"
            ),
          ]);
          return;
        }
      }
      this.$emit("onSearch", this.search);
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
};
</script>
<style lang="scss" scoped>
.form-search {
  padding: 0.25rem;
  button {
    margin-top: 1.8125rem;
    font-size: 14px;
  }
}
</style>

<style lang="scss">
.order-list__search {
  .mx-datepicker {
    width: 100%;
    .mx-input {
      border: 1px solid #a6a6a6 !important;
    }
  }
}
</style>
