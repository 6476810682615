<template>
  <div class="checkout-confirmation">
    <div class="global-order-options">
      <table>
        <tbody>
          <tr>
            <td>Order reference:</td>
            <td class="plaintext">{{ propEntity.name }}</td>
          </tr>
          <tr>
            <td>Delivery mode:</td>
            <td class="plaintext">{{ deliveryTypeLabel }}</td>
          </tr>
          <tr v-if="propEntity.primaryMarket">
            <td>Market:</td>
            <td>
              <ul>
                <li class="plaintext">
                  Primary Market:&nbsp;
                  {{ primaryMarketSelected ? primaryMarketSelected.label : "" }}
                </li>
                <li class="plaintext" v-if="propEntity.secondaryMarket">
                  Secondary Market:&nbsp;
                  {{
                    secondaryMarketSelected ? secondaryMarketSelected.label : ""
                  }}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Payment method:</td>
            <td class="plaintext">Purchase order: {{ propContract.name }}</td>
          </tr>
          <tr v-if="1 == 2">
            <td>Delivery to:</td>
            <td>
              <ul>
                <li class="plaintext"></li>
                <li class="plaintext"></li>
              </ul>
            </td>
          </tr>
          <tr
            v-if="
              propEntity.emails &&
              propEntity.emails.length &&
              propEntity.emails.filter((x) => x.value && x.value.trim()).length
            "
          >
            <td>Notifications:</td>
            <td>
              <ul>
                <li
                  class="plaintext"
                  v-for="(email, index) in propEntity.emails.filter(
                    (x) => x.value && x.value.trim()
                  )"
                  :key="'email' + index"
                >
                  {{ email.value }}
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="options">
      <div class="left">
        <div class="title d-flex justify-content-between align-items-center">
          <h6>
            {{
              propProductTypeSelected &&
              propProductTypeSelected.technicalProduct
                ? propProductTypeSelected.technicalProduct.label
                : ""
            }}
          </h6>
          <div>
            Price&nbsp;
            <span
              class="listed-price"
              v-if="propPrice && propPrice.discountValue > 0"
              v-html="
                getCurrencyCode(propPrice ? propPrice.currency : null) +
                propPrice.discountValue
              "
            ></span>
            <strong v-if="propPrice && !propPrice.loading">
              <em
                v-html="getCurrencyCode(propPrice ? propPrice.currency : null)"
              ></em>
              {{ price2Pay | numberFormat }}</strong
            >
            <small v-if="propPrice && !propPrice.loading"
              >&nbsp;(&#45;{{
                propPrice ? propPrice.discountPercentage : ""
              }}&#37;)</small
            >
          </div>
        </div>
        <div
          class="form-group row"
          v-for="option in propEntity.options"
          :key="option.name"
        >
          <label class="col-sm-6 col-form-label">{{
            convertNameLabel(option.label)
          }}</label>
          <div class="col-sm-6">
            <input
              type="text"
              readonly
              class="form-control-plaintext font-weight-light pl-2"
              :value="getLabelOption(option.selectedValue, option.values)"
            />
          </div>
        </div>
      </div>
      <div class="right">
        <p class="text-center w-100">
          Polygon <span v-html="propEntity.area"></span>
        </p>
        <div v-if="['oneday'].includes(this.target)">
          <RightOneDay
            :propEntity="propEntity"
            :prop-coordinate-system="coordinateSystem"
          />
        </div>
        <div v-else-if="['onenow'].includes(this.target)">
          <RightOneNow
            :prop-entity="propEntity"
            :prop-coordinate-system="coordinateSystem"
          />
        </div>
        <div v-else-if="['oneplan'].includes(this.target)">
          <RightOnePlan
            :prop-entity="propEntity"
            :prop-coordinate-system="coordinateSystem"
            :propFeasibilityOneAtlas="propFeasibilityOneAtlas"
          />
        </div>
        <div v-else-if="['oneseries'].includes(this.target)">
          <RightOneSeries
            :prop-entity="propEntity"
            :prop-coordinate-system="coordinateSystem"
          />
        </div>
      </div>
    </div>
    <div class="license-terms">
      <fieldset class="license" data-col-size="6" v-if="optionLicenseLabel">
        <p>Product Licence</p>
        <p class="span-2 plaintext">
          {{
            propProductTypeSelected && propProductTypeSelected.technicalProduct
              ? propProductTypeSelected.technicalProduct.label
              : ""
          }}
        </p>
        <p class="span-2 plaintext">{{ optionLicenseLabel }}</p>
        <p class="text-right"><button class="btn">Read</button></p>
      </fieldset>
      <div class="form-group m-0">
        <Checkbox
          v-model="propEntity.acceptLicenses"
          :binary="true"
          :disabled="propDisableActions"
        />
        <label class="p-checkbox-label plaintext"
          >I hereby accept the license(s) of the product(s) selected for this
          order</label
        >
      </div>
      <div class="form-group m-0">
        <Checkbox
          v-model="propEntity.acceptTerms"
          :binary="true"
          :disabled="propDisableActions"
        />
        <label class="p-checkbox-label plaintext underline"
          >I hereby accept the terms and conditions applicable to this order
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import Checkbox from "@/components/commons/checkbox/Checkbox";
import RightOneDay from "./options/RightOneDay.vue";
import RightOneNow from "./options/RightOneNow.vue";
import RightOnePlan from "./options/RightOnePlan.vue";
import RightOneSeries from "./options/RightOneSeries.vue";
export default {
  components: {
    Checkbox,
    RightOneDay,
    RightOneNow,
    RightOnePlan,
    RightOneSeries,
  },
  props: {
    propDeliveryTypes: {
      type: Array,
      default: () => [],
    },
    propProductOptions: {
      type: Array,
      default: () => [],
    },
    propProductTypeSelected: {
      type: Object,
      default: () => {},
    },
    propPrice: {
      type: Object,
      default: () => {},
    },
    propContract: {
      type: Object,
      default: () => {},
    },
    propMarkets: {
      type: Array,
      default: () => [],
    },
    propEntity: {
      type: Object,
      default: () => {},
    },
    // Response from api
    propFeasibilityOneAtlas: {
      type: Array,
      default: () => [],
    },
    propDisableActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },

  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    convertNameLabel(name) {
      if (!name) return;
      name = name.trim();
      let array = name.split("_");
      for (let i = 0; i < array.length; i++) {
        if (!array[i]) continue;
        array[i] = this.capitalizeFirstLetter(array[i].trim().toLowerCase());
      }
      name = array.join(" ");
      return name;
    },
    getLabelOption(id, values) {
      let item = values.find((x) => x.id === id);
      return item && Object.keys(item).length > 0 ? item.label : null;
    },
    getCurrencyCode(txt) {
      let result = "&euro;";
      switch (txt) {
        case "EUR":
          result = "&euro;";
          break;
        case "DOLLAR":
          result = "&#36;";
          break;
        case "POUND":
          result = "&#163;";
          break;
        case "YEN":
          result = "&#165;";
          break;
        case "CENT":
          result = "&#162;";
          break;
        case "RUPEE":
          result = "&#8360;";
          break;
        case "DONG":
          result = "&#8363;";
          break;
        default:
          result = txt;
          break;
      }
      return result;
    },
  },
  computed: {
    deliveryTypeLabel() {
      let item = this.propDeliveryTypes.find(
        (x) => x.deliveryTypeId === this.propEntity.deliveryType
      );
      return item ? item.label : null;
    },
    primaryMarketSelected() {
      return this.propMarkets.find(
        (x) => x.id === this.propEntity.primaryMarket
      );
    },
    secondaryMarketSelected() {
      if (
        this.primaryMarketSelected &&
        this.primaryMarketSelected.secondaryMarket
      )
        return this.primaryMarketSelected.secondaryMarket.find(
          (x) => x.id === this.propEntity.secondaryMarket
        );
      else return null;
    },
    coordinateSystem() {
      let item = this.propProductOptions.find((x) => x.name === "projection_1");
      if (
        item &&
        Object.keys(item).length > 0 &&
        item.values &&
        item.values.length
      ) {
        let defaultItem = item.values.find((x) => x.id === item.defaultValue);
        return defaultItem && Object.keys(defaultItem).length > 0
          ? defaultItem.label
          : null;
      }
      return null;
    },
    optionLicenseLabel() {
      let option = this.propEntity.options.find((x) => x.name === "licence");
      if (option && Object.keys(option).length && option.values) {
        let value = option.values.find((x) => x.id === option.selectedValue);
        return value ? value.label : null;
      }
      return null;
    },
    target() {
      if (
        this.propProductTypeSelected &&
        Object.keys(this.propProductTypeSelected).length > 0
      ) {
        return this.propProductTypeSelected.technicalProduct.target.id;
      }
      return null;
    },
    totalAmount() {
      return this.propPrice &&
        Object.keys(this.propPrice).length &&
        this.propPrice.totalAmount
        ? this.propPrice.totalAmount
        : 0;
    },
    discountValue() {
      return this.propPrice &&
        Object.keys(this.propPrice).length &&
        this.propPrice.discountValue
        ? this.propPrice.discountValue
        : 0;
    },
    price2Pay() {
      return this.totalAmount - this.discountValue >= 0
        ? this.totalAmount - this.discountValue
        : 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.checkout-confirmation {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  .global-order-options {
    background-color: #eef3f7;
    border: 1px solid rgba(0, 0, 0, 0.075);
    border-radius: 0.25rem;
    padding: 1rem 1.5rem 1rem 1rem;
    table {
      tbody {
        tr {
          td {
            padding: 0.5rem 0.75rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.075);
            &:first-child {
              text-align: left;
              padding-left: 2rem;
              min-width: 17rem;
            }
            &:last-child {
              padding-left: 0;
            }
            &.plaintext {
              font-family: "Helvetica Neue Light";
            }
            ul {
              list-style: none;
              margin: 0;
              padding: 0;
              li {
                &.plaintext {
                  font-family: "Helvetica Neue Light";
                }
              }
            }
          }
        }
      }
    }
  }
  .options {
    background-color: #eef3f7;
    border: 1px solid rgba(0, 0, 0, 0.075);
    border-radius: 0.25rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1rem 1.5rem 1rem 1rem;
    gap: 1rem;
    .left {
      .form-group {
        background-color: white;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.075);
        &:first-child {
          border-top-left-radius: 0.25rem;
          border-top-right-radius: 0.25rem;
        }
        &:last-child {
          border-bottom-left-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
        .col-sm-6 {
          padding-left: 2rem;

          &:last-child {
            padding: 0;
          }
          .form-control-plaintext {
            font-family: "Helvetica Neue Light";
          }
        }
      }
    }
    .right {
      table {
        width: 100%;
        background-color: white;
        border-radius: 0.25rem;
        padding: 0.5rem;
        tbody {
          tr {
            td {
              padding: 0.5rem 0.75rem;
              border-bottom: 1px solid rgba(0, 0, 0, 0.075);
              &:first-child {
                text-align: right;
                width: 11rem;
              }
              &:last-child {
                padding-left: 0;
              }
              &.plaintext {
                font-family: "Helvetica Neue Light";
              }
            }
          }
        }
      }
    }
  }
  .license-terms {
    background-color: #eef3f7;
    border: 1px solid rgba(0, 0, 0, 0.075);
    border-radius: 0.25rem;
    padding: 1rem 1.5rem 1rem 1rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    .license {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      p {
        line-height: 2rem;
        margin: 0;
        padding-left: 1.5rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;

        &:first-child {
          border: none;
        }
        &.span-2 {
          grid-column-start: span 2;
          grid-column-end: span 2;
          padding-left: 2rem;
        }
        &.plaintext {
          font-family: "Helvetica Neue Light";
        }
        button {
          width: 112px;
          height: 32px;
          margin-right: 1.5rem;
          background-color: #64748b;
          border: 1px solid #475569;
          color: white;
          border-radius: 0.25rem;
          font-size: 14px;
        }
      }
    }
    .form-group {
      label {
        &.plaintext {
          font-family: "Helvetica Neue Light";
        }
        &.underline {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
