<template>
  <div class="integrate-content">
    <div class="integrate__dialog">
      <DialogMessage
        v-if="message.length > 0"
        :propTitle="$t('title_message_info')"
        :prop-show="message.length > 0"
        :prop-message="message"
        @close="message = []"
      />
    </div>
    <div class="integrate__plans"></div>
    <div class="integrate__search" id="elementSearch">
      <IntegrateSearch
        :prop-buttons="buttons"
        :prop-search="myOrder.search"
        @onSearch="onSearch"
        @accordionHeaderClick="accordionHeaderSearchClick"
      />
    </div>
    <div class="integrate__datatable">
      <IntegrateDataTable
        :prop-items="myOrder.items"
        :prop-loading="myOrder.loading"
        :prop-table="myOrder.table"
        @downloadImage="downloadImage"
        @shoDialogCancelOrder="shoDialogCancelOrder"
      />
    </div>
  </div>
</template>
<script>
import DialogMessage from "@/components/commons/dialog/MessageBox";
import IntegrateDataTable from "./list/DataTable";
import IntegrateSearch from "./list/Search";
import planetFunc from "@/utils/functions/planet";
import { change_alias } from "@/utils/commons/common";
import axios from "axios";
export default {
  components: { DialogMessage, IntegrateDataTable, IntegrateSearch },
  props: {},
  data() {
    return {
      message: [],
      myOrder: {
        loading: false,
        page_number: 0,
        page_size: 10,
        total_page: 0,
        total_records: 0,
        items: [],
        _link: null,
        search: {
          status: null,
          page: 0,
          size: 10,
        },
        table: {
          selected: null,
          height: 400,
        },
        is_bottom: false,
      },

      buttons: {
        filter: {
          disabled: false,
          processing: false,
          visible: true,
        },
        detail: {
          disabled: false,
          processing: false,
        },
        cancelOrder: {
          disabled: false,
          processing: false,
        },
        download: {
          disabled: false,
          processing: false,
          ids: [],
        },
      },
    };
  },
  created() {
    setTimeout(() => {
      this.$emit("initBreadcrumb", [
        "menu_sidebar_integrate_data",
        "menu_sidebar_integrate_planet",
      ]);
    }, 200);
    if (!this.isAdmin) return;
    this.getOrders();
  },
  mounted() {
    setTimeout(() => {
      this.myOrder.table.height = this.calcTableHeight();
    }, 100);
  },
  methods: {
    accordionHeaderSearchClick() {},
    calcTableHeight() {
      let element = document.getElementById("elementSearch");
      return (
        this.documentHeight -
        3 * 0.5 * 16 -
        56 -
        (element ? element.offsetHeight : 0) -
        24 -
        2 * 0.5 * 16
      );
    },
    shoDialogCancelOrder(item) {},
    async downloadImage(item) {
      if (item && Object.keys(item).length > 0) {
        if (!item.data_detail) await this.getOrderById(item.id);
        let index = this.myOrder.items.findIndex((x) => x.id === item.id);
        if (index >= 0) {
          if (!this.myOrder.items[index].data_detail) return;
          let fileName = this.myOrder.items[
            index
          ].delivery.archive_filename.replace(
            "{{name}}",
            change_alias(this.myOrder.items[index].name)
          );
          let fileNameCheck = this.myOrder.items[
            index
          ].delivery.archive_filename.replace("{{name}}", "");
          let objLink =
            this.myOrder.items[index].data_detail._links &&
            this.myOrder.items[index].data_detail._links.results
              ? this.myOrder.items[index].data_detail._links.results.find(
                  function (x) {
                    return x.location.endsWith(fileNameCheck);
                  }
                )
              : null;
          if (!objLink)
            objLink =
              this.myOrder.items[index].data_detail._links &&
              this.myOrder.items[index].data_detail._links.results &&
              this.myOrder.items[index].data_detail._links.results.length > 0
                ? this.myOrder.items[index].data_detail._links.results[0]
                : null;
          if (!objLink) return;
          // check status file
          if (objLink.delivery === "success") {
            // check expired date
            let expired = new Date(objLink.expires_at);
            if (expired && expired.getTime() - new Date().getTime() > 0) {
              const link = document.createElement("a");
              link.href = objLink.location;
              link.setAttribute("download", fileName);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            } else {
              this.message.push("Download expired!");
            }
          } else {
            if (objLink.delivery)
              this.message.push("Delivery " + objLink.delivery);
          }
        }
      }
    },
    onSearch(status) {
      this.myOrder.search.status = status;
      this.myOrder.search.page = 0;
      this.myOrder.is_bottom = false;
      this.myOrder.items = [];
      this.getOrders();
    },
    async getOrders() {
      this.$store.dispatch("setSpinnerApp", {
        show: false,
      });
      this.myOrder.loading = true;
      try {
        let params = {};
        if (this.myOrder.search.status)
          params = {
            order_state: this.myOrder.search.status,
          };
        let response = await planetFunc.getOrders(params);
        if (response && response.status === 200) {
          this.myOrder.items = response.data.orders ? response.data.orders : [];
          this.myOrder._link = response.data._link ? response.data._link : null;
        }
      } catch (error) {
        this.myOrder.loading = false;
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
      this.myOrder.loading = false;
      this.$store.dispatch("setSpinnerApp", {
        show: false,
      });
    },

    async getOrderById(id) {
      this.$store.dispatch("setSpinnerApp", {
        show: true,
      });
      try {
        let response = await planetFunc.getOrderById(id);
        if (response && response.status === 200) {
          let index = this.myOrder.items.findIndex((x) => x.id === id);
          if (index >= 0) {
            this.myOrder.items[index].data_detail = response.data;
          }
        }
      } catch (error) {
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
      }
      this.$store.dispatch("setSpinnerApp", {
        show: false,
      });
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    tableHeight() {
      return this.documentHeight - 56 - 96 - 2 * 42 - 38;
    },
    lang() {
      return this.$store.getters.getLang;
    },
    roles() {
      return this.$store.getters.getRoles;
    },
    isAdmin() {
      return this.roles && this.roles.map((x) => x.name).includes("ROLE_ADMIN");
    },
  },
};
</script>
<style lang="scss" scoped>
.integrate-content {
  margin: 0;
  padding: 0.5rem;
  .integrate__search {
    padding: 0;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .integrate__datatable {
    margin-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
}
</style>
