import axios from "axios";
import basicApi from "@/utils/apis/basic";
import { capellaUsername, capellaPassword } from "@/utils/commons/environment";
import { geoServiceApiURL } from "@/utils/commons/environment";
function authenticateUser(user, password) {
  var token = user + ":" + password;
  // Should i be encoding this value????? does it matter???
  // Base64 Encoding -> btoa
  var hash = btoa(token);

  return "Basic " + hash;
}

export default {
  async getToken() {
    const object = axios({
      method: "post",
      url: "https://api.capellaspace.com/token",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: authenticateUser(capellaUsername, capellaPassword),
      },
      params: {},
    });
    let response = await object;
    return response;
  },
  async searchByIdsOld(ids, authorization) {
    const object = axios({
      method: "post",
      url: "https://75mf0ywiu5.execute-api.us-west-2.amazonaws.com/Prod/catalog/search",
      data: {
        sortby: [{ field: "properties.datetime", direction: "desc" }],
        ids: ids,
      },
      headers: {
        Authorization: authorization,
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
    let response = await object;
    return response;
  },
  async searchOld(entity, authorization, next) {
    entity.limit = 25;
    entity.sortby = [{ field: "properties.datetime", direction: "desc" }];
    const object = axios({
      method: "post",
      url: "https://75mf0ywiu5.execute-api.us-west-2.amazonaws.com/Prod/catalog/search",
      data: entity,
      headers: {
        Authorization: authorization,
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
    let response = await object;
    return response;
  },
  async search(entity, accessToken) {
    let headers = {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json; charset=UTF-8",
    };
    let payload = {
      intersects: entity.intersects,
      query: {},
      queryPage: {
        capella: {
          limit: 250,
          page: entity?.page || 1,
        },
      },
      searchChanel: {
        capella: true,
      },
    };

    if (entity.resolution_from || entity.resolution_to) {
      payload.query["sar:resolution_range"] = {
        gte: entity.resolution_from,
        lte: entity.resolution_to,
      };
    }

    if (entity.cloud_coverage_from || entity.cloud_coverage_to) {
      payload.query["eo:cloud_cover"] = {
        gte: entity.cloud_coverage_from,
        lte: entity.cloud_coverage_to,
      };
    }
    if (entity.incidence_angle_from || entity.incidence_angle_to) {
      payload.query["view:incidence_angle"] = {
        gte: entity.incidence_angle_from,
        lte: entity.incidence_angle_to,
      };
    }

    const object = axios({
      method: "post",
      url: geoServiceApiURL + "/search",
      data: payload,
      headers: headers,
    });
    let response = await object;
    return response;
  },
  async searchByIds(ids, accessToken) {
    let headers = {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json; charset=UTF-8",
    };
    let payload = {
      query: {},
      queryPage: {},
      searchChanel: {
        capella: true,
      },
      ids: ids,
    };
    const object = axios({
      method: "post",
      url: geoServiceApiURL + "/search",
      data: payload,
      headers: headers,
    });
    let response = await object;
    return response;
  },
  async getImageThumb(url) {
    let headers = {};
    const object = axios({
      method: "get",
      url: url.replace(
        "https://cirrus-prod-data-27qi4kja7o84.s3.us-west-2.amazonaws.com",
        "/capella"
      ),
      headers: headers,
      responseType: "blob",
    });
    let response = await object;
    return response;
  },
};
