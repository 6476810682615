<template>
  <div class="container-fluid">
    <h2>
      <button class="btn-transparent back" @click="goBackPage" v-if="prevRoute">
        <i class="fas fa-arrow-left"></i>
        &nbsp;&nbsp;
      </button>
      {{ breadcrumb }}
    </h2>
    <hr />
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      breadcrumb: ""
    };
  },
  created() {
    this.getBreadcrumb();
  },
  watch: {
    $route: {
      handler(val) {
        this.getBreadcrumb();
      },
      deep: true
    }
  },
  methods: {
    getBreadcrumb: function() {
      let route = this.$route;
      if (Object.keys(route.meta).length > 0)
        this.breadcrumb = route.meta.title ? route.meta.title : "";
    },
    goBackPage: function() {
      let route = {};
      if (this.prevRoute.name) route["name"] = this.prevRoute.name;
      else route["path"] = this.prevRoute.path;
      if (Object.entries(this.prevRoute.params).length > 0)
        route["params"] = this.prevRoute.params;
      this.$router.push(route);
    }
  },
  computed: {
    prevRoute() {
      return this.$store.getters.getPrevRoute;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>