<template>
  <div class="tasking-map">
    <div class="" id="taskingMap"></div>
  </div>
</template>
<script>
import Map from "ol/Map";
import View from "ol/View";
import XYZ from "ol/source/XYZ";
import { TileImage, Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { defaults as defaultControls } from "ol/control";
import { defaults } from "ol/interaction";
import { toLonLat, transform } from "ol/proj.js";
import { getCenter } from "ol/extent";
export default {
  props: {
    propFeature: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      map: Object,
      layer: Object,
    };
  },
  watch: {
    propFeature(val) {
      this.layer.getSource().clear();
      if (val) {
        this.layer.getSource().addFeature(val);
        var ext = this.propFeature.getGeometry().getExtent();
        this.map.getView().fit(ext, {
          size: this.map.getSize(),
        });
        var center = getCenter(ext);
        this.map.getView().animate({
          center: [center[0], center[1]],
          duration: 100,
          zoom:
            this.map.getView().getZoom() < 2
              ? 2
              : this.map.getView().getZoom() - 1,
        });
      }
    },
  },
  mounted() {
    this.initMapOpenlayer();
  },
  methods: {
    initMapOpenlayer: function () {
      var vm = this;
      try {
        var source = new VectorSource({ wrapX: false, id: "sourcedraw" });

        this.layer = new VectorLayer({
          source: source,
        });
        this.layer.set("id", "layerdraw");
        this.map = new Map({
          controls: defaultControls({ attribution: false, zoom: false }),
          layers: [
            new TileLayer({
              id: new Date().getTime(),
              source: new XYZ({
                url:
                  vm.basemap &&
                  Object.keys(vm.basemap).length > 0 &&
                  vm.basemap.source &&
                  vm.basemap.source.url
                    ? vm.basemap.source.url
                    : process.env.VUE_APP_BASEMAP_URL,
              }),
              show: true,
              opacity: 1,
            }),
            this.layer,
          ],
          target: "taskingMap",
          view: new View({
            center: transform(
              [108.222848, 16.062833],
              "EPSG:4326",
              "EPSG:3857"
            ),
            zoom: 8,
            minZoom: 0,
            maxZoom: 20,
          }),
        });
        if (this.propFeature && Object.keys(this.propFeature).length > 0) {
          source.addFeature(this.propFeature);
          var ext = this.propFeature.getGeometry().getExtent();

          this.map.getView().fit(ext, this.map.getSize());
          var center = getCenter(ext);
          this.map.getView().animate({
            center: [center[0], center[1]],
            duration: 100,
            zoom:
              this.map.getView().getZoom() < 2
                ? 2
                : this.map.getView().getZoom() - 1,
          });
        }

        this.$emit("initMap", {
          map: this.map,
          layer: this.layer,
        });
      } catch (error) {}
    },
  },
  computed: {
    basemap() {
      return this.$store.getters.getBasemap;
    },
  },
};
</script>
<style lang="scss" scoped>
.tasking-map {
  width: 100%;
  height: 320px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.25);
  #taskingMap {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
