<template>
  <div class="my-order-content">
    <div class="my-order__dialog">
      <DialogImgSentinelMetadata
        v-if="dlgImgHubMetadata.show"
        :prop-style="{ width: dlgImgHubMetadata.width }"
        :prop-show="dlgImgHubMetadata.show"
        :prop-title="dlgImgHubMetadata.title"
        :prop-item="dlgImgHubMetadata.value"
        @close="
          dlgImgHubMetadata.show = false;
          dlgImgHubMetadata.value = null;
        "
      />
      <DialogConfirmation
        v-if="dlgConfirmDelete.show"
        :prop-show="dlgConfirmDelete.show"
        :prop-title="$t('title_message_confirm')"
        :prop-icon="dlgConfirmDelete.icon"
        :prop-message="dlgConfirmDelete.message"
        :propStyle="{
          width: 'auto',
        }"
        :textNo="$t('button_text_close')"
        :textYes="$t('button_text_cancel')"
        :propDisableButton="buttons.delete.disabled"
        :propSpinerButton="buttons.delete.processing"
        @cancel="onCancelConfirmDelete"
        @confirmed="onConfirmedDelete"
      />
      <DialogMessage
        v-if="message.length > 0"
        :propTitle="$t('title_message_info')"
        :prop-show="message.length > 0"
        :prop-message="message"
        @close="message = []"
      />
    </div>
    <div class="my-order__search" id="elementSearch">
      <OrderSearch
        :propSearch="myOrder.search"
        :prop-buttons="buttons"
        :propOrderStatus="orderStatus.items"
        @onSearch="onSearch"
        @message="message = $event"
      />
    </div>
    <div class="my-order__datatable">
      <OrderDataTable
        :propItems="myOrder.items"
        :prop-table="myOrder.table"
        :prop-button-detail="buttons.detail"
        :prop-loading="myOrder.loading"
        :propTotal="myOrder.total_records"
        :propButtonDownload="buttons.download"
        @nextPage="nextPage"
        @showOrderDetail="showOrderDetail"
        @showDialogCancel="showDialogCancel"
        @shoDialogRemark="shoDialogRemark"
        @downloadImage="downloadImage"
      />
    </div>
  </div>
</template>
<script>
import OrderSearch from "./myorderedfree/Search.vue";
import OrderDataTable from "./myorderedfree/DataTable";
import DialogConfirmation from "@/components/commons/dialog/Confirmation";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import DialogOrderDetail from "./myorderedfree/detail/DialogOrderDetail.vue";
import DialogCancelOrder from "./myorderedfree/detail/DialogCancelOrder.vue";
import DialogRemark from "./myorderedfree/detail/DialogRemark.vue";
import DialogImgSentinelMetadata from "@/components/commons/metadata/MetadataSentinel";
import meControllerFunc from "@/utils/functions/mecontroller";
import metaKeyFunc from "@/utils/functions/metakey";
import imageFunc from "@/utils/functions/image";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import requestFreeImageFunc from "@/utils/functions/requestfreeimage";
import { getFileExtension3 } from "@/utils/commons/common";
import { getMimeType } from "@/utils/commons/mimetypes";
import { change_alias } from "@/utils/commons/common";
import axios from "axios";
export default {
  components: {
    OrderDataTable,
    OrderSearch,
    DialogConfirmation,
    DialogCancelOrder,
    DialogMessage,
    DialogImgSentinelMetadata,
    DialogOrderDetail,
    DialogRemark,
  },
  data() {
    return {
      message: [],
      orderStatus: {
        items: [],
        data: [],
      },
      priorityLevel: {
        items: [],
        data: [],
      },
      myOrder: {
        loading: false,
        page_number: 0,
        page_size: 10,
        total_page: 0,
        total_records: 0,
        items: [],
        search: {
          create_from: null,
          create_to: null,
          name: null,
          is_completed: null,
          status_id: null,
          page: 0,
          size: 10,
        },
        table: {
          selected: null,
          height: 400,
        },
        is_bottom: false,
      },
      confirm: {
        message: null,
        show: false,
        title: this.$t("title_message_confirm"),
        icon: "fas fa-question",
        callback: {
          no: {
            method: null,
            params: null,
          },
          yes: {
            method: null,
            params: null,
          },
        },
        initValue: [],
        value: false,
      },
      dlgImgHubMetadata: {
        show: false,
        title: "Metadata",
        value: null,
        width: "480px",
      },

      dlgConfirmDelete: {
        message: null,
        show: false,
        title: null,
        icon: "fas fa-question",
        value: false,
      },
      buttons: {
        filter: {
          disabled: false,
          processing: false,
          visible: true,
        },
        detail: {
          disabled: false,
          processing: false,
        },
        delete: {
          disabled: false,
          processing: false,
        },
        cancelOrder: {
          disabled: false,
          processing: false,
        },
        download: {
          disabled: false,
          processing: false,
          ids: [],
        },
      },
    };
  },
  async created() {
    this.myOrder.search.size = Math.ceil(this.tableHeight / 50) + 5;
    this.$emit("initBreadcrumb", [
      "menu_sidebar_orders",
      "menu_sidebar_my_ordered_free",
    ]);
    var vm = this;
    if (vm.documentWidth < 576) {
      this.dlgImgHubMetadata.width = this.documentWidth + "px";
    } else if (vm.documentWidth >= 576 && vm.documentWidth < 768) {
      this.dlgImgHubMetadata.width = "540px";
    } else if (vm.documentWidth >= 768 && vm.documentWidth < 992) {
      this.dlgImgHubMetadata.width = "720px";
    } else {
      this.dlgImgHubMetadata.width = "960px";
    }
    this.getMyOrder();
  },

  mounted() {
    setTimeout(() => {
      this.myOrder.table.height = this.calcTableHeight();
    }, 100);
  },
  watch: {},
  methods: {
    accordionHeaderClick() {
      setTimeout(() => {
        this.myOrder.table.height = this.calcTableHeight();
      }, 100);
    },
    calcTableHeight() {
      let element = document.getElementById("elementSearch");
      return (
        this.documentHeight -
        3 * 0.5 * 16 -
        56 -
        (element ? element.offsetHeight : 0) -
        24 -
        2 * 0.5 * 16
      );
    },
    /*------------- Emit ------------------*/
    async downloadImage(item) {
      if (item && Object.keys(item).length > 0) {
        let filename = "";
        try {
          filename = (item.path ? item.path : "").substring(
            (item.path ? item.path : "").indexOf("/") + 1,
            (item.path ? item.path : "").length
          );
        } catch (error) {}
        let idTemp = item.id
          ? item.id
          : new Date().getTime() -
            Math.floor(Math.random() * 9999999) +
            Math.floor(Math.random() * 8888888);

        if (item.url_down) {
          window.open(item.url_down, "_blank");
          /*
          const linkdown = document.createElement("a");
          linkdown.href = url;
          linkdown.setAttribute(
            "download",
            filename
              ? filename
              : change_alias(item.name) + (extension ? "." + extension : ".zip")
          );
          document.body.appendChild(linkdown);
          linkdown.click();
          document.body.removeChild(linkdown);
          */
        } else {
          this.buttons.download.disabled = true;
          this.buttons.download.processing = true;
          this.buttons.download.ids.push(item.id);
          this.$store.dispatch("setFilesDownload", [
            {
              is_add: true,
              file: {
                name: item.name,
                file_name: item.path,
                id: item.id ? item.id : idTemp,
              },
            },
          ]);
          try {
            let response = await requestFreeImageFunc.download(
              item.id,
              this.$store.getters.getAccessToken
            );
            if (response && response.status === 200) {
              let index = this.myOrder.items.findIndex((x) => x.id === item.id);
              if (index >= 0) {
                this.myOrder.items[index].url_down = response.data.url;
              }
              if (response.data.url) window.open(response.data.url, "_blank");
              else
                this.message.push(
                  this.$t("message_download_file_error", null, {
                    name: item.name,
                  })
                );
              /*
              let extension = "";
              try {
                extension = getFileExtension3(item.path ? item.path : "");
              } catch (error2) {}
              if (!extension) extension = "zip";
              let contentType =
                response.headers && response.headers["content-type"]
                  ? response.headers["content-type"]
                  : "";
              let url,
                blob = new Blob(
                  [response.data],
                  contentType
                    ? { type: contentType }
                    : getMimeType(extension.toLowerCase())
                );

              if (blob.size === 0) {
                this.message.push(this.$t("message_file_dose_not_exist"));
              } else {
                try {
                  if (!window.navigator.msSaveOrOpenBlob) {
                    // BLOB NAVIGATOR
                    url = window.URL.createObjectURL(blob);
                  } else {
                    // BLOB FOR EXPLORER 11
                    url = window.navigator.msSaveOrOpenBlob(
                      blob,
                      filename
                        ? filename
                        : change_alias(item.name) +
                            (extension ? "." + extension : ".zip")
                    );
                  }
                  let index = this.myOrder.items.findIndex(
                    (x) => x.id === item.id
                  );
                  if (index >= 0) {
                    this.myOrder.items[index].url_down = url;
                  }
                  const linkdown = document.createElement("a");
                  linkdown.href = url;
                  linkdown.setAttribute(
                    "download",
                    filename
                      ? filename
                      : change_alias(item.name) +
                          (extension ? "." + extension : ".zip")
                  );
                  document.body.appendChild(linkdown);
                  linkdown.click();
                  document.body.removeChild(linkdown);
                  window.URL.revokeObjectURL(url);
                } catch (error3) {}
              }
              */
            } else {
              this.message.push(
                this.$t("message_download_file_error", null, {
                  name: item.name,
                })
              );
            }
          } catch (error) {
            if (error.response && error.response.status) {
              this.refreshToken(this.downloadImage, item);
            } else {
              let msg =
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message
                  ? error.response.data.message
                  : null;
              this.message.push(
                msg
                  ? msg
                  : this.$t("message_download_file_error", null, {
                      name: item.name,
                    })
              );
            }
          }

          this.buttons.download.disabled = false;
          this.buttons.download.processing = false;
          let indexR = this.buttons.download.ids.findIndex(
            (x) => x === item.id
          );
          if (indexR >= 0) this.buttons.download.ids.splice(indexR, 1);
          this.$store.dispatch("setFilesDownload", [
            {
              is_add: false,
              file: {
                name: item.name,
                file_name: item.path,
                id: item.id ? item.id : idTemp,
              },
            },
          ]);
        }
      }
    },
    shoDialogRemark(data) {
      this.dlgDialogRemark.value = data.remark;
      this.dlgDialogRemark.title = data.title;
      this.dlgDialogRemark.show = true;
    },
    showDialogCancel(item) {
      this.dlgConfirmDelete.value = item;
      this.dlgConfirmDelete.message = this.$t(
        "message_confirm_delete_order_image_free",
        null,
        {
          name: item.name ? item.name.trim() : "",
        }
      );
      this.dlgConfirmDelete.show = true;
    },
    async showOrderDetail(item) {
      let index2 = this.myOrder.items.findIndex((x) => x.uuid === item.uuid);
      if (index2 >= 0 && this.myOrder.items[index2].metadata) {
        this.dlgImgHubMetadata.value = this.myOrder.items[index2].metadata;
        this.dlgImgHubMetadata.show = true;
      } else {
        try {
          if (
            !this.sentinelData ||
            Object.keys(this.sentinelData).length === 0
          ) {
            await this.getAllSentinelData();
          }
          let params = {
            offset: 0,
            limit: 10,
            sortedby: "ingestiondate",
            order: "desc",
            filter: "(filename:" + item.name + "*)",
          };
          this.buttons.detail.disabled = true;
          this.buttons.detail.processing = true;
          this.buttons.detail.id = item.id;
          let response = await imageFunc.searchImagesHub(
            params,
            {},
            this.sentinelData.username,
            this.sentinelData.password,
            this.sentinelData.api_url
          );
          if (response && response.status === 200) {
            if (response.data.products && response.data.products.length > 0) {
              let index = response.data.products.findIndex(
                (x) => x.uuid === item.uuid
              );
              if (index >= 0) {
                if (index2 >= 0) {
                  if (!this.myOrder.items[index2].metadata) {
                    this.myOrder.items[index2].metadata =
                      response.data.products[index];
                  }
                  await this.getIconPreview(item.uuid);
                }

                this.dlgImgHubMetadata.value = response.data.products[index];
                this.dlgImgHubMetadata.show = true;
              } else {
                this.message.push(this.$t("message_not_found_image"));
              }
            } else {
              this.message.push(this.$t("message_not_found_image"));
            }
          } else {
            this.message.push(this.$t("message_not_found_image"));
          }

          this.buttons.detail.disabled = false;
          this.buttons.detail.processing = false;
          this.buttons.detail.id = null;
        } catch (error) {
          this.buttons.detail.disabled = false;
          this.buttons.detail.processing = false;
          this.buttons.detail.id = null;
          if (error.response && error.response.status === 401) {
            this.refreshToken(this.showOrderDetail, item);
          } else {
            this.message.push(this.$t("message_not_found_image"));
          }
        }
      }
    },

    async getIconPreview(uuid) {
      let index2 = this.myOrder.items.findIndex((x) => x.uuid === uuid);
      if (index2 >= 0 && this.myOrder.items[index2].metadata) {
        try {
          return axios({
            url: this.sentinelData.preview_url.replace("{uuid}", uuid),
            method: "get",
            headers: {
              Authorization:
                "basic " +
                btoa(
                  this.sentinelData.username + ":" + this.sentinelData.password
                ),
            },
            responseType: "blob",
          })
            .then((response) => {
              if (response && response.status === 200) {
                let contentType =
                  response.headers && response.headers["content-type"]
                    ? response.headers["content-type"]
                    : "image/jpeg";
                let blob = new Blob([response.data], { type: contentType });
                let extension = "";
                try {
                  extension = contentType.substring(
                    contentType.indexOf("/") + 1,
                    contentType.length
                  );
                } catch (error2) {}
                if (blob.size != 0) {
                  if (!window.navigator.msSaveOrOpenBlob) {
                    // BLOB NAVIGATOR
                    this.myOrder.items[index2].metadata.icon_src =
                      window.URL.createObjectURL(blob);
                  } else {
                    // BLOB FOR EXPLORER 11
                    this.myOrder.items[index2].metadata.icon_src =
                      window.navigator.msSaveOrOpenBlob(
                        blob,
                        uuid + "." + extension ? extension : "jpeg"
                      );
                  }
                }
              }
            })
            .catch((error) => {});
        } catch (error) {}
      }
    },
    nextPage() {
      if (!this.myOrder.is_bottom) {
        this.myOrder.search.page = this.myOrder.search.page + 1;
        this.getMyOrder();
      }
    },
    onSearch(data) {
      this.myOrder.search.create_from = data.create_from
        ? data.create_from
        : null;
      this.myOrder.search.create_to = data.create_to ? data.create_to : null;
      this.myOrder.search.status_id = data.status_id ? data.status_id : null;
      switch (data.status_id) {
        case 0:
          this.myOrder.search.is_completed = false;
          break;
        case 1:
          this.myOrder.search.is_completed = true;
          break;
        default:
          this.myOrder.search.is_completed = null;
          break;
      }
      this.myOrder.search.name = data.name ? data.name : null;
      this.myOrder.items = [];
      this.myOrder.page = 0;
      this.myOrder.is_bottom = false;
      this.getMyOrder();
    },
    async onCancelOrder(content) {
      try {
        this.buttons.cancelOrder.disabled = true;
        this.buttons.cancelOrder.processing = true;
        let response = await meControllerFunc.cancelOrder(
          {
            content: content,
            orderId: this.dlgCancelOrder.value.id,
            order_id: this.dlgCancelOrder.value.id,
          },
          this.$store.getters.getAccessToken
        );
        let msg = null;
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            this.$toast.success({
              title: this.$t("title_message_info"),
              message: this.$t("message_cancel_order_success"),
              position: "top right",
            });

            this.onSearch({
              create_from: this.myOrder.search.create_from,
              create_to: this.myOrder.search.create_to,
              status_id: this.myOrder.search.status_id,
              name: this.myOrder.search.name,
            });
            this.dlgCancelOrder.value = null;
            this.dlgCancelOrder.show = false;
          } else {
            msg = this.$t("message_cancel_order_error");
          }
        } else msg = this.$t("message_cancel_order_error");
        if (msg) this.message.push(msg);
        this.buttons.cancelOrder.disabled = false;
        this.buttons.cancelOrder.processing = false;
      } catch (error) {
        this.buttons.cancelOrder.disabled = false;
        this.buttons.cancelOrder.processing = false;
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.onCancelOrder);
        } else {
          let msge =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msge ? msge : this.$t("message_cancel_order_error")
          );
        }
      }
    },
    onCancelConfirmDelete() {
      this.dlgConfirmDelete.show = false;
      this.dlgConfirmDelete.message = null;
      this.dlgConfirmDelete.value = null;
    },
    async onConfirmedDelete() {
      this.buttons.delete.disabled = true;
      this.buttons.delete.processing = true;
      this.$store.dispatch("setSpinnerApp", {
        show: true,
      });
      try {
        let response = await meControllerFunc.cancelRequestFreeImage(
          this.dlgConfirmDelete.value.id,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.$toast.success({
              title: this.$t("title_message_info"),
              message: this.$t(
                "message_cancel_order_image_free_success",
                null,
                {
                  name: this.dlgConfirmDelete.value.name
                    ? this.dlgConfirmDelete.value.name.trim()
                    : "",
                }
              ),
              position: "top right",
            });
            this.onSearch({
              name: this.myOrder.search.name,
              is_completed: this.myOrder.search.is_completed,
              create_from: this.myOrder.search.create_from,
              create_to: this.myOrder.search.create_to,
            });
            this.onCancelConfirmDelete();
          } else {
            this.message.push([
              this.$t("message_cancel_order_image_free_error", null, {
                name: this.dlgConfirmDelete.value.name
                  ? this.dlgConfirmDelete.value.name.trim()
                  : "",
              }),
            ]);
          }
        } else {
          this.message.push([
            this.$t("message_cancel_order_image_free_error", null, {
              name: this.dlgConfirmDelete.value.name
                ? this.dlgConfirmDelete.value.name.trim()
                : "",
            }),
          ]);
        }
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });

        this.buttons.delete.disabled = false;
        this.buttons.delete.processing = false;
      } catch (error) {
        this.buttons.delete.disabled = false;
        this.buttons.delete.processing = false;
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.onConfirmedDelete);
        } else {
          let msg =
            error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push([
            msg
              ? msg
              : this.$t("message_cancel_order_image_free_error", null, {
                  name: this.dlgConfirmDelete.value.name
                    ? this.dlgConfirmDelete.value.name.trim()
                    : "",
                }),
          ]);
        }
      }
      this.buttons.delete.disabled = false;
      this.buttons.delete.processing = false;
    },
    /*--------------- Get data ------------------*/

    async getAllSentinelData() {
      try {
        let response = await metaKeyFunc.getByKey(
          "SENTINEL_DATA",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          let data = response.data.data ? response.data.data : [];
          if (data.length > 0) {
            try {
              let obj = JSON.parse(
                await cryptoFunc.decrypt(data[data.length - 1].value)
              );
              if (obj && Object.keys(obj).length > 0) {
                this.$store.dispatch("setSentinelData", {
                  username: obj.username
                    ? obj.username.trim().replace(/\s\s+/g, " ")
                    : null,
                  password: obj.password
                    ? obj.password.trim().replace(/\s\s+/g, " ")
                    : null,
                  api_url: obj.api_url
                    ? obj.api_url.trim().replace(/\s\s+/g, " ")
                    : null,
                  preview_url: obj.preview_url
                    ? obj.preview_url.trim().replace(/\s\s+/g, " ")
                    : null,
                });
              }
            } catch (err) {}
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllSentinelData);
        }
      }
    },

    async getMyOrder() {
      let params = {
        create_from: this.myOrder.search.create_from
          ? new Date(this.myOrder.search.create_from)
          : null,
        create_to: this.myOrder.search.create_to
          ? new Date(this.myOrder.search.create_to)
          : null,
        size: this.myOrder.search.size,
        page: this.myOrder.search.page,
      };
      this.myOrder.loading = true;
      this.buttons.filter.disabled = true;
      this.buttons.filter.processing = true;
      try {
        let response = await meControllerFunc.getRequestFreeImage(
          params,
          {
            is_completed: this.myOrder.search.is_completed,
            name: this.myOrder.search.name
              ? this.myOrder.search.name
                  .trim()
                  .replace(/\+/g, " ")
                  .replace(/\s\s+/g, " ")
              : null,
            from: this.myOrder.search.create_from
              ? new Date(this.myOrder.search.create_from)
              : null,
            to: this.myOrder.search.create_to
              ? new Date(this.myOrder.search.create_to)
              : null,
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.myOrder.items = [
            ...this.myOrder.items,
            ...response.data.content_page,
          ];
          this.myOrder.page_number = response.data.page_number;
          this.myOrder.page_size = response.data.page_size;
          this.myOrder.total_page = response.data.total_page;
          this.myOrder.total_records = response.data.total_records;
          if (
            (this.myOrder.items.length === this.myOrder.total_records &&
              this.myOrder.total_page > 1) ||
            (response.data.total_page === 1 &&
              this.myOrder.items.length <= response.data.page_size)
          )
            this.myOrder.is_bottom = true;
        }

        this.myOrder.loading = false;
        this.buttons.filter.disabled = false;
        this.buttons.filter.processing = false;
      } catch (error) {
        this.myOrder.loading = false;
        this.buttons.filter.disabled = false;
        this.buttons.filter.processing = false;

        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getMyOrder);
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    tableHeight() {
      return this.documentHeight - 56 - 96 - 2 * 42 - 38;
    },
    lang() {
      return this.$store.getters.getLang;
    },
    sentinelData() {
      return this.$store.getters.getSentinelData;
    },
  },
};
</script>
<style lang="scss" scoped>
.my-order-content {
  margin: 0;
  padding: 0.5rem;
  .my-order__search {
    padding: 0;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .my-order__datatable {
    margin-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
}
</style>

