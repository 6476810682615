<template>
  <div :style="{ minHeight: propContentHeight + 'px', overflowY: 'auto' }">
    <div
      class="loading-description"
      v-if="propProductTypes && propProductTypes.loading"
    >
      <p>
        <i class="fas fa-spinner fa-spin"></i>&nbsp;Please wait while loading
        product types
      </p>
    </div>
    <div class="product-types" v-else>
      <CommonAccordion
        :propTitle="type.sensor.label"
        :isChevronEnd="true"
        v-for="(type, index) in productTypes4Select"
        :key="index"
      >
        <div slot="content" class="p-3">
          <div
            class="field-radio"
            v-for="t in type.types"
            :key="t.productTypeId"
          >
            <RadioButton
              :id="t.productTypeId"
              :name="t.productTypeId"
              :value="t.productTypeId"
              v-model="selected"
            />
            <label :for="t.productTypeId">{{ t.technicalProduct.label }}</label>
          </div>
        </div>
      </CommonAccordion>
    </div>
  </div>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion";
import Checkbox from "@/components/commons/checkbox/Checkbox";
import RadioButton from "@/components/commons/radiobutton/RadioButton";
export default {
  components: {
    CommonAccordion,
    Checkbox,
    RadioButton,
  },
  props: {
    propContentHeight: {
      type: Number,
    },
    propProductTypes: {
      type: Object,
      default: () => {},
    },
    propEntity: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    if (
      !this.propProductTypes ||
      (this.propProductTypes &&
        this.propProductTypes.items &&
        this.propProductTypes.items.length === 0)
    ) {
      this.$emit("getProductTypes");
    }
    this.selected = this.propEntity.productType;
  },
  watch: {
    selected(val) {
      this.$emit("selectedProductType", val);
    },
  },
  computed: {
    sensors() {
      let results = [];
      results = [
        ...new Set(
          this.propProductTypes.items.map((x) => x.technicalProduct.sensor)
        ),
      ];
      for (let i = 0; i < results.length; i++) {
        if (i > 0 && i < results.length - 1) {
          let index = results.findIndex((x) => x.id === results[i].id);
          if (index >= 0) {
            results.splice(index, 1);
            i = i - 1;
          }
        }
      }
      return results;
    },
    productTypes4Select() {
      let results = [];
      for (let i = 0; i < this.sensors.length; i++) {
        results.push({
          sensor: this.sensors[i],
          types: this.propProductTypes.items.filter(
            (x) => x.technicalProduct.sensor.id === this.sensors[i].id
          ),
        });
      }
      return results;
    },
  },
};
</script>
<style lang="scss" scoped>
.loading-description {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #eef3f7;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  width: 100%;
  p {
    margin-bottom: 0;
  }
}
.product-types {
  padding: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  .field-radio {
    margin-bottom: 0.5rem;
    &:last-child {
      margin: 0;
    }
    label {
      margin: 0;
      margin-left: 0.5rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="scss">
@import "@/assets/scss/_variables.scss";
.oneatlas-components {
  .product-types {
    .accordion-header a {
      color: white;
      background: $background-color-2;
      border: 1px solid $background-color-2;
      border-radius: 0.25rem;
      span {
        color: white !important;
      }
      &.active {
        color: white;
        background: $background-color-2;
        border: 1px solid $background-color-2;
        span {
          color: white;
        }
      }
    }
  }
}
</style>
