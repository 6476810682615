<template>
  <div class="wrapper">
    <header-layout v-if="isHeader"></header-layout>
    <main
      class="page-content"
      :style="[
        routeName === 'Signup' || routeName === 'ContactUs'
          ? { paddingTop: 56 + 'px' }
          : {},
      ]"
    >
      <slot name="default">
        <main-layout></main-layout>
      </slot>
    </main>
    <WidgetDownloadProgressBar
      v-if="widget.download.show"
      :prop-items="itemsDownload"
      :prop-show="widget.download.show2"
      :isLayoutDefault="true"
      @close="closeWidgetDownload"
    />
    <footer-layout></footer-layout>
  </div>
</template>
<script>
import HeaderLayout from "./default/Header";
import MainLayout from "./default/Main";
import FooterLayout from "./default/Footer";
import WidgetDownloadProgressBar from "@/components/commons/widget/DownloadProgressBar";
export default {
  components: {
    HeaderLayout,
    MainLayout,
    FooterLayout,
    WidgetDownloadProgressBar,
  },
  data() {
    return {
      widget: {
        download: {
          show: false,
          show2: false,
        },
      },
    };
  },
  created() {},
  computed: {
    isHeader() {
      return this.$store.getters.getIsHeader;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    mainHeight() {
      return this.documentHeight - (this.isHeader ? 3 * 16 : 0);
    },
    itemsDownload() {
      return this.$store.getters.getImagesDownload;
    },
    routeName() {
      return this.$route.name;
    },
  },
  watch: {
    itemsDownload(val) {
      if (val && val.length > 0) {
        this.widget.download.show2 = true;
        this.widget.download.show = true;
      } else {
        this.closeWidgetDownload();
      }
    },
  },
  methods: {
    closeWidgetDownload() {
      this.widget.download.show2 = false;
      setTimeout(() => {
        this.widget.download.show = false;
      }, 200);
    },
  },
};
</script>
<style lang="scss" scoped>
.callout {
  position: relative;
  margin-top: 0px;
  margin-right: 10px;
  float: right;
  background-color: white;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  z-index: 11;
  box-shadow: 3px 5px 19px 0 rgba(49, 50, 50, 0.62);
  width: 260px;
  .notch {
    position: absolute;
    top: -9px;
    right: 2.5rem;
    margin: 0;
    border-top: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    padding: 0;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    display: none;
    z-index: 10;
  }
}
</style>
