<template>
  <div class="_3Y2KAlw0nf54M3b1ku8">
    <div class="drag-header">
      <h5 class="drag-title" id="exampleModalLabel">{{ title }}</h5>
      <div></div>
      <div>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          @click="handleClose"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          v-if="isMinimize"
          type="button"
          class="minimize"
          data-dismiss="modal"
          aria-label="Minimize"
          @click="handleMinimize"
          style="margin-right: 5px"
        >
          <span aria-hidden="true">&minus;</span>
        </button>
      </div>
    </div>
    <div class="drag-body">
      <div id="loading" v-if="isLoading">
        <div class="loading"></div>
      </div>
      <slot v-if="!isLoading"></slot>
    </div>
    <slot name="drag-footer" class="drag-footer"></slot>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isMinimize: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClose: function () {
      this.$emit("close");
    },
    handleMinimize: function () {
      $(".card").hide(100);
      this.$store.dispatch("setDialogStatus", false);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
.drag-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.375rem 0.5rem;
  border-bottom: 1px solid #e9ecef;
  background-color: $background-color;
  color: white;
  height: 2rem;
  margin: 0.25rem;
}
.drag-title {
  margin-bottom: 0;
  line-height: 1.25;
  font-size: 14px;
}
button.close {
  color: white;
  outline: none;
  font-size: 1.25rem;
  font-weight: normal;
}
.close:hover {
  color: #dc3545;
}
.drag-body {
  width: 100%;
  height: calc(100% - 28px);
  overflow: auto;
  padding: 0.25rem;
  padding-top: 0;
}
.minimize {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  cursor: pointer;
  font-size: 20px !important;
}
button.minimize {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}
button.minimize {
  color: white;
  outline: none;
}
</style>
