var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"sidebar-wrapper",attrs:{"id":"sidebar"}},[_c('div',{staticClass:"sidebar-content"},[_c('div',{staticClass:"sidebar-brand"},[_c('a',{staticClass:"text-left",on:{"click":_vm.goHome}},[_c('img',{staticClass:"d-inline-block align-top",attrs:{"src":"/img/logo.png","alt":""}}),_c('span',{staticClass:"text-white"},[_vm._v("Geospatial Hub")])]),_vm._m(0)]),_c('div',{staticClass:"sidebar-header"},[_vm._m(1),_c('div',{staticClass:"user-info"},[_c('span',{staticClass:"user-name"},[_c('strong',[_vm._v(_vm._s(_vm.userMe.full_name ? _vm.userMe.full_name : ""))])]),_vm._m(2)])]),_c('div',{staticClass:"sidebar-menu"},[_c('ul',[_c('li',{staticClass:"sidebar-dropdown",class:['USER_INFO', 'USER_CHANGE_PASS'].includes(_vm.menuActive)
              ? 'active'
              : ''},[_c('a',[_c('i',{staticClass:"fas fa-user"}),_c('span',[_vm._v(_vm._s(_vm.$t("menu_sidebar_account")))])]),_c('div',{staticClass:"sidebar-submenu menu-children",style:({
              display: ['USER_INFO', 'USER_CHANGE_PASS'].includes(_vm.menuActive)
                ? 'block'
                : '',
            })},[_c('ul',[_c('li',{class:_vm.menuActive === 'USER_INFO' ? 'active' : '',on:{"click":_vm.goUserInfoPage}},[_c('a',[_vm._v(_vm._s(_vm.$t("label_my_info")))])]),_c('li',{class:_vm.menuActive === 'USER_CHANGE_PASS' ? 'active' : '',on:{"click":_vm.goUserChangePassPage}},[_c('a',[_vm._v(_vm._s(_vm.$t("menu_sidebar_change_pass")))])])])])]),_c('li',{staticClass:"sidebar-dropdown",class:[
              'MY_IMAGES',
              'UPLOAD_HISTORY',
              'MY_SHARE',
              'USER_SHARED',
              'UPLOAD' ].includes(_vm.menuActive)
              ? 'active'
              : ''},[_c('a',[_c('i',{staticClass:"fas fa-images"}),_c('span',[_vm._v(_vm._s(_vm.$t("menu_sidebar_data_management")))])]),_c('div',{staticClass:"sidebar-submenu menu-children",style:({
              display: [
                'MY_IMAGES',
                'UPLOAD_HISTORY',
                'MY_SHARE',
                'USER_SHARED',
                'UPLOAD' ].includes(_vm.menuActive)
                ? 'block'
                : '',
            })},[_c('ul',[_c('li',{class:_vm.menuActive === 'USER_SHARED' ? 'active' : '',on:{"click":_vm.goUserSharedPage}},[_c('a',[_vm._v(_vm._s(_vm.$t("menu_sidebar_image_shared_with_me")))])])])])]),_c('li',{staticClass:"sidebar-dropdown",class:['MY_ORDER', 'MY_ORDER_TASKING'].includes(_vm.menuActive)
              ? 'active'
              : ''},[_c('a',[_c('i',{staticClass:"fas fa-clipboard-list"}),_c('span',[_vm._v(_vm._s(_vm.$t("menu_sidebar_orders"))+" ")])]),_c('div',{staticClass:"sidebar-submenu menu-children",style:([
              !_vm.menuActive ||
              ['MY_ORDER', 'MY_ORDER_TASKING'].includes(_vm.menuActive)
                ? { display: 'block' }
                : {} ])},[_c('ul',[_c('li',{class:_vm.menuActive === 'MY_ORDER_TASKING' ? 'active' : '',on:{"click":_vm.goOrderTaskingPage}},[_c('a',[_vm._v(_vm._s(_vm.$t("menu_sidebar_my_ordered_tasking")))])]),_c('li',{class:_vm.menuActive === 'MY_ORDER' ? 'active' : '',on:{"click":_vm.goMyOrderPage}},[_c('a',[_vm._v(_vm._s(_vm.$t("menu_sidebar_my_request")))])])])])]),_c('li',{staticClass:"sidebar-dropdown",class:[
              'INTEGRATE_DATA',
              'INTEGRATE_PLANET',
              'INTEGRATE_ONEATLAS' ].includes(_vm.menuActive)
              ? 'active'
              : ''},[_c('a',[_c('i',{staticClass:"fas fa-images"}),_c('span',[_vm._v(_vm._s(_vm.$t("menu_sidebar_integrate_data")))])]),_c('div',{staticClass:"sidebar-submenu menu-children",style:({
              display: [
                'INTEGRATE_DATA',
                'INTEGRATE_PLANET',
                'INTEGRATE_ONEATLAS' ].includes(_vm.menuActive)
                ? 'block'
                : '',
            })},[_c('ul',[_c('li',{class:_vm.menuActive === 'INTEGRATE_PLANET' ? 'active' : '',on:{"click":_vm.go2IntegratePlanetPage}},[_c('a',[_vm._v(_vm._s(_vm.$t("menu_sidebar_integrate_planet")))])]),_c('li',{class:_vm.menuActive === 'INTEGRATE_ONEATLAS' ? 'active' : '',on:{"click":_vm.go2IntegrateAirbusPage}},[_c('a',[_vm._v(_vm._s(_vm.$t("menu_sidebar_integrate_oneatlas")))])])])])]),(_vm.isAdmin)?_c('li',{staticClass:"sidebar-dropdown",class:['TASKING_DATA', 'TASKING_PLANET', 'TASKING_ONEATLAS'].includes(
              _vm.menuActive
            )
              ? 'active'
              : ''},[_c('a',[_c('i',{staticClass:"fas fa-images"}),_c('span',[_vm._v(_vm._s(_vm.$t("menu_sidebar_tasking")))])]),_c('div',{staticClass:"sidebar-submenu menu-children",style:({
              display: [
                'TASKING_DATA',
                'TASKING_PLANET',
                'TASKING_ONEATLAS' ].includes(_vm.menuActive)
                ? 'block'
                : '',
            })},[_c('ul',[_c('li',{class:_vm.menuActive === 'TASKING_PLANET' ? 'active' : '',on:{"click":_vm.go2IntegratePlanetPage}},[_c('a',[_vm._v(_vm._s(_vm.$t("menu_sidebar_integrate_planet")))])]),_c('li',{class:_vm.menuActive === 'TASKING_ONEATLAS' ? 'active' : '',on:{"click":_vm.go2TaskingAirbusPage}},[_c('a',[_vm._v(_vm._s(_vm.$t("menu_sidebar_integrate_oneatlas")))])])])])]):_vm._e(),_c('li',{class:['STATISTIC_REPORT'].includes(_vm.menuActive) ? 'active' : '',attrs:{"data-type":"no-sub"},on:{"click":_vm.goStatisticReportPage}},[_c('a',[_c('i',{staticClass:"fas fa-chart-line"}),_vm._v(_vm._s(_vm.$t("menu_sidebar_statistic")))])])])])]),_c('div',{staticClass:"sidebar-footer text-center"},[_c('button',{staticClass:"btn btn-sm",on:{"click":_vm.onLogout}},[_c('i',{staticClass:"fa fa-power-off"})])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"close-sidebar"}},[_c('i',{staticClass:"fas fa-times"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-pic"},[_c('img',{staticClass:"img-responsive img-rounded",attrs:{"src":"/img/user.jpg","alt":"User picture"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"user-status"},[_c('i',{staticClass:"fa fa-circle"}),_c('span',{staticClass:"text-white"},[_vm._v("Online")])])}]

export { render, staticRenderFns }