<template>
  <div class="tasking-coordinates">
    <div class="">
      <div class="aoi-info">
        <div class="">
          <div class="geospatial-label font-weight-bold">
            {{ $t("label_polygon_points") }}
          </div>
          <div class="ml-auto mb-2" align="right">
            <div class="geospatial-label pr-2 d-inline">
              {{ $t("label_format") }}&nbsp;
            </div>
            <select style="width: 200px" v-model="format">
              <option value="deg">{{ $t("label_lat_lng_degrees") }}</option>
              <option value="min">{{ $t("label_lat_lng_minutes") }}</option>
            </select>
          </div>
          <div
            class="w-100"
            :style="{
              maxHeight: propContentHeight - 2 * 16 - 134 + 'px',
              overflowY: 'auto',
            }"
          >
            <p
              class="m-0 coor-item"
              v-for="(coor, index) in coordinates4Show"
              :key="index"
            >
              <label class="grid grid-cols-[1fr] cursor-pointer">
                <input
                  @mouseover="mouseoverPoint(index)"
                  type="text"
                  readonly
                  class="form-control new-style point-values"
                  :value="coor"
                />
                <span>Coordinate {{ index + 1 }}</span>
                <span
                  class="icon-remove-point"
                  title="Remove Point"
                  @click="removePoint(index)"
                  ><i class="fas fa-times"></i
                ></span>
              </label>
            </p>
          </div>
        </div>
        <div class="">
          <div class="geospatial-label font-weight-bold">
            {{ $t("label_perimeter") }}
          </div>
          <div class="geospatial-label">{{ perimeterFeature }}</div>
        </div>
        <div class="">
          <div class="geospatial-label font-weight-bold">
            {{ $t("label_area") }}
          </div>
          <div class="geospatial-label" v-html="areaFeature"></div>
        </div>
      </div>
    </div>
    <div>
      <TaskingMap
        ref="taskingMap"
        :prop-content-height="propContentHeight"
        :prop-feature="propFeature"
        @initMap="initMapSuccess"
        @drawEnd="drawEnd"
        @modifyEnd="modifyEnd"
      />
    </div>
  </div>
</template>
<script>
import TaskingMap from "./coordinates/Map";
var formatLength = function (line) {
  var length = getLength(line);
  var output;
  if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + " " + "km";
  } else {
    output = Math.round(length * 100) / 100 + " " + "m";
  }
  return output;
};
var formatArea = function (polygon) {
  var area = getArea(polygon);
  var output;
  if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + " " + "km<sup>2</sup>";
  } else {
    output = Math.round(area * 100) / 100 + " " + "m<sup>2</sup>";
  }
  return output;
};
import { getArea, getLength } from "ol/sphere.js";
import { toStringHDMS } from "ol/coordinate";
import { transform } from "ol/proj";
import { Circle as CircleStyle, Stroke, Style, Fill } from "ol/style";
import { easeOut } from "ol/easing";
import { getVectorContext } from "ol/render";
import { unByKey } from "ol/Observable";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
export default {
  components: {
    TaskingMap,
  },
  props: {
    propContentHeight: {
      type: Number,
    },
    propFeature: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      map: Object,
      layer: Object,
      coordinates: [],
      format: "deg",
      areaFeature: 0,
      perimeterFeature: 0,
      feature: null,
    };
  },
  mounted() {},
  watch: {
    propFeature(val) {
      if (val && Object.keys(val).length > 0) {
        if (this.layer && Object.keys(this.layer).length > 0) {
          this.layer.getSource().clear();
          this.layer.getSource().addFeature(val);

          this.coordinates = [];
          let coors = val.getGeometry().getCoordinates();
          if (coors && coors.length > 0) {
            for (let i = 0; i < coors[0].length; i++) {
              if (
                i === coors[0].length - 1 &&
                coors[0][0][0] === coors[0][coors[0].length - 1][0] &&
                coors[0][0][1] === coors[0][coors[0].length - 1][1]
              )
                break;
              this.coordinates.push(
                transform(coors[0][i], "EPSG:3857", "EPSG:4326")
              );
            }
          }
          this.areaFeature = formatArea(val.getGeometry());
          this.perimeterFeature = formatLength(val.getGeometry());
          this.$emit("updateEntity", {
            field: "area",
            value: this.areaFeature,
          });
          this.$emit("updateEntity", {
            field: "perimeter",
            value: this.perimeterFeature,
          });
        }
      }
    },
  },
  methods: {
    removePoint(index) {
      if (index <= this.coordinates.length - 1) {
        if (this.coordinates.length <= 3) {
          this.$emit("message", ["The polygon must have at least 3 vertices"]);
          return;
        }
        this.coordinates.splice(index, 1);
        let points = [];
        for (let i = 0; i < this.coordinates.length; i++) {
          points.push(transform(this.coordinates[i], "EPSG:4326", "EPSG:3857"));
        }
        let feature = this.layer.getSource().getFeatureById("featureTasking");
        if (feature && Object.keys(feature).length > 0) {
          feature.setGeometry(new Polygon([points]));
          this.modifyEnd([feature]);
        }
      }
    },
    mouseoverPoint(index) {
      if (index <= this.coordinates.length - 1) {
        let feature = this.layer.getSource().getFeatureById("flash" + index);
        if (!feature) {
          let coordinate = this.coordinates[index];
          let point = new Point(
            transform(coordinate, "EPSG:4326", "EPSG:3857")
          );
          feature = new Feature({
            geometry: point,
          });
          feature.setStyle(
            new Style({
              fill: new Fill({
                color: "transparent",
              }),
              stroke: new Stroke({
                color: "transparent",
                width: 1.25,
              }),
            })
          );
          feature.id = "flash" + index;
          feature.id_ = "flash" + index;
          this.layer.getSource().addFeature(feature);
        }

        this.flash(feature);
        try {
          setTimeout(() => {
            this.layer.getSource().removeFeature(feature);
          }, 4000);
        } catch (error) {}
      }
    },
    initMapSuccess(data) {
      this.map = data.map;
      this.layer = data.layer;
      if (this.propFeature && Object.keys(this.propFeature).length > 0) {
        if (this.layer && Object.keys(this.layer).length > 0) {
          this.layer.getSource().clear();
          this.layer.getSource().addFeature(this.propFeature);

          this.coordinates = [];
          let coors = this.propFeature.getGeometry().getCoordinates();
          if (coors && coors.length > 0) {
            for (let i = 0; i < coors[0].length; i++) {
              if (
                i === coors[0].length - 1 &&
                coors[0][0][0] === coors[0][coors[0].length - 1][0] &&
                coors[0][0][1] === coors[0][coors[0].length - 1][1]
              )
                break;
              this.coordinates.push(
                transform(coors[0][i], "EPSG:3857", "EPSG:4326")
              );
            }
          }
          this.areaFeature = formatArea(this.propFeature.getGeometry());
          this.perimeterFeature = formatLength(this.propFeature.getGeometry());
          this.$emit("updateEntity", {
            field: "area",
            value: this.areaFeature,
          });
          this.$emit("updateEntity", {
            field: "perimeter",
            value: this.perimeterFeature,
          });
          setTimeout(() => {
            if (this.$refs.taskingMap)
              this.$refs.taskingMap.modifyFeature("featureTasking");
          }, 20);
        }
      }
    },
    refrestMap() {
      if (this.$refs.taskingMap) this.$refs.taskingMap.refrestMap();
    },
    drawEnd(feature) {
      this.$emit("updateEntity", {
        field: "feature",
        value: feature,
      });
      this.coordinates = [];
      let coors = feature.getGeometry().getCoordinates();
      if (coors && coors.length > 0) {
        for (let i = 0; i < coors[0].length; i++) {
          if (
            i === coors[0].length - 1 &&
            coors[0][0][0] === coors[0][coors[0].length - 1][0] &&
            coors[0][0][1] === coors[0][coors[0].length - 1][1]
          )
            break;
          this.coordinates.push(
            transform(coors[0][i], "EPSG:3857", "EPSG:4326")
          );
        }
      }
      this.areaFeature = formatArea(feature.getGeometry());
      this.perimeterFeature = formatLength(feature.getGeometry());
      this.$emit("updateEntity", {
        field: "area",
        value: this.areaFeature,
      });
      this.$emit("updateEntity", {
        field: "perimeter",
        value: this.perimeterFeature,
      });
    },
    modifyEnd(features) {
      if (features && features.length > 0) {
        this.$emit("updateEntity", {
          field: "feature",
          value: features[0],
        });
        this.coordinates = [];
        let coors = features[0].getGeometry().getCoordinates();
        if (coors && coors.length > 0) {
          for (let i = 0; i < coors[0].length; i++) {
            if (
              i === coors[0].length - 1 &&
              coors[0][0][0] === coors[0][coors[0].length - 1][0] &&
              coors[0][0][1] === coors[0][coors[0].length - 1][1]
            )
              break;
            this.coordinates.push(
              transform(coors[0][i], "EPSG:3857", "EPSG:4326")
            );
          }
        }
        this.areaFeature = formatArea(features[0].getGeometry());
        this.perimeterFeature = formatLength(features[0].getGeometry());
        this.$emit("updateEntity", {
          field: "area",
          value: this.areaFeature,
        });
        this.$emit("updateEntity", {
          field: "perimeter",
          value: this.perimeterFeature,
        });
        this.$emit("clearAttempts");
        this.$emit("clearPrice");
      }
    },
    flash(feature) {
      const start = Date.now();
      const flashGeom = feature.getGeometry().clone();
      const listenerKey = this.layer.on("postrender", animate);
      var vm = this;
      function animate(event) {
        const frameState = event.frameState;
        const elapsed = frameState.time - start;
        if (elapsed >= 3000) {
          unByKey(listenerKey);
          return;
        }
        const vectorContext = getVectorContext(event);
        const elapsedRatio = elapsed / 3000;
        // radius will be 5 at start and 30 at end.
        const radius = easeOut(elapsedRatio) * 25 + 5;
        const opacity = easeOut(1 - elapsedRatio);

        const style = new Style({
          image: new CircleStyle({
            radius: radius,
            stroke: new Stroke({
              color: "rgba(255, 191, 35, " + opacity + ")",
              width: 0.25 + opacity,
            }),
          }),
        });

        vectorContext.setStyle(style);
        vectorContext.drawGeometry(flashGeom);
        // tell OpenLayers to continue postrender animation
        vm.map.render();
      }
    },
  },
  computed: {
    coordinates4Show() {
      let result = [];
      if (this.coordinates && this.coordinates.length) {
        for (let i = 0; i < this.coordinates.length; i++) {
          switch (this.format) {
            case "deg":
              result.push(this.coordinates[i]);
              break;
            case "min":
              result.push(toStringHDMS(this.coordinates[i]));
              break;
            default:
              result.push(this.coordinates[i]);
              break;
          }
        }
      }
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
.tasking-coordinates {
  padding: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: [first] 28rem [line2] auto [end];
  .ml-auto {
    margin-left: auto;
    margin-right: 0;
  }
  .coor-item {
    position: relative;
    label {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      &:hover {
        cursor: pointer;
      }
      input {
        width: 100%;
        order: 1;
        outline: none;
        box-shadow: none;
        &:hover {
          cursor: pointer;
          border: 1px solid #ffbf23 !important;
          border-color: #ffbf23;
        }
      }
      span {
        order: none;
        font-size: 0.8125rem;
        &.icon-remove-point {
          position: absolute;
          right: 0.5rem;
          top: 1.875rem;
          font-size: 10px;
          &:hover {
            color: #c00;
          }
        }
      }
    }
  }
}
</style>
