<template>
  <StepProgress
    :steps="steps"
    :current-step="currentStep"
    :icon-class="'fas fa-check'"
    :activeColor="activeColor"
    :passiveColor="passiveColor"
    :activeThickness="activeThickness"
    :passiveThickness="passiveThickness"
    :lineThickness="lineThickness"
  />
</template>
<script>
import StepProgress from "vue-step-progress";
export default {
  components: {
    StepProgress,
  },
  props: {
    steps: {
      type: Array,
      default() {
        return [];
      },
      validator(val) {
        return val && val.length > 0;
      },
    },
    currentStep: {
      type: Number,
      default: 0,
    },
    iconClass: {
      type: String,
      default: "fas fa-check",
    },
    activeColor: {
      type: String,
      default: "#009EF7",
    },
    activeTextColor: {
      type: String,
      default: "#009EF7",
    },
    passiveColor: {
      type: String,
      default: "#B3C3D4",
    },
    activeThickness: {
      type: Number,
      default: 4,
    },
    passiveThickness: {
      type: Number,
      default: 4,
    },
    lineThickness: {
      type: Number,
      default: 4,
    },
  },
};
</script>
<style lang="scss" >
.step-progress__wrapper {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.step-progress__wrapper-before {
  background-color: grey;
  transform: translateY(-50%) perspective(1000px);
}

.step-progress__wrapper-after,
.step-progress__wrapper-before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 12px;
  width: 100%;
}

.step-progress__wrapper-after {
  background-color: red;
  transform: scaleX(0) translateY(-50%) perspective(1000px);
  transform-origin: left center;
  transition: transform 0.5s ease;
}

.step-progress__bar {
  width: 100%;
  display: flex;
  height: 4rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.step-progress__step {
  z-index: 2;
  position: relative;
  --activeColor: red;
  --activeTextColor: #1e1e2d;
  --passiveColor: grey;
  --activeBorder: 5px;
  --passiveBorder: 5px;
}

.step-progress__step span {
  color: var(--passiveColor);
  transition: 0.3s ease;
  display: block;
  font-size: 14px;
  transform: translateZ(0) scale(1) perspective(1000px);
  text-align: center;
  opacity: 1;
}

@media (max-width: 767px) {
  .step-progress__step span {
    font-size: 28px;
  }
}

.step-progress__step--active .step-progress__step-label {
  color: var(--activeTextColor);
  font-style: normal;
  font-weight: 500;
  text-align: center;
}
.step-progress__step--active span {
  color: var(--activeColor);
}

.step-progress__step--active .step-progress__step-icon {
  opacity: 1;
}

.step-progress__step--valid .step-progress__step-icon {
  opacity: 1;
  transform: translate3d(-50%, -50%, 0) scale(1) perspective(1000px);
}

.step-progress__step--valid span {
  color: var(--activeColor);
  opacity: 0;
  transform: translateZ(0) scale(2) perspective(1000px);
}

.step-progress__step--valid .step-progress__step-label {
  color: var(--activeTextColor);
}

.step-progress__step:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) perspective(1000px);
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  border: var(--passiveBorder) solid var(--passiveColor);
  transition: 0.3s ease;
}

@media (max-width: 767px) {
  .step-progress__step:after {
    width: 40px;
    height: 40px;
  }
}

.step-progress__step--active:after,
.step-progress__step--valid:after {
  border: var(--activeBorder) solid var(--activeColor);
}

.step-progress__step--valid:after {
  background-color: var(--activeColor);
}

.step-progress__step-label {
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 50%;
  transform: translateX(-50%) perspective(1000px);
  white-space: nowrap;
  font-size: 14px;
  color: var(--passiveColor);
  transition: 0.3s ease;
}

.step-progress__step-icon {
  font-size: 16px;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: transform 0.3s ease;
  opacity: 0;
  transform: translate3d(-50%, -50%, 0) scale(0) perspective(1000px);
}

@media (max-width: 767px) {
  .step-progress__step-icon {
    font-size: 22px;
  }
}
</style>