<template>
  <div class="oneatlas-tab">
    <div class="tab-left left-content">
      <div class="oneatlas-steps">
        <StepProgress
          :steps="steps"
          :current-step="step"
          :activeColor="'#009EF7'"
          :activeTextColor="'#1E1E2D'"
          :passiveColor="'#B3C3D4'"
          :iconClass="'fas fa-check'"
        />
      </div>
      <div
        class="oneatlas-components p-4"
        :style="{ height: contentHeight + 'px' }"
      >
        <div class="component-order-name">
          <div class="form-group" v-show="step === 0">
            <label for="txt-cart-description" class="control-label pl-1">
              Order name
            </label>
            <Textarea
              id="txt-cart-description"
              class="form-control"
              rows="2"
              maxlength="512"
              autofocus
              :autoResize="true"
              v-model="cart.name"
              @change="orderNameChanged"
            >
            </Textarea>
            <small class="w-100 text-right float-right mt-1"
              >{{
                cart.name && cart.name.trim() ? cart.name.trim().length : 0
              }}/512</small
            >
          </div>
        </div>
        <div class="component-confirm" v-show="step === 1">
          <div class="form-group">
            <label>Product type</label>
            <Dropdown
              class="w-100 new-style"
              :options="productTypes"
              v-model="cart.productType"
              :showClear="false"
              optionValue="id"
              optionLabel="label"
            >
              <template #option="slotProps">
                <div>
                  <p class="mb-0">{{ slotProps.option.label }}</p>
                  <small style="word-break: break-all; white-space: normal">{{
                    slotProps.option.description
                  }}</small>
                </div>
              </template>
            </Dropdown>
          </div>

          <div class="form-group">
            <label>Radiometric Processing</label>
            <Dropdown
              class="w-100 new-style"
              :options="geometricProcessing"
              v-model="cart.radiometricProcessing"
              :showClear="false"
              optionValue="id"
              optionLabel="label"
              ><template #option="slotProps">
                <div>
                  <p class="mb-0">{{ slotProps.option.label }}</p>
                  <small style="word-break: break-all; white-space: normal">{{
                    slotProps.option.description
                  }}</small>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="form-group">
            <label>Projection</label>
            <Dropdown
              class="w-100 new-style"
              :options="projections"
              v-model="cart.crsCode"
              :showClear="false"
              optionValue="id"
              optionLabel="label"
              ><template #option="slotProps">
                <div>
                  <p class="mb-0">{{ slotProps.option.label }}</p>
                  <small style="word-break: break-all; white-space: normal">{{
                    slotProps.option.description
                  }}</small>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="form-group">
            <label>Image Format</label>
            <Dropdown
              class="w-100 new-style"
              :options="imageFormats"
              v-model="cart.imageFormat"
              :showClear="false"
              optionValue="id"
              optionLabel="label"
            >
              <template #option="slotProps">
                <div>
                  <p class="mb-0">{{ slotProps.option.label }}</p>
                  <small style="word-break: break-all; white-space: normal">{{
                    slotProps.option.description
                  }}</small>
                </div>
              </template>
            </Dropdown>
          </div>
        </div>
      </div>

      <div
        class="oneatlas-actions d-flex justify-content-between align-items-center px-4 py-2"
      >
        <Button
          v-if="step >= 1"
          @click="prevStep"
          :label="'Back'"
          icon="fas fa-arrow-left"
          class="p-button-secondary p-button-text button-prev"
          :disabled="buttons.prev.disable"
        />
        <Button
          v-if="step < 1"
          @click="nextStep"
          :label="'Continue'"
          :class="'button-next'"
          :disabled="buttons.next.disable"
        />
      </div>
    </div>

    <div class="tab-right">
      <h6 class="summary-title">Order Summary - OneAtlas</h6>
      <div class="summary-order">
        <div class="form-group">
          <label>Order name</label>
          <h6
            class="summary-order-name"
            :class="cart.name ? 'font-weight-bold' : 'text-muted'"
            :title="cart.name ? (cart.name.length > 26 ? cart.name : '') : ''"
          >
            {{
              cart.name
                ? cart.name.length > 26
                  ? cart.name.substring(0, 26) + "..."
                  : cart.name
                : "--"
            }}
          </h6>
        </div>
        <div class="form-group">
          <label>Orders to be placed</label>
          <h6 class="summary-order-name font-weight-bold">1</h6>
        </div>
        <div>
          Price:&nbsp;
          <i class="fas fa-spinner fa-spin" v-if="cart.price.loading"></i>
          <span class="listed-price" v-else
            ><span v-if="cart.price && cart.price.price"
              ><strong class="text-success">{{
                cart.price.price.amount | numberFormat
              }}</strong
              ><em
                v-html="
                  getCurrencyCode(
                    cart.price && cart.price.price
                      ? cart.price.price.amountUnit
                      : null
                  )
                "
              ></em></span
          ></span>
        </div>
      </div>
      <ul
        class="order-summary-list"
        :style="{ height: contentHeight - 101 + 'px' }"
      >
        <li class="summary-item" v-for="p in arraySatelliteName" :key="p.name">
          <div class="item-header">
            <svg
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-do1tfz"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="SatelliteIcon"
            >
              <path
                fill-rule="evenodd"
                d="m17.714 2.3597 4.0406 4.0406c.4463.4463.4463 1.17 0 1.6162l-4.0405 4.0404.808.8084c.4463.4463.4463 1.1699 0 1.6162l-4.0406 4.0406c-.4463.4463-1.17.4463-1.6162 0l-.8084-.808-4.0404 4.0405c-.4463.4463-1.1699.4463-1.6162 0L2.3597 17.714c-.4463-.4463-.4463-1.17 0-1.6162l4.0401-4.0409-.8076-.8079c-.4463-.4463-.4463-1.17 0-1.6162l4.0406-4.0406c.4463-.4463 1.17-.4463 1.6162 0l.808.8076 4.0408-4.0401c.4463-.4463 1.1699-.4463 1.6162 0ZM4.7838 15.2889l-1.616 1.617 4.0406 4.0406L8.8238 19.33l-4.04-4.0412Zm2.4229-2.4251-1.6149 1.6171 4.04 4.0412 1.616-1.6172-4.0411-4.0411Zm3.2342-6.4635-4.0406 4.0406 7.273 7.2731 4.0407-4.0406-7.2731-7.2731Zm4.04-.8085-1.6171 1.6149 4.0411 4.0411 1.6172-1.616-4.0412-4.04Zm2.425-2.424-1.617 1.616 4.0412 4.04 1.6164-1.6154-4.0406-4.0406Z"
              ></path>
            </svg>
            <span> {{ p.value }}</span>
          </div>
          <div class="item-card" @click="showDialogPriceDetail(p)">
            <div class="px-2 py-3">
              <div class="item-count">
                <svg
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="BurstModeOutlinedIcon"
                >
                  <path
                    d="M1 5h2v14H1zm4 0h2v14H5zm17 0H10c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1zm-1 12H11V7h10v10zm-3.57-4.38-2 2.57L14 13.47l-2 2.52h8z"
                  ></path></svg
                ><span class="pl-1 pt-1">&nbsp; {{ p.count }} items</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="summary-actions" v-if="step > 0 || cart.description">
        <Button
          label="Order"
          class="button-confirm"
          @click="onOrderOneAtlas"
          :icon="
            propButton && propButton.processing ? 'fas fa-spinner fa-spin' : ''
          "
          :disabled="
            propButton && propButton.disable ? propButton.disable : false
          "
        >
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import StepProgress from "@/components/commons/steps/StepProgress";
import Textarea from "@/components/commons/textarea/Textarea";
import Button from "@/components/commons/button/Button";
import RadioButton from "@/components/commons/radiobutton/RadioButton";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import oneAtlasFunc from "@/utils/functions/oneatlas";
import cryptoFunc from "@/utils/functions/crypto";
export default {
  components: {
    StepProgress,
    Textarea,
    Button,
    RadioButton,
    Dropdown,
  },
  props: {
    propCartName: {
      type: String,
      default: null,
    },
    propImages: {
      type: Array,
      default: () => [],
    },
    propButton: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      steps: ["Order name", "Production Options"],
      step: 0,
      buttons: {
        next: {
          processing: false,
          disable: false,
          icon: null,
        },
        prev: {
          processing: false,
          disable: false,
          icon: null,
        },
        create: {
          processing: false,
          disable: false,
          icon: null,
        },
      },
      cart: {
        crsCode: "urn:ogc:def:crs:EPSG::4326",
        productType: "pansharpened",
        radiometricProcessing: "DISPLAY",
        aoi: {
          type: "Polygon",
          coordinates: [],
        },
        id: null,
        imageFormat: "image/jp2",
        name: null,
        price: {
          loading: false,
        },
      },
      satellites: [],
      arraySatelliteName: [],
      productTypes: [
        {
          id: "bundle",
          label: "Bundle",
          description:
            "One Panchromatic image + the Multispectral (Blue, Green, Red and Near Infrared) bands delivered separately",
        },
        {
          id: "pansharpened",
          label: "Pansharpened",
          description:
            "Merging the Panchromatic with the full set of colour Blue, Green, Red and Near Infrared bands",
        },
      ],
      imageFormats: [
        {
          id: "image/jp2",
          label: "JPEG 2000",
          description: "The format of the delivered image will be JPEG 2000",
        },
        {
          id: "image/geotiff",
          label: "Geotiff",
          description:
            "The format of the delivered image will be Cloud Optimized Geotiff",
        },
      ],

      geometricProcessing: [
        {
          id: "REFLECTANCE",
          label: "Reflectance",
          description:
            "Sensor calibration and systematic atmospheric effects are effectivley used to radiometrically correct the image",
        },
        {
          id: "DISPLAY",
          label: "Display",
          description:
            "Former OVR (Optimized Visual Rendering). 8-bit pixel coding is requested which appears lighter",
        },
      ],
      projections: [
        {
          id: "urn:ogc:def:crs:EPSG::4326",
          label: "EPSG:4326",
          description:
            "Geodetic coordinate system for World using Uses simple Equirectangular projection",
        },
        {
          id: "urn:ogc:def:crs:EPSG::326",
          label: "EPSG:326[01-60]",
          description: "WGS 84 north zones",
        },
        {
          id: "urn:ogc:def:crs:EPSG::327",
          label: "EPSG:327[01-60]",
          description: "WGS 84 south zones",
        },
      ],
    };
  },
  async created() {
    this.satellites = this.propImages.map((x) => x.properties.platform);
    for (let i = 0; i < this.satellites.length; i++) {
      let index = this.arraySatelliteName.findIndex(
        (x) => x.name === this.satellites[i]
      );
      if (index >= 0) {
        this.arraySatelliteName[index].count++;
      } else {
        this.arraySatelliteName.push({
          name: this.satellites[i],
          value: this.satellites[i],
          count: 1,
        });
      }
    }
    //await this.calculatePrice();
  },
  mounted() {},
  watch: {
    propCartName(val) {
      this.cart.name = val;
    },
  },
  methods: {
    orderNameChanged() {
      this.$emit("orderNameChanged", this.cart.name);
    },
    async nextStep() {
      this.step++;
    },
    prevStep() {
      this.step--;
    },
    showDialogPriceDetail(data) {
      console.log(data);
      let items = this.propImages.filter(
        (x) => x.properties.platform === data.value
      );
      if (
        this.cart.price &&
        this.cart.price.price &&
        this.cart.price.price.deliveries
      ) {
        for (let i = 0; i < items.length; i++) {
          items[i].price = this.cart.price.price.deliveries.find(
            (x) => x.id === items[i].properties.id
          );
        }
      }
      this.$emit("showDialogPriceDetail", items);
    },
    clearCart() {
      this.cart = {
        crsCode: "urn:ogc:def:crs:EPSG::4326",
        productType: "pansharpened",
        radiometricProcessing: "DISPLAY",
        aoi: {
          type: "Polygon",
          coordinates: [],
        },
        id: null,
        imageFormat: "image/jp2",
        name: null,
      };
    },
    onOrderOneAtlas() {
      let message = [];
      if (!this.cart.name || !this.cart.name.trim()) {
        message.push("Order Name is required.");
      }
      if (message.length > 0) {
        this.$emit("message", message);
        return;
      }
      this.$emit("onOrderOneAtlas", this.cart);
    },
    getCurrencyCode(txt) {
      let result = "&euro;";
      switch (txt) {
        case "EUR":
          result = "&euro;";
          break;
        case "DOLLAR":
          result = "&#36;";
          break;
        case "POUND":
          result = "&#163;";
          break;
        case "YEN":
          result = "&#165;";
          break;
        case "CENT":
          result = "&#162;";
          break;
        case "RUPEE":
          result = "&#8360;";
          break;
        case "DONG":
          result = "&#8363;";
          break;
        default:
          result = txt;
          break;
      }
      return result;
    },
    async calculatePrice() {
      this.buttons.create.disable = true;
      this.buttons.create.processing = false;
      this.buttons.create.icon = null;
      this.buttons.next.disable = true;
      this.buttons.next.processing = false;
      this.buttons.next.icon = null;
      this.buttons.prev.disable = true;
      this.buttons.prev.processing = false;
      this.buttons.prev.icon = null;
      this.cart.price.loading = true;
      let payload = {
        kind: "order.data.gb.product",
        products: [],
      };
      if (this.propImages && this.propImages.length) {
        for (let i = 0; i < this.propImages.length; i++) {
          let obj = {
            crsCode: this.cart.crsCode,
            productType: this.cart.productType,
            radiometricProcessing: this.cart.radiometricProcessing,
            aoi: {},
            id: this.propImages[i].properties.id,
            imageFormat: this.cart.imageFormat,
          };
          if (this.coordinates && this.coordinates.length > 0) {
            obj.aoi = {
              type: "Polygon",
              coordinates: this.coordinates,
            };
          } else {
            if (
              this.propImages[i].geometry &&
              Object.keys(this.propImages[i].geometry).length
            )
              obj.aoi = this.propImages[i].geometry;
          }
          payload.products.push(obj);
        }
      } else {
        this.cart.price.loading = false;
        this.buttons.create.disable = false;
        this.buttons.create.processing = false;
        this.buttons.create.icon = null;
        this.buttons.next.disable = false;
        this.buttons.next.processing = false;
        this.buttons.next.icon = null;
        this.buttons.prev.disable = false;
        this.buttons.prev.processing = false;
        this.buttons.prev.icon = null;
        return;
      }
      console.log(payload);
      //return;
      try {
        let response = await oneAtlasFunc.calculatePrice(
          payload,
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );
        if (response && response.status === 200) {
          this.cart.price = response.data && response.data ? response.data : {};
        }
      } catch (error) {
        this.cart.price.loading = false;
        this.buttons.create.disable = false;
        this.buttons.create.processing = false;
        this.buttons.create.icon = null;
        this.buttons.next.disable = false;
        this.buttons.next.processing = false;
        this.buttons.next.icon = null;
        this.buttons.prev.disable = false;
        this.buttons.prev.processing = false;
        this.buttons.prev.icon = null;
        if (error && error.response && error.response.status === 401) {
          this.$store.dispatch("clearOneAtlasToken");
          let r = await this.getOneAtlasTokenData();
          if (r) {
            this.calculatePrice();
          }
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.$emit("message", [error.response.data.message]);
        }
      }
      this.cart.price.loading = false;
      this.buttons.create.disable = false;
      this.buttons.create.processing = false;
      this.buttons.create.icon = null;
      this.buttons.next.disable = false;
      this.buttons.next.processing = false;
      this.buttons.next.icon = null;
      this.buttons.prev.disable = false;
      this.buttons.prev.processing = false;
      this.buttons.prev.icon = null;
    },
    async getOneAtlasTokenData() {
      var vm = this;
      return new Promise(async function (resolve, reject) {
        try {
          let res = await oneAtlasFunc.getAccessToken();
          if (res && res.status === 200) {
            res.data.expires_time =
              new Date().getTime() +
              (res.data.expires_in ? res.data.expires_in * 1000 : 0);

            await localStorage.setItem(
              "oneatlas-token",
              btoa(cryptoFunc.encrypt(JSON.stringify(res.data)).toString())
            );
            await vm.$store.dispatch("setOneAtlasToken", res.data);
            resolve(1);
          } else {
            return resolve(null);
          }
        } catch (err) {
          console.log(err);
          resolve(null);
        }
      });
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    contentHeight() {
      return (this.documentHeight * 50) / 100;
    },
    criteria() {
      return this.$store.getters.getSearchDto;
    },
    coordinates() {
      if (this.criteria && Object.keys(this.criteria).length > 0) {
        try {
          let obj = JSON.parse(this.criteria.boundary);
          if (obj && Object.keys(obj).length > 0) {
            if (obj.coordinates) {
              return obj.coordinates;
            }
          }
        } catch (error) {}
      }
      return [];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.oneatlas-tab {
  display: grid;
  grid-template-columns: [first] auto [line2] 272px [end];
  .tab-left {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 100%;
    align-items: stretch;
    align-content: stretch;
    .oneatlas-steps {
      padding-left: 5rem;
      padding-right: 5rem;
      &.shadow-b {
        box-shadow: rgba(33, 35, 38, 0.2) 0px 10px 10px -10px;
      }
    }
    .oneatlas-components {
      overflow: auto;
      .component-select-assets {
        .assets-box {
          .border-t {
            border-top-width: 1px;
            border-top-style: solid;
            border-color: rgb(189, 197, 198);
            &:first-child {
              border-top: none;
            }
          }
          .title-box {
            font-size: 20px;
            font-weight: 700;
            &::after {
              content: "";
              display: block;
              width: 100%;
              margin-top: 0.25rem;
            }
          }
        }
      }
    }
    .oneatlas-actions {
      width: 100%;
      background-color: rgba($color: #000000, $alpha: 0.005);
      border-radius: 0.25rem;
      .button-prev {
        outline: none;
        box-shadow: none;
        &:focus {
          outline: none;
          box-shadow: none;
        }
        &:active {
          outline: none;
          box-shadow: none;
        }
      }
      .button-next {
        margin-left: auto;
        background-color: $background-color-2;
        border-color: $background-color-2;
      }
    }
  }
  .tab-right {
    background-color: rgba($color: $background-color-2, $alpha: 0.05);
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    .summary-title {
      font-size: 16px;
      font-weight: 700;
      padding: 0.5rem 1rem;
      border-bottom: 1px solid rgba(189, 197, 198, 0.5);
    }
    .summary-order {
      padding: 0.5rem 1rem 1rem;
      border-bottom: 1px solid rgba(189, 197, 198, 0.5);
      display: block;
      .form-group {
        margin-bottom: 0.5rem;
        h6 {
          margin: 0;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
    ul.order-summary-list {
      list-style: none;
      margin: 0;
      padding: 0;
      overflow: auto;
      height: 100%;
      position: relative;
      li.summary-item {
        padding: 0.75rem;
        border-bottom: 1px solid #eff2f5;
        &:last-child {
          border: 0;
        }
        .item-header {
          display: flex;
          box-sizing: border-box;
          list-style: none;
          font-weight: 500;
          font-size: 1rem;
          margin-bottom: 0.25rem;
          background-color: transparent;
          svg {
            user-select: none;
            width: 1em;
            height: 1em;
            display: inline-block;
            fill: currentcolor;
            flex-shrink: 0;
            transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            font-size: 1.5rem;
            margin-right: 4px;
          }
        }
        .item-card {
          width: 100%;
          color: rgb(0, 31, 35);
          transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          border-radius: 4px;
          border: 1px solid rgb(189, 197, 198);
          overflow: hidden;
          background-color: white;
          flex-shrink: 0;
          &:hover {
            cursor: pointer;
          }
          .item-count {
            max-width: 100%;
            font-family: Inter, sans-serif;
            font-size: 0.8125rem;
            display: inline-flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            height: 24px;
            color: rgb(0, 31, 35);
            white-space: nowrap;
            transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            cursor: default;
            outline: 0px;
            text-decoration: none;
            padding: 0px;
            vertical-align: middle;
            box-sizing: border-box;
            background-color: transparent;
            border-radius: 4px;
            border: none;
            svg {
              user-select: none;
              width: 1em;
              height: 1em;
              display: inline-block;
              fill: currentcolor;
              flex-shrink: 0;
              transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
              font-size: 1.5rem;
            }
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
    .summary-actions {
      padding: 0.5rem;
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      box-shadow: none;
      display: flex;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;
      flex-shrink: 0;
      position: static;
      background-color: transparent;
      color: inherit;
      background-image: unset;
      border-top: 1px solid rgba(189, 197, 198, 0.5);
      .button-confirm {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        outline: 0px;
        margin: 0px;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        font-size: 0.875rem;
        font-weight: 700;
        text-transform: none;
        line-height: 1.75;
        min-width: 64px;
        padding: 5px 10px;
        border-radius: 4px;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border: 1px solid $background-color-2;
        color: $background-color-2;
        width: 100%;
        box-shadow: none;
        background-color: transparent;
        &:hover {
          background-color: rgba($color: $background-color-2, $alpha: 0.125);
        }
      }
    }
  }
}
</style>
