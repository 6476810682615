<template>
  <Dialog
    :header="propTitle"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :maximizable="propItem.quicklook ? true : false"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
    @maximizedChage="maximizedChage"
  >
    <div class="table-responsive">
      <table class="table">
        <tbody>
          <tr>
            <td>
              <CommonAccordion
                :propTitle="$t('label_footprint')"
                :isChevronEnd="true"
              >
                <div slot="content">
                  <div class="HuLJs83D">
                    <MapOpenlayer :prop-wkt="propItem.wkt" />
                  </div>
                </div>
              </CommonAccordion>
            </td>
            <td>
              <CommonAccordion
                :propTitle="$t('label_quicklook')"
                :isChevronEnd="true"
              >
                <div slot="content" class="quicklook">
                  <quick-look
                    v-if="propItem.quicklook && propItem.icon_src"
                    :prop-data="{
                      img: propItem.icon_src,
                    }"
                  ></quick-look>
                  <div class="quicklook-img" v-else>
                    <img
                      width="100%"
                      src="/img/icons/no-image.png"
                      title="No Image"
                    />
                  </div>
                </div>
              </CommonAccordion>
            </td>
          </tr>
          <tr>
            <td>
              <CommonAccordion :propTitle="'Summary'" :isChevronEnd="true">
                <div slot="content">
                  <div
                    class="table-responsive"
                    :style="[
                      maximized
                        ? { width: (documentWidth - 68) / 2 + 'px' }
                        : {
                            width:
                              parseFloat(
                                propStyle.width.replace('px', '') - 68
                              ) /
                                2 +
                              'px',
                          },
                    ]"
                  >
                    <table class="table">
                      <tbody>
                        <tr
                          v-for="(item, index) in summary"
                          :key="'summary' + index"
                        >
                          <td>{{ item.name }}:</td>
                          <td>{{ item.value }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </CommonAccordion>
            </td>
            <td>
              <CommonAccordion :propTitle="'Product'" :isChevronEnd="true">
                <div slot="content">
                  <div
                    class="table-responsive"
                    :style="[
                      maximized
                        ? { width: (documentWidth - 68) / 2 + 'px' }
                        : {
                            maxWidth:
                              parseFloat(
                                propStyle.width.replace('px', '') - 68
                              ) /
                                2 +
                              'px',
                          },
                    ]"
                  >
                    <table class="table">
                      <tbody>
                        <tr
                          v-for="(item, index) in summary"
                          :key="'summary' + index"
                        >
                          <td>{{ item.name }}:</td>
                          <td>{{ item.value }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </CommonAccordion>
            </td>
          </tr>
          <tr>
            <td>
              <CommonAccordion :propTitle="'Instrument'" :isChevronEnd="true">
                <div slot="content">
                  <div
                    class="table-responsive"
                    :style="[
                      maximized
                        ? { width: (documentWidth - 68) / 2 + 'px' }
                        : {
                            width:
                              parseFloat(
                                propStyle.width.replace('px', '') - 68
                              ) /
                                2 +
                              'px',
                          },
                    ]"
                  >
                    <table class="table">
                      <tbody>
                        <tr
                          v-for="(item, index) in instrument"
                          :key="'instrument' + index"
                        >
                          <td>{{ item.name }}:</td>
                          <td>{{ item.value }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </CommonAccordion>
            </td>
            <td>
              <CommonAccordion :propTitle="'Platform'" :isChevronEnd="true">
                <div slot="content">
                  <div
                    class="table-responsive"
                    :style="[
                      maximized
                        ? { width: (documentWidth - 68) / 2 + 'px' }
                        : {
                            maxWidth:
                              parseFloat(
                                propStyle.width.replace('px', '') - 68
                              ) /
                                2 +
                              'px',
                          },
                    ]"
                  >
                    <table class="table">
                      <tbody>
                        <tr
                          v-for="(item, index) in platform"
                          :key="'platform' + index"
                        >
                          <td>{{ item.name }}:</td>
                          <td>{{ item.value }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </CommonAccordion>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <template #footer>
      <Button
        :label="$t('button_text_close')"
        :icon="'fas fa-times'"
        :class="'p-button-danger mr-0'"
        @click="onClose"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import CommonAccordion from "@/components/commons/accordion/Accordion";
import MapOpenlayer from "./metadatasentinel/Map";
import QuickLook from "./metadatasentinel/QuickLook";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
export default {
  components: {
    Dialog,
    Button,
    CommonAccordion,
    MapOpenlayer,
    QuickLook,
    DataTable,
    Column,
  },
  props: {
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "480px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      maximized: false,
      imgPreview: {
        loading: true,
        src: null,
      },
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
  },

  methods: {
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    maximizedChage(val) {
      this.maximized = val;
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    summary() {
      if (this.propItem && Object.keys(this.propItem).length > 0) {
        if (
          this.propItem.indexes &&
          Object.keys(this.propItem.indexes).length > 0
        ) {
          let item = this.propItem.indexes.find(
            (x) => x.name.toLowerCase() === "summary"
          );
          if (item && Object.keys(item).length > 0) {
            return item.children ? item.children : [];
          }
        }
      }
      return [];
    },
    product() {
      if (this.propItem && Object.keys(this.propItem).length > 0) {
        if (
          this.propItem.indexes &&
          Object.keys(this.propItem.indexes).length > 0
        ) {
          let item = this.propItem.indexes.find(
            (x) => x.name.toLowerCase() === "product"
          );
          if (item && Object.keys(item).length > 0) {
            return item.children ? item.children : [];
          }
        }
      }
      return [];
    },
    platform() {
      if (this.propItem && Object.keys(this.propItem).length > 0) {
        if (
          this.propItem.indexes &&
          Object.keys(this.propItem.indexes).length > 0
        ) {
          let item = this.propItem.indexes.find(
            (x) => x.name.toLowerCase() === "platform"
          );
          if (item && Object.keys(item).length > 0) {
            return item.children ? item.children : [];
          }
        }
      }
      return [];
    },
    instrument() {
      if (this.propItem && Object.keys(this.propItem).length > 0) {
        if (
          this.propItem.indexes &&
          Object.keys(this.propItem.indexes).length > 0
        ) {
          let item = this.propItem.indexes.find(
            (x) => x.name.toLowerCase() === "instrument"
          );
          if (item && Object.keys(item).length > 0) {
            return item.children ? item.children : [];
          }
        }
      }
      return [];
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  &.table td,
  &.table th {
    border-top: 0;
    padding: 0.25rem;
    .table td {
      padding: 0.25rem 0.5rem;
    }
  }
  .HuLJs83D {
    display: block;
    position: relative;
  }
}
.quicklook-img {
  padding: 2.25rem;
  margin: 0 auto;
  text-align: center;
  img {
    width: 60%;
  }
}
</style>