export const tokenServiceApiURL = process.env.VUE_APP_ORIGIN_URL; //"VUE_APP_ORIGIN_URL"; //*/ process.env.VUE_APP_ORIGIN_URL;
export const geoServiceApiURL = process.env.VUE_APP_GEO_API_URL; //"VUE_APP_GEO_API_URL"; //*/ process.env.VUE_APP_GEO_API_URL;
export const userServiceApiURL = process.env.VUE_APP_API_URL; //"VUE_APP_API_URL"; //*/ process.env.VUE_APP_API_URL;
export const enpointPreviewUrl = process.env.VUE_APP_ENPOINT_PREVIEW; //"VUE_APP_ENPOINT_PREVIEW"; //*/ process.env.VUE_APP_ENPOINT_PREVIEW;
export const recaptchaSiteKey = "6LdXQUQcAAAAAEkUqsrisMde1Ebhb-TUn_mmSq89";
export const spymesatApi = "https://www.spymesat.com/api/v2/satellites/";
export const planetEndPoint = "PLANET_END_POINT"; //*/ "/planet";
export const planetApiKey = "PLAK0bb6e6b69efa4183ac775febab0a8e0f";

//export const capellaUsername = "canhbuivs@vegastar.vn";
//export const capellaPassword = "vega@1234";

export const capellaUsername = "vega.capella@vegastar.com.vn";
export const capellaPassword = "Vega@123456";

export const maxCloudCoverOneAtlasOneDay = 5; // "MAX_CLOUD_COVER_ONEATLAS_ONEDAY";
export const maxIncidenceAngleOneAtlasOneDay = 50; // "MAX_INCIDENCE_ANGLE_ONEATLAS_ONEDAY";
