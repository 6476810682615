<template>
  <div class="delivery-payment">
    <div class="comment">
      <h6>Please provide a name for this order</h6>
      <p>Choose a reference name (such as an internal project number)</p>
      <div class="form-group row m-0">
        <label class="col-sm-1 col-form-label"
          >Name <span class="required">*</span></label
        >
        <div class="col-sm-11">
          <input
            type="text"
            class="form-control"
            v-model="propEntity.name"
            maxlength="100"
          />
        </div>
      </div>
    </div>
    <div class="my-market">
      <h6>Specify your market</h6>
      <div class="row m-0">
        <div class="col-12 col-md-6">
          <label>Primary Market <span class="required">*</span></label>
          <select
            class="form-control"
            v-model="propEntity.primaryMarket"
            @change="primaryMarketChange"
          >
            <option
              v-for="market in propMarkets"
              :key="market.id"
              :value="market.id"
            >
              {{ market.label }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-6">
          <label>Secondary Market</label>
          <select class="form-control" v-model="propEntity.secondaryMarket">
            <option
              v-for="market in secondaryMarkets"
              :key="market.id"
              :value="market.id"
            >
              {{ market.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="payment">
      <div class="row m-0">
        <div class="col-12 col-md-6">
          <h6>Payment</h6>
          <select class="form-control" v-model="propEntity.payment">
            <option v-for="p in payments" :key="p.id" :value="p.id">
              {{ p.label }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-6">
          <h6>Purchase order</h6>
          <span class="plaintext"
            >{{ propContract.name + " (Remaining: " }}
            <em v-html="getCurrencyCode(propContract.currency)"></em>
            {{ propContract.creditRemaining | numberFormat }})</span
          >
        </div>
      </div>
    </div>
    <div class="notifications">
      <h6>Notifications / Add users</h6>
      <div class="row m-0">
        <div
          class="col-12 col-md-6 d-flex gap-2 user-card"
          v-for="(email, index) in propEntity.emails"
          :key="'email' + index"
        >
          <label>Name</label>
          <input type="email" class="form-control" v-model="email.value" />
          <button
            class="btn"
            v-if="index === propEntity.emails.length - 1"
            @click="addNewUser"
          >
            <i class="fas fa-user-plus"></i>&nbsp;Add
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Dropdown from "@/components/commons/dropdown/Dropdown";
export default {
  components: {
    Dropdown,
  },
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },
    propContract: {
      type: Object,
      default: () => {},
    },
    propMarkets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      secondaryMarkets: [],
      payments: [
        {
          id: "purchase_order",
          label: "Purchase Order",
        },
      ],
      accordion: {
        active: true,
      },
    };
  },
  created() {
    if (!this.propMarkets || this.propMarkets.length === 0) {
      this.$emit("getMarkets");
    }
  },
  methods: {
    primaryMarketChange(event) {
      this.secondaryMarkets = [];
      this.propEntity.secondaryMarket = null;
      let market = this.propMarkets.find(
        (x) => x.id === this.propEntity.primaryMarket
      );
      this.secondaryMarkets =
        market && market.secondaryMarket ? market.secondaryMarket : [];
    },
    addNewUser() {
      this.propEntity.emails.push({
        label: "Name",
        value: null,
      });
    },
    getCurrencyCode(txt) {
      let result = "&euro;";
      switch (txt) {
        case "EUR":
          result = "&euro;";
          break;
        case "DOLLAR":
          result = "&#36;";
          break;
        case "POUND":
          result = "&#163;";
          break;
        case "YEN":
          result = "&#165;";
          break;
        case "CENT":
          result = "&#162;";
          break;
        case "RUPEE":
          result = "&#8360;";
          break;
        case "DONG":
          result = "&#8363;";
          break;
        default:
          result = txt;
          break;
      }
      return result;
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.delivery-payment {
  height: auto;
  display: grid;
  grid-auto-columns: 1fr;
  gap: 1rem;
  .comment {
    background-color: #eef3f7;
    border: 1px solid rgba(0, 0, 0, 0.075);
    border-radius: 0.25rem;
    padding: 1rem 1.5rem 1rem 1rem;
    margin: auto 0;
    h6 {
      margin-bottom: 0.5rem;
    }
    p {
      margin-bottom: 0.5rem;
      font-family: "Helvetica Neue Light";
      font-weight: 550;
    }
  }
  .payment {
    background-color: #eef3f7;
    border: 1px solid rgba(0, 0, 0, 0.075);
    border-radius: 0.25rem;
    padding: 1rem 1.5rem 1rem 1rem;
    margin: auto 0;
    .plaintext {
      line-height: 2rem;
    }
  }
  .notifications {
    background-color: #eef3f7;
    border: 1px solid rgba(0, 0, 0, 0.075);
    border-radius: 0.25rem;
    padding: 1rem 1.5rem 1rem 1rem;
    margin: auto 0;
    .user-card {
      display: flex;
      gap: 1rem;
      flex-wrap: nowrap;
      align-self: center;
      justify-content: center;
      label {
        width: 96px;
        margin: 0;
        align-self: center;
        justify-content: center;
      }

      button {
        width: 128px;
        border: 1px solid;
        font-size: 14px;
        background-color: #64748b;
        border: 1px solid #475569;
        color: white;
        align-self: center;
        justify-items: center;
        i {
          font-size: 12px;
        }
      }
    }
  }
  .my-market {
    background-color: #eef3f7;
    border: 1px solid rgba(0, 0, 0, 0.075);
    border-radius: 0.25rem;
    padding: 1rem 1.5rem 1rem 1rem;
    margin: auto 0;
    div[class*="col-"] {
      display: flex;
      gap: 1rem;
      flex-wrap: nowrap;
      align-self: center;
      justify-content: center;
      label {
        width: 176px;
        margin: 0;
        align-self: center;
        justify-content: center;
      }
      .p-dropdown {
        width: calc(100% - 176px);
      }
    }
  }
}
</style>
