<template>
  <div class="datatable">
    <ContextMenu :model="menuModel" ref="cmImagesDatatable" />
    <DataTable
      :value="items.filter((x) => x.is_delete != true)"
      ref="dt"
      :resizableColumns="true"
      columnResizeMode="expand"
      selectionMode="single"
      dataKey="id"
      contextMenu
      :selection.sync="selected"
      @row-contextmenu="onRowContextMenu"
    >
      <template #empty v-if="items && items.length === 0">
        <span class="text-center">{{ $t("label_no_data") }}</span>
      </template>
      <Column
        header="No"
        headerStyle="width: 3rem; text-align: center!important"
        bodyStyle="text-align: center;"
      >
        <template #body="slotProps">
          {{ slotProps.index + 1 }}
        </template></Column
      >

      <Column :header="$t('label_image_name')">
        <template #body="slotProps">
          {{ slotProps.data.name }}
        </template>
      </Column>
      <Column :header="$t('label_image_time')" headerStyle="width: 12rem;">
        <template #body="slotProps">{{
          formatUnixDateTime2String(
            slotProps.data.imaging_date
              ? slotProps.data.imaging_date
              : slotProps.data.metadata
              ? slotProps.data.metadata.imaging_date
              : ""
          )
        }}</template>
      </Column>
      <Column headerStyle="width: 3.25rem; text-align: center!important">
        <template #body="slotProps">
          <Button
            icon="fas fa-list-alt"
            class="p-button p-button-success p-button-sm"
            @click="showDlgMetadata(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
import Button from "@/components/commons/button/Button";
import moment from "moment";
export default {
  components: {
    DataTable,
    Column,
    ContextMenu,
    Button,
  },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propStatus: {
      type: Object,
      default: () => {
        return {
          isAdd: false,
          isEdit: false,
        };
      },
    },
  },
  data() {
    return {
      items: [],
      selected: null,
      menuModel: [
        {
          label: "Metadata",
          icon: "fas fa-list-alt",
          command: () => this.showDlgMetadata(this.selected),
          visible: true,
        },
      ],
    };
  },
  watch: {
    propItems: {
      handler(val) {
        this.initData();
        this.selected = null;
      },
      immediate: true,
    },
  },
  methods: {
    showDlgMetadata(item) {
      this.$emit("showDlgMetadata", item);
    },
    initData() {
      if (this.propItems && this.propItems.length > 0)
        this.items = Object.assign(
          [],
          this.propItems.filter((x) => x.is_delete != true)
        );
      else this.items = [];
    },
    removeItem(item) {
      this.$emit("removeImage", item);
      let index = this.items.findIndex((x) => x.id === item.id);
      if (index >= 0) {
        this.items.splice(index, 1);
      }
    },
    onRowContextMenu(event) {
      if (this.selected && Object.keys(this.selected).length > 0) {
        this.$refs.cmImagesDatatable.show(event.originalEvent);
      }
    },
    formatUnixDateTime2String(value) {
      let result = "---";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.datatable {
  padding: 0.25rem;
  button {
    width: 1.6125rem;
    height: 1.6125rem;
    padding: 0.25rem;
  }
}
</style>
<style lang="scss" scoped>
.my-project__images {
  .datatable {
    button {
      width: 1.825rem;
      height: 1.825rem;
      padding: 0.25rem;
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.my-project__images {
  .p-datatable {
    border-radius: 0.25rem;
    .p-datatable-thead > tr > {
      th {
        background: white;
        border: none;
        color: #a1a5b7;
        border-bottom: 1px solid #eff2f5;
      }
    }
    .p-datatable-tbody > tr > {
      border-bottom: 1px solid #eff2f5;
      td {
        border: none;
        color: $text-color;
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
</style>