<template>
  <div
    class="map__openlayer"
    id="map__openlayer"
    :style="{ height: mapHeight + 'px' }"
  ></div>
</template>
<script>
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { Tile as TileLayer } from "ol/layer";
import XYZ from "ol/source/XYZ";
import { TileImage } from "ol/source";
import { defaults as defaultControls, FullScreen } from "ol/control";
import { fromLonLat, transform } from "ol/proj";
import WKT from "ol/format/WKT";
import { Vector as VectorLayer, Image as ImageLayer } from "ol/layer";
import { Vector as VectorSource, ImageCanvas } from "ol/source";
import { Style, Fill, Stroke } from "ol/style.js";
import { getCenter } from "ol/extent";

import metaKeyFunc from "@/utils/functions/metakey";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
export default {
  props: {
    propWkt: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      map: Object,
      layer: Object,
      layerDrawImage: Object,
      styles: {
        footprint: new Style({
          fill: new Fill({
            color: "rgba(0,158,247,0.3)",
          }),
          stroke: new Stroke({
            color: "rgba(0,158,247,1)",
            width: 1.25,
          }),
        }),
        image: new Style({
          fill: new Fill({
            color: "rgba(0,0,0,0)",
          }),
          stroke: new Stroke({
            color: "rgba(0,0,0,0.8)",
            width: 1.25,
          }),
        }),
      },
    };
  },
  async mounted() {
    if (!this.basemap || Object.keys(this.basemap).length === 0)
      await this.getAllBasemaps();
    this.initMapOpenlayer();
  },
  created() {},
  methods: {
    initMapOpenlayer: function () {
      var vm = this;
      var source = new VectorSource({ wrapX: false, id: "sourcedraw" });

      this.layer = new VectorLayer({
        source: source,
      });
      this.layer.set("id", "layerdraw");
      this.map = new Map({
        controls: defaultControls({ attribution: false, zoom: false }),
        layers: [
          new TileLayer({
            title: "Basemap",
            id: "vgbasemap",
            source: new XYZ({
              url:
                vm.basemap &&
                Object.keys(vm.basemap).length > 0 &&
                vm.basemap.source &&
                vm.basemap.source.url
                  ? vm.basemap.source.url
                  : process.env.VUE_APP_BASEMAP_URL,
            }),
            show: true,
            opacity: 1,
          }),
          this.layer,
        ],
        target: "map__openlayer",
        view: new View({
          center: fromLonLat([105, 21]),
          zoom: 8,
          minZoom: 1,
          maxZoom: 20,
        }),
      });
      this.addFootprint();
      this.$emit("initMap", this.map);
    },

    addFootprint: async function () {
      try {
        if (this.propWkt) {
          this.addFeature(this.layer, this.propWkt, this.styles.footprint);
        }
      } catch (error) {
        console.error("addFootprint", error);
      }
    },
    addFeature(layer, wkt, style) {
      let id = new Date().getTime();
      let feature = layer.getSource().getFeatureById(id);
      if (!feature) {
        var points = [];
        try {
          const format = new WKT();
          feature = format.readFeature(wkt, {
            dataProjection: "EPSG:4326",
            featureProjection: "EPSG:3857",
          });
          feature.set("id", id);
          feature.id_ = id;
          feature.setStyle(style);
          layer.getSource().addFeature(feature);
        } catch (error) {
          console.error("addFeature", error);
        }
      } else {
        feature.style = { visibility: "visible" };
      }
      if (feature) {
        var ext = feature.getGeometry().getExtent();

        this.map.getView().fit(ext, this.map.getSize());
        var center = getCenter(ext);
        this.map.getView().animate({
          center: [center[0], center[1]],
          duration: 100,
          zoom:
            this.map.getView().getZoom() < 2
              ? 2
              : this.map.getView().getZoom() - 1,
        });
      }
    },
    async getAllBasemaps() {
      try {
        let response = await metaKeyFunc.getByKey(
          "BASEMAPS",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            let items = [];
            let data = response.data.data ? response.data.data : [];
            for (let i = 0; i < data.length; i++) {
              try {
                let obj = JSON.parse(data[i].value);
                if (obj && Object.keys(obj).length > 0) {
                  items.push({
                    id: data[i].id,
                    type:
                      obj.type && obj.type === "shapefile"
                        ? "JSON_FILE"
                        : "TILE_LAYER",
                    name: data[i].name,
                    title: obj.name ? obj.name : obj.NAME,
                    value: data[i].value,
                    description: data[i].description,
                    id:
                      new Date().getTime() -
                      Math.floor(Math.random() * 99999) +
                      Math.floor(Math.random() * 10000),
                    show:
                      (obj.is_show && obj.is_show + "" === "1") ||
                      (obj.IS_SHOW && obj.IS_SHOW + "" === "1")
                        ? true
                        : false,
                    opacity: 100,
                    basemap: true,
                    source: {
                      type: "TILE_IMAGE",
                      url: obj.path ? obj.path : obj.PATH,
                    },
                  });
                }
              } catch (error) {}
            }
            if (items && items.length > 0) {
              if (!this.basemap || Object.keys(this.basemap).length === 0) {
                let arrayBasemaps = items.filter(
                  (x) => x.show === true && (!x.type || x.type === "TILE_LAYER")
                );
                if (arrayBasemaps && arrayBasemaps.length > 0)
                  this.$store.dispatch(
                    "setBasemapDefault",
                    arrayBasemaps[arrayBasemaps.length - 1]
                  );
              }
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllBasemaps);
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {});
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {});
      }
      localStorage.removeItem("lock-refresh");
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    mapHeight() {
      return 300;
    },
    basemap() {
      return this.$store.getters.getBasemap;
    },
  },
};
</script>
<style lang="scss" scoped>
.map__openlayer {
  position: absolute;
  width: 100%;
  padding: 0;
  border: 1px solid #eee;
  max-height: 1000px;
}
</style>
