<template>
  <div
    class="background"
    :style="[isResize ? { height: 'calc(100vh - 56px)' } : { height: '100%' }]"
  >
    <DialogMessage
      v-if="message.length > 0"
      :propTitle="$t('title_message_info')"
      :prop-show="message.length > 0"
      :prop-message="message"
      @close="message = []"
    />
    <DialogConfirm
      v-if="dialogSuccess.show"
      :prop-show="dialogSuccess.show"
      :prop-title="$t('title_message_info')"
      :prop-icon="dialogSuccess.icon"
      :prop-message="dialogSuccess.message"
      :textNo="''"
      :textYes="$t('button_text_close')"
      :propStyle="{ width: dialogSuccess.width }"
      @cancel="closeDialog"
      @confirmed="closeDialog"
    />
    <div class="signup" id="signupform">
      <h4 class="text-left title">{{ $t("title_register_new_account") }}</h4>
      <ValidationObserver ref="userObserver">
        <form>
          <div class="form-group">
            <label
              >{{ $t("label_full_name") }}&nbsp;
              <span class="font-weight-bold required">*</span></label
            >
            <ValidationProvider :name="$t('label_full_name')" rules="required">
              <InputText
                type="text"
                class="form-control w-100"
                maxlength="150"
                autofocus
                :placeholder="$t('placeholder_full_name') + '...'"
                v-model="entity.full_name"
              />
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label class="p-col-fixed"
              >{{ $t("label_username") }}&nbsp;
              <span class="font-weight-bold required">*</span></label
            >

            <ValidationProvider :name="$t('label_username')" rules="required">
              <InputText
                type="text"
                maxlength="50"
                v-model="entity.username"
                aria-autocomplete="off"
                autocomplete="off"
                class="form-control w-100"
                :placeholder="$t('placeholder_username') + '...'"
              />
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label class="p-col-fixed"
              >{{ $t("label_password") }}&nbsp;
              <span class="font-weight-bold required">*</span></label
            >

            <ValidationProvider
              :name="$t('label_password')"
              rules="required"
              vid="confirmation"
            >
              <InputText
                type="password"
                maxlength="50"
                v-model="entity.password"
                aria-autocomplete="off"
                autocomplete="off"
                class="form-control w-100"
                :placeholder="$t('placeholder_password') + '...'"
              />
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label class="w-100 form-label"
              >{{ $t("label_password_confirm") }}&nbsp;
              <span class="font-weight-bold required">*</span></label
            >
            <ValidationProvider
              :name="$t('label_password_confirm')"
              rules="required|confirmed:confirmation"
            >
              <InputText
                type="password"
                class="form-control"
                v-model="entity.pass_confirm"
                aria-autocomplete="off"
                autocomplete="off"
                maxLength="50"
                :placeholder="$t('placeholder_confirm_password') + '...'"
              />
            </ValidationProvider>
          </div>

          <div class="form-group">
            <div class="d-flex">
              <div
                class="p-field p-col pr-3"
                v-for="item in dataGender"
                :key="item.id"
              >
                <label for="male" class="p-radiobutton-label pr-2">
                  {{ $t("label_" + item.code) }}
                </label>
                <RadioButton
                  :id="'radio' + item.id"
                  :name="item.code"
                  :value="item.id"
                  v-model="entity.gender"
                />
              </div>
            </div>
          </div>

          <div class="form-group">
            <label
              >{{ $t("label_email") }}&nbsp;
              <span class="font-weight-bold required">*</span></label
            >
            <ValidationProvider
              :name="$t('label_email')"
              rules="required|email"
            >
              <InputText
                type="email"
                class="form-control"
                :placeholder="$t('placeholder_email') + '...'"
                maxlength="150"
                v-model="entity.email"
              />
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label
              >{{ $t("label_phone_number") }}&nbsp;
              <span class="font-weight-bold required">*</span></label
            >

            <ValidationProvider
              :name="$t('label_phone_number')"
              rules="required"
            >
              <InputText
                type="tel"
                class="form-control w-100"
                :placeholder="$t('placeholder_phone_number') + '...'"
                maxlength="10"
                v-model="entity.phone_number"
              />
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>{{ $t("label_address") }}</label>
            <Textarea
              :autoResize="true"
              rows="2"
              cols="30"
              maxlength="250"
              v-model="entity.address"
              class="form-control w-100"
              :placeholder="$t('placeholder_address') + '...'"
            />
          </div>
          <div class="form-group">
            <label
              >{{ $t("label_organisation_name") }}&nbsp;
              <span class="font-weight-bold required">*</span></label
            >
            <ValidationProvider
              :name="$t('label_organisation_name')"
              rules="required"
            >
              <InputText
                type="text"
                class="form-control"
                maxlength="255"
                :placeholder="$t('placeholder_organisation_name') + '...'"
                v-model="entity.organization"
              />
            </ValidationProvider>
          </div>
          <div
            class="form-group"
            v-if="strReCaptchaSiteKey && strReCaptchaSiteKey != 'null'"
          >
            <vue-recaptcha
              :sitekey="strReCaptchaSiteKey"
              :loadRecaptchaScript="true"
            >
            </vue-recaptcha>
          </div>
          <div class="form-group text-right">
            <button
              type="button"
              class="btn btn-geostore2 btn-submit"
              @click="onSubmit"
              :disabled="button.disable"
            >
              <i class="fas fa-spinner fa-spin" v-if="button.processing"></i
              >&nbsp;{{ $t("button_text_register") }}
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import InputText from "@/components/commons/inputtext/InputText";
import Textarea from "@/components/commons/textarea/Textarea";
import RadioButton from "@/components/commons/radiobutton/RadioButton";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import VueRecaptcha from "vue-recaptcha";
import DialogConfirm from "@/components/commons/dialog/Confirmation2";
import userMeControllerFunc from "@/utils/functions/usermecontroller";
import { isVietnamesePhoneNumber } from "@/utils/commons/common";
import { recaptchaSiteKey } from "@/utils/commons/environment";
export default {
  components: {
    InputText,
    DialogMessage,
    Textarea,
    RadioButton,
    VueRecaptcha,
    DialogConfirm,
  },
  data() {
    return {
      message: [],
      isResize: false,
      entity: {
        id: null,
        address: null,
        email: null,
        full_name: null,
        password: null,
        phone_number: null,
        username: null,
        pass_confirm: null,
        gender: "1",
        organization: null,
        is_active: true,
      },
      dataGender: [
        {
          id: "1",
          code: "male",
        },
        {
          id: "2",
          code: "female",
        },
        {
          id: "3",
          code: "other",
        },
      ],

      dialogSuccess: {
        show: false,
        title: this.$t("title_message_confirm"),
        width: 540 + "px",
        value: null,
        icon: "",
        message: null,
      },
      button: {
        disable: false,
        processing: false,
      },
      strReCaptchaSiteKey: recaptchaSiteKey,
    };
  },
  created() {
    this.$store.dispatch("setIsLoginForm", false);
  },
  mounted() {
    setTimeout(() => {
      let element = document.getElementById("signupform");
      if (element && Object.keys(element).length > 0) {
        this.isResize =
          this.documentHeight - 56 - element.offsetHeight - 32 > 0;
      } else this.isResize = false;
    }, 100);
  },
  watch: {
    documentWidth(val) {
      let element = document.getElementById("signupform");
      if (element && Object.keys(element).length > 0) {
        this.isResize =
          this.documentHeight - 56 - element.offsetHeight - 32 > 0;
      } else this.isResize = false;
    },
  },
  methods: {
    generateEntity() {
      return {
        id: null,
        address: null,
        email: null,
        full_name: null,
        password: null,
        phone_number: null,
        username: null,
        pass_confirm: null,
        gender: "1",
        organization: null,
        is_active: true,
      };
    },
    closeDialog() {
      this.dialogSuccess.message = "";
      this.dialogSuccess.show = false;
      this.$router.push({
        path: "/",
      });
    },
    onSubmit() {
      var vm = this;
      this.$refs.userObserver.validate().then(async (success) => {
        if (!success) {
          setTimeout(() => {
            Object.keys(vm.$refs.userObserver.errors).forEach(function (key) {
              if (
                vm.$refs.userObserver.errors[key] &&
                vm.$refs.userObserver.errors[key].length
              )
                vm.message.push(vm.$refs.userObserver.errors[key].join(",\n"));
            });
          }, 100);
          return;
        } else {
          this.button.disable = true;
          this.button.processing = true;
          var responseCaptcha = null;
          if (this.strReCaptchaSiteKey && this.strReCaptchaSiteKey != "null") {
            responseCaptcha = grecaptcha.getResponse();

            if (!responseCaptcha || responseCaptcha.length === 0) {
              this.button.disable = false;
              this.button.processing = false;
              this.message.push(this.$t("message_select_recaptcha_pls"));
              return;
            }
          }

          let dto = Object.assign({}, this.entity);
          dto.username = dto.username
            ? dto.username.trim().replace(/\s\s+/g, " ")
            : null;
          dto.full_name = dto.full_name
            ? dto.full_name.trim().replace(/\s\s+/g, " ")
            : null;
          dto.password = dto.password
            ? dto.password.trim().replace(/\s\s+/g, " ")
            : null;
          dto.email = dto.email
            ? dto.email.trim().replace(/\s\s+/g, " ")
            : null;
          dto.phone_number = dto.phone_number
            ? dto.phone_number.trim().replace(/\s\s+/g, " ")
            : null;
          dto.address = dto.address
            ? dto.address.trim().replace(/\s\s+/g, " ")
            : null;
          dto.organization = dto.organization
            ? dto.organization.trim().replace(/\s\s+/g, " ")
            : null;

          if (dto.password && dto.pass_confirm.length < 8) {
            this.button.disable = false;
            this.button.processing = false;
            this.message.push(this.$t("message_password_characters_error"));
            return;
          }
          let number = 0,
            alpha = 0;
          for (let i = 0; i < dto.password.length; i++) {
            let characterNum = dto.password.charCodeAt(i);
            if (characterNum === 32) {
              this.message.push(this.$t("message_password_characters_error"));
              break;
            }
            if (characterNum >= 48 && characterNum <= 57) {
              number = number + 1;
            }
            if (characterNum >= 65 && characterNum <= 90) {
              alpha = alpha + 1;
            }
            if (characterNum >= 97 && characterNum <= 122) {
              alpha = alpha + 1;
            }
          }
          if (number == 0 || alpha == 0) {
            this.message.push(this.$t("message_password_characters_error"));
          }
          // if (
          //   !isVietnamesePhoneNumber(
          //     this.entity.phone_number
          //       ? this.entity.phone_number.trim().replace(/\s\s+/g, " ")
          //       : null
          //   )
          // ) {
          //   this.message.push(
          //     this.$t("message_phone_number_is_not_correct_format")
          //   );
          // }
          if (this.message.length > 0) {
            this.button.disable = false;
            this.button.processing = false;
            return;
          }

          if (dto.pass_confirm != undefined) delete dto.pass_confirm;
          try {
            let response = await userMeControllerFunc.create(
              dto,
              responseCaptcha
            );
            if (response && response.status === 200) {
              if (response.data && response.data.success) {
                // let msg =
                // this.$toast.success({
                //   title: this.$t("title_message_info"),
                //   message: this.$t("message_register_account_success", null, {
                //     name: dto.username,
                //   }),
                //   position: "top right",
                // });
                // this.$router.push({
                //   path: "/",
                // });
                this.dialogSuccess.message =
                  response.data && response.data.message
                    ? response.data.message
                    : null;
                this.dialogSuccess.show = true;
              } else {
                if (
                  this.strReCaptchaSiteKey &&
                  this.strReCaptchaSiteKey != "null"
                )
                  grecaptcha.reset();
                this.message.push(
                  this.$t("message_register_account_error", null, {
                    name: dto.username,
                  })
                );
              }
            } else {
              if (
                this.strReCaptchaSiteKey &&
                this.strReCaptchaSiteKey != "null"
              )
                grecaptcha.reset();
              this.message.push(
                this.$t("message_register_account_error", null, {
                  name: dto.username,
                })
              );
            }
            this.button.disable = false;
            this.button.processing = false;
          } catch (error) {
            if (this.strReCaptchaSiteKey && this.strReCaptchaSiteKey != "null")
              grecaptcha.reset();
            this.button.disable = false;
            this.button.processing = false;
            let msg = null;
            if (error.response && error.response.data) {
              msg = error.response.data.message
                ? error.response.data.message
                : null;
            }
            this.message.push(
              msg
                ? msg
                : this.$t("message_register_account_error", null, {
                    name: dto.username,
                  })
            );
          }
        }
      });
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.background {
  margin: 0;
  padding: 0;
  background-image: url("/img/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 0.5rem;
  width: 100%;
}
.signup {
  background: white;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(2, 34, 89, 1);
  -moz-box-shadow: 0px 0px 2px 0px rgba(2, 34, 89, 1);
  box-shadow: 0px 0px 2px 0px rgba(2, 34, 89, 1);
  border-radius: 0.25rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem;
  width: 560px;
  .form-group {
    label {
      margin: 0;
      padding-left: 0.25rem;
      color: $text-color;
    }
    input,
    textarea {
      background: #eef3f7;
      border-radius: 4px;
      border-color: #eef3f7;
      &::placeholder {
        color: #b3c3d4;
      }
    }
    &:last-child {
      margin: 0;
    }
  }
  button {
    &.btn-submit {
      font-size: 14px;
      min-width: 7rem;
    }
  }
}
</style>
<style lang="scss">
.signup {
  .form-group {
    .p-radiobutton .p-radiobutton-box {
      background: #eef3f7;
      border-color: #eef3f7;
    }
  }
}
</style>
