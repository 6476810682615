import Vue from 'vue'
import Vuex from 'vuex'
import common from "./modules/common"
import token from "./modules/token"
import search from "./modules/search"
import tasking from "./modules/tasking"
Vue.use(Vuex)

export const store =  new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    common,
    token,
    search,tasking
  }
})
