import { extend, localize } from "vee-validate";
import {
  min,
  max,
  required,
  email,
  length,
  confirmed
} from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";
import ja from "vee-validate/dist/locale/ja.json";
import vi from "vee-validate/dist/locale/vi.json";

// Install rules
extend("required", required);
extend("min", min);
extend("max", max);
extend("email", email);
extend("confirmed", confirmed);
extend("length", length);

// Install and Activate the Arabic locale.
//localize("vi", vi);
