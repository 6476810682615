import { render, staticRenderFns } from "./StatisticDownload.vue?vue&type=template&id=3545d0f5&scoped=true&"
import script from "./StatisticDownload.vue?vue&type=script&lang=js&"
export * from "./StatisticDownload.vue?vue&type=script&lang=js&"
import style0 from "./StatisticDownload.vue?vue&type=style&index=0&id=3545d0f5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3545d0f5",
  null
  
)

export default component.exports