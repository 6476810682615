import axios from "axios";
import basicApi from "@/utils/apis/basic";
import { geoServiceApiURL } from "@/utils/commons/environment";
import qs from "qs";
function authenticateUser(user, password) {
  var token = user + ":" + password;

  // Should i be encoding this value????? does it matter???
  // Base64 Encoding -> btoa
  var hash = btoa(token);

  return "Basic " + hash;
}

export default {
  async searchByIds(ids, accessToken) {
    let headers = {
      Authorization: "Bearer " + accessToken,
    };
    let payload = {
      query: {},
      queryPage: {},
      searchChanel: {
        oneatlas: true,
      },
      ids: ids,
    };

    const object = axios({
      method: "post",
      url: geoServiceApiURL + "/search",
      data: payload,
      headers: headers,
    });
    let response = await object;
    return response;
  },
  async search(entity, accessToken) {
    let headers = {
      Authorization: "Bearer " + accessToken,
    };

    let payload = {
      query: {},
      queryPage: {
        oneatlas: {
          limit: 250,
          page: entity?.page || 1,
        },
      },
      searchChanel: {
        oneatlas: true,
      },
    };
    if (entity.resolution_from || entity.resolution_to) {
      payload.query["sar:resolution_range"] = {
        gte: entity.resolution_from,
        lte: entity.resolution_to,
      };
    }

    if (entity.cloud_coverage_from || entity.cloud_coverage_to) {
      payload.query["eo:cloud_cover"] = {
        gte: entity.cloud_coverage_from,
        lte: entity.cloud_coverage_to,
      };
    }
    if (entity.incidence_angle_from || entity.incidence_angle_to) {
      payload.query["view:incidence_angle"] = {
        gte: entity.incidence_angle_from,
        lte: entity.incidence_angle_to,
      };
    }
    if (entity.boundary && entity.boundary.length > 0) {
      payload.intersects = {
        type: "Polygon",
        coordinates: [entity.boundary],
      };
    }

    const object = axios({
      method: "post",
      url: geoServiceApiURL + "/search",
      data: payload,
      headers: headers,
    });
    let response = await object;
    return response;
  },
  async searchRadar(payload, authorization) {
    if (payload.boundary && payload.boundary.length > 0) {
      payload.aoi = {
        type: "Polygon",
        coordinates: [payload.boundary],
      };
    }
    if (payload.image_time_from && payload.image_time_to) {
      payload.time = {
        from: "2021-10-14T13:28:03.569Z",
        to: "2021-10-29T13:28:03.569Z",
      };
    }
    let headers = {
      Authorization: authorization,
    };
    const object = axios({
      method: "post",
      url: "/oneatlas/v1/sar/catalogue",
      headers: headers,
      data: {
        aoi: {
          type: "Polygon",
          coordinates: [
            [
              [9.346, 47.788],
              [9.291, 47.644],
              [9.538, 47.592],
              [9.62, 47.75],
              [9.511, 47.802],
              [9.346, 47.788],
            ],
          ],
        },
        time: {
          from: "2021-10-14T13:28:03.569Z",
          to: "2021-10-29T13:28:03.569Z",
        },
      },
    });
    let response = await object;
    return response;
  },
  async getImage(url) {
    let headers = {};

    const object = axios({
      method: "get",
      url: url,
      headers: headers,
      responseType: "blob",
    });
    let response = await object;
    return response;
  },
  async getAccessToken() {
    const object = axios({
      method: "post",
      url: "https://authenticate.foundation.api.oneatlas.airbus.com/auth/realms/IDP/protocol/openid-connect/token",
      data: qs.stringify({
        grant_type: "api_key",
        apikey:
          "zNGTIngKpSqCXd27ZOIcSXSZHCmRfrY04_0jNCA3ByYS_lNBolWXSqFaAceW2yccIx574AutLoyk53RFC8LtRQ==",
        client_id: "IDP",
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      params: {},
    });
    let response = await object;
    return response;
  },
  async getUserMe(authorization) {
    let headers = {
      Authorization: authorization,
    };
    const object = axios({
      method: "get",
      url: "https://data.api.oneatlas.airbus.com/api/v1/me",
      headers: headers,
    });
    let response = await object;
    return response;
  },
  async getContract(url, authorization) {
    const object = axios({
      method: "get",
      url: url,
      headers: {
        Authorization: authorization,
      },
    });
    let response = await object;
    return response;
  },
  async getSubscriptions(params, url, authorization) {
    const object = axios({
      method: "get",
      url: url,
      headers: {
        Authorization: authorization,
      },
      params: params,
    });
    let response = await object;
    return response;
  },
  async getOrders(params, url, authorization) {
    const object = axios({
      method: "get",
      url: url,
      headers: {
        Authorization: authorization,
      },
      params: params,
    });
    let response = await object;
    return response;
  },

  async getOrderDetail(url, authorization) {
    const object = axios({
      method: "get",
      url: url,
      headers: {
        Authorization: authorization,
      },
    });
    let response = await object;
    return response;
  },
  async calculatePrice(payload, authorization) {
    const object = axios({
      method: "post",
      url: "https://data.api.oneatlas.airbus.com/api/v1/prices",
      headers: {
        Authorization: authorization,
        // "Cache-Control": "no-cache",
        // "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
      },
      data: payload,
    });
    let response = await object;
    return response;
  },
  async createOrder12(payload, authorization) {
    const object = axios({
      //method: "post",
      //url: "https://data.api.oneatlas.airbus.com/api/v1/orders",
      headers: {
        //Authorization: authorization,
        // "Cache-Control": "no-cache",
        // "Content-Type": "application/json",
      },
      //data: payload,
    });
    let response = await object;
    return response;
  },
  async getImageById(id, authorization) {
    const object = axios({
      method: "get",
      url:
        "https://search.foundation.api.oneatlas.airbus.com/api/v2/opensearch?id=" +
        id,
      headers: {
        Authorization: authorization,
      },
    });
    let response = await object;
    return response;
  },
  async downloadImage(url, authorization) {
    const object = axios({
      method: "get",
      //url: url,
      headers: {
        //Authorization: authorization,
      },
      responseType: "blob",
    });
    let response = await object;
    return response;
  },

  // For Tasking
  async getContracts(authorization) {
    const object = axios({
      method: "get",
      url: "https://order.api.oneatlas.airbus.com/api/v1/contracts",
      headers: {
        Authorization: authorization,
      },
    });
    let response = await object;
    return response;
  },
  async getProductTypes(cisContractId, authorization) {
    const object = axios({
      method: "get",
      url: "https://order.api.oneatlas.airbus.com/api/v1/contracts/<CisContractId>/productTypes".replace(
        "<CisContractId>",
        cisContractId
      ),
      headers: {
        Authorization: authorization,
      },
    });
    let response = await object;
    return response;
  },
  async getProductOptions(
    payload,
    cisContractId,
    productTypeId,
    authorization
  ) {
    //payload = object aoi (geojson)
    const object = axios({
      method: "post",
      url: "https://order.api.oneatlas.airbus.com/api/v1/contracts/<CisContractId>/productTypes/<productTypeId>/options"
        .replace("<CisContractId>", cisContractId)
        .replace("<productTypeId>", productTypeId),
      headers: {
        Authorization: authorization,
      },
      data: payload,
    });
    let response = await object;
    return response;
  },
  async checkFeasibility(payload, authorization) {
    const object = axios({
      method: "post",
      url: "https://order.api.oneatlas.airbus.com/api/v1/feasibility",
      headers: {
        Authorization: authorization,
      },
      data: payload,
    });
    let response = await object;
    return response;
  },
  async getAttempts(payload, authorization) {
    // This step is only mandatory for ONEDAY and ONENOW. This request will offer you several choices:
    const object = axios({
      method: "post",
      url: "https://order.api.oneatlas.airbus.com/api/v1/attempts",
      headers: {
        Authorization: authorization,
      },
      data: payload,
    });
    let response = await object;
    return response;
  },
  async getDeliveryTypes(cisContractId, authorization) {
    // This step is only mandatory for ONEDAY and ONENOW. This request will offer you several choices:
    const object = axios({
      method: "get",
      url: "https://order.api.oneatlas.airbus.com/api/v1/contracts/<CisContractId>/deliveryTypes".replace(
        "<CisContractId>",
        cisContractId
      ),
      headers: {
        Authorization: authorization,
      },
    });
    let response = await object;
    return response;
  },
  async getMarkets(cisContractId, authorization) {
    // This step is only mandatory for ONEDAY and ONENOW. This request will offer you several choices:
    const object = axios({
      method: "get",
      url: "https://order.api.oneatlas.airbus.com/api/v1/contracts/<CisContractId>/properties".replace(
        "<CisContractId>",
        cisContractId
      ),
      headers: {
        Authorization: authorization,
      },
    });
    let response = await object;
    return response;
  },
  async calculatePrice4Tasking(payload, authorization) {
    const object = axios({
      method: "post",
      url: "https://order.api.oneatlas.airbus.com/api/v1/prices",
      headers: {
        Authorization: authorization,
      },
      data: payload,
    });
    let response = await object;
    return response;
  },
  async createOrderTasking345(payload, authorization) {
    const object = axios({
      //method: "post",
      //url: "https://order.api.oneatlas.airbus.com/api/v1/orders",
      headers: {
        //Authorization: authorization,
      },
      //data: payload,
    });
    let response = await object;
    return response;
  },
  async trackTaskings(cisContractId, params, authorization) {
    const object = axios({
      method: "get",
      url: "https://order.api.oneatlas.airbus.com/api/v1/contracts/<cisContractId>/taskings".replace(
        "<cisContractId>",
        cisContractId
      ),
      headers: {
        Authorization: authorization,
      },
      params: params,
    });
    let response = await object;
    return response;
  },
  async trackTaskingDetail(cisContractId, taskingId, authorization) {
    const object = axios({
      //method: "get",
      //url: "https://order.api.oneatlas.airbus.com/api/v1/contracts/<cisContractId>/taskings/<taskingId>"
      //  .replace("<cisContractId>", cisContractId)
      //  .replace("<taskingId>", taskingId),
      headers: {
        Authorization: authorization,
      },
    });
    let response = await object;
    return response;
  },
  async cancelTasking(cisContractId, taskingId, authorization) {
    const object = axios({
      method: "post",
      //url: "https://order.api.oneatlas.airbus.com/api/v1/contracts/<cisContractId>/taskings/<taskingId>/cancel"
      //  .replace("<cisContractId>", cisContractId)
      //  .replace("<taskingId>", taskingId),
      headers: {
        Authorization: authorization,
      },
    });
    let response = await object;
    return response;
  },
};
