var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"widget-download-progress-bar animate__animated",class:_vm.show ? 'animate__fadeInBottomRight' : 'animate__fadeOutBottomRight',style:(_vm.style)},[_c('div',{staticClass:"widget-header"},[_c('h6',{staticClass:"title"},[_vm._v(" Downloading... "),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.onClose}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])]),_c('div',{staticClass:"widget-body"},[_c('ul',{staticClass:"list-group images-download"},_vm._l((_vm.propItems),function(item,index){return _c('li',{key:index,staticClass:"list-group-item image-item"},[_c('div',{staticClass:"media"},[_c('icon-preview',{attrs:{"propId":item.preview && item.preview.icon_path
                ? item.preview.icon_path
                : item.preview && item.preview.preview_path
                ? item.preview.preview_path
                : null,"prop-src":item.preview && item.preview.icon_path
                ? item.preview.icon_path
                : item.preview && item.preview.preview_path
                ? item.preview.preview_path
                : null}}),_c('div',{staticClass:"media-body"},[_c('h6',{staticClass:"mt-0",attrs:{"title":item.name.length > 32 ? item.name : ''}},[_vm._v(" "+_vm._s(item.name.length > 32 ? item.name.substring(0, 32) + "..." : item.name)+" ")]),_c('ProgressBar',{staticStyle:{"height":"0.5em"},attrs:{"mode":"indeterminate"}})],1)],1)])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }