import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";

import VueAxios from "vue-axios";
import axios from "axios";

axios.interceptors.request.use(async (request) => {
  // if (!request.headers["Access-Control-Allow-Origin"])
  //   request.headers["Access-Control-Allow-Origin"] = "*";

  // request.headers["Access-Control-Allow-Methods"] =
  //   "GET, PUT, POST, DELETE, OPTIONS";

  // request.headers["Access-Control-Allow-Headers"] =
  //   "Origin, X-Requested-With, Content-Type, Accept";
  return request;
});
Vue.use(VueAxios, axios);

//import "./assets/styles/tailwind.css";

import "./utils/commons/vee-validate.js";
import CxltToastr from "../public/js/toastr/cxlt-vue2-toastr";
import toastrcss from "../public/css/toastr/cxlt-vue2-toastr.css";
Vue.use(CxltToastr, {
  position: "bottom right",
  timeOut: "5000",
  progressBar: false,
  closeButton: true,
});

import { ValidationObserver, ValidationProvider } from "vee-validate";

// Install components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-nav-tabs/dist/vue-tabs.min.css";

import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/vi";
import "vue2-datepicker/locale/en";

import "../public/css/customize/primevue.min.css";
import "../public/css/customize/saga-blue/theme.css";
import "../public/css/customize/nova-light/theme.css";
import "../public/css/primeicons/primeicons.css";

import "animate.css";

import moment from "moment";
Vue.filter("formatUnix", function (value) {
  if (value) {
    return moment.unix(value).format("DD/MM/YYYY");
  } else return "";
});
Vue.filter("formatUnixDateTime", function (value) {
  if (value) {
    return moment.unix(value).format("HH:mm DD/MM/YYYY");
  }
});

Vue.filter("formatSizeFile", function (bytes, si = false) {
  if (!bytes) return null;
  var thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  var units = si
    ? ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + "" + units[u];
});

Vue.filter("numberFormat", function (value) {
  if (value) {
    return new Intl.NumberFormat().format(value);
  } else return value;
});

import i18n from "./lang/lang";
Vue.config.productionTip = false;

const app = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
window.Event = app;
export default app;
