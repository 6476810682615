<template>
  <div class="upload">
    <div class="upload__header">
      <h5 class="upload__header-title">
        {{ $t("title_upload") }}
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          @click="close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </h5>
    </div>
    <div class="upload__body">
      <vue-tabs @tab-change="handleTabChange">
        <v-tab title="Shape (zip archived)">
          <shape-file :prop-data="data.shape"></shape-file>
        </v-tab>
        <v-tab title="KML">
          <kml :prop-data="data.kml"></kml>
        </v-tab>
        <v-tab title="Geojson">
          <geojson :prop-data="data.geojson"></geojson>
        </v-tab>
        <v-tab title="CSV">
          <csv :prop-data="data.csv"></csv>
        </v-tab>
        <v-tab title="Query">
          <query :prop-data="data.query"></query>
        </v-tab>
        <v-tab title="Geodatabase (zip archived)">
          <geo-database-file :prop-data="data.geodatabase"></geo-database-file>
        </v-tab>
      </vue-tabs>
    </div>
    <div class="upload__footer">
      <button class="btn btn-primary" @click="onSearch">
        <i class="fas fa-search"></i>&nbsp;{{ $t("button_text_search") }}
      </button>
    </div>
  </div>
</template>
<script>
//https://cristijora.github.io/vue-tabs/#/?id=vue-tabs-props
import { VueTabs, VTab } from "vue-nav-tabs";
import ShapeFile from "./upload/ShapeFile";
import GeoDatabaseFile from "./upload/GeoDatabase.vue";
import Kml from "./upload/KML";
import Csv from "./upload/CSV";
import Geojson from "./upload/Geojson";
import Query from "./upload/Query";
import { GeoJSON } from "ol/format.js";
import KML from "ol/format/KML.js";
import Polygon from "ol/geom/Polygon";
import {
  Circle as CircleStyle,
  Fill,
  RegularShape,
  Stroke,
  Style,
} from "ol/style.js";
import Feature from "ol/Feature";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
export default {
  props: {
    propCriteria: {
      type: Object,
      default: () => {},
    },
    propItems: {
      type: Array,
      default: () => [],
    },
    propData: {
      type: Object,
      default: () => {},
    },
    map: {
      required: true,
    },
  },
  components: {
    VueTabs,
    VTab,
    ShapeFile,
    Kml,
    Csv,
    Geojson,
    Query,
    GeoDatabaseFile,
  },
  data() {
    return {
      selected: "SHAPE",
      data: {
        shape: {
          file: null,
          contents: [],
        },
        kml: {
          file: null,
          contents: null,
        },
        geojson: {
          file: null,
          contents: null,
        },
        csv: {
          file: null,
          imagesName: [],
          text: "",
        },
        query: {
          file: null,
          fields: [],
        },
        geodatabase: {
          file: null,
          contents: [],
        },
      },
    };
  },
  computed: {
    searchDto() {
      return this.$store.getters.getSearchDto;
    },
  },
  created() {
    if (this.searchDto) {
      if (this.searchDto.image_name != undefined) {
        this.data.csv.text = (
          this.searchDto.image_name ? this.searchDto.image_name : []
        ).join("\n");
      }
    }
  },
  methods: {
    close() {
      this.$emit("close", {
        component: "UPLOAD",
      });
    },
    handleTabChange(e) {
      switch (e) {
        case 0:
          this.selected = "SHAPE";
          break;
        case 1:
          this.selected = "KML";
          break;
        case 2:
          this.selected = "Geojson";
          break;
        case 3:
          this.selected = "CSV";
          break;
        case 4:
          this.selected = "Query";
          break;
        case 5:
          this.selected = "Geodatabase";
          break;
        default:
          break;
      }
    },
    addlayerGeodatabase(data) {
      try {
        const vectorSource = new VectorSource({
          features: new GeoJSON({
            dataProjection: "EPSG:4326",
            featureProjection: "EPSG:3857",
          }).readFeatures(JSON.stringify(data.features)),
          id: data.name + new Date().getTime(),
          wrapX: false,
        });
        const vectorLayer = new VectorLayer({
          source: vectorSource,
          id: data.name + new Date().getTime(),
        });
        vectorLayer.setZIndex(this.map.getLayers().array_.length + 10);
        this.map.addLayer(vectorLayer);
      } catch (error) {
        console.log(error);
      }
    },
    async onSearch() {
      var vm = this;
      try {
        switch (this.selected) {
          case "SHAPE":
            let geojsonObject = await this.readZipFile(this.data.shape.file);
            if (geojsonObject) {
              if (geojsonObject.features.length) {
                this.$store.dispatch("setSearchDto", {
                  boundary: JSON.stringify(geojsonObject.features[0].geometry),
                  file_name: this.data.shape.file
                    ? this.data.shape.file.name
                    : null,
                });
                this.$emit("onSearch", {
                  feature: geojsonObject.features[0],
                  value: geojsonObject.features[0],
                  type: "SHAPE",
                  boundary: JSON.stringify(geojsonObject.features[0].geometry),
                });
              } else
                this.$emit("message", [
                  this.$t("message_structure_file_not_correct", null, {
                    name: "shape",
                  }),
                ]);
            } else
              this.$emit("message", [
                this.$t("message_structure_file_not_correct", null, {
                  name: "shape",
                }),
              ]);

            break;
          case "Geodatabase":
            this.$store.dispatch("setSpinnerApp", {
              show: true,
            });
            let featureCollection;
            try {
              var r = require("@/utils/lib/index");
              var reader = new FileReader();
              reader.onload = await function () {
                r(reader.result).then(function (t) {
                  if (t && Object.keys(t).length > 0) {
                    if (t[vm.data.geodatabase.file.name])
                      featureCollection = t[vm.data.geodatabase.file.name];
                    else {
                      for (const [key, value] of Object.entries(t)) {
                        featureCollection = value;
                      }
                    }
                    if (
                      featureCollection &&
                      Object.keys(featureCollection).length
                    ) {
                      vm.addlayerGeodatabase({
                        features: featureCollection,
                        name: vm.data.geodatabase.file.name,
                      });
                    }
                  }
                });
              };
              reader.readAsArrayBuffer(this.data.geodatabase.file);
            } catch (error) {
              this.$store.dispatch("setSpinnerApp", {
                show: false,
              });
              console.log(error);
            }
            this.$store.dispatch("setSpinnerApp", {
              show: false,
            });
            break;
          case "KML":
            let format = new KML({
              defaultStyle: new Style({
                stroke: new Stroke({
                  color: "red",
                  width: 1.25,
                }),
                fill: new Fill({
                  color: "rgba(255,255,255,0.4)",
                }),
                strokeColor: "#3399CC",
              }),
            });
            let feature = format.readFeature(this.data.kml.contents, {
              featureProjection: "EPSG:4326",
            });
            var geom = [];
            geom.push(feature);
            var writer = new GeoJSON();
            var geoJsonStr = writer.writeFeatures(geom);
            let geometryObj = JSON.parse(geoJsonStr).features[0].geometry;
            let boundary = {
              type: "Polygon",
              coordinates: [],
              feature: null,
            };
            if (geometryObj && geometryObj.type === "MultiPolygon") {
              boundary.coordinates =
                geometryObj.coordinates && geometryObj.coordinates.length > 0
                  ? geometryObj.coordinates[0]
                  : [];
              let polygon = new Polygon([geometryObj.coordinates[0][0]]);
              boundary.feature = new Feature({
                geometry: polygon.transform("EPSG:4326", "EPSG:3857"),
              });
            }
            this.$store.dispatch("setSearchDto", {
              boundary: JSON.stringify(
                boundary.coordinates.length > 0
                  ? boundary
                  : JSON.parse(geoJsonStr).features[0].geometry
              ),
              file_name: this.data.kml.file ? this.data.kml.file.name : null,
            });
            this.$emit("onSearch", {
              feature: boundary.feature
                ? boundary.feature
                : new Feature(
                    feature
                      .getGeometry()
                      .clone()
                      .transform("EPSG:4326", "EPSG:3857")
                  ),
              value: JSON.parse(geoJsonStr).features[0],
              type: "KML",
              boundary: JSON.stringify(
                boundary.coordinates.length > 0
                  ? boundary
                  : JSON.parse(geoJsonStr).features[0].geometry
              ),
            });
            break;
          case "Geojson":
            let feature2 = new GeoJSON().readFeatures(
              this.data.geojson.contents,
              {
                featureProjection: "EPSG:4326",
              }
            );
            if (feature2 && feature2.length > 0) {
              var geom = [];
              geom.push(feature2[0]);
              var writer = new GeoJSON();
              var geoJsonStr = writer.writeFeatures(geom);
              let geometryObj = JSON.parse(geoJsonStr).features[0].geometry;
              let boundary = {
                type: "Polygon",
                coordinates: [],
                feature: null,
              };

              if (geometryObj && geometryObj.type === "MultiPolygon") {
                boundary.coordinates =
                  geometryObj.coordinates && geometryObj.coordinates.length > 0
                    ? geometryObj.coordinates[0]
                    : [];
                let polygon = new Polygon([geometryObj.coordinates[0][0]]);
                boundary.feature = new Feature({
                  geometry: polygon.transform("EPSG:4326", "EPSG:3857"),
                });
              }
              this.$store.dispatch("setSearchDto", {
                boundary: JSON.stringify(
                  boundary.coordinates.length > 0
                    ? boundary
                    : JSON.parse(geoJsonStr).features[0].geometry
                ),
                file_name: this.data.geojson.file
                  ? this.data.geojson.file.name
                  : null,
              });
              this.$emit("onSearch", {
                feature: boundary.feature
                  ? boundary.feature
                  : new Feature(
                      feature2[0]
                        .getGeometry()
                        .clone()
                        .transform("EPSG:4326", "EPSG:3857")
                    ),
                value: JSON.parse(geoJsonStr).features[0],
                type: "Geojson",
                boundary: JSON.stringify(
                  boundary.coordinates.length > 0
                    ? boundary
                    : JSON.parse(geoJsonStr).features[0].geometry
                ),
              });

              // this.$store.dispatch("setSearchDto", {
              //   boundary: JSON.stringify(
              //     JSON.parse(geoJsonStr).features[0].geometry
              //   ),
              //   file_name: this.data.geojson.file
              //     ? this.data.geojson.file.name
              //     : null,
              // });
              // this.$emit("onSearch", {
              //   feature: new Feature(
              //     feature2[0]
              //       .getGeometry()
              //       .clone()
              //       .transform("EPSG:4326", "EPSG:3857")
              //   ),
              //   value: feature2[0],
              //   type: "Geojson",
              //   boundary: JSON.stringify(
              //     JSON.parse(geoJsonStr).features[0].geometry
              //   ),
              // });
            } else {
              this.$emit("message", [
                this.$t("message_structure_file_not_correct", null, {
                  name: "geojson",
                }),
              ]);
            }
            break;
          case "CSV":
            if (!this.data.csv.text || this.data.csv.text.length === 0) {
              this.$emit("message", [this.$t("message_insert_image_name")]);
              return;
            } else {
              this.$store.dispatch("setSearchDto", {
                text: this.data.csv.text.split("\n").join(" "),
              });
              this.$emit("onSearch");
            }
            break;
          case "Query":
            let fields = this.data.query.fields.filter(
              (x) => x.value != null && x.value != undefined
            );

            if (fields && fields.length > 0) {
              let dto = {};
              let keysField = fields.map((x) => x.key);
              if (!keysField.includes("boundary")) {
                dto.boundary = null;
              }
              for (let i = 0; i < fields.length; i++) {
                if (fields[i].key === "image_time_from") {
                  dto.image_time_from = fields[i].value
                    ? fields[i].value
                    : null;
                }
                if (fields[i].key === "image_time_to") {
                  dto.image_time_to = fields[i].value ? fields[i].value : null;
                }
                if (fields[i].key === "resolution") {
                  if (fields[i].value && fields[i].value.length >= 2) {
                    dto.resolution_from = fields[i].value[0];
                    dto.resolution_to = fields[i].value[1];
                    dto.resolution = fields[i].value;
                  } else {
                    dto.resolution = null;
                    dto.resolution_from = null;
                    dto.resolution_to = null;
                  }
                }
                if (fields[i].key === "incidence_angle") {
                  if (fields[i].value && fields[i].value.length >= 2) {
                    dto.incidence_angle_from = fields[i].value[0];
                    dto.incidence_angle_to = fields[i].value[1];
                    dto.incidence_angle = fields[i].value;
                  } else {
                    dto.incidence_angle = null;
                    dto.incidence_angle_from = null;
                    dto.incidence_angle_to = null;
                  }
                }
                if (fields[i].key === "processing_level") {
                  if (fields[i].value) {
                    dto.processing_level = fields[i].value;
                  } else {
                    dto.processing_level = null;
                  }
                }
                if (fields[i].key === "selectedKey") {
                  if (fields[i].value) {
                    dto.selectedKey = fields[i].value;
                  } else {
                    dto.selectedKey = null;
                  }
                }
                if (fields[i].key === "cloud_cove") {
                  if (fields[i].value && fields[i].value.length >= 2) {
                    dto.cloud_coverage_from = fields[i].value[0];
                    dto.cloud_coverage_to = fields[i].value[1];
                    dto.cloud_cove = fields[i].value;
                  } else {
                    dto.cloud_cove = null;
                    dto.cloud_coverage_from = null;
                    dto.cloud_coverage_to = null;
                  }
                }

                if (fields[i].key === "imaging_mode") {
                  if (fields[i].value) {
                    dto.imaging_mode = fields[i].value;
                  } else {
                    dto.imaging_mode = null;
                  }
                }
                if (fields[i].key === "direction") {
                  if (fields[i].value) {
                    dto.direction = fields[i].value;
                  } else {
                    dto.direction = null;
                  }
                }
                if (fields[i].key === "pol_layer") {
                  if (fields[i].value) {
                    dto.pol_layer = fields[i].value;
                  } else {
                    dto.pol_layer = null;
                  }
                }
                if (fields[i].key === "boundary") {
                  if (fields[i].value) {
                    dto.boundary = fields[i].value;
                  } else {
                    dto.boundary = null;
                  }
                }
              }
              this.$store.dispatch("setSearchDto", dto);
              this.$emit("initCriteria", dto);
              if (dto.boundary && dto.boundary.length > 0) {
                let geojsonObject =
                  `{
                              "type": "FeatureCollection",
                              "features": [
                                {
                                  "type": "Feature",
                                  "properties": {},
                                  "geometry": ` +
                  dto.boundary +
                  `

                                }
                              ]
                          }`;
                let feature3 = new GeoJSON().readFeatures(geojsonObject, {
                  featureProjection: "EPSG:4326",
                });
                if (feature3 && feature3.length > 0) {
                  var geom = [];
                  geom.push(feature3[0]);
                  var writer = new GeoJSON();
                  var geoJsonStr = writer.writeFeatures(geom);
                  this.$emit("onSearch", {
                    feature: new Feature(
                      feature3[0]
                        .getGeometry()
                        .clone()
                        .transform("EPSG:4326", "EPSG:3857")
                    ),
                    value: feature3[0],
                    type: "Query",
                    boundary: dto.boundary,
                  });
                } else {
                  this.$emit("message", [
                    this.$t("message_structure_file_not_correct", null, {
                      name: "geojson",
                    }),
                  ]);
                }
              }
              this.$emit("onSearch");
            }
            break;
        }
        this.close();
      } catch (error) {
        console.log(error);
        let message = "";
        switch (this.selected) {
          case "SHAPE":
            message = this.$t("message_structure_file_not_correct", null, {
              name: "shape",
            });
            break;
          case "Geodatabase":
            message = this.$t("message_structure_file_not_correct", null, {
              name: "geodatabase",
            });
            break;
          case "KML":
            message = this.$t("message_structure_file_not_correct", null, {
              name: "kml",
            });
            break;
          case "CSV":
            message = this.$t("message_structure_file_not_correct", null, {
              name: "csv",
            });
            break;

          case "Geojson":
            message = this.$t("message_structure_file_not_correct", null, {
              name: "geojson",
            });

          case "Query":
            message = this.$t("message_structure_file_not_correct", null, {
              name: "query",
            });
            break;
        }
        this.$emit("message", [message]);
      }
    },
    async readZipFile(file) {
      return new Promise(function (resolve, reject) {
        var vm = this;
        var reader = new FileReader();
        reader.onload = function () {
          shp(this.result).then(function (geojson) {
            if (geojson) {
              resolve(geojson);
            } else resolve(null);
          });
        };
        reader.readAsArrayBuffer(file);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.upload {
  height: 100%;
  width: 100%;
  font-size: 14px;
  .upload__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    .upload__header-title {
      width: 100%;
      margin-bottom: 0;
    }
  }
  .upload__body {
    min-height: 352px;
    max-height: 500px;
    width: 100%;
    overflow-y: auto;
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
    .nav-tabs {
      li {
        margin-bottom: -1px;
      }
    }
    .nav-tabs > li.active > a {
      color: #555;
      cursor: default;
      background-color: #fff;
      border: 1px solid #ddd;
      border-bottom-color: transparent;
    }
    .nav-tabs > li > a {
      margin-right: 2px;
      line-height: 1.42857143;
      border: 1px solid transparent;
      border-radius: 4px 4px 0 0;
      position: relative;
      display: block;
      padding: 10px 15px;
      &:hover {
        text-decoration: none;
        background-color: #eee;
      }
    }
  }
  .upload__footer {
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
    button {
      font-size: 14px;
    }
  }
}
</style>
