<template>
  <div class="Uhe723HDBgs4sd">
    <spinner
      :propLoading="loading.show"
      :propShape="loading.shape"
      :prop-text="loading.text"
      v-if="loading.show"
    ></spinner>

    <div class="openlayer__map" id="openlayer__map"></div>
    <div
      id="mouse-information"
      v-show="mouseCoordinate.lat != 0 && mouseCoordinate.lng != 0"
    >
      <div class="onoffswitch">
        <input
          type="checkbox"
          name="onoffswitch"
          class="onoffswitch-checkbox"
          id="myonoffswitch"
          checked
          v-model="isDD"
        />
        <label class="onoffswitch-label" for="myonoffswitch">
          <span class="onoffswitch-inner"></span>
          <span class="onoffswitch-switch"></span>
        </label>
      </div>
      <span class="mouse-position text-center">
        {{ pointerMove }}
      </span>
    </div>
    <div
      class="basemap-side"
      id="basemap-side"
      v-if="buttonsTool.basemap.active"
    >
      <basemap
        :propData="dataJSON.layers"
        :propShow="buttonsTool.basemap.active"
        @changeOpacity="changeOpacity($event)"
        @showLayer="showLayer($event)"
        @closeBasemapsLayer="closeBasemapsLayer"
      ></basemap>
    </div>

    <div class="controls-left" id="controls-left">
      <ControlsLeft
        :propButtonsTool="buttonsTool"
        :propCartTotal="cart.satellite.items.length"
        :propImagesTotal="
          (data.satellite.value && data.satellite.value.total_records
            ? data.satellite.value.total_records
            : 0) +
          (data.planet.pages && data.planet.items.length
            ? data.planet.items.length
            : 0) +
          (data.oneAtlas.value.pages && data.oneAtlas.value.pages.totalRecords
            ? data.oneAtlas.value.pages.totalRecords
            : 0) +
          (data.capella.value.pages && data.capella.value.pages.totalRecords
            ? data.capella.value.pages.totalRecords
            : 0)
        "
        @leftActionClick="leftActionClick"
        @onSearchCriteria="onSearchCriteria"
        @searchByName="onSearchByName"
        @panToLocation="panToLocation"
        @saveQuery="saveQuery"
      />
    </div>
    <div class="controls-right" id="controls-right">
      <ControlsRight
        :propButtonsTool="buttonsTool"
        @rightActionClick="rightActionClick"
      />
    </div>
    <div class="left-side" id="left-side">
      <left-side
        ref="leftSide"
        :map="map"
        :propData="data"
        :propCart="cart"
        :prop-criteria="criteria4Search"
        @getAllProcessingLevel="processingLevel.items = $event"
        @getAllProvider="provider.items = $event"
        @actionClick="actionClick($event)"
        @pagechanged="pagechanged"
        @pageSizechanged="pageSizechanged"
        @closeWidget="closeWidget"
        @onConfirmOrder="orderImage"
        @message="message = $event"
        @sortChangeSatellite="sortDataOptical"
      ></left-side>
    </div>
    <div
      class="middle-side"
      id="middle-side"
      :style="[
        {
          left:
            middleSidePositionLeft === 'auto'
              ? 'auto'
              : middleSidePositionLeft + 'px',
        },
        { right: middleSidePositionLeft === 'auto' ? '42px' : 'auto' },
      ]"
    >
      <middle-side
        ref="middleSide"
        :map="map"
        @clearSearch="clearSearch"
        @drawEnd="draw = $event"
        @onSearch="onSearch"
        @showAoiInfo="showAoiInfo"
        @message="message = $event"
        @initCriteria="initCriteria"
        @clearAllAoi="clearAllAoi"
        @setAoiFeature="setAoiFeature"
      ></middle-side>
    </div>

    <div class="dialog">
      <DialogDownloadAoi
        v-if="dialogDownloadAoi.show"
        :prop-title="dialogDownloadAoi.title"
        :prop-show="dialogDownloadAoi.show"
        :prop-style="{
          width: dialogDownloadAoi.width,
          overflowY: 'inherit!important',
        }"
        :prop-value="dialogDownloadAoi.value"
        @close="
          dialogDownloadAoi.show = false;
          dialogDownloadAoi.value = null;
        "
        @exportKML="exportKML"
      />
      <div
        class="dialog-optical-image-metadata"
        v-if="dlgOpticalImgMetadata.show"
      >
        <DialogMetadata
          :prop-style="{ width: dlgOpticalImgMetadata.width }"
          :prop-show="dlgOpticalImgMetadata.show"
          :prop-title="dlgOpticalImgMetadata.title"
          :prop-item="dlgOpticalImgMetadata.value"
          @close="
            dlgOpticalImgMetadata.show = false;
            dlgOpticalImgMetadata.value = null;
          "
          @initSuccessPreviewImange="initSuccessPreviewImange"
        />
      </div>
      <div class="dialog-planet-metadata" v-if="dlgPlanetMetadata.show">
        <DialogPlanetMetadata
          :prop-style="{ width: dlgPlanetMetadata.width }"
          :prop-show="dlgPlanetMetadata.show"
          :prop-title="dlgPlanetMetadata.title"
          :prop-item="dlgPlanetMetadata.value"
          @close="
            dlgPlanetMetadata.show = false;
            dlgPlanetMetadata.value = null;
          "
        />
      </div>
      <div class="dialog-oneatlas-metadata" v-if="dlgOneAtlasMetadata.show">
        <DialogOneAtlasMetadata
          :prop-style="{ width: dlgOneAtlasMetadata.width }"
          :prop-show="dlgOneAtlasMetadata.show"
          :prop-title="dlgOneAtlasMetadata.title"
          :prop-item="dlgOneAtlasMetadata.value"
          @close="
            dlgOneAtlasMetadata.show = false;
            dlgOneAtlasMetadata.value = null;
          "
        />
      </div>
      <div class="dialog-capella-metadata" v-if="dlgCapellaMetadata.show">
        <DialogCapellaMetadata
          :prop-style="{ width: dlgCapellaMetadata.width }"
          :prop-show="dlgCapellaMetadata.show"
          :prop-title="dlgCapellaMetadata.title"
          :prop-item="dlgCapellaMetadata.value"
          @close="
            dlgCapellaMetadata.show = false;
            dlgCapellaMetadata.value = null;
          "
        />
      </div>
      <div class="dialog-aoi-confirm" v-if="dialogConfirmAoi.show">
        <DialogConfirmAoi
          :prop-style="{ width: dialogConfirmAoi.width }"
          :prop-show="dialogConfirmAoi.show"
          :prop-images="dialogConfirmAoi.images"
          :prop-aoi-feature="dialogConfirmAoi.aoiFeature"
          @close="
            dialogConfirmAoi.images = [];
            dialogConfirmAoi.aoiFeature = null;
            dialogConfirmAoi.show = false;
          "
          @onConfirmedAoi="onConfirmedAoi"
        />
      </div>
      <div class="dialog-order" v-if="dlgOrder.show">
        <DialogOrder
          ref="dlgOrder"
          :prop-style="{ width: dlgOrder.width }"
          :prop-show="dlgOrder.show"
          :prop-title="dlgOrder.title"
          :prop-item="dlgOrder.value"
          :prop-images="cart.satellite.items"
          :propPriorityLevel="priorityLevel.items"
          :propProcessingLevel="processingLevel.items"
          :prop-button="dlgOrder.button"
          :prop-feature="dlgOrder.feature"
          :prop-planet-images="cart.planet.items ? cart.planet.items : []"
          @close="
            dlgOrder.show = false;
            dlgOrder.value = null;
            dlgOrder.feature = null;
          "
          @getAllPriorityLevel="getAllPriorityLevel"
          @getAllProcessingLevel="getAllProcessingLevel"
          @onOrderGeohub="onOrderGeohub"
          @onOrderPlanet="onOrderPlanet"
          @onOrderOneAtlas="onOrderOneAtlas"
          @onOrderCapella="onOrderCapella"
          @onOrderAll="onOrderAll"
          @showDialogPriceDetail="showDialogPriceDetail"
          @message="message = $event"
        />
        <DialogPriceDetail
          :prop-show="dlgPriceDetaill.show"
          :prop-items="dlgPriceDetaill.items"
          v-if="dlgPriceDetaill.show"
          @close="
            dlgPriceDetaill.show = false;
            dlgPriceDetaill.items = [];
          "
        />
      </div>
      <div class="dialog-tasking">
        <!-- <DialogTasking
          v-if="dialogTasking.show"
          :prop-show="dialogTasking.show"
          @close="dialogTasking.show = false"
          @message="message = $event"
          @confirmOrderTasking="onConfirmOrderTasking"
          ref="dialogTasking"
        /> -->
        <DialogTaskingNew
          v-if="dialogTasking.show"
          :prop-show="dialogTasking.show"
          @close="dialogTasking.show = false"
          @message="message = $event"
          ref="dialogTasking"
        />
      </div>
      <DialogMessage
        v-if="message.length"
        :propTitle="$t('title_message_info')"
        :prop-show="message.length"
        :prop-message="message"
        @close="message = []"
      />

      <DialogConfirm
        v-if="dialogConfirmTasking.show"
        :prop-show="dialogConfirmTasking.show"
        :prop-title="$t('title_message_confirm')"
        :prop-icon="dialogConfirmTasking.icon"
        :prop-message="dialogConfirmTasking.message"
        :textNo="$t('button_text_no')"
        :textYes="$t('button_text_continue')"
        :propStyle="{ width: dialogConfirmTasking.width }"
        @cancel="onCancelConfirmTasking"
        @confirmed="onConfirmedTasking"
      />
      <aoi-info
        v-if="aoiInfo.show"
        :prop-show="aoiInfo.show"
        :client-x="aoiInfo.clientX"
        :client-y="aoiInfo.clientY"
        :prop-info="aoiInfo"
        @showDialogExport="showDialogExport"
        @close="
          aoiInfo.show = false;
          aoiInfo.feature = null;
          aoiInfo.id = null;
        "
        @removeFeature="removeAoi"
      ></aoi-info>
      <drag-resize
        :x="dialog.x"
        :y="dialog.y"
        :w="dialog.w"
        :h="dialog.h"
        v-show="dialog.show"
        @resizing="dialogResizing"
      >
        <out-side
          :title="dialog.title"
          :isMinimize="false"
          @close="dialog.show = false"
        >
          <keep-alive>
            <component
              :is="dialog.component"
              :w="dialog.w"
              :h="dialog.h - 60"
              :propData="dialog.data"
              :propType="dialog.data.type"
            ></component>
          </keep-alive>
        </out-side>
      </drag-resize>
    </div>
  </div>
</template>
<script>
var formatArea = function (polygon) {
  var area = getArea(polygon);
  var output;
  if (area > 10000) {
    output = ((area / 1000000) * 100) / 100 + " " + "km<sup>2</sup>";
  } else {
    output = (area * 100) / 100 + " " + "m<sup>2</sup>";
  }
  return output;
};
import Spinner from "@/components/commons/spinners/Index";
import Basemap from "./mapopenlayer/Basemap";
import MiddleSide from "./mapopenlayer/MiddleSide";
import LeftSide from "./mapopenlayer/LeftSide";
import DragResize from "@/components/commons/drag/DragResize";
import OutSide from "@/components/commons/drag/OutSide";
import DialogImage from "./mapopenlayer/dialog/Image";
import DialogOrder from "./mapopenlayer/dialog/Order";
import DialogPriceDetail from "./mapopenlayer/dialog/order/oneatlas/PriceDetail.vue";
import DialogLogin from "@/components/pages/login/Index";
import DialogTasking from "@/components/pages/home/mapopenlayer/tasking/Tasking";
import DialogTaskingNew from "@/components/pages/home/mapopenlayer/Tasking";

import DialogMessage from "@/components/commons/dialog/MessageBox";
import DialogConfirm from "@/components/commons/dialog/Confirmation2";
import DialogMetadata from "@/components/commons/metadata/Metadata";
import DialogImgSentinelMetadata from "@/components/commons/metadata/MetadataSentinel";
import DialogPlanetMetadata from "@/components/commons/metadata/MetadataPlanet";
import DialogOneAtlasMetadata from "@/components/commons/metadata/MetadataOneAtlas";
import DialogCapellaMetadata from "@/components/commons/metadata/MetadataCapella";
import ControlsRight from "./mapopenlayer/ControlsRight";
import ControlsLeft from "./mapopenlayer/ControlsLeft";
import AoiInfo from "./mapopenlayer/middleside/define/AoiInfo";
import DialogDownloadAoi from "./mapopenlayer/middleside/define/DownloadAoi";
import DialogConfirmAoi from "./mapopenlayer/dialog/AoiConfirm";
import "ol/ol.css";
import Map from "ol/Map.js";
import View from "ol/View";
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Image as ImageLayer,
} from "ol/layer";
import XYZ from "ol/source/XYZ";
import {
  OSM,
  TileImage,
  Vector as VectorSource,
  ImageWMS,
  TileArcGISRest,
} from "ol/source.js";
import { fromLonLat } from "ol/proj";
import { toLonLat } from "ol/proj.js";
import { defaults as defaultControls } from "ol/control";
import { defaults } from "ol/interaction";
import { Style, Fill, Stroke, Circle } from "ol/style.js";
import GeoJSON from "ol/format/GeoJSON";
import { toStringHDMS } from "ol/coordinate";
import { getArea, getLength } from "ol/sphere.js";
import Polygon from "ol/geom/Polygon";
import { transform } from "ol/proj";

import capellaFunc from "@/utils/functions/capella";
import oneAtlasFunc from "@/utils/functions/oneatlas";
import planetFunc from "@/utils/functions/planet";
import geoImageFunc from "@/utils/functions/geoimage";
import cartFunc from "@/utils/functions/cart";
import orderFunc from "@/utils/functions/order";
import imageFunc from "@/utils/functions/image";
import metaKeyFunc from "@/utils/functions/metakey";

import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import moment from "moment";
export default {
  components: {
    Spinner,
    Basemap,
    MiddleSide,
    LeftSide,
    OutSide,
    DragResize,
    DialogMetadata,
    DialogImage,
    DialogLogin,
    DialogMetadata,
    DialogOrder,
    ControlsRight,
    ControlsLeft,
    DialogMessage,
    AoiInfo,
    DialogDownloadAoi,
    DialogImgSentinelMetadata,
    DialogTasking,
    DialogConfirm,
    DialogConfirmAoi,
    DialogPlanetMetadata,
    DialogOneAtlasMetadata,
    DialogCapellaMetadata,
    DialogTaskingNew,
    DialogPriceDetail,
  },
  props: {
    propComponentWidth: {
      type: Number,
      default: 100, // percent
    },
  },
  data() {
    return {
      message: [],
      aoiFeature: null,
      dialogConfirmAoi: {
        show: false,
        images: [],
        aoiFeature: null,
        width: "480px",
      },
      dialogDownloadAoi: {
        show: false,
        title: this.$t("title_export_aoi_to_file"),
        width: 0,
        value: null,
      },
      aoiInfo: {
        show: false,
        clientX: 0,
        clientY: 0,
        feature: null,
        name: null,
        tool: null,
        id: null,
      },
      buttonsTool: {
        criteria: {
          visible: true,
          disable: false,
          active: false,
        },

        satellite: {
          visible: true,
          disable: true,
          active: false,
          processing: false,
        },
        cart: {
          visible: true,
          disable: true,
          active: false,
        },
        saveQuery: {
          visible: true,
          disable: false,
          active: false,
        },
        search: {
          visible: true,
          disable: false,
          active: false,
        },
        basemap: {
          visible: true,
          disable: false,
          active: false,
        },
        fullscreen: {
          visible: true,
          disable: false,
          active: false,
        },
      },
      isOrder: false,
      map: Object,
      mouseCoordinate: {
        lat: 0,
        lng: 0,
      },
      draw: {},
      dataJSON: {
        layers: [],
      },
      layers: [],
      isDD: true,
      dialog: {
        show: false,
        component: "",
        title: "",
        x: 400,
        y: 100,
        w: 400,
        h: 500,
        data: {},
      },
      dialogCart: {
        show: false,
        title: this.$t("title_message_confirm"),
        x: 0,
        y: 0,
        w: 500,
        h: 370,
        data: {
          items: [],
        },
        isResizable: false,
      },
      dlgOpticalImgMetadata: {
        show: false,
        title: "Metadata",
        value: null,
        width: "480px",
      },

      dlgPlanetMetadata: {
        show: false,
        title: "Metadata",
        value: null,
        width: "480px",
      },

      dlgOneAtlasMetadata: {
        show: false,
        title: "Metadata",
        value: null,
        width: "480px",
      },
      dlgCapellaMetadata: {
        show: false,
        title: "Metadata",
        value: null,
        width: "480px",
      },
      dlgOrder: {
        show: false,
        title: this.$t("label_order"),
        value: null,
        width: "480px",
        button: {
          disable: false,
          processing: false,
        },
        feature: null,
      },
      dlgPriceDetaill: {
        show: false,
        title: this.$t("title_price_detail"),
        value: null,
        width: "720",
        feature: null,
        items: [],
      },
      dialogTasking: {
        show: false,
      },

      dialogConfirmTasking: {
        show: false,
        title: this.$t("title_message_confirm"),
        width: 540 + "px",
        value: null,
        icon: "fas fa-question",
        message: null,
      },
      cart: {
        satellite: {
          items: [],
        },
        planet: {
          items: [],
        },
        oneAtlas: {
          items: [],
        },
        capella: {
          items: [],
        },
      },
      criteria4Search: {
        resolution: [0, 40],
        incidence_angle: [0, 90],
        cloud_cove: [0, 100],
        start_time_from: null,
        start_time_to: null,
        selectedKey: null,
        imaging_mode: null,
        pol_layer: null,
        direction: null,
        processing_level: null,
        aoi: null,
        address: {
          name: "",
          boundary: null,
          boundingbox: null,
          item: [],
        },
        partners: ["PLANET", "ONEATLAS", "CAPELLA", "GEOHUB"],
        //partners: ["PLANET", "ONEATLAS", "CAPELLA"],
        partners4Search: ["PLANET", "ONEATLAS", "CAPELLA", "GEOHUB"],
        //partners4Search: ["PLANET", "ONEATLAS", "CAPELLA"],
      },
      data: {
        footprint: {
          clicked: {
            id: "",
            time: null,
            counter: 0,
            class: "",
            myInterval: null,
          },
        },
        satellite: {
          searching: false,
          value: {},
          page: 0,
          size: 100,
          isBottom: false,
          items: [],
          sort: "DEFAULT",
          order_by: null,
          sort_by: null,
          selected: [],
          checkAll: false,
          clicked: {
            id: "",
            time: null,
            counter: 0,
            class: "",
            myInterval: null,
          },
          hover: {
            id: "",
            time: null,
          },
          visible: true,
        },
        planet: {
          searching: false,
          value: {},
          page: 0,
          size: 250,
          isBottom: false,
          items: [],
          sort: "DEFAULT",
          order_by: null,
          sort_by: null,
          selected: [],
          checkAll: false,
          clicked: {
            id: "",
            time: null,
            counter: 0,
            class: "",
            myInterval: null,
          },
          hover: {
            id: "",
            time: null,
          },
          visible: true,
        },
        oneAtlas: {
          searching: false,
          value: {},
          page: 0,
          size: 250,
          isBottom: false,
          items: [],
          sort: "DEFAULT",
          order_by: null,
          sort_by: null,
          selected: [],
          checkAll: false,
          clicked: {
            id: "",
            time: null,
            counter: 0,
            class: "",
            myInterval: null,
          },
          hover: {
            id: "",
            time: null,
          },
          visible: true,
        },
        capella: {
          searching: false,
          value: {},
          page: 0,
          size: 250,
          isBottom: false,
          items: [],
          sort: "DEFAULT",
          order_by: null,
          sort_by: null,
          selected: [],
          checkAll: false,
          clicked: {
            id: "",
            time: null,
            counter: 0,
            class: "",
            myInterval: null,
          },
          hover: {
            id: "",
            time: null,
          },
          visible: true,
          links: [],
        },
        tasking: {
          searching: false,
          visible: true,
        },
      },
      priorityLevel: {
        items: [],
        data: [],
      },
      processingLevel: {
        items: [],
      },
      provider: {
        items: [],
      },
    };
  },
  created() {
    if (this.documentWidth < 576) {
      this.dialogDownloadAoi.width = this.documentWidth + "px";
      this.dialogConfirmTasking.width = this.documentWidth + "px";
    } else {
      this.dialogDownloadAoi.width = "540px";
      this.dialogConfirmTasking.width = "540px";
    }
    this.$store.dispatch("clearSearchDto");
  },
  async mounted() {
    await this.getAllBasemaps();
    if (!this.dataJSON.layers || this.dataJSON.layers.length === 0)
      this.readLocalDataJson();
    var vm = this;
    if (vm.documentWidth < 576) {
      this.dlgOpticalImgMetadata.width = this.documentWidth + "px";
      this.dlgPlanetMetadata.width = this.documentWidth + "px";
      this.dlgOneAtlasMetadata.width = this.documentWidth + "px";
      this.dlgCapellaMetadata.width = this.documentWidth + "px";
      this.dlgOrder.width = this.documentWidth + "px";
      this.dialogConfirmAoi.width = this.documentWidth + "px";
    } else if (vm.documentWidth >= 576 && vm.documentWidth < 768) {
      this.dlgOpticalImgMetadata.width = "540px";
      this.dlgPlanetMetadata.width = "540px";
      this.dlgOneAtlasMetadata.width = "540px";
      this.dlgCapellaMetadata.width = "540px";
      this.dlgOrder.width = "540px";
      this.dialogConfirmAoi.width = "540px";
    } else if (vm.documentWidth >= 768 && vm.documentWidth < 992) {
      this.dlgOpticalImgMetadata.width = "720px";
      this.dlgPlanetMetadata.width = "720px";
      this.dlgOneAtlasMetadata.width = "720px";
      this.dlgCapellaMetadata.width = "720px";
      this.dlgOrder.width = "720px";
      this.dialogConfirmAoi.width = "720px";
    } else {
      this.dlgOpticalImgMetadata.width = "960px";
      this.dlgPlanetMetadata.width = "960px";
      this.dlgOneAtlasMetadata.width = "960px";
      this.dlgCapellaMetadata.width = "960px";
      this.dlgOrder.width = "1140px";
      this.dialogConfirmAoi.width = "720px";
    }
  },
  methods: {
    onConfirmOrderTasking(name) {
      this.dialogConfirmTasking.message = this.$t(
        "message_tasking_confirm_request",
        null,
        {
          name: name,
        }
      );
      this.dialogConfirmTasking.show = true;
    },
    onCancelConfirmTasking() {
      this.dialogConfirmTasking.value = null;
      this.dialogConfirmTasking.message = null;
      this.dialogConfirmTasking.show = false;
    },
    onConfirmedTasking() {
      if (this.$refs.dialogTasking) {
        this.onCancelConfirmTasking();
        this.$refs.dialogTasking.createOrder();
      }
    },
    /*------------- Emit ----------------*/
    initSuccessPreviewImange(data) {
      if (this.$refs.leftSide) {
        if (this.$refs.leftSide.$refs.tabSatellite) {
          this.$refs.leftSide.$refs.tabSatellite.setPreviewImage(data);
        }
      }
    },
    /*------------- End Emit ---------------*/
    async resizeSplitter(values) {
      var vm = this;
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          vm.$refs.splitterRight.changeSize(
            [values[0], values[1]],
            [
              vm.$refs.splitterRight.$el.firstElementChild,
              vm.$refs.splitterRight.$el.lastElementChild,
            ]
          );
          resolve(1);
        }, 8);
      });
    },
    changeOpacity(data) {
      if (Object.keys(data).length > 0) {
        let index = this.dataJSON.layers.findIndex(
          (x) => x.id === data.layer.id
        );
        if (index >= 0) this.dataJSON.layers[index].opacity = data.opacity;

        var collection = this.map.getLayers();
        if (collection) {
          if (collection.array_.length > 0) {
            let indexLayer = collection.array_.findIndex(
              (x) => x.values_.id === data.layer.id
            );

            if (indexLayer >= 0) {
              let tileLayer = collection.array_[indexLayer];
              tileLayer.setOpacity(data.opacity / 100);
            }
          }
        }
      }
    },
    showLayer(data) {
      if (Object.keys(data).length > 0) {
        let index = this.dataJSON.layers.findIndex(
          (x) => x.id === data.layer.id
        );
        this.dataJSON.layers[index].show = data.show;

        var collection = this.map.getLayers();
        if (collection) {
          if (collection.array_.length > 0) {
            let indexLayer = collection.array_.findIndex(
              (x) => x.values_.id === data.layer.id
            );
            if (indexLayer >= 0) {
              let tileLayer = collection.array_[indexLayer];
              tileLayer.setVisible(data.show);
            }
          }
        }
      }
    },
    dialogResizing(val) {
      if (val.height > 0) this.dialog.h = val.height;
      if (val.width > 0) this.dialog.w = val.width;
    },
    dialogCartResizing(val) {
      if (val.height > 0) this.dialogCart.h = val.height;
      if (val.width > 0) this.dialogCart.w = val.width;
    },
    closeBasemapsLayer() {
      this.buttonsTool.basemap.active = false;
    },
    removeAoi(id) {
      if (
        this.aoiInfo.feature &&
        Object.keys(this.aoiInfo.feature).length > 0
      ) {
        if (this.$refs.middleSide) {
          this.$refs.middleSide.removeAoi(id);
        }
        setTimeout(() => {
          this.aoiInfo.feature = null;
          this.aoiInfo.show = false;
        }, 50);
      }
    },
    exportKML() {
      if (
        this.aoiInfo.feature &&
        Object.keys(this.aoiInfo.feature).length > 0
      ) {
        if (this.$refs.middleSide) {
          this.$refs.middleSide.exportKML();
        }
      }
    },
    showDialogExport() {
      if (
        this.aoiInfo.feature &&
        Object.keys(this.aoiInfo.feature).length > 0
      ) {
        this.dialogDownloadAoi.value = this.aoiInfo.feature;
        this.dialogDownloadAoi.show = true;
      }
    },
    setAoiFeature(feature) {
      this.aoiFeature = feature;
    },
    clearAllAoi() {
      this.clearSearch();
      this.aoiFeature = null;
    },
    initCriteria(data) {
      this.criteria4Search = {
        resolution: [0, 40],
        incidence_angle: [0, 90],
        cloud_cove: [0, 100],
        start_time_from: null,
        start_time_to: null,
        selectedKey: null,
        imaging_mode: null,
        pol_layer: null,
        direction: null,
        processing_level: null,
        aoi: null,
        address: {
          name: "",
          boundary: null,
          boundingbox: null,
          item: [],
        },
      };
      if (data && Object.keys(data).length > 0) {
        for (const [key, value] of Object.entries(data)) {
          this.criteria4Search[key] = value;
        }
      }
      if (this.criteria4Search.image_time_from) {
        try {
          this.criteria4Search.start_time_from = new Date(
            this.criteria4Search.image_time_from
          ).getTime();
        } catch (error) {}
      }
      if (this.criteria4Search.image_time_to) {
        try {
          this.criteria4Search.start_time_to = new Date(
            this.criteria4Search.image_time_to
          ).getTime();
        } catch (error) {}
      }
    },
    showAoiInfo(data) {
      this.aoiInfo.clientX = data.clientX;
      this.aoiInfo.clientY = data.clientY;
      this.aoiInfo.feature = data.feature;
      this.aoiInfo.name = data.name;
      this.aoiInfo.tool = data.tool;
      this.aoiInfo.show = data.show;
      this.aoiInfo.id = data.id;
    },
    closeWidget(code) {
      switch (code) {
        case "CRITERIA":
          this.buttonsTool.criteria.active = false;
          break;
        case "SATELLITE":
          this.buttonsTool.satellite.active = false;
          break;
        case "CART":
          this.buttonsTool.cart.active = false;
          break;
      }
    },
    rightActionClick(val) {
      if (val.code) {
        switch (val.code) {
          case "BASEMAPS":
            this.buttonsTool.basemap.active = !this.buttonsTool.basemap.active;
            break;
          case "ZOOM_IN":
            if (this.map.getView().getZoom() < 20)
              this.map.getView().setZoom(this.map.getView().getZoom() + 1);
            break;
          case "ZOOM_OUT":
            if (this.map.getView().getZoom() > 0)
              this.map.getView().setZoom(this.map.getView().getZoom() - 1);
            break;

          case "FULL_SCREEN":
            this.buttonsTool.fullscreen.active =
              !this.buttonsTool.fullscreen.active;
            if (!this.buttonsTool.fullscreen.active) {
              this.$store.dispatch("setIsHeader", true);
            } else {
              this.$store.dispatch("setIsHeader", false);
            }
            setTimeout(() => {
              this.map.updateSize();
            }, 100);
            break;
        }
      }
    },
    onSearchCriteria() {
      if (this.criteria4Search.partners4Search.length === 0) {
        this.message.push(
          this.$t("message_please_select_partner_befor_search")
        );
        return;
      }
      this.criteria4Search.partners = this.criteria4Search.partners4Search;
      if (this.$refs.leftSide) this.$refs.leftSide.tabIndex = 0;
      if (
        this.criteria4Search.start_time_from &&
        this.criteria4Search.start_time_to
      ) {
        let f, t;
        try {
          f = new Date(
            new Date(this.criteria4Search.start_time_from).setHours(0, 0, 0, 0)
          );
        } catch (error) {}
        try {
          t = new Date(
            new Date(this.criteria4Search.start_time_to).setHours(23, 59, 59, 0)
          );
        } catch (error) {}
        if (f && t) {
          if (f.getTime() - t.getTime() > 0) {
            this.message.push(
              this.$t(
                "message_search_to_time_must_greater_than_or_equal_from_time"
              )
            );
            return;
          }
        }
      }
      this.$store
        .dispatch("setSearchDto", {
          image_time_from: this.criteria4Search.start_time_from
            ? new Date(
                new Date(this.criteria4Search.start_time_from).setHours(
                  0,
                  0,
                  0,
                  0
                )
              )
            : null,
          image_time_to: this.criteria4Search.start_time_to
            ? new Date(
                new Date(this.criteria4Search.start_time_to).setHours(
                  23,
                  59,
                  59,
                  0
                )
              )
            : null,
          cloud_coverage_from:
            this.criteria4Search.cloud_cove &&
            this.criteria4Search.cloud_cove.length > 0
              ? this.criteria4Search.cloud_cove[0]
              : 0,
          cloud_coverage_to:
            this.criteria4Search.cloud_cove &&
            this.criteria4Search.cloud_cove.length > 1
              ? this.criteria4Search.cloud_cove[1]
              : null,
          incidence_angle_from:
            this.criteria4Search.incidence_angle &&
            this.criteria4Search.incidence_angle.length > 0
              ? this.criteria4Search.incidence_angle[0]
              : 0,
          incidence_angle_to:
            this.criteria4Search.incidence_angle &&
            this.criteria4Search.incidence_angle.length > 1
              ? this.criteria4Search.incidence_angle[1]
              : null,
          resolution_from:
            this.criteria4Search.resolution &&
            this.criteria4Search.resolution.length > 0
              ? this.criteria4Search.resolution[0]
              : 0,
          resolution_to:
            this.criteria4Search.resolution &&
            this.criteria4Search.resolution.length > 1
              ? this.criteria4Search.resolution[1]
              : null,
          processing_level: this.criteria4Search.processing_level
            ? this.criteria4Search.processing_level
            : null,
          satellites: this.criteria4Search.selectedKey
            ? Object.keys(this.criteria4Search.selectedKey)
            : null,
          selectedKey: this.criteria4Search.selectedKey
            ? this.criteria4Search.selectedKey
            : null,
          pol_layer: this.criteria4Search.pol_layer
            ? this.criteria4Search.pol_layer
            : null,
          direction: this.criteria4Search.direction
            ? this.criteria4Search.direction
            : null,
          imaging_mode: this.criteria4Search.imaging_mode
            ? this.criteria4Search.imaging_mode
            : null,
          text: null,
        })
        .then((r) => {
          this.onSearch();
        });
    },
    leftActionClick(val) {
      if (val.code) {
        switch (val.code) {
          case "SHOW_CRITERIA":
            if (this.$refs.leftSide) {
              if (!this.buttonsTool.criteria.active) {
                this.$refs.leftSide.addWidget({
                  show: true,
                  code: "CRITERIA",
                });
                this.buttonsTool.criteria.active = true;
              } else {
                this.$refs.leftSide.addWidget({
                  show: false,
                  code: "CRITERIA",
                });
                this.buttonsTool.criteria.active = false;
              }
            }
            break;
          case "SHOW_SATELLITE":
            if (this.$refs.leftSide) {
              if (!this.buttonsTool.satellite.active) {
                this.$refs.leftSide.addWidget({
                  show: true,
                  code: "SATELLITE",
                });
                this.buttonsTool.satellite.active = true;
              } else {
                this.$refs.leftSide.addWidget({
                  show: false,
                  code: "SATELLITE",
                });
                this.buttonsTool.satellite.active = false;
              }
            }

            break;
          case "SHOW_CART":
            if (this.$refs.leftSide) {
              if (!this.buttonsTool.cart.active) {
                this.$refs.leftSide.addWidget({
                  show: true,
                  code: "CART",
                });
                this.buttonsTool.cart.active = true;
                this.buttonsTool.cart.disable = false;
              } else {
                this.$refs.leftSide.addWidget({
                  show: false,
                  code: "CART",
                });
                this.buttonsTool.cart.active = false;
              }
            }
            break;
        }
      }
    },
    async actionClick(val) {
      if (val.code === "SHOW_PREVIEW_IMAGE") {
        this.dialog.show = true;
        this.dialog.component = "DialogImage";
        this.dialog.title = this.$t("label_preview");
        this.dialog.h = 350;
        this.dialog.data = {
          item: {
            preview: val.img,
          },
          name: val.name,
        };
      } else if (val.code === "SHOW_METADATA") {
        this.dlgOpticalImgMetadata.value = val.val;
        this.dlgOpticalImgMetadata.show = true;
      } else if (val.code === "SHOW_METADATA_PLANET") {
        this.dlgPlanetMetadata.value = val.val;
        this.dlgPlanetMetadata.show = true;
      } else if (val.code === "SHOW_METADATA_ONEATLAS") {
        this.dlgOneAtlasMetadata.value = val.val;
        this.dlgOneAtlasMetadata.show = true;
      } else if (val.code === "SHOW_METADATA_CAPELLA") {
        this.dlgCapellaMetadata.value = val.val;
        this.dlgCapellaMetadata.show = true;
      } else if (val.code === "ADD_TO_CART") {
        if (this.$store.getters.getAccessToken) {
          let index = -1;
          switch (val.type) {
            case "SATELLITE":
              index = this.cart.satellite.items.findIndex(
                (x) => x.id === val.item.id && x.type === "SATELLITE"
              );
              if (index >= 0) this.cart.satellite.items.splice(index, 1);
              else {
                try {
                  val.item.type = "SATELLITE";
                  this.cart.satellite.items.push(val.item);
                  this.$store.dispatch("setMyCart", [val.item.id]);
                } catch (error) {
                  if (error.response && error.response.status === 401) {
                    //this.refreshToken(this.actionClick, val);
                    if (!this.loggedIn) {
                      this.$store.dispatch("setCallBackFuncs", [
                        {
                          name: "onSeactionClickarch",
                        },
                      ]);
                      this.$store.dispatch("setIsLoginForm", true);
                    }
                  } else {
                    let msg =
                      error.response.data && error.response.data.message
                        ? error.response.data.message
                        : null;
                    this.message.push(
                      msg
                        ? msg
                        : this.$t("message_add_2_cart_error", null, {
                            name: val.item.name,
                          })
                    );
                  }
                }
              }
              if (this.cart.satellite.items.length > 0) {
                this.buttonsTool.cart.disable = false;
                this.buttonsTool.cart.visible = true;
              } else this.buttonsTool.cart.disable = true;
              break;
            case "PLANET":
              index = this.cart.satellite.items.findIndex(
                (x) => x.id === val.item.id && x.type === "PLANET"
              );
              if (index >= 0) {
                this.cart.satellite.items.splice(index, 1);
                let index2 = this.cart.planet.items.findIndex(
                  (x) => x.id === val.item.id
                );
                if (index2 >= 0) this.cart.planet.items.splice(index2, 1);
              } else {
                val.item.type = "PLANET";
                this.cart.satellite.items.push(val.item);
                this.cart.planet.items.push(val.item);
                this.$store.dispatch("setMyCart", [val.item.id]); // Using for api cart
              }

              if (this.cart.satellite.items.length > 0) {
                this.buttonsTool.cart.disable = false;
                this.buttonsTool.cart.visible = true;
              } else this.buttonsTool.cart.disable = true;

              break;
            case "ONEATLAS":
              index = this.cart.satellite.items.findIndex(
                (x) =>
                  x.properties &&
                  x.properties.id === val.item.properties.id &&
                  x.type === "ONEATLAS"
              );
              if (index >= 0) {
                this.cart.satellite.items.splice(index, 1);
                let index2 = this.cart.oneAtlas.items.findIndex(
                  (x) =>
                    x.properties && x.properties.id === val.item.properties.id
                );
                if (index2 >= 0) this.cart.oneAtlas.items.splice(index2, 1);
              } else {
                val.item.type = "ONEATLAS";
                this.cart.satellite.items.push(val.item);
                this.cart.oneAtlas.items.push(val.item);
                this.$store.dispatch("setMyCart", [val.item.properties.id]); // Using for api cart
              }

              if (this.cart.satellite.items.length > 0) {
                this.buttonsTool.cart.disable = false;
                this.buttonsTool.cart.visible = true;
              } else this.buttonsTool.cart.disable = true;
              break;

            case "CAPELLA":
              index = this.cart.satellite.items.findIndex(
                (x) =>
                  x.properties &&
                  x.properties.id === val.item.properties.id &&
                  x.type === "CAPELLA"
              );
              if (index >= 0) {
                this.cart.satellite.items.splice(index, 1);
                let index2 = this.cart.capella.items.findIndex(
                  (x) =>
                    x.properties && x.properties.id === val.item.properties.id
                );
                if (index2 >= 0) this.cart.capella.items.splice(index2, 1);
              } else {
                val.item.type = "CAPELLA";
                this.cart.satellite.items.push(val.item);
                this.cart.capella.items.push(val.item);
                this.$store.dispatch("setMyCart", [val.item.properties.id]); // Using for api cart
              }

              if (this.cart.satellite.items.length > 0) {
                this.buttonsTool.cart.disable = false;
                this.buttonsTool.cart.visible = true;
              } else this.buttonsTool.cart.disable = true;
              break;
          }
        } else {
          this.$store.dispatch("setCallBackFuncs", [
            {
              name: "actionClick",
              arguments: val,
            },
          ]);
          this.$store.dispatch("setIsLoginForm", true);
        }
      } else if (val.code === "REMOVE_CART_ITEM") {
        if (val.type === "SATELLITE") {
          if (this.$refs.leftSide && this.$refs.leftSide.$refs.tabSatellite)
            this.$refs.leftSide.$refs.tabSatellite.removeCartItem(val.val);
        }
        if (val.type === "ONEATLAS") {
          let index = this.cart.oneAtlas.items.findIndex(
            (x) => x.properties.id === val.val
          );
          if (index >= 0) this.cart.oneAtlas.items.splice(index, 1);
          if (this.$refs.leftSide && this.$refs.leftSide.$refs.tabOneAtlas)
            this.$refs.leftSide.$refs.tabOneAtlas.removeCartItem(val.val);
        }
        if (val.type === "PLANET") {
          let index = this.cart.planet.items.findIndex((x) => x.id === val.val);
          if (index >= 0) this.cart.planet.items.splice(index, 1);
          if (this.$refs.leftSide && this.$refs.leftSide.$refs.tabPlanet)
            this.$refs.leftSide.$refs.tabPlanet.removeCartItem(val.val);
        }
      }
    },
    readLocalDataJson: function () {
      var vm = this;
      vm.dataJSON.layers.length = 0;
      $.getJSON("/data/data.json", function (data) {})
        .success(function (data) {
          if (data) {
            if (data.layers) {
              vm.dataJSON.layers.push.apply(vm.dataJSON.layers, data.layers);
              if (!vm.basemap || Object.keys(vm.basemap).length === 0) {
                if (vm.dataJSON.layers && vm.dataJSON.layers.length > 0) {
                  let arrayBasemaps = vm.dataJSON.layers.filter(
                    (x) =>
                      x.show === true && (!x.type || x.type === "TILE_LAYER")
                  );
                  if (arrayBasemaps && arrayBasemaps.length > 0)
                    vm.$store.dispatch(
                      "setBasemapDefault",
                      arrayBasemaps[arrayBasemaps.length - 1]
                    );
                }
              }
            }
          }
        })
        .error(function () {})
        .complete(function () {
          vm.initLayers();
        });
    },
    async getAllBasemaps() {
      try {
        let response = await metaKeyFunc.getByKey(
          "BASEMAPS",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            let data = response.data.data ? response.data.data : [];
            for (let i = 0; i < data.length; i++) {
              try {
                let obj = JSON.parse(data[i].value);
                if (obj && Object.keys(obj).length > 0) {
                  this.dataJSON.layers.push({
                    id: data[i].id,
                    type:
                      obj.type && obj.type === "shapefile"
                        ? "JSON_FILE"
                        : "TILE_LAYER",
                    name: data[i].name,
                    title: obj.name ? obj.name : obj.NAME,
                    value: data[i].value,
                    description: data[i].description,
                    id:
                      new Date().getTime() -
                      Math.floor(Math.random() * 99999) +
                      Math.floor(Math.random() * 10000),
                    show:
                      (obj.is_show && obj.is_show + "" === "1") ||
                      (obj.IS_SHOW && obj.IS_SHOW + "" === "1")
                        ? true
                        : false,
                    opacity: 100,
                    basemap: true,
                    source: {
                      type: "TILE_IMAGE",
                      url: obj.path ? obj.path : obj.PATH,
                    },
                    fill: obj.fill ? obj.fill : null,
                    fill_opacity: obj.fill_opacity ? obj.fill_opacity : null,
                    stroke: obj.stroke ? obj.stroke : null,
                    stroke_opacity: obj.stroke_opacity
                      ? obj.stroke_opacity
                      : null,
                  });
                  if (obj.type && obj.type === "shapefile") {
                    try {
                      this.dataJSON.layers[i].geojsonObject = JSON.parse(
                        obj.geojson
                      );
                    } catch (errorParse) {}
                  }
                }
              } catch (error) {}
            }
            if (!this.basemap || Object.keys(this.basemap).length === 0) {
              if (this.dataJSON.layers && this.dataJSON.layers.length > 0) {
                let arrayBasemaps = this.dataJSON.layers.filter(
                  (x) => x.show === true && (!x.type || x.type === "TILE_LAYER")
                );
                if (arrayBasemaps && arrayBasemaps.length > 0)
                  this.$store.dispatch(
                    "setBasemapDefault",
                    arrayBasemaps[arrayBasemaps.length - 1]
                  );
              }
            }
            this.initLayers();
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          //this.refreshToken(this.getAllBasemaps);
        }
      }
    },
    async initLayers() {
      try {
        if (this.dataJSON.layers.findIndex((x) => x.id === "") < 0) {
          this.dataJSON.layers.push({
            id: "layerdraw",
            label: "AOI layer",
            show: true,
            opacity: 100,
            title: "AOI layer",
          });
        }
        for (let i = 0; i < this.dataJSON.layers.length; i++) {
          let type = Object(this.dataJSON.layers[i])["type"];
          let layer = undefined;
          switch (type) {
            case "TILE_LAYER":
              layer = await this.registerTileLayer(this.dataJSON.layers[i]);
              break;
            case "SHAPE_FILE":
              layer = await this.registerShapeFile(this.dataJSON.layers[i]);
              break;
            case "JSON_FILE":
              layer = await this.registerJsonFile(this.dataJSON.layers[i]);
              break;
            case "WMS_LAYER":
              layer = await this.registerWmsLayer(this.dataJSON.layers[i]);
              break;
            case "ARCGIS_MAPSERVICE":
              layer = await this.registerArcgisMapservice(
                this.dataJSON.layers[i]
              );
              break;
          }
          if (layer) {
            layer.set("id", this.dataJSON.layers[i].id);
            this.layers.push(layer);
          }
        }
        this.initOpenlayerMap();
      } catch (error) {}
    },
    registerTileLayer(layer) {
      return new Promise(function (resolve, reject) {
        try {
          if (layer.title === "OpenStreetMap") {
            resolve(
              new TileLayer({
                source: new OSM(),
                opacity: Object(layer).opacity,
                visible: Object(layer).show,
              })
            );
          } else {
            var obj = {
              title: Object(layer)["title"],
              id: Object(layer).id,
              source: new XYZ({
                url: Object(layer).source.url,
              }),
              show: Object(layer).show,
              opacity: Object(layer).opacity,
              visible: Object(layer).show,
            };
            resolve(new TileLayer(obj));
          }
        } catch (error) {
          resolve(null);
        }
      });
    },
    registerShapeFile(layer) {
      try {
        return new Promise(function (resolve, reject) {
          var vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(layer.geojsonObject, {
              dataProjection: layer.dataProjection,
              featureProjection: "EPSG:3857",
            }),
          });
          let vector = new VectorLayer({
            source: vectorSource,
            style: [
              new Style({
                image: new Circle({
                  fill: new Fill({
                    color: "rgba(255,0,0,0.2)",
                  }),
                  stroke: new Stroke({
                    color: "#FF0000",
                    width: 1.25,
                  }),
                  radius: 5,
                }),
                fill: new Fill({
                  color: "rgba(255,0,0,0.2)",
                }),
                stroke: new Stroke({
                  color: "#FF0000",
                  width: 1.25,
                }),
              }),
            ],
          });
          resolve(vector);
        });
      } catch (error) {
        resolve(null);
      }
    },
    registerJsonFile(layer) {
      try {
        var vm = this;
        return new Promise(function (resolve, reject) {
          var vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(layer.geojsonObject, {
              dataProjection: layer.dataProjection,
              featureProjection: "EPSG:3857",
            }),
          });
          let vector = new VectorLayer({
            source: vectorSource,
            style: new Style({
              image: new Circle({
                fill: new Fill({
                  color: layer.stroke
                    ? vm.hexToRgbA(layer.fill, layer.fill_opacity)
                    : null,
                }),
                stroke: new Stroke({
                  color: layer.stroke
                    ? vm.hexToRgbA(layer.stroke, layer.stroke_opacity)
                    : null,
                  width: 1.25,
                }),
                radius: 5,
              }),
              fill: new Fill({
                color: layer.stroke
                  ? vm.hexToRgbA(layer.fill, layer.fill_opacity)
                  : null,
              }),
              stroke: new Stroke({
                color: layer.stroke
                  ? vm.hexToRgbA(layer.stroke, layer.stroke_opacity)
                  : null,
                width: 1.25,
              }),
            }),
          });
          vector.setVisible(layer.show);
          resolve(vector);
        });
      } catch (error) {
        resolve(null);
      }
    },
    registerWmsLayer(layer) {
      return new Promise(function (resolve, reject) {
        try {
          var obj = {
            title: Object(layer)["title"],
            id: Object(layer).id,
            source: new ImageWMS({
              url: Object(layer).source.url,
              params: Object(layer).source.params,
            }),
            show: Object(layer).show,
            opacity: Object(layer).opacity,
          };
          resolve(new ImageLayer(obj));
        } catch (error) {
          resolve(null);
        }
      });
    },
    registerArcgisMapservice(layer) {
      try {
        return new Promise(function (resolve, reject) {
          resolve(
            new TileLayer({
              title: Object(layer)["title"],
              id: Object(layer).id,
              source: new TileArcGISRest({
                url: layer.source.url,
                params: layer.source.params,
              }),
              show: Object(layer).show,
              opacity: Object(layer).opacity,
            })
          );
        });
      } catch (error) {
        resolve(null);
      }
    },
    hexToRgbA(hex, opacity) {
      if (opacity === null || opacity === undefined) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
    initOpenlayerMap() {
      try {
        var vm = this;

        var sourceFootprint = new VectorSource({
          wrapX: false,
          id: "sourceFootprint",
        });
        var vectorFootprint = new VectorLayer({
          source: sourceFootprint,
        });
        vectorFootprint.set("id", "layerdrawsatellite");
        vm.layers.push(vectorFootprint);
        vectorFootprint.setZIndex(vm.layers.length + 1);

        var sourceFootprintHub = new VectorSource({
          wrapX: false,
          id: "sourceFootprintHub",
        });
        var vectorFootprintHub = new VectorLayer({
          source: sourceFootprintHub,
        });
        vectorFootprintHub.set("id", "layerdrawFootprintHub");
        vm.layers.push(vectorFootprintHub);
        vectorFootprintHub.setZIndex(vm.layers.length + 2);

        var sourceImage = new VectorSource({ wrapX: false, id: "sourceimage" });
        var vectorImage = new VectorLayer({
          source: sourceImage,
        });
        vectorImage.set("id", "layerdrawimage");
        vm.layers.push(vectorImage);
        vectorImage.setZIndex(vm.layers.length + 3);

        var sourceImageHub = new VectorSource({
          wrapX: false,
          id: "sourceimagehub",
        });
        var vectorImageHub = new VectorLayer({
          source: sourceImageHub,
        });
        vectorImageHub.set("id", "layerdrawimagehub");
        vm.layers.push(vectorImageHub);
        vectorImageHub.setZIndex(vm.layers.length + 4);

        var sourceMeasure = new VectorSource({
          wrapX: false,
          id: "sourcemeasure",
        });

        var vectorMeasure = new VectorLayer({
          source: sourceMeasure,
        });
        vectorMeasure.set("id", "layermeasure");
        vectorMeasure.setZIndex(vm.layers.length + 5);
        vm.layers.push(vectorMeasure);

        var source = new VectorSource({ wrapX: false, id: "sourcedraw" });

        var vector = new VectorLayer({
          source: source,
        });
        vector.set("id", "layerdraw");
        vm.layers.push(vector);
        vector.setZIndex(2);
        let _zoom = localStorage.getItem("zoom");
        if (!_zoom) _zoom = 6;
        try {
          _zoom = parseFloat(_zoom);
        } catch (errZoom) {
          _zoom = 6;
        }
        let _center = [localStorage.getItem("center")];
        if (!_center || _center.length === 0)
          _center = fromLonLat([108.222848, 16.062833]);
        try {
          if (!_center[0]) _center[0] = "";
          let centerLen = _center[0].split(",");
          if (centerLen.length != 2)
            _center = fromLonLat([108.222848, 16.062833]);
          else {
            try {
              let centerLng = parseFloat(centerLen[0]);
              let centerLat = parseFloat(centerLen[1]);
              if (isNaN(centerLng) || isNaN(centerLat))
                _center = fromLonLat([108.222848, 16.062833]);
              else _center = [centerLng, centerLat];
            } catch (errorPaser) {
              _center = fromLonLat([108.222848, 16.062833]);
            }
          }
        } catch (errorCenter) {
          _center = fromLonLat([108.222848, 16.062833]);
        }
        vm.map = new Map({
          controls: defaultControls({
            attribution: false,
            zoom: false,
          }),
          target: "openlayer__map",
          interactions: defaults({ doubleClickZoom: false }),
          layers: vm.layers,
          view: new View({
            zoom: _zoom,
            center: _center,
          }),
        });
        var selected = null;
        var highlightStyle = new Style({
          fill: new Fill({
            color: "rgba(255,165,0,.5)",
          }),
          stroke: new Stroke({
            color: "orange",
            width: 1.25,
          }),
        });
        var defaultStyle = new Style({
          fill: new Fill({
            color: "rgba(0,158,247,0.3)", //"transparent",
          }),
          stroke: new Stroke({
            color: "rgba(0,158,247,1)",
            width: 1.25,
          }),
        });

        this.map.on("pointermove", pointerMove);
        function pointerMove(evt) {
          let coordinate = toLonLat(evt.coordinate);
          vm.mouseCoordinate.lat = coordinate[1];
          vm.mouseCoordinate.lng = coordinate[0];
          if (selected !== null) {
            selected.setStyle(defaultStyle);
            selected = null;
          }
          vm.map.forEachFeatureAtPixel(evt.pixel, function (f) {
            let id = f.get("id");
            if (id && id + "") {
              if (
                (id + "").startsWith("IMAGE_") ||
                (id + "").startsWith("PLANET_") ||
                (id + "").startsWith("ONEATLAS_") ||
                (id + "").startsWith("FOOTPRINT_")
              ) {
                selected = f;
                f.setStyle(highlightStyle);
              }
            }

            return true;
          });

          vm.map.getTargetElement().style.cursor = selected ? "pointer" : "";
        }
        this.map.on("click", function (e) {
          if (selected) {
            let id = selected.get("id");
            if (id) {
              if (id.startsWith("IMAGE_")) {
                if (vm.data.satellite.clicked.id === id) return;
                vm.data.satellite.clicked.id = id;
                vm.data.satellite.clicked.time = new Date().getTime();
                vm.data.satellite.clicked.counter = 0;
                vm.data.satellite.clicked.class = "active";
                if (vm.data.satellite.clicked.myInterval)
                  clearInterval(vm.data.satellite.clicked.myInterval);
                vm.data.satellite.clicked.myInterval = setInterval(
                  interval,
                  2000
                );
              } else if (id.startsWith("PLANET_")) {
                if (vm.data.planet.clicked.id === id) return;
                vm.data.planet.clicked.id = id;
                vm.data.planet.clicked.time = new Date().getTime();
                vm.data.planet.clicked.counter = 0;
                vm.data.planet.clicked.class = "active";
                if (vm.data.planet.clicked.myInterval)
                  clearInterval(vm.data.planet.clicked.myInterval);
                vm.data.planet.clicked.myInterval = setInterval(
                  intervalPlanet,
                  2000
                );
              } else if (id.startsWith("ONEATLAS_")) {
                if (vm.data.oneAtlas.clicked.id === id) return;
                vm.data.oneAtlas.clicked.id = id;
                vm.data.oneAtlas.clicked.time = new Date().getTime();
                vm.data.oneAtlas.clicked.counter = 0;
                vm.data.oneAtlas.clicked.class = "active";
                if (vm.data.oneAtlas.clicked.myInterval)
                  clearInterval(vm.data.oneAtlas.clicked.myInterval);
                vm.data.oneAtlas.clicked.myInterval = setInterval(
                  intervalOneAtlas,
                  2000
                );
              } else if (id.startsWith("FOOTPRINT_")) {
                if (vm.data.footprint.clicked.id === id) return;
                vm.data.footprint.clicked.id = id;
                vm.data.footprint.clicked.time = new Date().getTime();
                vm.data.footprint.clicked.counter = 0;
                vm.data.footprint.clicked.class = "active";
                if (vm.data.footprint.clicked.myInterval)
                  clearInterval(vm.data.footprint.clicked.myInterval);
                vm.data.footprint.clicked.myInterval = setInterval(
                  intervalFootprint,
                  2000
                );
              }
            } else {
              vm.data.satellite.clicked.id = "";
              vm.data.satellite.clicked.time = null;
              vm.data.satellite.clicked.counter = 0;
              vm.data.satellite.clicked.class = "";
              vm.data.satellite.clicked.myInterval = null;

              vm.data.footprint.clicked.id = "";
              vm.data.footprint.clicked.time = null;
              vm.data.footprint.clicked.counter = 0;
              vm.data.footprint.clicked.class = "";
              vm.data.footprint.clicked.myInterval = null;
            }
          }
        });

        function interval() {
          vm.data.satellite.clicked.counter =
            vm.data.satellite.clicked.counter + 1;
          if (vm.data.satellite.clicked.counter > 4)
            vm.data.satellite.clicked.class = "fadeOut";
          if (vm.data.satellite.clicked.counter > 5) {
            if (vm.data.satellite.clicked.myInterval) {
              vm.data.satellite.clicked.id = "";
              vm.data.satellite.clicked.time = null;
              vm.data.satellite.clicked.counter = 0;
              vm.data.satellite.clicked.class = "";
              clearInterval(vm.data.satellite.clicked.myInterval);
              vm.data.satellite.clicked.myInterval = null;
            }
          }
        }
        function intervalPlanet() {
          vm.data.planet.clicked.counter = vm.data.planet.clicked.counter + 1;
          if (vm.data.planet.clicked.counter > 4)
            vm.data.planet.clicked.class = "fadeOut";
          if (vm.data.planet.clicked.counter > 5) {
            if (vm.data.planet.clicked.myInterval) {
              vm.data.planet.clicked.id = "";
              vm.data.planet.clicked.time = null;
              vm.data.planet.clicked.counter = 0;
              vm.data.planet.clicked.class = "";
              clearInterval(vm.data.planet.clicked.myInterval);
              vm.data.planet.clicked.myInterval = null;
            }
          }
        }
        function intervalOneAtlas() {
          vm.data.oneAtlas.clicked.counter =
            vm.data.oneAtlas.clicked.counter + 1;
          if (vm.data.oneAtlas.clicked.counter > 4)
            vm.data.oneAtlas.clicked.class = "fadeOut";
          if (vm.data.oneAtlas.clicked.counter > 5) {
            if (vm.data.oneAtlas.clicked.myInterval) {
              vm.data.oneAtlas.clicked.id = "";
              vm.data.oneAtlas.clicked.time = null;
              vm.data.oneAtlas.clicked.counter = 0;
              vm.data.oneAtlas.clicked.class = "";
              clearInterval(vm.data.oneAtlas.clicked.myInterval);
              vm.data.oneAtlas.clicked.myInterval = null;
            }
          }
        }

        function intervalFootprint() {
          vm.data.footprint.clicked.counter =
            vm.data.footprint.clicked.counter + 1;
          if (vm.data.footprint.clicked.counter > 4)
            vm.data.footprint.clicked.class = "fadeOut";
          if (vm.data.footprint.clicked.counter > 5) {
            if (vm.data.footprint.clicked.myInterval) {
              vm.data.footprint.clicked.id = "";
              vm.data.footprint.clicked.time = null;
              vm.data.footprint.clicked.counter = 0;
              vm.data.footprint.clicked.class = "";
              clearInterval(vm.data.footprint.clicked.myInterval);
              vm.data.footprint.clicked.myInterval = null;
            }
          }
        }
      } catch (error) {}
    },
    saveQuery() {
      if (this.$refs.leftSide) {
        this.$refs.leftSide.saveQuery();
      }
    },
    panToLocation(val) {
      if (val.lat === null && val.lng === null) return;
      if (Object.keys(this.map).length > 0) {
        this.map.getView().animate({
          center: fromLonLat([val.lng, val.lat]),
          duration: 2000,
          zoom:
            this.map.getView().getZoom() < 16
              ? 16
              : this.map.getView().getZoom(),
        });
      }
    },
    pageSizechanged(val) {
      switch (val.tab) {
        case "SATELLITE":
          this.data.satellite.page = 0;
          this.data.satellite.size = val.val;
          this.searchSatellitesOptical(this.criteria);
          break;
      }
    },
    pagechanged(val) {
      switch (val.tab) {
        case "SATELLITE":
          if (!this.data.satellite.isBottom) {
            this.data.satellite.page = val.val;
            this.searchImages(this.criteria);
          }
          break;
        case "PLANET":
          if (!this.data.planet.isBottom) {
            this.data.planet.page = val.val;
            this.planetSearchImages(this.criteria, true);
          }
          break;
        case "ONEATLAS":
          if (!this.data.oneAtlas.isBottom) {
            this.data.oneAtlas.page = val.val;
            this.oneAtlasSearchImages(this.criteria);
          }
          break;
        case "CAPELLA":
          if (!this.data.capella.isBottom) {
            this.data.capella.page = val.val;
            this.capellaSearchImages(this.criteria);
          }
          break;
      }
    },
    clearSearch() {
      if (this.$refs.leftSide && this.data.satellite.page === 0) {
        this.$refs.leftSide.clearLayer();
      }
      if (this.objsImage.length === 0)
        this.$store.dispatch("setObjectImage", []);
      this.data.satellite.order_by = null;
      this.data.satellite.sort_by = null;
      this.data.satellite.sort = "DEFAULT";
      this.data.satellite.value = {};
      this.data.satellite.items = [];
      this.data.satellite.checkAll = false;
      this.data.satellite.selected = [];
      this.data.satellite.page = 0;
      this.data.satellite.isBottom = false;

      this.data.planet.order_by = null;
      this.data.planet.sort_by = null;
      this.data.planet.sort = "DEFAULT";
      this.data.planet.value = {};
      this.data.planet.items = [];
      this.data.planet.checkAll = false;
      this.data.planet.selected = [];
      this.data.planet.page = 0;
      this.data.planet.isBottom = false;

      this.data.oneAtlas.order_by = null;
      this.data.oneAtlas.sort_by = null;
      this.data.oneAtlas.sort = "DEFAULT";
      this.data.oneAtlas.value = {};
      this.data.oneAtlas.items = [];
      this.data.oneAtlas.checkAll = false;
      this.data.oneAtlas.selected = [];
      this.data.oneAtlas.page = 0;
      this.data.oneAtlas.isBottom = false;
    },
    async onSearchByName(data) {
      if (this.criteria4Search.partners4Search.length === 0) {
        this.message.push(
          this.$t("message_please_select_partner_befor_search")
        );
        return;
      }
      if (this.$refs.leftSide) {
        this.$refs.leftSide.addWidget({
          show: true,
          code: "SATELLITE",
        });
      }
      if (this.objsImage.length === 0)
        this.$store.dispatch("setObjectImage", [
          "SATELLITE",
          "PLANET",
          "ONEATLAS",
          "CAPELLA",
        ]);
      this.data.satellite.order_by = null;
      this.data.satellite.sort_by = null;
      this.data.satellite.sort = "DEFAULT";
      this.data.satellite.value = {};
      this.data.satellite.items = [];
      this.data.satellite.checkAll = false;
      this.data.satellite.selected = [];
      this.data.satellite.page = 0;
      this.data.satellite.isBottom = false;

      this.data.planet.order_by = null;
      this.data.planet.sort_by = null;
      this.data.planet.sort = "DEFAULT";
      this.data.planet.value = {};
      this.data.planet.items = [];
      this.data.planet.checkAll = false;
      this.data.planet.selected = [];
      this.data.planet.page = 0;
      this.data.planet.isBottom = false;

      this.data.oneAtlas.order_by = null;
      this.data.oneAtlas.sort_by = null;
      this.data.oneAtlas.sort = "DEFAULT";
      this.data.oneAtlas.value = {};
      this.data.oneAtlas.items = [];
      this.data.oneAtlas.checkAll = false;
      this.data.oneAtlas.selected = [];
      this.data.oneAtlas.page = 0;
      this.data.oneAtlas.isBottom = false;
      this.searchImages({
        text: data.name,
      });
    },
    async onSearch() {
      if (this.$refs.leftSide) {
        this.$refs.leftSide.addWidget({
          show: true,
          code: "SATELLITE",
        });
      }
      if (this.objsImage.length === 0)
        this.$store.dispatch("setObjectImage", [
          "SATELLITE",
          "PLANET",
          "ONEATLAS",
          "CAPELLA",
        ]);
      this.data.satellite.order_by = null;
      this.data.satellite.sort_by = null;
      this.data.satellite.sort = "DEFAULT";
      this.data.satellite.value = {};
      this.data.satellite.items = [];
      this.data.satellite.checkAll = false;
      this.data.satellite.selected = [];
      this.data.satellite.page = 0;
      this.data.satellite.isBottom = false;

      this.data.planet.order_by = null;
      this.data.planet.sort_by = null;
      this.data.planet.sort = "DEFAULT";
      this.data.planet.value = {};
      this.data.planet.items = [];
      this.data.planet.checkAll = false;
      this.data.planet.selected = [];
      this.data.planet.page = 0;
      this.data.planet.isBottom = false;

      this.data.oneAtlas.order_by = null;
      this.data.oneAtlas.sort_by = null;
      this.data.oneAtlas.sort = "DEFAULT";
      this.data.oneAtlas.value = {};
      this.data.oneAtlas.items = [];
      this.data.oneAtlas.checkAll = false;
      this.data.oneAtlas.selected = [];
      this.data.oneAtlas.page = 0;
      this.data.oneAtlas.isBottom = false;

      this.data.capella.order_by = null;
      this.data.capella.sort_by = null;
      this.data.capella.sort = "DEFAULT";
      this.data.capella.value = {};
      this.data.capella.items = [];
      this.data.capella.checkAll = false;
      this.data.capella.selected = [];
      this.data.capella.page = 0;
      this.data.capella.isBottom = false;

      if (this.criteria4Search.partners.includes("GEOHUB"))
        this.searchImages(this.criteria);
      if (this.criteria4Search.partners.includes("PLANET")) {
        this.planetSearchImages(this.criteria);
      }
      if (this.criteria4Search.partners.includes("ONEATLAS"))
        this.oneAtlasSearchImages(this.criteria);
      if (this.criteria4Search.partners.includes("CAPELLA"))
        this.capellaSearchImages(this.criteria);
    },

    // search image from capella api
    async capellaSearchImages(objData) {
      if (this.data.capella.isBottom) return;
      let payload = {
        intersects: objData.boundary
          ? {
              type: "Polygon",
              coordinates: JSON.parse(objData.boundary).coordinates,
            }
          : {},
        limit: 250,
        page:
          !this.data.capella.page || this.data.capella.page + "" == "0"
            ? 0
            : this.data.capella.page,
        cloud_coverage_from:
          objData.cloud_coverage_from != null &&
          objData.cloud_coverage_from != undefined
            ? objData.cloud_coverage_from
            : null,
        cloud_coverage_to: objData.cloud_coverage_to
          ? objData.cloud_coverage_to
          : null,
        image_time_from: objData.image_time_from
          ? objData.image_time_from
          : null,
        image_time_to: objData.image_time_to ? objData.image_time_to : null,
        incidence_angle_from:
          objData.incidence_angle_from != null &&
          objData.incidence_angle_from != undefined
            ? objData.incidence_angle_from
            : null,
        incidence_angle_to: objData.incidence_angle_to
          ? objData.incidence_angle_to
          : null,
        resolution_from:
          objData.resolution_from != null &&
          objData.resolution_from != undefined
            ? objData.resolution_from
            : null,
        resolution_to: objData.resolution_to ? objData.resolution_to : null,
      };

      let timeZoneOffset = new Date().getTimezoneOffset();
      if (objData.image_time_from) {
        let from = new Date(objData.image_time_from);
        from = new Date(from.setHours(from.getHours() + timeZoneOffset / -60));
        payload.datetime = from.toISOString();
      }
      if (objData.image_time_to) {
        let to = new Date(objData.image_time_to);
        to = new Date(to.setHours(to.getHours() + timeZoneOffset / -60));
        payload.datetime =
          (payload.datetime ? payload.datetime : "") + "/" + to.toISOString();
      }
      try {
        this.data.capella.searching = true;
        let response = await capellaFunc.search(
          payload,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          let data =
            response.data && response.data.data && response.data.data.capella
              ? response.data.data.capella
              : {};
          data.results &&
            (this.data.capella.items = [
              ...this.data.capella.items,
              ...data.results,
            ]);

          // this.data.capella.links = response.data.links;
          // if (response.data.links && response.data.links.length === 0)
          //   this.data.capella.isBottom = true;
          // else {
          //   if (this.data.capella.items.length === response.data.numberMatched)
          //     this.data.capella.isBottom = true;
          // }

          if (this.data.capella.items.length >= data?.pages?.totalRecords)
            this.data.capella.isBottom = true;

          this.data.capella.value = data;
          if (this.data.capella.checkAll) {
            for (let i = 0; i < data.results.length; i++) {
              if (!this.data.capella.selected.includes(data.results[i].id))
                this.data.capella.selected.push(data.results[i].id);
            }
          }
        }

        this.data.capella.searching = false;
      } catch (error) {
        this.data.capella.searching = false;
        if (error && error.response && error.response.status === 401) {
          if (!this.loggedIn) {
            this.$store.dispatch("setCallBackFuncs", [
              {
                name: "onSearch",
              },
            ]);
            this.$store.dispatch("setIsLoginForm", true);
          }
        } else if (error && error.response && error.response.status === 404) {
          this.data.capella.isBottom = true;
        } else {
          let msg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "";
          if (msg) this.message.push(msg);
        }
      }
    },
    // end search image from oneatlas api
    // search images from planet api
    // end search image from capella api
    // search image from oneatlas api
    async oneAtlasSearchImages(objData) {
      if (this.data.oneAtlas.isBottom || this.data.oneAtlas.searching) return;
      try {
        this.data.oneAtlas.searching = true;
        let response = await oneAtlasFunc.search(
          {
            boundary: objData.boundary
              ? JSON.parse(objData.boundary).coordinates[0]
              : null,
            cloud_coverage_from:
              objData.cloud_coverage_from != null &&
              objData.cloud_coverage_from != undefined
                ? objData.cloud_coverage_from
                : null,
            cloud_coverage_to: objData.cloud_coverage_to
              ? objData.cloud_coverage_to
              : null,
            image_time_from: objData.image_time_from
              ? objData.image_time_from
              : null,
            image_time_to: objData.image_time_to ? objData.image_time_to : null,
            incidence_angle_from:
              objData.incidence_angle_from != null &&
              objData.incidence_angle_from != undefined
                ? objData.incidence_angle_from
                : null,
            incidence_angle_to: objData.incidence_angle_to
              ? objData.incidence_angle_to
              : null,
            resolution_from:
              objData.resolution_from != null &&
              objData.resolution_from != undefined
                ? objData.resolution_from
                : null,
            resolution_to: objData.resolution_to ? objData.resolution_to : null,
            processing_level: objData.processing_level
              ? objData.processing_level
              : null,
            text: objData.text ? objData.text : null,
            satellite_ids: objData.selectedKey
              ? Object.keys(objData.selectedKey)
              : null,
            page: this.data.oneAtlas.page,
            page_size: this.data.oneAtlas.size,

            processingLevel: "SENSOR",
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          let data =
            response.data && response.data.data && response.data.data.oneAtlas
              ? response.data.data.oneAtlas
              : {};
          data.results &&
            (this.data.oneAtlas.items = [
              ...this.data.oneAtlas.items,
              ...data.results,
            ]);

          if (this.data.oneAtlas.items.length >= data?.pages?.totalRecords)
            this.data.oneAtlas.isBottom = true;
          this.data.oneAtlas.value = data;
          if (this.data.oneAtlas.checkAll) {
            for (let i = 0; i < data.results.length; i++) {
              if (!this.data.oneAtlas.selected.includes(data.results[i]._id))
                this.data.oneAtlas.selected.push(data.results[i]._id);
            }
          }
        }

        this.data.oneAtlas.searching = false;
      } catch (error) {
        console.log(error);
        this.data.oneAtlas.searching = false;
        if (error && error.response && error.response.status === 401) {
          if (!this.loggedIn) {
            this.$store.dispatch("setCallBackFuncs", [
              {
                name: "onSearch",
              },
            ]);
            this.$store.dispatch("setIsLoginForm", true);
          }
        } else if (error.response.status === 404) {
          this.data.oneAtlas.isBottom = true;
        } else {
          let msg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "";
          if (msg) this.message.push(msg);
        }
      }
    },
    // end search image from oneatlas api
    // search images from planet api
    async planetSearchImages(objData, nextPage = false) {
      if (this.data.planet.isBottom || this.data.planet.searching) return;

      this.data.planet.searching = true;
      try {
        let response = await planetFunc.search(
          {
            boundary: objData.boundary
              ? JSON.parse(objData.boundary).coordinates[0]
              : null,
            cloud_coverage_from:
              objData.cloud_coverage_from != null &&
              objData.cloud_coverage_from != undefined
                ? objData.cloud_coverage_from
                : null,
            cloud_coverage_to: objData.cloud_coverage_to
              ? objData.cloud_coverage_to
              : null,
            image_time_from: objData.image_time_from
              ? objData.image_time_from
              : null,
            image_time_to: objData.image_time_to ? objData.image_time_to : null,
            incidence_angle_from:
              objData.incidence_angle_from != null &&
              objData.incidence_angle_from != undefined
                ? objData.incidence_angle_from
                : null,
            incidence_angle_to: objData.incidence_angle_to
              ? objData.incidence_angle_to
              : null,
            resolution_from:
              objData.resolution_from != null &&
              objData.resolution_from != undefined
                ? objData.resolution_from
                : null,
            resolution_to: objData.resolution_to ? objData.resolution_to : null,
            processing_level: objData.processing_level
              ? objData.processing_level
              : null,
            text: objData.text ? objData.text : null,
            satellite_ids: objData.selectedKey
              ? Object.keys(objData.selectedKey)
              : null,
            page: this.data.planet.page,
            page_size: this.data.planet.size,
            link_next:
              this.data.planet.value &&
              this.data.planet.value.links &&
              this.data.planet.value.links._next
                ? this.data.planet.value.links._next
                : null,
          },
          this.$store.getters.getAccessToken
        );

        if (response && response.status === 200) {
          let data =
            response.data && response.data.data && response.data.data.planet
              ? response.data.data.planet
              : {};
          data.results &&
            (this.data.planet.items = [
              ...this.data.planet.items,
              ...data.results,
            ]);

          if (!data.pages?._next) {
            this.data.planet.isBottom = true;
          }
          this.data.planet.value = data;
          if (this.data.planet.checkAll) {
            for (let i = 0; i < data.results.length; i++) {
              if (!this.data.planet.selected.includes(data.results[i]._id))
                this.data.planet.selected.push(data.results[i]._id);
            }
          }
        }

        this.data.planet.searching = false;
      } catch (error) {
        console.log(error);
        this.data.planet.searching = false;
        if (error && error.response && error.response.status === 401) {
          if (!this.loggedIn) {
            this.$store.dispatch("setCallBackFuncs", [
              {
                name: "onSearch",
              },
            ]);
            this.$store.dispatch("setIsLoginForm", true);
          }
        } else if (error.response.status === 404) {
          this.data.planet.isBottom = true;
        } else {
          let msg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "";
          if (msg) this.message.push(msg);
        }
      }
    },
    // end search images from planet api
    async searchImages(objData) {
      if (this.data.satellite.isBottom) return;
      // this.buttonsTool.satellite.visible = true;
      this.buttonsTool.satellite.disable = true;
      this.buttonsTool.satellite.processing = true;
      this.data.satellite.searching = true;
      setTimeout(() => {
        if (this.$refs.leftSide && this.data.satellite.page === 0) {
          this.$refs.leftSide.clearLayer();
        }
      }, 10);
      try {
        let response = null;
        if (this.loggedIn) {
          response = await imageFunc.search(
            {
              page: this.data.satellite.page,
              size: this.data.satellite.size,
              order: this.data.satellite.order_by,
              sort: this.data.satellite.sort_by,
            },
            {
              boundary: objData.boundary
                ? JSON.parse(objData.boundary).coordinates[0]
                : null,
              cloud_coverage_from:
                objData.cloud_coverage_from != null &&
                objData.cloud_coverage_from != undefined
                  ? objData.cloud_coverage_from
                  : null,
              cloud_coverage_to: objData.cloud_coverage_to
                ? objData.cloud_coverage_to
                : null,
              image_time_from: objData.image_time_from
                ? objData.image_time_from
                : null,
              image_time_to: objData.image_time_to
                ? objData.image_time_to
                : null,
              incidence_angle_from:
                objData.incidence_angle_from != null &&
                objData.incidence_angle_from != undefined
                  ? objData.incidence_angle_from
                  : null,
              incidence_angle_to: objData.incidence_angle_to
                ? objData.incidence_angle_to
                : null,
              resolution_from:
                objData.resolution_from != null &&
                objData.resolution_from != undefined
                  ? objData.resolution_from
                  : null,
              resolution_to: objData.resolution_to
                ? objData.resolution_to
                : null,
              processing_level: objData.processing_level
                ? objData.processing_level
                : null,
              text: objData.text ? objData.text : null,
              satellite_ids: objData.selectedKey
                ? Object.keys(objData.selectedKey)
                : null,
            },
            this.$store.getters.getAccessToken
          );
        } else {
          response = await imageFunc.publicSearch(
            {
              page: this.data.satellite.page,
              size: this.data.satellite.size,
            },
            {
              boundary: objData.boundary
                ? JSON.parse(objData.boundary).coordinates[0]
                : null,
              cloud_coverage_from:
                objData.cloud_coverage_from != null &&
                objData.cloud_coverage_from != undefined
                  ? objData.cloud_coverage_from
                  : null,
              cloud_coverage_to: objData.cloud_coverage_to
                ? objData.cloud_coverage_to
                : null,
              image_time_from: objData.image_time_from
                ? objData.image_time_from
                : null,
              image_time_to: objData.image_time_to
                ? objData.image_time_to
                : null,
              incidence_angle_from:
                objData.incidence_angle_from != null &&
                objData.incidence_angle_from != undefined
                  ? objData.incidence_angle_from
                  : null,
              incidence_angle_to: objData.incidence_angle_to
                ? objData.incidence_angle_to
                : null,
              resolution_from:
                objData.resolution_from != null &&
                objData.resolution_from != undefined
                  ? objData.resolution_from
                  : null,
              resolution_to: objData.resolution_to
                ? objData.resolution_to
                : null,
              processing_level: objData.processing_level
                ? objData.processing_level
                : null,
              text: objData.text ? objData.text : null,
              satellite_ids: objData.selectedKey
                ? Object.keys(objData.selectedKey)
                : null,
            },
            this.$store.getters.getAccessToken
          );
        }
        if (response && response.status === 200) {
          response.data.content_page &&
            (this.data.satellite.items = [
              ...this.data.satellite.items,
              ...response.data.content_page,
            ]);
          if (
            (response.data.content_page
              ? response.data.content_page.length
              : 0) < this.data.satellite.size
          )
            this.data.satellite.isBottom = true;
          this.data.satellite.value = response.data;
          if (this.data.satellite.checkAll) {
            for (let i = 0; i < response.data.content_page.length; i++) {
              if (
                !this.data.satellite.selected.includes(
                  response.data.content_page[i]._id
                )
              )
                this.data.satellite.selected.push(
                  response.data.content_page[i]._id
                );
            }
          }
        }

        // this.data.satellite.searching = false;
        // this.buttonsTool.satellite.disable = false;
        // this.buttonsTool.satellite.processing = false;
      } catch (error) {
        // this.data.satellite.searching = false;
        // this.buttonsTool.satellite.disable = false;
        // this.buttonsTool.satellite.processing = false;
        if (error && error.response && error.response.status === 401) {
          //this.refreshToken(this.searchImages, objData);

          this.$store.dispatch("setCallBackFuncs", [
            {
              name: "orderImage",
            },
          ]);
          this.$store.dispatch("setIsLoginForm", true);
        } else if (error.response.status === 404) {
          this.data.satellite.isBottom = true;
        } else {
          let msg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "";
          if (msg) this.message.push(msg);
        }
      }

      this.data.satellite.searching = false;
      this.buttonsTool.satellite.processing = false;

      this.buttonsTool.satellite.active = true;
      // this.buttonsTool.satellite.visible = true;
      this.buttonsTool.satellite.disable = false;
    },
    async searchSatellitesOptical(val, params = null) {
      if (!this.data.satellite.searching) {
        if (!this.data.satellite.isBottom) {
          this.data.satellite.searching = true;
          if (params === null) {
            params = {
              page: this.data.satellite.page,
              size: this.data.satellite.size,
            };
          }
          try {
            let response = await geoImageFunc.searchMetadata(
              val,
              params,
              this.$store.getters.getAccessToken
            );
            if (response.status === 200) {
              if (response.data) {
                response.data.content_page &&
                  (this.data.satellite.items = [
                    ...this.data.satellite.items,
                    ...response.data.content_page,
                  ]);
                if (
                  (response.data.content_page
                    ? response.data.content_page.length
                    : 0) < this.data.satellite.size
                )
                  this.data.satellite.isBottom = true;
                this.data.satellite.value = response.data;
                if (this.data.satellite.checkAll) {
                  for (let i = 0; i < response.data.content_page.length; i++) {
                    if (
                      !this.data.satellite.selected.includes(
                        response.data.content_page[i]._id
                      )
                    )
                      this.data.satellite.selected.push(
                        response.data.content_page[i]._id
                      );
                  }
                }
              }
            }
          } catch (error) {
            if (error.response && error.response.status === 401) {
              this.refreshToken(this.searchSatellitesOptical, val);
            }
          }
          this.data.satellite.searching = false;
        }
      }
    },
    sortDataOptical() {
      switch (this.data.satellite.sort) {
        case "TIME_ASCENDING":
          this.data.satellite.isBottom = false;
          this.data.satellite.order_by = "metadata.imaging_date";
          this.data.satellite.sort_by = "asc";
          this.data.satellite.value = {};
          this.data.satellite.items = [];
          this.data.satellite.checkAll = false;
          this.data.satellite.selected = [];
          this.data.satellite.page = 0;
          this.data.satellite.isBottom = false;
          this.searchImages(this.criteria);
          break;
        case "TIME_DESCENDING":
          this.data.satellite.isBottom = false;
          this.data.satellite.order_by = "metadata.imaging_date";
          this.data.satellite.sort_by = "desc";
          this.data.satellite.value = {};
          this.data.satellite.items = [];
          this.data.satellite.checkAll = false;
          this.data.satellite.selected = [];
          this.data.satellite.page = 0;
          this.data.satellite.isBottom = false;
          this.searchImages(this.criteria);
          break;
        case "RESOLUTION_ASCENDING":
          this.data.satellite.isBottom = false;
          this.data.satellite.order_by = "metadata.resolution";
          this.data.satellite.sort_by = "asc";
          this.data.satellite.value = {};
          this.data.satellite.items = [];
          this.data.satellite.checkAll = false;
          this.data.satellite.selected = [];
          this.data.satellite.page = 0;
          this.data.satellite.isBottom = false;
          this.searchImages(this.criteria);
          break;
        case "RESOLUTION_DESCENDING":
          this.data.satellite.isBottom = false;
          this.data.satellite.order_by = "metadata.resolution";
          this.data.satellite.sort_by = "desc";
          this.data.satellite.value = {};
          this.data.satellite.items = [];
          this.data.satellite.checkAll = false;
          this.data.satellite.selected = [];
          this.data.satellite.page = 0;
          this.data.satellite.isBottom = false;
          this.searchImages(this.criteria);
          break;
        case "CLOUD_COVERANGE_ASCENDING":
          this.data.satellite.isBottom = false;
          this.data.satellite.order_by = "metadata.cloud_coverage";
          this.data.satellite.sort_by = "asc";
          this.data.satellite.value = {};
          this.data.satellite.items = [];
          this.data.satellite.checkAll = false;
          this.data.satellite.selected = [];
          this.data.satellite.page = 0;
          this.data.satellite.isBottom = false;
          this.searchImages(this.criteria);
          break;
        case "CLOUD_COVERANGE_DESCENDING":
          this.data.satellite.isBottom = false;
          this.data.satellite.order_by = "metadata.cloud_coverage";
          this.data.satellite.sort_by = "desc";
          this.data.satellite.value = {};
          this.data.satellite.items = [];
          this.data.satellite.checkAll = false;
          this.data.satellite.selected = [];
          this.data.satellite.page = 0;
          this.data.satellite.isBottom = false;
          this.searchImages(this.criteria);
          break;
        case "INCIDENCE_ANGLE_ASCENDING":
          this.data.satellite.isBottom = false;
          this.data.satellite.order_by = "metadata.incidence_angle";
          this.data.satellite.sort_by = "asc";
          this.data.satellite.value = {};
          this.data.satellite.items = [];
          this.data.satellite.checkAll = false;
          this.data.satellite.selected = [];
          this.data.satellite.page = 0;
          this.data.satellite.isBottom = false;
          this.searchImages(this.criteria);
          break;
        case "INCIDENCE_ANGLE_DESCENDING":
          this.data.satellite.isBottom = false;
          this.data.satellite.order_by = "metadata.incidence_angle";
          this.data.satellite.sort_by = "desc";
          this.data.satellite.value = {};
          this.data.satellite.items = [];
          this.data.satellite.checkAll = false;
          this.data.satellite.selected = [];
          this.data.satellite.page = 0;
          this.data.satellite.isBottom = false;
          this.searchImages(this.criteria);
          break;
      }
    },
    showDialogPriceDetail(data) {
      this.dlgPriceDetaill.items = data;
      this.dlgPriceDetaill.show = true;
    },
    async onOrderOneAtlas(data) {
      let payload = {
        kind: "order.data.gb.product",
        products: [],
      };
      let images = this.cart.satellite.items.filter(
        (x) => x.type === "ONEATLAS"
      );
      if (images && images.length > 0) {
        for (let i = 0; i < images.length; i++) {
          payload.products.push({
            crsCode: data.crsCode,
            productType: data.productType,
            radiometricProcessing: data.radiometricProcessing,
            aoi: {
              type: "Polygon",
              coordinates: this.criteria.boundary
                ? JSON.parse(this.criteria.boundary).coordinates
                : [],
            },
            id: images[i].properties.id,
            imageFormat: data.imageFormat,
          });
        }
      }
      console.log(payload);
      this.isOrder = true;
      this.dlgOrder.button.disable = true;
      this.dlgOrder.button.processing = true;
      try {
        let response = await oneAtlasFunc.createOrder(
          payload,
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );
        if (
          response &&
          (response.status === 200 ||
            response.status === 201 ||
            response.status === 202)
        ) {
          this.$toast.success({
            title: this.$t("title_message_info"),
            message: this.$t("message_send_order_image_success"),
            position: "top right",
          });

          this.cart.oneAtlas.items = [];
          for (let i = 0; i < this.cart.satellite.items.length; i++) {
            if (this.cart.satellite.items[i].type === "ONEATLAS") {
              if (this.$refs.leftSide) {
                if (this.$refs.leftSide.$refs.tabOneAtlas) {
                  this.$refs.leftSide.$refs.tabOneAtlas.removeCartItem(
                    this.cart.satellite.items[i].properties.id
                  );
                }
              }
              this.cart.satellite.items.splice(i, 1);
              i = i - 1;
            }
          }
          if (this.$refs.dlgOrder) {
            this.$refs.dlgOrder.clearCart("ONEATLAS");
          }
        } else {
          this.message.push(this.$t("message_send_order_image_error"));
        }
      } catch (error) {
        this.isOrder = false;
        this.dlgOrder.button.disable = false;
        this.dlgOrder.button.processing = false;
        let msg = [this.$t("message_send_order_image_error")];
        if (error && error.response && error.response.data) {
          if (error.response.data.message) {
            msg = [error.response.data.message];
          }
        }
        this.message = msg;
      }
      this.isOrder = false;
      this.dlgOrder.button.disable = false;
      this.dlgOrder.button.processing = false;
    },
    onOrderPlanet(data) {
      this.orderImagesPlanet(data.bundles, data.name, data.order_type);
    },
    async orderImagesPlanet(bundles, name, order_type) {
      if (
        this.cart.planet &&
        this.cart.planet.items &&
        this.cart.planet.items.length > 0
      ) {
        let payload = {
          delivery: {
            archive_type: "zip",
            single_archive: true,
          },
          name: name,
          order_type: order_type,
          products: [],
        };
        for (let i = 0; i < bundles.length; i++) {
          let obj = {
            item_type: bundles[i].id,
            product_bundle: bundles[i].assets,
          };

          let items = this.cart.planet.items.filter(
            (x) => x.properties.item_type === bundles[i].id
          );
          if (items && items.length > 0)
            obj.item_ids = [...new Set(items.map((x) => x.id))];
          payload.products.push(obj);
        }
        console.log(payload);
        this.cart.planet.items = [];
        for (let i = 0; i < this.cart.satellite.items.length; i++) {
          if (this.cart.satellite.items[i].type === "PLANET") {
            if (this.$refs.leftSide) {
              if (this.$refs.leftSide.$refs.tabPlanet) {
                this.$refs.leftSide.$refs.tabPlanet.removeCartItem(
                  this.cart.satellite.items[i].id
                );
              }
            }
            this.cart.satellite.items.splice(i, 1);
            i = i - 1;
          }
        }
        if (this.$refs.dlgOrder) {
          this.$refs.dlgOrder.clearCart("PLANET");
        }
        return;
        this.isOrder = true;
        this.dlgOrder.button.disable = true;
        this.dlgOrder.button.processing = true;
        try {
          let response = await planetFunc.createOrder(payload);

          console.log("Response Planet", response);
          if (
            response &&
            (response.status === 200 ||
              response.status === 201 ||
              response.status === 202)
          ) {
            this.$toast.success({
              title: this.$t("title_message_info"),
              message: this.$t("message_send_order_image_success"),
              position: "top right",
            });
            this.cart.planet.items = [];
            for (let i = 0; i < this.cart.satellite.items.length; i++) {
              if (this.cart.satellite.items[i].type === "PLANET") {
                if (this.$refs.leftSide) {
                  if (this.$refs.leftSide.$refs.tabPlanet) {
                    this.$refs.leftSide.$refs.tabPlanet.removeCartItem(
                      this.cart.satellite.items[i].id
                    );
                  }
                }
                this.cart.satellite.items.splice(i, 1);
                i = i - 1;
              }
            }
            if (this.$refs.dlgOrder) {
              this.$refs.dlgOrder.clearCart("PLANET");
            }
          } else {
            this.message.push(this.$t("message_send_order_image_error"));
          }
        } catch (error) {
          this.isOrder = false;
          this.dlgOrder.button.disable = false;
          this.dlgOrder.button.processing = false;
          console.log("Error Planet", error);
          let msg = [this.$t("message_send_order_image_error")];
          if (error && error.response && error.response.data) {
            if (error.response.data.error_hints) {
              if (typeof error.response.data.error_hints === "object")
                msg = error.response.data.error_hints;
              else msg = [error.response.data.error_hints];
            }
          }
          this.message = msg;
        }
        this.dlgOrder.button.disable = false;
        this.dlgOrder.button.processing = false;
        this.isOrder = false;
      }
    },
    async onOrderAll(val) {
      this.isOrder = true;
      let boundingBox = [];
      if (this.dlgOrder && this.dlgOrder.show) {
        if (
          this.dlgOrder.feature &&
          Object.keys(this.dlgOrder.feature).length > 0
        ) {
          if (
            this.dlgOrder.feature.geometry &&
            Object.keys(this.dlgOrder.feature.geometry).length > 0
          ) {
            let coors = this.dlgOrder.feature.geometry.getCoordinates();
            if (coors && coors.length > 0) {
              for (let i = 0; i < coors[0].length; i++) {
                boundingBox.push(
                  transform(coors[0][i], "EPSG:3857", "EPSG:4326")
                );
              }
            }
          }
        }
      }

      this.dlgOrder.button.disable = true;
      this.dlgOrder.button.processing = true;
      try {
        let entity = {
          address:
            this.userMe &&
            Object.keys(this.userMe).length > 0 &&
            this.userMe.address
              ? this.userMe.address.trim().replace(/\s\s+/g, " ")
              : null,
          description: val.name
            ? val.name.trim().replace(/\s\s+/g, " ")
            : val.name
            ? val.name.trim().replace(/\s\s+/g, " ")
            : null,
          note: val.name ? val.name.trim().replace(/\s\s+/g, " ") : null,
          email:
            this.userMe &&
            Object.keys(this.userMe).length > 0 &&
            this.userMe.email
              ? this.userMe.email.trim().replace(/\s\s+/g, " ")
              : null,
          full_name:
            this.userMe &&
            Object.keys(this.userMe).length > 0 &&
            this.userMe.full_name
              ? this.userMe.full_name.trim().replace(/\s\s+/g, " ")
              : null,
          image_ids: {
            planet: [],
            one_atlas: [],
            capella: [],
            geo_hub: [],
          },
          images: [],
          organization:
            this.userMe &&
            Object.keys(this.userMe).length > 0 &&
            this.userMe.organization
              ? this.userMe.organization.trim().replace(/\s\s+/g, " ")
              : null,
          phone_number:
            this.userMe &&
            Object.keys(this.userMe).length > 0 &&
            this.userMe.phone_number
              ? this.userMe.phone_number.trim().replace(/\s\s+/g, " ")
              : null,

          name: val.name ? val.name.trim().replace(/\s\s+/g, " ") : null,
          priority_id: val.priority_id
            ? val.priority_id
            : "61266218f2455262b897b7f3",
          bounding_box: boundingBox,
        };
        if (this.cart.satellite.items.length > 0) {
          for (let i = 0; i < this.cart.satellite.items.length; i++) {
            if (this.cart.satellite.items[i].type === "PLANET") {
              entity.image_ids.planet.push(this.cart.satellite.items[i].id);
            } else if (this.cart.satellite.items[i].type === "ONEATLAS") {
              entity.image_ids.one_atlas.push(this.cart.satellite.items[i].id);
            } else if (this.cart.satellite.items[i].type === "CAPELLA") {
              entity.image_ids.capella.push(this.cart.satellite.items[i].id);
            } else if (this.cart.satellite.items[i].type === "SATELLITE") {
              entity.image_ids.geo_hub.push(this.cart.satellite.items[i].id);
              entity.images.push(this.cart.satellite.items[i]);
            }
          }
        }
        let response = await orderFunc.create(entity, this.accessToken);

        if (response.status === 200 || response.status === 201) {
          this.isOrder = false;
          // for (let i = 0; i < this.cart.satellite.items.length; i++) {
          //   if (this.cart.satellite.items[i].type === "SATELLITE") {
          //     if (this.$refs.leftSide) {
          //       if (this.$refs.leftSide.$refs.tabSatellite) {
          //         this.$refs.leftSide.$refs.tabSatellite.removeCartItem(
          //           this.cart.satellite.items[i].id
          //         );
          //       }
          //     }
          //     this.cart.satellite.items.splice(i, 1);
          //     i = i - 1;
          //   }
          // }
          this.cart.satellite.items = [];
          this.cart.planet.items = [];
          this.cart.oneAtlas.items = [];
          this.cart.capella.items = [];

          if (this.$refs.dlgOrder) {
            this.$refs.dlgOrder.clearCart("PLANET");
            this.$refs.dlgOrder.clearCart("ONEATLAS");
            this.$refs.dlgOrder.clearCart("CAPELLA");
            this.$refs.dlgOrder.clearCart("SATELLITE");
          }

          this.$toast.success({
            title: this.$t("title_message_info"),
            message: this.$t("message_send_order_image_success"),
            position: "top right",
          });
          this.dlgOrder.show = false;
          this.dlgOrder.value = null;
          this.dlgOrder.feature = null;
        } else {
          this.message.push(this.$t("message_send_order_image_error"));
        }
      } catch (error) {
        console.log(error);
        this.dlgOrder.button.disable = false;
        this.dlgOrder.button.processing = false;
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.onOrderAll, val);
        } else this.message.push(this.$t("message_send_order_image_error"));
      }
      this.isOrder = false;
      this.dlgOrder.button.disable = false;
      this.dlgOrder.button.processing = false;
    },
    async onOrderCapella(val) {},
    async onOrderGeohub(val) {
      this.isOrder = true;
      let boundingBox = [];
      if (this.dlgOrder && this.dlgOrder.show) {
        if (
          this.dlgOrder.feature &&
          Object.keys(this.dlgOrder.feature).length > 0
        ) {
          if (
            this.dlgOrder.feature.geometry &&
            Object.keys(this.dlgOrder.feature.geometry).length > 0
          ) {
            let coors = this.dlgOrder.feature.geometry.getCoordinates();
            if (coors && coors.length > 0) {
              for (let i = 0; i < coors[0].length; i++) {
                boundingBox.push(
                  transform(coors[0][i], "EPSG:3857", "EPSG:4326")
                );
              }
            }
          }
        }
      }

      this.dlgOrder.button.disable = true;
      this.dlgOrder.button.processing = true;
      try {
        let entity = {
          address:
            this.userMe &&
            Object.keys(this.userMe).length > 0 &&
            this.userMe.address
              ? this.userMe.address.trim().replace(/\s\s+/g, " ")
              : null,
          description: val.description
            ? val.description.trim().replace(/\s\s+/g, " ")
            : null,
          note: val.note ? val.note.trim().replace(/\s\s+/g, " ") : null,
          email:
            this.userMe &&
            Object.keys(this.userMe).length > 0 &&
            this.userMe.email
              ? this.userMe.email.trim().replace(/\s\s+/g, " ")
              : null,
          full_name:
            this.userMe &&
            Object.keys(this.userMe).length > 0 &&
            this.userMe.full_name
              ? this.userMe.full_name.trim().replace(/\s\s+/g, " ")
              : null,
          image_ids: [],
          images: [],
          organization:
            this.userMe &&
            Object.keys(this.userMe).length > 0 &&
            this.userMe.organization
              ? this.userMe.organization.trim().replace(/\s\s+/g, " ")
              : null,
          phone_number:
            this.userMe &&
            Object.keys(this.userMe).length > 0 &&
            this.userMe.phone_number
              ? this.userMe.phone_number.trim().replace(/\s\s+/g, " ")
              : null,

          name: val.name ? val.name.trim().replace(/\s\s+/g, " ") : null,
          priority_id: val.priority_id,
          bounding_box: boundingBox,
        };
        if (this.cart.satellite.items.length > 0) {
          for (let i = 0; i < this.cart.satellite.items.length; i++) {
            if (this.cart.satellite.items[i].type === "SATELLITE") {
              entity.image_ids.push(this.cart.satellite.items[i].id);
              entity.images.push(this.cart.satellite.items[i]);
            }
          }
        }
        let response = await orderFunc.create(entity, this.accessToken);

        if (response.status === 200 || response.status === 201) {
          this.isOrder = false;
          for (let i = 0; i < this.cart.satellite.items.length; i++) {
            if (this.cart.satellite.items[i].type === "SATELLITE") {
              if (this.$refs.leftSide) {
                if (this.$refs.leftSide.$refs.tabSatellite) {
                  this.$refs.leftSide.$refs.tabSatellite.removeCartItem(
                    this.cart.satellite.items[i].id
                  );
                }
              }
              this.cart.satellite.items.splice(i, 1);
              i = i - 1;
            }
          }
          if (this.$refs.dlgOrder) {
            this.$refs.dlgOrder.clearCart("SATELLITE");
          }

          this.$toast.success({
            title: this.$t("title_message_info"),
            message: this.$t("message_send_order_image_success"),
            position: "top right",
          });
        } else {
          this.message.push(this.$t("message_send_order_image_error"));
        }
      } catch (error) {
        console.log(error);
        this.dlgOrder.button.disable = false;
        this.dlgOrder.button.processing = false;
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.onOrderGeohub, val);
        } else this.message.push(this.$t("message_send_order_image_error"));
      }
      this.isOrder = false;
      this.dlgOrder.button.disable = false;
      this.dlgOrder.button.processing = false;
    },

    onConfirmedAoi(data) {
      this.dlgOrder.show = true;
      this.dlgOrder.feature = data;
    },
    async orderImage() {
      if (this.$store.getters.getAccessToken) {
        this.dlgOrder.show = true;
        if (this.aoiFeature && Object.keys(this.aoiFeature).length) {
          this.dlgOrder.feature = {
            id: "current_aoi",
            label: this.aoiFeature.get("label"),
            geometry: this.aoiFeature.getGeometry(),
          };
        } else this.dlgOrder.feature = null;
      } else {
        this.$store.dispatch("setCallBackFuncs", [
          {
            name: "orderImage",
          },
        ]);
        this.$store.dispatch("setIsLoginForm", true);
      }
    },
    async handleOrder(val) {
      if (this.isOrder) {
        return await cartFunc.create(val, this.accessToken);
      }
    },

    /*---------------- Meta key ----------------*/
    getPriorityByLang() {
      this.priorityLevel.items = [];
      for (let i = 0; i < this.priorityLevel.data.length; i++) {
        if (
          this.priorityLevel.data[i].translation &&
          this.priorityLevel.data[i].translation[this.lang] &&
          Object.keys(this.priorityLevel.data[i].translation[this.lang])
            .length > 0
        ) {
          this.priorityLevel.items.push({
            id: this.priorityLevel.data[i].id,
            key: this.priorityLevel.data[i].key,
            name: this.priorityLevel.data[i].name,
            value: this.priorityLevel.data[i].translation[this.lang].value
              ? this.priorityLevel.data[i].translation[this.lang].value
              : this.priorityLevel.data[i].value,
            description: this.priorityLevel.data[i].translation[this.lang]
              .description
              ? this.priorityLevel.data[i].translation[this.lang].description
              : this.priorityLevel.data[i].description,

            is_default: this.priorityLevel.data[i].is_default ? true : false,
          });
        } else {
          this.priorityLevel.items.push({
            id: this.priorityLevel.data[i].id,
            key: this.priorityLevel.data[i].key,
            name: this.priorityLevel.data[i].name,
            value: this.priorityLevel.data[i].value,
            description: this.priorityLevel.data[i].description,
            is_default: this.priorityLevel.data[i].is_default ? true : false,
          });
        }
      }
    },
    /*---------------- End metakey ------------------*/
    async getAllProcessingLevel() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PROCESSING_LEVEL",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.processLevel.items =
            response.data && response.data.data ? response.data.data : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllProcessingLevel);
        }
      }
    },
    async getAllPriorityLevel() {
      this.priorityLevel.items = [];
      try {
        let response = await metaKeyFunc.getByKey(
          "PROGRAMMING_SERVICE",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.priorityLevel.data =
            response.data && response.data.data ? response.data.data : [];
          this.getPriorityByLang();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllPriorityLevel);
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {});
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {});
      }
      localStorage.removeItem("lock-refresh");
    },
  },

  watch: {
    lang(val) {
      this.getPriorityByLang();
    },
    draw: {
      handler(val) {
        if (Object.keys(val).length > 0) {
          this.$store
            .dispatch("setSearchDto", {
              boundary: JSON.stringify(val.geometry),
            })
            .then((r) => {
              this.onSearch(val);
            });
        }
      },
      deep: true,
    },
    criteria: {
      handler(val) {},
      immediate: true,
    },
    loggedIn: {
      handler(val) {
        if (val) {
          if (this.callBackFuncs.length > 0) {
            for (let index = 0; index < this.callBackFuncs.length; index++) {
              try {
                if (
                  typeof this[this.callBackFuncs[index].name] !== "undefined"
                ) {
                  var vm = this;
                  setTimeout(() => {
                    // safe to use the function
                    vm[vm.callBackFuncs[index].name](
                      vm.callBackFuncs[index].arguments
                        ? vm.callBackFuncs[index].arguments
                        : null
                    );
                    setTimeout(() => {
                      vm.$store.dispatch(
                        "removeCallBackFunc",
                        vm.callBackFuncs[index].name
                      );
                    }, 100);
                  }, 200);
                }
              } catch (error) {}
            }
          }
        } else {
          this.cart.satellite.items = [];
          this.buttonsTool.cart.disable = true;
        }
      },
      immediate: true,
    },
    isTasking(val) {
      if (val) {
        try {
          localStorage.setItem("zoom", this.map.getView().getZoom());
          localStorage.setItem("center", this.map.getView().getCenter());
        } catch (error) {}
      }
      this.dialogTasking.show = val;
    },
  },
  computed: {
    isAdmin() {
      return this.roles && this.roles.map((x) => x.name).includes("ROLE_ADMIN");
    },
    roles() {
      return this.$store.getters.getRoles;
    },
    lang() {
      return this.$store.getters.getLang;
    },
    isTasking() {
      return this.$store.getters.getIsTasking;
    },
    basemap() {
      return this.$store.getters.getBasemap;
    },
    loading() {
      return this.$store.getters.getSpinnerApp;
    },
    myCart() {
      return this.$store.getters.getMyCart;
    },
    userMe() {
      return this.$store.getters.getUser;
    },
    middleSidePositionLeft() {
      let elementMiddleSide = document.getElementById("middle-side");
      let elementWidth =
        elementMiddleSide && Object.keys(elementMiddleSide)
          ? elementMiddleSide.offsetWidth
          : 66;
      let width = Math.ceil(this.documentWidth - elementWidth);
      return this.documentWidth < 768 ? "auto" : Math.ceil(width / 2);
    },
    mainHeight() {
      return this.documentHeight - 56;
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },

    pointerMove() {
      if (this.isDD) {
        return (
          parseFloat(this.mouseCoordinate.lat).toFixed(5) +
          "  -  " +
          parseFloat(this.mouseCoordinate.lng).toFixed(5)
        );
      } else {
        return toStringHDMS([
          this.mouseCoordinate.lng,
          this.mouseCoordinate.lat,
        ]);
      }
    },
    accessToken() {
      return this.$store.getters.getAccessToken;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    callBackFuncs() {
      return this.$store.getters.getCallBackFuncs;
    },
    criteria() {
      return this.$store.getters.getSearchDto;
    },
    objsImage() {
      return this.$store.getters.getObjectsImage;
    },
    oneAtlasData() {
      return this.$store.getters.getOneAtlasData;
    },
  },
  beforeDestroy() {
    try {
      localStorage.setItem("zoom", this.map.getView().getZoom());
      localStorage.setItem("center", this.map.getView().getCenter());
      this.map = Object;
    } catch (error) {}
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.Uhe723HDBgs4sd {
  height: 100%;
  width: 100%;
  position: relative;
}
.openlayer__map {
  position: absolute;
  height: 100%;
  width: 100%;
}
#mouse-information {
  position: absolute;
  bottom: 0.25rem;
  right: 0.25rem;
  z-index: 1;
  font-size: 14px;
  font-weight: 550;
  width: 19.5rem;
  display: flex;
  span.mouse-position {
    position: absolute;
    right: 0;
    background-color: white;
    padding: 0 1.5rem;
    width: 15rem;
    font-size: 13px;
    border-radius: 0.25rem;
  }
  .onoffswitch {
    position: relative;
    width: 4rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .onoffswitch-checkbox {
    display: none;
  }
  .onoffswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #999999;
    border-radius: 20px;
    margin: 0;
  }
  .onoffswitch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }
  .onoffswitch-inner:before,
  .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 1rem;
    line-height: 1rem;
    font-size: 14px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    box-sizing: border-box;
  }
  .onoffswitch-inner:before {
    content: "DD";
    margin: 0;
    padding: 0;
    background-color: $background-color;
    color: #ffffff;
    padding-left: 0.25rem;
  }
  .onoffswitch-inner:after {
    content: "DMS";
    margin: 0;
    padding: 0;
    background-color: $background-color;
    color: #ffffff;
    text-align: right;
    padding-right: 0.25rem;
  }
  .onoffswitch-switch {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin: 0;
    background: #ffffff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2.75rem;
    border: 2px solid #999999;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
  }
  .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px;
  }
}
#middle-side {
  position: absolute;
  z-index: 1;
  top: 0.25rem;
}
#left-side {
  z-index: 1;
  position: absolute;
  left: 0.5rem;
  top: 3.125rem;
}
#basemap-side {
  z-index: 2;
  position: absolute;
  right: 0.275rem;
  top: 0.25rem;
}

#controls-right {
  position: absolute;
  right: 0;
  top: 0.275rem;
  z-index: 1;
  border-radius: 0.25rem;
}
#controls-left {
  position: absolute;
  left: 0.5rem;
  top: 0.25rem;
  z-index: 1;
  border-radius: 0.25rem;
}
.map {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
