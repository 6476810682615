import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import qs from 'qs';
import app from './../../main'
import { geoServiceApiURL } from "@/utils/commons/environment"
export default {
   async publicSearch(params,dtoSearch,access_token) {
        let headers = basicApi.image.public_search.headers;
        headers["Authorization"] = "bearer " + access_token;;
        headers["Content-Type"]=  "application/json";
        headers['Accept-Language'] = app.$store.getters.getLang;        
        if (!params) params = {};
        if(dtoSearch && Object.keys(dtoSearch).length > 0){
          if(dtoSearch.resolution_from === 0 && dtoSearch.resolution_to === 40){
            dtoSearch.resolution_from = null;
            dtoSearch.resolution_to = null;
          }
          if(dtoSearch.cloud_coverage_from === 0 && dtoSearch.cloud_coverage_to === 100){
            dtoSearch.cloud_coverage_from = null;
            dtoSearch.cloud_coverage_to = null;
          }
          if(dtoSearch.incidence_angle_from === 0 && dtoSearch.incidence_angle_to === 90){
            dtoSearch.incidence_angle_from = null;
            dtoSearch.incidence_angle_to = null;
          }
        }
        const object = axios({
          method: basicApi.image.public_search.method,
          url: geoServiceApiURL + basicApi.image.public_search.url,
          headers:headers,
          params: params,
          data:dtoSearch
        });
        let response = await object;
        return response;
  },
    async search(params,dtoSearch,access_token) {
        let headers = basicApi.image.search.headers;
        headers["Authorization"] = "bearer " + access_token;;
        headers["Content-Type"]=  "application/json";
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        if(dtoSearch && Object.keys(dtoSearch).length > 0){
          if(dtoSearch.resolution_from === 0 && dtoSearch.resolution_to === 40){
            dtoSearch.resolution_from = null;
            dtoSearch.resolution_to = null;
          }
          if(dtoSearch.cloud_coverage_from === 0 && dtoSearch.cloud_coverage_to === 100){
            dtoSearch.cloud_coverage_from = null;
            dtoSearch.cloud_coverage_to = null;
          }
          if(dtoSearch.incidence_angle_from === 0 && dtoSearch.incidence_angle_to === 90){
            dtoSearch.incidence_angle_from = null;
            dtoSearch.incidence_angle_to = null;
          }
        }
        const object = axios({
          method: basicApi.image.search.method,
          url: geoServiceApiURL + basicApi.image.search.url,
          headers:headers,
          params: params,
          data:dtoSearch
        });
        let response = await object;
        return response;
  },
  async searchImagesHub(params, dtoSearch, username, password, apiUrl) {
        let headers = {};
        headers["Authorization"] = "basic " + btoa(username+":"+password);
        headers["Content-Type"]=  "application/json";
        headers["Accept"] = "application/json";
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
          method: "get",
          url: apiUrl ? apiUrl : process.env.VUE_APP_API_SEARCH_SENTINEL_HUB,
          headers:headers,
          params: params,
          data:dtoSearch
        });
        let response = await object;
        return response;
  },
  async create(entity,access_token){
        let headers = basicApi.image.create.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
          method: basicApi.image.create.method,
          url:  geoServiceApiURL + basicApi.image.create.url,
          data: entity,
          headers: headers,
          params: {
          }
        });
        let response = await object;
        return response;
    },
    async update(entity, access_token) {
      let headers = basicApi.image.update.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.image.update.method,
        url: geoServiceApiURL + basicApi.image.update.url.replace("{id}",entity.id),
        data: entity,
        headers: headers,
        params: {
        }
      });
      let response = await object;
      return response;
    },
  
    async delete(id, access_token) {
      let headers = basicApi.image.delete.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.image.delete.method,
        url: geoServiceApiURL + basicApi.image.delete.url.replace("{id}",id),
        headers: headers,
        params: {
        }
      });
      let response = await object;
      return response;
    },
  
    async downloadImage(id, access_token) {
      let headers = basicApi.image.downoad.headers;
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.image.downoad.method,
        url: geoServiceApiURL + basicApi.image.downoad.url.replace("{id}",id),
        headers:headers,
        responseType: 'blob',
        params: {
        }
      });
      let response = await object;
      return response;
    },
    async getById(id, access_token) {
      let headers = basicApi.image.get_by_id.headers;
      headers["Authorization"] = "bearer " + access_token;      
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object = axios({
        method: basicApi.image.get_by_id.method,
        url: geoServiceApiURL + basicApi.image.get_by_id.url.replace("{id}",id),
        headers: headers,
        params: {
        }
      });
      let response = await object;
      return response;
    },
  async downloadFolderAsZip(url, data) {
      const object = axios({
      method:'post',
      url:url,
      data:data,
      responseType: "blob",
      params: {
      }
    });
    let response = await object;
    return response;
  },
  
  async uploadImage(contentType, path, access_token) {
    let headers = basicApi.image.upload_image.headers;
    headers["Authorization"] = "bearer " + access_token;      
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.image.upload_image.method,
      url: geoServiceApiURL + basicApi.image.upload_image,
      headers: headers,
      params: {
        content_type:contentType,
        path: path
      }
    });
    let response = await object;
    return response;
  },
}