<template>
  <div
    class="tasking-map"
    :style="{ height: propContentHeight - 2 * 16 + 'px' }"
  >
    <div class="" id="taskingMap"></div>
  </div>
</template>
<script>
var sketch;
import Map from "ol/Map";
import View from "ol/View";
import XYZ from "ol/source/XYZ";
import Draw from "ol/interaction/Draw";
import { Modify, Select } from "ol/interaction.js";
import Collection from "ol/Collection";
import { Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { defaults as defaultControls } from "ol/control";
import { defaults } from "ol/interaction";
import { getCenter } from "ol/extent";
import { unByKey } from "ol/Observable.js";
import { Style, Fill, Stroke, Circle, Icon, Text } from "ol/style.js";
import { MultiPoint } from "ol/geom.js";
export default {
  props: {
    propFeature: {
      type: Object,
      default: () => {},
    },
    propContentHeight: {
      type: Number,
    },
  },
  data() {
    return {
      map: Object,
      layer: Object,
      draw: Object,
      modify: Object,
      select: Object,
      fillColor: "#FFBF23",
      fill: new Fill({
        color: "rgba(255,255,255,0.4)",
      }),
      strokeColor: "#FFBF23",
      stroke: new Stroke({
        color: "#FFBF23",
        width: 1.25,
      }),
    };
  },
  watch: {
    propFeature(val) {
      if (this.layer && Object.keys(this.layer).length) {
        this.layer.getSource().clear();
        if (val) {
          this.layer.getSource().addFeature(val);
          var ext = this.propFeature.getGeometry().getExtent();
          this.map.getView().fit(ext, {
            size: this.map.getSize(),
          });
          var center = getCenter(ext);
          this.map.getView().animate({
            center: [center[0], center[1]],
            duration: 100,
            zoom:
              this.map.getView().getZoom() < 2
                ? 2
                : this.map.getView().getZoom() - 0.5,
          });
          if (this.draw && Object.keys(this.draw).length)
            this.map.removeInteraction(this.draw);
          if (this.select && Object.keys(this.select).length > 0) {
            this.map.removeInteraction(this.select);
            this.select = Object;
          }
          setTimeout(() => {
            this.modifyFeature("featureTasking");
          }, 20);
        }
      }
    },
  },
  mounted() {
    this.initMapOpenlayer();
  },
  methods: {
    refrestMap() {
      setTimeout(() => {
        this.map.updateSize();
      }, 50);
    },
    initMapOpenlayer: function () {
      var vm = this;
      try {
        var source = new VectorSource({ wrapX: false, id: "sourcedraw" });

        this.layer = new VectorLayer({
          source: source,
          style: new Style({
            fill: new Fill({
              color: this.hexToRgbA(this.fillColor, 0.5),
            }),
            stroke: new Stroke({
              color: "#FFBF23",
              width: 2,
            }),
          }),
        });
        this.layer.set("id", "layerdraw");
        let _zoom = localStorage.getItem("zoom");
        if (!_zoom) _zoom = 6;
        try {
          _zoom = parseFloat(_zoom);
        } catch (errZoom) {
          _zoom = 6;
        }
        let _center = [localStorage.getItem("center")];
        if (!_center || _center.length === 0)
          _center = fromLonLat([108.222848, 16.062833]);
        try {
          if (!_center[0]) _center[0] = "";
          let centerLen = _center[0].split(",");
          if (centerLen.length != 2)
            _center = fromLonLat([108.222848, 16.062833]);
          else {
            try {
              let centerLng = parseFloat(centerLen[0]);
              let centerLat = parseFloat(centerLen[1]);
              if (isNaN(centerLng) || isNaN(centerLat))
                _center = fromLonLat([108.222848, 16.062833]);
              else _center = [centerLng, centerLat];
            } catch (errorPaser) {
              _center = fromLonLat([108.222848, 16.062833]);
            }
          }
        } catch (errorCenter) {
          _center = fromLonLat([108.222848, 16.062833]);
        }
        this.map = new Map({
          controls: defaultControls({ attribution: false, zoom: false }),
          interactions: defaults({ doubleClickZoom: false }),
          layers: [
            new TileLayer({
              id: new Date().getTime(),
              source: new XYZ({
                url:
                  vm.basemap &&
                  Object.keys(vm.basemap).length > 0 &&
                  vm.basemap.source &&
                  vm.basemap.source.url
                    ? vm.basemap.source.url
                    : process.env.VUE_APP_BASEMAP_URL,
              }),
              show: true,
              opacity: 1,
            }),
            this.layer,
          ],
          target: "taskingMap",
          view: new View({
            center: _center,
            zoom: _zoom,
            minZoom: 0,
            maxZoom: 20,
          }),
        });
        if (this.propFeature && Object.keys(this.propFeature).length > 0) {
          source.addFeature(this.propFeature);
          var ext = this.propFeature.getGeometry().getExtent();

          this.map.getView().fit(ext, this.map.getSize());
          var center = getCenter(ext);
          this.map.getView().animate({
            center: [center[0], center[1]],
            duration: 100,
            zoom:
              this.map.getView().getZoom() < 2
                ? 2
                : this.map.getView().getZoom() - 1,
          });
        }

        this.$emit("initMap", {
          map: this.map,
          layer: this.layer,
        });
        if (!this.propFeature || Object.keys(this.propFeature).length === 0) {
          this.setUpDrawField();
        }
      } catch (error) {}
    },
    setUpDrawField() {
      try {
        if (Object.keys(this.draw).length > 0) {
          this.map.removeInteraction(this.draw);
        }
        this.clearModify();
        var vm = this;
        this.draw = new Draw({
          source: vm.layer.getSource(),
          type: "Polygon",
        });
        this.map.addInteraction(this.draw);

        let listener;
        this.draw.on("drawstart", function (evt) {
          sketch = evt.feature;
          listener = sketch.getGeometry().on("change", function (evt) {});
        });

        this.draw.on("drawend", function (event) {
          event.feature.set("id", "featureTasking");
          event.feature.id = "featureTasking";
          event.feature.id_ = "featureTasking";

          vm.$emit("drawEnd", event.feature);
          unByKey(listener); // unset sketch
          sketch = null;
          vm.map.removeInteraction(vm.draw);
          setTimeout(() => {
            vm.modifyFeature("featureTasking");
          }, 20);
        });
      } catch (error) {
        console.log(error);
      }
    },

    clearModify() {
      if (Object.keys(this.modify).length > 0) {
        this.map.removeInteraction(this.modify);
        this.modify = Object;
      }
      if (Object.keys(this.select).length > 0) {
        this.map.removeInteraction(this.select);
        this.select = Object;
      }
    },
    editFeature(id) {
      this.clearModify();
      this.modifyFeature(id);
    },
    modifyFeature(id) {
      var vm = this;
      try {
        if (!this.select || Object.keys(this.select).length == 0) {
          let feature = this.layer.getSource().getFeatureById(id);
          this.select = new Select({
            features: feature
              ? new Collection([feature])
              : this.layer.getSource().getFeatures(),

            style: [
              new Style({
                image: new Circle({
                  radius: 4,
                  fill: new Fill({
                    color: vm.hexToRgbA("#fff", 1),
                  }),
                  stroke: new Stroke({
                    color: vm.hexToRgbA("#FFBF23", 0.8),
                    width: 4,
                  }),
                }),
                geometry: function (feature) {
                  var coordinates = feature.getGeometry().getCoordinates()[0];
                  return new MultiPoint(coordinates);
                },
              }),
              new Style({
                fill: new Fill({
                  color: vm.hexToRgbA(vm.fillColor, 0.5),
                }),
                stroke: new Stroke({
                  color: "#FFBF23",
                  width: 2,
                  lineDash: [5, 10],
                }),
              }),
            ],
          });
          this.map.addInteraction(this.select);
        }
        if (!this.modify || Object.keys(this.modify).length == 0) {
          vm.modify = new Modify({
            features: vm.select.getFeatures(),
          });
          vm.map.addInteraction(vm.modify);
          this.modify.on("modifystart", function (evt) {});

          this.modify.on("modifyend", function (event) {
            vm.$emit("modifyEnd", event.features.array_);
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    hexToRgbA(hex, opacity) {
      if (!opacity) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
  },
  computed: {
    basemap() {
      return this.$store.getters.getBasemap;
    },
  },
};
</script>
<style lang="scss" scoped>
.tasking-map {
  width: 100%;
  position: relative;
  #taskingMap {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
}
</style>
