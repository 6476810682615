<template>
  <div class="accordion">
    <div class="accordion-tab">
      <slot name="accordion-header">
        <div class="accordion-header">
          <a :class="active ? 'active' : ''">
            {{ propTitle }}
            <span v-if="active" @click="onHeaderClick"
              ><i class="pi pi-chevron-down"></i
            ></span>
            <span v-else @click="onHeaderClick"
              ><i class="pi pi-chevron-right"></i></span
            >&nbsp;</a
          >
        </div></slot
      >
      <div class="accordion-content" :id="id" :class="active ? 'in' : ''">
        <div :id="id2">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
export default {
  components: {},
  props: {
    propTitle: {
      Type: String,
      default: "",
    },
    propActive: {
      type: Boolean,
      default: true,
    },
    propTimeOut: {
      type: Number,
      default: 100,
    },
    isChevronEnd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: uuidv4(),
      id2: uuidv4(),
      l: undefined,
      w: undefined,
      active: true,
    };
  },
  created() {
    this.active = true;
    if (!this.propActive) {
      setTimeout(() => {
        this.onHeaderClick();
      }, 200);
    }
  },
  mounted() {
    this.w = $("#" + this.id);
    this.l = $("#" + this.id2);
    this.w.height(this.l.outerHeight(true));
    setTimeout(() => {
      this.w.height(!this.active ? 0 : "auto");
      this.w.css("overflow", "visible");
    }, this.propTimeOut);
  },
  methods: {
    onHeaderClick() {
      var vm = this;
      if (this.w.hasClass("in")) {
        this.w.removeClass("in");
        this.w.height(0);
        this.active = false;
        vm.w.css("overflow", "hidden");
      } else {
        setTimeout(() => {
          vm.w.css("overflow", "visible");
          this.w.height("auto");
        }, 100);
        this.w.addClass("in");
        this.w.height(this.l.outerHeight(true));
        this.active = true;
      }
      this.$emit("accordionHeaderClick");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.accordion {
  border-radius: 0.25rem;
  .accordion-header {
    cursor: pointer;
    position: relative;
    margin-top: 1px;
    width: 100%;
    zoom: 1;

    a {
      font-size: 14px;
      display: block;
      padding: 0.5rem 0.5rem;
      border: 1px solid white;
      color: #181c32;
      background-color: white;
      font-weight: 700;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      border-radius: 0.25rem;
      -moz-transition: background-color 0.2s, box-shadow 0.2s;
      -o-transition: background-color 0.2s, box-shadow 0.2s;
      -webkit-transition: background-color 0.2s, box-shadow 0.2s;
      transition: background-color 0.2s, box-shadow 0.2s;
      &.active {
        background-color: white;
        border: 1px solid white;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        color: #181c32 span {
          color: white;
          top: 1px;
        }
      }

      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;

      span {
        color: $background-color-2;
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        background: #eef3f7;
        border-radius: 0.25rem;
        font-size: 0.75rem;
        i.pi {
          position: absolute;
          top: 0.3rem;
          left: 0.3rem;
        }
        &:hover {
          cursor: pointer;
        }
      }
      &:hover {
        cursor: default;
      }
    }
  }
  .accordion-content {
    transition: height 200ms;
    background-color: #ffffff;
    overflow: hidden;
    &.in {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}
</style>
