<template>
  <div class="confirmation-production-parameters_radar">
    <div class="row no-gutters">
      <div class="col-2">
        <label class="col-form-label">{{ $t("label_product_level") }}</label>
      </div>
      <div class="col-10">
        <InputText
          class="form-control new-style w-100"
          maxlength="255"
          :value="
            propDto.order_request_info.processing_level
              ? propDto.order_request_info.processing_level.translation &&
                propDto.order_request_info.processing_level.translation[lang] &&
                propDto.order_request_info.processing_level.translation[lang]
                  .value
                ? propDto.order_request_info.processing_level.translation[lang]
                    .value
                : propDto.order_request_info.processing_level.value
              : ''
          "
          readonly
        />
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-2">
        <label class="col-form-label">{{ $t("label_file_format") }}</label>
      </div>
      <div class="col-10">
        <InputText
          class="form-control new-style w-100"
          maxlength="255"
          :value="
            propDto.order_request_info.file_format
              ? propDto.order_request_info.file_format.translation &&
                propDto.order_request_info.file_format.translation[lang] &&
                propDto.order_request_info.file_format.translation[lang].value
                ? propDto.order_request_info.file_format.translation[lang].value
                : propDto.order_request_info.file_format.value
              : ''
          "
          readonly
        />
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-2">
        <label class="col-form-label">{{ $t("label_delivery_media") }}</label>
      </div>
      <div class="col-10">
        <InputText
          class="form-control new-style w-100"
          maxlength="255"
          :value="
            propDto.order_request_info.delivery_media
              ? propDto.order_request_info.delivery_media.translation &&
                propDto.order_request_info.delivery_media.translation[lang] &&
                propDto.order_request_info.delivery_media.translation[lang]
                  .value
                ? propDto.order_request_info.delivery_media.translation[lang]
                    .value
                : propDto.order_request_info.delivery_media.value
              : ''
          "
          readonly
        />
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-2">
        <label class="col-form-label">{{ $t("label_delivery_service") }}</label>
      </div>
      <div class="col-10">
        <InputText
          class="form-control new-style w-100"
          maxlength="255"
          :value="
            propDto.order_request_info.delivery_service
              ? propDto.order_request_info.delivery_service.translation &&
                propDto.order_request_info.delivery_service.translation[lang] &&
                propDto.order_request_info.delivery_service.translation[lang]
                  .value
                ? propDto.order_request_info.delivery_service.translation[lang]
                    .value
                : propDto.order_request_info.delivery_service.value
              : ''
          "
          readonly
        />
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-2">
        <label class="col-form-label">{{ $t("label_license") }} </label>
      </div>
      <div class="col-10">
        <InputText
          class="form-control new-style w-100"
          maxlength="255"
          :value="
            propDto.order_request_info.license
              ? propDto.order_request_info.license.translation &&
                propDto.order_request_info.license.translation[lang] &&
                propDto.order_request_info.license.translation[lang].value
                ? propDto.order_request_info.license.translation[lang].value
                : propDto.order_request_info.license.value
              : ''
          "
          readonly
        />
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-2">
        <label class="col-form-label">{{ $t("label_note") }}</label>
      </div>
      <div class="col-10">
        <Textarea
          class="form-control new-style"
          rows="2"
          maxlength="512"
          :value="propDto.note"
          :autoResize="true"
          readonly
        >
        </Textarea>
      </div>
    </div>
  </div>
</template>
<script>
import InputText from "@/components/commons/inputtext/InputText";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import Textarea from "@/components/commons/textarea/Textarea";
export default {
  components: {
    Dropdown,
    Textarea,
    InputText,
  },
  props: {
    propDto: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.confirmation-production-parameters_radar {
  padding: 0 0.5rem;
  width: 100%;
  color: $text-color;
  .row {
    margin-bottom: 1rem;
  }
}
</style>
