<template>
  <div class="aoi-info">
    <div class="">
      <div class="geospatial-label font-weight-bold">
        {{ $t("label_name") }}:&nbsp;<strong>{{
          propInfo.name ? propInfo.name : ""
        }}</strong>
      </div>
    </div>
    <div class="">
      <div class="geospatial-label font-weight-bold">
        {{ $t("label_southwest_corner") }}
      </div>
      <div class="geospatial-clear row no-gutters">
        <div class="col-6 row no-gutters">
          <div class="col-9">
            <span
              >{{ $t("label_latitude") }}:&nbsp;<strong>{{
                lat1
              }}</strong></span
            >
          </div>
          <div class="col-3">
            <select v-model="formatLat1">
              <option value="min">min</option>
              <option value="deg">deg</option>
            </select>
          </div>
        </div>
        <div class="col-6 pl-2 row no-gutters">
          <div class="col-9">
            <span
              >{{ $t("label_longitude") }}:&nbsp;<strong>{{
                lng1
              }}</strong></span
            >
          </div>
          <div class="col-3">
            <select v-model="formatLng1">
              <option value="min">min</option>
              <option value="deg">deg</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="geospatial-label font-weight-bold">
        {{ $t("label_northeast_corner") }}
      </div>
      <div class="geospatial-clear row no-gutters">
        <div class="col-6 row no-gutters">
          <div class="col-9">
            <span
              >{{ $t("label_latitude") }}:&nbsp;<strong>{{
                lat3
              }}</strong></span
            >
          </div>
          <div class="col-3">
            <select v-model="formatLat3">
              <option value="min">min</option>
              <option value="deg">deg</option>
            </select>
          </div>
        </div>
        <div class="col-6 pl-2 row no-gutters">
          <div class="col-9">
            <span
              >{{ $t("label_longitude") }}:&nbsp;<strong>{{
                lng3
              }}</strong></span
            >
          </div>
          <div class="col-3">
            <select v-model="formatLng3">
              <option value="min">min</option>
              <option value="deg">deg</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="geospatial-label font-weight-bold">
        {{ $t("label_perimeter") }}
      </div>
      <div class="geospatial-label">{{ perimeterFeature }}</div>
    </div>
    <div class="">
      <div class="geospatial-label font-weight-bold">
        {{ $t("label_area") }}
      </div>
      <div class="geospatial-label">{{ areaFeature }}</div>
    </div>
  </div>
</template>
<script>
var formatLength = function (line) {
  var length = getLength(line);
  var output;
  if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + " " + "km";
  } else {
    output = Math.round(length * 100) / 100 + " " + "m";
  }
  return output;
};
var formatArea = function (polygon) {
  var area = getArea(polygon);
  var output;
  if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + " " + "km²";
  } else {
    output = Math.round(area * 100) / 100 + " " + "m²";
  }
  return output;
};
import { getArea, getLength } from "ol/sphere.js";
import { fromLonLat, transform } from "ol/proj";
import { toStringHDMS } from "ol/coordinate";
export default {
  props: {
    propInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      coordinates: [],
      formatLat1: "deg",
      formatLng1: "deg",
      formatLat3: "deg",
      formatLng3: "deg",
      areaFeature: 0,
      perimeterFeature: 0,
    };
  },
  watch: {
    "propInfo.feature": {
      handler(val) {
        this.coordinates = [];
        if (val) {
          let flatCoordinates = val.getGeometry().flatCoordinates;
          for (let i = 0; i < flatCoordinates.length - 2; i = i + 2) {
            let coor = transform(
              [flatCoordinates[i], flatCoordinates[i + 1]],
              "EPSG:3857",
              "EPSG:4326"
            );
            this.coordinates.push(coor);
          }
          this.areaFeature = formatArea(val.getGeometry());
          this.perimeterFeature = formatLength(val.getGeometry());
        }
      },
      immediate: true,
    },
  },
  computed: {
    lat1() {
      let result = "";
      if (this.coordinates && this.coordinates.length > 0) {
        switch (this.formatLat1) {
          case "deg":
            result = parseFloat(this.coordinates[0][1].toFixed(5));
            break;
          case "min":
            result = this.convertDD2DMS(this.coordinates[0][1], "lat");
            break;
          default:
            result = parseFloat(this.coordinates[0][1].toFixed(5));
            break;
        }
      }
      return result;
    },
    lat3() {
      let result = "";
      if (this.coordinates && this.coordinates.length > 0) {
        switch (this.formatLat3) {
          case "deg":
            result = parseFloat(this.coordinates[2][1].toFixed(5));
            break;
          case "min":
            result = this.convertDD2DMS(this.coordinates[2][1], "lat");
            break;
          default:
            result = parseFloat(this.coordinates[2][1].toFixed(5));
            break;
        }
      }
      return result;
    },
    lng1() {
      let result = "";
      if (this.coordinates && this.coordinates.length > 0) {
        switch (this.formatLng1) {
          case "deg":
            result = parseFloat(this.coordinates[0][0].toFixed(5));
            break;
          case "min":
            result = this.convertDD2DMS(this.coordinates[0][0], "lng");
            break;
          default:
            result = parseFloat(this.coordinates[0][0].toFixed(5));
            break;
        }
      }
      return result;
    },
    lng3() {
      let result = "";
      if (this.coordinates && this.coordinates.length > 0) {
        switch (this.formatLng3) {
          case "deg":
            result = parseFloat(this.coordinates[2][0].toFixed(5));
            break;
          case "min":
            result = this.convertDD2DMS(this.coordinates[2][0], "lng");
            break;
          default:
            result = parseFloat(this.coordinates[2][0].toFixed(5));
            break;
        }
      }
      return result;
    },
  },
  methods: {
    convertDD2DMS(val, c) {
      let result = "";
      let isminus = val < 0 ? true : false;
      let d = parseInt(Math.abs(val));
      let m = parseInt((Math.abs(val) - d) * 60);
      let s = Math.ceil((Math.abs(val) - d - m / 60) * 3600, 1);
      result = d + "° " + m + "' " + s + `"`;
      switch (c.toLowerCase()) {
        case "lat":
          if (isminus) result = result + " S";
          else result = result + " N";
          break;
        case "lng":
          if (isminus) result = result + " W";
          else result = result + " E";
          break;
      }
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
.geospatial-label {
  line-height: 2.2em;
}
.geospatial-clear {
  clear: both;
  input {
    font-size: 13px;
    padding: 0.125rem 0.375rem;
  }
  .geospatial-textbox {
    padding: 5px 4px;
    border: 1px solid #ccc;
    border-top: 1px solid #999;
    font-size: small;
    width: 100%;
    transition: border linear 0.2s, box-shadow linear 0.2s;
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }
}
</style>