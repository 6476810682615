<template>
  <div
    class="map__openlayer"
    id="map__openlayerMetadata"
    :style="{ height: mapHeight + 'px' }"
  ></div>
</template>
<script>
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { Tile as TileLayer } from "ol/layer";
import XYZ from "ol/source/XYZ";
import { defaults as defaultControls } from "ol/control";
import { fromLonLat, transform } from "ol/proj";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
import { Vector as VectorLayer, Image as ImageLayer } from "ol/layer";
import { Vector as VectorSource, ImageCanvas } from "ol/source";
import { Style, Fill, Stroke } from "ol/style.js";

import metaKeyFunc from "@/utils/functions/metakey";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import turf from "turf";
export default {
  props: {
    propSrc: {
      required: true,
    },
    propItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      map: Object,
      layer: Object,
      layerDrawImage: Object,
      styles: {
        footprint: new Style({
          fill: new Fill({
            color: "rgba(0,158,247,0.3)",
          }),
          stroke: new Stroke({
            color: "rgba(0,158,247,1)",
            width: 1.25,
          }),
        }),
        image: new Style({
          fill: new Fill({
            color: "rgba(0,0,0,0)",
          }),
          stroke: new Stroke({
            color: "rgba(0,0,0,0.8)",
            width: 1.25,
          }),
        }),
      },
    };
  },
  async mounted() {
    if (!this.basemap || Object.keys(this.basemap).length === 0)
      await this.getAllBasemaps();
    this.initMapOpenlayer();
  },
  created() {},
  methods: {
    initMapOpenlayer: function () {
      var vm = this;
      var source = new VectorSource({ wrapX: false, id: "sourcedraw" });

      this.layer = new VectorLayer({
        source: source,
      });
      this.layer.set("id", "layerdraw");
      this.map = new Map({
        controls: defaultControls({ attribution: false, zoom: false }),
        layers: [
          new TileLayer({
            title: "Basemap",
            id: "vgbasemap",
            source: new XYZ({
              url:
                vm.basemap &&
                Object.keys(vm.basemap).length > 0 &&
                vm.basemap.source &&
                vm.basemap.source.url
                  ? vm.basemap.source.url
                  : process.env.VUE_APP_BASEMAP_URL,
            }),
            show: true,
            opacity: 1,
          }),
          this.layer,
        ],
        target: "map__openlayerMetadata",
        view: new View({
          center: fromLonLat(
            vm.propPoints && vm.propPoints.length > 0
              ? vm.propPoints[0]
              : [108.222848, 16.062833]
          ),
          zoom: 8,
          minZoom: 0,
          maxZoom: 20,
        }),
      });
      this.$emit("initMap", this.map);
      this.addImage2Map();
    },

    addFootprint: async function (val) {
      try {
        this.addFeature(
          this.layer,
          val.geometry.coordinates[0],
          this.styles.footprint
        );
      } catch (error) {
        console.error("addFootprint", error);
      }
    },
    addFeature(layer, coordinates, style) {
      let id =
        this.propItem && this.propItem.properties && this.propItem.properties.id
          ? this.propItem.properties.id
          : new Date().getTime();
      let feature = layer.getSource().getFeatureById(id);
      if (!feature) {
        var points = [];
        try {
          for (var i = 0; i < coordinates.length; i++) {
            points[i] = transform(coordinates[i], "EPSG:4326", "EPSG:3857");
          }
          feature = new Feature({
            geometry: new Polygon([points]),
            id: id,
          });
          feature.set("id", id);
          feature.id_ = id;
          feature.setStyle(style);
          layer.getSource().addFeature(feature);
          let ext = feature.getGeometry().getExtent();
          this.map.getView().fit(ext, this.map.getSize());
          this.map.getView().setZoom(this.map.getView().getZoom() - 1);
        } catch (error) {
          console.error("addFeature", error);
        }
      } else {
        feature.style = { visibility: "visible" };
      }
    },
    fintNearPoint(coors, point) {
      try {
        let result = coors[0];
        let max = Number.MAX_VALUE;
        for (let i = 0; i < coors.length; i++) {
          var distance = turf.distance(
            turf.point(coors[i]),
            turf.point(point),
            null
          );
          if (distance < max) {
            max = distance;
            result = coors[i];
          }
        }
        return result;
      } catch (error) {
        console.log(error);
      }
    },
    addImage2Map: async function () {
      var vm = this;
      var corners = [
        { x: 100, y: 100 }, // ul
        { x: 520, y: 20 }, // ur
        { x: 520, y: 380 }, // br
        { x: 100, y: 380 }, // bl
      ];

      await this.addFootprint(this.propItem);
      let feature = this.layer
        .getSource()
        .getFeatureById(this.propItem.properties.id);

      var lcoordinates = [],
        pixels = [];
      if (
        this.propItem.geometry &&
        this.propItem.geometry.coordinates &&
        this.propItem.geometry.coordinates.length > 0
      ) {
        let coordinates =
          this.propItem.geometry &&
          this.propItem.geometry.coordinates &&
          this.propItem.geometry.coordinates.length > 0
            ? this.propItem.geometry.coordinates[0]
            : [];
        let extenFeature = feature.getGeometry().getExtent();
        let bottomLeftExtent = transform(
          [extenFeature[0], extenFeature[1]],
          "EPSG:3857",
          "EPSG:4326"
        );
        let topRightExtent = transform(
          [extenFeature[2], extenFeature[3]],
          "EPSG:3857",
          "EPSG:4326"
        );
        let topLeftExtent = [bottomLeftExtent[0], topRightExtent[1]];
        let bottomRightExtent = [topRightExtent[0], bottomLeftExtent[1]];
        let topleft = await this.fintNearPoint(coordinates, topLeftExtent);

        let topRight = await this.fintNearPoint(coordinates, topRightExtent);
        let bottomRight = await this.fintNearPoint(
          coordinates,
          bottomRightExtent
        );

        let bottomLeft = await this.fintNearPoint(
          coordinates,
          bottomLeftExtent
        );
        lcoordinates.push(topleft);
        lcoordinates.push(topRight);
        lcoordinates.push(bottomRight);
        lcoordinates.push(bottomLeft);
      }
      if (lcoordinates.length < 4) return;
      var bbox = {
        WKID: "EPSG:4326",
        coord: lcoordinates,
      };
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      var img = new Image();
      img.src = vm.propSrc;
      img.onload = function () {};
      var canvasFunction = function (
        extent,
        resolution,
        pixelRatio,
        size,
        projection
      ) {
        var canvasWidth = size[0],
          canvasHeight = size[1];
        canvas.setAttribute("width", canvasWidth);
        canvas.setAttribute("height", canvasHeight);
        var mapExtent = vm.map.getView().calculateExtent(vm.map.getSize());
        var canvasOrigin = vm.map.getPixelFromCoordinate([
          extent[0],
          extent[3],
        ]);
        var mapOrigin = vm.map.getPixelFromCoordinate([
          mapExtent[0],
          mapExtent[3],
        ]);
        var delta = [
          pixelRatio * mapOrigin[0] - pixelRatio * canvasOrigin[0],
          pixelRatio * mapOrigin[1] - pixelRatio * canvasOrigin[1],
        ];

        var points = bbox.coord;
        if (bbox.WKID != "EPSG:3857") {
          // Verifier egalité sur chaine
          for (var i = 0; i < points.length; i++) {
            points[i] = transform(points[i], bbox.WKID, "EPSG:3857");
          }
          bbox.WKID = "EPSG:3857";
        }
        for (var i = 0; i < points.length; i++) {
          pixels[i] = vm.map.getPixelFromCoordinate(points[i]);
          pixels[i][0] = pixelRatio * pixels[i][0] + delta[0];
          pixels[i][1] = pixelRatio * pixels[i][1] + delta[1];
          corners[i].x = pixels[i][0];
          corners[i].y = pixels[i][1];
        }
        // draw a box
        function drawBBox(mybbox) {
          context.save();
          context.moveTo(pixels[0][0], pixels[0][1]);
          context.lineTo(pixels[1][0], pixels[1][1]);
          context.lineTo(pixels[2][0], pixels[2][1]);
          context.lineTo(pixels[3][0], pixels[3][1]);
          context.closePath();
          context.lineWidth = 1;
          context.stroke();
          context.restore();
        }

        var p = new Perspective(context, img);
        if (p) {
          p.draw([
            [corners[0].x, corners[0].y],
            [corners[1].x, corners[1].y],
            [corners[2].x, corners[2].y],
            [corners[3].x, corners[3].y],
          ]);
        }
        //drawBBox(bbox);
        return canvas;
      };
      var canvasLayer = new ImageLayer({
        source: new ImageCanvas({
          canvasFunction: canvasFunction,
          projection: "EPSG:3857",
        }),
      });
      canvasLayer.setZIndex(vm.map.getLayers().array_.length);
      canvasLayer.set("id", this.propItem.properties.id);
      vm.map.addLayer(canvasLayer);
      vm.map.render();
    },
    async getAllBasemaps() {
      try {
        let response = await metaKeyFunc.getByKey(
          "BASEMAPS",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            let items = [];
            let data = response.data.data ? response.data.data : [];
            for (let i = 0; i < data.length; i++) {
              try {
                let obj = JSON.parse(data[i].value);
                if (obj && Object.keys(obj).length > 0) {
                  items.push({
                    id: data[i].id,
                    type:
                      obj.type && obj.type === "shapefile"
                        ? "JSON_FILE"
                        : "TILE_LAYER",
                    name: data[i].name,
                    title: obj.name ? obj.name : obj.NAME,
                    value: data[i].value,
                    description: data[i].description,
                    id:
                      new Date().getTime() -
                      Math.floor(Math.random() * 99999) +
                      Math.floor(Math.random() * 10000),
                    show:
                      (obj.is_show && obj.is_show + "" === "1") ||
                      (obj.IS_SHOW && obj.IS_SHOW + "" === "1")
                        ? true
                        : false,
                    opacity: 100,
                    basemap: true,
                    source: {
                      type: "TILE_IMAGE",
                      url: obj.path ? obj.path : obj.PATH,
                    },
                  });
                }
              } catch (error) {}
            }
            if (items && items.length > 0) {
              if (!this.basemap || Object.keys(this.basemap).length === 0) {
                let arrayBasemaps = items.filter(
                  (x) => x.show === true && (!x.type || x.type === "TILE_LAYER")
                );
                if (arrayBasemaps && arrayBasemaps.length > 0)
                  this.$store.dispatch(
                    "setBasemapDefault",
                    arrayBasemaps[arrayBasemaps.length - 1]
                  );
              }
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllBasemaps);
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {});
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {});
      }
      localStorage.removeItem("lock-refresh");
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    mapHeight() {
      let dom = document.getElementById("tableMetadata");
      if (dom) {
        return dom.offsetHeight;
      } else return 500;
    },
    basemap() {
      return this.$store.getters.getBasemap;
    },
  },
};
</script>
<style lang="scss" scoped>
.map__openlayer {
  position: absolute;
  width: 100%;
  padding: 0;
  border: 1px solid #eee;
  max-height: 1000px;
}
</style>
