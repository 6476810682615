<template>
  <div class="myself">
    <spinner
      v-if="loading.show"
      :propLoading="loading.show"
      :propShape="'tile'"
      :prop-text="loading.text"
    ></spinner>
    <router-view @initBreadcrumb="initBreadcrumb"></router-view>
  </div>
</template>
<script>
import Spinner from "@/components/commons/spinners/Index";
export default {
  components: {
    Spinner,
  },
  computed: {
    loading() {
      return this.$store.getters.getSpinnerApp;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
  methods: {
    initBreadcrumb(data) {
      this.$emit("initBreadcrumb", data);
    },
  },
};
</script>
<style>
.myself {
  background: #f3f6f8;
  min-height: calc(100vh - 56px);
}
</style>