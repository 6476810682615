<template>
  <div class="container-fluid">
    <div class="outer">
      <div class="middle">
        <div class="user-info">
          <div class="user-info__dialog">
            <DialogMessage
              v-if="message.length > 0"
              :propTitle="$t('title_message_info')"
              :prop-show="message.length > 0"
              :prop-message="message"
              @close="message = []"
            />
          </div>
          <div class="user-info__structure">
            <ValidationObserver ref="infoObserver">
              <div class="align-middle">
                <div class="form-group">
                  <label class="w-100"
                    >{{ $t("label_full_name") }}&nbsp;
                    <span class="font-weight-bold required">*</span></label
                  >
                  <ValidationProvider
                    :name="$t('label_full_name')"
                    rules="required"
                  >
                    <InputText
                      type="text"
                      class="w-100 form-control new-style"
                      v-model="entity.full_name"
                      maxLength="255"
                    />
                  </ValidationProvider>
                </div>

                <div class="form-group">
                  <label class="w-100"
                    >{{ $t("label_organisation_name") }}&nbsp;
                    <span class="font-weight-bold required">*</span></label
                  >
                  <ValidationProvider
                    :name="$t('label_organisation_name')"
                    rules="required"
                  >
                    <InputText
                      type="text"
                      class="w-100 form-control new-style"
                      v-model="entity.organization"
                      maxLength="255"
                    />
                  </ValidationProvider>
                </div>

                <div class="form-group">
                  <div class="d-flex">
                    <div
                      class="p-field p-col pr-3"
                      v-for="(item, index) in dataGender"
                      :key="item.id"
                    >
                      <label
                        for="male"
                        class="p-radiobutton-label pr-2"
                        :class="index === 0 ? 'mr-0 pl-0' : ''"
                      >
                        {{ $t("label_" + item.code) }}
                      </label>
                      <RadioButton
                        :id="'radio' + item.id"
                        :name="item.code"
                        :value="item.id"
                        v-model="entity.gender"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="w-100"
                    >{{ $t("label_email") }}&nbsp;
                    <span class="font-weight-bold required">*</span></label
                  >
                  <ValidationProvider
                    :name="$t('label_email')"
                    rules="required|email"
                  >
                    <InputText
                      type="email"
                      class="w-100 form-control new-style"
                      v-model="entity.email"
                      maxLength="150"
                    />
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label class="w-100"
                    >{{ $t("label_phone_number") }}&nbsp;
                    <span class="font-weight-bold required">*</span></label
                  >
                  <ValidationProvider
                    :name="$t('label_phone_number')"
                    rules="required"
                  >
                    <InputText
                      type="tel"
                      class="w-100 form-control new-style"
                      v-model="entity.phone_number"
                      maxLength="10"
                    />
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label class="w-100">{{ $t("label_address") }}</label>
                  <Textarea
                    v-model="entity.address"
                    :autoResize="true"
                    rows="4"
                    class="w-100 new-style"
                  />
                </div>
                <button
                  :label="$t('button_text_save')"
                  icon=""
                  class="btn btn-geostore2 w-100"
                  @click="onSave"
                >
                  <i class="fas fa-save"></i>&nbsp;{{ $t("button_text_save") }}
                </button>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RadioButton from "@/components/commons/radiobutton/RadioButton";
import Textarea from "@/components/commons/textarea/Textarea";
import InputText from "@/components/commons/inputtext/InputText";
import Button from "@/components/commons/button/Button";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import userMeControllerFunc from "@/utils/functions/usermecontroller";
import { isVietnamesePhoneNumber } from "@/utils/commons/common";
export default {
  components: {
    Textarea,
    InputText,
    Button,
    DialogMessage,
    RadioButton,
  },
  data() {
    return {
      message: [],
      entity: {
        full_name: null,
        organization: null,
        phone_number: null,
        email: null,
        address: null,
        gender: null,
      },
      dataGender: [
        {
          id: "1",
          code: "male",
        },
        {
          id: "2",
          code: "female",
        },
        {
          id: "3",
          code: "other",
        },
      ],
    };
  },
  created() {
    this.$emit("initBreadcrumb", ["menu_sidebar_account", "label_my_info"]);
  },
  mounted() {
    if (this.userMe && Object.keys(this.userMe).length > 0) {
      this.entity.full_name = this.userMe.full_name
        ? this.userMe.full_name.trim().replace(/\s\s+/g, " ")
        : null;
      this.entity.organization = this.userMe.organization
        ? this.userMe.organization.trim().replace(/\s\s+/g, " ")
        : null;
      this.entity.email = this.userMe.email
        ? this.userMe.email.trim().replace(/\s\s+/g, " ")
        : null;
      this.entity.phone_number = this.userMe.phone_number
        ? this.userMe.phone_number.trim().replace(/\s\s+/g, " ")
        : null;
      this.entity.address = this.userMe.address
        ? this.userMe.address.trim().replace(/\s\s+/g, " ")
        : null;
      this.entity.organization = this.userMe.organization
        ? this.userMe.organization.trim().replace(/\s\s+/g, " ")
        : null;
      this.entity.gender = this.userMe.gender ? this.userMe.gender + "" : null;
    }
  },
  computed: {
    userMe() {
      return this.$store.getters.getUser;
    },
  },
  watch: {
    userMe(val) {
      if (this.userMe && Object.keys(this.userMe).length > 0) {
        this.entity.full_name = this.userMe.full_name
          ? this.userMe.full_name.trim().replace(/\s\s+/g, " ")
          : null;
        this.entity.organization = this.userMe.organization
          ? this.userMe.organization.trim().replace(/\s\s+/g, " ")
          : null;
        this.entity.email = this.userMe.email ? this.userMe.email.trim() : null;
        this.entity.phone_number = this.userMe.phone_number
          ? this.userMe.phone_number.trim().replace(/\s\s+/g, " ")
          : null;
        this.entity.address = this.userMe.address
          ? this.userMe.address.trim().replace(/\s\s+/g, " ")
          : null;
        this.entity.gender = this.userMe.gender
          ? this.userMe.gender + ""
          : null;
      }
    },
  },
  methods: {
    async onSave() {
      var vm = this;
      this.$refs.infoObserver.validate().then(async (success) => {
        if (!success) {
          setTimeout(() => {
            Object.keys(vm.$refs.infoObserver.errors).forEach(function (key) {
              if (
                vm.$refs.infoObserver.errors[key] &&
                vm.$refs.infoObserver.errors[key].length
              )
                vm.message.push(vm.$refs.infoObserver.errors[key].join(",\n"));
            });
          }, 100);
          return;
        } else {
          // if (
          //   !isVietnamesePhoneNumber(
          //     this.entity.phone_number
          //       ? this.entity.phone_number.trim().replace(/\s\s+/g, " ")
          //       : null
          //   )
          // ) {
          //   this.message.push(
          //     this.$t("message_phone_number_is_not_correct_format")
          //   );
          //   return;
          // }
          let dto = Object.assign({}, this.entity);

          dto.full_name = dto.full_name
            ? dto.full_name.trim().replace(/\s\s+/g, " ")
            : null;
          dto.email = dto.email
            ? dto.email.trim().replace(/\s\s+/g, " ")
            : null;
          dto.phone_number = dto.phone_number
            ? dto.phone_number.trim().replace(/\s\s+/g, " ")
            : null;
          dto.address = dto.address
            ? dto.address.trim().replace(/\s\s+/g, " ")
            : null;
          dto.organization = dto.organization
            ? dto.organization.trim().replace(/\s\s+/g, " ")
            : null;
          try {
            let response = await userMeControllerFunc.update(
              dto,
              this.$store.getters.getAccessToken
            );
            let msg = null;
            if (response && response.status === 200) {
              if (response.data && response.data.success) {
                this.$toast.success({
                  title: this.$t("title_message_info"),
                  message: this.$t("message_my_info_success"),
                  position: "top right",
                });
                this.$store.dispatch("updateInfo", dto);
              } else {
                msg = this.$t("message_my_info_error");
              }
            } else msg = this.$t("message_my_info_error");

            if (msg) this.message.push(msg);
          } catch (error) {
            if (error.response && error.response.status === 401) {
              this.refreshToken(this.onSave);
            } else {
              let msg = null;
              msg =
                error.response.data && error.response.data.message
                  ? error.response.data.message
                  : null;
              if (!msg) this.$t("message_my_info_error");
              this.message.push(msg);
            }
          }
        }
      });
    },

    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.container-fluid {
  position: relative;
  width: 100%;
  height: calc(100vh - 56px);
}
.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1080;

  .middle {
    display: table-cell;
    vertical-align: middle;

    .user-info {
      background: white;
      -webkit-box-shadow: 0px 0px 2px 0px rgba(2, 34, 89, 1);
      -moz-box-shadow: 0px 0px 2px 0px rgba(2, 34, 89, 1);
      box-shadow: 0px 0px 2px 0px rgba(2, 34, 89, 1);
      border-radius: 0.25rem;
      margin-left: auto;
      margin-right: auto;
      padding: 1.5rem;
      width: 560px;
      input,
      label,
      button {
        font-size: 14px;
      }
      label {
        padding-left: 0.25rem;
        color: $text-color;
        font-weight: 500;
      }
    }
  }
}
</style>

<style lang="scss">
.user-info {
  .form-group {
    .p-radiobutton .p-radiobutton-box {
      background: #eef3f7;
      border-color: #eef3f7;
    }
  }
}
</style>
