var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.dialogDownloadAoi.show)?_c('DialogDownloadAoi',{attrs:{"prop-title":_vm.dialogDownloadAoi.title,"prop-show":_vm.dialogDownloadAoi.show,"prop-style":{
      width: _vm.dialogDownloadAoi.width,
      overflowY: 'inherit!important',
    },"prop-value":_vm.dialogDownloadAoi.value},on:{"close":function($event){_vm.dialogDownloadAoi.show = false;
      _vm.dialogDownloadAoi.value = null;},"exportKML":_vm.exportKML}}):_vm._e(),_c('div',{staticClass:"_3Hueb8NdkKhJBFbr6Fa"},[_c('div',{staticClass:"_4Unfhr6dnJnhwh1HbFd define"},[_c('define',{ref:"define",attrs:{"map":_vm.map,"propFeature":_vm.feature},on:{"drawEnd":function($event){return _vm.drawEnd($event)},"clearSearch":_vm.clearSearch,"actionClick":_vm.actionClick,"modifiedFeature":_vm.modifiedFeature,"showPopupAoiInfo":_vm.showPopupAoiInfo,"showDialogExport":_vm.showDialogExport,"clearAllAoi":_vm.clearAllAoi,"setAoiFeature":_vm.setAoiFeature}})],1)]),(_vm.isShow)?_c('div',{staticClass:"animated bounceInDown"},[_c('keep-alive',[_c(_vm.componentActive,{tag:"component",attrs:{"propCriteria":_vm.criteria,"map":_vm.map,"propData":{
          projects: _vm.componentActive === 'Criteria' ? _vm.projects : null,
          address:
            _vm.componentActive === 'ListAddress' ? _vm.criteria.address.items : null,
          items: _vm.componentActive === 'Models' ? _vm.objsImage : null,
        }},on:{"onSearch":_vm.onSearch,"close":_vm.close,"message":_vm.onMessage,"initCriteria":_vm.initCriteria}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }