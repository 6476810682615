<template>
  <div id="app">
    <spinner
      :propLoading="loading"
      :propShape="'square'"
      v-if="loading"
    ></spinner>
    <div v-else>
      <login-form
        v-if="isLoginForm"
        @loginSuccess="loginSuccess($event)"
      ></login-form>
      <ForgotPasswordForm v-if="isResetForm" />
      <router-view />
    </div>
    <div class="HyiJ5emoe8Hs" v-if="isWidgetUploadProgress">
      <WidgetUploadProgressBar
        ref="wgUploadProgressBar"
        :isLayoutDefault="['/', ''].includes(routePath)"
        :prop-show="isWidgetUploadProgress"
      />
    </div>

    <WidgetDownloadFiles
      v-if="widget.download.show"
      :prop-items="filesDownload"
      :prop-show="widget.download.show2"
      :isLayoutDefault="$route.path === '/' ? true : false"
      @close="closeWidgetDownload"
    />
  </div>
</template>
<script>
import Spinner from "@/components/commons/spinners/Index";
import LoginForm from "@/components/pages/login/Index";
import ForgotPasswordForm from "@/components/pages/login/ForgotPassword";
import Button from "@/components/commons/button/Button";
import WidgetUploadProgressBar from "@/components/commons/widget/UploadProgressBar";
import WidgetDownloadFiles from "@/components/commons/widget/DownloadFiles";
import cryptoFunc from "@/utils/functions/crypto";
import oauthFunc from "@/utils/functions/oauth";
export default {
  components: {
    LoginForm,
    Spinner,
    Button,
    WidgetUploadProgressBar,
    WidgetDownloadFiles,
    ForgotPasswordForm,
  },
  data() {
    return {
      loading: true,
      counterTime: 0,
      intervelMethod: null,
      lockRefresh: null,
      widget: {
        download: {
          show: false,
          show2: false,
        },
      },
    };
  },
  async created() {
    // getOneAtlasToken
    let oneAtlasToken = localStorage.getItem("oneatlas-token");
    if (oneAtlasToken && oneAtlasToken.trim()) {
      try {
        let plaintext = await cryptoFunc.decrypt(atob(oneAtlasToken));
        if (plaintext) {
          let data = JSON.parse(plaintext);
          if (data) {
            this.$store.dispatch("setOneAtlasToken", data);
          } else this.$store.dispatch("clearOneAtlasToken");
        } else this.$store.dispatch("clearOneAtlasToken");
      } catch (error) {
        this.$store.dispatch("clearOneAtlasToken");
      }
    } else this.$store.dispatch("clearOneAtlasToken");

    // getOneAtlasToken
    let capellaToken = localStorage.getItem("capella-token");
    if (capellaToken && capellaToken.trim()) {
      try {
        let plaintext = await cryptoFunc.decrypt(atob(capellaToken));
        if (plaintext) {
          let data = JSON.parse(plaintext);
          if (data) {
            this.$store.dispatch("setCapellaToken", data);
          } else this.$store.dispatch("clearCapellaToken");
        } else this.$store.dispatch("clearCapellaToken");
      } catch (error) {
        this.$store.dispatch("clearCapellaToken");
      }
    } else this.$store.dispatch("clearCapellaToken");
    // Spinner circle or square
    if (
      this.$route &&
      (this.$route.name === "Manual" || this.$route.name === "ContactUs")
    ) {
      this.loading = false;
      return;
    }
    this.loading = true;
    let checkData = false;
    let data = await this.decryptDataOauth();
    if (data && Object.keys(data).length > 0) {
      let time = data.time ? data.time : null;
      let ttl = data.expires_in ? data.expires_in : null;
      if (time && ttl) {
        if (new Date().getTime() - time < ttl * 1000 - 5) {
          checkData = true;
          this.$store.dispatch("setToken", data);
          if (this.$route.name === "Login") {
            this.$router.push({
              path: "/",
            });
          }
        }
      }
      if (!checkData) {
        let refreshToken = data.refresh_token ? data.refresh_token : null;
        if (refreshToken) {
          try {
            let check = await this.checkRefreshToken(refreshToken);
            if (check) {
              if (this.$route.name === "Login") {
                this.$router.push({
                  path: "/",
                });
              }
            } else {
              this.$store.dispatch("clearToken").then((r) => {
                if (
                  this.$route.path != "/" &&
                  this.$route.name != "Signup" &&
                  this.$route.name != "Login"
                ) {
                  this.$router.push({
                    path: "/",
                    query: {
                      next: btoa(cryptoFunc.encrypt(this.$route.path)),
                    },
                  });
                }
              });
            }
          } catch (error) {
            this.$store.dispatch("clearToken").then((r) => {
              if (
                this.$route.name != "Home" &&
                this.$route.name != "Signup" &&
                this.$route.name != "Login"
              ) {
                this.$router.push({
                  name: "Home",
                });
              }
            });
          }
        } else {
          this.$store.dispatch("clearToken").then((r) => {
            if (
              this.$route.name != "Home" &&
              this.$route.name != "Signup" &&
              this.$route.name != "Login"
            ) {
              this.$router.push({
                name: "Home",
              });
            }
          });
        }
      }
    } else {
      this.$store.dispatch("clearToken").then((r) => {
        if (
          this.$route.path != "/" &&
          this.$route.name != "Signup" &&
          this.$route.name != "Login"
        ) {
        }
      });
    }
    setTimeout(() => {
      this.loading = false;
    }, 150);
    this.onResize();
  },

  watch: {
    loggedIn: {
      handler(val) {
        if (val) {
          if (this.intervelMethod) {
            clearInterval(this.intervelMethod);
            this.intervelMethod = null;
          }
          this.intervelMethod = setInterval(
            this.intervalCheckRefreshToken,
            10000
          );
          this.counterTime = 10;
        } else {
          if (this.intervelMethod) {
            clearInterval(this.intervelMethod);
            this.intervelMethod = null;
          }
        }
      },
      immediate: true,
    },
    filesDownload(val) {
      if (val && val.length > 0) {
        this.widget.download.show2 = true;
        this.widget.download.show = true;
      } else {
        this.closeWidgetDownload();
      }
    },
    lang(val) {
      //document.title = this.$t("label_company_name");
    },
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    spinner() {
      return this.$store.getters.getSpinner;
    },
    expiresIn() {
      return this.$store.getters.getExpiresIn;
    },
    isLoginForm() {
      return this.$store.getters.getIsLoginForm;
    },
    isResetForm() {
      return this.$store.getters.getIsResetForm;
    },
    routePath() {
      return this.$route.path;
    },
    isWidgetUploadProgress() {
      return this.$store.getters.getIsWidgetUploadProgress;
    },
    filesDownload() {
      return this.$store.getters.getFilesDownload;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
  mounted() {
    var vm = this;
    window.addEventListener("resize", vm.onResize, { passive: true });

    setTimeout(() => {
      Event.$on("imageProgressBar", (file) => {
        if (vm.$refs.wgUploadProgressBar) {
          vm.$refs.wgUploadProgressBar.addFile(file);
        }
      });
    }, 2000);
  },
  methods: {
    closeWidgetDownload() {
      this.widget.download.show2 = false;
      setTimeout(() => {
        this.widget.download.show = false;
      }, 200);
    },
    async loginSuccess(val) {
      await this.$store.dispatch("setToken", val);
      await localStorage.setItem(
        "data",
        btoa(cryptoFunc.encrypt(JSON.stringify(val)).toString())
      );
      this.$store.dispatch("setIsLoginForm", false);
      try {
        const urlParams = new URLSearchParams(window.location.search);
        let path = urlParams.get("next");

        if (path) {
          try {
            path = await cryptoFunc.decrypt(atob(path));
          } catch (error) {
            path = "/";
          }
        } else {
          if (this.$route.path != "/login") path = this.$route.path;
          else path = "/";
        }
        if (!path) path = "/";
        let resolved = this.$router.resolve(path);
        if (resolved.route.name || resolved.route.name != "404") {
          if (path === "/login") {
            this.$router.push({
              path: "/",
            });
          } else {
            if (path != this.$route.path)
              this.$router.push({
                path: path,
              });
          }
        } else {
          if (this.$route.path != "/") {
            this.$router.push({
              path: "/",
            });
          }
        }
      } catch (error) {
        if (this.$route.path != "/") {
          this.$router.push({
            path: "/",
          });
        }
      }
    },
    async intervalCheckRefreshToken() {
      this.lockRefresh = localStorage.getItem("lockRefresh");
      if (this.lockRefresh) return;

      if (
        this.expiresIn === 0 ||
        (this.counterTime >= this.expiresIn - 29 &&
          this.counterTime <= this.expiresIn)
      ) {
        this.counterTime = this.expiresIn + 1;

        // decript data
        try {
          let str = localStorage.getItem("data");
          let plaintext = await cryptoFunc.decrypt(atob(str));
          if (plaintext) {
            let data = JSON.parse(plaintext);
            if (data && Object.keys(data).length > 0) {
              let time = data.time ? data.time : null;
              let ttl = data.expires_in ? data.expires_in : null;
              if (time && ttl) {
                if (new Date().getTime() - time < ttl * 1000 - 5) {
                  this.$store.dispatch("setToken", data);
                  this.counterTime = 0;
                  return;
                }
              }
            }
          }
        } catch (error) {}
        // end decript data
        localStorage.setItem("lockRefresh", true);
        let check = await this.checkRefreshToken(
          this.$store.getters.getRefreshToken
        );
        localStorage.removeItem("lockRefresh");
        if (!check) {
          this.$store.dispatch("clearToken").then((r) => {
            // if (this.$route.name != "Home") {
            //   this.$router.push({
            //     name: "Home",
            //   });
            // }
          });
        }
      } else this.counterTime = this.counterTime + 10;
    },
    async decryptDataOauth() {
      try {
        let plaintext = await cryptoFunc.decrypt(
          atob(localStorage.getItem("data"))
        );
        if (plaintext) {
          let data = JSON.parse(plaintext);
          if (data) {
            return new Promise(function (resolve, reject) {
              resolve(data);
            });
          } else return {};
        } else return {};
      } catch (error) {
        return {};
      }
    },
    async checkRefreshToken(refreshToken) {
      var vm = this;
      return new Promise(async function (resolve, reject) {
        try {
          let lockRefresh = localStorage.getItem("lock-refresh");
          if (lockRefresh != null || lockRefresh != undefined) {
            if (
              lockRefresh &&
              (lockRefresh + "").trim().toLowerCase() === "true"
            ) {
              resolve(true);
              return;
            }
          }
          localStorage.setItem("lock-refresh", true);
          let response = await oauthFunc.refresh(refreshToken);
          if (response && response.status === 200) {
            vm.counterTime = 0;
            await vm.$store.dispatch("setToken", response.data);

            response.data.time = new Date().getTime();
            await localStorage.setItem(
              "data",
              btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
            );
            resolve(true);
            localStorage.removeItem("lock-refresh");
          } else {
            localStorage.removeItem("lock-refresh");
            vm.$store.dispatch("clearToken");
            resolve(false);
          }
        } catch (error) {
          localStorage.removeItem("lock-refresh");
          vm.$store.dispatch("clearToken");
          resolve(false);
        }

        localStorage.removeItem("lock-refresh");
      });
    },
    onResize() {
      this.$store.dispatch(
        "setDocumentWidth",
        document.documentElement.clientWidth
      );
      this.$store.dispatch(
        "setDocumentHeight",
        document.documentElement.clientHeight
      );
    },
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      var vm = this;
      window.removeEventListener("resize", vm.onResize, { passive: true });
    }

    if (this.intervelMethod) {
      clearInterval(this.intervelMethod);
      this.intervelMethod = null;
    }

    localStorage.removeItem("lock-refresh");
  },
};
</script>
<style lang="scss">
#app {
  font-family: "Helvetica Neue", Avenir, Helvetica, Arial, sans-serif;
  color: #1e1e2d !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px !important;
}

.mx-input {
  border-color: #a6a6a6 !important;
  &:hover {
    border-color: #212121 !important;
  }
  &:disabled,
  .disabled {
    background-color: transparent;
  }
}
.datatable {
  .p-datatable {
    .p-datatable-thead > tr > th {
      text-align: left !important;
    }
    .p-datatable-tbody > tr:nth-child(even) {
      background: white;
    }
    .bg-gray {
      background: #f4f4f4cb !important;
    }
    td {
      button {
        padding: 0.429em 0.6125em;
      }
    }
    td {
      &.p-frozen-column {
        position: sticky;
        right: 0;
      }
    }
    th {
      &.p-frozen-column {
        position: sticky;
        right: 0;
      }
    }
  }
}
.HyiJ5emoe8Hs {
  position: fixed;
  bottom: 0.25rem;
  right: 0.25rem;
  width: 350px;
  height: 300px;
}
</style>
