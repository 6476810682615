<template>
  <div class="preview-icon mr-2 mt-0 border">
    <div
      class="spinner-border spinner-border-sm align-items-center mt-4"
      role="status"
      v-if="loading"
    >
      <span class="sr-only">{{ $t("label_loading") }}</span>
    </div>
    <div v-else>
      <img
        v-if="!fullPath"
        :src="'/img/icons/no-image.png'"
        class="preview-image no-image"
      />
      <!-- "enpoint + propSrc" -->
      <img
        :src="fullPath"
        class="preview-image"
        @click.stop="showPreviewImage"
        v-else
        onerror="this.src='/img/icons/no-image.png'"
      />
    </div>
  </div>
</template>
<script>
import fileFunc from "@/utils/functions/file";
import { enpointPreviewUrl } from "@/utils/commons/environment";
import axios from "axios";
export default {
  props: {
    propId: {
      type: String,
      default: "",
    },
    propSrc: {
      required: true,
    },
    propPreviewImages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      iconSrc: null,
      enpoint: enpointPreviewUrl,
      loading: false,
      fullPath: null,
    };
  },
  created() {
    if (this.propPreviewImages && this.propPreviewImages.length > 0) {
      let icons = this.propPreviewImages.filter((x) => (x.type = "ICON"));
      if (icons && icons.length === 0)
        icons = this.propPreviewImages.filter((x) => (x.type = "PREVIEW"));
      if (this.propSrc && this.propSrc.trim()) {
        let item = icons.find((x) => x.path === this.propSrc);
        let ext = null;
        if (item && Object.keys(item).length > 0) {
          ext = item.ext ? item.ext : null;
        }
        if (ext && ["tif", "tiff"].includes(ext.trim().toLowerCase())) {
          this.getImageTif(this.enpoint + this.propSrc);
        } else {
          this.fullPath = this.enpoint + this.propSrc;
        }
      }
    } else {
      this.fullPath =
        this.propSrc && this.propSrc.trim()
          ? this.enpoint + this.propSrc
          : null;
    }
  },
  mounted() {},

  watch: {
    propId: {
      handler(val) {
        if (!this.propSrc) {
          // if (this.propSrc) this.iconSrc = this.propSrc;
          // else this.getIconPreview();
        }
      },
      immediate: true,
    },
    propSrc: {
      handler(val) {
        this.iconSrc = val;
      },
      immediate: true,
    },
  },
  methods: {
    async getImageTif(url) {
      this.loading = true;
      try {
        return axios({
          url: url,
          method: "get",
          responseType: "blob",
        })
          .then(async (response) => {
            if (response && response.status === 200) {
              let blob = new Blob([response.data], {
                type: "image/tiff",
              });
              if (blob.size != 0) {
                if (this.propModel === "TSX-1") {
                  const tiff = await GeoTIFF.fromBlob(blob);
                  const image = await tiff.getImage();
                  const data = await image.readRasters();
                  const canvas = document.createElement("canvas");
                  const plot = new plotty.plot({
                    canvas,
                    data: data[0],
                    width: image.getWidth(),
                    height: image.getHeight(),
                    domain: [0, 256],
                    colorScale: "greys",
                  });
                  plot.render();
                  this.fullPath = canvas.toDataURL("image/tiff");
                  this.$emit("initSuccessIconImage", {
                    id: this.propId,
                    val: canvas.toDataURL("image/tiff"),
                  });
                } else {
                  let buffer = await blob.arrayBuffer();
                  var tiff = new Tiff({ buffer: buffer });
                  var canvas = tiff.toCanvas();
                  this.fullPath = canvas.toDataURL("image/tiff");
                  this.$emit("initSuccessIconImage", {
                    id: this.propId,
                    val: canvas.toDataURL("image/tiff"),
                  });
                }
              } else {
                this.fullPath = null;
              }
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.fullPath = null;
          });
      } catch (error) {
        this.loading = false;
        this.fullPath = null;
        if (error.response && error.response.status === 401) {
        } else {
          // this.$emit("initSuccessIconImage", {
          //   id: this.propId,
          //   val: "/img/icons/no-image.png",
          // });
        }
      }
    },
    async getIconPreview() {
      try {
        let response = await fileFunc.downloadPreview(
          this.propId,
          this.$store.getters.getAccessToken
        );
        if (response.status === 200) {
          let contentType =
            response.headers && response.headers["content-type"]
              ? response.headers["content-type"]
              : "image/jpg";
          let blob = new Blob([response.data], {
            type: contentType,
          });
          let arrSplit = contentType.split("/");
          if (blob.size != 0) {
            if (!window.navigator.msSaveOrOpenBlob) {
              // BLOB NAVIGATOR
              this.iconSrc = window.URL.createObjectURL(blob);
            } else {
              // BLOB FOR EXPLORER 11
              this.iconSrc = window.navigator.msSaveOrOpenBlob(
                blob,
                this.propId + "." + arrSplit[arrSplit.length - 1]
                  ? arrSplit[arrSplit.length - 1]
                  : "jpg"
              );
            }
            this.$emit("initSuccessIconImage", {
              id: this.propId,
              val: this.iconSrc,
            });
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
        } else {
          this.iconSrc = "/img/icons/no-image.png";
          this.$emit("initSuccessIconImage", {
            id: this.propId,
            val: this.iconSrc,
          });
        }
      }
    },
    showPreviewImage() {
      this.$emit("showPreviewImage");
    },
  },
};
</script>
<style lang="scss" scoped>
.preview-icon {
  text-align: center;
  width: 68px;
  height: 68px;
  background-color: #eceff1;
  img {
    object-fit: cover;
    padding-top: 2px;
    width: 64px;
    height: 64px;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
