<template>
  <Dialog
    :header="propTitle"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <div class="p-grid m-0">
      <p v-if="propOrder.description">{{ propOrder.description }}</p>
      <div class="p-field p-col-12 form-row m-0">
        <label class="p-col-fixed w-100"
          >{{ $t("label_reason") }}&nbsp;
          <span class="font-weight-bold required">*</span></label
        >
        <Textarea
          v-model="content"
          :autoResize="true"
          class="w-100"
          rows="5"
          maxLength="255"
        />
      </div>
    </div>
    <template #footer>
      <Button
        :disabled="propButton && propButton.disabled"
        :label="$t('button_text_close')"
        :icon="'fas fa-times'"
        :class="'p-button-danger mr-1'"
        @click="onClose"
      />
      <Button
        :disabled="propButton && propButton.disabled"
        :label="$t('button_text_cancel_order')"
        :icon="
          propButton && propButton.processing
            ? 'fas fa-spinner fa-spin'
            : 'fas fa-check'
        "
        :class="'p-button-success mr-0'"
        @click="onUpdateStatus"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import Textarea from "@/components/commons/textarea/Textarea";
export default {
  components: {
    Dialog,
    Button,
    Dropdown,
    Textarea,
  },
  props: {
    propOrder: {
      type: Object,
      default: () => {
        return {};
      },
    },
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "480px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propButton: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      content: null,
      show: false,
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = this.propShow;
      },
      immediate: true,
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    styleLabel() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:135px";
    },
    styleLabelCheckbox() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:128px";
    },
    styleControl() {
      if (this.documentWidth < 992) {
        return "width:100%";
      } else return "width:calc(100% - 135px)";
    },
  },
  methods: {
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    onUpdateStatus() {
      if (!this.content || this.content.trim() === "") {
        this.$emit("message", [this.$t("label_reason_is_required")]);
        return;
      }
      this.$emit("onCancelOrder", this.content);
    },
  },
};
</script>
<style lang="scss" scoped>
p {
  margin-bottom: 0.5rem;
}
</style>
