<template>
  <div class="feasibility">
    <div class="left">
      <h1 class="tasking-name mt-0">
        {{
          propProductTypeSelected &&
          Object.keys(propProductTypeSelected).length > 0
            ? propProductTypeSelected.technicalProduct.sensor.label +
              " " +
              propProductTypeSelected.technicalProduct.target.label
            : ""
        }}
      </h1>
      <div
        :style="{
          height: propContentHeight - 96 + 'px',
          overflowY: 'auto',
          padding: '3px',
        }"
      >
        <div class="form-group mb-2 d-flex gap-4 justify-center">
          <div class="field-radiobutton">
            <RadioButton
              id="standard"
              name="feasibility"
              value="standard"
              v-model="propFeasibility.type"
            />
            <label for="custom" class="m-0 ml-2">Standard</label>
          </div>
          <div class="field-radiobutton">
            <RadioButton
              id="custom"
              name="feasibility"
              value="custom"
              v-model="propFeasibility.type"
            />
            <label for="custom" class="m-0 ml-2">Custom</label>
          </div>
        </div>
        <p class="m-0">
          <label class="control-field grid grid-cols-1 cursor-pointer">
            <select
              v-model="propFeasibility.sensor"
              disabled
              class="form-control new-style order-1 w-100"
            >
              <option
                v-for="s in propSensors.filter((x) =>
                  this.propFeasibility.sensor
                    ? x.id === this.propFeasibility.sensor
                    : '1==1'
                )"
                :key="s.id"
                :value="s.id"
              >
                {{ s.label }}
              </option>
            </select>
            <span>Sensor</span>
          </label>
        </p>
        <fieldset class="grid grid-cols-2 gap-4 border-0 mb-2">
          <p class="m-0">
            <label class="control-field grid grid-cols-1 cursor-pointer">
              <date-picker
                class="control-date order-1 w-100"
                format="DD/MM/YYYY"
                :disabled-date="notBeforeToday"
                v-model="propFeasibility.startDate"
                value-type="timestamp"
                :lang="lang"
                :clearable="false"
                input-class=" form-control new-style"
              ></date-picker>
              <span>Start Date(UTC)<span class="required">&nbsp;*</span></span>
            </label>
          </p>
          <p class="m-0">
            <label class="control-field grid grid-cols-1 cursor-pointer">
              <date-picker
                class="control-date order-1 w-100"
                format="DD/MM/YYYY"
                :disabled-date="notBeforeStartDate"
                v-model="propFeasibility.endDate"
                value-type="timestamp"
                :lang="lang"
                :clearable="false"
                input-class=" form-control new-style"
              ></date-picker>
              <span>End date(UTC)<span class="required">&nbsp;*</span></span>
            </label>
          </p>
        </fieldset>
        <p class="m-0" v-if="propFeasibility.type === 'standard'">
          <label class="control-field grid grid-cols-1 cursor-pointer">
            <select
              v-model="propFeasibility.maxIncidenceAngle"
              class="form-control new-style order-1 w-100"
              :class="
                propFeasibility.maxIncidenceAngle > 20 ? 'border-warning' : ''
              "
            >
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
            <span
              :class="
                propFeasibility.maxIncidenceAngle > 20 ? 'text-warning' : ''
              "
              >Max incidence angle<span class="required">&nbsp;*</span></span
            >
            <span
              class="span-warning text-warning order-1"
              v-if="propFeasibility.maxIncidenceAngle > 20"
              >Be aware that geometric quality can be deteriorated if you
              associate an ortho processing level with an incidence angle >
              20.0</span
            >
          </label>
        </p>
        <p class="m-0" v-if="propFeasibility.type === 'custom'">
          <label class="control-field grid grid-cols-1 cursor-pointer">
            <input
              type="number"
              step="1"
              min="5"
              max="50"
              class="form-control new-style order-1"
              :class="
                propFeasibility.customMaxIncidenceAngle > 20
                  ? messageMaxCustomIncidenceAngle
                    ? 'border-danger'
                    : 'border-warning'
                  : messageMaxCustomIncidenceAngle
                  ? 'border-danger'
                  : ''
              "
              v-model="propFeasibility.customMaxIncidenceAngle"
            />
            <span
              :class="
                propFeasibility.customMaxIncidenceAngle > 20
                  ? messageMaxCustomIncidenceAngle
                    ? 'text-danger'
                    : 'text-warning'
                  : messageMaxCustomIncidenceAngle
                  ? 'text-danger'
                  : ''
              "
              >Max incidence angle<span class="required">&nbsp;*</span></span
            >
            <span class="order-1">
              <span
                class="span-danger text-danger"
                v-if="messageMaxCustomIncidenceAngle"
                >{{ messageMaxCustomIncidenceAngle }}.</span
              >&nbsp;
              <span
                class="span-warning text-warning"
                v-if="propFeasibility.customMaxIncidenceAngle > 20"
                >Be aware that geometric quality can be deteriorated if you
                associate an ortho processing level with an incidence angle >
                20.0</span
              ></span
            >
          </label>
        </p>

        <p class="m-0" v-if="propFeasibility.type === 'standard'">
          <label class="control-field grid grid-cols-1 cursor-pointer">
            <input
              type="text"
              class="form-control order-1"
              disabled
              :value="propFeasibility.maxCloudCover"
            />
            <span>Max cloud cover<span class="required">&nbsp;*</span></span>
          </label>
        </p>
        <fieldset
          class="fields-group grid-cols-2 gap-4 border-0 mb-2"
          v-if="propFeasibility.type === 'custom'"
        >
          <p class="m-0">
            <label class="control-field grid grid-cols-1 cursor-pointer">
              <select
                v-model="propFeasibility.customMaxCloudCover"
                class="form-control order-1 w-100"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
              </select>
              <span>Max cloud cover<span class="required">&nbsp;*</span></span>
            </label>
          </p>
          <p class="m-0">
            <label class="control-field grid grid-cols-1 cursor-pointer">
              <input
                type="number"
                step="1"
                :min="propFeasibility.customMaxCloudCover"
                max="100"
                class="form-control order-1"
                :class="
                  messageCustomNotificationThreshold ? 'border-danger' : ''
                "
                v-model="propFeasibility.customNotificationThreshold"
              />
              <span
                :class="messageCustomNotificationThreshold ? 'text-danger' : ''"
                >Acquisition notification threshold<span class="required"
                  >&nbsp;*</span
                ></span
              >
              <span
                class="span-danger text-danger order-1"
                v-if="messageCustomNotificationThreshold"
                >{{ messageCustomNotificationThreshold }}</span
              >
            </label>
          </p>
        </fieldset>
        <fieldset
          class="fields-group grid-cols-2 gap-4 border-0 mb-2"
          v-if="
            propFeasibility.type === 'custom' &&
            (propFeasibility.customAcquisitionMode === 'STEREO' ||
              propFeasibility.customAcquisitionMode === 'TRISTEREO')
          "
        >
          <p class="m-0">
            <label class="control-field grid grid-cols-1 cursor-pointer">
              <select
                v-model="propFeasibility.customAcquisitionMode"
                class="form-control order-1 w-100"
              >
                <option value="MONO">MONO</option>
                <option value="STEREO">STEREO</option>
                <option value="TRISTEREO">TRISTEREO</option>
              </select>
              <span>Acquisition Mode<span class="required">&nbsp;*</span></span>
            </label>
          </p>
          <p class="m-0">
            <label class="control-field grid grid-cols-1 cursor-pointer">
              <input
                type="number"
                step="0.01"
                min="0.2"
                max="0.8"
                class="form-control order-1"
                :class="messageCustomMinBH ? 'border-danger' : ''"
                v-model="propFeasibility.customMinBH"
              />
              <span :class="messageCustomMinBH ? 'text-danger' : ''"
                >Min B/H<span class="required">&nbsp;*</span></span
              >
              <span
                class="span-danger text-danger order-1"
                v-if="messageCustomMinBH"
                >{{ messageCustomMinBH }}</span
              >
            </label>
          </p>
          <p class="m-0">
            <label class="control-field grid grid-cols-1 cursor-pointer">
              <input
                type="number"
                step="0.01"
                min="0.2"
                max="0.8"
                class="form-control order-1"
                :class="messageCustomMaxBH ? 'border-danger' : ''"
                v-model="propFeasibility.customMaxBH"
              />
              <span :class="messageCustomMaxBH ? 'text-danger' : ''"
                >Max B/H<span class="required">&nbsp;*</span></span
              >
              <span
                class="span-danger text-danger order-1"
                v-if="messageCustomMaxBH"
                >{{ messageCustomMaxBH }}</span
              >
            </label>
          </p>
        </fieldset>
        <p
          class="m-0"
          v-if="
            propFeasibility.type === 'custom' &&
            propFeasibility.customAcquisitionMode === 'MONO'
          "
        >
          <label class="control-field grid grid-cols-1 cursor-pointer">
            <select
              class="form-control order-1 w-100"
              v-model="propFeasibility.customAcquisitionMode"
            >
              <option value="MONO">MONO</option>
              <option value="STEREO">STEREO</option>
              <option value="TRISTEREO">TRISTEREO</option>
            </select>
            <span>Acquisition Mode<span class="required">&nbsp;*</span></span>
          </label>
        </p>
        <p class="m-0 mb-2">
          <label class="control-field grid grid-cols-1 cursor-pointer">
            <Textarea
              class="form-control new-style order-1"
              rows="3"
              maxlength="255"
              autofocus
              :autoResize="true"
              v-model="propFeasibility.comments"
            />
            <span>Comments</span>
          </label>
        </p>
      </div>
    </div>
    <div class="right">
      <FeasibilityMap
        :prop-content-height="propContentHeight"
        @initMapSuccess="initMapSuccess"
      />
    </div>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import FeasibilityMap from "./feasibility/Map.vue";
import Textarea from "@/components/commons/textarea/Textarea";
import RadioButton from "@/components/commons/radiobutton/RadioButton";
import { Style, Fill, Stroke } from "ol/style.js";
import WKT from "ol/format/WKT";
import { getCenter } from "ol/extent";
export default {
  components: { DatePicker, FeasibilityMap, Textarea, RadioButton },
  props: {
    propContentHeight: {
      type: Number,
    },
    propFeature: {
      type: Object,
      default: () => {},
    },
    propProductTypeSelected: {
      type: Object,
      default: () => {},
    },
    propSensors: {
      type: Array,
      default: () => [],
    },
    propFeasibility: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      map: Object,
      layer: Object,
      styles: {
        footprint: new Style({
          fill: new Fill({
            color: "rgba(255,191,35,0.3)",
          }),
          stroke: new Stroke({
            color: "#FFBF23",
            width: 1.25,
          }),
        }),
        footprintHover: new Style({
          fill: new Fill({
            color: "transparent",
          }),
          stroke: new Stroke({
            color: "rgba(0,158,247,1)",
            width: 1.25,
          }),
        }),
        image: new Style({
          fill: new Fill({
            color: "rgba(0,0,0,0)",
          }),
          stroke: new Stroke({
            color: "rgba(0,0,0,0.8)",
            width: 1.25,
          }),
        }),
      },
    };
  },
  mounted() {
    if (
      !this.propFeasibility.sensor &&
      this.propProductTypeSelected &&
      Object.keys(this.propProductTypeSelected).length
    ) {
      this.propFeasibility.sensor = this.propProductTypeSelected
        .technicalProduct
        ? this.propProductTypeSelected.technicalProduct.sensor.id
        : null;
    }
  },
  watch: {
    "propFeasibility.type"(val) {
      if (val === "standard")
        this.propFeasibility.customAcquisitionMode = "MONO";
      if (
        val != "custom" &&
        !this.propFeasibility.customAcquisitionMode === "MONO"
      )
        this.propFeasibility.customAcquisitionMode = "MONO";
    },
  },
  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    notBeforeStartDate(date) {
      if (this.propFeasibility.startDate)
        return date < new Date(this.propFeasibility.startDate);
      else return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    initMapSuccess(data) {
      this.map = data.map;
      this.layer = data.layer;
      if (this.propFeature && Object.keys(this.propFeature).length) {
        this.layer.getSource().addFeature(this.propFeature);

        var ext = this.propFeature.getGeometry().getExtent();
        this.map.getView().fit(ext, {
          size: this.map.getSize(),
        });
        var center = getCenter(ext);
        this.map.getView().animate({
          center: [center[0], center[1]],
          duration: 100,
          zoom:
            this.map.getView().getZoom() < 2
              ? 2
              : this.map.getView().getZoom() - 0.5,
        });
      }
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    messageMaxCustomIncidenceAngle() {
      if (
        this.propFeasibility.customMaxIncidenceAngle === "" ||
        this.propFeasibility.customMaxIncidenceAngle === null ||
        this.propFeasibility.customMaxIncidenceAngle === undefined
      ) {
        return "The field is required";
      } else {
        if (
          this.propFeasibility.customMaxIncidenceAngle < 5 ||
          this.propFeasibility.customMaxIncidenceAngle > 50
        ) {
          return "The value must be from 5 to 50";
        }
      }
      return null;
    },
    messageCustomNotificationThreshold() {
      if (
        this.propFeasibility.customMaxCloudCover &&
        this.propFeasibility.customNotificationThreshold
      ) {
        if (
          this.propFeasibility.customNotificationThreshold <
          this.propFeasibility.customMaxCloudCover
        ) {
          return "The acquisition notification threshold field must be equal or superior to the max cloud cover";
        } else if (this.propFeasibility.customNotificationThreshold > 100)
          return "The value is above the maximum allowed value.";
      } else if (
        this.propFeasibility.customNotificationThreshold === null ||
        this.propFeasibility.customNotificationThreshold === undefined ||
        this.propFeasibility.customNotificationThreshold === ""
      )
        return "The acquisition notification threshold field is required";
      else return null;
    },
    messageCustomMinBH() {
      if (
        this.propFeasibility.customMinBH === null ||
        this.propFeasibility.customMinBH === undefined ||
        !this.propFeasibility.customMinBH
      )
        return "The min B/H field is required";
      else {
        if (
          this.propFeasibility.customMinBH < 0.2 ||
          this.propFeasibility.customMinBH > 0.8
        )
          return "The value must be from 0.2 to 0.8";
        else return null;
      }
    },
    messageCustomMaxBH() {
      if (
        this.propFeasibility.customMaxBH === null ||
        this.propFeasibility.customMaxBH === undefined ||
        !this.propFeasibility.customMaxBH
      )
        return "The max B/H field is required";
      else {
        if (
          this.propFeasibility.customMaxBH < 0.2 ||
          this.propFeasibility.customMaxBH > 0.8
        )
          return "The value must be from 0.2 to 0.8";
        else return null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.feasibility {
  padding: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: [first] 30rem [line2] auto [end];
  .left {
    h1 {
      &.tasking-name {
        &::after {
          content: "";
          display: block;
          width: 100%;
          border-bottom: 1px dashed #ccc;
          margin-top: 0.5rem;
        }
      }
    }
    .control-field {
      display: grid;
      grid-auto-columns: repeat(1, 1fr);
      cursor: pointer;

      .label-date {
        order: none;
        margin: 0;
      }
    }
    fieldset {
      border: none;
      padding: 0;
      &.fields-group {
        padding: 1rem;
        background-color: rgba($color: #eef3f7, $alpha: 1);
      }
    }
    .justify-center {
      justify-content: center;
    }
  }
  textarea.form-control {
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.4);
    }
  }
  select.new-style {
    background: #eef3f7 !important;
    border-radius: 4px;
    border-color: #eef3f7 !important;
  }

  .grid {
    display: grid;
  }
  .grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .gap-4 {
    gap: 1rem;
  }
  .order-1 {
    order: 1;
  }
  .order-0 {
    order: 0;
  }
  .span-warning {
    font-size: 10px;
  }
  .span-danger {
    font-size: 10px;
  }
}
</style>
<style lang="scss">
.feasibility {
  input {
    &.border-warning {
      border-color: #ffc107 !important;
    }
    &.border-danger {
      border-color: #dc3545 !important;
    }
  }
  select {
    &.new-style {
      &.border-warning {
        border-color: #ffc107 !important;
      }
      &.border-danger {
        border-color: #dc3545 !important;
      }
    }
  }
}
</style>
