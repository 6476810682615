import { render, staticRenderFns } from "./ItemMetadata.vue?vue&type=template&id=7e61d958&scoped=true&"
import script from "./ItemMetadata.vue?vue&type=script&lang=js&"
export * from "./ItemMetadata.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e61d958",
  null
  
)

export default component.exports