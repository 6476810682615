<template>
  <layout-default>
    <div class="container-fluid">
      <!-- <div class="background"> -->
      <SingupPage />
      <!-- </div> -->
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/components/pages/layouts/Default";
import SingupPage from "@/components/pages/signup/Signup";
export default {
  components: {
    LayoutDefault,
    SingupPage,
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
