
<template>
  <div class="huGHgeKd82d">
    <CustomChart
      :chartdata="data"
      :options="options"
      :type="'bar'"
      :ref="'customchart' + id"
    />
  </div>
</template>
<script>
import CustomChart from "@/components/commons/chart/CustomChart";
import PieChart from "@/components/commons/chart/Pie2";
export default {
  components: {
    CustomChart,
    PieChart,
  },
  props: {
    propData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id: new Date().getTime(),
      loading: true,
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              display: true,
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        showLines: true,
        plugins: {
          labels: [
            {
              render: function (args) {
                return args.value;
              },
            },
          ],
        },
      },
    };
  },
  created() {
    this.id =
      this.id -
      Math.floor(Math.random() * 5000) +
      Math.floor(Math.random() * 9999);
  },
  watch: {
    propData: {
      handler(item) {
        this.data.labels = [];
        this.data.datasets = [];
        let array = Object.assign(
          [],
          JSON.parse(JSON.stringify(item.children))
        );
        if (array && array.length > 0) {
          let color = this.getRandomColor();
          this.data.datasets.push({
            label: "Download",
            data: [],
            backgroundColor: color,
            borderColor: color,
          });
          for (let i = 0; i < array.length; i++) {
            this.data.labels.push(array[i].satellite);
            this.data.datasets[0].data.push(array[i].total);
          }
        }
        setTimeout(() => {
          if (this.$refs["customchart" + this.id]) {
            this.$refs["customchart" + this.id].updateChart();
          }
        }, 500);
      },
      immediate: true,
    },
  },
  methods: {
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    hexToRgbA(hex, opacity) {
      if (!opacity) opacity = "0.4";
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          "," +
          opacity +
          ")"
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.huGHgeKd82d {
  position: relative;
  height: 200px;
  width: 100%;
}
</style>
