<template>
  <div class="page-wrapper theme toggled">
    <a id="show-sidebar" class="btn btn-sm btn-toggled">
      <i class="fas fa-bars"></i>
    </a>
    <sidebar-layout></sidebar-layout>
    <div class="container-fluid">
      <main class="page-content">
        <div class="page-content__header-toolbar">
          <div class="breadcrumb" id="breadcrumb">
            <Breadcrumb :home="home" :model="breadcrumbitems" />
          </div>
        </div>
        <slot name="default"></slot>
      </main>

      <WidgetDownloadProgressBar
        v-if="widget.download.show"
        :prop-items="itemsDownload"
        :prop-show="widget.download.show2"
        :isLayoutDefault="false"
        @close="closeWidgetDownload"
      />
    </div>
  </div>
</template>
<script>
import SidebarLayout from "./admin/Sidebar";
import MainLayout from "./admin/Main";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import cryptoFunc from "@/utils/functions/crypto";
import Breadcrumb from "@/components/commons/breadcrumb/Breadcrumb";
import WidgetDownloadProgressBar from "@/components/commons/widget/DownloadProgressBar";
export default {
  components: {
    SidebarLayout,
    MainLayout,
    Dropdown,
    Breadcrumb,
    WidgetDownloadProgressBar,
  },
  props: {
    breadcrumbitems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      title: null,
      home: { icon: "fas fa-home", to: "/" },
      widget: {
        download: {
          show: false,
          show2: false,
        },
      },
      language: null,
      arrayLanguage: [
        {
          code: "en",
          label: "English",
          icon: "",
          id: 1,
        },
        {
          code: "vi",
          label: "Vietnamese",
          icon: "",
          id: 2,
        },
      ],
    };
  },
  created() {
    if (!this.lang) {
      if (this.arrayLanguage.findIndex((x) => x.code === this.lang) >= 0)
        this.language = this.arrayLanguage.find((x) => x.code === this.lang);
      else {
        this.language = this.arrayLanguage.find((x) => x.code === "vi");
      }
    } else {
      this.language = this.arrayLanguage.find((x) => x.code === this.lang);
    }
  },
  watch: {
    itemsDownload(val) {
      if (val && val.length > 0) {
        this.widget.download.show2 = true;
        this.widget.download.show = true;
      } else {
        this.closeWidgetDownload();
      }
    },
    language(val) {
      localStorage.setItem("language", val.code);
      this.$store.dispatch("setLang", val.code);
    },
  },
  mounted() {
    if (this.documentWidth < 768 && this.documentWidth > 0)
      $(".page-wrapper").removeClass("toggled");
  },
  methods: {
    closeWidgetDownload() {
      this.widget.download.show2 = false;
      setTimeout(() => {
        this.widget.download.show = false;
      }, 200);
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    itemsDownload() {
      return this.$store.getters.getImagesDownload;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.page-wrapper {
  height: 100vh;
}
.page-wrapper.toggled #show-sidebar {
  left: -40px;
}
/*----------------show sidebar button----------------*/
#show-sidebar {
  border-radius: 0 4px 4px 0px;
  cursor: pointer;
  color: #fff;
  position: fixed;
  left: 0;
  top: 3rem;
  width: 35px;
  transition-delay: 0.3s;
  z-index: 999;
}
.btn-toggled {
  background: $background-color;
  color: white;
}
.page-content {
  .breadcrumb {
    margin: 0;
    border-radius: 0;
    background: white;
    padding-left: 0;
    .p-component {
      border: 0;
      background: transparent;
      padding: 0.325rem 1rem;
    }
  }
}
/*--------------------------page-content-----------------------------*/
@media screen and (min-width: 768px) {
  .page-wrapper.toggled .page-content {
    padding-left: 280px;
  }
}
.page-wrapper .page-content {
  display: inline-block;
  width: 100%;
}
#show-sidebar {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
</style>
<style lang="scss">
.page-content__header-toolbar {
  background: white;
  border-bottom: 1px solid white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .dropdown-language {
    width: 7.5rem;
    background-color: #eef3f7;
    padding: 0.075rem 0.375rem;
    border-radius: 20px;
  }
  .dropdown-language {
    .p-dropdown {
      width: 7rem;
      border: transparent;
      background: transparent;
      &:active {
        outline: 0;
        border: 0;
        box-shadow: none;
      }
      &:hover {
        outline: 0;
        border: 0;
        box-shadow: none;
      }
      .p-dropdown-items-wrapper {
        width: 9rem;
        li {
          padding: 0.375rem 1rem;
        }
      }
      .p-dropdown-label {
        padding-right: 0.25rem;
      }
    }
    .p-dropdown-trigger {
      display: none;
    }
  }
}
</style>
