import CryptoJS from "crypto-js";
// String plaintext
const encrypt = function (plaintext) {
  try {
    return CryptoJS.AES.encrypt(
      plaintext,
      process.env.VUE_APP_SECRET_KEY + "abcd@321!09876543112"
    ).toString();
  } catch (error) {
    return "";
  }
};
// String ciphertext
const decrypt = async function (ciphertext) {
  try {
    let bytes = CryptoJS.AES.decrypt(
      ciphertext,
      process.env.VUE_APP_SECRET_KEY + "abcd@321!09876543112"
    );
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    return "";
  }
};
export default { encrypt, decrypt };
