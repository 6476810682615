<template>
    <div class="models">
        <div class="models__header">
            <h5 class="models__header-title">
                Vệ tinh
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </h5>
        </div>
        <div class="models__body">
            <v-jstree :data="propData.items" :collapse="true" show-checkbox multiple allow-batch whole-row @item-click="itemClick"></v-jstree>  
        </div>
        <div class="models__footer">
          <button class="btn btn-primary" @click="onSearch"><i class="fas fa-search"></i>&nbsp;Tìm kiếm</button>
        </div>
    </div>
</template>
<script>
import VJstree from 'vue-jstree'
export default {
    props:{
        propData:{
            type:Object,
            default:()=>{
                return{
                    items:[]
                }
            }
        },
        propCriteria:{
            type:Object,
            default:()=>{}
        }
    },
    components:{
        VJstree
    },
    data() {
        return {}
    },
    computed:{
        accessToken(){
            return this.$store.getters.getAccessToken
        }
    },
    created(){},
    methods: {
        itemClick (node) {
            let code = node.model.code ? node.model.code :'';
            if(["SATELLITE","ORTHO_IMAGE","THEMATIC_MAP","CONTROL_POINT"].includes(code)){
                if(node.model.selected){
                    this.$store.dispatch("setObjectImage",[code])
                }else
                    this.$store.dispatch("removeObjectImage",[code])

            }else{
                if(node.model.children.length){
                    for (let i = 0; i < node.model.children.length; i++) {
                        let index = this.propCriteria.model.findIndex(x=>x === node.model.children[i].code);
                        if(index >=0){
                            if(!node.model.selected){
                                this.propCriteria.model.splice(index,1);
                            }
                        }else {
                            if(node.model.selected){
                                this.propCriteria.model.push(node.model.children[i].code);
                            }
                        }
                    }
                }else{
                    let index = this.propCriteria.model.findIndex(x=>x === node.model.code);
                    if(index >=0){
                        if(!node.model.selected){
                            this.propCriteria.model.splice(index,1);
                        }
                    }else {
                        if(node.model.selected){
                            this.propCriteria.model.push(node.model.code);
                        }
                    }
                }
            }
        },
        close(){
            this.$emit("close",{
                component:"MODELS"
            })
        },
        onSearch(){
            this.$store.dispatch("setSearchDto",{
                model:this.propCriteria.model
            })
            setTimeout(() => {
                this.$emit("onSearch", { model : this.propCriteria.model });
                this.close();    
            }, 100);
        }
    }
}
</script>
<style lang="scss" scoped>
.models{
    height: 592px;
    width: 100%;
    overflow-y: auto;
    .models__header{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 1rem;
      border-bottom: 1px solid #e9ecef;
      border-top-left-radius: .3rem;
      border-top-right-radius: .3rem;
      .models__header-title{
        width: 100%;
        margin-bottom: 0;
      }
    }
    .models__body{
      position: relative;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      padding: 1rem;
      max-height: 466px;
      overflow-y: auto;
      tr{
        &:hover{
            cursor: pointer;
        }
      }
      .tree-default .tree-wholerow-clicked{
          background: transparent!important;
      }
    }
    .models__footer{
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 1rem;
        border-top: 1px solid #e9ecef;
    }
}
</style>
<style>
    .models__body .tree-default .tree-wholerow-clicked{
        background: transparent!important;
    }
</style>