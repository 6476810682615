<template>
  <div class="datatable">
    <ContextMenu
      :model="menuModel"
      ref="cmfilesDatatable"
      :appendTo="'kUbw7Hg2Jg'"
    />
    <DataTable
      :value="items.filter((x) => !x.is_delete)"
      ref="dt"
      :resizableColumns="false"
      columnResizeMode="expand"
      selectionMode="single"
      dataKey="id"
      contextMenu
      :selection.sync="selected"
      @row-contextmenu="onRowContextMenu"
    >
      <template
        #empty
        v-if="items && items.filter((x) => !x.is_delete).length === 0"
      >
        <span class="text-center">{{ $t("label_no_files") }}</span>
      </template>
      <Column
        header="No"
        headerStyle="width: 3rem; text-align: center!important"
        bodyStyle="text-align: center;"
      >
        <template #body="slotProps">
          {{ slotProps.index + 1 }}
        </template></Column
      >
      <Column :header="$t('label_file_name')">
        <template #body="slotProps">
          {{ slotProps.data ? slotProps.data.name : "" }}
        </template>
      </Column>
      <Column
        :header="$t('label_file_size')"
        headerStyle="width: 6.5rem;"
        bodyStyle="width: 6.5 rem;"
      >
        <template #body="slotProps">
          {{ slotProps.data.size | formatSizeFile }}
        </template>
      </Column>
      <Column headerStyle="width: 4rem;" bodyStyle="width: 4rem;">
        <template #body="slotProps">
          <button
            icon="fas fa-download"
            type="button"
            class="btn btn-primary btn-sm mr-1"
            :title="$t('button_text_download')"
            @click="downloadFile(slotProps.data)"
          >
            <i class="fas fa-download"></i>
          </button></template
      ></Column>
    </DataTable>
  </div>
</template>
<script>
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import Button from "@/components/commons/button/Button";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
export default {
  components: {
    DataTable,
    Column,
    ContextMenu,
    Button,
  },

  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propStatus: {
      type: Object,
      default: () => {
        return {
          isAdd: false,
          isEdit: false,
        };
      },
    },
  },
  data() {
    return {
      items: [],
      selected: null,
      menuModel: [
        {
          label: this.$t("button_text_download"),
          icon: "fas fa-download",
          command: () => this.downloadFile(this.selected),
          visible: true,
        },
        {
          label: this.$t("button_text_remove"),
          icon: "fas fa-trash",
          command: () => this.removeFile(this.selected),
          visible: true,
        },
      ],
    };
  },
  watch: {
    propItems: {
      handler(val) {
        this.initData();
        this.selected = null;
      },
      immediate: true,
    },
  },
  methods: {
    initData() {
      if (this.propItems && this.propItems.length > 0)
        this.items = Object.assign(
          [],
          this.propItems.filter((x) => x && !x.is_delete)
        );
      else this.items = [];
    },
    onRowContextMenu(event) {
      if (this.propStatus.isAdd || this.propStatus.isEdit) {
        if (this.selected && Object.keys(this.selected).length > 0) {
          this.$refs.cmMembersDatatable.show(event.originalEvent);
        }
      }
    },
    downloadFile(data) {
      this.$emit("downloadFile", data);
    },
    removeFile(data) {
      let index = this.propItems.findIndex((x) => x.id === data.id);
      if (index >= 0) {
        this.propItems[index].is_delete = true;
        if (this.propItems[index].is_add) this.propItems[index].is_add = false;
        this.$emit("removeFile", this.propItems[index]);
      }
    },
  },
};
</script>
<style lang="scss">
.my-project__files {
  .datatable {
    @import "@/assets/scss/_datatable.scss";
  }
}
</style>