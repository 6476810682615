<template>
  <div class="my-order-content">
    <div class="my-order__dialog">
      <DialogOrderDetailEgeo
        v-if="dlgOrderDetailEgeo.show"
        :prop-style="{ width: dlgOrderDetailEgeo.width }"
        :prop-show="dlgOrderDetailEgeo.show"
        :prop-title="dlgOrderDetailEgeo.title"
        :prop-item="dlgOrderDetailEgeo.value"
        :propGranularities="granularity.items"
        :propLookDirection="lookDirection.items"
        :propOrbitDirection="orbitDirection.items"
        :propProgramingServices="programingService.items"
        :propSatelliteType="satelliteType.items"
        @close="
          dlgOrderDetailEgeo.show = false;
          dlgOrderDetailEgeo.value = null;
        "
        @getAllDirection="getAllDirection"
        @getAllTaskingProgrammingService="getAllTaskingProgrammingService"
        @getAllTaskingGranularity="getAllTaskingGranularity"
        @getAllSatelliteType="getAllSatelliteType"
      />
      <DialogRemark
        v-if="dlgDialogRemark.show"
        :prop-style="{
          width: dlgDialogRemark.width,
        }"
        :prop-show="dlgDialogRemark.show"
        :prop-title="dlgDialogRemark.title"
        :prop-value="dlgDialogRemark.value"
        @close="
          dlgDialogRemark.show = false;
          dlgDialogRemark.value = null;
          dlgDialogRemark.title = null;
        "
      />
      <DialogCancelOrder
        v-if="dlgCancelOrder.show"
        :prop-style="{ width: dlgCancelOrder.width }"
        :prop-show="dlgCancelOrder.show"
        :prop-title="dlgCancelOrder.title"
        :prop-items="dlgCancelOrder.value"
        :propButton="buttons.cancelOrder"
        @close="
          dlgCancelOrder.show = false;
          dlgCancelOrder.value = null;
        "
        @message="message = $event"
        @onCancelOrder="onCancelOrder"
      />
      <DialogImageList
        v-if="dlgImageList.show"
        :prop-style="{ width: dlgImageList.width }"
        :prop-show="dlgImageList.show"
        :prop-title="dlgImageList.title"
        :prop-items="dlgImageList.value.images"
        :prop-buttons="buttons"
        :propImageTypes="satelliteType.items"
        @close="
          dlgImageList.show = false;
          dlgImageList.value = null;
        "
        @getAllSatelliteType="getAllSatelliteType"
        @showDialogMetadata="showDialogMetadata"
        @downloadImages="onDownloadImages"
      />

      <DialogMetadata
        v-if="dialogMetadata.show"
        :prop-style="{ width: dialogMetadata.width }"
        :prop-show="dialogMetadata.show"
        :prop-title="dialogMetadata.title"
        :prop-item="dialogMetadata.value"
        @close="
          dialogMetadata.show = false;
          dialogMetadata.value = null;
        "
      />

      <DialogMessage
        v-if="message.length > 0"
        :propTitle="$t('title_message_info')"
        :prop-show="message.length > 0"
        :prop-message="message"
        @close="message = []"
      />
    </div>
    <div class="my-order__search" id="elementSearch">
      <OrderSearch
        :propSearch="myOrder.search"
        :prop-buttons="buttons"
        :propOrderStatus="orderStatus.items"
        @onSearch="onSearch"
        @message="message = $event"
      />
    </div>
    <div class="my-order__datatable">
      <OrderDataTable
        :propItems="myOrder.items"
        :prop-table="myOrder.table"
        :prop-button-detail="buttons.detail"
        :prop-loading="myOrder.loading"
        :propTotal="myOrder.total_records"
        @nextPage="nextPage"
        @showOrderDetail="showOrderDetail"
        @showDialogCancel="showDialogCancel"
        @shoDialogRemark="shoDialogRemark"
        @showImageList="showImageList"
      />
    </div>
  </div>
</template>
<script>
import OrderSearch from "./myorderedtasking/Search.vue";
import OrderDataTable from "./myorderedtasking/DataTable";
import DialogConfirmation from "@/components/commons/dialog/Confirmation";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import DialogOrderDetailEgeo from "./myorderedtasking/detail/DialogOrderDetailEgeo.vue";
import DialogCancelOrder from "./myorderedtasking/detail/DialogCancelOrder.vue";
import DialogRemark from "./myorderedtasking/detail/DialogRemark.vue";
import DialogImageList from "./myorderedtasking/detail/DialogImageList.vue";
import DialogMetadata from "@/components/commons/metadata/Metadata";
import meControllerFunc from "@/utils/functions/mecontroller";
import metaKeyFunc from "@/utils/functions/metakey";
import orderFunc from "@/utils/functions/order";
import imageSharingFunc from "@/utils/functions/imagesharing";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
export default {
  components: {
    OrderDataTable,
    OrderSearch,
    DialogConfirmation,
    DialogCancelOrder,
    DialogMessage,
    DialogOrderDetailEgeo,
    DialogRemark,
    DialogImageList,
    DialogMetadata,
  },
  data() {
    return {
      message: [],
      orderStatus: {
        items: [],
        data: [],
      },
      priorityLevel: {
        items: [],
        data: [],
      },
      myOrder: {
        loading: false,
        page_number: 0,
        page_size: 10,
        total_page: 0,
        total_records: 0,
        items: [],
        search: {
          create_from: null,
          create_to: null,
          status_id: null,
          page: 0,
          size: 10,
        },
        table: {
          selected: null,
          height: 400,
        },
        is_bottom: false,
      },
      confirm: {
        message: null,
        show: false,
        title: this.$t("title_message_confirm"),
        icon: "fas fa-question",
        callback: {
          no: {
            method: null,
            params: null,
          },
          yes: {
            method: null,
            params: null,
          },
        },
        initValue: [],
        value: false,
      },
      dlgOrderDetailEgeo: {
        show: false,
        title: this.$t("header_detail"),
        value: null,
        width: "480px",
      },
      dlgCancelOrder: {
        show: false,
        title: this.$t("title_cancel_order"),
        value: null,
        width: "480px",
      },
      dlgDialogRemark: {
        show: false,
        title: null,
        value: null,
        width: "540px",
      },
      dlgImageList: {
        show: false,
        title: this.$t("title_image_list"),
        value: null,
        width: "480px",
      },
      dialogMetadata: {
        show: false,
        title: "Metadata",
        value: null,
        width: "540px",
      },
      buttons: {
        filter: {
          disabled: false,
          processing: false,
          visible: true,
        },
        detail: {
          disabled: false,
          processing: false,
        },
        cancelOrder: {
          disabled: false,
          processing: false,
        },
        download: {
          disabled: false,
          processing: false,
        },
      },

      granularity: {
        items: [],
      },
      programingService: {
        items: [],
      },
      lookDirection: {
        items: [],
      },
      orbitDirection: {
        items: [],
      },
      satelliteType: {
        items: [],
      },
    };
  },
  async created() {
    this.myOrder.search.size = Math.ceil(this.tableHeight / 50) + 5;
    this.$emit("initBreadcrumb", [
      "menu_sidebar_orders",
      "menu_sidebar_my_ordered_tasking",
    ]);
    var vm = this;
    if (vm.documentWidth < 576) {
      this.dlgOrderDetailEgeo.width = this.documentWidth + "px";
      this.dlgDialogRemark.width = this.documentWidth + "px";
      this.dlgImageList.width = this.documentWidth + "px";
      this.dialogMetadata.width = this.documentWidth + "px";
    } else if (vm.documentWidth >= 576 && vm.documentWidth < 768) {
      this.dlgOrderDetailEgeo.width = "540px";
      this.dlgDialogRemark.width = "540px";
      this.dlgImageList.width = "540px";
      this.dialogMetadata.width = "540px";
    } else if (vm.documentWidth >= 768 && vm.documentWidth < 992) {
      this.dlgOrderDetailEgeo.width = "720px";
      this.dlgDialogRemark.width = "540px";
      this.dlgImageList.width = "720px";
      this.dialogMetadata.width = "720px";
    } else {
      this.dlgOrderDetailEgeo.width = "960px";
      this.dlgDialogRemark.width = "540px";
      this.dlgImageList.width = "960px";
      this.dialogMetadata.width = "960px";
    }
    await this.getAllOrderStatus();
    this.getMyOrder();
  },

  mounted() {
    setTimeout(() => {
      this.myOrder.table.height = this.calcTableHeight();
    }, 100);
  },
  watch: {
    lang() {
      this.getOrderStatusByLang();
    },
  },
  methods: {
    accordionHeaderClick() {
      setTimeout(() => {
        this.myOrder.table.height = this.calcTableHeight();
      }, 100);
    },
    calcTableHeight() {
      let element = document.getElementById("elementSearch");
      return (
        this.documentHeight -
        3 * 0.5 * 16 -
        56 -
        (element ? element.offsetHeight : 0) -
        24 -
        2 * 0.5 * 16
      );
    },
    /*------------- Emit ------------------*/

    showDialogMetadata(item) {
      this.dialogMetadata.value = item;
      this.dialogMetadata.show = true;
    },
    showImageList(item) {
      this.dlgImageList.value = item;
      this.dlgImageList.show = true;
    },
    shoDialogRemark(data) {
      this.dlgDialogRemark.value = data.remark;
      this.dlgDialogRemark.title = data.title;
      this.dlgDialogRemark.show = true;
    },
    showDialogCancel(item) {
      this.dlgCancelOrder.value = item;
      this.dlgCancelOrder.show = true;
    },
    async showOrderDetail(item) {
      try {
        this.buttons.detail.disable = true;
        this.buttons.detail.processing = true;
        this.buttons.detail.id = item.id;
        let response = await orderFunc.getById(
          item.id,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.dlgOrderDetailEgeo.value = response.data.data
              ? response.data.data
              : {};
            this.dlgOrderDetailEgeo.show = true;
          } else {
            this.message.push(this.$t("message_not_found_request"));
          }
        } else {
          this.message.push(this.$t("message_not_found_request"));
        }

        this.buttons.detail.disable = false;
        this.buttons.detail.processing = false;
        this.buttons.detail.id = null;
      } catch (error) {
        this.buttons.detail.disable = false;
        this.buttons.detail.processing = false;
        this.buttons.detail.id = null;
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.showOrderDetail, item);
        } else {
          this.message.push(this.$t("message_not_found_request"));
        }
      }
    },
    initSuccessPreviewImange(data) {
      let index = this.dlgOrderDetail.value.images.findIndex(
        (x) => x.id === data.id
      );
      if (index >= 0) {
        this.dlgOrderDetail.value.images[index].preview_src = data.val;
      }
    },
    nextPage() {
      if (!this.myOrder.is_bottom) {
        this.myOrder.search.page = this.myOrder.search.page + 1;
        this.getMyOrder();
      }
    },
    async downloadImage(data) {
      let index = this.dlgImageList.value.images
        ? this.dlgImageList.value.images.findIndex(
            (x) => x.image.id === data.id
          )
        : -1;

      if (index >= 0) {
        let image = this.dlgImageList.value.images[index];

        try {
          this.buttons.download.disabled = true;
          this.buttons.download.processing = true;

          if (data.is_close_dialog) {
            this.dlgImageList.show = false;
            this.dlgImageList.value = null;
            this.buttons.download.disabled = false;
            this.buttons.download.processing = false;
          }
          this.$store.dispatch("setImagesDownload", [
            {
              is_add: true,
              image: image,
            },
          ]);
          let response = await imageSharingFunc.download2(
            null,
            null,
            data.id,
            this.$store.getters.getAccessToken
          );
          if (response && response.status === 200) {
            window.open(response.data.url, "_blank");
          } else {
            this.message.push(
              this.$t("message_download_file_error", null, {
                name: image.name,
              })
            );
          }
          this.$store.dispatch("setImagesDownload", [
            {
              is_add: false,
              image: image,
            },
          ]);
        } catch (error) {
          this.$store.dispatch("setImagesDownload", [
            {
              is_add: false,
              image: image,
            },
          ]);
          if (error.response && error.response.status === 401) {
            await this.refreshToken(this.downloadImage, data);
          } else {
            let msg =
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : null;
            this.message.push(
              msg
                ? msg
                : this.$t("message_download_file_error", null, {
                    name: image.name,
                  })
            );
          }
        }
      } else {
        if (data.is_close_dialog) {
          this.dlgImageList.show = false;
          this.dlgImageList.value = null;

          this.buttons.download.disabled = false;
          this.buttons.download.processing = false;
        }
      }
    },
    async onDownloadImages(data) {
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          this.downloadImage({
            id: data[i],
            is_close_dialog: i === data.length - 1,
          });
        }
      }
    },
    onSearch(data) {
      this.myOrder.search.create_from = data.create_from
        ? data.create_from
        : null;
      this.myOrder.search.create_to = data.create_to ? data.create_to : null;
      this.myOrder.search.status_id = data.status_id ? data.status_id : null;
      this.myOrder.items = [];
      this.myOrder.page = 0;
      this.myOrder.is_bottom = false;
      this.getMyOrder();
    },
    async onCancelOrder(content) {
      try {
        this.buttons.cancelOrder.disabled = true;
        this.buttons.cancelOrder.processing = true;
        let response = await meControllerFunc.cancelOrder(
          {
            content: content,
            orderId: this.dlgCancelOrder.value.id,
            order_id: this.dlgCancelOrder.value.id,
          },
          this.$store.getters.getAccessToken
        );
        let msg = null;
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            this.$toast.success({
              title: this.$t("title_message_info"),
              message: this.$t("message_cancel_order_success"),
              position: "top right",
            });

            this.onSearch({
              create_from: this.myOrder.search.create_from,
              create_to: this.myOrder.search.create_to,
              status_id: this.myOrder.search.status_id,
            });
            this.dlgCancelOrder.value = null;
            this.dlgCancelOrder.show = false;
          } else {
            msg = this.$t("message_cancel_order_error");
          }
        } else msg = this.$t("message_cancel_order_error");
        if (msg) this.message.push(msg);
        this.buttons.cancelOrder.disabled = false;
        this.buttons.cancelOrder.processing = false;
      } catch (error) {
        this.buttons.cancelOrder.disabled = false;
        this.buttons.cancelOrder.processing = false;
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.onCancelOrder);
        } else {
          let msge =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msge ? msge : this.$t("message_cancel_order_error")
          );
        }
      }
    },
    /*--------------- Meta key ------------------*/
    getOrderStatusByLang() {
      this.orderStatus.items = [];
      for (let i = 0; i < this.orderStatus.data.length; i++) {
        if (
          this.orderStatus.data[i].translation &&
          this.orderStatus.data[i].translation[this.lang] &&
          Object.keys(this.orderStatus.data[i].translation[this.lang]).length >
            0
        ) {
          this.orderStatus.items.push({
            id: this.orderStatus.data[i].id,
            key: this.orderStatus.data[i].key,
            name: this.orderStatus.data[i].name,
            value: this.orderStatus.data[i].translation[this.lang].value
              ? this.orderStatus.data[i].translation[this.lang].value
              : this.orderStatus.data[i].value,
            description: this.orderStatus.data[i].translation[this.lang]
              .description
              ? this.orderStatus.data[i].translation[this.lang].description
              : this.orderStatus.data[i].description,
          });
        } else {
          this.orderStatus.items.push({
            id: this.orderStatus.data[i].id,
            key: this.orderStatus.data[i].key,
            name: this.orderStatus.data[i].name,
            value: this.orderStatus.data[i].value,
            description: this.orderStatus.data[i].description,
          });
        }
      }
    },
    /*---------------End meta key ----------------*/
    /*--------------- Get data ------------------*/

    async getMyOrder() {
      let params = {
        create_from: this.myOrder.search.create_from
          ? new Date(this.myOrder.search.create_from)
          : null,
        create_to: this.myOrder.search.create_to
          ? new Date(this.myOrder.search.create_to)
          : null,
        size: this.myOrder.search.size,
        page: this.myOrder.search.page,
        status_id: this.myOrder.search.status_id
          ? this.myOrder.search.status_id
          : null,
        is_request_order: true,
      };
      this.myOrder.loading = true;
      this.buttons.filter.disabled = true;
      this.buttons.filter.processing = true;
      try {
        let response = await meControllerFunc.getMyOrder(
          params,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.myOrder.items = [
            ...this.myOrder.items,
            ...response.data.content_page,
          ];
          this.myOrder.page_number = response.data.page_number;
          this.myOrder.page_size = response.data.page_size;
          this.myOrder.total_page = response.data.total_page;
          this.myOrder.total_records = response.data.total_records;
          if (
            (this.myOrder.items.length === this.myOrder.total_records &&
              this.myOrder.total_page > 1) ||
            (response.data.total_page === 1 &&
              this.myOrder.items.length <= response.data.page_size)
          )
            this.myOrder.is_bottom = true;
        }

        this.myOrder.loading = false;
        this.buttons.filter.disabled = false;
        this.buttons.filter.processing = false;
      } catch (error) {
        this.myOrder.loading = false;
        this.buttons.filter.disabled = false;
        this.buttons.filter.processing = false;

        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getMyOrder);
        }
      }
    },
    async getAllOrderStatus() {
      try {
        let response = await metaKeyFunc.getByKey(
          "ORDER_STATUS",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.orderStatus.data =
            response.data && response.data.data ? response.data.data : [];
          this.getOrderStatusByLang();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllOrderStatus);
        }
      }
    },
    async getAllPriorityLevel() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PRIORITY_LEVEL",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.priorityLevel.items =
            response.data && response.data.data ? response.data.data : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllPriorityLevel);
        }
      }
    },

    async getAllTaskingGranularity() {
      try {
        let response = await metaKeyFunc.getByKey(
          "GRANULARITY",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.granularity.items = response.data.data ? response.data.data : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingGranularity);
        }
      }
    },

    async getAllTaskingProgrammingService() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PROGRAMMING_SERVICE",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.programingService.items = response.data.data
            ? response.data.data
            : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingProgrammingService);
        }
      }
    },

    async getAllDirection() {
      try {
        let response = await metaKeyFunc.getByKey(
          "DIRECTION",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          let data =
            response.data && response.data.data ? response.data.data : [];
          this.lookDirection.items = data.filter(
            (x) => x.name != "ASCENDING" && x.name != "DESCENDING"
          );
          this.orbitDirection.items = data.filter(
            (x) => x.name != "LEFT" && x.name != "RIGHT"
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllDirection);
        }
      }
    },

    async getAllSatelliteType() {
      try {
        let response = await metaKeyFunc.getByKey(
          "IMAGE_TYPES",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.satelliteType.items =
            response.data && response.data.data ? response.data.data : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllSatelliteType);
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    tableHeight() {
      return this.documentHeight - 56 - 96 - 2 * 42 - 38;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.my-order-content {
  margin: 0;
  padding: 0.5rem;
  .my-order__search {
    padding: 0;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .my-order__datatable {
    margin-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
}
</style>
