<template>
  <div class="aoi-info">
    <div class="">
      <div class="geospatial-label font-weight-bold">
        {{ $t("label_name") }}:&nbsp;<strong>{{
          propInfo.name ? propInfo.name : ""
        }}</strong>
      </div>
    </div>

    <div class="">
      <div class="geospatial-label font-weight-bold">
        {{ $t("label_polygon_points") }}
      </div>
      <div class="d-flex float-right mb-2">
        <div class="geospatial-label pr-2">{{ $t("label_format") }}&nbsp;</div>
        <select style="width: 200px" v-model="format">
          <option value="deg">{{ $t("label_lat_lng_degrees") }}</option>
          <option value="min">{{ $t("label_lat_lng_minutes") }}</option>
        </select>
      </div>
      <textarea class="form-control" readonly v-text="text" rows="4"></textarea>
    </div>
    <div class="p-2">
      <div class="font-italic">
        <span v-if="format === 'min'">{{ $t("label_aoi_minutes_info") }}</span>
        <span v-else>{{ $t("label_aoi_degrees_info") }}</span>
      </div>
    </div>
    <div class="">
      <div class="geospatial-label font-weight-bold">
        {{ $t("label_perimeter") }}
      </div>
      <div class="geospatial-label">{{ perimeterFeature }}</div>
    </div>
    <div class="">
      <div class="geospatial-label font-weight-bold">
        {{ $t("label_area") }}
      </div>
      <div class="geospatial-label">{{ areaFeature }}</div>
    </div>
  </div>
</template>
<script>
var formatLength = function(line) {
  var length = getLength(line);
  var output;
  if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + " " + "km";
  } else {
    output = Math.round(length * 100) / 100 + " " + "m";
  }
  return output;
};
var formatArea = function(polygon) {
  var area = getArea(polygon);
  var output;
  if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + " " + "km²";
  } else {
    output = Math.round(area * 100) / 100 + " " + "m²";
  }
  return output;
};
import { getArea, getLength } from "ol/sphere.js";
import { fromLonLat, transform } from "ol/proj";
import { toStringHDMS } from "ol/coordinate";
export default {
  props: {
    propInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      coordinates: [],
      format: "deg",
      areaFeature: 0,
      perimeterFeature: 0,
    };
  },
  watch: {
    "propInfo.feature": {
      handler(val) {
        this.coordinates = [];
        if (val) {
          let flatCoordinates = val.getGeometry().flatCoordinates;
          for (let i = 0; i < flatCoordinates.length - 2; i = i + 2) {
            let coor = transform(
              [flatCoordinates[i], flatCoordinates[i + 1]],
              "EPSG:3857",
              "EPSG:4326"
            );
            if (coor && coor.length > 0) {
              coor[0] = parseFloat(coor[0].toFixed(5));
              if (coor[1]) coor[1] = parseFloat(coor[1].toFixed(5));
            }
            this.coordinates.push(coor);
          }
          this.areaFeature = formatArea(val.getGeometry());
          this.perimeterFeature = formatLength(val.getGeometry());
        }
      },
      immediate: true,
    },
  },
  computed: {
    text() {
      let result = [];
      if (this.coordinates && this.coordinates.length) {
        let arrayCoor = Object.assign(
          [],
          JSON.parse(JSON.stringify(this.coordinates))
        );
        for (let i = 0; i < arrayCoor.length; i++) {
          switch (this.format) {
            case "deg":
              result.push(arrayCoor[i].reverse().join(", "));
              break;
            case "min":
              result.push(
                toStringHDMS(
                  transform(this.coordinates[i], "EPSG:4326", "EPSG:3857")
                )
              );
              break;
            default:
              result.push(arrayCoor[i].reverse().join(", "));
              break;
          }
        }
      }
      return result.join("; ").trim();
    },
  },
};
</script>
<style lang="scss" scoped>
.geospatial-label {
  line-height: 2.2em;
}
.geospatial-clear {
  clear: both;
  input {
    font-size: 13px;
    padding: 0.125rem 0.375rem;
  }
  .geospatial-textbox {
    padding: 5px 4px;
    border: 1px solid #ccc;
    border-top: 1px solid #999;
    font-size: small;
    width: 100%;
    transition: border linear 0.2s, box-shadow linear 0.2s;
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }
}
textarea {
  font-size: 13px;
  // font-family: Arial Unicode MS, Arial, sans-serif;
}
</style>
