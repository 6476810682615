<template>
  <CommonAccordion
    :isChevronEnd="true"
    :propTitle="'Planet' + ' (' + propItems.length + ')'"
    ><div slot="content" class="accordion w-100">
      <div class="datatable">
        <ContextMenu
          :model="menuModel"
          ref="cmDlgOrderDetailDatatable"
          :appendTo="'dialogOrderDetail'"
        />
        <DataTable
          :value="propItems"
          ref="dt"
          :resizableColumns="true"
          columnResizeMode="expand"
          selectionMode="single"
          dataKey="id"
          contextMenu
          :selection.sync="selected"
          @row-contextmenu="onRowContextMenu"
          @row-click="rowClick"
        >
          <Column>
            <template #body="slotProps">
              <div class="media">
                <icon-preview :propItem="slotProps.data"></icon-preview>
                <div class="media-body">
                  <ul class="m-0">
                    <li class="lbl-geostore">
                      <strong>{{
                        slotProps.data && slotProps.data.id
                          ? slotProps.data.id
                          : null
                      }}</strong>
                    </li>
                    <li class="mt-1">
                      <div class="">
                        <span
                          ><strong>{{ $t("label_image_time") }}:</strong>
                          {{ slotProps.data.properties.datetime }}</span
                        >
                        <span class="pl-2 pr-2">|</span>
                        <span
                          ><strong>{{ $t("label_satellite") }}: </strong>
                          {{
                            slotProps.data.properties &&
                            slotProps.data.properties.instrument
                              ? slotProps.data.properties.instrument
                              : ""
                          }}</span
                        >
                      </div>
                    </li>
                    <ItemMetadata
                      :prop-item="slotProps.data"
                      :propImageTypes="propImageTypes"
                    />
                  </ul>
                </div>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import IconPreview from "./planet/IconPreview.vue";
import ItemMetadata from "./planet/ItemMetadata.vue";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
import moment from "moment";
export default {
  components: {
    IconPreview,
    ItemMetadata,
    DataTable,
    Column,
    ContextMenu,
    CommonAccordion,
  },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propImageTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: null,
      selected2: null,
      menuModel: [
        {
          label: "Metadata",
          icon: "fas fa-list-alt",
          command: () =>
            this.showMetadata(this.selected ? this.selected : null),
          visible: true,
        },
      ],
      time: new Date().getTime(),
    };
  },
  created() {},
  methods: {
    showMetadata(data) {
      this.$emit("showMetadata", data);
    },
    onRowContextMenu(event) {
      if (this.selected && Object.keys(this.selected).length > 0) {
        this.$refs.cmDlgOrderDetailDatatable.show(event.originalEvent);
      }
    },
    onRowContextMenu2(event) {
      if (this.selected2 && Object.keys(this.selected2).length > 0) {
        this.$refs.cmDeliveryDatatable.show(event.originalEvent);
      }
    },

    rowClick(e) {
      if (e.data && this.selected && e.data.id == this.selected.id) {
        if (this.time) {
          if (new Date().getTime() - this.time <= 300) {
            this.$emit("showMetadata", this.selected ? this.selected : null);
          }
        }
      }
      this.time = new Date().getTime();
    },
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
  },
};
</script>
<style lang="scss">
.accordion {
  padding: 0.5rem;
  .accordion-header a {
    padding-left: 0;
  }
}
</style>
