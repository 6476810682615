<template>
  <Dialog
    :header="$t('title_export_aoi_to_file')"
    :visible.sync="show"
    :maximizable="false"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <div class="row no-gutters">
      <label class="col-3 m-0 pt-2">{{ $t("label_file_format") }}</label>
      <Dropdown
        class="col-9"
        v-model="value"
        :showClear="true"
        :options="formatTypes"
        optionValue="id"
        optionLabel="name"
        optionDisabled="is_disable"
        placeholder="- Select -"
      />
    </div>
    <template #footer>
      <Button
        :label="$t('button_text_close')"
        :icon="'fas fa-times'"
        :class="'p-button-outlined btn-transparent2'"
        @click="onClose"
      />
      <Button
        class="mr-0"
        :label="$t('button_text_agree')"
        :icon="'fas fa-check-circle'"
        :class="'p-button btn-geostore-2'"
        @click="onConfirm"
      />
    </template>
  </Dialog>
</template>
<script>
var formatArea = function (polygon) {
  var area = getArea(polygon);
  var output;
  if (area > 10000) {
    output = ((area / 1000000) * 100) / 100 + " " + "km2";
  } else {
    output = (area * 100) / 100 + " " + "m2";
  }
  return output;
};
import Button from "@/components/commons/button/Button";
import Dialog from "@/components/commons/dialog/Dialog";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import { GeoJSON } from "ol/format.js";
import Feature from "ol/Feature";
import { getArea, getLength } from "ol/sphere.js";
import shpwrite from "@/utils/commons/shp-write";
export default {
  components: {
    Dialog,
    Dropdown,
    Button,
  },
  props: {
    propTitle: {
      type: String,
      default: "Export AOI to File",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "400px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propType: {
      type: String,
      default: "primary",
    },
    propValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      value: 1,
      formatTypes: [
        {
          id: 1,
          code: "SHAPE_FILE",
          name: "Shape File (shp files archived)",
        },
        {
          id: 2,
          code: "KML",
          name: "Google Keyhole Mark-up Language File (KML)",
        },
      ],
    };
  },
  created() {
    this.show = this.propShow;
  },
  methods: {
    onConfirm() {
      switch (this.value) {
        case 1:
          this.exportShapeFile();
          break;
        case 2:
          this.exportKML();
          break;
      }
    },
    onClose() {
      this.$emit("close");
    },
    exportKML() {
      this.$emit("exportKML");
      this.onClose();
    },
    exportShapeFile() {
      var writer = new GeoJSON();
      var geom = [];
      geom.push(
        new Feature(
          this.propValue
            .getGeometry()
            .clone()
            .transform("EPSG:3857", "EPSG:4326")
        )
      );
      var geoJsonStr = writer.writeFeatures(geom);
      if (geoJsonStr) {
        var options = {
          folder: "shapes",
          types: {
            point: "POINTS",
            polygon: "POLYGONS",
            line: "LINES",
          },
        };
        try {
          let geoFeatures = JSON.parse(geoJsonStr);
          if (geoFeatures && Object.keys(geoFeatures).length > 0) {
            if (geoFeatures.features && geoFeatures.features.length > 0) {
              if (!geoFeatures.features[0].properties)
                geoFeatures.features[0].properties = {};
              geoFeatures.features[0].properties.area = formatArea(
                this.propValue.getGeometry()
              );
            }
          }
          shpwrite.download(geoFeatures, options);
        } catch (error) {}
      }
      this.onClose();
    },
  },
};
</script>
<style lang="scss" scoped></style>
