<template>
  <div class="confirmation-production-parameters_optical">
    <div class="row no-gutters">
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_product")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="propDto.optical.product_name"
              readonly
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_resampling_kernel")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="propDto.optical.resampling_kernel_name"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_bands")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="propDto.optical.bands_name"
              readonly
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_license")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="propDto.optical.license_name"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_bit_depth")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="propDto.optical.bit_depth_name"
              readonly
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_ortho_options")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="propDto.optical.ortho_option_name"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_pixel_resolution")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="propDto.optical.pixel_resolution_name"
              readonly
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_file_format")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="propDto.optical.file_format_name"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-6 col-form-label">{{
            $t("label_dra_contrast_enhancement")
          }}</label>
          <div class="col-12 col-md-6">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="propDto.optical.dra_contrast_name"
              readonly
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_tiling")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="propDto.optical.tiling_name"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 col-md-6">
        <div class="row no-gutters">
          <label class="col-12 col-md-4 col-form-label">{{
            $t("label_delivery_media")
          }}</label>
          <div class="col-12 col-md-8">
            <InputText
              class="form-control new-style w-100"
              maxlength="255"
              :value="propDto.optical.delivery_media_name"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <label class="col-2 col-form-label">{{ $t("label_note") }}</label>
      <div class="col-10">
        <Textarea
          class="form-control new-style"
          rows="2"
          maxlength="512"
          :value="propDto.note"
          :autoResize="true"
          readonly
        >
        </Textarea>
      </div>
    </div>
  </div>
</template>
<script>
import InputText from "@/components/commons/inputtext/InputText";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import Textarea from "@/components/commons/textarea/Textarea";
export default {
  components: {
    Dropdown,
    InputText,
    Textarea,
  },
  props: {
    propDto: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      arrayProduct: [
        {
          id: "VIEW_READY_2A",
          code: "VIEW_READY_2A",
          label: "View - ready(standard) 2A",
        },
        {
          id: "VIEW_READY_OR2A",
          code: "VIEW_READY_OR2A",
          label: "View - ready(standard) OR2A",
        },
        {
          id: "VIEW_READY_STEREO_OR2A",
          code: "VIEW_READY_STEREO_OR2A",
          label: "View - ready Stereo(standard) OR2A",
        },
        {
          id: "MAP_READY(Orthor 1:12000)",
          code: "MAP_READY",
          label: "Map - ready",
        },
        {
          id: "SYSTEM_READY",
          code: "SYSTEM_READY",
          label: "System - ready(basic)",
        },
        {
          id: "SYSTEM_READY_STEREO",
          code: "SYSTEM_READY_STEREO",
          label: "System - ready Stereo(basic)",
        },
      ],
      arrayBands: [
        {
          id: "PANCHROMATIC",
          code: "PANCHROMATIC",
          label: "Panchromatic (black/white)",
        },
        {
          id: "NATURAL_COLOR",
          code: "NATURAL_COLOR",
          label: "Natural color (3-band pan-sharpened: R,G,B)",
        },
        {
          id: "COLOR_INFRARED",
          code: "COLOR_INFRARED",
          label: "Color infrared ( 3- band pan-sharpened: R, G, NIR1)",
        },

        {
          id: "4_BAND_PAN_SHARPENED",
          code: "4_BAND_PAN_SHARPENED",
          label: "4-Band Pan-sharpened (Red,Green,Blue, Near IR)",
        },
        {
          id: "4_BAND_BUNDLE",
          code: "4_BAND_BUNDLE",
          label: "4-Band Bundle (Panchromatic +4- Band Multispectral)",
        },
        {
          id: "8_BAND_BUNDLE",
          code: "8_BAND_BUNDLE",
          label: "8-Band bundle (Panchromatic +8- Band Multispectral)",
        },
        {
          id: "8_BAND_SHORTWAVE_INFRARED",
          code: "8_BAND_SHORTWAVE_INFRARED",
          label: "8-Band Shortwave infrared (SWIR)",
        },
      ],
      arrayLicense: [
        {
          id: "SINGLE",
          code: "SINGLE",
          label: "Single",
        },
        {
          id: "GROUP_LICENSE_2_5",
          code: "GROUP_LICENSE_2_5",
          label: "Group license (2-5 user)",
        },
        {
          id: "GROUP_LICENSE_GREATER_THAN_5",
          code: "GROUP_LICENSE_GREATER_THAN_5",
          label: "Group license (>5 user)",
        },
      ],
      arrayBitDepth: [
        {
          id: "8BIT",
          code: "8BIT",
          label: "8 bit",
        },
        {
          id: "16BIT",
          code: "16BIT",
          label: "16 bit",
        },
      ],
      arrayPixelResolution: [
        {
          id: "30CM",
          code: "30CM",
          label: "30 cm",
        },
        {
          id: "40CM",
          code: "40CM",
          label: "40 cm",
        },
        {
          id: "50CM",
          code: "50CM",
          label: "50 cm",
        },
        {
          id: "60CM",
          code: "60CM",
          label: "60 cm",
        },
        {
          id: "STANDARD",
          code: "STANDARD",
          label: "Standard (60 cm QB or 50 cm orther)",
        },
      ],
      arrayDRAContrast: [
        {
          id: "YES",
          code: "YES",
          label: "Yes/on",
        },
        {
          id: "NO",
          code: "NO",
          label: "No/off",
        },
      ],
      arrayResamplingKernel: [
        {
          id: "ENANCHED_KERNEL",
          code: "ENANCHED_KERNEL",
          label: "Enanched kernel",
        },
        {
          id: "PAN_SHARPENDED_KERNEL",
          code: "PAN_SHARPENDED_KERNEL",
          label: "Pan-sharpended kernel",
        },
        {
          id: "MTF_KERNEL",
          code: "MTF_KERNEL",
          label: "MTF kernel",
        },

        {
          id: "4X4_CUBIC_CONVOLUTION",
          code: "4X4_CUBIC_CONVOLUTION",
          label: "4x4 Cubic convolution",
        },
        {
          id: "2X2_BILINEAR",
          code: "2X2_BILINEAR",
          label: "2x2 Bilinear",
        },
        {
          id: "NEAREST_NEIGHTBOR",
          code: "NEAREST_NEIGHTBOR",
          label: "Nearest Neightbor",
        },
      ],
      arrayOrthorOptions: [
        {
          id: "MOCSAICKED",
          code: "MOCSAICKED",
          label: "Mocsaicked",
        },
        {
          id: "BLOCK_ADJUSTED_PRODUCT",
          code: "BLOCK_ADJUSTED_PRODUCT",
          label: "Block Adjusted Product (strips)",
        },
        {
          id: "BLOCK_ADJUSTED_MULTI_IMAGE",
          code: "BLOCK_ADJUSTED_MULTI_IMAGE",
          label: "Block Adjusted Multi-image (For map scale orthor)",
        },
      ],
      arrayFileFormat: [
        {
          id: "GEOTIFF_1.0",
          code: "GEOTIFF_1.0",
          label: "GeoTiff 1.0",
        },
        {
          id: "NITF_2.0",
          code: "NITF_2.0",
          label: "NITF 2.0",
        },
        {
          id: "NITF_2.1",
          code: "NITF_2.1",
          label: "NITF 2.1",
        },
      ],
      arrayTiling: [
        {
          id: "NONE",
          code: "NONE",
          label: "none",
        },
        {
          id: "8x8_PIXEL",
          code: "8x8_PIXEL",
          label: "8x8 pixel",
        },
        {
          id: "14X14_PIXEL",
          code: "14X14_PIXEL",
          label: "14X14 pixel",
        },
        {
          id: "16X16_PIXEL",
          code: "16X16_PIXEL",
          label: "16X16 pixel",
        },
      ],
      arrayDeliveryMedia: [
        {
          id: "FTP_PULL",
          code: "FTP_PULL",
          label: "FTP pull",
        },
        {
          id: "DVD",
          code: "DVD",
          label: "DVD",
        },
        {
          id: "HD",
          code: "HD",
          label: "HD (Only for large volumes)",
        },
        {
          id: "OTHER",
          code: "OTHER",
          label: "Other",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.confirmation-production-parameters_optical {
  padding: 0 0.5rem;
  width: 100%;
  color: $text-color;
  .row {
    margin-bottom: 1rem;
    .col-md-6 {
      padding-right: 0.25rem;
      &:last-child {
        padding-right: 0;
        padding-left: 0.25rem;
      }
    }
    .row {
      margin: 0;
    }
  }
}
</style>
