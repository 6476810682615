<template>
  <div class="media">
    <div
      class="align-self-start mr-3 show-layer"
      title="Show layer"
      @click="showLayer"
      :class="propLayer.show ? 'active' : ''"
    >
      <i class="fas fa-eye"></i>
    </div>
    <div class="media-body">
      <h6 class="media-heading mt-0">
        {{ propLayer.title }}
      </h6>
      <div class="slidecontainer">
        <div class="form-group row no-gutters">
          <small for="inputrange" class="col-sm-2">Opacity</small>
          <div class="col-sm-10">
            <input
              type="range"
              min="1"
              max="100"
              step="0.01"
              v-model="sliderValue"
              @input="changeWidth"
              class="slider"
              id="myRange"
              :disabled="!propLayer.show"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    propLayer: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      sliderValue: 0,
    };
  },
  created() {
    if (Object.keys(this.propLayer).length > 0) {
      if (this.propLayer.opacity) this.sliderValue = this.propLayer.opacity;
    }
  },
  methods: {
    changeWidth() {
      this.$emit("changeOpacity", {
        layer: this.propLayer,
        opacity: this.sliderValue,
      });
    },
    showLayer: function () {
      this.$emit("showLayer", {
        layer: this.propLayer,
        show: !this.propLayer.show,
      });
    },
  },
};
</script>
<style scoped>
.show-layer {
  cursor: pointer;
}
.show-layer {
  color: rgba(0, 0, 0, 0.5);
}
.show-layer.active {
  color: #0d5881;
}
.slidecontainer input {
  width: 100%;
}
.media-heading small {
  float: right;
}
h6 {
  font-size: 14px;
}
</style>
