<template>
  <div class="tasking-time">
    <div
      v-if="
        propEntity.satellite_type &&
          propEntity.satellite_type.toLowerCase() === 'radar'
      "
    >
      <TaskingTimeRadar
        :propEntity="propEntity"
        :propLookDirection="propLookDirection"
        :propOrbitDirection="propOrbitDirection"
      />
    </div>
    <div
      v-else-if="
        propEntity.satellite_type &&
          propEntity.satellite_type.toLowerCase() === 'optical'
      "
    >
      <TaskingTimeOptical
        :propEntity="propEntity"
        :propOrderTypes="propOrderTypes"
      />
    </div>
  </div>
</template>
<script>
import TaskingTimeRadar from "./taskingtime/TaskingTimeRadar.vue";
import TaskingTimeOptical from "./taskingtime/TaskingTimeOptical.vue";
export default {
  components: { TaskingTimeRadar, TaskingTimeOptical },
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },
    propOrderTypes: {
      type: Array,
      default: () => [],
    },
    propLookDirection: {
      type: Array,
      default: () => [],
    },
    propOrbitDirection: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    if (!this.propOrderTypes || this.propOrderTypes.length === 0) {
      this.$emit("getAllTaskingOrderType");
    }
    if (!this.propLookDirection || this.propLookDirection.length === 0) {
      this.$emit("getAllDirection");
    }
  },
};
</script>
<style lang="scss" scoped></style>
