<template>
  <CommonAccordion
    :propTitle="$t('title_search')"
    @accordionHeaderClick="accordionHeaderClick"
  >
    <div slot="content">
      <div class="form-search">
        <div class="form-row no-gutters">
          <div class="col-3">
            <label class="control-label">{{ $t("label_image_name") }}</label>
            <InputText
              type="search"
              v-model="search.name"
              class="new-style form-control w-100"
              maxLength="255"
            />
          </div>
          <div class="col-2">
            <label class="control-label">{{ $t("label_status") }}</label>
            <Dropdown
              style="width: 100%"
              v-model="search.status_id"
              :showClear="true"
              :filter="true"
              :options="status"
              optionValue="id"
              optionLabel="label"
              datakey="id"
              class="new-style"
            >
              <template #option="slotProps">
                <div class="d-flex">
                  <div
                    class="square _18x18 pr-2"
                    :style="statusColor(slotProps.option.name)"
                  >
                    &nbsp;
                  </div>
                  <span>{{ $t(slotProps.option.label) }}</span>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="col-2">
            <label class="control-label">{{ $t("label_date_from") }}</label>
            <date-picker
              v-model="search.from"
              class="w-100"
              :format="'DD/MM/YYYY'"
              valueType="timestamp"
              :disabled-date="notAfterToday"
              :lang="lang"
              input-class=" form-control new-style"
            >
            </date-picker>
          </div>
          <div class="col-2">
            <label class="control-label">{{ $t("label_date_to") }}</label>
            <date-picker
              v-model="search.to"
              class="w-100"
              :format="'DD/MM/YYYY'"
              valueType="timestamp"
              :disabled-date="notAfterToday"
              :lang="lang"
              input-class=" form-control new-style"
            >
            </date-picker>
          </div>
          <div class="col-2">
            <button
              type="button"
              class="btn btn-geostore2 btn-sm btn-w120-h36"
              @click="onSearch"
              :disabled="propButtons.filter.disabled"
            >
              <i
                :class="
                  propButtons.filter.processing
                    ? 'fas fa-spinner fa-spin'
                    : 'fas fa-search'
                "
              ></i
              >&nbsp;{{ $t("button_text_search") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import CommonAccordion from "@/components/commons/accordion/Accordion3";
import InputText from "@/components/commons/inputtext/InputText";
export default {
  components: {
    DatePicker,
    Dropdown,
    CommonAccordion,
    InputText,
  },
  props: {
    propUser: {
      type: Array,
      default: () => [],
    },
    propSearch: {
      type: Object,
      default: () => {
        return {
          create_from: null,
          create_to: null,
          status_id: null,
          name: null,
        };
      },
    },
    propOrderStatus: {
      type: Array,
      default: () => [],
    },
    propButtons: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search: {
        from: null,
        to: null,
        status_id: null,
        name: null,
      },
      status: [
        {
          id: 0,
          name: "ORDER_PROCESSING",
          key: "label_processing",
          label: "",
        },
        {
          id: 1,
          name: "ORDER_COMPLETED",
          key: "label_completed",
          label: "",
        },
      ],
    };
  },
  created() {
    this.search.from = this.propSearch.create_from;
    this.search.to = this.propSearch.create_to;
    this.search.status_id = this.propSearch.status_id;
    this.search.name = this.propSearch.name;
    for (let i = 0; i < this.status.length; i++) {
      this.status[i].label = this.$t(this.status[i].key);
    }
  },
  watch: {
    propSearch: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          this.search.from = val.create_from ? val.create_from : null;
          this.search.to = val.create_to ? val.create_to : null;
          this.search.status_id = val.status_id ? val.status_id : null;
          this.search.name = val.name ? val.name : null;
        }
      },
      immediate: true,
    },
    lang(val) {
      for (let i = 0; i < this.status.length; i++) {
        this.status[i].label = this.$t(this.status[i].key);
      }
    },
  },
  methods: {
    accordionHeaderClick() {
      this.$emit("accordionHeaderClick");
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    getStatusLabel(val) {
      let item = this.status.find((x) => (x.id = val));
      if (item && Object.keys(item).length > 0) {
        return this.$t(item.label);
      } else return "";
    },
    statusColor(name) {
      let result = null;
      switch (name) {
        case "ORDER_PROCESSING":
          result = "background:#0d6efd";
          break;
        case "ORDER_CREATED":
          result = "background:#F68E1E";
          break;
        case "ORDER_COMPLETED":
          result = "background:#27AE60";
          break;
        case "ORDER_CANCELED":
          result = "background:#ffc107";
          break;
        case "ORDER_REJECTED":
          result = "background:#F1416C";
          break;
        case "ORDER_CONFIRMED":
          result = "background:#0dcaf0";
          break;
        default:
          result = "background:#9da5b1";
          break;
      }
      return result;
    },
    onSearch() {
      if (!this.search.from && this.search.to) {
        this.$emit("message", [
          this.$t("message_search_from_time_is_required"),
        ]);
        return;
      } else if (this.search.from && !this.search.to) {
        this.$emit("message", [this.$t("message_search_to_time_is_required")]);
        return;
      } else if (this.search.from && this.search.to) {
        let from = new Date(this.search.from).setHours(0, 0, 0, 0);
        let to = new Date(this.search.to).setHours(23, 59, 59, 0);
        if (from - to > 0) {
          this.$emit("message", [
            this.$t(
              "message_search_to_time_must_greater_than_or_equal_from_time"
            ),
          ]);
          return;
        }
        this.$emit("onSearch", {
          create_from: from,
          create_to: to,
          status_id: this.search.status_id ? this.search.status_id : null,
          name: this.search.name
            ? this.search.name.trim().replace(/\s\s+/g, " ")
            : null,
        });
      } else {
        this.$emit("onSearch", {
          create_from: null,
          create_to: null,
          status_id: this.search.status_id ? this.search.status_id : null,
          name: this.search.name
            ? this.search.name.trim().replace(/\s\s+/g, " ")
            : null,
        });
      }
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-search {
  button {
    margin-top: 1.25rem;
    font-size: 14px;
  }
  label {
    margin: 0;
    padding: 0;
    padding-left: 0.25rem;
  }
}
.form-group {
  margin-bottom: 0.5rem;
  margin: 0;
  label {
    padding-left: 0.25rem;
  }
}

.square {
  margin-right: 0.5rem;
  opacity: 0.7;
}
</style>

<style lang="scss">
.order__search {
  .mx-datepicker {
    width: 100%;
    .mx-input {
      border: 1px solid #a6a6a6 !important;
      height: 33px;
      border-radius: 3px;
      &:hover {
        border-color: #212121 !important;
      }
      &:disabled,
      .disabled {
        background-color: transparent;
      }
    }
  }
}
</style>
