<template>
  <router-view @initBreadcrumb="initBreadcrumb"></router-view>
</template>
<script>
export default {
  methods: {
    initBreadcrumb(data) {
      this.$emit("initBreadcrumb", data);
    },
  },
};
</script>
