<template>
  <Dialog
    :header="$t('header_confirm_password')"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <div class="p-fluid p-grid p-formgrid">
      <div class="p-field p-col-12">
        <label>{{ $t("label_password") }}</label>
        <InputText
          maxLength="50"
          type="password"
          class="new-style w-100"
          v-model="password"
          autofocus
        />
      </div>
    </div>
    <template #footer>
      <Button
        :label="$t('button_text_close')"
        :icon="'fas fa-times'"
        :class="'p-button-danger  p-button-sm mr-1'"
        @click="onClose"
      />
      <Button
        :label="$t('button_text_confirm')"
        :icon="'fas fa-check'"
        :class="'p-button-success p-button-sm'"
        @click="onDownload"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import InputText from "@/components/commons/inputtext/InputText";
export default {
  components: {
    Dialog,
    Button,
    InputText,
  },
  props: {
    propShow: {
      type: Boolean,
      default: false,
    },

    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "480px",
        };
      },
    },
  },
  data() {
    return {
      password: null,
      show: false,
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = this.propShow;
      },
      immediate: true,
    },
  },
  methods: {
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    onDownload() {
      if (!this.password) {
        this.$emit("message", [this.$t("label_password_is_required")]);
        return;
      }
      this.$emit("onDownload", this.password);
    },
  },
};
</script>
<style lang="scss" scoped></style>
