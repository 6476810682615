import axios from "axios";
import basicApi from "@/utils/apis/basic";
import { geoServiceApiURL } from "@/utils/commons/environment";
import { planetApiKey, planetEndPoint } from "@/utils/commons/environment";
function authenticateUser(user, password) {
  var token = user + ":" + password;
  // Should i be encoding this value????? does it matter???
  // Base64 Encoding -> btoa
  var hash = btoa(token);

  return "Basic " + hash;
}

export default {
  async searchByIds(ids, accessToken) {
    let headers = {
      Authorization: "Bearer " + accessToken,
    };
    let payload = {
      query: {},
      queryPage: {},
      searchChanel: {
        planet: true,
      },
      ids: ids,
    };

    const object = axios({
      method: "post",
      url: geoServiceApiURL + "/search",
      data: payload,
      headers: headers,
    });
    let response = await object;
    return response;
  },
  async search(entity, accessToken) {
    if (entity.page > 0 && !entity.link_next) return;
    let headers = basicApi.cloud.searchPlanet.headers;
    headers["Authorization"] = "Bearer " + accessToken;

    let payload = {
      query: {},
      queryPage: {
        planet: {
          limit: 250,
          page: entity?.page || 1,
        },
      },
      searchChanel: {
        planet: true,
      },
    };

    if (entity.boundary && entity.boundary.length > 0) {
      payload.intersects = {
        type: "Polygon",
        coordinates: [entity.boundary],
      };
    }

    if (entity.resolution_from || entity.resolution_to) {
      payload.query["sar:resolution_range"] = {
        gte: entity.resolution_from,
        lte: entity.resolution_to,
      };
    }

    if (entity.cloud_coverage_from || entity.cloud_coverage_to) {
      payload.query["eo:cloud_cover"] = {
        gte: entity.cloud_coverage_from,
        lte: entity.cloud_coverage_to,
      };
    }
    if (entity.incidence_angle_from || entity.incidence_angle_to) {
      payload.query["view:incidence_angle"] = {
        gte: entity.incidence_angle_from,
        lte: entity.incidence_angle_to,
      };
    }

    const object = axios({
      method: entity.page > 0 && entity.link_next ? "get" : "post",
      url:
        entity.page > 0 && entity.link_next
          ? entity.link_next
          : geoServiceApiURL + "/search",
      data: payload,
      headers: headers,
    });
    let response = await object;
    return response;
  },
  async getImageThumb(url) {
    let headers = basicApi.cloud.searchPlanet.headers;
    headers["Authorization"] = authenticateUser(planetApiKey, "");
    const object = axios({
      method: "get",
      url: url,
      headers: headers,
      responseType: "blob",
    });
    let response = await object;
    return response;
  },
  async createOrder(payload) {
    //let headers = basicApi.cloud.searchPlanet.headers;
    //headers["Authorization"] = authenticateUser(planetApiKey, "");
    const object = axios({
      //method: "post",
      // url: "planet/compute/ops/orders/v2",
      //data: payload,
      //headers: headers,
    });
    let response = await object;
    return response;
  },

  async getOrders(params) {
    //?order_state=<queued|running|success|partial|failed|cancelled>
    let headers = basicApi.cloud.searchPlanet.headers;
    headers["Authorization"] = authenticateUser(planetApiKey, "");
    //headers["Access-Control-Allow-Origin"] = "*";
    //headers["Content-Type"] = "application/json;charset=utf-8";

    const object = axios({
      method: "get",
      url: planetEndPoint + "/compute/ops/orders/v2/",
      params: params,
      headers: headers,
      mode: "no-cors",
      headers: headers,

      withCredentials: true,
      credentials: "same-origin",
      crossdomain: true,
    });
    let response = await object;
    return response;
  },

  async getOrderById(orderId) {
    let headers = basicApi.cloud.searchPlanet.headers;
    headers["Authorization"] = authenticateUser(planetApiKey, "");
    const object = axios({
      method: "get",
      url: planetEndPoint + "/compute/ops/orders/v2/" + orderId,
      headers: headers,
    });
    let response = await object;
    return response;
  },
  async cancelOrder(orderId) {
    let headers = basicApi.cloud.searchPlanet.headers;
    headers["Authorization"] = authenticateUser(planetApiKey, "");
    const object = axios({
      method: "put",
      url: planetEndPoint + "/compute/ops/orders/v2/" + orderId,
      headers: headers,
    });
    let response = await object;
    return response;
  },

  async bulkCancelOrderByIds(ids) {
    let headers = basicApi.cloud.searchPlanet.headers;
    headers["Authorization"] = authenticateUser(planetApiKey, "");
    const object = axios({
      method: "post",
      url: planetEndPoint + "/compute/ops/bulk/orders/v2/cancel",
      data: {
        order_ids: ids,
      },
      headers: headers,
    });
    let response = await object;
    return response;
  },
  async bulkCancelOrderQueued() {
    let headers = basicApi.cloud.searchPlanet.headers;
    headers["Authorization"] = authenticateUser(planetApiKey, "");
    const object = axios({
      method: "post",
      url: planetEndPoint + "/compute/ops/bulk/orders/v2/cancel",
      data: {},
      headers: headers,
    });
    let response = await object;
    return response;
  },
  async report() {
    let headers = basicApi.cloud.searchPlanet.headers;
    headers["Authorization"] = authenticateUser(planetApiKey, "");
    const object = axios({
      method: "get",
      url: planetEndPoint + "/reports/v0/",
      headers: headers,
    });
    let response = await object;
    return response;
  },
};
