<template>
  <div class="container-fluid tasking-content">
    <div class="dialog">
      <DialogDetail
        v-if="dialogDetail.show"
        :prop-show="dialogDetail.show"
        :prop-item="dialogDetail.item"
        @close="
          dialogDetail.show = false;
          dialogDetail.item = null;
        "
      />
      <DialogConfirmation
        v-if="confirmDelete.show"
        :prop-show="confirmDelete.show"
        :prop-title="$t('title_message_confirm')"
        :prop-icon="confirmDelete.icon"
        :prop-message="confirmDelete.message"
        :textNo="$t('button_text_close')"
        :textYes="$t('button_text_confirm')"
        @cancel="onCancelConfirmDelete"
        @confirmed="onConfirmedDelete"
      />
      <DialogMessage
        v-if="message.length > 0"
        :propTitle="$t('title_message_info')"
        :prop-show="message.length > 0"
        :prop-message="message"
        @close="message = []"
      />
    </div>
    <div class="tasking-oneatlats">
      <div class="oneatlats-tabs">
        <div class="tabs-search">
          <label
            class="search-field relative flex items-center cursor-pointer self-center justify-between"
          >
            <i class="fas fa-search"></i>

            <input
              type="search"
              placeholder="Customer reference"
              class="form-control"
              maxlength="50"
              v-model="customerRef"
            />
          </label>
        </div>
        <div class="relative">
          <div
            class="images-spinners Iybw1Bhj3Jhd8s"
            v-if="
              dataInprogress.loading ||
              dataCompleted.loading ||
              dataCancelled.loading
            "
          >
            <TileSpinner
              :loading="
                dataInprogress.loading ||
                dataCompleted.loading ||
                dataCancelled.loading
              "
              :height="'40'"
              :width="'40'"
              :color="'fd853e'"
              class="ehAbnmg4Jwb9BS"
            ></TileSpinner>
          </div>
          <Tabs
            :prop-tabs-height="mapComponentHeight"
            :prop-data="{
              inprogress: dataInprogress,
              completed: dataCompleted,
              cancelled: dataCancelled,
            }"
            :prop-footprint-ids="footprintIds"
            @tabChanged="tabChanged"
            @pagechanged="pagechanged"
            @mouseOverItem="mouseOverItem"
            @mouseLeaveItem="mouseLeaveItem"
            @addFootprint="addFootprint"
            @pan2Location="pan2Location"
            @showDetail="showDetail"
            @cancelTasking="cancelTasking"
          />
        </div>
      </div>
      <div class="oneatlats-map" :style="{ height: mapComponentHeight + 'px' }">
        <TaskingMap
          @initMap="
            map = $event.map;
            layer = $event.layer;
          "
        />
      </div>
    </div>
  </div>
</template>
<script>
import Tabs from "./Tabs.vue";
import TaskingMap from "./TaskingMap.vue";
import { TileSpinner } from "vue-spinners";
import DialogDetail from "./Detail.vue";
import DialogConfirmation from "@/components/commons/dialog/Confirmation";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import { Style, Fill, Stroke } from "ol/style.js";
import { transform } from "ol/proj";
import { getCenter } from "ol/extent";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
import oneAtlasFunc from "@/utils/functions/oneatlas";
import cryptoFunc from "@/utils/functions/crypto";
export default {
  components: {
    Tabs,
    TaskingMap,
    TileSpinner,
    DialogDetail,
    DialogConfirmation,
    DialogMessage,
  },
  data() {
    return {
      map: Object,
      layer: Object,
      workspaceId: "b277ebe5-1f77-482a-ad00-e31e40f1b31b",
      contractId: "cab446cb-9f14-4ba7-9e81-162dc495c59c",
      customerRef: null,
      timeout: null,
      minLength: 1,
      message: [],
      dataInprogress: {
        items: [],
        page: 0,
        size: 25,
        isBottom: false,
        loading: false,
        loaded: false,
      },
      dataCancelled: {
        items: [],
        page: 0,
        size: 25,
        isBottom: false,
        loading: false,
        loaded: false,
      },
      dataCompleted: {
        items: [],
        page: 0,
        size: 25,
        isBottom: false,
        loading: false,
        loaded: false,
      },
      contracts: [
        {
          contractId: "Vegastar_OneAtlastasking_Orders",
          customerId: "C501325",
          name: "Vegastar_OneAtlastasking_Orders",
          tradeAgreementUrl: "https://www.intelligence-airbusds.com/cgf/",
          creditRemaining: "12000.0",
          currency: "EUR",
          language: "en",
          contractType: "ONEATLASDATA",
          backendSource: "legacy",
        },
      ],
      confirmDelete: {
        message: null,
        show: false,
        title: null,
        icon: "fas fa-question",
        value: false,
      },
      dialogDetail: {
        item: null,
        show: false,
      },

      styles: {
        footprint: new Style({
          fill: new Fill({
            color: "rgba(0,158,247,0.3)",
          }),
          stroke: new Stroke({
            color: "rgba(0,158,247,1)",
            width: 1.25,
          }),
        }),
        footprintHover: new Style({
          fill: new Fill({
            color: "transparent",
          }),
          stroke: new Stroke({
            color: "rgba(0,158,247,1)",
            width: 1.25,
          }),
        }),
        image: new Style({
          fill: new Fill({
            color: "rgba(0,0,0,0)",
          }),
          stroke: new Stroke({
            color: "rgba(0,0,0,0.8)",
            width: 1.25,
          }),
        }),
      },
      hoverId: null,
      footprintIds: [],
    };
  },
  async created() {
    setTimeout(() => {
      this.$emit("initBreadcrumb", [
        "menu_sidebar_tasking",
        "menu_sidebar_integrate_oneatlas",
      ]);
    }, 200);
    // if (this.$store.getters.checkOneAtlasTokenExpired) {
    //   this.$store.dispatch("clearOneAtlasToken");
    //   let r = await this.getOneAtlasTokenData();
    //   if (!r) return;
    // }
    //await this.getContracts();
    // if (this.oneAtlasData || Object.keys(this.oneAtlasData).length === 0) {
    //   let r = await this.getOneAtlasUserInfo();
    // }
    this.getTaskingsInprogress();
  },
  watch: {
    customerRef(val) {
      this.onInput();
    },
  },
  methods: {
    async showDetail(val) {
      this.$store.dispatch("setSpinnerApp", {
        show: true,
      });
      try {
        let response = await oneAtlasFunc.trackTaskingDetail(
          this.oneAtlasContractId,
          val.icr.id,
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );
        if (response && response.status === 200) {
          if (
            response.data &&
            response.data.taskings &&
            response.data.taskings.length > 0
          ) {
            this.dialogDetail.item = response.data.taskings[0];
            this.dialogDetail.show = true;
          } else {
            this.message.push([
              msg
                ? msg
                : this.$t("message_not_found_tasking_detail", null, {
                    name: val.icr && val.icr.id ? val.icr.id : "",
                  }),
            ]);
          }
        } else {
          this.message.push([
            msg
              ? msg
              : this.$t("message_not_found_tasking_detail", null, {
                  name: val.icr && val.icr.id ? val.icr.id : "",
                }),
          ]);
        }
      } catch (error) {
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
        if (error && error.response && error.response.status === 401) {
          this.$store.dispatch("clearOneAtlasToken");
          let r = await this.getOneAtlasTokenData();
          if (r) {
            this.showDetail(val);
          }
        } else {
          let msg =
            error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push([
            msg
              ? msg
              : this.$t("message_not_found_tasking_detail", null, {
                  name: val.icr && val.icr.id ? val.icr.id : "",
                }),
          ]);
        }
      }
      this.$store.dispatch("setSpinnerApp", {
        show: false,
      });
    },

    onCancelConfirmDelete() {
      this.confirmDelete.value = null;
      this.confirmDelete.message = null;
      this.confirmDelete.show = null;
    },
    async onConfirmedDelete() {
      this.$store.dispatch("setSpinnerApp", {
        show: true,
      });
      try {
        let response = await oneAtlasFunc.cancelTasking(
          this.oneAtlasContractId,
          this.confirmDelete.value.icr.id,
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );
        if (response && response.status === 200) {
          this.$toast.success({
            title: this.$t("title_message_info"),
            message: this.$t("message_cancel_tasking_success", null, {
              name:
                this.confirmDelete.value.icr && this.confirmDelete.value.icr.id
                  ? this.confirmDelete.value.icr.id
                  : "",
            }),
            position: "top right",
          });
          this.onCancelConfirmDelete();
          this.dataInprogress.isBottom = false;
          this.dataInprogress.loaded = false;
          this.dataInprogress.page = 0;
          this.dataInprogress.items = [];

          this.dataCancelled.isBottom = false;
          this.dataCancelled.loaded = false;
          this.dataCancelled.page = 0;
          this.dataCancelled.items = [];
          this.getTaskingsInprogress();
          this.getTaskingsCancelled();
        } else {
          this.message.push([
            this.$t("message_cancel_tasking_error", null, {
              name:
                this.confirmDelete.value.icr && this.confirmDelete.value.icr.id
                  ? this.confirmDelete.value.icr.id
                  : "",
            }),
          ]);
        }
      } catch (error) {
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
        if (error && error.response && error.response.status === 401) {
          this.$store.dispatch("clearOneAtlasToken");
          let r = await this.getOneAtlasTokenData();
          if (r) {
            this.onConfirmedDelete();
          }
        } else {
          let msg =
            error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push([
            msg
              ? msg
              : this.$t("message_cancel_tasking_error", null, {
                  name:
                    this.confirmDelete.value.icr &&
                    this.confirmDelete.value.icr.id
                      ? this.confirmDelete.value.icr.id
                      : "",
                }),
          ]);
        }
      }
      this.$store.dispatch("setSpinnerApp", {
        show: false,
      });
    },
    cancelTasking(val) {
      this.confirmDelete.value = val;
      this.confirmDelete.message = this.$t("message_cancel_tasking", null, {
        name: val.icr && val.icr.id ? val.icr.id : "",
      });
      this.confirmDelete.show = true;
    },
    addFootprint(val) {
      let index = this.footprintIds.findIndex((x) => x === val.icr.id);
      // add
      if (index < 0) {
        this.footprintIds.push(val.icr.id);

        var coors = [];
        if (
          val.icr &&
          val.icr.aoi &&
          val.icr.aoi.coordinates &&
          val.icr.aoi.coordinates.length > 0
        ) {
          for (let i = 0; i < val.icr.aoi.coordinates[0].length; i++) {
            coors.push(val.icr.aoi.coordinates[0][i]);
          }
        }
        try {
          this.addFeature(
            this.layer,
            coors,
            this.styles.footprint,
            "FOOTPRINT_" + val.icr.id
          );
        } catch (error) {}
      } else {
        //remove
        this.footprintIds.splice(index, 1);
        let feature = this.layer
          .getSource()
          .getFeatureById("FOOTPRINT_" + val.icr.id);
        if (feature) {
          this.layer.getSource().removeFeature(feature);
        }
      }
    },
    pan2Location(val) {
      if (
        val.icr &&
        val.icr.aoi &&
        val.icr.aoi.coordinates &&
        val.icr.aoi.coordinates.length > 0
      ) {
        let coors = [];
        for (let i = 0; i < val.icr.aoi.coordinates[0].length; i++) {
          coors.push(
            transform(val.icr.aoi.coordinates[0][i], "EPSG:4326", "EPSG:3857")
          );
        }
        let polygon = new Polygon([coors]);
        this.map.getView().fit(polygon.getExtent(), {
          size: this.map.getSize() - 100,
        });
        let center = getCenter(polygon.getExtent());
        this.map.getView().animate({
          center: [center[0], center[1]],
          duration: 100,
          zoom:
            this.map.getView().getZoom() < 2
              ? 2
              : this.map.getView().getZoom() - 0.5,
        });
      }
    },
    async mouseOverItem(val) {
      if (this.hoverId === val.icr.id) return;
      if (!this.footprintIds.includes(val.icr.id)) {
        this.hoverId = val.icr.id;
        try {
          let feature = this.layer
            .getSource()
            .getFeatureById("hover_" + this.hoverId);
          if (!feature) {
            let coors = [];
            if (
              val.icr &&
              val.icr.aoi &&
              val.icr.aoi.coordinates &&
              val.icr.aoi.coordinates.length > 0
            ) {
              for (let i = 0; i < val.icr.aoi.coordinates[0].length; i++) {
                coors.push(val.icr.aoi.coordinates[0][i]);
              }
            }
            this.addFeature(
              this.layer,
              coors,
              this.styles.footprintHover,
              "hover_" + this.hoverId
            );
          }
        } catch (error) {}
      }
    },
    async mouseLeaveItem(val) {
      if (this.hoverId === "") return;
      if (!this.layer || Object.keys(this.layer).length === 0)
        await this.getLayerDraw();
      try {
        let feature = this.layer
          .getSource()
          .getFeatureById("hover_" + val.icr.id);
        if (feature) {
          this.layer.getSource().removeFeature(feature);
        } else {
        }
      } catch (error) {}
      this.hoverId = "";
    },

    addFeature(layer, coordinates, style, id) {
      let feature = layer.getSource().getFeatureById(id);
      if (!feature) {
        var points = [];
        try {
          for (var i = 0; i < coordinates.length; i++) {
            points[i] = transform(coordinates[i], "EPSG:4326", "EPSG:3857");
          }
          feature = new Feature({
            geometry: new Polygon([points]),
            id: id,
          });
          feature.set("id", id);
          feature.id_ = id;
          feature.setStyle(style);
          layer.getSource().addFeature(feature);
        } catch (error) {
          console.log(error);
        }
      } else {
        feature.style = { visibility: "visible" };
      }
    },
    onInput() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      let query = this.customerRef;
      if (query.length === 0) {
        this.dataInprogress.isBottom = false;
        this.dataInprogress.loaded = false;
        this.dataInprogress.page = 0;
        this.dataInprogress.items = [];
        this.dataCompleted.isBottom = false;
        this.dataCompleted.loaded = false;
        this.dataCompleted.page = 0;
        this.dataCompleted.items = [];
        this.dataCancelled.isBottom = false;
        this.dataCancelled.loaded = false;
        this.dataCancelled.page = 0;
        this.dataCancelled.items = [];
        this.getTaskingsInprogress();
        this.getTaskingsCompleted();
        this.getTaskingsCancelled();
      } else {
        query = query.trim();
        if (query.length >= this.minLength) {
          this.timeout = setTimeout(() => {
            this.dataInprogress.isBottom = false;
            this.dataInprogress.loaded = false;
            this.dataInprogress.page = 0;
            this.dataInprogress.items = [];
            this.dataCompleted.isBottom = false;
            this.dataCompleted.loaded = false;
            this.dataCompleted.page = 0;
            this.dataCompleted.items = [];
            this.dataCancelled.isBottom = false;
            this.dataCancelled.loaded = false;
            this.dataCancelled.page = 0;
            this.dataCancelled.items = [];
            this.getTaskingsInprogress(query);
            this.getTaskingsCompleted(query);
            this.getTaskingsCancelled(query);
          }, 500);
        } else {
          this.dataInprogress.isBottom = false;
          this.dataInprogress.loaded = false;
          this.dataInprogress.page = 0;
          this.dataInprogress.items = [];
          this.dataCompleted.isBottom = false;
          this.dataCompleted.loaded = false;
          this.dataCompleted.page = 0;
          this.dataCompleted.items = [];
          this.dataCancelled.isBottom = false;
          this.dataCancelled.loaded = false;
          this.dataCancelled.page = 0;
          this.dataCancelled.items = [];
          this.getTaskingsInprogress();
          this.getTaskingsCompleted();
          this.getTaskingsCancelled();
        }
      }
    },
    pagechanged(data) {
      switch (data.tab) {
        case "INPROGRESS":
          if (!this.dataInprogress.isBottom) {
            this.dataInprogress.loaded = false;
            this.dataInprogress.page = data.val;
            this.getTaskingsInprogress();
          }

          break;
        case "COMPLETED":
          if (!this.dataCompleted.isBottom) {
            this.dataCompleted.loaded = false;
            this.dataCompleted.page = data.val;
            this.getTaskingsCompleted();
          }
          break;
        case "CANCELLED":
          if (!this.dataCancelled.isBottom) {
            this.dataCancelled.loaded = false;
            this.dataCancelled.page = data.val;
            this.getTaskingsCancelled();
          }
          break;
      }
    },
    tabChanged(tabIndex) {
      switch (tabIndex) {
        case 0:
          this.getTaskingsInprogress();
          break;
        case 1:
          this.getTaskingsCompleted();
          break;
        case 2:
          this.getTaskingsCancelled();
          break;
        default:
          break;
      }
    },
    async getTaskingsInprogress(customerReference = null) {
      if (this.dataInprogress.isBottom || this.dataInprogress.loaded) return;
      this.dataInprogress.loading = true;
      try {
        let response = await oneAtlasFunc.trackTaskings(
          this.oneAtlasContractId,
          {
            status: "In Progress",
            startPage: this.dataInprogress.page + 1,
            count: this.dataInprogress.size,
            customerRef: customerReference ? customerReference : null,
          },
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );
        if (response && response.status === 200) {
          this.dataInprogress.items = [
            ...this.dataInprogress.items,
            ...response.data.taskings,
          ];
          if (response.data.taskings.length < this.dataInprogress.size)
            this.dataInprogress.isBottom = true;
        }
        this.dataInprogress.loaded = true;
        this.dataInprogress.loading = false;
      } catch (error) {
        this.dataInprogress.loading = false;
        if (error && error.response && error.response.status === 401) {
          this.$store.dispatch("clearOneAtlasToken");
          let r = await this.getOneAtlasTokenData();
          if (r) {
            this.getTaskingsInprogress();
          }
        }
      }
    },
    async getTaskingsCancelled(customerReference = null) {
      if (this.dataCancelled.isBottom || this.dataCancelled.loaded) return;
      this.dataCancelled.loading = true;
      try {
        let response = await oneAtlasFunc.trackTaskings(
          this.oneAtlasContractId,
          {
            status: "Canceled",
            startPage: this.dataCancelled.page + 1,
            count: this.dataCancelled.size,
            customerRef: customerReference ? customerReference : null,
          },
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );
        if (response && response.status === 200) {
          this.dataCancelled.items = [
            ...this.dataCancelled.items,
            ...response.data.taskings,
          ];
          if (response.data.taskings.length < this.dataCancelled.size)
            this.dataCancelled.isBottom = true;
        }
        this.dataCancelled.loaded = true;
        this.dataCancelled.loading = false;
      } catch (error) {
        this.dataCancelled.loading = false;
        if (error && error.response && error.response.status === 401) {
          this.$store.dispatch("clearOneAtlasToken");
          let r = await this.getOneAtlasTokenData();
          if (r) {
            this.getTaskingsCancelled();
          }
        }
      }
    },
    async getTaskingsCompleted(customerReference = null) {
      if (this.dataCompleted.isBottom || this.dataCompleted.loaded) return;
      this.dataCompleted.loading = true;
      try {
        let response = await oneAtlasFunc.trackTaskings(
          this.oneAtlasContractId,
          {
            status: "Completed",
            startPage: this.dataCompleted.page + 1,
            count: this.dataCompleted.size,
            customerRef: customerReference ? customerReference : null,
          },
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );
        if (response && response.status === 200) {
          this.dataCompleted.items = [
            ...this.dataCompleted.items,
            ...response.data.taskings,
          ];
          if (response.data.taskings.length < this.dataCompleted.size)
            this.dataCompleted.isBottom = true;
        }
        this.dataCompleted.loaded = true;
        this.dataCompleted.loading = false;
      } catch (error) {
        this.dataCompleted.loading = false;
        if (error && error.response && error.response.status === 401) {
          this.$store.dispatch("clearOneAtlasToken");
          let r = await this.getOneAtlasTokenData();
          if (r) {
            this.getTaskingsCompleted();
          }
        }
      }
    },
    async getOneAtlasUserInfo() {
      var vm = this;
      return new Promise(async function (resolve, reject) {
        try {
          let response = await oneAtlasFunc.getUserMe(
            vm.$store.getters.getOneAtlasTokenType +
              " " +
              vm.$store.getters.getOneAtlasToken
          );
          if (response && response.status === 200) {
            await vm.$store.dispatch("setOneAtlasData", response.data);
            resolve(response.data);
          } else return resolve(null);
        } catch (error) {
          console.log(error);
          return resolve(null);
        }
      });
    },
    async getOneAtlasTokenData() {
      var vm = this;
      return new Promise(async function (resolve, reject) {
        try {
          let res = await oneAtlasFunc.getAccessToken();
          if (res && res.status === 200) {
            res.data.expires_time =
              new Date().getTime() +
              (res.data.expires_in ? res.data.expires_in * 1000 : 0);

            await localStorage.setItem(
              "oneatlas-token",
              btoa(cryptoFunc.encrypt(JSON.stringify(res.data)).toString())
            );
            await vm.$store.dispatch("setOneAtlasToken", res.data);
            resolve(1);
          } else {
            return resolve(null);
          }
        } catch (err) {
          console.log(err);
          resolve(null);
        }
      });
    },

    async getContracts() {
      try {
        let response = await oneAtlasFunc.getContracts(
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );
        if (response && response.status === 200) {
          this.contracts =
            response.data && response.data.contracts
              ? response.data.contracts
              : [];
        }
      } catch (error) {
        if (error && error.response && error.response.status === 401) {
          this.$store.dispatch("clearOneAtlasToken");
          let r = await this.getOneAtlasTokenData();
          if (r) {
            this.getContracts();
          }
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (
            error.response.data.message ===
            "malformed authorization header, we found an Authorization header but it did not have the correct format"
          ) {
            this.$store.dispatch("clearOneAtlasToken");
            let r = await this.getOneAtlasTokenData();
            if (r) {
              this.createOrder();
            }
          }
        }
      }
    },
  },
  computed: {
    mapComponentHeight() {
      return this.documentHeight - 56 - 2 * 8 - 2 * 8;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    oneAtlasData() {
      return this.$store.getters.getOneAtlasData;
    },
    oneAtlasContractId() {
      return this.oneAtlasContract ? this.oneAtlasContract.contractId : null;
    },
    oneAtlasWorkspaceId() {
      if (!this.oneAtlasData || Object.keys(this.oneAtlasData).length === 0)
        return null;
      else {
        return this.oneAtlasData.contract &&
          this.oneAtlasData.contract.workspaceId
          ? this.oneAtlasData.contract.workspaceId
          : null;
      }
    },
    oneAtlasContract() {
      let result = null;
      if (this.contracts && this.contracts.length > 0) {
        result = this.contracts[0];
      }
      try {
        if (!result) result = this.contracts.find((x) => x.contractId);
      } catch (error) {}
      return result;
    },
    oneAtlasContractId() {
      return this.oneAtlasContract ? this.oneAtlasContract.contractId : null;
    },
  },
};
</script>
<style lang="scss" scoped>
.container-fluid {
  margin: 0;
  padding: 0;
  &.tasking-content {
    padding: 0.5rem;
    border-radius: 0.25rem;
  }
  .tasking-oneatlats {
    display: grid;
    grid-template-columns: [first] 30rem [line2] auto [end];
    gap: 1rem;
    padding: 0.5rem;
    background: white;
    border-radius: 0.25rem;
    .oneatlats-tabs {
      .relative {
        position: relative;
        .Iybw1Bhj3Jhd8s {
          display: table;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: rgba(0, 0, 0, 0.3);
          z-index: 1;
          .ehAbnmg4Jwb9BS {
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
      .tabs-search {
        width: 100%;
        background: #eee;
        border-radius: 0.25rem;
        margin-bottom: 0.5rem;
        .search-field {
          position: relative;
          display: flex;
          align-items: center;
          padding: 0.75rem 1rem;
          margin: 0;
          &:hover {
            cursor: pointer;
          }
          input {
            width: 100%;
            margin: auto 0;
            padding-right: 1.5rem;
          }
          i {
            position: absolute;
            right: 1.5rem;
          }
        }
      }
    }
    .oneatlats-map {
      position: relative;
      border-radius: 0.25rem;
    }
  }
}
</style>
