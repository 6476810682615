var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"confirmation-production-parameters_radar"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-2"},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t("label_product_level")))])]),_c('div',{staticClass:"col-10"},[_c('InputText',{staticClass:"form-control new-style w-100",attrs:{"maxlength":"255","value":_vm.propDto.order_request_info.processing_level
            ? _vm.propDto.order_request_info.processing_level.translation &&
              _vm.propDto.order_request_info.processing_level.translation[_vm.lang] &&
              _vm.propDto.order_request_info.processing_level.translation[_vm.lang]
                .value
              ? _vm.propDto.order_request_info.processing_level.translation[_vm.lang]
                  .value
              : _vm.propDto.order_request_info.processing_level.value
            : '',"readonly":""}})],1)]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-2"},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t("label_file_format")))])]),_c('div',{staticClass:"col-10"},[_c('InputText',{staticClass:"form-control new-style w-100",attrs:{"maxlength":"255","value":_vm.propDto.order_request_info.file_format
            ? _vm.propDto.order_request_info.file_format.translation &&
              _vm.propDto.order_request_info.file_format.translation[_vm.lang] &&
              _vm.propDto.order_request_info.file_format.translation[_vm.lang].value
              ? _vm.propDto.order_request_info.file_format.translation[_vm.lang].value
              : _vm.propDto.order_request_info.file_format.value
            : '',"readonly":""}})],1)]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-2"},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t("label_delivery_media")))])]),_c('div',{staticClass:"col-10"},[_c('InputText',{staticClass:"form-control new-style w-100",attrs:{"maxlength":"255","value":_vm.propDto.order_request_info.delivery_media
            ? _vm.propDto.order_request_info.delivery_media.translation &&
              _vm.propDto.order_request_info.delivery_media.translation[_vm.lang] &&
              _vm.propDto.order_request_info.delivery_media.translation[_vm.lang]
                .value
              ? _vm.propDto.order_request_info.delivery_media.translation[_vm.lang]
                  .value
              : _vm.propDto.order_request_info.delivery_media.value
            : '',"readonly":""}})],1)]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-2"},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t("label_delivery_service")))])]),_c('div',{staticClass:"col-10"},[_c('InputText',{staticClass:"form-control new-style w-100",attrs:{"maxlength":"255","value":_vm.propDto.order_request_info.delivery_service
            ? _vm.propDto.order_request_info.delivery_service.translation &&
              _vm.propDto.order_request_info.delivery_service.translation[_vm.lang] &&
              _vm.propDto.order_request_info.delivery_service.translation[_vm.lang]
                .value
              ? _vm.propDto.order_request_info.delivery_service.translation[_vm.lang]
                  .value
              : _vm.propDto.order_request_info.delivery_service.value
            : '',"readonly":""}})],1)]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-2"},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t("label_license"))+" ")])]),_c('div',{staticClass:"col-10"},[_c('InputText',{staticClass:"form-control new-style w-100",attrs:{"maxlength":"255","value":_vm.propDto.order_request_info.license
            ? _vm.propDto.order_request_info.license.translation &&
              _vm.propDto.order_request_info.license.translation[_vm.lang] &&
              _vm.propDto.order_request_info.license.translation[_vm.lang].value
              ? _vm.propDto.order_request_info.license.translation[_vm.lang].value
              : _vm.propDto.order_request_info.license.value
            : '',"readonly":""}})],1)]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-2"},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t("label_note")))])]),_c('div',{staticClass:"col-10"},[_c('Textarea',{staticClass:"form-control new-style",attrs:{"rows":"2","maxlength":"512","value":_vm.propDto.note,"autoResize":true,"readonly":""}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }