<template>
  <div class="container-fluid">
    <div class="outer">
      <div class="middle">
        <div class="user-change-pass">
          <div class="user-change-pass__dialog">
            <DialogMessage
              v-if="message.length > 0"
              :propTitle="$t('title_message_info')"
              :prop-show="message.length > 0"
              :prop-message="message"
              @close="message = []"
            />
          </div>
          <div class="user-change-pass__structure">
            <ValidationObserver ref="passObserver">
              <div class="align-middle">
                <div class="form-group">
                  <label class="w-100 form-label"
                    >{{ $t("label_old_password") }}&nbsp;
                    <span class="font-weight-bold required">*</span></label
                  >
                  <ValidationProvider
                    :name="$t('label_old_password')"
                    rules="required"
                  >
                    <InputText
                      type="password"
                      class="form-control new-style"
                      v-model="entity.pass_old"
                      aria-autocomplete="off"
                      autocomplete="off"
                      maxLength="50"
                    />
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label class="w-100 form-label"
                    >{{ $t("label_new_password") }}&nbsp;
                    <span class="font-weight-bold required">*</span></label
                  >
                  <ValidationProvider
                    :name="$t('label_new_password')"
                    rules="required"
                    vid="confirmation"
                  >
                    <InputText
                      type="password"
                      class="form-control new-style"
                      v-model="entity.pass_new"
                      aria-autocomplete="off"
                      autocomplete="off"
                      maxLength="50"
                    />
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label class="w-100 form-label"
                    >{{ $t("label_new_password_confirm") }}&nbsp;
                    <span class="font-weight-bold required">*</span></label
                  >
                  <ValidationProvider
                    :name="$t('label_new_password_confirm')"
                    rules="required|confirmed:confirmation"
                  >
                    <InputText
                      type="password"
                      class="form-control new-style"
                      v-model="entity.pass_confirm"
                      aria-autocomplete="off"
                      autocomplete="off"
                      maxLength="50"
                    />
                  </ValidationProvider>
                </div>
                <button
                  :label="$t('button_text_save')"
                  icon=""
                  class="btn btn-geostore2 w-100"
                  @click="onSave"
                >
                  <i class="fas fa-save"></i>&nbsp;{{ $t("button_text_save") }}
                </button>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputText from "@/components/commons/inputtext/InputText";
import Button from "@/components/commons/button/Button";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import userMeControllerFunc from "@/utils/functions/usermecontroller";
export default {
  components: {
    InputText,
    Button,
    DialogMessage,
  },
  data() {
    return {
      message: [],
      entity: {
        pass_old: null,
        pass_new: null,
        pass_confirm: null,
      },
    };
  },
  created() {
    this.$emit("initBreadcrumb", [
      "menu_sidebar_account",
      "menu_sidebar_change_pass",
    ]);
  },
  methods: {
    onSave() {
      var vm = this;
      this.$refs.passObserver.validate().then(async (success) => {
        if (!success) {
          setTimeout(() => {
            Object.keys(vm.$refs.passObserver.errors).forEach(function (key) {
              if (
                vm.$refs.passObserver.errors[key] &&
                vm.$refs.passObserver.errors[key].length
              )
                vm.message.push(vm.$refs.passObserver.errors[key].join(",\n"));
            });
          }, 100);
          return;
        } else {
          let dto = Object.assign({}, this.entity);
          dto.pass_old = dto.pass_old ? dto.pass_old.trim() : null;
          dto.pass_new = dto.pass_new ? dto.pass_new.trim() : null;
          dto.pass_confirm = dto.pass_confirm ? dto.pass_confirm.trim() : null;

          if (dto.pass_old === dto.pass_new) {
            this.message.push(
              this.$t("message_pass_new_must_different_pass_old")
            );
            return;
          }
          if (dto.pass && dto.pass_new.length < 8) {
            this.message.push(this.$t("message_password_characters_error"));
            return;
          }
          let number = 0,
            alpha = 0;
          for (let i = 0; i < dto.pass_new.length; i++) {
            let characterNum = dto.pass_new.charCodeAt(i);
            if (characterNum === 32) {
              this.message.push(this.$t("message_password_characters_error"));
              break;
            }
            if (characterNum >= 48 && characterNum <= 57) {
              number = number + 1;
            }
            if (characterNum >= 65 && characterNum <= 90) {
              alpha = alpha + 1;
            }
            if (characterNum >= 97 && characterNum <= 122) {
              alpha = alpha + 1;
            }
          }
          if (number == 0 || alpha == 0) {
            this.message.push(this.$t("message_password_characters_error"));
          }
          if (this.message.length > 0) {
            return;
          }
          try {
            let response = await userMeControllerFunc.changePass(
              dto,
              this.$store.getters.getAccessToken
            );
            let msg = null;
            if (response && response.status === 200) {
              if (response.data && response.data.success) {
                this.$toast.success({
                  title: this.$t("title_message_info"),
                  message: this.$t("message_change_pass_success"),
                  position: "top right",
                });
                this.$router.push({
                  name: "Login",
                  query: {
                    next: btoa(this.$route.path),
                  },
                });
              } else {
                msg = this.$t("message_change_pass_error");
              }
            } else msg = this.$t("message_change_pass_error");

            if (msg) this.message.push(msg);
          } catch (error) {
            if (error.response && error.response.status === 401) {
              this.refreshToken(this.onSave);
            } else {
              let msg = null;
              msg =
                error.response.data && error.response.data.message
                  ? error.response.data.message
                  : null;
              if (!msg) this.$t("message_change_pass_error");
              this.message.push(msg);
            }
          }
        }
      });
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.container-fluid {
  position: relative;
  width: 100%;
  height: calc(100vh - 56px);
}
.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1080;

  .middle {
    display: table-cell;
    vertical-align: middle;

    .user-change-pass {
      background: white;
      -webkit-box-shadow: 0px 0px 2px 0px rgba(2, 34, 89, 1);
      -moz-box-shadow: 0px 0px 2px 0px rgba(2, 34, 89, 1);
      box-shadow: 0px 0px 2px 0px rgba(2, 34, 89, 1);
      border-radius: 0.25rem;
      margin-left: auto;
      margin-right: auto;
      padding: 1.5rem;
      width: 560px;
      input,
      label,
      button {
        font-size: 14px;
      }
      label {
        padding-left: 0.25rem;
        color: $text-color;
        font-weight: 500;
      }
    }
  }
}
</style>
