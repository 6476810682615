<template>
  <table>
    <tbody>
      <tr>
        <td>Coordinate system:</td>
        <td class="plaintext">{{ propCoordinateSystem }}</td>
      </tr>
      <tr>
        <td>Dates:</td>
        <td class="plaintext">
          {{ strDates }}
        </td>
      </tr>
      <tr>
        <td>Max incidence angle:</td>
        <td class="plaintext">{{ maxIncidenceAngle }}&deg;</td>
      </tr>
      <tr>
        <td>Max cloud cover:</td>
        <td class="plaintext">{{ maxCloudCover }}%</td>
      </tr>
      <tr v-if="this.propEntity.feasibility.type === 'custom'">
        <td>Notification threshold:</td>
        <td class="plaintext">{{ notificationThreshold }}</td>
      </tr>
      <tr v-if="this.propEntity.feasibility.type === 'custom'">
        <td>Acquisition Mode:</td>
        <td class="plaintext">{{ acquisitionMode }}</td>
      </tr>
      <tr v-if="minBH">
        <td>Min B/H:</td>
        <td class="plaintext">{{ minBH }}</td>
      </tr>
      <tr v-if="maxBH">
        <td>Max B/H:</td>
        <td class="plaintext">{{ maxBH }}</td>
      </tr>
      <tr>
        <td>Classification:</td>
        <td class="plaintext">{{ classification }}</td>
      </tr>

      <tr>
        <td>Manual feasibility study required:</td>
        <td class="plaintext">
          {{ isManual }}
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import moment from "moment";
export default {
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },
    propCoordinateSystem: {
      type: String,
      default: "",
    },
    // Response from api
    propFeasibilityOneAtlas: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    strStartDate() {
      try {
        moment.locale(this.lang);
        let date = new Date(this.propEntity.feasibility.startDate);
        return moment(date).format("ll");
        // let start = new Date(this.propEntity.feasibilityOneSeries.startDate);
        // let startD = start.getDate();
        // if (startD < 10) startD = "0" + startD;
        // let startMonth = start.getMonth();
        // if (startMonth < 10) startMonth = "0" + startMonth;
        // return start.getFullYear() + "-" + startMonth + "-" + startD;
      } catch (error) {}
      return null;
    },
    strEndDate() {
      try {
        moment.locale(this.lang);
        let date = new Date(this.propEntity.feasibility.endDate);
        return moment(date).format("ll");
        // let end = new Date(this.propEntity.feasibilityOneSeries.endDate);
        // let endD = end.getDate();
        // if (endD < 10) endD = "0" + endD;
        // let endMonth = end.getMonth();
        // if (endMonth < 10) endMonth = "0" + endMonth;
        // return end.getFullYear() + "-" + endMonth + "-" + endD;
      } catch (error) {}
      return null;
    },
    strDates() {
      return (
        (this.strStartDate ? this.strStartDate : "_") +
        " " +
        this.$t("label_to").toLowerCase() +
        " " +
        (this.strEndDate ? this.strEndDate : "_")
      );
    },
    maxIncidenceAngle() {
      if (this.propEntity.feasibility.type === "standard") {
        return this.propEntity.feasibility.maxIncidenceAngle;
      } else if (this.propEntity.feasibility.type === "custom") {
        return this.propEntity.feasibility.customMaxIncidenceAngle;
      }
      return null;
    },
    maxCloudCover() {
      if (this.propEntity.feasibility.type === "standard") {
        return this.propEntity.feasibility.maxCloudCover;
      } else if (this.propEntity.feasibility.type === "custom") {
        return this.propEntity.feasibility.customMaxCloudCover;
      }
      return null;
    },
    notificationThreshold() {
      if (this.propEntity.feasibility.type === "custom") {
        return this.propEntity.feasibility.customNotificationThreshold;
      }
      return null;
    },
    classification() {
      if (
        this.propFeasibilityOneAtlas &&
        this.propFeasibilityOneAtlas.length > 0
      ) {
        return this.propFeasibilityOneAtlas[0].progTypes[0].feasibility
          .classification
          ? this.propFeasibilityOneAtlas[0].progTypes[0].feasibility
              .classification
          : null;
      }
      return null;
    },
    isManual() {
      if (
        this.propFeasibilityOneAtlas &&
        this.propFeasibilityOneAtlas.length > 0
      ) {
        return (
          (this.propFeasibilityOneAtlas[0].progTypes[0].feasibility.automation
            ? this.propFeasibilityOneAtlas[0].progTypes[0].feasibility
                .automation
            : null) === "MANUAL"
        );
      }
      return false;
    },
    acquisitionMode() {
      if (this.propEntity.feasibility.type === "custom") {
        return this.propEntity.feasibility.customAcquisitionMode;
      }
      return "MONO";
    },
    minBH() {
      if (
        this.propEntity.feasibility.type === "custom" &&
        ["STEREO", "TRISTEREO"].includes(this.acquisitionMode)
      ) {
        return this.propEntity.feasibility.customMinBH;
      }
      return null;
    },
    maxBH() {
      if (
        this.propEntity.feasibility.type === "custom" &&
        ["STEREO", "TRISTEREO"].includes(this.acquisitionMode)
      ) {
        return this.propEntity.feasibility.customMaxBH;
      }
      return null;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.plaintext {
  font-family: "Helvetica Neue Light";
}

table {
  width: 100%;
  background-color: white;
  border-radius: 0.25rem;
  padding: 0.5rem;
  tbody {
    tr {
      td {
        padding: 0.5rem 0.75rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.075);
        &:first-child {
          text-align: right;
          width: 11rem;
        }
        &:last-child {
          padding-left: 0;
        }
        &.plaintext {
          font-family: "Helvetica Neue Light";
        }
      }
    }
  }
}
</style>
