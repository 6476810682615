<template>
  <layout-default>
    <router-view />
  </layout-default>
</template>

<script>
import LayoutDefault from "@/components/pages/layouts/Default";
export default {
  name: "Layout",
  components: {
    LayoutDefault,
  },
};
</script>
