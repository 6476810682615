<template>
<div class="image-preview" :style="{height: h +'px'}">
  <div id="viewer-image"  :style="{height: h +'px'}" ref="image"/>
  </div>
</template>
<script>
//https://codesandbox.io/s/vue-openseadragon-4ybif?from-embed
import OpenSeadragon from "openseadragon";
export default {
  name: "Viewer",
  props:{
    h:{
      type:Number,
      default:0
    },
    propData:{
      type:Object
    }
  },
  components:{},
  data() {
    return {
      viewer: null,
      source:null,
      show:true
    };
  },
  watch:{
    'propData.item.preview':{
      handler(val){
        if(val){
          var vm = this;
          if(this.source){
            vm.viewer.addTiledImage({
                tileSource: {
                  type: "image",
                  url:val
                },
                success: function () {
                  if(vm.viewer.world._items.length > 1)
                    vm.viewer.world.removeItem(vm.viewer.world.getItemAt(0));
                }
            });
          }
        }else{ 
          if(this.viewer.world._items.length > 0)
            this.viewer.world.removeItem(this.viewer.world.getItemAt(0));
        }
      },
      deep:true
    }
  },
  mounted() {
    this.initViewer();
  },
  methods: {
    initViewer() {
      this.source = [
        {
          type: "image",
          url:this.propData.item.preview
        }
      ];
      this.viewer = OpenSeadragon({
        id: "viewer-image",
        animationTime: 0.4,
        //prefixUrl: "//openseadragon.github.io/openseadragon/images/",
        showNavigator: false,
        showNavigationControl: false,
        sequenceMode: false,
        tileSources: this.source,
        preload:true
      });
    }
  }
};
</script>
<style lang="scss" scoped>
#viewer-image{
    width: 100%;
    background: rgba(2, 34, 89, 0.1);
}
.image-preview{
  position: relative;
}
</style>

