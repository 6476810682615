<template>
  <CommonAccordion :propTitle="$t('label_order_list')">
    <div slot="content">
      <div class="datatable">
        <ContextMenu :model="menuModel" ref="cmOrderDatatable" />
        <DataTable
          :scrollable="true"
          :value="propItems"
          ref="dt"
          :loading="propLoading"
          :resizableColumns="false"
          columnResizeMode="expand"
          selectionMode="single"
          dataKey="id"
          contextMenu
          :selection.sync="propTable.selected"
          @row-contextmenu="onRowContextMenu"
          :scrollHeight="
            propTable.height - (propItems.length ? 40 : 0) - 38 + 'px'
          "
          :rowClass="rowClass"
          :virtualScroll="true"
          @virtual-scroll="onVirtualScroll"
          ><template #header v-if="propItems.length">
            <div class="text-left">
              <div>
                <div v-if="propItems.length" class="font-weight-normal">
                  {{
                    $t("label_show_from_to", null, {
                      from: 1,
                      to: propItems.length,
                      total: 0,
                    })
                  }}
                </div>
              </div>
            </div>
          </template>
          <template #empty v-if="propItems && propItems.length === 0">
            <span class="text-center">{{ $t("label_no_data") }}</span>
          </template>
          <Column
            header="No"
            headerStyle="width: 3rem; text-align: center!important"
            bodyStyle="width: 3rem;text-align: center;padding:0"
          >
            <template #body="slotProps">
              <div>
                {{ slotProps.index + 1 }}
              </div>
            </template></Column
          >
          <Column :header="$t('label_order_name')" field="name">
            <template #body="slotProps">
              <a class="item-link" @click="go2OrderDetail(slotProps.data)">{{
                slotProps.data.name
              }}</a>
            </template></Column
          >
          <Column :header="$t('label_order_type')" field="order_type"> </Column>
          <Column :header="$t('label_order_created')" field="created_on">
          </Column>
          <Column
            :header="$t('label_status')"
            field="state"
            headerStyle="width: 7rem;"
            bodyStyle="width: 7rem;text-align: center;"
            ><template #body="slotProps">
              <div class="oval" :style="columnStyle(slotProps.data)">
                <span>{{ slotProps.data.status }}</span>
              </div>
            </template>
          </Column>
          <Column
            headerStyle="width: 6.5rem"
            bodyStyle="width: 6.5rem;overflow: visible"
            :headerClass="'p-frozen-column right'"
            :bodyClass="'p-frozen-column right'"
          >
            <template #body="slotProps">
              <Button
                :icon="
                  propButtonDetail &&
                  propButtonDetail.processing &&
                  slotProps.data.id === propButtonDetail.id
                    ? 'fas fa-spinner fa-spin'
                    : 'fas fa-eye'
                "
                type="button"
                class="p-button -button-sm p-button-info mr-1"
                :title="$t('button_text_detail')"
                @click="go2OrderDetail(slotProps.data)"
                :disabled="
                  propButtonDetail &&
                  propButtonDetail.disable &&
                  slotProps.data.id === propButtonDetail.id
                    ? propButtonDetail.disable
                    : false
                "
              >
              </Button>

              <Button
                v-if="slotProps.data.state && slotProps.data.state === 'queued'"
                icon="fas fa-trash"
                type="button"
                class="p-button-sm p-button-danger"
                :title="$t('button_text_cancel_order')"
                @click="shoDialogCancelOrder(slotProps.data)"
              >
              </Button>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion3";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import Button from "@/components/commons/button/Button";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
export default {
  components: { CommonAccordion, DataTable, Column, Button, ContextMenu },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propTable: {
      type: Object,
      default: () => {},
    },
    propLoading: {
      type: Boolean,
      default: false,
    },
    propButtonDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      menuModel: [
        {
          label: this.$t("button_text_detail"),
          icon: "fas fa-eye",
          command: () => this.go2OrderDetail(this.propTable.selected),
          visible: true,
        },
        {
          label: this.$t("button_text_cancel_order"),
          icon: "fas fa-trash",
          command: () => this.shoDialogCancelOrder(this.propTable.selected),
          visible: true,
        },
      ],
    };
  },

  methods: {
    onVirtualScroll(e) {
      this.$emit("nextPage");
    },
    go2OrderDetail(item) {
      this.propTable.selected = item;
      this.$router.push({
        name: "IntegratePlanetDetail",
        params: {
          id: item.id,
        },
      });
    },
    shoDialogCancelOrder(item) {
      this.propTable.selected = item;
      this.$emit("showDialogCancel", item);
    },
    onRowContextMenu(event) {
      this.propTable.selected = event.data;
      if (
        this.propTable.selected &&
        Object.keys(this.propTable.selected).length > 0
      ) {
        if (this.propTable.selected.state === "queued")
          this.menuModel[1].visible = true;
        else this.menuModel[1].visible = false;

        this.$refs.cmOrderDatatable.show(event.originalEvent);
      }
    },
    rowClass() {},

    columnStyle(val) {
      let result = "";
      switch (val.status) {
        case "running":
          result = "background:#0d6efd";
          break;
        case "queued":
          result = "background:#F68E1E";
          break;
        case "success":
          result = "background:#27AE60";
          break;
        case "cancelled":
          result = "background:#ffc107";
          break;
        case "failed":
          result = "background:#F1416C";
          break;
        case "partial":
          result = "background:#0dcaf0";
          break;
        default:
          result = "background:#9da5b1";
          break;
      }
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.datatable {
  .oval {
    height: 36px;
    margin: 0 auto;
    border-radius: 20px;
    text-align: center;
    span {
      text-align: center;
      vertical-align: middle;
      line-height: 36px;
      color: white;
      font-size: 12px;
    }
  }
  .item-link {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
<style lang="scss">
.integrate__datatable {
  @import "@/assets/scss/_datatable.scss";
}
</style>
