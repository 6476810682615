<template>
  <div class="tasking-production-parameters">
    <div
      v-if="
        propEntity.satellite_type &&
          propEntity.satellite_type.toLowerCase() === 'radar'
      "
    >
      <ProductionParametersRadar
        :propEntity="propEntity"
        :propProductLevels="propProductLevels"
        :propFileFormats="propFileFormatsRadar"
        :propDeliveriesMedia="propDeliveriesMedia"
        :propDeliveriesService="propDeliveriesService"
        :propLicenses="propLicenses"
      />
    </div>
    <div
      v-if="
        propEntity.satellite_type &&
          propEntity.satellite_type.toLowerCase() === 'optical'
      "
    >
      <ProductionParametersOptical
        :propEntity="propEntity"
        :propProducts="propProducts"
        :propBands="propBands"
        :propLicenses="propLicenses"
        :propBitDepths="propBitDepths"
        :propPixelResolutions="propPixelResolutions"
        :propDRAContrasts="propDRAContrasts"
        :propResamplingKernels="propResamplingKernels"
        :propOrthoOptions="propOrthoOptions"
        :propFileFormats="propFileFormats"
        :propTilings="propTilings"
        :propDeliveriesMedia="propDeliveriesMedia"
      />
    </div>
  </div>
</template>
<script>
import Dropdown from "@/components/commons/dropdown/Dropdown";
import Textarea from "@/components/commons/textarea/Textarea";
import ProductionParametersRadar from "./productionparameters/ProductionParametersRadar";
import ProductionParametersOptical from "./productionparameters/ProductionParametersOptical";
export default {
  components: {
    Dropdown,
    Textarea,
    ProductionParametersRadar,
    ProductionParametersOptical,
  },
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },
    propProducts: {
      type: Array,
      default: () => [],
    },
    propBands: {
      type: Array,
      default: () => [],
    },
    propLicenses: {
      type: Array,
      default: () => [],
    },
    propBitDepths: {
      type: Array,
      default: () => [],
    },
    propPixelResolutions: {
      type: Array,
      default: () => [],
    },
    propDRAContrasts: {
      type: Array,
      default: () => [],
    },
    propResamplingKernels: {
      type: Array,
      default: () => [],
    },
    propOrthoOptions: {
      type: Array,
      default: () => [],
    },
    propFileFormats: {
      type: Array,
      default: () => [],
    },

    propTilings: {
      type: Array,
      default: () => [],
    },

    propDeliveriesMedia: {
      type: Array,
      default: () => [],
    },
    propFileFormatsRadar: {
      type: Array,
      default: () => [],
    },

    propProductLevels: {
      type: Array,
      default: () => [],
    },
    propDeliveriesService: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  created() {
    if (!this.propProducts || this.propProducts.length === 0) {
      this.$emit("getAllTaskingProduct");
    }
    if (!this.propBands || this.propBands.length === 0) {
      this.$emit("getAllTaskingBands");
    }
    if (!this.propLicenses || this.propLicenses.length === 0) {
      this.$emit("getAllTaskingLicense");
    }
    if (!this.propBitDepths || this.propBitDepths.length === 0) {
      this.$emit("getAllTaskingBitDepth");
    }
    if (!this.propPixelResolutions || this.propPixelResolutions.length === 0) {
      this.$emit("getAllTaskingPixelResolution");
    }
    if (!this.propDRAContrasts || this.propDRAContrasts.length === 0) {
      this.$emit("getAllTaskingDRAContrast");
    }
    if (
      !this.propResamplingKernels ||
      this.propResamplingKernels.length === 0
    ) {
      this.$emit("getAllTaskingResamplingKernel");
    }
    if (!this.propOrthoOptions || this.propOrthoOptions.length === 0) {
      this.$emit("getAllTaskingOrthoOption");
    }
    if (!this.propFileFormats || this.propFileFormats.length === 0) {
      this.$emit("getAllTaskingFileFormat");
    }
    if (!this.propTilings || this.propTilings.length === 0) {
      this.$emit("getAllTaskingTiling");
    }
    if (!this.propDeliveriesMedia || this.propDeliveriesMedia.length === 0) {
      this.$emit("getAllTaskingDeliveryMedia");
    }
    if (!this.propFileFormatsRadar || this.propFileFormatsRadar.length === 0) {
      this.$emit("getAllTaskingFileFormatsRadar");
    }

    if (!this.propProductLevels || this.propProductLevels.length === 0) {
      this.$emit("getAllTaskingProductLevel");
    }
    if (
      !this.propDeliveriesService ||
      this.propDeliveriesService.length === 0
    ) {
      this.$emit("getAllTaskingDeliveryService");
    }
  },
};
</script>
<style lang="scss" scoped></style>
