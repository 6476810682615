<template>
  <div class="preview-icon mr-2 mt-2 border">
    <div
      class="spinner-border spinner-border-sm align-items-center mt-4"
      role="status"
      v-if="loading"
    >
      <span class="sr-only">{{ $t("label_loading") }}</span>
    </div>
    <div v-else>
      <img
        v-if="!fullPath"
        :src="'/img/icons/no-image.png'"
        class="preview-image no-image"
      />
      <img
        :src="fullPath"
        class="preview-image"
        @click.stop="showPreviewImage"
        v-else
        onerror="this.src='/img/icons/no-image.png'"
        @load="loadedImage"
      />
    </div>
  </div>
</template>
<script>
import capellaFunc from "@/utils/functions/capella";
export default {
  props: {
    propAssets: {
      type: Object,
      default: () => {},
    },
    propId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      fullPath: null,
    };
  },
  created() {
    this.fullPath =
      this.propAssets &&
      Object.keys(this.propAssets).length &&
      this.propAssets.thumbnail &&
      Object.keys(this.propAssets.thumbnail).length
        ? this.propAssets.thumbnail.href
        : null;
    if (this.fullPath) {
      this.$emit("initSuccessIconImage", {
        id: this.propId,
        val: this.fullPath,
        //blob_link: this.fullPath,
      });
      this.getImage();
    }
  },
  mounted() {},
  watch: {},
  methods: {
    loadedImage(e) {},
    async getImage() {
      try {
        let response = await capellaFunc.getImageThumb(this.fullPath);
        if (response.status === 200) {
          let contentType =
            response.headers && response.headers["content-type"]
              ? response.headers["content-type"]
              : "image/png";
          let blob = new Blob([response.data], {
            type: contentType,
          });
          let url;
          let arrSplit = contentType.split("/");
          if (blob.size != 0) {
            if (!window.navigator.msSaveOrOpenBlob) {
              // BLOB NAVIGATOR
              url = window.URL.createObjectURL(blob);
            } else {
              // BLOB FOR EXPLORER 11
              url = window.navigator.msSaveOrOpenBlob(
                blob,
                this.propId + "." + arrSplit[arrSplit.length - 1]
                  ? arrSplit[arrSplit.length - 1]
                  : "png"
              );
            }
            this.$emit("initSuccessIconImage", {
              id: this.propId,
              blob_link: url,
            });
          }
        }
      } catch (error) {}
    },
    showPreviewImage() {
      this.$emit("showPreviewImage");
    },
  },
};
</script>
<style lang="scss" scoped>
.preview-icon {
  text-align: center;
  width: 66px;
  height: 66px;
  background-color: #eceff1;
  border-radius: 0.25rem;
  img {
    object-fit: cover;
    width: 64px;
    height: 64px;
    border-radius: 0.25rem;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
