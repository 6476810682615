var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{style:({ zIndex: 1050 }),attrs:{"header":_vm.$t('header_detail'),"visible":_vm.show,"maximizable":false,"modal":true,"contentStyle":{
    width: _vm.width + 'px',
  }},on:{"update:visible":function($event){_vm.show=$event},"hide":_vm.onClose}},[_c('table',{staticClass:"table table-metadata",attrs:{"cellspacing":"0","cellpadding":"0"}},[_c('tbody',[_c('tr',[_c('td',{staticStyle:{"width":"12rem"}},[_vm._v("Type Name")]),_c('td',[_c('span',{staticClass:"type-name"},[_vm._v(_vm._s(_vm.propItem.icr.progTypeName))])])]),_c('tr',[_c('td',[_vm._v("Customer Reference")]),_c('td',[_vm._v(_vm._s(_vm.propItem.icr.customerRef))])]),_c('tr',[_c('td',[_vm._v("Id")]),_c('td',[_vm._v(_vm._s(_vm.propItem.icr.id))])]),_c('tr',[_c('td',[_vm._v("Sensor")]),_c('td',[_vm._v(_vm._s(_vm.propItem.icr.mission))])]),_c('tr',[_c('td',[_vm._v("Program")]),_c('td',[_vm._v(_vm._s(_vm.propItem.icr.program))])]),_c('tr',[_c('td',[_vm._v("Max Incidence Angle")]),_c('td',[_vm._v(_vm._s(_vm.propItem.icr.maxIncidenceAngle))])]),_c('tr',[_c('td',[_vm._v("Max Cloud Cover")]),_c('td',[_vm._v(_vm._s(_vm.propItem.icr.maxCloudCover))])]),_c('tr',[_c('td',[_vm._v("Status")]),_c('td',[_vm._v(_vm._s(_vm.propItem.icr.status))])]),_c('tr',[_c('td',[_vm._v("Ordered Attempt")]),_c('td',[_vm._v(_vm._s(_vm.propItem.icr.status))])]),_c('tr',[_c('td',[_vm._v("Downloading Configuration")]),_c('td',[(
              _vm.propItem.icr.downloadingConfigurations &&
              _vm.propItem.icr.downloadingConfigurations.length
            )?_c('div',[_c('ul',{staticClass:"list-none m-0 p-0"},_vm._l((_vm.propItem.icr.downloadingConfigurations),function(d,index){return _c('li',{key:index + 'd'},[_vm._v(" "+_vm._s(d.receivingStation)+" "+_vm._s(d.processingStation)+" ")])}),0)]):_vm._e()])]),_c('tr',[_c('td',[_vm._v("Notifications")]),_c('td',[(
              _vm.propItem.icr.notifications && _vm.propItem.icr.notifications.length
            )?_c('div',[_c('ul',{staticClass:"list-none m-0 p-0"},_vm._l((_vm.propItem.icr.notifications),function(n,index){return _c('li',{key:index + 'n'},[(n.channels && n.channels.length)?_c('div',_vm._l((n.channels),function(c,i){return _c('div',{key:i + 'c'},[(c.addresses && c.addresses.length)?_c('span',[_vm._v(" "+_vm._s(c.addresses.join(","))+" ")]):_vm._e()])}),0):_vm._e()])}),0)]):_vm._e()])]),_c('tr',[_c('td',[_vm._v("Sale Order (SAL)")]),_c('td',[_vm._v(_vm._s(_vm.propItem.sal))])]),_c('tr',[_c('td',[_vm._v("Sale Order (SO)")]),_c('td',[_vm._v(_vm._s(_vm.propItem.so))])]),_c('tr',[_c('td',[_vm._v("Order creator")]),_c('td',[_vm._v(_vm._s(_vm.propItem.icr.orderCreator))])]),_c('tr',[_c('td',[_vm._v("Creation Date")]),_c('td',[_vm._v(_vm._s(_vm.propItem.icr.creationDate))])]),_c('tr',[_c('td',[_vm._v("Geometric Processing")]),_c('td',[_vm._v(_vm._s(_vm.propItem.icr.productionParameters.geometricProcessing))])]),_c('tr',[_c('td',[_vm._v("Projection Code")]),_c('td',[_vm._v(_vm._s(_vm.propItem.icr.productionParameters.projectionCode))])]),_c('tr',[_c('td',[_vm._v("Spectral Bands Combinations")]),_c('td',[_vm._v(" "+_vm._s(_vm.propItem.icr.productionParameters.spectralBandsCombination)+" ")])]),_c('tr',[_c('td',[_vm._v("Product Format")]),_c('td',[_vm._v(_vm._s(_vm.propItem.icr.productionParameters.productFormat))])]),_c('tr',[_c('td',[_vm._v("Pixel Coding")]),_c('td',[_vm._v(_vm._s(_vm.propItem.icr.productionParameters.radiometricProcessing))])]),_c('tr',[_c('td',[_vm._v("Radiometric Processing")]),_c('td',[_vm._v(_vm._s(_vm.propItem.icr.productionParameters.pixelCoding))])]),_c('tr',[_c('td',[_vm._v("Elevation")]),_c('td',[_vm._v(_vm._s(_vm.propItem.icr.productionParameters.elevation))])]),_c('tr',[_c('td',[_vm._v("Priority")]),_c('td',[_vm._v(_vm._s(_vm.propItem.icr.productionParameters.priority))])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }