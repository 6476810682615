<template>
  <div class="oneatlas-components">
    <ProductTypes
      v-if="step === 1"
      :prop-content-height="propContentHeight"
      :prop-product-types="propProductTypes"
      :prop-entity="propEntity"
      @getProductTypes="getProductTypes"
      @selectedProductType="selectedProductType"
    />
    <div v-if="step === 2">
      <Attempts
        v-if="['oneday', 'onenow'].includes(target)"
        :prop-feature="propFeature"
        :prop-content-height="propContentHeight"
        :prop-attempts="propAttempts"
        :prop-product-type-selected="propProductTypeSelected"
        :prop-entity="propEntity"
        @getAttempts="getAttempts"
        @selectedAttempt="selectedAttempt"
        @modifyPolygon="modifyPolygon"
      />
      <Feasibility
        v-else-if="['oneplan'].includes(target)"
        :prop-feature="propFeature"
        :prop-content-height="propContentHeight"
        :prop-product-type-selected="propProductTypeSelected"
        :propSensors="sensors"
        :prop-feasibility="feasibility"
      ></Feasibility>
      <FeasibilityOneSeries
        v-else-if="['oneseries'].includes(target)"
        :prop-feature="propFeature"
        :prop-content-height="propContentHeight"
        :prop-product-type-selected="propProductTypeSelected"
        :propSensors="sensors"
        :prop-feasibility="feasibilityOneSeries"
        @message="onMessage"
      ></FeasibilityOneSeries>
    </div>
    <CheckOut
      ref="checkout"
      v-if="step === 3"
      :prop-content-height="propContentHeight"
      :prop-product-options="propProductOptions"
      :prop-price="propPrice"
      :prop-delivery-types="propDeliveryTypes"
      :prop-markets="propMarkets"
      :prop-contract="propContract"
      :prop-product-type-selected="propProductTypeSelected"
      :propFeasibilityOneAtlas="propFeasibilityOneAtlas"
      :prop-entity="propEntity"
      @onPrev="onPrev(true)"
      @getProductOptions="getProductOptions"
      @getDeliveryTypes="getDeliveryTypes"
      @getPrice="getPrice"
      @getMarkets="getMarkets"
      @message="onMessage"
      @createOrder="createOrder"
      @changeLabelNext="changeLabelNext"
    />
  </div>
</template>
<script>
import ProductTypes from "./oneatlas/ProductTypes.vue";
import Attempts from "./oneatlas/Attempts.vue";
import Feasibility from "./oneatlas/Feasibility.vue";
import FeasibilityOneSeries from "./oneatlas/FeasibilityOneSeries.vue";
import CheckOut from "./oneatlas/CheckOut.vue";
export default {
  components: {
    ProductTypes,
    Attempts,
    CheckOut,
    Feasibility,
    FeasibilityOneSeries,
  },
  props: {
    propContentHeight: {
      type: Number,
    },
    propFeature: {
      type: Object,
      default: () => {},
    },
    propProductTypes: {
      type: Object,
      default: () => {},
    },
    propAttempts: {
      type: Array,
      default: () => [],
    },
    propProductOptions: {
      type: Array,
      default: () => [],
    },
    propPrice: {
      type: Object,
      default: () => {},
    },
    propDeliveryTypes: {
      type: Array,
      default: () => [],
    },
    propMarkets: {
      type: Array,
      default: () => [],
    },
    propContract: {
      type: Object,
      default: () => {},
    },
    propProductTypeSelected: {
      type: Object,
      default: () => {},
    },
    propEntity: {
      type: Object,
      default: () => {},
    },
    // Response from api
    propFeasibilityOneAtlas: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      step: 1,
      feasibilityOneSeries: {
        sensor: null,
        temporalMode: "MULTIPERIOD",
        startDate: null,
        endDate: null,
        dateRange: [],
        numberOfObservations: 2,
        periodOfTime: 7,
        maxIncidenceAngle: 30,
        maxCloudCover: 10,
        notificationThreshold: 10,
        acquisitionMode: "MONO",
        minBH: 0.4,
        maxBH: 0.7,
      },
      feasibility: {
        type: "standard",
        sensor: null,
        startDate: null,
        endDate: null,
        maxIncidenceAngle: 30,
        maxCloudCover: 10,
        comments: null,
        customMaxIncidenceAngle: 30,
        customMaxCloudCover: 10,
        customNotificationThreshold: 10,
        customAcquisitionMode: "MONO",
        customMinBH: 0.4,
        customMaxBH: 0.7,
      },
    };
  },
  created() {
    let today = new Date();
    if (!this.feasibility.startDate) {
      this.feasibility.startDate = today.getTime();
    }
    if (!this.feasibility.endDate) {
      try {
        if (this.feasibility.startDate) {
          let dateStart = new Date(this.feasibility.startDate);
          this.feasibility.endDate = new Date(
            dateStart.setDate(dateStart.getDate() + 60)
          ).getTime();
        } else {
          this.feasibility.endDate = new Date(
            today.setDate(today.getDate() + 60)
          )
            .setHours(23)
            .setMinutes(59)
            .setSeconds(59)
            .getTime();
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.feasibility.sensor = this.selectedSensor;
    }, 1500);
  },
  methods: {
    onMessage(msg) {
      this.$emit("message", msg);
    },
    modifyPolygon() {
      this.$emit("modifyPolygon");
    },
    async onNext() {
      if (this.step === 3) {
        if (this.$refs.checkout) this.$refs.checkout.onNext();
      } else if (this.step === 1) {
        if (!this.propEntity.productType) {
          this.$emit("message", ["Please select product type"]);
          return;
        }
        if (this.propEntity.productTypeOld != this.propEntity.productType) {
          this.$emit("clearPrice");
          this.getProductOptions();
          if (["oneday", "onenow"].includes(this.target))
            await this.getAttempts(
              this.propEntity.startDate
                ? new Date(this.propEntity.startDate)
                : new Date()
            );
          this.propEntity.productTypeOld = this.propEntity.productType;
        } else if (
          this.propEntity.productTypeOld === this.propEntity.productType &&
          this.propEntity.productType != null
        ) {
          if (!this.propAttempts || this.propAttempts.length === 0) {
            await this.getAttempts(
              this.propEntity.startDate
                ? new Date(this.propEntity.startDate)
                : new Date()
            );
          }
        }
        this.step++;
      } else if (this.step === 2) {
        if (["oneday", "onenow"].includes(this.target)) {
          if (this.propEntity.attempts.length === 0) {
            this.$emit("message", ["Please select a product"]);
            return;
          }

          if (
            this.propEntity.segmentIdsOld.filter(
              (x) => !this.propEntity.segmentIds.includes(x)
            ).length > 0 ||
            this.propEntity.segmentIds.filter(
              (x) => !this.propEntity.segmentIdsOld.includes(x)
            ).length > 0
          ) {
            this.getPrice();
            this.propEntity.segmentIdsOld = this.propEntity.segmentIds;
          }
          this.step++;
        } else if (["oneplan"].includes(this.target)) {
          // validate form
          let msg = [];
          if (!this.feasibility.startDate) {
            msg.push("The start date field is required");
          }
          if (!this.feasibility.endDate) {
            msg.push("The end date field is required");
          }
          if (this.feasibility.startDate && this.feasibility.endDate) {
            if (this.feasibility.startDate - this.feasibility.endDate > 0) {
              msg.push("The start date must not be greater than end date");
            }
          }
          if (
            this.feasibility.maxIncidenceAngle === null ||
            this.feasibility.maxIncidenceAngle === undefined
          ) {
            msg.push("The max incidence angle field is required");
          } else {
            if (this.feasibility.maxIncidenceAngle < 0) {
              msg.push("The max incidence angle must not be less than 0");
            } else if (this.feasibility.maxIncidenceAngle > 50) {
              msg.push("The max incidence angle must not be greater than 50");
            }
          }
          if (
            this.feasibility.maxCloudCover === null ||
            this.feasibility.maxCloudCover === undefined
          ) {
            msg.push("The max cloud cover field is required");
          } else {
            if (this.feasibility.maxIncidenceAngle < 0) {
              msg.push("The max cloud cover must not be less than 0");
            } else if (this.feasibility.maxIncidenceAngle > 100) {
              msg.push("The max cloud cover must not be greater than 100");
            }
          }
          if (
            ["STEREO", "TRISTEREO"].includes(
              this.feasibility.customAcquisitionMode
            ) &&
            this.feasibility.type === "custom"
          ) {
            if (
              this.feasibility.customMinBH === null ||
              this.feasibility.customMinBH === undefined ||
              !this.feasibility.customMinBH
            )
              msg.push("The min B/H field is required");
            else {
              if (
                this.feasibility.customMinBH < 0.2 ||
                this.feasibility.customMinBH > 0.8
              )
                msg.push("The min B/H must be from 0.2 to 0.8");
            }
            if (
              this.feasibility.customMaxBH === null ||
              this.feasibility.customMaxBH === undefined ||
              !this.feasibility.customMaxBH
            )
              msg.push("The max B/H field is required");
            else {
              if (
                this.feasibility.customMaxBH < 0.2 ||
                this.feasibility.customMaxBH > 0.8
              )
                msg.push("The max B/H must be from 0.2 to 0.8");
            }
          }
          if (msg && msg.length > 0) {
            this.$emit("message", msg);
            return;
          }
          await this.checkFeasibility(this.feasibility);
          this.step++;
        } else if (["oneseries"].includes(this.target)) {
          let msg = [];
          if (this.feasibilityOneSeries.temporalMode === "MULTIPERIOD") {
            if (this.feasibilityOneSeries.dateRange.length < 2) {
              msg.push("Date Range mimimum of 2 periods");
            }
          } else if (this.feasibilityOneSeries.temporalMode === "REGULAR") {
            if (!this.feasibilityOneSeries.startDate)
              msg.push("Start Date is required");
            if (!this.feasibilityOneSeries.endDate)
              msg.push("End Date is required");
            if (!this.feasibilityOneSeries.numberOfObservations)
              msg.push("Number of observations is required");
            if (!this.feasibilityOneSeries.periodOfTime)
              msg.push("Period of time is required");
          }
          if (!this.feasibilityOneSeries.notificationThreshold)
            msg.push("Acquisition Notification Threshold is required");
          if (!this.feasibilityOneSeries.maxIncidenceAngle)
            msg.push("Max Incidence angle is required");
          if (!this.feasibilityOneSeries.maxCloudCover)
            msg.push("Max Cloud Cover is required");
          if (
            ["STEREO", "TRISTEREO"].includes(
              this.feasibilityOneSeries.acquisitionMode
            )
          ) {
            if (
              this.feasibilityOneSeries.minBH === null ||
              this.feasibilityOneSeries.minBH === undefined ||
              !this.feasibilityOneSeries.minBH
            )
              msg.push("The min B/H field is required");
            else {
              if (
                this.feasibilityOneSeries.minBH < 0.2 ||
                this.feasibilityOneSeries.minBH > 0.8
              )
                msg.push("The min B/H must be from 0.2 to 0.8");
            }
            if (
              this.feasibilityOneSeries.maxBH === null ||
              this.feasibilityOneSeries.maxBH === undefined ||
              !this.feasibilityOneSeries.maxBH
            )
              msg.push("The max B/H field is required");
            else {
              if (
                this.feasibilityOneSeries.maxBH < 0.2 ||
                this.feasibilityOneSeries.maxBH > 0.8
              )
                msg.push("The max B/H must be from 0.2 to 0.8");
            }
          }
          if (msg && msg.length > 0) {
            this.$emit("message", msg);
            return;
          }
          await this.checkFeasibility(this.feasibilityOneSeries);
          this.step++;
        }
      } else this.step++;
    },
    onPrev(forback = false) {
      if (this.step === 3 && !forback) {
        if (this.$refs.checkout) this.$refs.checkout.onPrev();
        return;
      }
      if (this.step === 1) {
        this.$emit("onPrev");
        return;
      }
      this.step--;
    },
    selectedAttempt(data) {
      this.$emit("selectedAttempt", data);
    },
    selectedProductType(data) {
      this.$emit("selectedProductType", data);
    },
    getProductTypes() {
      this.$emit("getProductTypes");
    },
    getAttempts(startDate) {
      this.$emit("getAttempts", startDate);
    },
    getProductOptions() {
      this.$emit("getProductOptions");
    },
    getDeliveryTypes() {
      this.$emit("getDeliveryTypes");
    },
    getPrice() {
      this.$emit("getPrice");
    },
    getMarkets() {
      this.$emit("getMarkets");
    },
    checkFeasibility(data) {
      this.$emit("checkFeasibility", data);
    },
    createOrder() {
      this.$emit("createOrder");
    },
    changeLabelNext(txt) {
      this.$emit("changeLabelNext", txt);
    },
  },
  computed: {
    target() {
      if (
        this.propProductTypeSelected &&
        Object.keys(this.propProductTypeSelected).length > 0
      ) {
        return this.propProductTypeSelected.technicalProduct.target.id;
      }
      return null;
    },
    selectedSensor() {
      if (
        this.propProductTypeSelected &&
        Object.keys(this.propProductTypeSelected).length > 0
      ) {
        return this.propProductTypeSelected.technicalProduct.sensor.id;
      }
      return null;
    },
    sensors() {
      let results = [];
      results = [
        ...new Set(
          this.propProductTypes.items.map((x) => x.technicalProduct.sensor)
        ),
      ];
      for (let i = 0; i < results.length; i++) {
        if (i > 0 && i < results.length - 1) {
          let index = results.findIndex((x) => x.id === results[i].id);
          if (index >= 0) {
            results.splice(index, 1);
            i = i - 1;
          }
        }
      }
      return results;
    },
  },
};
</script>
<style lang="scss" scoped></style>
