import { render, staticRenderFns } from "./Coordinates.vue?vue&type=template&id=6b2a4e70&scoped=true&"
import script from "./Coordinates.vue?vue&type=script&lang=js&"
export * from "./Coordinates.vue?vue&type=script&lang=js&"
import style0 from "./Coordinates.vue?vue&type=style&index=0&id=6b2a4e70&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b2a4e70",
  null
  
)

export default component.exports