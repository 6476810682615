<template>
  <div class="tasking-checkout">
    <div class="checkout-top">
      <div class="steps">
        <StepProgress
          :steps="steps"
          :current-step="step - 1"
          :activeColor="'#22c55e'"
          :activeTextColor="'#16a34a'"
          :passiveColor="'#B3C3D4'"
          :iconClass="'fas fa-check'"
        />
      </div>
      <h4 class="label-checkout">Checkout</h4>
      <div class="total-cost">
        <h6>
          Total cost:&nbsp;
          <i class="fas fa-spinner fa-spin" v-if="propPrice.loading"></i
          ><span v-else
            ><span
              class="listed-price"
              v-if="propPrice.discountValue > 0"
              v-html="
                getCurrencyCode(propPrice.currency) + propPrice.discountValue
              "
            ></span
            ><strong>
              <em v-html="getCurrencyCode(propPrice.currency)"></em>
              {{ price2Pay | numberFormat }}</strong
            ></span
          >
        </h6>
        <button
          class="btn btn-proceed"
          @click="nextStep"
          :disabled="disableActions"
        >
          <i class="fas fa-check"></i>&nbsp;{{
            step === 3 ? "Create Order" : "Proceed"
          }}
        </button>
      </div>
    </div>
    <div
      :style="{ minHeight: propContentHeight - 237 + 'px', overflowY: 'auto' }"
    >
      <Options
        v-if="step === 1"
        :prop-delivery-types="propDeliveryTypes"
        :prop-product-options="propProductOptions"
        :prop-product-type-selected="propProductTypeSelected"
        :prop-price="propPrice"
        :prop-entity="propEntity"
        :propFeasibilityOneAtlas="propFeasibilityOneAtlas"
        @getPrice="getPrice"
      />
      <DeliveryPayment
        v-else-if="step === 2"
        :prop-entity="propEntity"
        :prop-markets="propMarkets"
        :prop-contract="propContract"
        @getMarkets="getMarkets"
      />
      <Confirmation
        v-else-if="step === 3"
        :prop-delivery-types="propDeliveryTypes"
        :prop-contract="propContract"
        :prop-markets="propMarkets"
        :prop-product-options="propProductOptions"
        :prop-product-type-selected="propProductTypeSelected"
        :prop-price="propPrice"
        :prop-entity="propEntity"
        :propFeasibilityOneAtlas="propFeasibilityOneAtlas"
      />
    </div>
  </div>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion";
import StepProgress from "@/components/commons/steps/StepProgress";
import Options from "./checkout/Options.vue";
import DeliveryPayment from "./checkout/DeliveryPayment.vue";
import Confirmation from "./checkout/Confirmation.vue";
export default {
  components: {
    CommonAccordion,
    StepProgress,
    Options,
    DeliveryPayment,
    Confirmation,
  },
  props: {
    propContentHeight: {
      type: Number,
    },
    propProductOptions: {
      type: Array,
      default: () => [],
    },
    propPrice: {
      type: Object,
      default: () => {},
    },
    propDeliveryTypes: {
      type: Array,
      default: () => [],
    },
    propMarkets: {
      type: Array,
      default: () => [],
    },
    propContract: {
      type: Object,
      default: () => {},
    },
    propProductTypeSelected: {
      type: Object,
      default: () => {},
    },
    propEntity: {
      type: Object,
      default: () => {},
    },
    // Response from api
    propFeasibilityOneAtlas: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      step: 1,
      steps: ["Options", "Delivery & Payment", "Confirmation"],
      accordion: {
        active: true,
      },
      disableActions: false,
    };
  },
  created() {
    if (!this.propDeliveryTypes || this.propDeliveryTypes.length === 0) {
      this.$emit("getDeliveryTypes");
    }
  },
  watch: {},
  methods: {
    nextStep() {
      if (this.disableActions) return;
      this.onNext();
    },
    onNext() {
      if (this.step >= 3) {
        let msg = [];
        if (!this.propEntity.acceptLicenses) {
          msg.push(
            "Please accept the license(s) of the product(s) selected for this order"
          );
        }
        if (!this.propEntity.acceptTerms) {
          msg.push(
            "Please accept the terms and conditions applicable to this order"
          );
        }
        if (msg.length > 0) {
          this.$emit("message", msg);
          return;
        }
        this.disableActions = true;
        this.$emit("createOrder");
        return;
      } else if (this.step === 2) {
        // Validate Form
        let msg = [];
        if (!this.propEntity.name || !this.propEntity.name.trim())
          msg.push("Please provide a name for this order");
        if (!this.propEntity.primaryMarket)
          msg.push("Please specify your market");
        if (msg.length > 0) {
          this.$emit("message", msg);
          return;
        }
        this.$emit("changeLabelNext", "Create Order");
      }
      this.step++;
    },
    onPrev() {
      if (this.step === 1) {
        this.$emit("onPrev");
        return;
      }
      this.disableActions = false;
      this.$emit("changeLabelNext", "Next");
      this.step--;
    },
    getMarkets() {
      this.$emit("getMarkets");
    },
    getPrice() {
      this.$emit("getPrice");
    },
    getCurrencyCode(txt) {
      let result = "&euro;";
      switch (txt) {
        case "EUR":
          result = "&euro;";
          break;
        case "DOLLAR":
          result = "&#36;";
          break;
        case "POUND":
          result = "&#163;";
          break;
        case "YEN":
          result = "&#165;";
          break;
        case "CENT":
          result = "&#162;";
          break;
        case "RUPEE":
          result = "&#8360;";
          break;
        case "DONG":
          result = "&#8363;";
          break;
        default:
          result = txt;
          break;
      }
      return result;
    },
  },
  computed: {
    totalAmount() {
      return this.propPrice &&
        Object.keys(this.propPrice).length &&
        this.propPrice.totalAmount
        ? this.propPrice.totalAmount
        : 0;
    },
    discountValue() {
      return this.propPrice &&
        Object.keys(this.propPrice).length &&
        this.propPrice.discountValue
        ? this.propPrice.discountValue
        : 0;
    },
    price2Pay() {
      return this.totalAmount - this.discountValue >= 0
        ? this.totalAmount - this.discountValue
        : 0;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.tasking-checkout {
  padding: 0 1rem;
  display: grid;
  // grid-template-rows: 6rem 1.75rem 5rem 6rem auto;
  gap: 1rem;
  font-size: 14px;
  .checkout-top {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
  .steps {
    height: 6rem;
  }
  h4 {
    margin: 0;
    &.label-checkout {
      color: #16a34a;
      margin-bottom: 1rem;
    }
  }
  .total-cost {
    background-color: #eef3f7;
    border: 1px solid rgba(0, 0, 0, 0.075);
    border-radius: 0.25rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem 0 1rem;
    h6 {
      margin: 0;
      color: #475569;
      .listed-price {
        text-decoration: line-through;
        color: #999;
        margin: 0 0.5rem;
      }
    }
    button {
      &.btn-proceed {
        color: white;
        width: 10rem;
        background-color: #22c55e;
        border: 1px solid #16a34a;
        font-size: 14px;
        i {
          font-size: 12px;
        }
      }
    }
  }
  .comment {
    background-color: #eef3f7;
    border: 1px solid rgba(0, 0, 0, 0.075);
    border-radius: 0.25rem;
    padding: 1rem 1.5rem 1rem 1rem;
    margin: auto 0;
    h6 {
      margin-bottom: 0.5rem;
    }
    p {
      margin-bottom: 0.5rem;
      font-family: "Helvetica Neue Light";
      font-weight: 550;
    }
  }
  .payment {
    border: 1px solid rgba(0, 0, 0, 0.075);
    border-radius: 0.25rem;
    padding: 1rem 1.5rem 1rem 1rem;
    margin: auto 0;
    .plaintext {
      line-height: 2rem;
    }
  }
  .notifications {
    border: 1px solid rgba(0, 0, 0, 0.075);
    border-radius: 0.25rem;
    padding: 1rem 1.5rem 1rem 1rem;
    margin: auto 0;
    .user-card {
      display: flex;
      gap: 1rem;
      flex-wrap: nowrap;
      align-self: center;
      justify-content: center;
      label {
        width: 96px;
        margin: 0;
        align-self: center;
        justify-content: center;
      }

      button {
        width: 128px;
        border: 1px solid;
        font-size: 14px;
        background-color: #64748b;
        border: 1px solid #475569;
        color: white;
        align-self: center;
        justify-items: center;
        i {
          font-size: 12px;
        }
      }
    }
  }
  .p-dropdown {
    border-color: rgba(0, 0, 0, 0.125);
    box-shadow: none;
    outline: none;
    .p-dropdown-trigger {
      color: rgba(0, 0, 0, 0.125) !important;
    }
  }
}
</style>
