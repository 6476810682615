import { Module } from "vuex";
//const cryptoFunc = require("@/utils/functions/crypto")
const SET_TOKEN = "SET_TOKEN";
const CLEAR_TOKEN = "CLEAR_TOKEN";
const SET_USER = "SET_USER";
const UPDATE_INFO = "UPDATE_INFO";
const SET_SENTINEL_DATA = "SET_SENTINEL_DATA";
const SET_ONEATLAS_DATA = "SET_ONEATLAS_DATA";
const SET_ONEATLAS_TOKEN = "SET_ONEATLAS_TOKEN";
const CLEAR_ONEATLAS_DATA = "CLEAR_ONEATLAS_DATA";
const CLEAR_ONEATLAS_TOKEN = "CLEAR_ONEATLAS_TOKEN";

const SET_CAPELLA_TOKEN = "SET_CAPELLA_TOKEN";
const CLEAR_CAPELLA_TOKEN = "CLEAR_CAPELLA_TOKEN";
const tokenModule = {
  state: {
    token: {},
    loggedIn: false,
    user: {},
    sentinelData: {},
    oneAtlasData: JSON.parse(localStorage.getItem("oneAtlasData")),
    oneAtlasToken: {},
    capellaToken: {},
  },
  mutations: {
    [SET_TOKEN](state, payload) {
      state.loggedIn = true;
      state.token = payload;
    },
    [CLEAR_TOKEN](state) {
      state.loggedIn = false;
      state.token = {};
      state.user = {};
    },
    [SET_USER](state, payload) {
      state.user = payload;
    },
    [SET_SENTINEL_DATA](state, payload) {
      state.sentinelData = payload;
    },
    [UPDATE_INFO](state, payload) {
      if (payload && Object.keys(payload).length > 0) {
        for (const [key, value] of Object.entries(payload)) {
          state.user[key] = value;
        }
      }
    },
    [SET_ONEATLAS_DATA](state, payload) {
      state.oneAtlasData = payload;
      localStorage.setItem("oneAtlasData", JSON.stringify(payload));
    },
    [SET_ONEATLAS_TOKEN](state, payload) {
      if (!payload.expires_time)
        payload.expires_time =
          new Date().getTime() +
          (payload.expires_in ? payload.expires_in * 1000 : 0);
      state.oneAtlasToken = payload;
    },

    [CLEAR_ONEATLAS_DATA](state) {
      state.oneAtlasData = {};
      localStorage.removeItem("oneAtlasData");
    },
    [CLEAR_ONEATLAS_TOKEN](state) {
      state.oneAtlasToken = {};
      localStorage.removeItem("oneatlas-token");
    },

    [CLEAR_CAPELLA_TOKEN](state) {
      state.capellaToken = {};
      localStorage.removeItem("capella-token");
    },
    [SET_CAPELLA_TOKEN](state, payload) {
      state.capellaToken = payload;
    },
  },
  actions: {
    setToken({ commit }, payload) {
      commit(SET_TOKEN, payload);
    },
    clearToken({ commit }) {
      localStorage.removeItem("data");
      commit(CLEAR_TOKEN);
    },
    setUser({ commit }, payload) {
      commit(SET_USER, payload);
    },
    setSentinelData({ commit }, payload) {
      commit(SET_SENTINEL_DATA, payload);
    },
    updateInfo({ commit }, payload) {
      commit(UPDATE_INFO, payload);
    },
    setOneAtlasData({ commit }, payload) {
      commit(SET_ONEATLAS_DATA, payload);
    },
    setOneAtlasToken({ commit }, payload) {
      commit(SET_ONEATLAS_TOKEN, payload);
    },

    clearOneAtlasData({ commit }) {
      commit(CLEAR_ONEATLAS_DATA);
    },
    clearOneAtlasToken({ commit }) {
      commit(CLEAR_ONEATLAS_TOKEN);
    },

    setCapellaToken({ commit }, payload) {
      commit(SET_CAPELLA_TOKEN, payload);
    },
    clearCapellaToken({ commit }) {
      commit(CLEAR_CAPELLA_TOKEN);
    },
  },
  getters: {
    getAccessToken(state) {
      let accessToken = "";
      if (Object.keys(state.token).length > 0) {
        accessToken = state.token.access_token ? state.token.access_token : "";
      }
      return accessToken;
    },
    getRefreshToken(state) {
      let refreshToken = "";
      if (Object.keys(state.token).length > 0) {
        refreshToken = state.token.refresh_token
          ? state.token.refresh_token
          : "";
      }
      return refreshToken;
    },
    getExpiresIn: (state) => {
      let result = 0;
      if (state.token && state.token.expires_in) {
        try {
          result = parseInt(state.token.expires_in);
        } catch (error) {}
      }
      return result;
    },
    loggedIn(state) {
      return state.loggedIn;
    },
    getUserName(state) {
      return state.user.username ? state.user.username : "";
    },
    getUserId(state) {
      return state.user.id ? state.user.id : "";
    },
    getUser(state) {
      return state.user ? state.user : {};
    },
    getRoles(state) {
      return state.user && state.user.roles ? state.user.roles : [];
    },
    getSentinelData(state) {
      return state.sentinelData ? state.sentinelData : {};
    },
    getOneAtlasData(state) {
      return state.oneAtlasData ? state.oneAtlasData : {};
    },

    getOneAtlasToken(state) {
      return state.oneAtlasToken && Object.keys(state.oneAtlasToken).length > 0
        ? state.oneAtlasToken.access_token
        : null;
    },
    getOneAtlasTokenType(state) {
      return state.oneAtlasToken && Object.keys(state.oneAtlasToken).length > 0
        ? state.oneAtlasToken.token_type
        : "Bearer";
    },
    getOneAtlasTokenExpiresIn(state) {
      return state.oneAtlasToken && Object.keys(state.oneAtlasToken).length > 0
        ? state.oneAtlasToken.expires_in
        : 0;
    },
    checkOneAtlasTokenExpired(state) {
      let result = true; // true = Expired
      let expires_time =
        state.oneAtlasToken && Object.keys(state.oneAtlasToken).length > 0
          ? state.oneAtlasToken.expires_time
          : 0;
      result = new Date().getTime() - expires_time > 0 ? true : false;
      return result;
    },
    getCapellaAccessToken(state) {
      return state.capellaToken && Object.keys(state.capellaToken).length > 0
        ? state.capellaToken.accessToken
        : null;
    },

    getCapellaTokenType(state) {
      return state.capellaToken && Object.keys(state.capellaToken).length > 0
        ? state.capellaToken.tokenType
        : "Bearer";
    },
    getCapellaTokenExpiresIn(state) {
      return state.capellaToken && Object.keys(state.capellaToken).length > 0
        ? state.capellaToken.expiresIn
        : 0;
    },
    checkCapellaTokenExpired(state) {
      let result = true; // true = Expired
      let expires_time =
        state.capellaToken && Object.keys(state.capellaToken).length > 0
          ? state.capellaToken.expires_time
          : 0;
      result = new Date().getTime() - expires_time > 0 ? true : false;
      return result;
    },
  },
};
export default tokenModule;
