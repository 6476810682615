<template>
  <form class="m-2">
    <div class="form-group">
      <label class="control-label">{{ $t("label_csv_file") }}</label>
      <div class="custom-file">
        <input
          type="file"
          accept=".csv"
          class="custom-file-input"
          @change="changeFile"
        />
        <label class="custom-file-label" for="customFile">{{
          propData.file ? propData.file.name : ""
        }}</label>
      </div>
    </div>
    <div class="form-group list-name">
      <label class="control-label w-100"
        >{{ $t("label_image_name") }}
        <button
          type="button"
          class="btn btn-danger btn-sm float-right"
          @click="removeAllImage"
        >
          <i class="fas fa-trash-alt"></i>
        </button>
      </label>
      <div class="row no-gutters image-name">
        <textarea
          class="form-control"
          rows="6"
          v-model="propData.text"
        ></textarea>
      </div>
    </div>
  </form>
</template>
<script>
export default {
  props: {
    propData: {
      type: Object,
      default: () => {
        return {
          file: null,
          imagesName: [],
          text: null,
        };
      },
    },
  },
  data() {
    return {
      imagesName: [],
    };
  },
  methods: {
    changeFile(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      else {
        let vm = this;
        vm.propData.file = files[0];
        var reader = new FileReader();
        reader.onload = async function () {
          vm.processData(reader.result);
        };
        reader.readAsText(files[0]);
      }
    },
    processData(allText) {
      // var csvData = new Array();
      var jsonObject = allText.split(/\r?\n|\r/);
      let array = [];
      if (jsonObject.length == 0) return;
      for (var i = 1; i < jsonObject.length; i++) {
        array = jsonObject[i].split(";");
        if (array.length > 0) {
          array[0] && this.imagesName.push(array[0]);
        }
      }
      this.propData.text = this.imagesName.join("\n");
    },
    removeAllImage() {
      this.propData.text = null;
      //this.$store.dispatch("removeObjectsSearchDto", ["image_name"]);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-group {
  .image-name {
    position: relative;
    margin-bottom: 0.5rem;
    .clear-text {
      position: absolute;
      top: 0.375rem;
      right: 0.75rem;
      &:hover svg {
        fill: #dc3545;
        cursor: pointer;
      }
      svg {
        width: 0.5rem;
        height: 0.5rem;
      }
    }
  }
}
textarea {
  font-size: 14px;
}
// .list-name{
//     max-height: 220px;
//     overflow-y: auto;
// }
</style>
