<template>
  <div class="geohub-tab">
    <div class="tab-left left-content">
      <div class="geohub-steps">
        <StepProgress
          :steps="steps"
          :current-step="step"
          :activeColor="'#009EF7'"
          :activeTextColor="'#1E1E2D'"
          :passiveColor="'#B3C3D4'"
          :iconClass="'fas fa-check'"
        />
      </div>
      <div
        class="geohub-components p-4"
        :style="{ height: contentHeight + 'px' }"
      >
        <div class="component-order-name">
          <div class="form-group" v-show="step === 0">
            <label for="txt-cart-description" class="control-label pl-1">
              Order name
            </label>
            <Textarea
              id="txt-cart-description"
              class="form-control"
              rows="2"
              maxlength="512"
              autofocus
              :autoResize="true"
              v-model="cart.name"
              @change="orderNameChanged"
            >
            </Textarea>
            <small class="w-100 text-right float-right mt-1"
              >{{
                cart.name && cart.name.trim() ? cart.name.trim().length : 0
              }}/512</small
            >
          </div>
        </div>
        <div class="component-confirm text-center" v-show="step === 1">
          <label class="control-label">Priority</label>
          <div class="form-row">
            <div class="col" v-for="item in propPriorityLevel" :key="item.id">
              <RadioButton
                :id="item.id"
                :name="item.id"
                :value="item.id"
                v-model="cart.priority_id"
              />
              <label class="m-0 ml-2" :for="item.id">{{ item.value }}</label>
            </div>
          </div>
        </div>
      </div>

      <div
        class="geohub-actions d-flex justify-content-between align-items-center px-4 py-2"
      >
        <Button
          v-if="step >= 1"
          @click="prevStep"
          :label="'Back'"
          :icon="'fas fa-arrow-left'"
          :class="'p-button-secondary p-button-text button-prev'"
        />
        <Button
          v-if="step < 1"
          @click="nextStep"
          :label="'Continue'"
          :class="'button-next h-9'"
        />
      </div>
    </div>

    <div class="tab-right">
      <h6 class="summary-title">Order Summary</h6>
      <div class="summary-order">
        <div class="form-group">
          <label class="underline">Order name</label>
          <h6
            class="summary-order-name"
            :class="cart.name ? 'font-weight-bold' : 'text-muted'"
            :title="cart.name ? (cart.name.length > 26 ? cart.name : '') : ''"
          >
            {{
              cart.name
                ? cart.name.length > 26
                  ? "- " + cart.name.substring(0, 26) + "..."
                  : "- " + cart.name
                : "--"
            }}
          </h6>
        </div>
        <div class="form-group">
          <label class="underline">Programming service</label>
          <p class="summary-order-name font-weight-bold m-0">
            - Priority: {{ priorityName }}
          </p>
        </div>
      </div>
      <div
        class="order-summary-list"
        :style="{ height: contentHeight - 80 + 'px' }"
      >
        <PlanetSummary :propImages="satellitesPlanet" />
        <OneAtlasSummary :propImages="satellitesOneAtlas" />
        <CapellaSummary :propImages="satellitesCapella" />
        <GeoHubSummary :propImages="satellitesGeoHub" />
      </div>
      <div class="summary-actions" v-if="step > 0 || cart.name">
        <Button
          label="Order"
          class="button-confirm h-9"
          @click="onOrderGeohub"
          :icon="
            propButton && propButton.processing ? 'fas fa-spinner fa-spin' : ''
          "
          :disabled="
            propButton && propButton.disable ? propButton.disable : false
          "
        >
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import StepProgress from "@/components/commons/steps/StepProgress";
import Textarea from "@/components/commons/textarea/Textarea";
import Button from "@/components/commons/button/Button";
import RadioButton from "@/components/commons/radiobutton/RadioButton";

import PlanetSummary from "./summary/Planet";
import OneAtlasSummary from "./summary/OneAtlas";
import CapellaSummary from "./summary/Capella";
import GeoHubSummary from "./summary/GeoHub";
export default {
  components: {
    StepProgress,
    Textarea,
    Button,
    RadioButton,
    PlanetSummary,
    OneAtlasSummary,
    CapellaSummary,
    GeoHubSummary,
  },
  props: {
    propCartName: {
      type: String,
      default: null,
    },
    propPriorityLevel: {
      type: Array,
      default: () => [],
    },
    propImages: {
      type: Array,
      default: () => [],
    },
    propButton: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      steps: ["Order name", "Programming service"],
      step: 0,
      cart: {
        name: null,
        address: null,
        description: null,
        note: null,
        email: null,
        full_name: null,
        image_ids: [],
        organization: null,
        phone_number: null,
        processing_level: null,
        priority_id: null,
        priority_name: null,
      },
      satellitesPlanet: [],
      satellitesOneAtlas: [],
      satellitesCapella: [],
      satellitesGeoHub: [],
    };
  },
  created() {
    this.satellitesPlanet = this.propImages.filter((x) => x.type == "PLANET");

    this.satellitesOneAtlas = this.propImages.filter(
      (x) => x.type == "ONEATLAS"
    );

    this.satellitesCapella = this.propImages.filter((x) => x.type == "CAPELLA");

    this.satellitesGeoHub = this.propImages.filter(
      (x) => x.type == "SATELLITE"
    );
  },
  mounted() {
    if (
      this.propPriorityLevel &&
      this.propPriorityLevel.length > 0 &&
      !this.cart.priority_id
    )
      this.cart.priority_id = this.propPriorityLevel[0].id;
  },
  watch: {
    propPriorityLevel(val) {
      if (
        this.propPriorityLevel &&
        this.propPriorityLevel.length > 0 &&
        !this.cart.priority_id
      )
        this.cart.priority_id = this.propPriorityLevel[0].id;
    },
    propCartName: {
      handler(val) {
        this.cart.name = val;
      },
      immediate: true,
    },
  },
  methods: {
    orderNameChanged() {
      this.$emit("orderNameChanged", this.cart.name);
    },
    nextStep() {
      this.step++;
    },
    prevStep() {
      this.step--;
    },

    clearCart() {
      this.cart.name = null;
      this.cart.description = null;
      this.cart.note = null;
      if (this.propPriorityLevel && this.propPriorityLevel.length > 0)
        this.cart.priority_id = this.propPriorityLevel[0].id;
      else this.cart.priority_id = null;
    },
    onOrderGeohub() {
      let message = [];
      if (!this.cart.name || !this.cart.name.trim()) {
        message.push("Order Name is required.");
      }
      if (message.length > 0) {
        this.$emit("message", message);
        return;
      }

      this.cart.note = this.cart.description = this.cart.name.trim();
      this.$emit("onOrderGeohub", this.cart);
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    contentHeight() {
      return (this.documentHeight * 50) / 100;
    },
    priorityName() {
      let item = this.propPriorityLevel.find(
        (x) => x.id == this.cart.priority_id
      );
      return item ? item.value : null;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.h-9 {
  height: 2.25rem; /* 36px */
}
.geohub-tab {
  display: grid;
  grid-template-columns: [first] auto [line2] 320px [end];
  .tab-left {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 100%;
    align-items: stretch;
    align-content: stretch;
    .geohub-steps {
      padding-left: 5rem;
      padding-right: 5rem;
      &.shadow-b {
        box-shadow: rgba(33, 35, 38, 0.2) 0px 10px 10px -10px;
      }
    }
    .geohub-components {
      overflow: auto;
      .component-select-assets {
        .assets-box {
          .border-t {
            border-top-width: 1px;
            border-top-style: solid;
            border-color: rgb(189, 197, 198);
            &:first-child {
              border-top: none;
            }
          }
          .title-box {
            font-size: 20px;
            font-weight: 700;
            &::after {
              content: "";
              display: block;
              width: 100%;
              margin-top: 0.25rem;
            }
          }
        }
      }
    }
    .geohub-actions {
      width: 100%;
      background-color: rgba($color: #000000, $alpha: 0.005);
      border-radius: 0.25rem;
      .button-prev {
        outline: none;
        box-shadow: none;
        &:focus {
          outline: none;
          box-shadow: none;
        }
        &:active {
          outline: none;
          box-shadow: none;
        }
      }
      .button-next {
        margin-left: auto;
        background-color: $background-color-2;
        border-color: $background-color-2;
      }
    }
  }
  .tab-right {
    background-color: rgba($color: $background-color-2, $alpha: 0.05);
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    .summary-title {
      font-size: 16px;
      font-weight: 700;
      padding: 0.5rem 1rem;
      border-bottom: 1px solid rgba(189, 197, 198, 0.5);
    }
    .summary-order {
      padding: 0.5rem 1rem 1rem;
      border-bottom: 1px solid rgba(189, 197, 198, 0.5);
      display: block;
      .form-group {
        margin-bottom: 0.5rem;
        h6 {
          margin: 0;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
    .order-summary-list {
      margin: 0;
      padding: 0;
      overflow: auto;
      height: 100%;
    }
    .summary-actions {
      padding: 0.5rem;
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      box-shadow: none;
      display: flex;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;
      flex-shrink: 0;
      position: static;
      background-color: transparent;
      color: inherit;
      background-image: unset;
      border-top: 1px solid rgba(189, 197, 198, 0.5);
      .button-confirm {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        outline: 0px;
        margin: 0px;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        font-size: 0.875rem;
        font-weight: 700;
        text-transform: none;
        line-height: 1.75;
        min-width: 64px;
        padding: 5px 10px;
        border-radius: 4px;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border: 1px solid $background-color-2;
        color: $background-color-2;
        width: 100%;
        box-shadow: none;
        background-color: transparent;
        &:hover {
          background-color: rgba($color: $background-color-2, $alpha: 0.125);
        }
      }
    }
  }
}
</style>
