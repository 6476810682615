<template>
  <div class="order-detail" v-if="propItem && propItem.images">
    <OrderInfo :prop-item="propItem" />
    <AoiInfo :prop-item="propItem" :prop-images="propItem.images" />
    <div class="order-images" v-if="imagesOne && imagesOne.length > 0">
      <CommonAccordion
        :isChevronEnd="true"
        :propTitle="$t('label_order_images')"
        ><div slot="content" class="accordion w-100">
          <div class="datatable" v-for="item in imagesOne" :key="item.id">
            <DetailMetadata
              :propItem="item.image"
              :prop-show-download="false"
            />
          </div>
        </div>
      </CommonAccordion>
    </div>
    <div class="order-images" v-if="sharedImages && sharedImages.length > 0">
      <CommonAccordion
        :isChevronEnd="true"
        :propTitle="$t('label_received_images')"
        ><div slot="content" class="accordion w-100">
          <div class="datatable" v-for="item in sharedImages" :key="item.id">
            <DetailMetadata
              :propItem="item.image"
              :prop-show-download="true"
              :propButtonDownload="propButtonDownload"
              @onDownloadImage="onDownloadImage"
            />
          </div>
        </div>
      </CommonAccordion>
    </div>
  </div>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import DetailMetadata from "./detail4images/Metadata.vue";
import AoiInfo from "./detail4images/AoiInfo";
import OrderInfo from "./detail4images/OrderInfo";
import moment from "moment";
export default {
  components: { CommonAccordion, DetailMetadata, AoiInfo, OrderInfo },
  props: {
    propItem: {
      type: Object,
      default: () => {},
    },
    propButtonDownload: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
    onDownloadImage(data) {
      this.$emit("onDownloadImage", data);
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    sharedImages() {
      let results = [];
      if (
        this.propItem &&
        this.propItem.images &&
        this.propItem.images.length > 0
      ) {
        let items = [];
        // let items = this.propItems.filter(
        //   (x) => x && x.image && x.user_add != this.propOrder.created_by
        // );
        for (let i = 0; i < this.propItem.images.length; i++) {
          if (
            this.propItem.images[i] &&
            this.propItem.images[i].image &&
            this.propItem.images[i].user_add != this.propItem.created_by
          ) {
            if (
              items.findIndex(
                (x) => x.image.id === this.propItem.images[i].image.id
              ) < 0
            )
              items.push(this.propItem.images[i]);
          }
        }
        results = items.filter(
          (x) =>
            x.image &&
            this.propItem.shared &&
            this.propItem.shared.map((y) => y.image_id).includes(x.image.id) &&
            this.propItem.shared
              .map((y) => y.order_id)
              .includes(this.propItem.id)
        );
      }
      return results;
    },
    imagesOne() {
      // images of buyer
      let results = [];
      if (this.propItem.images && this.propItem.images.length > 0) {
        for (let i = 0; i < this.propItem.images.length; i++) {
          if (
            this.propItem.images[i] &&
            this.propItem.images[i].image &&
            this.propItem.images[i].user_add === this.propItem.created_by
          ) {
            if (
              results.findIndex(
                (x) => x.image.id === this.propItem.images[i].image.id
              ) < 0
            )
              results.push(this.propItem.images[i]);
          }
        }
      }
      return results;
    },
  },
};
</script>
<style lang="scss" scoped>
.accordion {
  width: 100%;
  .accordion-header a {
    padding-left: 0 !important;
  }
}
.tasking-detail {
  .detail-metadata {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.order-images {
  margin-top: 0.5rem;
  .accordion {
    background: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    .accordion-header a {
      padding-left: 0;
    }
  }
}
</style>
