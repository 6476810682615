var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-order-content"},[_c('div',{staticClass:"my-order__dialog"},[(_vm.dlgOrderDetailEgeo.show)?_c('DialogOrderDetailEgeo',{attrs:{"prop-style":{ width: _vm.dlgOrderDetailEgeo.width },"prop-show":_vm.dlgOrderDetailEgeo.show,"prop-title":_vm.dlgOrderDetailEgeo.title,"prop-item":_vm.dlgOrderDetailEgeo.value,"propGranularities":_vm.granularity.items,"propLookDirection":_vm.lookDirection.items,"propOrbitDirection":_vm.orbitDirection.items,"propProgramingServices":_vm.programingService.items,"propSatelliteType":_vm.satelliteType.items},on:{"close":function($event){_vm.dlgOrderDetailEgeo.show = false;
        _vm.dlgOrderDetailEgeo.value = null;},"getAllDirection":_vm.getAllDirection,"getAllTaskingProgrammingService":_vm.getAllTaskingProgrammingService,"getAllTaskingGranularity":_vm.getAllTaskingGranularity,"getAllSatelliteType":_vm.getAllSatelliteType}}):_vm._e(),(_vm.dlgDialogRemark.show)?_c('DialogRemark',{attrs:{"prop-style":{
        width: _vm.dlgDialogRemark.width,
      },"prop-show":_vm.dlgDialogRemark.show,"prop-title":_vm.dlgDialogRemark.title,"prop-value":_vm.dlgDialogRemark.value},on:{"close":function($event){_vm.dlgDialogRemark.show = false;
        _vm.dlgDialogRemark.value = null;
        _vm.dlgDialogRemark.title = null;}}}):_vm._e(),(_vm.dlgCancelOrder.show)?_c('DialogCancelOrder',{attrs:{"prop-style":{ width: _vm.dlgCancelOrder.width },"prop-show":_vm.dlgCancelOrder.show,"prop-title":_vm.dlgCancelOrder.title,"prop-items":_vm.dlgCancelOrder.value,"propButton":_vm.buttons.cancelOrder},on:{"close":function($event){_vm.dlgCancelOrder.show = false;
        _vm.dlgCancelOrder.value = null;},"message":function($event){_vm.message = $event},"onCancelOrder":_vm.onCancelOrder}}):_vm._e(),(_vm.dlgImageList.show)?_c('DialogImageList',{attrs:{"prop-style":{ width: _vm.dlgImageList.width },"prop-show":_vm.dlgImageList.show,"prop-title":_vm.dlgImageList.title,"prop-items":_vm.dlgImageList.value.images,"prop-buttons":_vm.buttons,"propImageTypes":_vm.satelliteType.items},on:{"close":function($event){_vm.dlgImageList.show = false;
        _vm.dlgImageList.value = null;},"getAllSatelliteType":_vm.getAllSatelliteType,"showDialogMetadata":_vm.showDialogMetadata,"downloadImages":_vm.onDownloadImages}}):_vm._e(),(_vm.dialogMetadata.show)?_c('DialogMetadata',{attrs:{"prop-style":{ width: _vm.dialogMetadata.width },"prop-show":_vm.dialogMetadata.show,"prop-title":_vm.dialogMetadata.title,"prop-item":_vm.dialogMetadata.value},on:{"close":function($event){_vm.dialogMetadata.show = false;
        _vm.dialogMetadata.value = null;}}}):_vm._e(),(_vm.message.length > 0)?_c('DialogMessage',{attrs:{"propTitle":_vm.$t('title_message_info'),"prop-show":_vm.message.length > 0,"prop-message":_vm.message},on:{"close":function($event){_vm.message = []}}}):_vm._e()],1),_c('div',{staticClass:"my-order__search",attrs:{"id":"elementSearch"}},[_c('OrderSearch',{attrs:{"propSearch":_vm.myOrder.search,"prop-buttons":_vm.buttons,"propOrderStatus":_vm.orderStatus.items},on:{"onSearch":_vm.onSearch,"message":function($event){_vm.message = $event}}})],1),_c('div',{staticClass:"my-order__datatable"},[_c('OrderDataTable',{attrs:{"propItems":_vm.myOrder.items,"prop-table":_vm.myOrder.table,"prop-button-detail":_vm.buttons.detail,"prop-loading":_vm.myOrder.loading,"propTotal":_vm.myOrder.total_records},on:{"nextPage":_vm.nextPage,"showOrderDetail":_vm.showOrderDetail,"showDialogCancel":_vm.showDialogCancel,"shoDialogRemark":_vm.shoDialogRemark,"showImageList":_vm.showImageList}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }