<template>
    <div>
        <div class="row"></div>
        <div class="row">
            <div class="col-6">
                <CommonAccordion :propTitle="'COSMO-Skymed One Tasking'">
                    <div slot="header" class="pr-2">                        
                        <Checkbox
                            v-model="allTasking"
                            :indeterminate="indeterminateTasking"
                            :binary="true"
                            @click.stop="checkBoxAllTaskingClick"
                            @change="checkBoxAllTaskingChange"
                            :prop-style="style"
                        />
                    </div>
                    <div slot="content" class="p-2">
                        <v-jstree :data="tasking" :collapse="true" show-checkbox multiple allow-batch whole-row :no-dots="true" @item-click="itemTaskingClick"></v-jstree>  
                    </div>
                </CommonAccordion>
            </div>
            <div class="col-6">
                <CommonAccordion :propTitle="'COSMO-Skymed archive'">
                    <div slot="header" class="pr-2">
                        <Checkbox
                            v-model="allArchive"
                            :indeterminate="indeterminateArchive"
                            :binary="true"
                            @click.stop="checkBoxAllArchiveClick"
                            @change="checkBoxAllArchiveChange"
                            :prop-style="style"
                        />
                    </div>
                    <div slot="content" class="p-2">
                        <v-jstree :data="archive" :collapse="true" show-checkbox multiple allow-batch whole-row :no-dots="true" @item-click="itemArchiveClick"></v-jstree>  
                    </div>
                </CommonAccordion>
            </div>
        </div>
    </div>
</template>
<script>
import CommonAccordion from  "@/components/commons/accordion/Accordion"
import Checkbox from  "@/components/commons/checkbox/Checkbox"
import VJstree from 'vue-jstree'
export default {
    components:{
        CommonAccordion, Checkbox, VJstree
    },
    data(){
        return{
            indeterminateTasking: false,            
            indeterminateArchive: false,
            style: " border-color: #ced4da; background: transparent;",
            allTasking:true,
            allArchive:true,
            tasking:[
                {
                    label:"COSMO-Skymed One Now",
                    text:"COSMO-Skymed One Now",
                    value:"onenow",
                    id:"onenow",
                    code:"onenow",
                    icon:"fas fa-satellite",
                    selected:true
                },{
                    label:"COSMO-Skymed One Day",
                    text:"COSMO-Skymed One Day",
                    value:"oneday",
                    id:"oneday",
                    code:"oneday",
                    icon:"fas fa-satellite",
                    selected:true
                },{
                    label:"COSMO-Skymed One Plan",
                    text:"COSMO-Skymed One Plan",
                    value:"oneplan",
                    id:"oneplan",
                    code:"oneplan",
                    icon:"fas fa-satellite",
                    selected:true
                },{
                    label:"COSMO-Skymed One Series",
                    text:"COSMO-Skymed One Series",
                    value:"oneseries",
                    id:"oneseries",
                    code:"oneseries",
                    icon:"fas fa-satellite",
                    selected:true
                }
            ],
            archive:[
                {
                    label:"ScanSAR (HugeRegion) - Left",
                    text:"ScanSAR (HugeRegion) - Left",
                    value:"scansar_hugeregion_left",
                    id:"scansar_hugeregion_left",
                    code:"scansar_hugeregion_left",
                    icon:"fas fa-image",
                    selected:true
                },
                {
                    label:"ScanSAR (HugeRegion) - Right",
                    text:"ScanSAR (HugeRegion) - Right",
                    value:"scansar_hugeregion_right",
                    id:"scansar_hugeregion_right",
                    code:"scansar_hugeregion_right",
                    icon:"fas fa-image",
                    selected:true
                },
                {
                    label:"ScanSAR (WideRegion) - Left",
                    text:"ScanSAR (WideRegion) - Left",
                    value:"scansar_wideregion_left",
                    id:"scansar_wideregion_left",
                    code:"scansar_wideregion_left",
                    icon:"fas fa-image",
                    selected:true
                },
                {
                    label:"ScanSAR (WideRegion) - Right",
                    text:"ScanSAR (WideRegion) - Right",
                    value:"scansar_wideregion_right",
                    id:"scansar_wideregion_right",
                    code:"scansar_wideregion_right",
                    icon:"fas fa-image",
                    selected:true
                },
                {
                    label:"Spotlight (Mode 2) - Left",
                    text:"Spotlight (Mode 2) - Left ",
                    value:"spotlight_mode2_left",
                    id:"spotlight_mode2_left",
                    code:"spotlight_mode2_left",
                    icon:"fas fa-image",
                    selected:true
                },
                {
                    label:"Spotlight (Mode 2) - Right",
                    text:"Spotlight (Mode 2) - Right",
                    value:"spotlight_mode2_right",
                    id:"spotlight_mode2_right",
                    code:"spotlight_mode2_right",
                    icon:"fas fa-image",
                    selected:true
                },
                {
                    label:"StripMap (HIMAGE) - Left",
                    text:"StripMap (HIMAGE) - Left",
                    value:"stripmap_himige_left",
                    id:"stripmap_himige_left",
                    code:"stripmap_himige_left",
                    icon:"fas fa-image",
                    selected:true
                },
                {
                    label:"StripMap (HIMAGE) - Right",
                    text:"StripMap (HIMAGE) - Right",
                    value:"stripmap_himige_right",
                    id:"stripmap_himige_right",
                    code:"stripmap_himige_right",
                    icon:"fas fa-image",
                    selected:true
                },
                {
                    label:"StripMap (Ping Pong) - Left",
                    text:"StripMap (Ping Pong) - Left",
                    value:"stripmap_pingpong_left",
                    id:"stripmap_pingpong_left",
                    code:"stripmap_pingpong_left",
                    icon:"fas fa-image",
                    selected:true
                },
                {
                    label:"StripMap (Ping Pong) - Right",
                    text:"StripMap (Ping Pong) - Right",
                    value:"stripmap_pingpong_right",
                    id:"stripmap_pingpong_right",
                    code:"stripmap_pingpong_right",
                    icon:"fas fa-image",
                    selected:true
                }
            ]
        }
    },
    computed:{
        searchDto(){
            return this.$store.getters.getSearchDto
        },
    },
    created(){},
    mounted(){
        if(this.searchDto && Object.keys(this.searchDto).keys > 0){
            let dto = Object.assign({}, JSON.parse(JSON.stringify(this.searchDto)));
            if(dto.egeos && Object.keys(dto.egeos).length > 0){
                if(dto.egeos.tasking){
                    for (let i = 0; i < this.tasking.length; i++) {
                        let index = dto.egeos.tasking.findIndex(x=>x.id === this.tasking[i].id);
                        if(index >= 0){
                            this.tasking[i].selected = dto.egeos.tasking[index].selected ? dto.egeos.tasking[index].selected : false
                        }else this.tasking[i].selected = false
                    }
                    for (let i = 0; i < this.archive.length; i++) {
                        let index = dto.egeos.archive.findIndex(x=>x.id === this.archive[i].id);
                        if(index >= 0){
                            this.archive[i].selected = dto.egeos.archive[index].selected ? dto.egeos.archive[index].selected : false
                        }else this.archive[i].selected = false
                    }
                }
            }else{
                this.$store.dispatch("setSearchDto",{
                    egeos:{
                        tasking: this.tasking,
                        archive: this.archive
                    }
                })
            }
        }
        this.$emit("productChange",{
            egeos:{
                tasking: this.tasking,
                archive: this.archive
            }
        })
    },
    methods:{
        checkBoxAllTaskingChange(){
            for (let i = 0; i < this.tasking.length; i++) {
                this.tasking[i].selected = this.allTasking;
            }
        },
        checkBoxAllTaskingClick(){},
        checkBoxAllArchiveChange(){
            for (let i = 0; i < this.archive.length; i++) {
                this.archive[i].selected = this.allArchive;
            }
        },
        checkBoxAllArchiveClick(){},
        itemTaskingClick(){
            let array = this.tasking.map(x=>x.selected);
            this.allTasking = array.filter(x=>x).length === this.tasking.length;
            if(!this.allTasking && array.filter(x=>x).length > 0)
                this.indeterminateTasking = true;
            else this.indeterminateTasking = false;
            this.$emit("productChange",{
                egeos:{
                    tasking: this.tasking,
                    archive: this.archive
                }
            })
        },
        itemArchiveClick(){
            let array = this.archive.map(x=>x.selected);
            this.allArchive = array.filter(x=>x).length === this.archive.length;
            if(!this.allArchive && array.filter(x=>x).length > 0)
                this.indeterminateArchive = true;
            else this.indeterminateArchive = false;
            this.$emit("productChange",{
                egeos:{
                    tasking: this.tasking,
                    archive: this.archive
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
</style>