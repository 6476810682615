<template>
  <navbar @openLoginForm="openLoginForm"></navbar>
</template>
<script>
import Navbar from "./header/Navbar";
export default {
  components: { Navbar },
  methods: {
    openLoginForm() {
      this.$store.dispatch("setIsLoginForm", true);
    },
  },
};
</script>