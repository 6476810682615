<template>
  <div class="tasking-area-of-interest">
    <div class="row no-gutters">
      <label class="col-12 col-md-3 col-form-label"
        >{{ $t("label_area_name") }}&nbsp;<span class="required">*</span></label
      >
      <div class="col-12 col-md-9">
        <InputText
          class="form-control new-style"
          v-model="propEntity.area_name"
          maxlength="255"
          autofocus
          id="inputAreaName"
        />
      </div>
    </div>
    <div class="row no-gutters">
      <label class="col-12 col-md-3 col-form-label"
        >{{ $t("label_number_of_scenes") }}&nbsp;<span class="required"
          >*</span
        ></label
      >
      <div class="col-12 col-md-9">
        <InputText
          type="number"
          class="form-control new-style"
          min="1"
          max="100"
          v-model="propEntity.number_of_scenes"
        />
      </div>
    </div>
    <div class="row no-gutters">
      <label class="col-12 col-md-3 col-form-label"
        >{{ $t("label_satellite") }} &nbsp;<span class="required"
          >*</span
        ></label
      >
      <div class="col-12 col-md-9">
        <treeselect
          class="new-style"
          v-model="propEntity.satellite"
          :clearable="false"
          :multiple="false"
          :options="propProviders"
          placeholder=""
          :noChildrenText="$t('label_no_sub_options')"
          :noOptionsText="$t('label_no_options_text')"
          :noResultsText="$t('label_no_results_text')"
          :disableBranchNodes="true"
          :default-expand-level="1"
          @select="satelliteSelect"
        >
          <label slot="option-label" slot-scope="{ node }">{{
            node.raw.label
          }}</label>
          <div slot="value-label" slot-scope="{ node }">
            {{ node.raw.label }}
          </div>
        </treeselect>
      </div>
    </div>
    <div class="row no-gutters">
      <label class="col-12 col-md-3 col-form-label"
        >{{ $t("label_form") }}&nbsp;<span class="required">*</span></label
      >
      <div class="col-12 col-md-9">
        <Dropdown
          readony
          class="new-style w-100"
          :showClear="false"
          :options="options"
          optionLabel="label"
          optionValue="id"
          v-model="propEntity.coordinates_form"
        >
          <template #value="slotProps">
            <div class="p-dropdown-value" v-if="slotProps.value">
              <span>{{ $t(findLabelCoordinatesForm(slotProps.value)) }}</span>
            </div>
          </template>
          <template #option="slotProps">
            <div class="p-dropdown-option">
              <span> {{ $t(slotProps.option.label) }}</span>
            </div>
          </template>
        </Dropdown>
      </div>
    </div>
    <div class="row no-gutters w-100">
      <CommonAccordion :isChevronEnd="true" :propTitle="$t('label_aoi_info')">
        <div slot="content" class="accordion">
          <div
            class="row no-gutters centre-coordinates"
            v-if="propEntity.coordinates_form === 'radius'"
          >
            <label class="col-2 text-position">{{
              $t("label_point_radius")
            }}</label>
            <div class="col-10 row no-gutters m-0">
              <div class="col-4">
                <label class="col-form-label">{{ $t("label_latitude") }}</label>
                <InputText
                  type="number"
                  class="form-control new-style"
                  readonly
                  maxLength="9"
                  v-model="propEntity.centre.lat"
                />
              </div>

              <div class="col-4">
                <label class="col-form-label"
                  >{{ $t("label_longitude") }}
                </label>
                <InputText
                  type="number"
                  class="form-control new-style"
                  readonly
                  maxLength="10"
                  v-model="propEntity.centre.lng"
                />
              </div>
              <div class="col-4">
                <label for="inputRadius" class="col-form-label"
                  >{{ $t("label_radius") }} (Km)</label
                >
                <InputText
                  type="text"
                  class="form-control new-style"
                  readonly
                  maxLength="7"
                  v-model="propEntity.centre.radius"
                />
              </div>
            </div>
          </div>
          <div
            class="row no-gutters w-100"
            v-if="propEntity.coordinates_form === 'polygon'"
          >
            <div class="row no-gutters w-100">
              <div class="col">
                <div class="row no-gutters corner-group">
                  <label class="col-2 text-position">{{
                    $t("label_upper_left")
                  }}</label>
                  <div class="col-5">
                    <label class="col-form-label">{{
                      $t("label_latitude")
                    }}</label>
                    <InputText
                      type="text"
                      class="form-control new-style"
                      placeholder="0.0"
                      maxLength="9"
                      v-model="upperLeft.lat"
                      readonly
                    />
                  </div>
                  <div class="col-5">
                    <label class="col-form-label">{{
                      $t("label_longitude")
                    }}</label>
                    <InputText
                      type="text"
                      class="form-control new-style"
                      placeholder="0.0"
                      maxLength="10"
                      v-model="upperLeft.lng"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row no-gutters corner-group">
                  <label class="col-2 text-position">{{
                    $t("label_upper_right")
                  }}</label>
                  <div class="col-5">
                    <label class="col-form-label">{{
                      $t("label_latitude")
                    }}</label>
                    <InputText
                      type="text"
                      class="form-control new-style"
                      placeholder="0.0"
                      maxLength="9"
                      v-model="upperRight.lat"
                      readonly
                    />
                  </div>
                  <div class="col-5">
                    <label class="col-form-label">{{
                      $t("label_longitude")
                    }}</label>
                    <InputText
                      type="text"
                      class="form-control new-style"
                      placeholder="0.0"
                      maxLength="10"
                      v-model="upperRight.lng"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row no-gutters w-100">
              <div class="col">
                <div class="row no-gutters corner-group">
                  <label class="col-2 text-position">{{
                    $t("label_lower_left")
                  }}</label>
                  <div class="col-5">
                    <label class="col-form-label">{{
                      $t("label_latitude")
                    }}</label>
                    <InputText
                      type="text"
                      class="form-control new-style"
                      placeholder="0.0"
                      maxLength="9"
                      v-model="lowerLeft.lat"
                      readonly
                    />
                  </div>
                  <div class="col-5">
                    <label class="col-form-label">{{
                      $t("label_longitude")
                    }}</label>
                    <InputText
                      type="text"
                      class="form-control new-style"
                      placeholder="0.0"
                      maxLength="10"
                      v-model="lowerLeft.lng"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row no-gutters corner-group">
                  <label class="col-2 text-position">{{
                    $t("label_lower_right")
                  }}</label>
                  <div class="col-5">
                    <label class="col-form-label">{{
                      $t("label_latitude")
                    }}</label>
                    <InputText
                      type="text"
                      class="form-control new-style"
                      placeholder="0.0"
                      maxLength="9"
                      v-model="lowerRight.lat"
                      readonly
                    />
                  </div>
                  <div class="col-5">
                    <label class="col-form-label">{{
                      $t("label_longitude")
                    }}</label>
                    <InputText
                      type="text"
                      class="form-control new-style"
                      placeholder="0.0"
                      maxLength="10"
                      v-model="lowerRight.lng"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div></div
      ></CommonAccordion>
    </div>
  </div>
</template>
<script>
import InputText from "@/components/commons/inputtext/InputText";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import Treeselect from "@riophae/vue-treeselect";
export default {
  components: {
    InputText,
    Dropdown,
    CommonAccordion,
    Treeselect,
  },
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },
    propProviders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: [
        {
          id: "polygon",
          label: "label_points_polygon",
        },
        {
          id: "radius",
          label: "label_point_radius",
        },
      ],
      upperLeft: {
        lat: null,
        lng: null,
      },
      upperRight: {
        lat: null,
        lng: null,
      },
      lowerLeft: {
        lat: null,
        lng: null,
      },
      lowerRight: {
        lat: null,
        lng: null,
      },
    };
  },
  created() {
    this.options = this.options.filter(
      (x) => x.id === this.propEntity.coordinates_form
    );
    if (this.propProviders.length === 0) {
      this.$emit("getAllProvider");
    }
    if (
      this.propEntity.coordinates_form === "polygon" &&
      this.propEntity.coordinates
    ) {
      if (
        this.propEntity.coordinates[0] &&
        Object.keys(this.propEntity.coordinates[0]).length > 0
      ) {
        this.upperLeft.lat = this.propEntity.coordinates[0].lat
          ? this.propEntity.coordinates[0].lat
          : null;
        this.upperLeft.lng = this.propEntity.coordinates[0].lng
          ? this.propEntity.coordinates[0].lng
          : null;
      }
      if (
        this.propEntity.coordinates[1] &&
        Object.keys(this.propEntity.coordinates[1]).length > 0
      ) {
        this.upperRight.lat = this.propEntity.coordinates[1].lat
          ? this.propEntity.coordinates[1].lat
          : null;
        this.upperRight.lng = this.propEntity.coordinates[1].lng
          ? this.propEntity.coordinates[1].lng
          : null;
      }

      if (
        this.propEntity.coordinates[2] &&
        Object.keys(this.propEntity.coordinates[2]).length > 0
      ) {
        this.lowerLeft.lat = this.propEntity.coordinates[2].lat
          ? this.propEntity.coordinates[2].lat
          : null;
        this.lowerLeft.lng = this.propEntity.coordinates[2].lng
          ? this.propEntity.coordinates[2].lng
          : null;
      }
      if (
        this.propEntity.coordinates[3] &&
        Object.keys(this.propEntity.coordinates[3]).length > 0
      ) {
        this.lowerRight.lat = this.propEntity.coordinates[3].lat
          ? this.propEntity.coordinates[3].lat
          : null;
        this.lowerRight.lng = this.propEntity.coordinates[3].lng
          ? this.propEntity.coordinates[3].lng
          : null;
      }
    }
  },
  mounted() {
    document.getElementById("inputAreaName").focus();
  },
  methods: {
    findLabelCoordinatesForm(id) {
      let item = this.options.find((x) => x.id === id);
      return item ? item.label : null;
    },
    satelliteSelect(node) {
      this.propEntity.satellite_name =
        node && node.label ? node.label.trim() : this.propEntity.satellite_name;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.tasking-area-of-interest {
  padding: 0 0.5rem;
  width: 100%;
  color: $text-color;
  .row {
    margin-bottom: 1rem;
  }
  .text-position {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .accordion {
    width: 100%;
    .row {
      margin-bottom: 1rem;
      &.centre-coordinates {
        .col-4 {
          padding-right: 0.25rem;
          padding-left: 0.25rem;
          &:last-child {
            padding-right: 0;
          }
        }
      }
      &.corner-group {
        label {
        }
        .col-5 {
          padding-right: 0.25rem;
          &:last-child {
            padding-left: 0.25rem;
            padding-right: 0;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.tasking-area-of-interest {
  .accordion .accordion-header a {
    padding-left: 0 !important;
  }
}
</style>
