<template>
  <div class="list-group">
    <div
      class="list-group-item"
      v-for="item in segments4Display"
      :key="item.id"
      @mouseover="mouseOverItem(item)"
      @mouseleave="mouseLeaveItem(item)"
    >
      <div class="attempt-item">
        <div class="item-text">
          <p>
            <strong>{{ propAttempts[0].progTypes[0].mission }}</strong>
            -
            {{ formatUTCAcquisitionStartDate(item.acquisitionStartDate) }} (UTC)
          </p>

          <span
            >{{ $t("label_deadline") }}:
            {{ formatGMTOrderDeadline(item.orderDeadline) }}
            | UTC: {{ formatUTCOrderDeadline(item.orderDeadline) }}</span
          ><br />
          <span
            >{{ $t("label_incidence_angle") }}:
            {{ item.incidenceAngle.toFixed(2) }}&deg; -
            {{ maxIncidenceAngle }}&deg;</span
          >
        </div>
        <div class="item-buttons">
          <button
            class="btn border"
            :class="
              propSelected &&
              propSelected.findIndex((x) => x.id === item.id) >= 0
                ? 'active'
                : ''
            "
            @click="selectAttempt(item)"
          >
            <i class="fas fa-shopping-cart"></i>
          </button>
          <button
            class="btn border"
            :class="
              propFootprints &&
              propFootprints.findIndex((x) => x === item.id) >= 0
                ? 'active'
                : ''
            "
            @click="addFootprint(item)"
          >
            <i class="fas fa-thumbtack"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import {
  maxCloudCoverOneAtlasOneDay,
  maxIncidenceAngleOneAtlasOneDay,
} from "@/utils/commons/environment";
export default {
  props: {
    propAttempts: {
      type: Array,
      default: () => [],
    },
    propSelected: {
      type: Array,
      default: () => [],
    },
    propFootprints: {
      type: Array,
      default: () => [],
    },
    propSort: {
      type: String,
      default: "accessToTarget,up",
    },
  },
  data() {
    return {
      segments: [],
      maxIncidenceAngle: maxIncidenceAngleOneAtlasOneDay,
    };
  },
  created() {
    try {
      this.segments =
        this.propAttempts &&
        this.propAttempts.length &&
        this.propAttempts[0].progTypes[0].segments
          ? this.propAttempts[0].progTypes[0].segments
          : [];
    } catch (error) {}
  },
  watch: {},
  methods: {
    formatUTCAcquisitionStartDate(val) {
      try {
        moment.locale(this.lang);
        let date = new Date(val);
        return moment(date).utc().format("lll");
      } catch (error) {}
      return null;
    },
    formatUTCOrderDeadline(val) {
      try {
        moment.locale(this.lang);
        let date = new Date(val);
        return moment(date).utc().format("lll");
      } catch (error) {}
      return null;
    },

    formatGMTOrderDeadline(val) {
      try {
        moment.locale(this.lang);
        let date = new Date(val);
        return moment(date).format("lll");
      } catch (error) {}
      return null;
    },
    selectAttempt(item) {
      this.$emit("selectAttempt", {
        segment: item,
      });
    },
    addFootprint(item) {
      this.$emit("addFootprint", item);
    },
    mouseOverItem(item) {
      this.$emit("mouseOverItem", item);
    },
    mouseLeaveItem(item) {
      this.$emit("mouseLeaveItem", item);
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    segments4Display() {
      switch (this.propSort) {
        case "accessToTarget,up":
          return this.segments.sort(function (a, b) {
            if (
              new Date(a.acquisitionStartDate).getTime() <
              new Date(b.acquisitionStartDate).getTime()
            )
              return -1;
            else if (
              new Date(a.acquisitionStartDate).getTime() >
              new Date(b.acquisitionStartDate).getTime()
            )
              return 1;
            return 0;
          });
          break;
        case "accessToTarget,down":
          return this.segments.sort(function (a, b) {
            if (
              new Date(a.acquisitionStartDate).getTime() <
              new Date(b.acquisitionStartDate).getTime()
            )
              return 1;
            else if (
              new Date(a.acquisitionStartDate).getTime() >
              new Date(b.acquisitionStartDate).getTime()
            )
              return -1;
            return 0;
          });

          break;
        case "mission,up":
          return this.segments.sort(function (a, b) {
            if (a.id < b.id) return -1;
            else if (a.id > b.id) return 1;
            return 0;
          });
          break;
        case "mission,down":
          return this.segments.sort(function (a, b) {
            if (a.id < b.id) return 1;
            else if (a.id > b.id) return -1;
            return 0;
          });
          break;
        case "orderDeadline,up":
          return this.segments.sort(function (a, b) {
            if (
              new Date(a.orderDeadline).getTime() <
              new Date(b.orderDeadline).getTime()
            )
              return -1;
            else if (
              new Date(a.orderDeadline).getTime() >
              new Date(b.orderDeadline).getTime()
            )
              return 1;
            return 0;
          });
          break;
        case "orderDeadline,down":
          return this.segments.sort(function (a, b) {
            if (
              new Date(a.orderDeadline).getTime() <
              new Date(b.orderDeadline).getTime()
            )
              return 1;
            else if (
              new Date(a.orderDeadline).getTime() >
              new Date(b.orderDeadline).getTime()
            )
              return -1;
            return 0;
          });
          break;
        case "viewingAngle,up":
          return this.segments.sort(function (a, b) {
            if (a.incidenceAngle < b.incidenceAngle) return -1;
            else if (a.incidenceAngle > b.incidenceAngle) return 1;
            return 0;
          });
          break;
        case "viewingAngle,down":
          return this.segments.sort(function (a, b) {
            if (a.incidenceAngle < b.incidenceAngle) return 1;
            else if (a.incidenceAngle > b.incidenceAngle) return -1;
            return 0;
          });
          break;

        default:
          return this.segments;
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.list-group {
  list-style: none;
  .list-group-item {
    border-color: #eef3f7;
    padding: 0.5rem 0.75rem;
    &:hover {
      cursor: pointer;
      background: #eef3f7;
    }
    .attempt-item {
      display: grid;
      grid-template-columns: [first] auto [line2] 4rem [end];
      gap: 0.5rem;
      .item-text {
        p {
          margin-bottom: 0;
        }
        span {
          font-size: 12px;
        }
      }
      .item-buttons {
        justify-content: center;
        margin: auto;
        button {
          width: 1.75rem;
          height: 1.75rem;
          font-size: 12px;
          margin-right: 0.25rem;
          line-height: 0;
          padding: 0;
          &:last-child {
            margin-right: 0;
          }
          &.active {
            background: rgba(0, 158, 247, 0.8);
            border: 1px solid rgba(0, 158, 247, 1);
            color: white;
          }
        }
      }
    }
  }
}
</style>
