<template>
  <div id="viewer-image" :style="{ height: imageHeight + 'px' }" ref="image" />
</template>
<script>
import OpenSeadragon from "openseadragon";
export default {
  name: "Viewer",
  props: {
    h: {
      type: Number,
      default: 0,
    },
    propData: {
      type: Object,
    },
  },
  components: {},
  data() {
    return {
      viewer: null,
      source: null,
      show: true,
    };
  },
  watch: {
    "propData.img": {
      handler(val) {
        if (val) {
          var vm = this;
          if (vm.source) {
            vm.viewer.addTiledImage({
              tileSource: {
                type: "image",
                url: vm.propData.img,
              },
              success: function () {
                vm.viewer.world.removeItem(vm.viewer.world.getItemAt(0));
              },
            });
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    imageHeight() {
      let dom = document.getElementById("tableMetadata");
      if (dom) {
        return dom.offsetHeight;
      } else return 500;
    },
  },
  mounted() {
    this.initViewer();
  },
  methods: {
    initViewer() {
      var vm = this;
      const img = new Image();
      img.src = this.propData.img;
      img.onload = function () {
        vm.source = [
          {
            type: "image",
            url: vm.propData.img,
          },
        ];
        vm.viewer = OpenSeadragon({
          id: "viewer-image",
          animationTime: 0.4,
          showNavigator: false,
          showNavigationControl: false,
          sequenceMode: false,
          tileSources: vm.source,
          preload: true,
        });
      };
    },
  },
};
</script>
<style lang="scss" scoped>
#viewer-image {
  width: 100%;
  background: rgba(229, 232, 238, 0.25);
  border-radius: 0.25rem;
}
</style>
<style lang="scss">
.openseadragon-container {
  background-color: rgba(229, 232, 238, 0.25);
}
.openseadragon-container :focus {
  outline: none;
}
</style>

