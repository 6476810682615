
const SET_IS_TASKING = "SET_IS_TASKING";
export default {
    state: {
        isTasking:false
    },
    getters: {
        getIsTasking: state => {
            return state.isTasking;
        },
    },
    mutations: {
        [SET_IS_TASKING](state, payload) {
            state.isTasking = payload;
        },
    },
    actions: {
        setIsTasking({ commit }, payload) {
            commit(SET_IS_TASKING, payload);
        },
    }
}