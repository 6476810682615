<template>
  <li>
    <div class="mt-1">
      <span
        >Type:<strong>{{
          propItem.properties["sar:product_type"]
        }}</strong></span
      >
      <span class="pl-1 pr-1">|</span>
      <span
        >Mode:
        <strong>{{ propItem.properties["sar:instrument_mode"] }}</strong></span
      >
      <span class="pl-1 pr-1">|</span>
      <span
        >Polarization: <strong>{{ polLayer }}</strong></span
      >
      <span class="pl-1 pr-1">|</span>
      <span
        >Incidence:
        <strong>{{ propItem.properties["view:incidence_angle"] }}</strong></span
      >
    </div>
  </li>
</template>
<script>
export default {
  props: {
    propItem: {
      type: Object,
      default: () => {},
    },
  },
  created() {},
  computed: {
    polLayer() {
      let result = null;
      if (
        this.propItem &&
        Object.keys(this.propItem).length > 0 &&
        this.propItem.properties
      ) {
        let pol = this.propItem.properties["sar:polarizations"];
        if (pol) {
          if (Array.isArray(pol)) result = pol.join(",");
          else result = pol;
        }
      }
      return result;
    },

    direction() {
      let result = null;
      if (
        this.propItem &&
        Object.keys(this.propItem).length > 0 &&
        this.propItem.metadata
      ) {
        let object = this.propItem.metadata
          ? this.propItem.metadata.find(
              (x) => x.key === "direction" || x.key === "look_direction"
            )
          : null;
        result =
          object && object.value
            ? (object.value + "").trim().replace(/\s\s+/g, " ")
            : null;
      }
      return result;
    },

    beamMode() {
      let result = null;
      if (
        this.propItem &&
        Object.keys(this.propItem).length > 0 &&
        this.propItem.metadata
      ) {
        let object = this.propItem.metadata
          ? this.propItem.metadata.find(
              (x) => x.key === "beam_mode" || x.key === "mode"
            )
          : null;
        result =
          object && object.value
            ? (object.value + "").trim().replace(/\s\s+/g, " ")
            : null;
      }
      return result;
    },

    orbitDirection() {
      let result = null;
      if (
        this.propItem &&
        Object.keys(this.propItem).length > 0 &&
        this.propItem.metadata
      ) {
        let object = this.propItem.metadata
          ? this.propItem.metadata.find(
              (x) => x.key === "orbit_direction" || x.key === "orbit"
            )
          : null;
        result =
          object && object.value
            ? (object.value + "").trim().replace(/\s\s+/g, " ")
            : null;
      }
      return result;
    },
    satellite() {},
  },
};
</script>
<style lang="scss" scoped></style>
