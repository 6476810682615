<template>
  <div class="coors_polygon">
    <h6 class="title">{{ $t("label_corner_coordinates") }}</h6>
    <div class="row no-gutters corner-group">
      <label class="col-2 text-position">{{ $t("label_upper_left") }}</label>
      <div class="col-5">
        <label for="inputRadiuslat" class="col-form-label">{{
          $t("label_latitude")
        }}</label>
        <InputText
          type="number"
          id="i7h2G4hbh11"
          class="form-control new-style"
          @input="inputUpperLeftChange('i7h2G4hbh11')"
          maxlength="9"
          min="0"
          max="180"
          step="0.000001"
          v-model="upperLeft.lat"
        />
      </div>
      <div class="col-5">
        <label for="inputRadiusLng" class="col-form-label">{{
          $t("label_longitude")
        }}</label>
        <InputText
          type="number"
          id="i7h2G4hbh12"
          class="form-control new-style"
          @input="inputUpperLeftChange('i7h2G4hbh12')"
          maxlength="10"
          min="0"
          max="180"
          step="0.000001"
          v-model="upperLeft.lng"
        />
      </div>
    </div>
    <div class="row no-gutters corner-group">
      <label class="col-2 text-position">{{ $t("label_upper_right") }}</label>
      <div class="col-5">
        <label for="inputRadiuslat" class="col-form-label">{{
          $t("label_latitude")
        }}</label>
        <InputText
          type="number"
          id="i7h2G4hbh13"
          class="form-control new-style"
          @input="inputUpperRightChange('i7h2G4hbh13')"
          maxlength="9"
          min="0"
          max="180"
          step="0.000001"
          v-model="upperRight.lat"
        />
      </div>
      <div class="col-5">
        <label for="inputRadiusLng" class="col-form-label">{{
          $t("label_longitude")
        }}</label>
        <InputText
          type="number"
          id="i7h2G4hbh14"
          class="form-control new-style"
          @input="inputUpperRightChange('i7h2G4hbh14')"
          maxlength="10"
          min="0"
          max="180"
          step="0.000001"
          v-model="upperRight.lng"
        />
      </div>
    </div>
    <div class="row no-gutters corner-group">
      <label class="col-2 text-position">{{ $t("label_lower_left") }}</label>
      <div class="col-5">
        <label for="inputRadiuslat" class="col-form-label">{{
          $t("label_latitude")
        }}</label>
        <InputText
          type="number"
          id="i7h2G4hbh15"
          class="form-control new-style"
          @input="inputLowerLeftChange('i7h2G4hbh15')"
          maxlength="9"
          min="0"
          max="180"
          step="0.000001"
          v-model="lowerLeft.lat"
        />
      </div>
      <div class="col-5">
        <label for="inputRadiusLng" class="col-form-label">{{
          $t("label_longitude")
        }}</label>
        <InputText
          type="number"
          id="i7h2G4hbh16"
          class="form-control new-style"
          @input="inputLowerLeftChange('i7h2G4hbh16')"
          maxlength="10"
          min="0"
          max="180"
          step="0.000001"
          v-model="lowerLeft.lng"
        />
      </div>
    </div>
    <div class="row no-gutters corner-group">
      <label class="col-2 text-position">{{ $t("label_lower_right") }}</label>
      <div class="col-5">
        <label for="inputRadiuslat" class="col-form-label">{{
          $t("label_latitude")
        }}</label>
        <InputText
          type="number"
          id="i7h2G4hbh17"
          class="form-control new-style"
          @input="inputLowerRightChange('i7h2G4hbh17')"
          maxlength="9"
          min="0"
          max="180"
          step="0.000001"
          v-model="lowerRight.lat"
        />
      </div>
      <div class="col-5">
        <label for="inputRadiusLng" class="col-form-label">{{
          $t("label_longitude")
        }}</label>
        <InputText
          type="number"
          id="i7h2G4hbh18"
          class="form-control new-style"
          @input="inputLowerRightChange('i7h2G4hbh18')"
          maxlength="10"
          min="0"
          max="180"
          step="0.000001"
          v-model="lowerRight.lng"
        />
      </div>
    </div>
  </div>
</template>
<script>
import InputText from "@/components/commons/inputtext/InputText";
import InputNumber from "@/components/commons/inputnumber/InputNumber";
import CommonAccordion from "@/components/commons/accordion/Accordion2";
export default {
  components: {
    InputText,
    CommonAccordion,
    InputNumber,
  },
  props: {
    propCoordinates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      upperLeft: {
        lat: null,
        lng: null,
      },
      upperRight: {
        lat: null,
        lng: null,
      },
      lowerLeft: {
        lat: null,
        lng: null,
      },
      lowerRight: {
        lat: null,
        lng: null,
      },
    };
  },
  created() {
    if (this.propCoordinates) {
      if (
        this.propCoordinates[0] &&
        Object.keys(this.propCoordinates[0]).length > 0
      ) {
        this.upperLeft.lat = this.propCoordinates[0].lat
          ? this.propCoordinates[0].lat
          : null;
        this.upperLeft.lng = this.propCoordinates[0].lng
          ? this.propCoordinates[0].lng
          : null;
      }
      if (
        this.propCoordinates[1] &&
        Object.keys(this.propCoordinates[1]).length > 0
      ) {
        this.upperRight.lat = this.propCoordinates[1].lat
          ? this.propCoordinates[1].lat
          : null;
        this.upperRight.lng = this.propCoordinates[1].lng
          ? this.propCoordinates[1].lng
          : null;
      }

      if (
        this.propCoordinates[3] &&
        Object.keys(this.propCoordinates[3]).length > 0
      ) {
        this.lowerLeft.lat = this.propCoordinates[3].lat
          ? this.propCoordinates[3].lat
          : null;
        this.lowerLeft.lng = this.propCoordinates[3].lng
          ? this.propCoordinates[3].lng
          : null;
      }
      if (
        this.propCoordinates[2] &&
        Object.keys(this.propCoordinates[2]).length > 0
      ) {
        this.lowerRight.lat = this.propCoordinates[2].lat
          ? this.propCoordinates[2].lat
          : null;
        this.lowerRight.lng = this.propCoordinates[2].lng
          ? this.propCoordinates[2].lng
          : null;
      }
    }
  },
  methods: {
    inputUpperLeftChange(id) {
      let input = document.getElementById(id);
      let value = input.value;
      if (value.length > input.maxLength) {
        input.value = value.substring(0, input.maxLength);
      }
      this.$emit("inputUpperLeftChange", {
        lat: this.upperLeft.lat ? parseFloat(this.upperLeft.lat) : null,
        lng: this.upperLeft.lat ? parseFloat(this.upperLeft.lng) : null,
      });
    },
    inputUpperRightChange(id) {
      let input = document.getElementById(id);
      let value = input.value;
      if (value.length > input.maxLength) {
        input.value = value.substring(0, input.maxLength);
      }
      this.$emit("inputUpperRightChange", {
        lat: this.upperRight.lat ? parseFloat(this.upperRight.lat) : null,
        lng: this.upperRight.lat ? parseFloat(this.upperRight.lng) : null,
      });
    },
    inputLowerLeftChange(id) {
      let input = document.getElementById(id);
      let value = input.value;
      if (value.length > input.maxLength) {
        input.value = value.substring(0, input.maxLength);
      }
      this.$emit("inputLowerLeftChange", {
        lat: this.lowerLeft.lat ? parseFloat(this.lowerLeft.lat) : null,
        lng: this.lowerLeft.lat ? parseFloat(this.lowerLeft.lng) : null,
      });
    },
    inputLowerRightChange(id) {
      let input = document.getElementById(id);
      let value = input.value;
      if (value.length > input.maxLength) {
        input.value = value.substring(0, input.maxLength);
      }
      this.$emit("inputLowerRightChange", {
        lat: this.lowerRight.lat ? parseFloat(this.lowerRight.lat) : null,
        lng: this.lowerRight.lat ? parseFloat(this.lowerRight.lng) : null,
      });
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    locate() {
      if (this.lang === "en") {
        return "en-US";
      } else {
        return "vi-VN";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.coors_polygon {
  padding-right: 0.5rem;
  color: $text-color;
  h6.title {
    margin: 0;
    font-size: 14px;
    color: $text-color;
    font-weight: 500;
  }
  .row {
    margin-bottom: 0.5rem;
    &.corner-group {
      label {
        &.text-position {
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }
      }
      .col-5 {
        padding-right: 0.25rem;
        &:last-child {
          padding-left: 0.25rem;
          padding-right: 0;
        }
      }
    }
  }
}
</style>
