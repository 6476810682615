<template>
  <div class="datatable">
    <ContextMenu :model="menuModel" ref="cmShare4MeDatatable" />
    <DataTable
      :value="propItems"
      :loading="propLoading"
      ref="dt"
      :resizableColumns="true"
      columnResizeMode="expand"
      selectionMode="single"
      dataKey="id"
      contextMenu
      :selection.sync="selected"
      @row-contextmenu="onRowContextMenu"
      :rowClass="rowClass"
      :scrollable="true"
      :virtualScroll="true"
      @virtual-scroll="onVirtualScroll"
      :scrollHeight="propTableHeight - (propItems.length ? 40 : 0) - 38 + 'px'"
    >
      <template #header v-if="propItems.length">
        <div class="text-left">
          <div v-if="propItems.length" class="font-weight-normal">
            {{
              $t("label_show_from_to", null, {
                from: 1,
                to: propItems.length,
                total: propTotal,
              })
            }}
          </div>
        </div>
      </template>
      <template #empty v-if="propItems && propItems.length === 0">
        <span class="text-center">{{ $t("label_no_data") }}</span>
      </template>
      <Column
        header="No"
        headerStyle="width: 3rem; text-align: center!important"
        bodyStyle="text-align: center;"
      >
        <template #body="slotProps">
          {{ slotProps.index + 1 }}
        </template></Column
      >
      <Column
        field="description"
        :header="$t('label_table_content_share')"
        headerStyle="width: 32rem"
        bodyStyle="width: 32rem"
      ></Column>
      <Column
        :header="$t('label_image_name')"
        headerStyle="width: 32rem"
        bodyStyle="width: 32rem"
      >
        <template #body="slotProps">
          <div :title="slotProps.data.image ? slotProps.data.image.name : ''">
            {{ slotProps.data.image ? slotProps.data.image.name : "" }}
          </div>
        </template></Column
      >
      <Column
        :header="$t('label_table_sharing_time')"
        headerStyle="width: 12rem"
        bodyStyle="width: 12rem"
      >
        <template #body="slotProps">
          {{
            formatUnixDateTime2String(
              slotProps.data.created_at ? slotProps.data.created_at : ""
            )
          }}
        </template>
      </Column>
      <Column
        :header="$t('label_table_expired_time')"
        headerStyle="width: 12rem"
        bodyStyle="width: 12rem"
      >
        <template #body="slotProps">
          {{ formatUnixDateTime2String(slotProps.data.expired_time) }}
        </template>
      </Column>
      <Column
        field="download_number"
        :header="$t('label_table_remaining_downloads')"
        headerStyle="width: 12rem"
        bodyStyle="width: 12rem"
      >
      </Column>
      <Column
        headerStyle="width: 6rem"
        bodyStyle="width:  6rem"
        :headerClass="'p-frozen-column'"
        :bodyClass="'p-frozen-column'"
      >
        <template #body="slotProps">
          <Button
            v-if="slotProps.data.image"
            title="Metadata"
            icon="fas fa-list-alt"
            class="p-button-sm btn-geostore2 mr-1"
            @click="showDialogMetadata(slotProps.data)"
          />
          <Button
            :title="$t('label_download')"
            :icon="
              propButtonDownload &&
              propButtonDownload.processing &&
              slotProps.data.id === propButtonDownload.id
                ? 'fas fa-spinner fa-spin'
                : 'fas fa-download'
            "
            class="p-button-sm btn-transparent2"
            @click="downloadImage(slotProps.data)"
            v-if="!disableButton(slotProps.data)"
            :disabled="
              propButtonDownload &&
              propButtonDownload.disable &&
              slotProps.data.id === propButtonDownload.id
                ? propButtonDownload.disable
                : false
            "
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import Button from "@/components/commons/button/Button";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
import moment from "moment";
export default {
  components: {
    DataTable,
    Column,
    Button,
    ContextMenu,
  },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propLoading: {
      type: Boolean,
      default: false,
    },
    propTableHeight: {
      type: Number,
      default: 400,
    },
    propTotal: {
      type: Number,
      default: 0,
    },
    propButtonDownload: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selected: null,
      menuModel: [
        {
          label: "Metadata",
          icon: "fas fa-list-alt",
          command: () => this.showDialogMetadata(this.selected),
          visible: true,
        },
        {
          label: this.$t("label_download"),
          icon: "fas fa-download",
          command: () => this.downloadImage(this.selected),
          visible: true,
        },
      ],
    };
  },
  methods: {
    onVirtualScroll(e) {
      this.$emit("nextPage");
    },
    async onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.$emit("nextPage");
      }
    },
    showDialogMetadata(item) {
      this.$emit("showDialogMetadata", item.image);
    },
    async downloadImage(val) {
      if (val.download_number > 0) {
        let checkCancel = false;
        if (val.expired_time) {
          try {
            let expiredTime = new Date(val.expired_time);
            if (expiredTime.getTime() - new Date() < 0) {
              checkCancel = true;
              this.$emit(this.$t("message_file_down_expired"));
            }
          } catch (error) {}
        }
        if (!checkCancel) this.$emit("downloadItem", val);
      } else {
        this.$emit("message", this.$t("message_file_down_out_of_number"));
      }
    },
    onRowContextMenu(event) {
      if (this.selected && Object.keys(this.selected).length > 0) {
        this.$refs.cmShare4MeDatatable.show(event.originalEvent);
      }
    },
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
    rowClass(data) {
      let result = null;
      if (data && Object.keys(data).length > 0) {
        if (data.expired_time) {
          if (new Date(data.expired_time) - new Date() < 0) {
            result = "bg-secondary text-white";
          } else if (
            new Date(data.expired_time) - new Date() <
            3 * 24 * 60 * 60 * 1000
          ) {
            result = "bg-gray-400";
          }
        }
        try {
          if (
            data.download_number != null &&
            data.download_number != undefined &&
            parseInt(data.download_number) <= 0
          ) {
            result = "bg-secondary text-white";
          }
        } catch (error) {}
      }
      return result;
    },
    disableButton(data) {
      let result = false;
      if (data && Object.keys(data).length > 0) {
        if (data.expired_time) {
          if (new Date(data.expired_time) - new Date() < 0) {
            result = true;
          }
        }
        try {
          if (
            data.download_number != null &&
            data.download_number != undefined &&
            parseInt(data.download_number) <= 0
          ) {
            result = true;
          }
        } catch (error) {}
      }
      return result;
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.shared4me__datatable {
  .p-datatable {
    border-radius: 0.25rem;
    .p-datatable-header {
      background-color: white !important;
      border: 1px solid white;
    }
    .p-datatable-thead {
      tr {
        border-bottom: 1px solid #eff2f5;
        th {
          background: white;
          border: none;
          color: #a1a5b7;
        }
      }
    }
    .p-datatable-tbody {
      tr {
        border-bottom: 1px solid #eff2f5;
        &.p-highlight {
          background: $background-color-2 !important;
          td {
            color: white;
          }
        }
        td {
          border: none;
          color: $text-color;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
