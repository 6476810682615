<template>
  <div id="cesiumContainer"></div>
</template>
<script>
import axios from "axios";
import { getGroundTracks } from "tle.js";
import { getLatLngObj } from "tle.js";
import {
  getInclination,
  getPerigee,
  getMeanAnomaly,
  getRightAscension,
} from "tle.js";
import { getSatelliteInfo } from "tle.js/dist/tlejs.cjs";
import { spymesatApi } from "@/utils/commons/environment";
export default {
  data() {
    return {
      viewer: Object,
      scene: Object,
      satellites: [],
      counter: 0,
      startTime: new Date(),
    };
  },
  async created() {},
  async mounted() {
    this.initMap();
    this.getSatellites();
  },
  methods: {
    initMap() {
      var vm = this;
      Cesium.Ion.defaultAccessToken =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJhMDUyYzNjZC1jNmRhLTQ4NjgtODZhMC1lMTA5NWE3ODYxNjEiLCJpZCI6NDk5OTksImlhdCI6MTY1MTYzMzk0N30.XObuMJ9H-NZi8UX4G5uq2qZsM6S_vmUorNg6kbuxWcw";

      // Initialize the Cesium Viewer in the HTML element with the `cesiumContainer` ID.

      //Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
      this.viewer = new Cesium.Viewer("cesiumContainer", {
        terrainProvider: Cesium.createWorldTerrain(),
        // imageryProvider: new Cesium.OpenStreetMapImageryProvider({
        //   url: "https://tile.f4map.com/tiles/f4_3d/",
        //   fileExtension: "png",
        // }),
        // mapProjection: new Cesium.WebMercatorProjection(),
        // timeline: true,
        // animation: true,
        // shouldAnimate: false,
        // baseLayerPicker: false,
        // baseLayerPicker: false,
        // selectionIndicator: false, //Disable selection indicator
        // geocoder: false,
        // homeButton: true,
        // sceneModePicker: true,
        navigationHelpButton: false,
        animation: true,
        creditsDisplay: false,
        timeline: true,
        fullscreenButton: true,
      });
      this.viewer.entities.removeAll();

      this.scene = this.viewer.scene;
      this.viewer.scene.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(
          105.85195880735967,
          21.031185156058473,
          18000000
        ),
        orientation: {
          heading: 6,
        },
      });
      const timeStepInSeconds = 3600;
      const totalSeconds = timeStepInSeconds * 60;
      const start = Cesium.JulianDate.fromDate(this.startTime); // Cesium.JulianDate.now();
      const stop = Cesium.JulianDate.addSeconds(
        start,
        totalSeconds,
        new Cesium.JulianDate()
      );
      this.viewer.clock.startTime = start.clone();
      //this.viewer.clock.stopTime = stop.clone();
      this.viewer.clock.currentTime = start.clone();
      this.viewer.timeline.zoomTo(start, stop);
      // Speed up the playback speed 50x.
      this.viewer.clock.multiplier = 1;

      this.viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;
      this.viewer.clock.clockStep = Cesium.ClockStep.SYSTEM_CLOCK_MULTIPLIER;
      this.viewer.clock.canAnimate = true;
      this.viewer.clock.shouldAnimate = true;
      this.viewer.clock.onTick.addEventListener(function (clock) {});
      const bottomContainer = document.querySelector(
        "div.cesium-viewer-bottom"
      );
      try {
        if (bottomContainer) bottomContainer.remove();
      } catch (error) {}
      const cesiumViewerTimelineContainer = document.querySelector(
        "div.cesium-viewer-timelineContainer"
      );
    },
    async createModel(url, data) {
      var vm = this;
      let nowTime = new Date(vm.viewer.clock.currentTime.toString()).getTime();
      let times = []; // array time

      let arrayTimes = [-5, 0, 5, 10, 15];
      for (let i = 0; i < arrayTimes.length; i++) {
        times.push(nowTime + arrayTimes[i] * 60 * 1000);
      }
      let coordinates = [];

      var vm = this;
      let tleStr = "";
      tleStr += data.TLE.Name + "\n";
      tleStr += data.TLE.LineOne + "\n";
      tleStr += data.TLE.LineTwo;

      const observerLat = 0;
      const observerLng = 0;

      let dataTle = getSatelliteInfo(tleStr, null, observerLat, observerLng, 0);
      if (
        dataTle &&
        (isNaN(dataTle.lat) ||
          isNaN(dataTle.lng) ||
          !dataTle.lat ||
          !dataTle.lng)
      ) {
        return;
      }
      console.log(dataTle);
      if (dataTle && Object.keys(dataTle).length > 0) {
        for (let i = 0; i < times.length; i++) {
          coordinates.push(getLatLngObj(tleStr, times[i]));
        }
        var position = Cesium.Cartesian3.fromDegrees(
          dataTle.lng,
          dataTle.lat,
          dataTle.height * 1000
        );
        var heading;
        //heading = Cesium.Math.toRadians(360 - getInclination(tleStr));
        if (coordinates[0].lat - coordinates[coordinates.length - 1].lat > 0)
          heading = Cesium.Math.toRadians(getInclination(tleStr));
        else heading = Cesium.Math.toRadians(360 - getInclination(tleStr));
        var pitch = 0;
        var roll = 0;
        var hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);
        var orientation = Cesium.Transforms.headingPitchRollQuaternion(
          position,
          hpr
        );
        const isConstant = false;
        var entity = this.viewer.entities.add({
          name: data.Name,
          description: data.About,
          position: new Cesium.CallbackProperty(function (time, result) {
            // Get position
            let now = new Date(
              vm.viewer.clock.currentTime.toString()
            ).getTime();
            let d2 = getLatLngObj(tleStr, now);
            return Cesium.Cartesian3.fromDegrees(
              d2.lng,
              d2.lat,
              dataTle.height * 1000
            );
          }),
          orientation: orientation,
          model: {
            uri: url,
            minimumPixelSize: 40,
            //maximumScale: 400000,
            scale: 2,
          },
          id: "model_" + data._id,
        });
        //return;
        setTimeout(() => {
          var line = vm.viewer.entities.add({
            id: "line_" + data._id,
            name: data.Name + " Orbit",
            polyline: {
              // This callback updates positions each frame.
              positions: new Cesium.CallbackProperty(function (time, result) {
                let now = new Date(
                  vm.viewer.clock.currentTime.toString()
                ).getTime();
                let results = [];
                for (let i = 0; i < arrayTimes.length; i++) {
                  // Get position
                  let d = getLatLngObj(tleStr, now + arrayTimes[i] * 60 * 1000);
                  results.push(
                    Cesium.Cartesian3.fromDegrees(
                      d.lng,
                      d.lat,
                      dataTle.height * 1000
                    )
                  );
                }
                return results;
              }, isConstant),

              width: 2,
              material: Cesium.Color.YELLOW,
              // material: new Cesium.PolylineOutlineMaterialProperty({
              //   color: Cesium.Color.fromCssColorString("#00FF00"),
              //   outlineWidth: 0,
              // }),
            },
          });
        }, 3500);
      }
    },
    reloadPage() {
      if (this.viewer && Object.keys(this.viewer).length > 0) {
        this.viewer.entities.removeAll();
        this.getSatellites();
      }
    },
    updateModel(data, timeStr) {
      return;
      var vm = this;
      let tleStr = "";
      tleStr += data.TLE.Name + "\n";
      tleStr += data.TLE.LineOne + "\n";
      tleStr += data.TLE.LineTwo;
      let dateNow = new Date(timeStr).getTime();
      const observerLat = 0;
      const observerLng = 0;
      let dataTle = getSatelliteInfo(
        tleStr,
        dateNow,
        observerLat,
        observerLng,
        0
      );
      if (dataTle && Object.keys(dataTle).length > 0) {
        //update line
        let index = this.satellites.findIndex((x) => x._id === data._id);
        if (index >= 0) {
          this.satellites[index].coordinates.shift();
          this.satellites[index].coordinates.push(
            getLatLngObj(tleStr, dateNow + 10 * 74 * 1000)
          );
          let entityLine = this.viewer.entities.getById("line_" + data._id);

          //let cartesians = this.satellites[index].cartesians;
          if (entityLine && Object.keys(entityLine).length > 0) {
            this.satellites[index].cartesians.shift();
            this.satellites[index].cartesians.push(
              Cesium.Cartesian3.fromDegrees(
                this.satellites[index].coordinates[
                  this.satellites[index].coordinates.length - 1
                ].lng,
                this.satellites[index].coordinates[
                  this.satellites[index].coordinates.length - 1
                ].lat,
                dataTle.height * 1000
              )
            );
            this.viewer.entities.remove(entityLine);
            vm.viewer.entities.add({
              polyline: {
                positions: this.satellites[index].cartesians,
                width: 2,
                material: Cesium.Color.YELLOW,
              },
              id: "line_" + data._id,
            });
            //entityLine.polyline.positions = this.satellites[index].cartesians;

            // entityLine.polyline = {
            //   positions: this.satellites[index].cartesians,
            //   width: 2,
            //   material: Cesium.Color.YELLOW,
            // };
          }
          //update image
          let entity = this.viewer.entities.getById(data._id);
          if (entity && Object.keys(entity).length > 0) {
            if (dataTle && Object.keys(dataTle).length > 0) {
              var position = Cesium.Cartesian3.fromDegrees(
                dataTle.lng,
                dataTle.lat,
                dataTle.height * 1000
              );
              var heading;

              if (
                this.satellites[index].coordinates[0].lat -
                  this.satellites[index].coordinates[
                    this.satellites[index].coordinates.length - 1
                  ].lat >
                0
              )
                heading = Cesium.Math.toRadians(getInclination(tleStr));
              else
                heading = Cesium.Math.toRadians(360 - getInclination(tleStr));
              var pitch = 0;
              var roll = 0;
              var hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);
              var orientation = Cesium.Transforms.headingPitchRollQuaternion(
                position,
                hpr
              );
              var property = new Cesium.SampledPositionProperty();
              property.addSample(Cesium.JulianDate.now(), position);
              // entity.position.addSample(
              //   Cesium.JulianDate.fromIso8601(timeStr),
              //   Cesium.Cartesian3.fromDegrees(
              //     dataTle.lng,
              //     dataTle.lat,
              //     dataTle.height * 1000
              //   )
              // );
              entity.position.addSample(Cesium.JulianDate.now(), position); // = property;
              // entity.orientation = new Cesium.VelocityOrientationProperty(
              //   property
              // );
            }
          }
        }
      }
    },
    updateLocation(timeStr) {
      if (this.satellites && this.satellites.length > 0) {
        for (let i = 0; i < this.satellites.length; i++) {
          this.updateModel(this.satellites[i], timeStr);
        }
      }
    },
    async getSatellites() {
      axios({
        url: spymesatApi,
        method: "get",
        headers: {
          //"Access-Control-Allow-Credentials": true,
          //"Access-Control-Allow-Headers": "*",
        },
        data: null,
      })
        .then((res) => {
          if (res && res.status === 200) {
            if (res.data && res.data && res.data.result.length) {
              this.satellites = res.data.result;
              for (let i = 0; i < res.data.result.length; i++) {
                this.createModel(
                  "/img/icons/Satellite.glb",
                  res.data.result[i]
                );
              }
            }
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style lang="scss" scoped>
#cesiumContainer {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
}
</style>
