var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data && Object.keys(_vm.data).length)?_c('div',{staticClass:"item-detail",on:{"mouseover":function($event){return _vm.mouseOverItem(_vm.data)},"mouseleave":function($event){return _vm.mouseLeaveItem(_vm.data)}}},[_c('div',[_c('strong',{attrs:{"title":_vm.data.properties &&
        _vm.data.properties.acquisitionIdentifier &&
        _vm.data.properties.acquisitionIdentifier.length > 54
          ? _vm.data.properties.acquisitionIdentifier
          : ''}},[_vm._v(_vm._s(_vm.data.properties.acquisitionIdentifier && _vm.data.properties.acquisitionIdentifier.length > 54 ? _vm.data.properties.acquisitionIdentifier.substring(0, 54) + "..." : _vm.data.properties.acquisitionIdentifier)+" ")])]),_c('div',{staticClass:"media"},[_c('icon-preview',{attrs:{"propId":_vm.data.properties && _vm.data.properties.activityId
          ? _vm.data.properties.activityId
          : null,"prop-src":_vm.data._links && _vm.data._links.thumbnail && _vm.data._links.thumbnail.href
          ? _vm.data._links.thumbnail.href
          : null},on:{"initSuccessIconImage":function($event){return _vm.initSuccessIconImage(_vm.data, $event)},"showPreviewImage":function($event){return _vm.showPreviewImage(_vm.data)}}}),_c('div',{staticClass:"media-body"},[_c('ul',[_c('li',{staticClass:"mt-1"},[_c('div',{},[_c('span',[_vm._v("Order date: "),_c('strong',[_vm._v(_vm._s(_vm.propItem.createdAt ? _vm.propItem.createdAt : ""))])]),_c('span',{staticClass:"pl-2 pr-2"},[_vm._v("|")]),_c('span',[_vm._v(_vm._s(_vm.$t("label_satellite"))+": "),_c('strong',[_vm._v(_vm._s(_vm.data.properties && _vm.data.properties.platform ? _vm.data.properties.platform : ""))])])])]),_c('li',{staticClass:"mt-1"},[_c('div',{},[_c('span',[_vm._v("Acquisition date: "),_c('strong',[_vm._v(_vm._s(_vm.data.properties && _vm.data.properties.acquisitionDate ? _vm.data.properties.acquisitionDate : ""))])])])]),_c('ItemMetadata',{attrs:{"prop-item":_vm.data}}),(_vm.message)?_c('li',{staticClass:"mt-1"},[_c('div',{},[_c('span',{staticClass:"text-danger"},[_vm._v("Message: "),_c('strong',[_vm._v(_vm._s(_vm.message))])])])]):_vm._e()],1),_c('div',{staticClass:"actions text-right mt-1"},[(_vm.downloadUrl)?_c('button',{staticClass:"btn btn-transparent2 btn-sm float-left ml-0",attrs:{"type":"button","title":'Download'},on:{"click":_vm.downloadImage}},[_c('i',{staticClass:"fas fa-download"}),_vm._v(" Download ")]):_vm._e(),_c('button',{staticClass:"btn btn-transparent2 btn-sm mr-1",attrs:{"type":"button","title":'Metadata'},on:{"click":function($event){$event.stopPropagation();return _vm.showMetadata(_vm.data)}}},[_c('i',{staticClass:"fas fa-eye"})]),_c('button',{staticClass:"btn btn-transparent2 btn-sm ml-auto",attrs:{"type":"button","title":_vm.$t('tooltip_pan_2_coordinate'),"disabled":!_vm.data.geometry},on:{"click":function($event){$event.stopPropagation();return _vm.onPanCoordinate(_vm.data)}}},[_c('i',{staticClass:"fas fa-crosshairs"})])])])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }