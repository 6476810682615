<template>
  <CommonAccordion
    :propTitle="$t('title_search')"
    @accordionHeaderClick="accordionHeaderClick"
  >
    <div slot="content">
      <div class="form-search">
        <div class="form-row no-gutters">
          <div class="col-3">
            <Dropdown
              style="width: 100%"
              v-model="search.status"
              :showClear="true"
              :filter="true"
              :options="status"
              optionValue="id"
              optionLabel="label"
              datakey="id"
              class="new-style"
              :placeholder="$t('placeholder_select_status')"
            >
            </Dropdown>
          </div>
          <div class="col-2">
            <button
              type="button"
              class="btn btn-geostore2 btn-sm btn-w120-h36"
              @click="onSearch"
              :disabled="propButtons.filter.disabled"
            >
              <i
                :class="
                  propButtons.filter.processing
                    ? 'fas fa-spinner fa-spin'
                    : 'fas fa-search'
                "
              ></i
              >&nbsp;{{ $t("button_text_search") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion3";
import Dropdown from "@/components/commons/dropdown/Dropdown";
export default {
  components: {
    CommonAccordion,
    Dropdown,
  },
  props: {
    propSearch: {
      type: Object,
      default: () => {
        return {
          status: null,
        };
      },
    },
    propButtons: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search: {
        status: null,
      },
      status: [
        {
          id: "queued",
          label: "Queued",
        },
        {
          id: "running",
          label: "Running",
        },
        {
          id: "success",
          label: "Success",
        },
        {
          id: "partial",
          label: "Partial",
        },
        {
          id: "failed",
          label: "Failed",
        },
        {
          id: "cancelled",
          label: "Cancelled",
        },
      ],
    };
  },
  created() {
    this.search.status = this.propSearch.status;
  },
  watch: {
    propSearch: {
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          this.search.status = val.status ? val.status : null;
        }
      },
      immediate: true,
    },
  },
  methods: {
    accordionHeaderClick() {
      this.$emit("accordionHeaderClick");
    },
    onSearch() {
      this.$emit("onSearch", this.search.status);
    },
  },
};
</script>
<style lang="scss" scoped></style>
