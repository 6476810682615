<template>
  <div class="integrate-content">
    <div class="integrate__dialog">
      <DialogOneAtlasMetadata
        v-if="dlgOneAtlasMetadata.show"
        :prop-style="{ width: dlgOneAtlasMetadata.width }"
        :prop-show="dlgOneAtlasMetadata.show"
        :prop-title="dlgOneAtlasMetadata.title"
        :prop-item="dlgOneAtlasMetadata.value"
        @close="
          dlgOneAtlasMetadata.show = false;
          dlgOneAtlasMetadata.value = null;
        "
      />
    </div>
    <div class="integrate__datatable subscriptions">
      <IntegrateDataTableSubscriptions
        :prop-items="subscriptions.items"
        :prop-loading="subscriptions.loading"
        :prop-table="subscriptions.table"
        :prop-total="subscriptions.totalResults"
      />
    </div>
    <div class="integrate__capacity">
      <Capacity :propSubscription="subscriptions.table.selected" />
    </div>
    <div class="integrate__list orders">
      <OrdersList
        :propInprogressItems="myOrder.processing.items"
        :propInprogressLength="myOrder.processing.totalResults"
        :propDeliveredItems="myOrder.delivered.items"
        :propDeliveredLength="myOrder.delivered.totalResults"
        :propErrorsItems="myOrder.errors.items"
        :propErrorsLength="myOrder.errors.totalResults"
        :propRejectedItems="myOrder.rejected.items"
        :propRejectedLength="myOrder.rejected.totalResults"
        @actionClick="actionClick"
        @loadMore="onLoadMore"
        @downloadImage="downloadImage"
      />
    </div>
  </div>
</template>
<script>
import DialogOneAtlasMetadata from "@/components/commons/metadata/MetadataOneAtlas";
import IntegrateDataTable from "./list/DataTable";
import IntegrateDataTableSubscriptions from "./list/DataTableSubscriptions";
import OrdersList from "./list/Orders.vue";
import Capacity from "./list/Capacity.vue";
import oneAtlasFunc from "@/utils/functions/oneatlas";
import cryptoFunc from "@/utils/functions/crypto";
export default {
  components: {
    DialogOneAtlasMetadata,
    IntegrateDataTable,
    IntegrateDataTableSubscriptions,
    OrdersList,
    Capacity,
  },
  props: {},
  data() {
    return {
      message: [],
      myOrder: {
        loading: false,
        page_number: 0,
        page_size: 10,
        total_page: 0,
        total_records: 0,
        itemsPerPage: 10,
        startIndex: 0,
        totalResults: 0,
        items: [],
        processing: {
          // ordered status
          items: [],
          is_bottom: false,
          loading: false,
          page_number: 0,
          page_size: 10,
          total_page: 0,
          total_records: 0,
          itemsPerPage: 10,
          startIndex: 0,
          totalResults: 0,
        },
        delivered: {
          items: [],
          is_bottom: false,
          loading: false,
          page_number: 0,
          page_size: 10,
          total_page: 0,
          total_records: 0,
          itemsPerPage: 10,
          startIndex: 0,
          totalResults: 0,
        },
        errors: {
          items: [],
          is_bottom: false,
          loading: false,
          page_number: 0,
          page_size: 10,
          total_page: 0,
          total_records: 0,
          itemsPerPage: 10,
          startIndex: 0,
          totalResults: 0,
        },
        rejected: {
          items: [],
          is_bottom: false,
          loading: false,
          page_number: 0,
          page_size: 10,
          total_page: 0,
          total_records: 0,
          itemsPerPage: 10,
          startIndex: 0,
          totalResults: 0,
        },
        _link: null,
        search: {
          status: null,
          page: 0,
          size: 10,
        },
        table: {
          selected: null,
          height: 256,
        },
        is_bottom: false,
        url: null,
      },
      subscriptions: {
        loading: false,
        page_number: 0,
        page_size: 10,
        total_page: 0,
        total_records: 0,
        itemsPerPage: 10,
        startIndex: 0,
        totalResults: 0,
        count_limit_reached: false,
        items: [],
        table: {
          selected: null,
          height: 256,
        },
        is_bottom: false,
        url: null,
      },

      buttons: {
        filter: {
          disabled: false,
          processing: false,
          visible: true,
        },
        detail: {
          disabled: false,
          processing: false,
        },
        cancelOrder: {
          disabled: false,
          processing: false,
        },
        download: {
          disabled: false,
          processing: false,
          ids: [],
        },
      },
      dlgOneAtlasMetadata: {
        show: false,
        title: "Metadata",
        value: null,
        width: "480px",
      },
    };
  },
  async created() {
    setTimeout(() => {
      this.$emit("initBreadcrumb", [
        "menu_sidebar_integrate_data",
        "menu_sidebar_integrate_oneatlas",
      ]);
    }, 200);

    if (!this.isAdmin) return;
    var vm = this;
    if (vm.documentWidth < 576) {
      this.dlgOneAtlasMetadata.width = this.documentWidth + "px";
    } else if (vm.documentWidth >= 576 && vm.documentWidth < 768) {
      this.dlgOneAtlasMetadata.width = "540px";
    } else if (vm.documentWidth >= 768 && vm.documentWidth < 992) {
      this.dlgOneAtlasMetadata.width = "720px";
    } else {
      this.dlgOneAtlasMetadata.width = "960px";
    }
    if (this.$store.getters.checkOneAtlasTokenExpired) {
      this.$store.dispatch("clearOneAtlasToken");
      let r = await this.getOneAtlasToken();
      if (!r) return;
    }
    if (this.oneAtlasData || Object.keys(this.oneAtlasData).length === 0) {
      let r = await this.getOneAtlasUserInfo();

      if (!r) return;
      else {
        if (
          r.contract &&
          r.contract._links &&
          Object.keys(r.contract._links).length > 0
        ) {
          this.subscriptions.url =
            r.contract._links.subscriptions &&
            r.contract._links.subscriptions.href
              ? r.contract._links.subscriptions.href
              : null;
          this.myOrder.url =
            r.contract._links.orders && r.contract._links.orders.href
              ? r.contract._links.orders.href
              : null;
        }
      }
    }
    this.getSubscriptions();
  },
  mounted() {},
  watch: {
    "subscriptions.table.selected"(val) {
      this.myOrder.is_bottom = false;
      this.myOrder.items = [];
      this.myOrder.page_number = 0;
      this.myOrder.itemsPerPage = 10;
      this.myOrder.startIndex = 0;
      this.myOrder.totalResults = 0;
      if (val && Object.keys(val).length > 0) {
        this.myOrder.url =
          val._links.orders && val._links.orders.href
            ? val._links.orders.href
            : null;
      }
      this.myOrder.processing.page_number = 0;
      this.myOrder.delivered.page_number = 0;
      this.myOrder.errors.page_number = 0;
      this.myOrder.rejected.page_number = 0;
      this.myOrder.page_number = 0;
      this.myOrder.processing.is_bottom = false;
      this.myOrder.delivered.is_bottom = false;
      this.myOrder.errors.is_bottom = false;
      this.myOrder.rejected.is_bottom = false;
      this.myOrder.is_bottom = false;

      this.myOrder.processing.startIndex = 0;
      this.myOrder.delivered.startIndex = 0;
      this.myOrder.errors.startIndex = 0;
      this.myOrder.rejected.startIndex = 0;
      this.myOrder.startIndex = 0;

      this.myOrder.processing.totalResults = 0;
      this.myOrder.delivered.totalResults = 0;
      this.myOrder.errors.totalResults = 0;
      this.myOrder.rejected.totalResults = 0;
      this.myOrder.totalResults = 0;

      this.myOrder.processing.items = [];
      this.myOrder.delivered.items = [];
      this.myOrder.errors.items = [];
      this.myOrder.rejected.items = [];
      this.myOrder.items = [];

      this.getOrders("processing");
      this.getOrders("delivered");
      this.getOrders("error");
      this.getOrders("rejected");
    },
  },
  methods: {
    async actionClick(data) {
      if (data.code === "SHOW_METADATA_ONEATLAS") {
        this.dlgOneAtlasMetadata.value = data.val;
        this.dlgOneAtlasMetadata.show = true;
      }
    },
    accordionHeaderSearchClick() {},
    calcTableHeight() {
      let element = document.getElementById("elementSearch");
      return (
        this.documentHeight -
        3 * 0.5 * 16 -
        56 -
        (element ? element.offsetHeight : 0) -
        24 -
        2 * 0.5 * 16
      );
    },
    async downloadImage(data) {
      this.$store.dispatch("setSpinnerApp", {
        show: true,
      });
      try {
        let response = await oneAtlasFunc.downloadImage(
          data.url,
          this.$store.getters.getOneAtlasTokenType +
            " " +
            this.$store.getters.getOneAtlasToken
        );
        if (response && response.status === 200) {
          let contentType =
            response.headers && response.headers["content-type"]
              ? response.headers["content-type"]
              : "application/zip";
          let url,
            blob = new Blob([response.data], { type: contentType });
          let extension = "";
          try {
            extension = contentType.substring(
              contentType.indexOf("/") + 1,
              contentType.length
            );
          } catch (error2) {}
          if (blob.size === 0) {
            this.message.push(this.$t("message_file_dose_not_exist"));
          } else {
            try {
              if (!window.navigator.msSaveOrOpenBlob) {
                // BLOB NAVIGATOR
                url = window.URL.createObjectURL(blob);
              } else {
                // BLOB FOR EXPLORER 11
                url = window.navigator.msSaveOrOpenBlob(
                  blob,
                  (data.acquisitionIdentifier
                    ? data.acquisitionIdentifier
                    : new Date().getTime()) +
                    (extension ? "." + extension : ".zip")
                );
              }
              const linkdown = document.createElement("a");
              linkdown.href = url;
              linkdown.setAttribute(
                "download",
                (data.acquisitionIdentifier
                  ? data.acquisitionIdentifier
                  : new Date().getTime()) +
                  (extension ? "." + extension : ".zip")
              );
              document.body.appendChild(linkdown);
              linkdown.click();
              document.body.removeChild(linkdown);
              window.URL.revokeObjectURL(url);
            } catch (error3) {}
          }
        }
      } catch (error) {
        this.$store.dispatch("setSpinnerApp", {
          show: false,
        });
        if (error && error.response && error.response.status === 401) {
          this.$store.dispatch("clearOneAtlasToken");
          let r = await this.getOneAtlasToken();
          if (r) {
            r.expires_time =
              new Date().getTime() + (r.expires_in ? r.expires_in * 1000 : 0);
            await localStorage.setItem(
              "oneatlas-token",
              btoa(cryptoFunc.encrypt(JSON.stringify(r)).toString())
            );
            await this.$store.dispatch("setOneAtlasToken", r);
            this.downloadImage(url);
          }
        }
      }
      this.$store.dispatch("setSpinnerApp", {
        show: false,
      });
    },
    onLoadMore(status) {
      switch (status) {
        case "processing":
          if (this.myOrder.processing.is_bottom) return;
          this.myOrder.processing.page_number =
            this.myOrder.processing.page_number + 1;
          break;
        case "delivered":
          if (this.myOrder.delivered.is_bottom) return;
          this.myOrder.delivered.page_number =
            this.myOrder.delivered.page_number + 1;
          break;
        case "error":
          if (this.myOrder.errors.is_bottom) return;
          this.myOrder.errors.page_number = this.myOrder.errors.page_number + 1;
          break;
        case "rejected":
          if (this.myOrder.rejected.is_bottom) return;
          this.myOrder.rejected.page_number =
            this.myOrder.rejected.page_number + 1;
          break;
        default:
          if (this.myOrder.is_bottom) return;
          this.myOrder.page_number = this.myOrder.page_number + 1;
          break;
      }
      this.getOrders(status);
    },
    async getOrders(status, loading = false) {
      if (loading)
        this.$store.dispatch("setSpinnerApp", {
          show: loading,
        });
      this.myOrder.loading = true;
      try {
        if (!this.myOrder.is_bottom) {
          let params = {};
          params = {
            status: status,
          };
          switch (status) {
            case "processing":
              params.status = "ordered";
              params.page = this.myOrder.processing.page_number + 1;
              params.itemsPerPage = this.myOrder.processing.page_size;
              break;
            case "delivered":
              params.page = this.myOrder.delivered.page_number + 1;
              params.itemsPerPage = this.myOrder.delivered.page_size;
              break;
            case "error":
              params.page = this.myOrder.errors.page_number + 1;
              params.itemsPerPage = this.myOrder.errors.page_size;
              break;
            case "rejected":
              params.page = this.myOrder.rejected.page_number + 1;
              params.itemsPerPage = this.myOrder.rejected.page_size;
              break;
            default:
              params.page = this.myOrder.page_number;
              params.itemsPerPage = this.myOrder.page_size;
              break;
          }
          let response = await oneAtlasFunc.getOrders(
            params,
            this.myOrder.url,
            this.$store.getters.getOneAtlasTokenType +
              " " +
              this.$store.getters.getOneAtlasToken
          );
          if (response && response.status === 200) {
            switch (status) {
              case "processing":
                this.myOrder.processing.items = [
                  ...this.myOrder.processing.items,
                  ...(response.data && response.data.items
                    ? response.data.items
                    : []),
                ];
                this.myOrder.processing.itemsPerPage =
                  response.data && response.data.itemsPerPage
                    ? response.data.itemsPerPage
                    : 0;
                this.myOrder.processing.startIndex =
                  response.data && response.data.startIndex
                    ? response.data.startIndex
                    : 0;
                this.myOrder.processing.totalResults =
                  response.data && response.data.totalResults
                    ? response.data.totalResults
                    : 0;
                if (
                  this.myOrder.processing.items.length ===
                  (this.myOrder.processing.totalResults =
                    response.data && response.data.totalResults
                      ? response.data.totalResults
                      : 0)
                )
                  this.myOrder.processing.is_bottom = true;
                break;
              case "delivered":
                this.myOrder.delivered.items = [
                  ...this.myOrder.delivered.items,
                  ...(response.data && response.data.items
                    ? response.data.items
                    : []),
                ];
                this.myOrder.delivered.itemsPerPage =
                  response.data && response.data.itemsPerPage
                    ? response.data.itemsPerPage
                    : 0;
                this.myOrder.delivered.startIndex =
                  response.data && response.data.startIndex
                    ? response.data.startIndex
                    : 0;
                this.myOrder.delivered.totalResults =
                  response.data && response.data.totalResults
                    ? response.data.totalResults
                    : 0;
                if (
                  this.myOrder.delivered.items.length ===
                  (response.data && response.data.totalResults
                    ? response.data.totalResults
                    : 0)
                )
                  this.myOrder.delivered.is_bottom = true;
                break;
              case "error":
                this.myOrder.errors.items = [
                  ...this.myOrder.errors.items,
                  ...(response.data && response.data.items
                    ? response.data.items
                    : []),
                ];
                this.myOrder.errors.itemsPerPage =
                  response.data && response.data.itemsPerPage
                    ? response.data.itemsPerPage
                    : 0;
                this.myOrder.errors.startIndex =
                  response.data && response.data.startIndex
                    ? response.data.startIndex
                    : 0;
                this.myOrder.errors.totalResults =
                  response.data && response.data.totalResults
                    ? response.data.totalResults
                    : 0;
                if (
                  this.myOrder.errors.items.length ===
                  (response.data && response.data.totalResults
                    ? response.data.totalResults
                    : 0)
                )
                  this.myOrder.errors.is_bottom = true;
                break;
              case "rejected":
                this.myOrder.rejected.items = [
                  ...this.myOrder.rejected.items,
                  ...(response.data && response.data.items
                    ? response.data.items
                    : []),
                ];
                this.myOrder.rejected.itemsPerPage =
                  response.data && response.data.itemsPerPage
                    ? response.data.itemsPerPage
                    : 0;
                this.myOrder.rejected.startIndex =
                  response.data && response.data.startIndex
                    ? response.data.startIndex
                    : 0;
                this.myOrder.rejected.totalResults =
                  response.data && response.data.totalResults
                    ? response.data.totalResults
                    : 0;
                if (
                  this.myOrder.rejected.items.length ===
                  (response.data && response.data.totalResults
                    ? response.data.totalResults
                    : 0)
                )
                  this.myOrder.rejected.is_bottom = true;
                break;
              default:
                this.myOrder.items = [
                  ...this.myOrder.items,
                  ...(response.data && response.data.items
                    ? response.data.items
                    : []),
                ];
                this.myOrder.itemsPerPage =
                  response.data && response.data.itemsPerPage
                    ? response.data.itemsPerPage
                    : 0;
                this.myOrder.startIndex =
                  response.data && response.data.startIndex
                    ? response.data.startIndex
                    : 0;
                this.myOrder.totalResults =
                  response.data && response.data.totalResults
                    ? response.data.totalResults
                    : 0;
                if (
                  response.data &&
                  response.data.items &&
                  response.data.items.length < this.myOrder.page_size
                )
                  this.myOrder.is_bottom = true;
                break;
            }
          }
        }
        if (loading)
          this.$store.dispatch("setSpinnerApp", {
            show: false,
          });
      } catch (error) {
        this.myOrder.loading = false;
        if (loading)
          this.$store.dispatch("setSpinnerApp", {
            show: false,
          });
        if (error && error.response && error.response.status === 401) {
          this.$store.dispatch("clearOneAtlasToken");
          let r = await this.getOneAtlasToken();
          if (r) {
            r.expires_time =
              new Date().getTime() + (r.expires_in ? r.expires_in * 1000 : 0);
            await localStorage.setItem(
              "oneatlas-token",
              btoa(cryptoFunc.encrypt(JSON.stringify(r)).toString())
            );
            await this.$store.dispatch("setOneAtlasToken", r);
            this.getOrders(status, loading);
          }
        }
      }
      this.myOrder.loading = false;
    },
    async getSubscriptions(loading = false) {
      if (loading)
        this.$store.dispatch("setSpinnerApp", {
          show: loading,
        });
      this.subscriptions.loading = true;
      try {
        if (!this.subscriptions.is_bottom) {
          let params = {};
          params = {
            startIndex:
              this.subscriptions.page_number * this.subscriptions.page_size,
            itemsPerPage: this.subscriptions.page_size,
          };
          let response = await oneAtlasFunc.getSubscriptions(
            params,
            this.subscriptions.url,
            this.$store.getters.getOneAtlasTokenType +
              " " +
              this.$store.getters.getOneAtlasToken
          );
          if (response && response.status === 200) {
            this.subscriptions.items = [
              ...this.subscriptions.items,
              ...(response.data && response.data.items
                ? response.data.items
                : []),
            ];
            this.subscriptions.itemsPerPage =
              response.data && response.data.itemsPerPage
                ? response.data.itemsPerPage
                : 0;
            this.subscriptions.startIndex =
              response.data && response.data.startIndex
                ? response.data.startIndex
                : 0;
            this.subscriptions.totalResults =
              response.data && response.data.totalResults
                ? response.data.totalResults
                : 0;
            if (
              response.data &&
              response.data.items &&
              response.data.items.length < this.subscriptions.page_size
            )
              this.subscriptions.is_bottom = true;
            if (response.data.items && response.data.items.length > 0)
              this.subscriptions.table.selected = response.data.items[0];
          }
        }
        if (loading)
          this.$store.dispatch("setSpinnerApp", {
            show: false,
          });
      } catch (error) {
        this.subscriptions.loading = false;
        console.log(error);
        if (loading)
          this.$store.dispatch("setSpinnerApp", {
            show: false,
          });
        if (error && error.response && error.response.status === 401) {
          this.$store.dispatch("clearOneAtlasToken");
          let r = await this.getOneAtlasToken();
          if (r) {
            r.expires_time =
              new Date().getTime() + (r.expires_in ? r.expires_in * 1000 : 0);
            await localStorage.setItem(
              "oneatlas-token",
              btoa(cryptoFunc.encrypt(JSON.stringify(r)).toString())
            );
            await this.$store.dispatch("setOneAtlasToken", r);
            this.getSubscriptions(loading);
          }
        }
      }
      this.subscriptions.loading = false;
    },
    async getOneAtlasUserInfo() {
      var vm = this;
      return new Promise(async function (resolve, reject) {
        try {
          let response = await oneAtlasFunc.getUserMe(
            vm.$store.getters.getOneAtlasTokenType +
              " " +
              vm.$store.getters.getOneAtlasToken
          );
          if (response && response.status === 200) {
            await vm.$store.dispatch("setOneAtlasData", response.data);
            resolve(response.data);
          } else return resolve(null);
        } catch (error) {
          console.log(error);
          return resolve(null);
        }
      });
    },
    async getOneAtlasToken() {
      var vm = this;
      return new Promise(async function (resolve, reject) {
        try {
          let res = await oneAtlasFunc.getAccessToken();
          if (res && res.status === 200) {
            res.data.expires_time =
              new Date().getTime() +
              (res.data.expires_in ? res.data.expires_in * 1000 : 0);
            await localStorage.setItem(
              "oneatlas-token",
              btoa(cryptoFunc.encrypt(JSON.stringify(res.data)).toString())
            );
            await vm.$store.dispatch("setOneAtlasToken", res.data);
            resolve(1);
          } else {
            return resolve(null);
          }
        } catch (err) {
          console.log(err);
          resolve(null);
        }
      });
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    tableHeight() {
      return this.documentHeight - 56 - 96 - 2 * 42 - 38;
    },
    lang() {
      return this.$store.getters.getLang;
    },
    oneAtlasData() {
      return this.$store.getters.getOneAtlasData;
    },
    roles() {
      return this.$store.getters.getRoles;
    },
    isAdmin() {
      return this.roles && this.roles.map((x) => x.name).includes("ROLE_ADMIN");
    },
  },
};
</script>
<style lang="scss" scoped>
.integrate-content {
  margin: 0;
  padding: 0.5rem;
  .integrate__search {
    padding: 0;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .integrate__capacity {
    padding: 0;
    margin-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
    }
  }
  .integrate__datatable,
  .integrate__list {
    margin-top: 0.5rem;
    .accordion {
      border-radius: 0.5rem;
      .accordion-header {
        margin-bottom: 0.5rem;
      }
    }
  }
}
</style>

<style lang="scss">
.integrate-content {
  .integrate__datatable,
  .integrate__list {
    .accordion {
      .accordion-header {
        margin-bottom: 0.5rem;
      }
    }
  }
}
</style>
