<template>
  <div
    class="satellite-cart animate__animated"
    :class="propShow ? 'animate__fadeInLeft' : 'animate__fadeOutLeft'"
    :style="{ width: widgetSize }"
  >
    <div class="panel-heading">
      <h6 class="panel-heading-title">
        <span> {{ $t("label_order") }}</span>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          @click="onClose"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </h6>
    </div>
    <div class="panel-subheading">
      <h6 class="panel-subheading-title">
        {{ $t("label_total_images", {}, { total: propItems.length }) }}
        <button
          type="button"
          @click="onConfirmOrder"
          class="btn btn-sm btn-geostore2 float-right"
          :disabled="!propItems.length > 0"
        >
          <i class="fas fa-question"></i>&nbsp;{{ $t("button_text_confirm") }}
        </button>
      </h6>
    </div>
    <div class="satellite-cart__results panel-body">
      <ul class="list-group" :style="{ height: bodyHeight + 'px' }">
        <li
          class="list-group-item list-group-item-action"
          v-for="item in propItems"
          :key="item.id"
          v-on:click="showMetada(item)"
        >
          <div class="media" v-if="item.type === 'SATELLITE'">
            <icon-preview
              :propId="
                item.preview && item.preview.icon_path
                  ? item.preview.icon_path
                  : item.preview && item.preview.preview_path
                  ? item.preview.preview_path
                  : null
              "
              :prop-src="
                item.preview && item.preview.icon_path
                  ? item.preview.icon_path
                  : item.preview && item.preview.preview_path
                  ? item.preview.preview_path
                  : null
              "
              :propPreviewImages="
                item.preview && item.preview.preview_images
                  ? item.preview.preview_images
                  : []
              "
              @showPreviewImage="showPreviewImage(item)"
            ></icon-preview>
            <div class="media-body">
              <ul>
                <li
                  class="lbl-geostore2 d-flex justify-content-between align-items-center"
                >
                  <strong
                    :title="item.name && item.name.length > 50 ? item.name : ''"
                    >{{
                      item.name && item.name.length > 50
                        ? item.name.substring(0, 50) + "..."
                        : item.name
                    }}</strong
                  >
                  <strong class="text-success text-right font-weight-bold"
                    >(G)</strong
                  >
                </li>
                <li class="mt-1">
                  <div class="">
                    <span
                      >{{ $t("label_image_time") }}:
                      <strong>{{
                        formatUnixDateTime2String(
                          item.info && item.info.imaging_date
                        )
                      }}</strong></span
                    >
                    <span class="pl-2 pr-2">|</span>
                    <span
                      >{{ $t("label_satellite") }}:
                      <strong>{{
                        item.info && item.info.satellite
                          ? item.info.satellite.value
                          : ""
                      }}</strong>
                    </span>
                  </div>
                </li>
                <ItemMetadata
                  :prop-item="item"
                  :propImageTypes="propImageTypes"
                />
              </ul>
            </div>
            <button
              class="btn btn-danger btn-sm remove-item"
              :title="
                $t('tooltip_remove_from_request', null, { name: item.name })
              "
              @click.stop="removeItem(item)"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>

          <div class="media" v-else-if="item.type === 'PLANET'">
            <icon-preview-planet
              :propItem="item"
              @showPreviewImage="showPreviewImage(item)"
            ></icon-preview-planet>
            <div class="media-body">
              <ul>
                <li
                  class="lbl-geostore2 d-flex justify-content-between align-items-center"
                >
                  <strong
                    :title="item.id && item.id.length > 50 ? item.id : ''"
                    >{{
                      item.id && item.id.length > 50
                        ? item.id.substring(0, 50) + "..."
                        : item.id
                    }}</strong
                  >
                  <strong class="text-success text-right font-weight-bold"
                    >(P)</strong
                  >
                </li>
                <li class="mt-1">
                  <div class="">
                    <span
                      >{{ $t("label_image_time") }}:
                      <strong>{{
                        item.properties && item.properties.datetime
                          ? item.properties.datetime
                          : ""
                      }}</strong></span
                    >
                    <span class="pl-2 pr-2">|</span>
                    <span
                      >{{ $t("label_satellite") }}:
                      <strong>{{
                        item.properties && item.properties.instrument
                          ? item.properties.instrument
                          : ""
                      }}</strong></span
                    >
                  </div>
                </li>
                <ItemMetadataPlanet
                  :prop-item="item"
                  :propImageTypes="propImageTypes"
                />
              </ul>
            </div>
            <button
              class="btn btn-danger btn-sm remove-item"
              :title="
                $t('tooltip_remove_from_request', null, {
                  name: item.id,
                })
              "
              @click.stop="removeItem(item)"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
          <div class="media" v-else-if="item.type === 'ONEATLAS'">
            <icon-preview-one-atlas
              :propAssets="item.assets"
              @initSuccessIconImage="initSuccessIconImage(item, $event)"
              @showPreviewImage="showPreviewImage(item)"
            ></icon-preview-one-atlas>
            <div class="media-body">
              <ul>
                <li
                  class="lbl-geostore2 d-flex justify-content-between align-items-center"
                >
                  <strong
                    :title="
                      item.properties &&
                      item.properties.acquisitionIdentifier &&
                      item.properties.acquisitionIdentifier.length > 54
                        ? item.properties.acquisitionIdentifier
                        : ''
                    "
                    >{{
                      item.properties.acquisitionIdentifier &&
                      item.properties.acquisitionIdentifier.length > 54
                        ? item.properties.acquisitionIdentifier.substring(
                            0,
                            54
                          ) + "..."
                        : item.properties.acquisitionIdentifier
                    }}</strong
                  >
                  <strong class="text-success text-right font-weight-bold"
                    >(O)</strong
                  >
                </li>
                <li class="mt-1">
                  <div class="">
                    <span
                      >{{ $t("label_image_time") }}:
                      <strong>{{
                        item.properties && item.properties.datetime
                          ? item.properties.datetime
                          : ""
                      }}</strong></span
                    >
                    <span class="pl-2 pr-2">|</span>
                    <span
                      >{{ $t("label_satellite") }}:
                      <strong>{{
                        item.properties && item.properties.platform
                          ? item.properties.platform
                          : ""
                      }}</strong></span
                    >
                  </div>
                </li>
                <ItemMetadataOneAtlas
                  :prop-item="item"
                  :propImageTypes="propImageTypes"
                />
              </ul>
            </div>
            <button
              class="btn btn-danger btn-sm remove-item"
              :title="
                $t('tooltip_remove_from_request', null, {
                  name: item.id,
                })
              "
              @click.stop="removeItem(item)"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
          <div class="media" v-else-if="item.type === 'CAPELLA'">
            <icon-preview-capella
              :propAssets="item.assets"
              @initSuccessIconImage="initSuccessIconImage(item, $event)"
              @showPreviewImage="showPreviewImage(item)"
            ></icon-preview-capella>
            <div class="media-body">
              <ul>
                <li
                  class="lbl-geostore2 d-flex justify-content-between align-items-center"
                >
                  <strong
                    :title="
                      item.id && item.id && item.id.length > 54 ? item.id : ''
                    "
                    >{{
                      item.id && item.id.length > 54
                        ? item.id.substring(0, 54) + "..."
                        : item.id
                    }}</strong
                  >
                  <strong class="text-success text-right font-weight-bold"
                    >(C)</strong
                  >
                </li>
                <li class="mt-1">
                  <div class="">
                    <span
                      >{{ $t("label_image_time") }}:
                      <strong>{{
                        item.properties && item.properties.datetime
                          ? item.properties.datetime
                          : ""
                      }}</strong></span
                    >
                    <span class="pl-2 pr-2">|</span>
                    <span
                      >{{ $t("label_satellite") }}:
                      <strong>{{
                        item.properties && item.properties.platform
                          ? item.properties.platform
                          : ""
                      }}</strong></span
                    >
                  </div>
                </li>
                <ItemMetadataCapella
                  :prop-item="item"
                  :propImageTypes="propImageTypes"
                />
              </ul>
            </div>
            <button
              class="btn btn-danger btn-sm remove-item"
              :title="
                $t('tooltip_remove_from_request', null, {
                  name: item.id,
                })
              "
              @click.stop="removeItem(item)"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import ItemMetadata from "./cart/ItemMetadata";
import ItemMetadataPlanet from "./planet/ItemMetadata";
import ItemMetadataOneAtlas from "./oneatlas/ItemMetadata";
import ItemMetadataCapella from "./capella/ItemMetadata";
import IconPreview from "./cart/IconPreview";
import IconPreviewPlanet from "./cart/planet/IconPreview.vue";
import IconPreviewOneAtlas from "./cart/oneatlas/IconPreview.vue";
import IconPreviewCapella from "./capella/IconPreview.vue";
import fileFunc from "@/utils/functions/file";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import moment from "moment";
export default {
  props: {
    map: {
      required: true,
    },
    propItems: {
      type: Array,
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propImageTypes: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ItemMetadata,
    IconPreview,
    IconPreviewPlanet,
    IconPreviewOneAtlas,
    IconPreviewCapella,
    ItemMetadataPlanet,
    ItemMetadataOneAtlas,
    ItemMetadataCapella,
  },
  data() {
    return {
      layer: Object,
      buttonsActive: {
        footprint: [],
        images: [],
        cart: [],
      },
    };
  },
  computed: {
    widgetSize() {
      if (this.documentWidth < 576) return this.documentWidth - 16 + "px";
      else return "30.5rem";
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    accessToken() {
      return this.$store.getters.getAccessToken;
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    bodyHeight() {
      return (
        this.documentHeight -
        16 * 5.8125 -
        46 -
        11 -
        16 -
        42 +
        (!this.isHeader ? 3.5 * 16 : 0) -
        2
      );
    },
    isHeader() {
      return this.$store.getters.getIsHeader;
    },
    lang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
    getSensingDate(item) {
      let result = "";
      if (item && Object.keys(item).length > 0) {
        if (item.indexes && item.indexes.length > 0) {
          let summary = item.indexes.find(
            (x) => x.name.toLowerCase() === "summary"
          );
          if (summary && Object.keys(summary).length > 0) {
            let index = summary.children
              ? summary.children.findIndex(
                  (x) => x.name.toLowerCase() === "date"
                )
              : -1;
            if (index >= 0) {
              try {
                result = this.formatUnixDateTime2String(
                  summary.children[index].value
                );
              } catch (error) {}
            }
          }
        }
      }
      return result;
    },

    getSatellite(item) {
      let result = "";
      if (item && Object.keys(item).length > 0) {
        if (item.indexes && item.indexes.length > 0) {
          let summary = item.indexes.find(
            (x) => x.name.toLowerCase() === "summary"
          );
          if (summary && Object.keys(summary).length > 0) {
            let index = summary.children
              ? summary.children.findIndex(
                  (x) => x.name.toLowerCase() === "satellite"
                )
              : -1;
            if (index >= 0) {
              result = summary.children[index].value;
            }
          }
        }
      }
      return result;
    },
    onConfirmOrder() {
      this.$emit("onConfirmOrder");
    },
    onClose() {
      this.$emit("close");
    },
    async showMetada(val) {
      switch (val.type) {
        case "SATELLITE":
          this.$emit("actionClick", {
            code: "SHOW_METADATA",
            val: val,
          });
          break;
        case "PLANET":
          this.$emit("actionClick", {
            code: "SHOW_METADATA_PLANET",
            val: val,
          });
          break;
        case "ONEATLAS":
          this.$emit("actionClick", {
            code: "SHOW_METADATA_ONEATLAS",
            val: val,
          });
          break;
        case "CAPELLA":
          this.$emit("actionClick", {
            code: "SHOW_METADATA_CAPELLA",
            val: val,
          });
          break;
      }
    },
    async showPreviewImage(val) {
      this.showMetada(val);
    },

    async getPreview(val) {
      try {
        let response = await fileFunc.downloadPreview(
          val.preview_path,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          let contentType =
            response.headers && response.headers["content-type"]
              ? response.headers["content-type"]
              : "image/jpg";
          let blob = new Blob([response.data], {
            type: contentType,
          });
          let arrSplit = contentType.split("/");
          val.preview_extension = arrSplit[arrSplit.length - 1]
            ? arrSplit[arrSplit.length - 1]
            : "jpg";
          if (blob.size != 0) {
            if (!window.navigator.msSaveOrOpenBlob) {
              // BLOB NAVIGATOR
              val.preview_src = window.URL.createObjectURL(blob);
            } else {
              // BLOB FOR EXPLORER 11
              val.preview_src = window.navigator.msSaveOrOpenBlob(
                blob,
                new Date().getTime() + "." + arrSplit[arrSplit.length - 1]
                  ? arrSplit[arrSplit.length - 1]
                  : "jpg"
              );
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getPreview, val);
        }
      }
    },
    removeItem(val) {
      let id = val.id,
        index = -1;
      if (val.type === "ONEATLAS") {
        id = val.properties.id;
        index = this.propItems.findIndex((x) => x.properties.id === id);
      } else {
        index = this.propItems.findIndex((x) => x.id === id);
      }
      if (index >= 0) {
        this.propItems.splice(index, 1);
        index = this.buttonsActive.footprint.findIndex((x) => x === id);
        if (index >= 0) this.buttonsActive.footprint.splice(index, 1);
        if (Object.keys(this.layer).length > 0) {
          let feature = this.layer.getSource().getFeatureById(id);
          if (feature) {
            this.layer.getSource().removeFeature(feature);
          }
        }
        this.$emit("actionClick", {
          val: id,
          code: "REMOVE_CART_ITEM",
          type: val.type,
        });
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {});
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {});
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.satellite-cart {
  background: white;
  .btn-sm {
    padding: 0.125rem 0.325rem;
  }
  font-size: 14px;
  height: 100%;
  width: 30.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .panel-heading {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    h6 {
      margin: 0;
      font-size: 14px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;

      font-size: 16px;
      font-weight: 500;
    }
  }
  .panel-subheading-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    padding: 0.75rem 1rem;
    font-size: 14px;
    button {
      width: 6rem;
    }
  }
}
.satellite-cart__results {
  background: white;
  font-size: 14px;
  border-top: 1px solid rgba($color: $background-color, $alpha: 0.4);
  .actions {
    button {
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
.list-group {
  overflow-y: auto;
}
.list-group-item {
  font-size: 12px;
  padding: 0.5rem;
  border: 0;
  border-radius: 0;
  border-top: 1px solid rgba($color: $background-color, $alpha: 0.4);
  &:first-child {
    border-top: 0;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba($color: #c7e7f9, $alpha: 0.5);
  }
  &:last-child {
    border-bottom: 1px solid rgba($color: $background-color, $alpha: 0.4);
  }
}
.media {
  position: relative;
  img {
    border: 1px solid rgba($color: $background-color, $alpha: 0.5);
    width: 64px;
    height: 64px;
    &:hover {
      cursor: pointer;
      border: 1px solid rgba($color: $background-color, $alpha: 0.8);
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  button.remove-item {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
button {
  &.close {
    span {
      font-size: 24px;
    }
  }
}
@media (max-width: 576px) {
  .satellite-cart {
    width: calc(100% - 8px) !important;
  }
}
</style>
