<template>
  <Dialog
    :header="title"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :maximizable="false"
    :modal="true"
    :contentStyle="{
      width: width + 'px',
    }"
    @hide="onClose"
  >
    <div class="detail-price">
      <div class="left"><PriceMap :prop-height="mapHeight" /></div>

      <div class="right">
        <div class="card" v-for="item in propItems" :key="item.properties.id">
          <div class="card-body">
            <div class="row">
              <div class="col-5 col-md-4">
                <div class="icon-big text-center icon-warning">
                  <i class="fas-solid fa-money-bill"></i>
                </div>
              </div>
              <div class="col-7 col-md-8">
                <div class="numbers">
                  <p class="card-category">{{ $t("label_price") }}</p>
                  <p class="card-title">$ 1,345</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="image-name">
              <strong
                :title="
                  item.properties &&
                  item.properties.archiveIdentifier &&
                  item.properties.archiveIdentifier.length > 40
                    ? item.properties.archiveIdentifier
                    : ''
                "
                >{{
                  item.properties.archiveIdentifier &&
                  item.properties.archiveIdentifier.length > 40
                    ? item.properties.archiveIdentifier.substring(0, 40) + "..."
                    : item.properties.archiveIdentifier
                }}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import PriceMap from "./Map.vue";
export default {
  components: {
    Dialog,
    Button,
    PriceMap,
  },
  props: {
    propShow: {
      type: Boolean,
      default: false,
    },
    propItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      title: this.$t("title_price_detail"),
      show: false,
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow(val) {
      this.show = val;
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
      this.show = false;
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    width() {
      if (this.documentWidth >= 992) return 960;
      else if (this.documentWidth >= 768 && this.documentWidth < 992)
        return 720;
      else return this.documentWidth;
    },
    mapHeight() {
      if (this.documentHeight > 768) {
        return 640;
      } else {
        return this.documentHeight - 2 * 48 - 80;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.detail-price {
  display: grid;
  grid-template-columns: [first] auto [line2] 24rem [end];
  gap: 0.5rem;
  .left {
  }
  .right {
    padding: 0;
    .card-body .numbers {
      text-align: right;
      font-size: 1.5rem;
      p {
        margin: 0;
      }
      .card-category {
        color: #9a9a9a;
        font-size: 16px;
        line-height: 1.4em;
      }
    }
    .card-footer {
      padding: 0 15px 15px;
      background-color: transparent;
      border: 0;
    }
  }
}
</style>
