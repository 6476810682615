import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { geoServiceApiURL, userServiceApiURL } from "@/utils/commons/environment"
export default {
  async getList(page, size, access_token) {
    const object =  axios({
      method: 'get',
      url: system.endPointSub + system.config.project.list,
      params: {
        page: page,
        size: size,
      },
      headers: {
        "Authorization": "bearer " + access_token,
        'Accept-Language':app.$store.getters.getLang
      },
    });

    let response = await object;
    return response;
  },

  async getAll(access_token) {
    let headers = basicApi.project.get_all.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object =  axios({
      method: basicApi.project.get_all.method,
      url: userServiceApiURL + basicApi.project.get_all.url,
      headers: headers,
      params: {
      }
    });

    let response = await object;
    return response;
  },

  async getById(id, access_token) {
    let headers = basicApi.project.get_by_id.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.project.get_by_id.method,
      url: userServiceApiURL + basicApi.project.get_by_id.url.replace("{id}",id),
      headers: headers,
      params: {
      }
    });

    let response = await object;
    return response;
  },
  async addImage(projectId,imageId, access_token) {
    let headers = basicApi.project.add_image.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.project.add_image.method,
      url: geoServiceApiURL + basicApi.project.add_image.url.replace("{project_id}",projectId),
      headers: headers,
      params:{
        image_id: imageId,        
      }
    });

    let response = await object;
    return response;
  },
  async downloadAttachment(projectId,fileId, access_token) {
    let headers = basicApi.project.download_attachment.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.project.download_attachment.method,
      url: geoServiceApiURL + basicApi.project.download_attachment.url.replace("{project_id}",projectId),
      headers: headers,
      params: {
        file_id:fileId
      },
      //responseType: "blob" // important
    });
    let response = await object;
    return response;
  },
}