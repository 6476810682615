<template>
  <div class="satellite panel" :style="{ width: widgetSize }">
    <div class="images-spinners Iybw1Bhj3Jhd8s" v-if="propSearching">
      <TileSpinner
        :loading="propSearching"
        :height="'40'"
        :width="'40'"
        :color="'fd853e'"
        class="ehAbnmg4Jwb9BS"
      ></TileSpinner>
    </div>
    <div
      class="panel-subheading pl-2 pt-2"
      id="labelTotals"
      v-if="!propSearching"
    >
      <h6 class="m-0 d-flex flex-column">
        <span v-if="images.length"
          >{{
            $t("label_show_from_to_2", null, {
              from: 1,
              to: this.propData.items ? this.propData.items.length : 0,
            })
          }}
          <i
            class="custome-add-list"
            :disabled="propData.isBottom"
            @click="nextPage"
          ></i
        ></span>
        <span v-else>{{ $t("label_no_results") }}</span>
      </h6>
    </div>

    <div
      class="satellite__filter panel-filter p-2"
      id="resultsFilter"
      v-if="images.length"
    >
      <div class="row no-gutters">
        <div class="results-sort col-12 col-sm-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="input-group-text">{{ $t("label_sort") }}</div>
            </div>
            <select
              v-model="propData.sort"
              :disabled="propData.searching"
              @change="sortChange"
            >
              <option value="DEFAULT">{{ $t("label_default") }}</option>
              <option value="TIME_ASCENDING">
                {{ $t("label_sort_time_asc") }}
              </option>
              <option value="TIME_DESCENDING">
                {{ $t("label_sort_time_desc") }}
              </option>
              <option value="RESOLUTION_ASCENDING">
                {{ $t("label_sort_resolution_asc") }}
              </option>
              <option value="RESOLUTION_DESCENDING">
                {{ $t("label_sort_resolution_desc") }}
              </option>
              <option value="CLOUD_COVERANGE_ASCENDING">
                {{ $t("label_sort_cloud_cove_asc") }}
              </option>
              <option value="CLOUD_COVERANGE_DESCENDING">
                {{ $t("label_sort_cloud_cove_desc") }}
              </option>
              <option value="INCIDENCE_ANGLE_ASCENDING">
                {{ $t("label_sort_incidence_angle_asc") }}
              </option>
              <option value="INCIDENCE_ANGLE_DESCENDING">
                {{ $t("label_sort_incidence_angle_desc") }}
              </option>
            </select>
          </div>
        </div>
        <div class="results-check-all col-2 col-sm-2">
          <div class="checkbox checkbox-primary align-middle mt-2">
            <input
              id="check-all-oneatlas"
              type="checkbox"
              v-model="propData.checkAll"
              :disabled="propData.searching"
              @change="selectAllChange"
            />
            <label for="check-all-oneatlas" class="lbl-geostore2 mb-0">
              <strong>{{ $t("label_all") }}</strong>
            </label>
          </div>
        </div>
        <div class="results-actions col-10 col-sm-4 text-right mt-1 d-flex">
          <div class="dropdown mr-1">
            <button
              :disabled="propData.selected.length === 0"
              class="btn btn-geostore2 btn-sm btn-export dropdown-toggle"
              type="button"
              id="dropdownMenuButtonExport"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              Export
            </button>
            <div
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButtonExport"
            >
              <a class="dropdown-item" @click="exportCsv"
                >Csv&nbsp;({{ $t("label_image_name") }})</a
              >
              <a class="dropdown-item" @click="exportShapeFile"
                >Shape File (shp files archived)</a
              >
            </div>
          </div>
          <button
            type="button"
            class="btn btn-transparent2 btn-sm mr-1"
            :disabled="propData.selected.length === 0"
            :title="$t('tooltip_add_all_footprint')"
            @click="addFootprintAll"
          >
            <i class="custome-font custome-foot-print"></i>
          </button>
          <button
            type="button"
            class="btn btn-transparent2 btn-sm mr-1"
            :title="$t('tooltip_add_all_image_2_map')"
            :disabled="propData.selected.length === 0"
            @click="addImage2MapAll"
          >
            <i class="fas fa-map-marked-alt"></i>
          </button>
          <button
            type="button"
            class="btn btn-transparent2 btn-sm mr-1"
            :title="$t('tooltip_add_all_image_2_request')"
            :disabled="propData.selected.length === 0"
            @click="addToCartAll"
          >
            <i class="fas fa-shopping-cart"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="satellite_results panel-body">
      <ul
        class="list-group"
        :style="{
          height: bodyHeight + 'px',
        }"
        @scroll="onScroll"
      >
        <li
          class="list-group-item list-group-item-action"
          v-for="item in images"
          :key="item.properties.id"
          v-on:click="showMetada(item)"
          @mouseover="mouseOverItem(item)"
          @mouseleave="mouseLeaveItem(item)"
          :class="[
            propData.clicked.id &&
            propData.clicked.id === 'ONEATLAS_' + item.properties.id
              ? propData.clicked.class
              : '',
            propFootprintClick &&
            propFootprintClick.id &&
            propFootprintClick.id === 'FOOTPRINT_' + item.properties.id
              ? propFootprintClick.class
              : '',
          ]"
        >
          <div class="checkbox checkbox-primary">
            <input
              :id="item.properties.id"
              type="checkbox"
              :value="item.properties.id"
              v-model="propData.selected"
            />
            <label
              :for="item.properties.id"
              class="lbl-geostore2 m-0"
              style="cursor: pointer"
              v-on:click.stop.prevent="selectedImage(item.properties.id)"
            >
              <strong
                :title="
                  item.properties &&
                  item.properties.acquisitionIdentifier &&
                  item.properties.acquisitionIdentifier.length > 54
                    ? item.properties.acquisitionIdentifier
                    : ''
                "
                >{{
                  item.properties.acquisitionIdentifier &&
                  item.properties.acquisitionIdentifier.length > 54
                    ? item.properties.acquisitionIdentifier.substring(0, 54) +
                      "..."
                    : item.properties.acquisitionIdentifier
                }}
              </strong>
            </label>
          </div>
          <div class="media">
            <icon-preview
              :propId="
                item.properties && item.properties.activityId
                  ? item.properties.activityId
                  : null
              "
              :propItem="item"
              @initSuccessIconImage="initSuccessIconImage(item, $event)"
              @showPreviewImage="showPreviewImage(item)"
            ></icon-preview>
            <div class="media-body">
              <ul>
                <li class="mt-1">
                  <div class="">
                    <span
                      >{{ $t("label_image_time") }}:
                      <strong>{{
                        item.properties && item.properties.datetime
                          ? item.properties.datetime
                          : ""
                      }}</strong></span
                    >
                    <span class="pl-2 pr-2">|</span>
                    <span
                      >{{ $t("label_satellite") }}:
                      <strong>{{
                        item.properties && item.properties.platform
                          ? item.properties.platform
                          : ""
                      }}</strong></span
                    >
                  </div>
                </li>
                <ItemMetadata
                  :prop-item="item"
                  :propImageTypes="propImageTypes"
                />
              </ul>
              <div class="actions mt-1">
                <button
                  type="button"
                  class="btn btn-transparent2 btn-sm"
                  :disabled="!item.geometry"
                  :title="$t('tooltip_add_footprint')"
                  :class="
                    buttonsActive.footprint.includes(item.properties.id)
                      ? 'active'
                      : ''
                  "
                  @click.stop="addFootprint(item)"
                >
                  <i
                    class="custome-font custome-foot-print-white"
                    v-if="buttonsActive.footprint.includes(item.properties.id)"
                  ></i>
                  <i class="custome-font custome-foot-print" v-else></i>
                </button>
                <button
                  v-if="!['/img/icons/no-image.png'].includes(item.icon_src)"
                  type="button"
                  class="btn btn-transparent2 btn-sm"
                  :title="$t('tooltip_add_image_2_map')"
                  :class="
                    buttonsActive.images.includes(item.properties.id)
                      ? 'active'
                      : ''
                  "
                  @click.stop="addImage2Map(item)"
                >
                  <i class="fas fa-map-marked-alt"></i>
                </button>
                <button
                  v-if="
                    item._links &&
                    item._links.quicklook &&
                    item._links.quicklook.href
                  "
                  type="button"
                  class="btn btn-transparent2 btn-sm"
                  :title="$t('tooltip_download_preview')"
                  @click.stop="downloadPreviewImage(item)"
                >
                  <i class="fas fa-file-download pl-1 pr-1"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-transparent2 btn-sm"
                  :title="$t('tooltip_add_image_2_request')"
                  :class="
                    propCarts
                      .map((x) => x.properties.id)
                      .includes(item.properties.id)
                      ? 'active'
                      : ''
                  "
                  @click.stop="addToCart(item)"
                >
                  <i class="fas fa-shopping-cart"></i>
                </button>

                <button
                  type="button"
                  class="btn btn-transparent2 btn-sm"
                  :title="$t('tooltip_pan_2_coordinate')"
                  :disabled="!item.geometry"
                  @click.stop="onPanCoordinate(item)"
                >
                  <i class="fas fa-crosshairs"></i>
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import ItemMetadata from "./oneatlas/ItemMetadata";
import IconPreview from "./oneatlas/IconPreview";
import { TileSpinner } from "vue-spinners";
import { fromLonLat, transform } from "ol/proj";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
import { Vector as VectorLayer, Image as ImageLayer } from "ol/layer";
import { Vector as VectorSource, ImageCanvas } from "ol/source";
import { Style, Fill, Stroke } from "ol/style.js";
import geoImageFunc from "@/utils/functions/geoimage";
import fileFunc from "@/utils/functions/file";
import imageSharingFunc from "@/utils/functions/imagesharing";
import imageFunc from "@/utils/functions/image";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import { change_alias } from "@/utils/commons/common";
import { enpointPreviewUrl } from "@/utils/commons/environment";
import { getMimeType } from "@/utils/commons/mimetypes";
import moment from "moment";
import axios from "axios";
import shpwrite from "@/utils/commons/shp-write";
import turf from "turf";
import oneAtlasFunc from "@/utils/functions/oneatlas";
export default {
  props: {
    map: {
      required: true,
    },
    propData: {
      type: Object,
      default: () => {},
    },
    propCarts: {
      type: Array,
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propSearching: {
      type: Boolean,
      default: false,
    },
    propFootprintClick: {
      type: Object,
      default: () => {},
    },
    propImageTypes: {
      type: Array,
    },
  },
  components: { IconPreview, ItemMetadata, TileSpinner },
  data() {
    return {
      layer: Object,
      layerDrawImage: Object,
      buttonsActive: {
        footprint: [],
        images: [],
        cart: [],
      },
      styles: {
        footprint: new Style({
          fill: new Fill({
            color: "rgba(0,158,247,0.3)",
          }),
          stroke: new Stroke({
            color: "rgba(0,158,247,1)",
            width: 1.25,
          }),
        }),
        footprintHover: new Style({
          fill: new Fill({
            color: "transparent",
          }),
          stroke: new Stroke({
            color: "rgba(0,158,247,1)",
            width: 1.25,
          }),
        }),
        image: new Style({
          fill: new Fill({
            color: "rgba(0,0,0,0)",
          }),
          stroke: new Stroke({
            color: "rgba(0,0,0,0.8)",
            width: 1.25,
          }),
        }),
      },
      images: [],
    };
  },
  created() {},
  mounted() {},
  computed: {
    widgetSize() {
      if (this.documentWidth < 576) return this.documentWidth - 16 + "px";
      else return "30.5rem";
    },
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    accessToken() {
      return this.$store.getters.getAccessToken;
    },
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
    bodyHeight() {
      let elementLabelTotals = document.getElementById("labelTotals");
      let elementResultsFilter = document.getElementById("resultsFilter");
      return (
        this.documentHeight -
        56 - // header
        42 - // control left
        45 - // header result
        55 - // tabs
        2 * 4 - // Padding control left - search results
        (this.propSearching ? 0 : this.images.length > 0 ? 76 : 25) + // label + filter || label no results
        (!this.isHeader ? 3.5 * 16 : 0)
      );
    },
    isHeader() {
      return this.$store.getters.getIsHeader;
    },
    username() {
      return this.$store.getters.getUserName;
    },
    roles() {
      return this.$store.getters.getRoles;
    },
    isAdmin() {
      return this.roles && this.roles.map((x) => x.name).includes("ROLE_ADMIN");
    },
  },
  watch: {
    "propData.selected": {
      handler(val) {
        if (
          this.propData.selected.length === this.propData.items.length &&
          this.propData.items.length.length > 0
        )
          this.propData.checkAll = true;
        else this.propData.checkAll = false;
      },
      deep: true,
    },
    "propData.items": {
      async handler(val) {
        this.images = Object.assign([], val && val.length > 0 ? val : []);
        for (let i = 0; i < this.images.length; i++) {
          if (this.propData.checkAll) {
            if (!this.propData.selected.includes(this.images[i].id)) {
              this.propData.selected.push(this.images[i].id);
            }
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    async clearLayer() {
      if (this.layer && Object.keys(this.layer).length > 0) {
        this.layer.getSource().clear();
      } else {
        await this.getLayerDraw();
        if (this.layer && Object.keys(this.layer).length > 0) {
          this.layer.getSource().clear();
        }
      }

      if (this.layerDrawImage && Object.keys(this.layerDrawImage).length > 0) {
        this.layerDrawImage.getSource().clear();
      } else {
        await this.getLayerDrawImage();
        if (
          this.layerDrawImage &&
          Object.keys(this.layerDrawImage).length > 0
        ) {
          this.layerDrawImage.getSource().clear();
        }
      }
      let mapLayers = this.map.getLayers().array_;
      if (mapLayers && mapLayers.length > 0) {
        let layersRemove = [];
        for (let i = 0; i < mapLayers.length; i++) {
          if ((mapLayers[i].get("id") + "").startsWith("LAYER_ONEATLAS_")) {
            layersRemove.push(mapLayers[i]);
          }
        }
        try {
          if (layersRemove && layersRemove.length > 0) {
            for (let i = 0; i < layersRemove.length; i++) {
              this.map.removeLayer(layersRemove[i]);
            }
          }
        } catch (error) {}
      }
      this.buttonsActive.footprint = [];
      this.buttonsActive.images = [];
      this.buttonsActive.cart = [];
    },
    onClose() {
      this.$emit("close");
    },
    async mouseOverItem(val) {
      if (this.propData.hover.id === val.properties.id) return;
      if (!this.buttonsActive.footprint.includes(val.properties.id)) {
        this.propData.hover.id = val.properties.id;
        this.propData.hover.time = new Date().getTime();
        if (!this.layer || Object.keys(this.layer).length === 0)
          await this.getLayerDraw();
        try {
          let feature = this.layer
            .getSource()
            .getFeatureById("hover_" + val.properties.id);
          if (!feature) {
            let coors = [];
            if (
              val.geometry &&
              val.geometry.coordinates &&
              val.geometry.coordinates.length > 0
            ) {
              for (let i = 0; i < val.geometry.coordinates[0].length; i++) {
                //if (i > 4) break;
                coors.push(val.geometry.coordinates[0][i]);
              }
            }
            this.addFeature(
              this.layer,
              coors,
              this.styles.footprintHover,
              "hover_" + val.properties.id
            );
          }
        } catch (error) {}
      }
    },
    async mouseLeaveItem(val) {
      if (this.propData.hover.id === "") return;
      if (!this.layer || Object.keys(this.layer).length === 0)
        await this.getLayerDraw();
      try {
        let feature = this.layer
          .getSource()
          .getFeatureById("hover_" + val.properties.id);
        if (feature) {
          this.layer.getSource().removeFeature(feature);
        } else {
        }
      } catch (error) {}
      this.propData.hover.id = "";
      this.propData.hover.time = null;
    },
    selectedImage(val) {
      let index = this.propData.selected.findIndex((x) => x === val);
      if (index >= 0) {
        this.propData.selected.splice(index, 1);
      } else this.propData.selected.push(val);
    },
    formatUnixDateTimeFromTime(value) {
      if (value) {
        return moment.unix(value).format("DD/MM/YYYY/HH/mm");
      } else return "";
    },
    exportShapeFile() {
      if (this.propData.selected && this.propData.selected.length > 0) {
        let itemsSelect = this.images.filter((x) =>
          this.propData.selected.includes(x.id)
        );
        if (itemsSelect && itemsSelect.length > 0) {
          let geoFeatures = {
            type: "FeatureCollection",
            features: [],
          };
          for (let i = 0; i < itemsSelect.length; i++) {
            if (
              itemsSelect[i].geometry &&
              itemsSelect[i].geometry.coordinates &&
              itemsSelect[i].geometry.coordinates.length > 0
            ) {
              let coordinates = [];
              for (
                let j = 0;
                j < itemsSelect[i].geometry.coordinates[0].length;
                j++
              ) {
                if (j >= 4) break;
                coordinates.push(itemsSelect[i].geometry.coordinates[0][j]);
              }
              if (coordinates && coordinates.length > 0) {
                coordinates.push(coordinates[0]);
              }
              let properties = {
                name: itemsSelect[i].name ? itemsSelect[i].name : "",
                scene_id: itemsSelect[i].sceneId
                  ? itemsSelect[i].sceneId
                  : itemsSelect[i].scene_id
                  ? itemsSelect[i].scene_id
                  : "",
              };
              let keysIgnore = ["boundary"];
              if (itemsSelect[i].info) {
                if (
                  itemsSelect[i].info.satellite &&
                  Object.keys(itemsSelect[i].info.satellite).length > 0
                ) {
                  properties.satellite = itemsSelect[i].info.satellite.value
                    ? itemsSelect[i].info.satellite.value
                    : null;
                  keysIgnore.push("satellite");
                }
                if (itemsSelect[i].info.imaging_date) {
                  properties.imaging_date = itemsSelect[i].info.imaging_date;
                  keysIgnore.push("imaging_date");
                }
                if (itemsSelect[i].info.incidence_angle) {
                  properties.incidence_angle =
                    itemsSelect[i].info.incidence_angle;
                  keysIgnore.push("incidence_angle");
                }
                if (itemsSelect[i].info.resolution) {
                  properties.resolution = itemsSelect[i].info.resolution;
                  keysIgnore.push("resolution");
                }
                if (
                  itemsSelect[i].info.processing_level &&
                  Object.keys(itemsSelect[i].info.processing_level).length > 0
                ) {
                  properties.processing_level = itemsSelect[i].info
                    .processing_level.value
                    ? itemsSelect[i].info.processing_level.value
                    : null;
                  keysIgnore.push("processing_level");
                }
              }
              if (itemsSelect[i].metadata) {
                for (let k = 0; k < itemsSelect[i].metadata.length; k++) {
                  if (
                    itemsSelect[i].metadata[k].key &&
                    !keysIgnore.includes(itemsSelect[i].metadata[k].key)
                  ) {
                    if (itemsSelect[i].metadata[k].key in properties) {
                    } else {
                      properties[itemsSelect[i].metadata[k].key] = itemsSelect[
                        i
                      ].metadata[k].value
                        ? itemsSelect[i].metadata[k].value
                        : null;
                    }
                  }
                }
              }

              let feature = {
                type: "Feature",
                properties: properties,
                geometry: {
                  type: "Polygon",
                  coordinates: coordinates,
                },
              };
              geoFeatures.features.push(feature);
            }
          }
          if (geoFeatures.features) {
            var options = {
              folder: "shapes",
              types: {
                point: "POINTS",
                polygon: "POLYGONS",
                line: "LINES",
              },
            };
            shpwrite.download(geoFeatures, options);
          }
        }
      }
    },
    exportCsv() {
      if (this.propData.selected && this.propData.selected.length > 0) {
        let dataExport = [],
          items = this.images.filter((x) =>
            this.propData.selected.includes(x.id)
          );
        for (let i = 0; i < items.length; i++) {
          if (dataExport.findIndex((x) => x.name === items[i].name) < 0) {
            dataExport.push({
              name: items[i].name,
            });
          }
        }
        var vm = this;

        const convertArrayOfObjectsToCSV = (args) => {
          const data = args.data;
          if (!data || !data.length) return;

          const columnDelimiter = args.columnDelimiter || ",";
          const lineDelimiter = args.lineDelimiter || "\n";

          const keys = Object.keys(data[0]);

          let result = "",
            ctr = 0;
          result += keys.join(columnDelimiter);
          result += lineDelimiter;

          data.forEach((item) => {
            ctr = 0;
            keys.forEach((key) => {
              if (ctr > 0) result += columnDelimiter;
              result += item[key];
              ctr++;
            });
            result += lineDelimiter;
          });

          return result;
        };
        const downloadCSV = (args) => {
          let csv = convertArrayOfObjectsToCSV({
            data: dataExport,
          });
          if (!csv) return;

          const filename = args.filename || "export.csv";

          if (!csv.match(/^data:text\/csv/i)) {
            csv = "data:text/csv;charset=utf-8," + csv;
          }

          const data = encodeURI(csv);

          const link = document.createElement("a");
          link.setAttribute("href", data);
          link.setAttribute("download", filename);
          link.click();
          //document.body.removeChild(link);
        };
        downloadCSV({
          filename:
            "export_" +
            vm
              .formatUnixDateTimeFromTime(new Date().getTime() / 1000)
              .split("/")
              .join("_") +
            ".csv",
        });
      }
    },
    addFootprintAll() {
      if (this.propData.selected.length > 0) {
        var vm = this;
        let items = this.propData.items.filter(function (x) {
          return vm.propData.selected.includes(x.properties.id);
        });
        for (let i = 0; i < items.length; i++) {
          vm.addFootprint(items[i]);
        }
      }
    },
    async addImage2MapAll() {
      if (this.propData.selected.length > 0) {
        var vm = this;
        let items = this.propData.items.filter(function (x) {
          return vm.propData.selected.includes(x.properties.id);
        });
        await items.reduce(async (prevPromise, i) => {
          await prevPromise;
          return await vm.addImage2Map(i);
        }, Promise.resolve());
        let center = this.map.getView().getCenter();
        this.map.getView().setCenter([center[0] + 1, center[1] + 1]);
        setTimeout(() => {
          this.map.getView().setZoom(this.map.getView().getZoom() - 0.0001);
          this.map.updateSize();
        }, 500);
      }
    },
    addToCartAll() {
      if (this.propData.selected.length > 0) {
        var vm = this;
        let items = this.propData.items.filter(function (x) {
          return vm.propData.selected.includes(x.properties.id);
        });
        for (let i = 0; i < items.length; i++) {
          vm.addToCart(items[i]);
        }
      }
    },
    selectAllChange() {
      if (this.propData.checkAll) {
        for (let i = 0; i < this.propData.items.length; i++) {
          if (
            !this.propData.selected.includes(
              this.propData.items[i].properties.id
            )
          )
            this.propData.selected.push(this.propData.items[i].properties.id);
        }
      } else this.propData.selected = [];
    },
    sortChange() {
      switch (this.propData.sort) {
        case "TIME_ASCENDING":
          this.images.sort(function (a, b) {
            if (
              new Date(a.properties.datetime).getTime() <
              new Date(b.properties.datetime).getTime()
            )
              return -1;
            else if (
              new Date(a.properties.datetime).getTime() >
              new Date(b.properties.datetime).getTime()
            )
              return 1;
            return 0;
          });
          break;
        case "TIME_DESCENDING":
          this.images.sort(function (a, b) {
            if (
              new Date(a.properties.datetime).getTime() <
              new Date(b.properties.datetime).getTime()
            )
              return 1;
            else if (
              new Date(a.properties.datetime).getTime() >
              new Date(b.properties.datetime).getTime()
            )
              return -1;
            return 0;
          });
          break;
        case "RESOLUTION_ASCENDING":
          this.images.sort(function (a, b) {
            if (a.properties.resolution < b.properties.resolution) return -1;
            else if (a.properties.resolution > b.properties.resolution)
              return 1;
            return 0;
          });
          break;
        case "RESOLUTION_DESCENDING":
          this.images.sort(function (a, b) {
            if (a.properties.resolution < b.properties.resolution) return 1;
            else if (a.properties.resolution > b.properties.resolution)
              return -1;
            return 0;
          });
          break;
        case "CLOUD_COVERANGE_ASCENDING":
          this.images.sort(function (a, b) {
            if (a.properties["eo:cloud_cover"] < b.properties["eo:cloud_cover"])
              return -1;
            else if (
              a.properties["eo:cloud_cover"] > b.properties["eo:cloud_cover"]
            )
              return 1;
            return 0;
          });
          break;
        case "CLOUD_COVERANGE_DESCENDING":
          this.images.sort(function (a, b) {
            if (a.properties["eo:cloud_cover"] < b.properties["eo:cloud_cover"])
              return 1;
            else if (
              a.properties["eo:cloud_cover"] > b.properties["eo:cloud_cover"]
            )
              return -1;
            return 0;
          });
          break;
        case "INCIDENCE_ANGLE_ASCENDING":
          this.images.sort(function (a, b) {
            if (
              a.properties["view:incidence_angle"] <
              b.properties["view:incidence_angle"]
            )
              return -1;
            else if (
              a.properties["view:incidence_angle"] >
              b.properties["view:incidence_angle"]
            )
              return 1;
            return 0;
          });
          break;
        case "INCIDENCE_ANGLE_DESCENDING":
          this.images.sort(function (a, b) {
            if (
              a.properties["view:incidence_angle"] <
              b.properties["view:incidence_angle"]
            )
              return 1;
            else if (
              a.properties["view:incidence_angle"] >
              b.properties["view:incidence_angle"]
            )
              return -1;
            return 0;
          });
          break;
      }
    },
    nextPage() {
      if (!this.propData.isBottom) {
        this.$emit("pagechanged", {
          tab: "ONEATLAS",
          val: this.propData.value?.pages?.page + 1,
        });
      }
    },
    async onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (
        scrollTop > 0 &&
        Math.ceil(scrollTop + clientHeight) >= scrollHeight
      ) {
        if (!this.propData.isBottom) {
          this.$emit("pagechanged", {
            tab: "ONEATLAS",
            val: this.propData.value?.pages?.page + 1,
          });
        }
      }
    },
    pageSizechanged(val) {
      this.$emit("pageSizechanged", {
        tab: "ONEATLAS",
        val: val,
      });
    },
    pagechanged(val) {
      this.$emit("pagechanged", {
        tab: "ONEATLAS",
        val: val,
      });
    },
    initSuccessIconImage(item, data) {
      item.icon_src = data.val;
      item.is_image = true;
    },
    async showPreviewImage(val) {
      this.showMetada(val);
    },
    async downloadQuicklook(val) {
      try {
        let response = await oneAtlasFunc.getImage(val.url);
        if (response && response.status === 200) {
          let contentType =
            response.headers && response.headers["content-type"]
              ? response.headers["content-type"]
              : "image/png";
          let blob = new Blob([response.data], {
            type: contentType,
          });
          let imgUrl = null;
          let arrSplit = contentType.split("/");
          if (blob.size != 0) {
            if (!window.navigator.msSaveOrOpenBlob) {
              // BLOB NAVIGATOR
              imgUrl = window.URL.createObjectURL(blob);
            } else {
              // BLOB FOR EXPLORER 11
              imgUrl = window.navigator.msSaveOrOpenBlob(
                blob,
                val.name + ".png"
              );
            }
            let index = this.propData.items.findIndex((x) => x.id === val.id);
            if (index >= 0) {
              this.propData.items[index].image_src = imgUrl;
            }
          }
        }
      } catch (error) {
        console.log(error);
        if (error && error.response && error.response.status === 401) {
          this.refreshToken(this.downloadQuicklook);
        }
      }
    },
    async downloadPreviewImage(val) {
      if (!val.image_src) {
        await this.downloadQuicklook({
          url:
            val._links && val._links.quicklook && val._links.quicklook.href
              ? val._links.quicklook.href
              : null,
          name:
            val.properties && val.properties.archiveIdentifier
              ? val.properties.archiveIdentifier
              : new Date().getTime(),
          id: val.id,
        });
      }
      let index = this.propData.items.findIndex((x) => x.id === val.id);
      if (index >= 0 && this.propData.items[index].image_src) {
        const linkdown = document.createElement("a");
        linkdown.href = this.propData.items[index].image_src;
        linkdown.setAttribute(
          "download",
          (val.properties && val.properties.archiveIdentifier
            ? val.properties.archiveIdentifier
            : new Date().getTime()) +
            "." +
            "png"
        );
        document.body.appendChild(linkdown);
        linkdown.click();
        document.body.removeChild(linkdown);
      }
    },
    async downloadGoogleEarth(val) {
      if (val.google === undefined || val.google === null)
        await this.getGoogleEarth(val);
      if (val.google === undefined || val.google === null) {
        return;
      }
      const linkdown = document.createElement("a");
      linkdown.href = val.google;
      linkdown.setAttribute("download", val.image_name + ".kmz");
      document.body.appendChild(linkdown);
      linkdown.click();
      document.body.removeChild(linkdown);
    },
    async showMetada(val) {
      this.$emit("actionClick", {
        code: "SHOW_METADATA_ONEATLAS",
        val: val,
      });
    },
    getLayerDraw() {
      if (Object.keys(this.map).length > 0) {
        if (Object.keys(this.layer).length === 0) {
          var layers = this.map.getLayers().array_;
          if (layers.length > 0) {
            for (let i = 0; i < layers.length; i++) {
              if (layers[i].get("id") === "layerdrawsatellite") {
                this.layer = layers[i];
                break;
              }
            }
          }
          if (Object.keys(this.layer).length === 0) {
            var source = new VectorSource({ wrapX: false });
            this.layer = new VectorLayer({
              source: source,
              style: [this.styles.footprint],
            });
            this.layer.set("id", "layerdrawsatellite");
            this.layer.setZIndex(this.map.getLayers().length);
            this.map.addLayer(this.layer);
          }
        }
      }
      return this.layer;
    },
    getLayerDrawImage() {
      if (Object.keys(this.map).length > 0) {
        if (Object.keys(this.layerDrawImage).length === 0) {
          var layers = this.map.getLayers().array_;
          if (layers.length > 0) {
            for (let i = 0; i < layers.length; i++) {
              if (layers[i].get("id") === "layerdrawimage") {
                this.layerDrawImage = layers[i];
                break;
              }
            }
          }
          if (Object.keys(this.layerDrawImage).length === 0) {
            var source = new VectorSource({ wrapX: false });
            this.layerDrawImage = new VectorLayer({
              source: source,
              style: [this.styles.footprint],
            });
            this.layerDrawImage.set("id", "layerdrawimage");
            this.layerDrawImage.setZIndex(this.map.getLayers().length);
            this.map.addLayer(this.layerDrawImage);
          }
        }
      }
      return this.layerDrawImage;
    },
    async addFootprint(val) {
      try {
        if (!this.layer || Object.keys(this.layer).length === 0)
          await this.getLayerDraw();
      } catch (error) {
        console.log(error);
      }

      let index = this.buttonsActive.footprint.findIndex(
        (x) => x === val.properties.id
      );
      // add
      if (index < 0) {
        this.buttonsActive.footprint.push(val.properties.id);

        var points = [];
        if (
          val.geometry &&
          val.geometry.coordinates &&
          val.geometry.coordinates.length > 0
        ) {
          for (let i = 0; i < val.geometry.coordinates[0].length; i++) {
            points.push(val.geometry.coordinates[0][i]);
          }
        }
        try {
          this.addFeature(
            this.layer,
            points,
            this.styles.footprint,
            "FOOTPRINT_" + val.properties.id
          );
        } catch (error) {
          console.log(error);
        }
      } else {
        //remove
        this.buttonsActive.footprint.splice(index, 1);
        let feature = this.layer
          .getSource()
          .getFeatureById("FOOTPRINT_" + val.properties.id);
        if (feature) {
          this.layer.getSource().removeFeature(feature);
        }
      }
    },
    async addFootprintImage(val) {
      if (!this.layerDrawImage || Object.keys(this.layerDrawImage).length === 0)
        await this.getLayerDrawImage();
      var points = [];
      if (
        val.geometry &&
        val.geometry.coordinates &&
        val.geometry.coordinates.length > 0
      ) {
        for (let i = 0; i < val.geometry.coordinates[0].length; i++) {
          //if (i > 4) break;
          points.push(val.geometry.coordinates[0][i]);
        }
      }
      try {
        this.addFeature(
          this.layerDrawImage,
          points,
          this.styles.image,
          "ONEATLAS_" + val.properties.id
        );
      } catch (error) {
        console.log(error);
      }
    },
    addFeature(layer, coordinates, style, id) {
      let feature = layer.getSource().getFeatureById(id);
      if (!feature) {
        var points = [];
        try {
          for (var i = 0; i < coordinates.length; i++) {
            points[i] = transform(coordinates[i], "EPSG:4326", "EPSG:3857");
          }
          feature = new Feature({
            geometry: new Polygon([points]),
            id: id,
          });
          feature.set("id", id);
          feature.id_ = id;
          feature.setStyle(style);
          layer.getSource().addFeature(feature);
        } catch (error) {}
      } else {
        feature.style = { visibility: "visible" };
      }
    },
    fintNearPoint(coors, point) {
      try {
        let result = coors[0];
        let max = Number.MAX_VALUE;
        for (let i = 0; i < coors.length; i++) {
          var distance = turf.distance(
            turf.point(coors[i]),
            turf.point(point),
            null
          );
          if (distance < max) {
            max = distance;
            result = coors[i];
          }
        }
        return result;
      } catch (error) {}
    },
    addImage2Map: async function (val) {
      try {
        let index = this.buttonsActive.images.findIndex(
          (x) => x === val.properties.id
        );
        var vm = this,
          perspective = undefined,
          layer = null;
        var layers = this.map.getLayers().array_;
        if (layers.length > 0) {
          for (let i = 0; i < layers.length; i++) {
            if (layers[i].get("id") === "LAYER_ONEATLAS_" + val.properties.id) {
              layer = layers[i];
              break;
            }
          }
        }
        if (index >= 0) {
          this.buttonsActive.images.splice(index, 1);
          if (layer) layer.setVisible(false);
          let feature = this.layerDrawImage
            .getSource()
            .getFeatureById("ONEATLAS_" + val.properties.id);
          if (feature) {
            this.layerDrawImage.getSource().removeFeature(feature);
          }
        } else {
          this.buttonsActive.images.push(val.properties.id);
          if (layer) {
            layer.setVisible(true);
            this.addFootprintImage(val);
          } else {
            await this.addFootprintImage(val);

            let feature = this.layerDrawImage
              .getSource()
              .getFeatureById("ONEATLAS_" + val.properties.id);

            var corners = [
              { x: 100, y: 100 }, // ul
              { x: 520, y: 20 }, // ur
              { x: 520, y: 380 }, // br
              { x: 100, y: 380 }, // bl
            ];

            var lcoordinates = [],
              pixels = [];
            if (
              val.geometry &&
              val.geometry.coordinates &&
              val.geometry.coordinates.length > 0
            ) {
              let coordinates =
                val.geometry &&
                val.geometry.coordinates &&
                val.geometry.coordinates.length > 0
                  ? val.geometry.coordinates[0]
                  : [];
              let extenFeature = feature.getGeometry().getExtent();
              let bottomLeftExtent = transform(
                [extenFeature[0], extenFeature[1]],
                "EPSG:3857",
                "EPSG:4326"
              );
              let topRightExtent = transform(
                [extenFeature[2], extenFeature[3]],
                "EPSG:3857",
                "EPSG:4326"
              );
              let topLeftExtent = [bottomLeftExtent[0], topRightExtent[1]];
              let bottomRightExtent = [topRightExtent[0], bottomLeftExtent[1]];
              let topleft = await this.fintNearPoint(
                coordinates,
                topLeftExtent
              );

              let topRight = await this.fintNearPoint(
                coordinates,
                topRightExtent
              );
              let bottomRight = await this.fintNearPoint(
                coordinates,
                bottomRightExtent
              );

              let bottomLeft = await this.fintNearPoint(
                coordinates,
                bottomLeftExtent
              );
              lcoordinates.push(topleft);
              lcoordinates.push(topRight);
              lcoordinates.push(bottomRight);
              lcoordinates.push(bottomLeft);
            }
            if (lcoordinates.length < 4) return;
            var bbox = {
              WKID: "EPSG:4326",
              coord: lcoordinates,
            };
            var canvas = document.createElement("canvas");
            var context = canvas.getContext("2d");
            var img = new Image();
            // img.src =
            //   val._links && val._links.thumbnail && val._links.thumbnail.href
            //     ? val._links.thumbnail.href
            //     : null;

            img.src = null;
            if (val && val.links && val.links.length > 0) {
              let thumbnail = val.links.find((e) => {
                return e.rel == "thumbnail";
              });

              if (thumbnail && thumbnail.href) {
                img.src = thumbnail.href;
              }
            }

            img.onload = function () {};
            var canvasFunction = function (
              extent,
              resolution,
              pixelRatio,
              size,
              projection
            ) {
              var canvasWidth = size[0],
                canvasHeight = size[1];
              canvas.setAttribute("width", canvasWidth);
              canvas.setAttribute("height", canvasHeight);
              var mapExtent = vm.map
                .getView()
                .calculateExtent(vm.map.getSize());
              var canvasOrigin = vm.map.getPixelFromCoordinate([
                extent[0],
                extent[3],
              ]);
              var mapOrigin = vm.map.getPixelFromCoordinate([
                mapExtent[0],
                mapExtent[3],
              ]);
              var delta = [
                pixelRatio * mapOrigin[0] - pixelRatio * canvasOrigin[0],
                pixelRatio * mapOrigin[1] - pixelRatio * canvasOrigin[1],
              ];

              var points = bbox.coord;
              if (bbox.WKID != "EPSG:3857") {
                // Verifier egalité sur chaine
                for (var i = 0; i < points.length; i++) {
                  points[i] = transform(points[i], bbox.WKID, "EPSG:3857");
                }
                bbox.WKID = "EPSG:3857";
              }
              for (var i = 0; i < points.length; i++) {
                pixels[i] = vm.map.getPixelFromCoordinate(points[i]);
                pixels[i][0] = pixelRatio * pixels[i][0] + delta[0];
                pixels[i][1] = pixelRatio * pixels[i][1] + delta[1];
                corners[i].x = pixels[i][0];
                corners[i].y = pixels[i][1];
              }
              // draw a box
              function drawBBox(mybbox) {
                context.save();
                context.moveTo(pixels[0][0], pixels[0][1]);
                context.lineTo(pixels[1][0], pixels[1][1]);
                context.lineTo(pixels[2][0], pixels[2][1]);
                context.lineTo(pixels[3][0], pixels[3][1]);
                context.closePath();
                context.lineWidth = 1;
                context.stroke();
                context.restore();
              }
              try {
                var p = new Perspective(context, img);
                if (p) {
                  p.draw([
                    [corners[0].x, corners[0].y],
                    [corners[1].x, corners[1].y],
                    [corners[2].x, corners[2].y],
                    [corners[3].x, corners[3].y],
                  ]);
                }
              } catch (errorPerspective) {}

              //drawBBox(bbox);
              return canvas;
            };
            var canvasLayer = new ImageLayer({
              source: new ImageCanvas({
                canvasFunction: canvasFunction,
                projection: "EPSG:3857",
              }),
            });
            canvasLayer.setZIndex(vm.map.getLayers().array_.length);
            canvasLayer.set("id", "LAYER_ONEATLAS_" + val.properties.id);
            vm.map.addLayer(canvasLayer);
            let center = this.map.getView().getCenter();
            this.map.getView().setCenter([center[0] + 1, center[1] + 1]);
            setTimeout(() => {
              this.map.getView().setZoom(this.map.getView().getZoom() - 0.0001);
              this.map.updateSize();
            }, 100);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    addToCart(val) {
      if (this.$store.getters.getAccessToken) {
        let index = this.buttonsActive.cart.findIndex(
          (x) => x === val.properties.id
        );
        if (index >= 0) {
          this.buttonsActive.cart.splice(index, 1);
          this.$emit("actionClick", {
            code: "ADD_TO_CART",
            type: "ONEATLAS",
            item: val,
          });
        } else {
          this.buttonsActive.cart.push(val.properties.id);
          this.$emit("actionClick", {
            code: "ADD_TO_CART",
            type: "ONEATLAS",
            item: val,
          });
        }
      } else {
        this.$emit("actionClick", {
          code: "ADD_TO_CART",
          type: "ONEATLAS",
          item: val,
        });
      }
    },
    onPanCoordinate(val) {
      if (
        val &&
        val.properties &&
        val.properties.geometryCentroid &&
        Object.keys(val.properties.geometryCentroid).length > 0
      ) {
        let zoomLevel = this.map.getView().getZoom();
        this.map.getView().animate({
          center: fromLonLat([
            val.properties.geometryCentroid.lon,
            val.properties.geometryCentroid.lat,
          ]),
          duration: 2000,
          zoom: zoomLevel < 16 ? zoomLevel + 1 : zoomLevel,
        });
      } else {
        if (
          val.geometry &&
          val.geometry.coordinates &&
          val.geometry.coordinates.length > 0
        ) {
          if (val.geometry.coordinates[0].length > 3) {
            let coordinate = [
              (val.geometry.coordinates[0][0][0] +
                val.geometry.coordinates[0][2][0]) /
                2,
              (val.geometry.coordinates[0][0][1] +
                val.geometry.coordinates[0][2][1]) /
                2,
            ];
            let zoomLevel = this.map.getView().getZoom();
            this.map.getView().animate({
              center: fromLonLat(coordinate),
              duration: 2000,
              zoom: zoomLevel < 16 ? zoomLevel + 1 : zoomLevel,
            });
          }
        }
      }
    },
    removeCartItem(val) {
      let index = this.buttonsActive.cart.findIndex((x) => x === val);
      if (index >= 0) {
        this.buttonsActive.cart.splice(index, 1);
      }
    },
    removeItem(val) {
      let index = this.propData.items.findIndex((x) => x.id === val);
      if (index >= 0) {
        this.propData.items.splice(index, 1);
        index = this.buttonsActive.footprint.findIndex((x) => x === val);
        if (index >= 0) this.buttonsActive.footprint.splice(index, 1);
        index = this.buttonsActive.cart.findIndex((x) => x === val);
        if (index >= 0) this.buttonsActive.cart.splice(index, 1);
        index = this.buttonsActive.images.findIndex((x) => x === val);
        if (index >= 0) this.buttonsActive.images.splice(index, 1);
        if (Object.keys(this.layer).length > 0) {
          let feature = this.layer
            .getSource()
            .getFeatureById("FOOTPRINT_" + val);
          if (feature) {
            this.layer.getSource().removeFeature(feature);
          }
        }
        if (Object.keys(this.layerDrawImage).length > 0) {
          let feature = this.layerDrawImage
            .getSource()
            .getFeatureById("ONEATLAS_" + val);
          if (feature) {
            this.layerDrawImage.getSource().removeFeature(feature);
          }
        }
        if (Object.keys(this.map).length > 0) {
          var layers = this.map.getLayers().array_;
          if (layers.length > 0) {
            for (let i = 0; i < layers.length; i++) {
              if (layers[i].get("id") === val) {
                this.map.removeLayer(layers[i]);
                break;
              }
            }
          }
        }
      }
    },
    async getIconPreview(val) {
      try {
        let response = await geoImageFunc.downloadIcon(
          val.properties.id,
          this.$store.getters.getAccessToken
        );
        if (response.status === 200) {
          let blob = new Blob([response.data], {
            type: "image/jpg",
          });
          if (blob.size != 0) {
            if (!window.navigator.msSaveOrOpenBlob) {
              // BLOB NAVIGATOR
              let url = window.URL.createObjectURL(blob);
              if (val.icon === undefined || val.icon === null) val.icon = url;
            } else {
              // BLOB FOR EXPLORER 11
              let url = window.navigator.msSaveOrOpenBlob(
                blob,
                val.properties.id + ".jpg"
              );
              if (val.icon === undefined || val.icon === null) val.icon = url;
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getIconPreview, val);
        }
      }
    },

    async getPreviewNewVer(val) {
      if (val && val.preview && val.preview.preview_path) {
        let itemPreview = val.preview.preview_images.find(
          (x) => x.path === val.preview.preview_path
        );
        try {
          return axios({
            url:
              enpointPreviewUrl +
              (val.preview && val.preview.preview_path
                ? val.preview.preview_path
                : null),
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers":
                "Origin, X-Requested-With, Content-Type, Accept, Authorization",
            },
            method: "get",
            responseType: "blob",
            mode: "no-cors",
            withCredentials: false,
            crossdomain: false,
          }).then((response) => {
            let contentType =
              response.headers && response.headers["content-type"]
                ? {
                    type: response.headers["content-type"],
                  }
                : null;
            if (!contentType) {
              if (itemPreview && itemPreview.ext)
                contentType = getMimeType(itemPreview.ext.toLowerCase());
              else
                contentType = {
                  type: "image/jpg",
                };
            }
            let blob = new Blob([response.data], contentType);
            val.preview_extension =
              itemPreview && itemPreview.ext ? itemPreview.ext : "jpg";
            if (blob.size != 0) {
              if (!window.navigator.msSaveOrOpenBlob) {
                // BLOB NAVIGATOR
                val.preview_src = window.URL.createObjectURL(blob);
              } else {
                // BLOB FOR EXPLORER 11
                val.preview_src = window.navigator.msSaveOrOpenBlob(
                  blob,
                  (val.name ? val.name : new Date().getTime()) +
                    "." +
                    val.preview_extension
                );
              }
            }
          });
        } catch (error) {}
      }
    },
    async getPreview(val) {
      try {
        let response = await fileFunc.downloadPreview(
          val.preview_path,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          let contentType =
            response.headers && response.headers["content-type"]
              ? response.headers["content-type"]
              : "image/jpg";
          let blob = new Blob([response.data], {
            type: contentType,
          });
          let arrSplit = contentType.split("/");
          val.preview_extension = arrSplit[arrSplit.length - 1]
            ? arrSplit[arrSplit.length - 1]
            : "jpg";
          if (blob.size != 0) {
            if (!window.navigator.msSaveOrOpenBlob) {
              // BLOB NAVIGATOR
              val.preview_src = window.URL.createObjectURL(blob);
            } else {
              // BLOB FOR EXPLORER 11
              val.preview_src = window.navigator.msSaveOrOpenBlob(
                blob,
                new Date().getTime() + "." + arrSplit[arrSplit.length - 1]
                  ? arrSplit[arrSplit.length - 1]
                  : "jpg"
              );
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getPreview, val);
        }
      }
    },
    async getGoogleEarth(val) {
      try {
        let response = await geoImageFunc.downloadGoogleEarth(
          val.properties.id,
          this.$store.getters.getAccessToken
        );
        if (response.status === 200) {
          let blob = new Blob([response.data], {
            type: "application/vnd.google-earth.kmz",
          });
          if (blob.size != 0) {
            if (!window.navigator.msSaveOrOpenBlob) {
              // BLOB NAVIGATOR
              let url = window.URL.createObjectURL(blob);
              if (val.google === undefined || val.google === null)
                val.google = url;
            } else {
              // BLOB FOR EXPLORER 11
              let url = window.navigator.msSaveOrOpenBlob(
                blob,
                val.properties.id + ".kmz"
              );
              if (val.google === undefined || val.google === null)
                val.google = url;
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getGoogleEarth, val);
        } else if (error.response && error.response.status === 404) {
          await this.getGoogleEarthFromImage(val);
        } else if (error.response && error.response.status === 500) {
          await this.getGoogleEarthFromImage(val);
        }
      }
    },
    async getGoogleEarthFromImage(val) {
      try {
        let response = await geoImageFunc.exportGoogleEarth(
          val.properties.id,
          this.$store.getters.getAccessToken
        );
        if (response.status === 200) {
          let blob = new Blob([response.data], {
            type: "application/vnd.google-earth.kmz",
          });
          if (blob.size != 0) {
            if (!window.navigator.msSaveOrOpenBlob) {
              // BLOB NAVIGATOR
              let url = window.URL.createObjectURL(blob);
              if (val.google === undefined || val.google === null)
                val.google = url;
            } else {
              // BLOB FOR EXPLORER 11
              let url = window.navigator.msSaveOrOpenBlob(
                blob,
                val.properties.id + ".kmz"
              );
              if (val.google === undefined || val.google === null)
                val.google = url;
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getGoogleEarthFromImage, val);
        }
      }
    },
    async downloadImage(item) {
      this.$store.dispatch("setImagesDownload", [
        {
          is_add: true,
          image: item,
        },
      ]);
      try {
        let response = await imageSharingFunc.download2(
          item.password ? item.password : null,
          item.token,
          item.properties.id,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          //if (response.data.type.toLowerCase() === "file") {
          if (response.data.url) {
            window.open(response.data.url, "_blank");
          } else if (response.data.type.toLowerCase() === "folder") {
            this.downloadFolderAsZip({
              url: response.data.url,
              // .replace("http://", "http:/")
              // .replace("https://", "https:/")
              // .replace("//", "/")
              // .replace("http:/", "http://")
              // .replace("https:/", "https://"),
              method: "post",
              data: response.data.data,
              image: item,
            });
          }
        } else {
          this.message.push(
            this.$t("message_download_file_error", null, { name: item.name })
          );
        }
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: item,
          },
        ]);
      } catch (error) {
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: item,
          },
        ]);
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.downloadImage, item);
        } else {
          let msg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.message.push(
            msg
              ? msg
              : this.$t("message_download_file_error", null, {
                  name: item.name,
                })
          );
        }
      }
    },
    async downloadFolderAsZip(data) {
      this.$store.dispatch("setImagesDownload", [
        {
          is_add: true,
          image: data.image,
        },
      ]);
      try {
        let response = await imageFunc.downloadFolderAsZip(data.url, data.data);
        if (response.status === 200) {
          let contentType =
            response.headers && response.headers["content-type"]
              ? response.headers["content-type"]
              : "application/zip";
          let url,
            blob = new Blob([response.data], { type: contentType });
          let extension = "";
          try {
            extension = contentType.substring(
              contentType.indexOf("/") + 1,
              contentType.length
            );
          } catch (error2) {}
          if (blob.size === 0) {
            this.message.push(this.$t("message_file_dose_not_exist"));
          } else {
            try {
              if (!window.navigator.msSaveOrOpenBlob) {
                // BLOB NAVIGATOR
                url = window.URL.createObjectURL(blob);
              } else {
                // BLOB FOR EXPLORER 11
                url = window.navigator.msSaveOrOpenBlob(
                  blob,
                  change_alias(data.image.name) + ".zip" //(extension ? "."+extension :".zip")
                );
              }
              const linkdown = document.createElement("a");
              linkdown.href = url;
              linkdown.setAttribute(
                "download",
                change_alias(data.image.name) + ".zip"
              ), //(extension ? "."+extension :".zip"));
                document.body.appendChild(linkdown);
              linkdown.click();
              document.body.removeChild(linkdown);
              window.URL.revokeObjectURL(url);
            } catch (error3) {}
          }
        } else {
          this.message.push(
            this.$t("message_download_file_error", null, {
              name: data.image.name,
            })
          );
        }
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: data.image,
          },
        ]);
      } catch (error) {
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: data.image,
          },
        ]);
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.downloadFolderAsZip, data);
        } else {
          //let responseObj = await error.response.data.text();
          if (
            error.request &&
            error.request.responseType === "blob" &&
            error.response.data instanceof Blob &&
            error.response.data.type &&
            error.response.data.type.toLowerCase().indexOf("json") != -1
          ) {
            let reader = new FileReader();
            reader.onload = () => {
              let responseObj = JSON.parse(reader.result);
              let msg =
                responseObj && responseObj.message ? responseObj.message : null;
              this.message.push(
                msg
                  ? msg
                  : this.$t("message_download_file_error", null, {
                      name: data.image.name ? data.image.name : null,
                    })
              );
            };
            reader.readAsText(error.response.data);
          } else {
            let msg =
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : null;
            this.message.push(
              msg
                ? msg
                : this.$t("message_download_file_error", null, {
                    name: data.image.name,
                  })
            );
          }
        }
      }
    },
    async downloadImage2(val) {
      this.$store.dispatch("setImagesDownload", [
        {
          is_add: true,
          image: val,
        },
      ]);
      try {
        let response = await imageSharingFunc.download2(
          val.password ? val.password : null,
          val.token ? val.token : null,
          val.properties.id,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          let contentType =
            response.headers && response.headers["content-type"]
              ? response.headers["content-type"]
              : "application/zip";
          let url,
            blob = new Blob([response.data], { type: contentType });
          let extension = "";
          try {
            extension = contentType.substring(
              contentType.indexOf("/") + 1,
              contentType.length
            );
          } catch (error2) {}
          if (blob.size === 0) {
            this.$emit("message", [this.$t("message_file_dose_not_exist")]);
          } else {
            if (!window.navigator.msSaveOrOpenBlob) {
              // BLOB NAVIGATOR
              url = window.URL.createObjectURL(blob);
            } else {
              // BLOB FOR EXPLORER 11
              url = window.navigator.msSaveOrOpenBlob(
                blob,
                change_alias(val.name) + ".zip" // (extension ? "."+extension :".zip")
              );
            }
            const linkdown = document.createElement("a");
            linkdown.href = url;
            linkdown.setAttribute("download", change_alias(val.name) + ".zip"); // (extension ? "."+extension :".zip"));
            document.body.appendChild(linkdown);
            linkdown.click();
            document.body.removeChild(linkdown);
            window.URL.revokeObjectURL(url);
          }
        } else
          this.$emit("message", [
            this.$t("message_download_file_error", null, {
              name: val.name ? val.name : null,
            }),
          ]);
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: val,
          },
        ]);
      } catch (error) {
        this.$store.dispatch("setImagesDownload", [
          {
            is_add: false,
            image: val,
          },
        ]);
        if (error.response && error.response.status === 401) {
          await this.refreshToken(this.downloadImage, val);
        } else {
          let msg =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : null;
          this.$emit("message", [
            msg
              ? msg
              : this.$t("message_download_file_error", null, {
                  name: val.name ? val.name : null,
                }),
          ]);
        }
      }
    },
    /*------------- $refs------------ */
    setPreviewImage(data) {
      let index = this.images.findIndex((x) => x.id === data.id);
      if (index >= 0) this.images[index].preview_src = data.val;
    },

    /*------------- end refs --------------*/

    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {});
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {});
      }
      localStorage.removeItem("lock-refresh");
    },
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.satellite {
  background: white;
  height: 100%;
  width: 30.5rem;
  border: 1px solid #ccc;
  border-top: 0;
  border-bottom: 0;
  position: relative;
  .Iybw1Bhj3Jhd8s {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
    .ehAbnmg4Jwb9BS {
      display: table-cell;
      vertical-align: middle;
    }
  }
  .btn-sm {
    padding: 0.125rem 0.325rem;
  }
  .panel-heading {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    h6 {
      margin: 0;
    }
  }
  span,
  button {
    font-size: 14px;
  }
  i.custome-add-list {
    &:hover {
      cursor: pointer;
    }
  }
  i.custome-add-list[disabled="disabled"] {
    &:hover {
      cursor: default;
    }
  }
}
.satellite__filter {
  height: auto;
  font-size: 12px;
  .input-group {
    .input-group-text {
      font-size: 12px;
      background: $background-color-2;
      border-color: $background-color-2;
      color: white;
    }
    select {
      background: #f5f8fa;
      border: 1px solid #f5f8fa;
      box-sizing: border-box;
      border-radius: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .results-actions {
    button {
      font-size: 12px;
      &:hover {
        .custome-foot-print {
          content: url("/img/icons/footprint-white.png");
        }
      }
    }
    button[disabled="disabled"] {
      &.btn {
        &:hover {
          color: $background-color-2 !important;
          .custome-foot-print {
            content: url("/img/icons/footprint2.png");
          }
        }
      }
    }
    button[disabled="disabled"] {
      &.btn-export {
        &:hover {
          color: white !important;
        }
      }
    }
    .dropdown-menu {
      .dropdown-item {
        font-size: 12px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.satellite_results {
  font-size: 14px;
  .actions {
    button {
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
.list-group {
  overflow-y: auto;
}
.list-group-item {
  font-size: 12px;
  padding: 0.5rem;
  border: 0;
  border-radius: 0;
  border-top: 1px solid #eff2f5;
  &:hover {
    cursor: pointer;
    background-color: rgba($color: #c7e7f9, $alpha: 0.5);
  }
  &.active {
    background-color: rgba(255, 165, 0, 0.5);
    color: $background-color;
  }
  &.fadeOut {
    transition: background-color 2s linear;
    -moz-transition: background-color 2s linear;
    -webkit-transition: background-color 2s linear;
    -ms-transition: background-color 2s linear;
    background-color: transparent;
    color: $background-color;
  }
  .media {
    span {
      font-size: 12px !important;
    }
  }
}
.media {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  button {
    margin-left: 0.25rem;
    &.close {
      span {
        font-size: 24rem;
      }
    }
  }
}
button {
  &.close {
    span {
      font-size: 24px;
    }
  }
  &.btn-export:hover {
    color: white !important;
  }
}
</style>
