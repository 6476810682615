<template>
  <Dialog
    id="dialogOrderDetail"
    :header="$t('header_detail')"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <div class="datatable">
      <ContextMenu
        :model="menuModel"
        ref="cmDlgOrderDetailDatatable"
        :appendTo="'dialogOrderDetail'"
      />
      <DataTable
        :value="propItems"
        ref="dt"
        :resizableColumns="true"
        columnResizeMode="expand"
        selectionMode="single"
        dataKey="id"
        contextMenu
        :selection.sync="selected"
        @row-contextmenu="onRowContextMenu"
        @row-click="rowClick"
      >
        <Column>
          <template #body="slotProps">
            <div class="media">
              <icon-preview
                :propId="
                  slotProps.data.icon_path
                    ? slotProps.data.icon_path
                    : slotProps.data.preview_path
                    ? slotProps.data.preview_path
                    : null
                "
                :prop-src="
                  slotProps.data.icon_src ? slotProps.data.icon_src : null
                "
              ></icon-preview>
              <div class="media-body">
                <ul>
                  <li class="lbl-geostore">
                    <strong>{{ slotProps.data.name }}</strong>
                  </li>
                  <li class="mt-1">
                    <div class="">
                      <span
                        ><strong>{{ $t("label_image_time") }}:</strong>
                        {{
                          (new Date(
                            slotProps.data.imaging_date
                              ? slotProps.data.imaging_date
                              : slotProps.data.metadata
                              ? slotProps.data.metadata.imaging_date
                              : null
                          ) /
                            1000)
                            | formatUnixDateTime
                        }}</span
                      >
                      <span class="pl-2 pr-2">|</span>
                      <span
                        ><strong>{{ $t("label_satellite") }}: </strong
                        >{{
                          slotProps.data.satellite_type
                            ? slotProps.data.satellite_type.value
                            : slotProps.data.metadata
                            ? (slotProps.data.metadata.mission
                                ? slotProps.data.metadata.mission
                                : "") +
                              " " +
                              (slotProps.data.metadata.mission_index
                                ? slotProps.data.metadata.mission_index
                                : "")
                            : ""
                        }}</span
                      >
                    </div>
                  </li>
                  <li>
                    <div class="mt-1">
                      <span
                        ><strong>Res (m):</strong
                        >{{
                          slotProps.data.resolution
                            ? parseFloat(slotProps.data.resolution).toFixed(4)
                            : slotProps.data.metadata &&
                              slotProps.data.metadata.resolution
                            ? parseFloat(
                                slotProps.data.metadata.resolution
                              ).toFixed(4)
                            : ""
                        }}</span
                      >
                      <span class="pl-1 pr-1">|</span>
                      <span
                        ><strong>Inc Ang (°):</strong>
                        {{
                          slotProps.data.incidence_angle
                            ? parseFloat(
                                slotProps.data.incidence_angle
                              ).toFixed(4)
                            : slotProps.data.metadata &&
                              slotProps.data.metadata.incidence_angle
                            ? parseFloat(
                                slotProps.data.metadata.incidence_angle
                              ).toFixed(4)
                            : ""
                        }}</span
                      >
                      <span class="pl-1 pr-1">|</span>
                      <span
                        ><strong>Cloud (%):</strong>
                        {{
                          slotProps.data.cloud_coverage
                            ? parseFloat(slotProps.data.cloud_coverage).toFixed(
                                2
                              )
                            : slotProps.data.metadata &&
                              slotProps.data.metadata.cloud_coverage
                            ? parseFloat(
                                slotProps.data.metadata.cloud_coverage
                              ).toFixed(2)
                            : ""
                        }}</span
                      >
                      <span v-if="slotProps.data.type == 'RADAR'"
                        ><strong>Look dir:</strong>
                        {{ slotProps.data.direction }}</span
                      >
                      <span
                        class="pl-1 pr-1"
                        v-if="slotProps.data.type == 'RADAR'"
                        >|</span
                      >
                      <span v-if="slotProps.data.type == 'RADAR'"
                        ><strong>Pol:</strong>&nbsp;{{
                          slotProps.data.pol_layer
                        }}</span
                      >
                      <span
                        class="pl-1 pr-1"
                        v-if="slotProps.data.type == 'RADAR'"
                        >|</span
                      >
                      <span v-if="slotProps.data.type == 'RADAR'"
                        ><strong>Mode:</strong>&nbsp;{{
                          slotProps.data.mode
                        }}</span
                      >
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <template #footer>
      <Button
        :label="$t('button_text_close')"
        :icon="'fas fa-times'"
        :class="'p-button-danger mr-0'"
        @click="onClose"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import IconPreview from "./dialogorderdetail/IconPreview.vue";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";

export default {
  components: {
    Dialog,
    Button,
    IconPreview,
    DataTable,
    Column,
    ContextMenu,
  },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "480px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: null,
      menuModel: [
        {
          label: "Metadata",
          icon: "fas fa-list-alt",
          command: () => this.showMetadata(this.selected),
          visible: true,
        },
      ],
      time: new Date().getTime(),
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
  },
  methods: {
    onRowContextMenu(event) {
      if (this.selected && Object.keys(this.selected).length > 0) {
        this.$refs.cmDlgOrderDetailDatatable.show(event.originalEvent);
      }
    },
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    showMetadata(item) {
      this.$emit("showMetadata", item);
    },
    rowClick(e) {
      if (this.selected) {
        if (e.data.id == this.selected.id) {
          if (this.time) {
            if (new Date().getTime() - this.time <= 300) {
              this.$emit("showMetadata", this.selected);
            }
          }
        }
      }
      this.time = new Date().getTime();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.list-group {
  overflow-y: auto;
}
.list-group-item {
  padding: 0.5rem;
  border: 0;
  border-radius: 0;
  border-top: 1px solid rgba($color: $background-color, $alpha: 0.4);
  &:first-child {
    border-top: 0;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba($color: #c7e7f9, $alpha: 0.5);
  }
}
.media {
  position: relative;
  img {
    border: 1px solid rgba($color: $background-color, $alpha: 0.5);
    width: 64px;
    height: 64px;
    &:hover {
      cursor: pointer;
      border: 1px solid rgba($color: $background-color, $alpha: 0.8);
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 13px;
  }
  button.remove-item {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
</style>
<style lang="scss">
.my-order__dialog {
  .datatable {
    .p-datatable-thead {
      display: none;
    }
  }
}
</style>
