<template>
  <div class="order-detail-content">
    <div class="order-detail__dialog">
      <DialogPassword
        v-if="dialogPassword.show"
        :prop-style="{ width: dialogPassword.width }"
        :prop-show="dialogPassword.show"
        @close="dialogPassword.show = false"
        @onDownload="downloadWithPass"
        @message="message = $event"
      />
      <DialogMessage
        v-if="message.length > 0"
        :propTitle="$t('title_message_info')"
        :prop-show="message.length > 0"
        :prop-message="message"
        @close="message = []"
      />
    </div>
    <div v-if="!loading">
      <div
        class="empty-data"
        v-if="!id || !data || Object.keys(data).length === 0"
      >
        <Error404 />
      </div>
      <div v-else>
        <Detail
          :prop-item="data"
          :propGranularities="granularity.items"
          :propLookDirection="lookDirection.items"
          :propOrbitDirection="orbitDirection.items"
          :propProgramingServices="programingService.items"
          :propSatelliteType="satelliteType.items"
          :propButtonDownload="buttons.download"
          @getAllDirection="getAllDirection"
          @getAllTaskingProgrammingService="getAllTaskingProgrammingService"
          @getAllTaskingGranularity="getAllTaskingGranularity"
          @getAllSatelliteType="getAllSatelliteType"
          @onDownloadImage="onDownloadImage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Error404 from "../Error404.vue";
import Detail from "./Detail.vue";
import DialogPassword from "./detail4images/DialogPassword.vue";
import DialogMessage from "@/components/commons/dialog/MessageBox";
import metaKeyFunc from "@/utils/functions/metakey";
import orderFunc from "@/utils/functions/order";
import imageSharingFunc from "@/utils/functions/imagesharing";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
export default {
  components: { Detail, DialogMessage, DialogPassword, Error404 },

  data() {
    return {
      message: [],
      loading: false,
      id: null,
      data: {},
      granularity: {
        items: [],
      },
      programingService: {
        items: [],
      },
      lookDirection: {
        items: [],
      },
      orbitDirection: {
        items: [],
      },
      satelliteType: {
        items: [],
      },
      buttons: {
        download: {
          disabled: false,
          processing: false,
          ids: [],
        },
      },
      downloadId: null,
      dialogPassword: {
        show: false,
        width: "480px",
      },
    };
  },
  async created() {
    this.loading = true;

    this.$store.dispatch("setSpinnerApp", { loading: true, show: true });
    this.id = this.$route.params ? this.$route.params.id : null;
    //await this.getAllSatelliteType();
    // await this.getAllDirection();
    // await this.getAllTaskingGranularity();
    // await this.getAllTaskingProgrammingService();
    if (this.id) await this.getDetail();
    this.loading = false;
    this.$store.dispatch("setSpinnerApp", { loading: false, show: false });
  },
  methods: {
    downloadWithPass(password) {
      this.onDownloadImage({
        image_id: this.downloadId,
        password: password,
      });
      this.dialogPassword.show = false;
    },
    async onDownloadImage(val) {
      if (this.data && this.data.images) {
        let index = this.data.images
          ? this.data.images.findIndex((x) => x.image.id === val.image_id)
          : -1;
        if (index >= 0) {
          let token = null;
          let image = this.data.images[index].image;
          if (image) {
            let indexShared = -1;

            this.downloadId = image.id;
            if (this.data.shared) {
              indexShared = this.data.shared.findIndex(
                (x) => x.image_id === image.id && x.order_id === this.data.id
              );
              if (indexShared >= 0) {
                token = this.data.shared[indexShared].token
                  ? this.data.shared[indexShared].token
                  : null;
                if (this.data.shared[indexShared].is_pass && !val.password) {
                  this.dialogPassword.show = true;
                  return;
                }
              }
            }
            this.buttons.download.ids.push(val.image_id);
            try {
              this.buttons.download.disabled = true;
              this.buttons.download.processing = true;
              this.$store.dispatch("setImagesDownload", [
                {
                  is_add: true,
                  image: image,
                },
              ]);
              let response = await imageSharingFunc.download2(
                val.password ? val.password : null,
                token,
                val.image_id,
                this.$store.getters.getAccessToken
              );
              if (response && response.status === 200) {
                window.open(decodeURI(response.data.url), "_blank");
              } else {
                this.message.push(
                  this.$t("message_download_file_error", null, {
                    name: image.name,
                  })
                );
              }
              this.$store.dispatch("setImagesDownload", [
                {
                  is_add: false,
                  image: image,
                },
              ]);
              let indexId = this.buttons.download.ids.findIndex(
                (x) => x === val.image_id
              );
              if (indexId >= 0) this.buttons.download.ids.splice(indexId, 1);

              this.buttons.download.disabled = false;
              this.buttons.download.processing = false;
              this.downloadId = null;
            } catch (error) {
              this.downloadId = null;
              this.buttons.download.disabled = false;
              this.buttons.download.processing = false;
              let indexId = this.buttons.download.ids.findIndex(
                (x) => x === val.image_id
              );
              if (indexId >= 0) this.buttons.download.ids.splice(indexId, 1);
              this.$store.dispatch("setImagesDownload", [
                {
                  is_add: false,
                  image: image,
                },
              ]);
              if (error.response && error.response.status === 401) {
                await this.refreshToken(this.downloadImage, data);
              } else {
                let msg =
                  error.response &&
                  error.response.data &&
                  error.response.data.message
                    ? error.response.data.message
                    : null;
                this.message.push(
                  msg
                    ? msg
                    : this.$t("message_download_file_error", null, {
                        name: image.name,
                      })
                );
              }
            }
          }
        } else {
          this.buttons.download.disabled = false;
          this.buttons.download.processing = false;
        }
      }
    },
    async getDetail() {
      try {
        let response = await orderFunc.getById(
          this.id,
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data.success) {
            this.data = response.data.data ? response.data.data : {};
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getDetail);
        }
      }
    },

    async getAllTaskingGranularity() {
      try {
        let response = await metaKeyFunc.getByKey(
          "GRANULARITY",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.granularity.items = response.data.data ? response.data.data : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingGranularity);
        }
      }
    },

    async getAllTaskingProgrammingService() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PROGRAMMING_SERVICE",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.programingService.items = response.data.data
            ? response.data.data
            : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllTaskingProgrammingService);
        }
      }
    },

    async getAllDirection() {
      try {
        let response = await metaKeyFunc.getByKey(
          "DIRECTION",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          let data =
            response.data && response.data.data ? response.data.data : [];
          this.lookDirection.items = data.filter(
            (x) => x.name != "ASCENDING" && x.name != "DESCENDING"
          );
          this.orbitDirection.items = data.filter(
            (x) => x.name != "LEFT" && x.name != "RIGHT"
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllDirection);
        }
      }
    },

    async getAllSatelliteType() {
      try {
        let response = await metaKeyFunc.getByKey(
          "IMAGE_TYPES",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.satelliteType.items =
            response.data && response.data.data ? response.data.data : [];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllSatelliteType);
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>
<style lang="scss" scoped>
.order-detail-content {
  padding: 0.5rem;
  #notfound {
    position: relative;
    height: calc(100vh - 72px);
    background: white;
    border-radius: 0.5rem;
  }
}
</style>
