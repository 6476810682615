<template>
  <div>
    <div class="tools-map">
      <div
        class="tool-item"
        :class="propButtonsTool.basemap.active ? 'active' : ''"
        v-if="propButtonsTool.basemap.visible"
        :disabled="propButtonsTool.basemap.disable"
      >
        <a :title="$t('tooltip_basemaps')" @click="activeTool('BASEMAPS')"
          ><i class="fas fa-layer-group"></i
        ></a>
      </div>
    </div>
    <div class="tools-map" v-show="documentWidth >= 768">
      <div class="tool-item">
        <a :title="$t('tooltip_zoom_in')" @click="activeTool('ZOOM_IN')">
          <i class="fas fa-plus"></i>
        </a>
      </div>
      <div class="tool-item">
        <a :title="$t('tooltip_zoom_out')" @click="activeTool('ZOOM_OUT')"
          ><i class="fas fa-minus"></i>
        </a>
      </div>
      <div
        class="tool-item"
        :class="propButtonsTool.fullscreen.active ? 'active' : ''"
        v-if="propButtonsTool.fullscreen.visible"
        :disabled="propButtonsTool.fullscreen.disable"
      >
        <a :title="$t('tooltip_fullscreen')" @click="activeTool('FULL_SCREEN')"
          ><i class="fas fa-expand"></i
        ></a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    propButtonsTool: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    activeTool(code) {
      this.$emit("rightActionClick", {
        code: code,
      });
    },
  },
  computed: {
    documentWidth() {
      return this.$store.getters.getDocumentWidth;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
div {
  .tools-map {
    background: rgba(255, 255, 255, 0.4);
    padding: 0.25rem;
    margin-top: 0.25rem;
    border-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    &:first-child {
      margin: 0;
    }
    .tool-item {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      display: block;
      margin: 0;
      padding: 0.05rem;
      color: white;
      font-size: 1.14em;
      font-weight: bold;
      text-decoration: none;
      text-align: center;

      height: 32px;

      width: 32px;
      line-height: 0.4em;
      border: none;
      border-radius: 2px;
      margin-bottom: 0.125rem;
      :last-child {
        margin-bottom: 0;
      }
      a {
        color: white;
        background: $background-color;
        border-radius: 0.125rem;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        text-align: center;
        display: block;
        height: 32px;
        width: 32px;
        border-radius: 0.25rem;
        i {
          position: relative;
          vertical-align: top;
          font-size: 14px;
          top: 8px;
        }
        &:hover {
          cursor: pointer;
        }
      }
      &.active {
        background: $orange; //#ff8c00;
        a {
          background: $orange; //#ff8c00;
        }
      }
    }
  }
}
</style>
