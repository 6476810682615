<template>
  <Ordered />
</template>
<script>
import Ordered from "./ordered/Ordered";
export default {
  components: {
    Ordered,
  },
  created() {
    setTimeout(() => {
      this.$emit("initBreadcrumb", [
        "menu_sidebar_my_request",
        "header_detail",
        this.$route.params ? this.$route.params.id : null,
      ]);
    }, 200);
  },
};
</script>
