<template>
  <div
    class="widget-download-progress-bar animate__animated"
    :class="show ? 'animate__fadeInBottomRight' : 'animate__fadeOutBottomRight'"
    :style="style"
  >
    <div class="widget-header">
      <h6 class="title">
        Downloading...
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          @click="onClose"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </h6>
    </div>
    <div class="widget-body">
      <ul class="list-group images-download">
        <li
          class="list-group-item image-item"
          v-for="(item, index) in propItems"
          :key="index"
        >
          <div class="media">
            <icon-preview :propFileName="item.file_name"></icon-preview>
            <div class="media-body">
              <h6 class="mt-0" :title="item.name.length > 32 ? item.name : ''">
                {{
                  item.name.length > 32
                    ? item.name.substring(0, 32) + "..."
                    : item.name
                }}
              </h6>
              <ProgressBar mode="indeterminate" style="height: 0.5em" />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import ProgressBar from "@/components/commons/progressbar/ProgressBar";
import IconPreview from "./downloadfiles/IconPreview.vue";
export default {
  components: { ProgressBar, IconPreview },
  props: {
    propShow: {
      type: Boolean,
      default: false,
    },
    propItems: {
      type: Array,
      default: () => [],
    },
    isLayoutDefault: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    this.show = this.propShow;
  },
  watch: {
    show(val) {
      this.show = val;
    },
  },
  methods: {
    onClose() {
      this.show = false;
      this.$emit("close");
    },
  },
  computed: {
    style() {
      return {
        bottom: this.isLayoutDefault ? "1.8125rem" : "0.25rem",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.widget-download-progress-bar {
  width: 22rem;
  position: fixed;
  right: 0.25rem;
  z-index: 2;
  background: white;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  .widget-header {
    .title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin: 0;
      padding: 0.5rem 0.75rem;
      padding-right: 0.5rem;
      background: $background-color;
      color: white;
      font-size: 14px;
      button {
        color: white;
      }
    }
  }
  .widget-body {
    max-height: 16rem;
    min-height: 3rem;
    overflow-y: auto;
    h6 {
      font-size: 14px;
    }
  }
  ul.images-download {
    margin: 0;
    padding: 0;
    list-style: none;
    .list-group-item {
      padding: 0.5rem 0.75rem;
    }
  }
}
</style>
