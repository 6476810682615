import axios from "axios";
import basicApi from "@/utils/apis/basic";
import app from "./../../main";
import { geoServiceApiURL } from "@/utils/commons/environment";
export default {
  async create(entity, access_token) {
    let headers = basicApi.order.create.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers["Accept-Language"] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.order.create.method,
      url: geoServiceApiURL + basicApi.order.create.url,
      data: entity,
      headers: headers,
      params: {},
    });
    let response = await object;
    return response;
  },
  async update(entity, access_token) {
    let headers = basicApi.order.update.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers["Accept-Language"] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.order.update.method,
      url: geoServiceApiURL + basicApi.order.update.url,
      data: entity,
      headers: headers,
      params: {},
    });
    let response = await object;
    return response;
  },
  async getById(id, access_token) {
    let headers = basicApi.order.get_by_id.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers["Accept-Language"] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.order.get_by_id.method,
      url: geoServiceApiURL + basicApi.order.get_by_id.url.replace("{id}", id),
      headers: headers,
      params: {},
    });
    let response = await object;
    return response;
  },
  async getMyOrderd(params, access_token) {
    let headers = basicApi.order.my_ordered.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers["Accept-Language"] = app.$store.getters.getLang;
    if (!params) params = {};
    const object = axios({
      method: basicApi.order.my_ordered.method,
      url: geoServiceApiURL + basicApi.order.my_ordered.url,
      params: params,
      headers: headers,
    });
    let response = await object;
    return response;
  },
};
