const SET_SEARCH_DTO = "SET_SPINNER_APP";
const REMOVE_OBJECTS_SEARCH_DTO = "REMOVE_OBJECTS_SEARCH_DTO";
const SET_OBJECT_IMAGE = "SET_OBJECT_IMAGE";
const REMOVE_OBJECT_IMAGE = "REMOVE_OBJECT_IMAGE";
const CLEAR_SEARCH_DTO = "CLEAR_SEARCH_DTO";
export default {
  state: {
    searchDto: {},
    objsImage: [],
  },
  getters: {
    getSearchDto: (state) => {
      return state.searchDto;
    },
    getObjectsImage: (state) => {
      return state.objsImage;
    },
    getFileNameSearch: (state) => {
      return state.searchDto ? state.searchDto.file_name : "";
    },
  },
  mutations: {
    [SET_SEARCH_DTO](state, payload) {
      if (payload) {
        for (const [key, value] of Object.entries(payload)) {
          state.searchDto[key] = value;
        }
        if (payload.file_name) state.searchDto.file_name = payload.file_name;
        else state.searchDto.file_name = null;
      }
    },
    [REMOVE_OBJECTS_SEARCH_DTO](state, payload) {
      if (payload) {
        for (let i = 0; i < payload.length; i++) {
          if (state.searchDto[payload[i]] != undefined)
            delete state.searchDto[payload[i]] != undefined;
        }
      }
    },
    [SET_OBJECT_IMAGE](state, payload) {
      if (payload) {
        for (let i = 0; i < payload.length; i++) {
          let index = state.objsImage.findIndex((x) => x === payload[i]);
          if (index < 0) state.objsImage.push(payload[i]);
        }
      }
    },
    [REMOVE_OBJECT_IMAGE](state, payload) {
      if (payload) {
        for (let i = 0; i < payload.length; i++) {
          let index = state.objsImage.findIndex((x) => x === payload[i]);
          if (index >= 0) state.objsImage.splice(index, 1);
        }
      }
    },
    [CLEAR_SEARCH_DTO](state) {
      state.searchDto = {};
    },
  },
  actions: {
    setSearchDto({ commit }, payload) {
      commit(SET_SEARCH_DTO, payload);
    },
    removeObjectsSearchDto({ commit }, payload) {
      commit(REMOVE_OBJECTS_SEARCH_DTO, payload);
    },
    clearSearchDto({ commit }, payload) {
      commit(CLEAR_SEARCH_DTO, payload);
    },
    setObjectImage({ commit }, payload) {
      commit(SET_OBJECT_IMAGE, payload);
    },
    removeObjectImage({ commit }, payload) {
      commit(REMOVE_OBJECT_IMAGE, payload);
    },
  },
};
