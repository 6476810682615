import axios from "axios";
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { geoServiceApiURL } from "@/utils/commons/environment"
export default {
    async getImagesShare4Me(params,data, access_token) {
        let headers = basicApi.me_controller.share_for_me.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        const object = axios({
            method: basicApi.me_controller.share_for_me.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.share_for_me.url,
            params: params,
            data:data,
            headers: headers
        });
        let response = await object;
        return response;
    },
    async getShareByMe(params,data, access_token) {
        let headers = basicApi.me_controller.share_by_me.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        const object = axios({
            method: basicApi.me_controller.share_by_me.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.share_by_me.url,
            params: params,
            data:data,
            headers: headers
        });
        let response = await object;
        return response;
    },
    async getMyOrder(params, access_token) {
        let headers = basicApi.me_controller.my_order.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        const object = axios({
            method: basicApi.me_controller.my_order.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.my_order.url,
            params: params,
            headers: headers
        });
        let response = await object;
        return response;
    },
    async getMyCart(params, access_token) {
        let headers = basicApi.me_controller.my_cart.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        const object = axios({
            method: basicApi.me_controller.my_cart.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.my_cart.url,
            params: params,
            headers: headers
        });
        let response = await object;
        return response;
    },
    async getMyProject(params, access_token) {
        let headers = basicApi.me_controller.my_project.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        const object = axios({
            method: basicApi.me_controller.my_project.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.my_project.url,
            params: params,
            headers: headers
        });
        let response = await object;
        return response;
    },
    async getMyImages(params,data, access_token) {
        let headers = basicApi.me_controller.my_images.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        const object = axios({
            method: basicApi.me_controller.my_images.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.my_images.url,
            params: params,
            data:data,
            headers: headers
        });
        let response = await object;
        return response;
    },
    async cancelOrder(entity, access_token) {
        let headers = basicApi.me_controller.cancel_order.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.me_controller.cancel_order.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.cancel_order.url,
            data: entity,
            headers: headers,
            params: {
            }
        });
        let response = await object;
        return response;
    },
    async createShare(entity, access_token) {
        let headers = basicApi.me_controller.create_share.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.me_controller.create_share.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.create_share.url,
            data: entity,
            headers: headers,
            params: {
            }
        });
        let response = await object;
        return response;
    },
    async getMyLogs(params, access_token) {
        let headers = basicApi.me_controller.my_logs.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        const object = axios({
            method: basicApi.me_controller.my_logs.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.my_logs.url,
            data: params,
            params:params,
            headers: headers
        });
        let response = await object;
        return response;
    },
    async getStatistic(params, access_token) {
        let headers = basicApi.me_controller.statistic.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        const object = axios({
            method: basicApi.me_controller.statistic.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.statistic.url,
            data: params,
            headers: headers
        });
        let response = await object;
        return response;
    },
    async cancelOrder(params, access_token) {
        let headers = basicApi.me_controller.cancel_order.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        const object = axios({
            method: basicApi.me_controller.cancel_order.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.cancel_order.url,
            params: params,
            headers: headers
        });
        let response = await object;
        return response;
    },
    async removeUserFromShare(share_id ,user_id,access_token) {
        let headers = basicApi.me_controller.cancel_order.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.me_controller.cancel_order.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.cancel_order.url,
            params: {
                share_id: share_id,
                user_id: user_id,
            },
            headers: headers
        });
        let response = await object;
        return response;
    },
    
    
    async getRequestFreeImage(params,data, access_token) {
        let headers = basicApi.me_controller.get_request_free_image.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        const object = axios({
            method: basicApi.me_controller.get_request_free_image.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.get_request_free_image.url,
            params: params,
            data:data,
            headers: headers
        });
        let response = await object;
        return response;
    },
    
    
    async cancelRequestFreeImage(id, access_token) {
        let headers = basicApi.me_controller.cancel_request_free_image.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        let params = {};
        if (!params) params = {};
        const object = axios({
            method: basicApi.me_controller.cancel_request_free_image.method,
            url:  geoServiceApiURL  +
            basicApi.me_controller.cancel_request_free_image.url.replace("{id}",id),
            params:params,
            headers: headers
        });
        let response = await object;
        return response;
    },
}