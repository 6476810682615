<template>
    <div class="products">
        <div class="products__header">
            <h5 class="products__header-title">
                Sản phẩm
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </h5>
        </div>
        <div class="products__body">
            <egeos @productChange="productChange"></egeos>
        </div>
        <div class="products__footer">
          <button class="btn btn-primary" @click="onSearch"><i class="fas fa-search"></i>&nbsp;Tìm kiếm</button>
        </div>
    </div>
</template>
<script>
import VJstree from 'vue-jstree'
import Egeos from "./products/Egeos"
export default {
    props:{
        propData:{
            type:Object,
            default:()=>{
                return{
                    items:[]
                }
            }
        },
    },
    components:{
        VJstree, Egeos
    },
    data() {
        return {
            dataEgeos:{}
        }
    },
    computed:{
        accessToken(){
            return this.$store.getters.getAccessToken
        }
    },
    created(){},
    methods: {
        productChange(data){
            this.dataEgeos = Object.assign({},data.egeos);
        },
        itemClick (node) {},
        close(){
            this.$emit("close",{
                component:"products"
            })
        },
        onSearch(){
            this.$store.dispatch("setSearchDto",{
                egeos:this.dataEgeos
            })
            setTimeout(() => {
                this.$emit("onSearch", {
                    egeos:this.dataEgeos
                });
                this.close();    
            }, 100);
        }
    }
}
</script>
<style lang="scss" scoped>
.products{
    height: 592px;
    width: 100%;
    overflow-y: auto;
    .products__header{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 1rem;
      border-bottom: 1px solid #e9ecef;
      border-top-left-radius: .3rem;
      border-top-right-radius: .3rem;
      .products__header-title{
        width: 100%;
        margin-bottom: 0;
      }
    }
    .products__body{
      position: relative;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      padding: 1rem;
      max-height: 466px;
      overflow-y: auto;
      tr{
        &:hover{
            cursor: pointer;
        }
      }
      .tree-default .tree-wholerow-clicked{
          background: transparent!important;
      }
    }
    .products__footer{
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 1rem;
        border-top: 1px solid #e9ecef;
        button{
            font-size: 14px;
        }
    }
}
</style>
<style>
    .products__body .tree-default .tree-wholerow-clicked{
        background: transparent!important;
    }
</style>