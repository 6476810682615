<template>
  <CommonAccordion :propTitle="'Subscriptions'">
    <div slot="content">
      <div class="datatable">
        <DataTable
          :scrollable="true"
          :value="propItems"
          ref="dt"
          :loading="propLoading"
          :resizableColumns="false"
          columnResizeMode="expand"
          selectionMode="single"
          dataKey="id"
          contextMenu
          :scrollHeight="
            propTable.height - (propItems.length ? 40 : 0) - 38 + 'px'
          "
          :selection.sync="propTable.selected"
          :rowClass="rowClass"
          :virtualScroll="true"
          @virtual-scroll="onVirtualScroll"
          ><template #header v-if="propItems.length">
            <div class="text-left">
              <div>
                <div v-if="propItems.length" class="font-weight-normal">
                  {{
                    $t("label_show_from_to", null, {
                      from: 1,
                      to: propItems.length,
                      total: propTotal,
                    })
                  }}
                </div>
              </div>
            </div>
          </template>
          <template #empty v-if="propItems && propItems.length === 0">
            <span class="text-center">{{ $t("label_no_data") }}</span>
          </template>
          <Column
            header="No"
            headerStyle="width: 3rem; text-align: center!important"
            bodyStyle="width: 3rem;text-align: center;padding:0"
          >
            <template #body="slotProps">
              <div>
                {{ slotProps.index + 1 }}
              </div>
            </template></Column
          >
          <Column :header="'Id'" field="id"></Column>
          <Column header="Started At" field="startedAt">
            <template #body="slotProps"
              ><span v-if="convertString2DateTime(slotProps.data.startedAt)">{{
                (convertString2DateTime(slotProps.data.startedAt).getTime() /
                  1000)
                  | formatUnix
              }}</span></template
            >
          </Column>
          <Column header="Ended At" field="endedAt">
            <template #body="slotProps">
              <span v-if="convertString2DateTime(slotProps.data.endedAt)">{{
                (convertString2DateTime(slotProps.data.endedAt).getTime() /
                  1000)
                  | formatUnix
              }}</span></template
            ></Column
          >
          <Column
            :header="$t('label_status')"
            field="state"
            headerStyle="width: 7rem;"
            bodyStyle="width: 7rem;text-align: center;"
            ><template #body="slotProps">
              <div class="oval" :style="columnStyle(slotProps.data)">
                <span>{{ slotProps.data.status }}</span>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import CommonAccordion from "@/components/commons/accordion/Accordion3";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import Button from "@/components/commons/button/Button";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";
export default {
  components: { CommonAccordion, DataTable, Column, Button, ContextMenu },
  props: {
    propItems: {
      type: Array,
      default: () => [],
    },
    propTable: {
      type: Object,
      default: () => {},
    },
    propLoading: {
      type: Boolean,
      default: false,
    },
    propButtonDetail: {
      type: Object,
      default: () => {},
    },
    propTotal: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },

  methods: {
    onVirtualScroll(e) {
      this.$emit("nextPage");
    },
    go2OrderDetail(item) {
      this.propTable.selected = item;
      this.$router.push({
        name: "IntegratePlanetDetail",
        params: {
          id: item.id,
        },
      });
    },
    shoDialogCancelOrder(item) {
      this.propTable.selected = item;
      this.$emit("showDialogCancel", item);
    },
    onRowContextMenu(event) {
      this.propTable.selected = event.data;
      if (
        this.propTable.selected &&
        Object.keys(this.propTable.selected).length > 0
      ) {
        if (this.propTable.selected.state === "queued")
          this.menuModel[1].visible = true;
        else this.menuModel[1].visible = false;

        this.$refs.cmOrderDatatable.show(event.originalEvent);
      }
    },
    rowClass() {},

    columnStyle(val) {
      let result = "";
      switch (val.status) {
        case "active":
          result = "background:#27AE60";
          break;
        default:
          result = "background:#9da5b1";
          break;
      }
      return result;
    },
    convertString2DateTime(value) {
      try {
        return new Date(value);
      } catch (error) {
        return null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.datatable {
  .oval {
    height: 36px;
    margin: 0 auto;
    border-radius: 20px;
    text-align: center;
    span {
      text-align: center;
      vertical-align: middle;
      line-height: 36px;
      color: white;
      font-size: 12px;
    }
  }
  .item-link {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
<style lang="scss">
.integrate__datatable {
  @import "@/assets/scss/_datatable.scss";
}
</style>
