<template>
  <div class="statistic-download">
    <div class="statistic-upload__dialog">
      <DialogDetail
        v-if="dialog.detail.show"
        :prop-style="{ width: dialog.detail.width }"
        :prop-show="dialog.detail.show"
        :prop-title="dialog.detail.title"
        :prop-items="dialog.detail.items"
        @close="
          dialog.detail.show = false;
          dialog.detail.items = [];
        "
      />
    </div>
    <div class="statistic-download__search">
      <DownloadFilter
        :prop-search="statisticDownload.search"
        @onSearch="onSearch"
      />
    </div>
    <div class="statistic-download__datatable">
      <DownloadDataTable
        :prop-items="statisticDownload.items"
        @shoDialogDetail="shoDialogDetail"
      />
    </div>
    <div class="statistic-download__chart">
      <DownloadChart :prop-data="statisticDownload.items" />
    </div>
  </div>
</template>
<script>
import DownloadFilter from "./statisticdownload/Filter.vue";
import DownloadDataTable from "./statisticdownload/DataTable";
import DownloadChart from "./statisticdownload/DataChart";
import DialogDetail from "./statisticdownload/dialog/DialogDetail.vue";
import statisticFunc from "@/utils/functions/statistic";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";
import metaKeyFunc from "@/utils/functions/metakey";
export default {
  components: {
    DownloadFilter,
    DownloadDataTable,
    DownloadChart,
    DialogDetail,
  },
  props: {},
  data() {
    return {
      statisticDownload: {
        items: [],
        search: {
          from: null,
          to: null,
          page: 0,
          size: 2147483647,
        },
        table: {
          selected: null,
        },
        is_bottom: false,
      },
      provider: {
        items: [],
      },
      dialog: {
        detail: {
          show: false,
          title: "Chi tiết",
          items: [],
          width: "480px",
        },
      },
    };
  },
  async created() {
    setTimeout(() => {
      this.$emit("initBreadcrumb", [
        "menu_sidebar_statistic",
        "menu_sidebar_statistic_download",
      ]);
    }, 200);
    var vm = this;
    if (vm.documentWidth < 576) {
      this.dialog.detail.width = this.documentWidth + "px";
    } else if (vm.documentWidth >= 576 && vm.documentWidth < 768) {
      this.dialog.detail.width = "540px";
    } else if (vm.documentWidth >= 768 && vm.documentWidth < 992) {
      this.dialog.detail.width = "720px";
    } else {
      this.dialog.detail.width = "720px";
    }
    let today = new Date(),
      dateMinus7 = new Date();
    today.setHours(23);
    today.setMinutes(59);
    today.setSeconds(59);
    dateMinus7.setHours(0);
    dateMinus7.setMinutes(0);
    dateMinus7.setSeconds(0);
    this.statisticDownload.search.to = today;
    this.statisticDownload.search.from = new Date(
      dateMinus7.setDate(dateMinus7.getDate() - 7)
    );
    await this.getAllProvider();
    this.getSummary();
  },
  methods: {
    menuHeaderClick(val) {
      switch (val.code) {
        case "RELOAD":
          this.getSummary();
          break;
      }
    },
    onSearch(data) {
      this.statisticDownload.search.from = data.from;
      this.statisticDownload.search.to = data.to;
      this.getSummary();
    },
    async shoDialogDetail(item) {
      if (item.items && item.items.length > 0) {
        this.dialog.detail.items = item.items;
        this.dialog.detail.show = true;
      }
    },
    /*---------------------- Get data ---------------------------*/
    async getAllProvider() {
      try {
        let response = await metaKeyFunc.getByKey(
          "PROVIDER",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          this.provider.items =
            response.data && response.data.data ? response.data.data : [];

          this.provider.items.sort(function (a, b) {
            if (a.name < b.name) return -1;
            else if (a.name > b.name) return 1;
            return 0;
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getAllProvider);
      }
    },
    async getSummary() {
      this.statisticDownload.items = [];
      try {
        let response = await statisticFunc.getSummary(
          {
            from: this.statisticDownload.search.from,
            to: this.statisticDownload.search.to,
            type: "download",
          },
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            this.initData(response.data.data);
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401)
          this.refreshToken(this.getAllProvider);
      }
    },
    initData(data) {
      if (data && data.length > 0) {
        if (this.provider.items && this.provider.items.length > 0) {
          for (let i = 0; i < this.provider.items.length; i++) {
            if (
              this.provider.items[i].sub_meta_key &&
              this.provider.items[i].sub_meta_key.length > 0
            ) {
              for (
                let j = 0;
                j < this.provider.items[i].sub_meta_key.length;
                j++
              ) {
                try {
                  // let array = data.filter(
                  //   //(x) =>
                  //   // x.image &&
                  //   // x.image.satellite_type.name ===
                  //   //   this.provider.items[i].sub_meta_key[j].name
                  //   (x) =>
                  //     x.image &&
                  //     ((x.image.metadata &&
                  //       x.image.metadata.satellite_id ===
                  //         this.provider.items[i].sub_meta_key[j].id) ||
                  //       (x.image.metadata_extra &&
                  //         x.image.metadata_extra.satellite_id ===
                  //           this.provider.items[i].sub_meta_key[j].id))
                  // );
                   let array = data.filter(
                    //(x) =>
                    // x.image &&
                    // x.image.satellite_type.name ===
                    //   this.provider.items[i].sub_meta_key[j].name
                    (x) =>
                      x.image &&
                      ((x.image.info &&
                        x.image.info.satellite_id ===
                          this.provider.items[i].sub_meta_key[j].id))
                  );
                  if (array && array.length > 0) {
                    this.statisticDownload.items.push({
                      provider: this.provider.items[i].value,
                      satellite: this.provider.items[i].sub_meta_key[j].value,
                      total: array.reduce(
                        (sum, record) => sum + parseFloat(record["total"]),
                        0
                      ),
                      satellite_id: this.provider.items[i].sub_meta_key[j].id,
                      satellite_name:
                        this.provider.items[i].sub_meta_key[j].name,
                      provider_id: this.provider.items[i].id,
                      provider__name: this.provider.items[i].name,
                      items: array.map((x) => x.image.name),
                    });
                  }
                } catch (error) {}
              }
            }
          }
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {
            if (this.$route.name != "Login") {
              this.$router.push({
                name: "Login",
                query: {
                  next: btoa(this.$route.path),
                },
              });
            }
          });
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {
          if (this.$route.name != "Login") {
            this.$router.push({
              name: "Login",
              query: {
                next: btoa(this.$route.path),
              },
            });
          }
        });
      }
      localStorage.removeItem("lock-refresh");
    },
    /*---------------------- End get data -------------------------*/
  },
};
</script>
<style lang="scss" scoped>
.statistic-download__search {
  padding: 0.25rem;
}
.statistic-download__datatable {
  padding: 0.25rem;
}

.statistic-download__chart {
  padding: 0.25rem;
}
</style>