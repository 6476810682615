<template>
  <div class="tasking-time__radar">
    <div class="date-range">
      <div class="row no-gutters">
        <div class="col">
          <label
            >{{ $t("label_acceptable_date_range") }}&nbsp;<span class="required"
              >*</span
            ></label
          >
        </div>
        <div class="col">
          <label style="width: 90px" class="range-time-label">{{
            $t("label_earliest")
          }}</label>
          <date-picker
            style="width: calc(100% - 90px)"
            :format="'DD/MM/YYYY'"
            valuetype="timestamp"
            :input-class="'form-control'"
            v-model="propEntity.radar.earliest"
            :disabled-date="notBeforeToday"
            :disabled-time="notBeforeTodayTimeClock"
          >
          </date-picker>
        </div>
        <div class="col">
          <div class="row no-gutters">
            <div class="col">
              <AutoComplete
                v-model="propEntity.radar.earliest_hh"
                :suggestions="suggestionsHours"
                @complete="searchHour($event)"
                :dropdown="false"
                class="new-style"
                placeholder="HH"
                id="inputEarliestHH"
              />
            </div>
            <div class="col">
              <AutoComplete
                v-model="propEntity.radar.earliest_mm"
                :suggestions="suggestionsMinutes"
                @complete="searchMinute($event)"
                :dropdown="false"
                class="new-style"
                placeholder="MM"
                id="inputEarliestMM"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters mb-0">
        <div class="col"></div>
        <div class="col">
          <div class="form-group">
            <label style="width: 90px" class="range-time-label">{{
              $t("label_latest")
            }}</label>
            <date-picker
              style="width: calc(100% - 90px)"
              :format="'DD/MM/YYYY'"
              valuetype="timestamp"
              :input-class="'form-control'"
              v-model="propEntity.radar.latest"
              :disabled-date="notBeforeToday"
              :disabled-time="notBeforeTodayTimeClock"
            >
            </date-picker>
          </div>
        </div>
        <div class="col">
          <div class="row no-gutters">
            <div class="col">
              <AutoComplete
                v-model="propEntity.radar.latest_hh"
                :suggestions="suggestionsLatestHours"
                @complete="searchLatestHour($event)"
                :dropdown="false"
                class="new-style"
                placeholder="HH"
                id="inputLatestHH"
              />
            </div>
            <div class="col">
              <AutoComplete
                v-model="propEntity.radar.latest_mm"
                :suggestions="suggestionsLatestMinutes"
                @complete="searchLatestMinute($event)"
                :dropdown="false"
                class="new-style"
                placeholder="MM"
                id="inputLatestMM"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="incidence">
      <CommonAccordion
        :isChevronEnd="true"
        :propTitle="
          $t('label_incidence_angle') +
          '( 20-59,' +
          $t('label_or_leave_unspecified') +
          ')'
        "
        ><div slot="content" class="accordion">
          <div class="row no-gutters">
            <div class="col-4">
              <label>{{ $t("label_minimum") }}</label>
            </div>
            <div class="col-8">
              <AutoComplete
                v-model="propEntity.radar.minimum"
                :suggestions="suggestionsMinimum"
                @complete="searchMinimum($event)"
                :dropdown="false"
                class="new-style w-100"
                placeholder="[20-59]"
                id="inputMinimum"
              />
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-4">
              <label>{{ $t("label_maximum") }}</label>
            </div>
            <div class="col-8">
              <AutoComplete
                v-model="propEntity.radar.maximum"
                :suggestions="suggestionsMaximum"
                @complete="searchMaximum($event)"
                :dropdown="false"
                class="new-style w-100"
                id="inputMaximum"
                placeholder="[20-59]"
              />
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-4">
              <label>{{ $t("label_look_direction") }}</label>
            </div>
            <div class="col-8">
              <div class="d-flex">
                <div
                  class="p-field p-col"
                  style="width: 10rem"
                  v-for="item in propLookDirection"
                  :key="item.id"
                >
                  <RadioButton
                    :id="'radio' + item.id"
                    :name="item.id"
                    :value="item.id"
                    v-model="propEntity.radar.look_direction"
                  />
                  <label
                    :for="'radio' + item.id"
                    class="p-radiobutton-label pr-2"
                  >
                    {{ item.value }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row no-gutters mb-0">
            <div class="col-4">
              <label>{{ $t("label_orbit_direction") }}</label>
            </div>
            <div class="col-8">
              <div class="d-flex">
                <div
                  class="p-field p-col"
                  style="width: 10rem"
                  v-for="item in propOrbitDirection"
                  :key="item.id"
                >
                  <RadioButton
                    :id="'radio' + item.id"
                    :name="item.id"
                    :value="item.id"
                    v-model="propEntity.radar.orbit_direction"
                  />
                  <label
                    :for="'radio' + item.id"
                    class="p-radiobutton-label pr-2"
                  >
                    {{ item.value }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CommonAccordion>
    </div>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import InputText from "@/components/commons/inputtext/InputText";
import RadioButton from "@/components/commons/radiobutton/RadioButton";
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import AutoComplete from "@/components/commons/autocomplete/AutoComplete";
import { change_alias } from "@/utils/commons/common";
export default {
  components: {
    DatePicker,
    InputText,
    CommonAccordion,
    RadioButton,
    AutoComplete,
  },
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },
    propLookDirection: {
      type: Array,
      default: () => [],
    },
    propOrbitDirection: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      arrayHours: [],
      suggestionsHours: [],
      suggestionsMinutes: [],
      arrayMinutes: [],
      suggestionsLatestHours: [],
      suggestionsLatestMinutes: [],

      arrayIncidence: [],
      suggestionsMinimum: [],
      suggestionsMaximum: [],
    };
  },
  created() {
    for (let i = 0; i < 24; i++) {
      if (i < 10)
        this.arrayHours.push({
          id: "0" + i,
          value: "0" + i,
          label: "0" + i,
        });
      else
        this.arrayHours.push({
          id: "" + i,
          value: "" + i,
          label: "" + i,
        });
    }
    for (let i = 0; i < 60; i++) {
      if (i < 10)
        this.arrayMinutes.push({
          id: "0" + i,
          value: "0" + i,
          label: "0" + i,
        });
      else
        this.arrayMinutes.push({
          id: "" + i,
          value: "" + i,
          label: "" + i,
        });
    }
    for (let i = 20; i < 60; i++) {
      this.arrayIncidence.push({
        id: "" + i,
        value: "" + i,
        label: "" + i,
      });
    }
  },
  mounted() {
    this.setInputFilter(
      document.getElementById("inputEarliestHH"),
      function (value) {
        return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 23);
      }
    );
    this.setInputFilter(
      document.getElementById("inputLatestHH"),
      function (value) {
        return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 23);
      }
    );
    this.setInputFilter(
      document.getElementById("inputEarliestMM"),
      function (value) {
        return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 59);
      }
    );
    this.setInputFilter(
      document.getElementById("inputLatestMM"),
      function (value) {
        return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 59);
      }
    );

    this.setInputFilter(
      document.getElementById("inputMinimum"),
      function (value) {
        return (
          /^\d*$/.test(value) &&
          (value === "" || (parseInt(value) >= 0 && parseInt(value) <= 59))
        );
      }
    );
    this.setInputFilter(
      document.getElementById("inputMaximum"),
      function (value) {
        return (
          /^\d*$/.test(value) &&
          (value === "" || (parseInt(value) >= 0 && parseInt(value) <= 59))
        );
      }
    );
  },
  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    notBeforeTodayTimeClock(date) {
      let now = new Date();
      return (
        date <
        new Date(new Date().setHours(now.getHours(), now.getMinutes(), 0, 0))
      );
    },
    searchMaximum(e) {
      this.suggestionsMaximum = this.arrayIncidence
        .filter(
          (x) =>
            change_alias(x.value.trim().split(" ").join("")).startsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            ) ||
            change_alias(x.value.trim().split(" ").join("")).endsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            )
        )
        .map((x) => x.value);
    },
    searchMinimum(e) {
      this.suggestionsMinimum = this.arrayIncidence
        .filter(
          (x) =>
            change_alias(x.value.trim().split(" ").join("")).startsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            ) ||
            change_alias(x.value.trim().split(" ").join("")).endsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            )
        )
        .map((x) => x.value);
    },

    searchHour(e) {
      this.suggestionsHours = this.arrayHours
        .filter(
          (x) =>
            change_alias(x.value.trim().split(" ").join("")).startsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            ) ||
            change_alias(x.value.trim().split(" ").join("")).endsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            )
        )
        .map((x) => x.value);
    },
    searchMinute(e) {
      this.suggestionsMinutes = this.arrayMinutes
        .filter(
          (x) =>
            change_alias(x.value.trim().split(" ").join("")).startsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            ) ||
            change_alias(x.value.trim().split(" ").join("")).endsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            )
        )
        .map((x) => x.value);
    },

    searchLatestHour(e) {
      this.suggestionsLatestHours = this.arrayHours
        .filter(
          (x) =>
            change_alias(x.value.trim().split(" ").join("")).startsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            ) ||
            change_alias(x.value.trim().split(" ").join("")).endsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            )
        )
        .map((x) => x.value);
    },
    searchLatestMinute(e) {
      this.suggestionsLatestMinutes = this.arrayMinutes
        .filter(
          (x) =>
            change_alias(x.value.trim().split(" ").join("")).startsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            ) ||
            change_alias(x.value.trim().split(" ").join("")).endsWith(
              change_alias(e.query.toLowerCase().split(" ").join(""))
            )
        )
        .map((x) => x.value);
    },
    setInputFilter(textbox, inputFilter) {
      [
        "input",
        "keydown",
        "keyup",
        "mousedown",
        "mouseup",
        "select",
        "contextmenu",
        "drop",
      ].forEach(function (event) {
        textbox.addEventListener(event, function () {
          if (inputFilter(this.value)) {
            this.oldValue = this.value;
            this.oldSelectionStart = this.selectionStart;
            this.oldSelectionEnd = this.selectionEnd;
          } else if (this.hasOwnProperty("oldValue")) {
            this.value = this.oldValue;
            this.setSelectionRange(
              this.oldSelectionStart,
              this.oldSelectionEnd
            );
          } else {
            this.value = "";
          }
        });
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
.tasking-time__radar {
  padding: 0 0.5rem;
  .date-range {
    .row {
      padding: 0;
      margin: 0;
      margin-bottom: 1rem;
      .col {
        padding-right: 0.25rem;
        padding-left: 0.25rem;
        &:last-child {
          padding-right: 0;
        }
        &:first-child {
          padding-left: 0;
        }

        .range-time-label {
          line-height: 2.105rem;
          text-align: center;
          background-color: #009ef7; //#eee;
          border: 1px solid #009ef7; //#ccc;
          border-right: 0;
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
          color: #f1f4fa;
          font-size: 14px;
          margin: 0;
        }
      }
      .row {
        margin: 0;
      }
    }
  }
  .incidence {
    .row {
      padding: 0;
      margin: 0;
      margin-bottom: 1rem;
    }
  }
}
</style>
<style lang="scss">
.tasking-time__radar {
  .mx-input-wrapper {
    input {
      font-size: 14px;
      background: #eef3f7;
      border-radius: 4px;
      border-color: #eef3f7;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      &:hover {
        border-color: #212121 !important;
      }
      &:disabled,
      .disabled {
        background-color: transparent;
      }
    }
  }
  .accordion-header a {
    padding-left: 0 !important;
  }
  #inputEarliestHH {
    width: 100%;
  }
  #inputEarliestMM {
    width: 100%;
  }
  #inputLatestHH {
    width: 100%;
  }
  #inputLatestMM {
    width: 100%;
  }
  #inputMinimum {
    width: 100%;
  }
  #inputMaximum {
    width: 100%;
  }
}
</style>
