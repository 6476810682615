<template>
  <div class="tasking-map" id="taskingMap"></div>
</template>
<script>
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import { Tile as TileLayer } from "ol/layer";
import XYZ from "ol/source/XYZ";
import { TileImage } from "ol/source";
import { defaults as defaultControls, FullScreen } from "ol/control";
import { fromLonLat, transform } from "ol/proj";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
import { Vector as VectorLayer, Image as ImageLayer } from "ol/layer";
import { Vector as VectorSource, ImageCanvas } from "ol/source";
import {
  Style,
  Fill,
  Stroke,
  Circle,
  Icon,
  Text,
  RegularShape,
} from "ol/style.js";

export default {
  data() {
    return {
      map: Object,
      layer: Object,
    };
  },
  mounted() {
    setTimeout(() => {
      this.initMapOpenlayer();
    }, 100);
  },
  methods: {
    initMapOpenlayer: function () {
      var vm = this;
      var source = new VectorSource({ wrapX: false, id: "sourcedraw" });

      this.layer = new VectorLayer({
        source: source,
      });
      this.layer.set("id", "layerdraw");
      this.map = new Map({
        controls: defaultControls({ attribution: false, zoom: false }),
        layers: [
          new TileLayer({
            title: "Basemap",
            id: "vgbasemap",
            source: new XYZ({
              url:
                vm.basemap &&
                Object.keys(vm.basemap).length > 0 &&
                vm.basemap.source &&
                vm.basemap.source.url
                  ? vm.basemap.source.url
                  : process.env.VUE_APP_BASEMAP_URL,
            }),
            show: true,
            opacity: 1,
          }),
          this.layer,
        ],
        target: "taskingMap",
        view: new View({
          center: fromLonLat(
            vm.propPoints && vm.propPoints.length > 0
              ? vm.propPoints[0]
              : [108.222848, 16.062833]
          ),
          zoom: 8,
          minZoom: 0,
          maxZoom: 20,
        }),
      });
      this.$emit("initMap", {
        map: this.map,
        layer: this.layer,
      });
    },
  },
  computed: {
    basemap() {
      return this.$store.getters.getBasemap;
    },
  },
};
</script>
<style lang="scss" scoped>
.tasking-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
}
</style>
