<template>
    <div class="container-fluid login-page"></div>
</template>
<script>
export default {
  mounted(){
    if(this.loggedIn){
      this.$store.dispatch("setIsLoginForm", false);
      this.$router.push({
        name:"UserInfo"
      })
    }else{      
      this.$store.dispatch("setIsLoginForm", true);
    }
  },
  computed:{
    loggedIn(){
      return this.$store.getters.loggedIn
    }
  },
}
</script>
<style lang="scss" scoped>
.login-page{
   display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url("/img/background.jpg");
  background-size: 100%;
}
</style>