import axios from "axios";
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { geoServiceApiURL } from "@/utils/commons/environment"
export default {
    async create(entity, access_token) {
        let headers = basicApi.cart.create.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.cart.create.method,
            url:  geoServiceApiURL  +
            basicApi.cart.create.url,
            data: entity,
            headers: headers,
            params: {}
        });
        let response = await object;
        return response;
    },
    async getMyOrderd(params, access_token) {
        let headers = basicApi.cart.my_orderd.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        if (!params) params = {};
        const object = axios({
            method: basicApi.cart.my_orderd.method,
            url:  geoServiceApiURL  +
            basicApi.cart.my_orderd.url,
            params: params,
            headers: headers
        });
        let response = await object;
        return response;
    },
    async addItem(imageId, access_token) {
        let headers = basicApi.cart.add_item.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.cart.add_item.method,
            url:  geoServiceApiURL  +
            basicApi.cart.add_item.url,
            params: {
                image_id: imageId,
            },
            headers: headers
        });
        let response = await object;
        return response;
    },
    async removeItem(imageId, access_token) {
        let headers = basicApi.cart.remove_item.headers;
        headers["Authorization"] = "bearer " + access_token;
        headers['Accept-Language'] = app.$store.getters.getLang;
        const object = axios({
            method: basicApi.cart.remove_item.method,
            url:  geoServiceApiURL  +
            basicApi.cart.remove_item.url,
            params: {
                image_id: imageId,
            },
            headers: headers
        });
        let response = await object;
        return response;
    },
}