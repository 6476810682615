<template>
  <div class="tasking-sensor-mode">
    <div class="row no-gutters">
      <div class="col-3">
        <label
          >{{ $t("label_image_mode") }}&nbsp;<span class="required"
            >*</span
          ></label
        >
      </div>
      <div class="col-9">
        <Dropdown
          class="new-style w-100"
          :options="propBeamMode"
          v-model="propEntity.radar.beam_mode"
          :showClear="false"
          :filter="true"
          optionValue="id"
          optionLabel="value"
          @change="beamModeChange"
        />
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-3">
        <label
          >{{ $t("label_pol_layer") }}&nbsp;<span class="required"
            >*</span
          ></label
        >
      </div>
      <div class="col-9">
        <Dropdown
          class="new-style w-100"
          :options="arrayPolLayer"
          v-model="propEntity.radar.pol_layer"
          :showClear="false"
          :filter="true"
          optionValue="id"
          optionLabel="value"
          @change="polLayerChange"
        />
      </div>
    </div>
    <div class="row no-gutters">
      <CommonAccordion
        :isChevronEnd="true"
        :propTitle="$t('label_interferometry')"
        ><div slot="content" class="accordion">
          <div class="row no-gutters">
            <div class="col-3">
              <label>{{ $t("label_number_of_iterations") }}</label>
            </div>
            <div class="col-9">
              <InputText
                type="number"
                class="form-control new-style"
                min="1"
                max="100"
                v-model="propEntity.radar.number_of_iterations"
              />
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-3">
              <label>{{ $t("label_granularity") }}</label>
            </div>
            <div class="col-9">
              <div class="d-flex flex-wrap">
                <div
                  :style="{ minWidth: '9rem' }"
                  class="p-field p-col d-flex"
                  v-for="item in propGranularities"
                  :key="item.id"
                >
                  <RadioButton
                    :id="'radio' + item.id"
                    :name="item.id"
                    :value="item.id"
                    v-model="propEntity.radar.granularity"
                  />
                  <label
                    :for="'radio' + item.id"
                    class="p-radiobutton-label pr-2 pb-3"
                  >
                    {{ $t(item.value) }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CommonAccordion>
    </div>
  </div>
</template>
<script>
import InputText from "@/components/commons/inputtext/InputText";
import Dropdown from "@/components/commons/dropdown/Dropdown";
import RadioButton from "@/components/commons/radiobutton/RadioButton";
import CommonAccordion from "@/components/commons/accordion/Accordion2";
export default {
  components: {
    Dropdown,
    RadioButton,
    CommonAccordion,
    InputText,
  },
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },
    propGranularities: {
      type: Array,
      default: () => [],
    },
    propBeamMode: {
      type: Array,
      default: () => [],
    },
    propPolLayer: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      arrayPolLayer: [],
    };
  },
  created() {
    if (this.propPolLayer)
      this.arrayPolLayer = Object.assign([], this.propPolLayer);
    if (!this.propGranularities || this.propGranularities.length === 0) {
      this.$emit("getAllTaskingGranularity");
    }
    if (!this.propBeamMode || this.propBeamMode.length === 0) {
      this.$emit("getAllTaskingBeamMode");
    }
  },
  methods: {
    beamModeChange(e) {
      this.propEntity.radar.pol_layer = null;
      if (!e.value) this.propPolLayer = [];
      else {
        let item = this.propBeamMode.find((x) => x.id === e.value);
        this.propEntity.radar.beam_mode_name =
          item && item.value ? item.value.trim() : null;
        this.arrayPolLayer = item && item.sub_meta_key ? item.sub_meta_key : [];
        this.$emit("setPolLayer", this.arrayPolLayer);
      }
    },

    polLayerChange(event) {
      let item = this.arrayPolLayer.find((x) => x.id === event.value);
      this.propEntity.radar.pol_layer_name =
        item && item.value ? item.value.trim() : null;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.tasking-sensor-mode {
  padding: 0 0.5rem;
  width: 100%;
  color: $text-color;
  .row {
    margin-bottom: 1rem;
  }

  .accordion {
    width: 100%;
    .row {
      margin-bottom: 1rem;
    }
  }
}
</style>
<style lang="scss">
.tasking-sensor-mode {
  .accordion .accordion-header a {
    padding-left: 0 !important;
  }
}
</style>
