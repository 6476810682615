<template>
  <Dialog
    id="dialogOrderDetail"
    :header="$t('title_image_list')"
    :visible.sync="show"
    :style="{ zIndex: 1050 }"
    :modal="true"
    :contentStyle="propStyle"
    @hide="onClose"
  >
    <div class="datatable">
      <ContextMenu
        :model="menuModel"
        ref="cmDlgOrderDetailDatatable"
        :appendTo="'dialogOrderDetail'"
      />
      <DataTable
        :value="items"
        ref="dt"
        :resizableColumns="true"
        columnResizeMode="expand"
        selectionMode="single"
        dataKey="id"
        contextMenu
        :selection.sync="selected"
        @row-contextmenu="onRowContextMenu"
        @row-click="rowClick"
      >
        <Column>
          <template #body="slotProps">
            <div class="d-flex">
              <div>
                <Checkbox
                  class="pt-1 mr-2"
                  v-model="selectedIds"
                  :binary="false"
                  :value="slotProps.data.id"
                />
              </div>
              <div class="media">
                <icon-preview
                  :propId="
                    slotProps.data.preview && slotProps.data.preview.icon_path
                      ? slotProps.data.preview.icon_path
                      : slotProps.data.preview &&
                        slotProps.data.preview.preview_path
                      ? slotProps.data.preview.preview_path
                      : null
                  "
                  :prop-src="
                    slotProps.data.preview && slotProps.data.preview.icon_path
                      ? slotProps.data.preview.icon_path
                      : slotProps.data.preview &&
                        slotProps.data.preview.preview_path
                      ? slotProps.data.preview.preview_path
                      : null
                  "
                  :propPreviewImages="
                    slotProps.data.preview &&
                    slotProps.data.preview.preview_images
                      ? slotProps.data.preview.preview_images
                      : []
                  "
                ></icon-preview>
                <div class="media-body">
                  <ul>
                    <li class="lbl-geostore">
                      <strong>{{ slotProps.data.name }}</strong>
                    </li>
                    <li class="mt-1">
                      <div class="">
                        <span
                          >{{ $t("label_image_time") }}:
                          <strong>{{
                            slotProps.data && slotProps.data.info
                              ? slotProps.data.info.imaging_date
                              : ""
                          }}</strong>
                        </span>
                        <span class="pl-2 pr-2">|</span>
                        <span
                          >{{ $t("label_satellite") }}:
                          <strong>{{
                            slotProps.data.info && slotProps.data.info.satellite
                              ? slotProps.data.info.satellite.value
                              : slotProps.data.metadata &&
                                slotProps.data.metadata.satellite
                              ? slotProps.data.metadata.satellite.value
                              : slotProps.data.metadata_extra
                              ? (slotProps.data.metadata_extra.mission
                                  ? slotProps.data.metadata_extra.mission
                                  : "") +
                                " " +
                                (slotProps.data.metadata_extra.mission_index
                                  ? slotProps.data.metadata_extra.mission_index
                                  : "")
                              : ""
                          }}</strong>
                        </span>
                      </div>
                    </li>
                    <ItemMetadata
                      :prop-item="slotProps.data"
                      :propImageTypes="propImageTypes"
                    />
                  </ul>
                </div>
              </div>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <template #footer>
      <button
        :label="$t('button_text_download')"
        :icon="'fas fa-download'"
        class="btn btn-geostore2 btn-sm mr-0 float-left"
        @click="onDownloadImages"
        :disabled="
          !selectedIds ||
          selectedIds.length === 0 ||
          (propButtons && propButtons.download && propButtons.download.disabled)
        "
      >
        <i
          :class="
            propButtons &&
            propButtons.download &&
            propButtons.download.processing
              ? 'fas fa-spinner fa-spin'
              : 'fas fa-download'
          "
        ></i
        >&nbsp;
        {{ $t("button_text_download") }}
      </button>
      <Button
        :label="$t('button_text_close')"
        :icon="'fas fa-times'"
        :class="'p-button-danger p-button-sm mr-0'"
        @click="onClose"
      />
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/components/commons/dialog/Dialog";
import Button from "@/components/commons/button/Button";
import IconPreview from "./dialogimagelist/IconPreview.vue";
import ItemMetadata from "./dialogimagelist/ItemMetadata.vue";
import DataTable from "@/components/commons/datatable/DataTable";
import Column from "@/components/commons/column/Column";
import ContextMenu from "@/components/commons/contextmenu/ContextMenu";

import Checkbox from "@/components/commons/checkbox/Checkbox";
export default {
  components: {
    Dialog,
    Button,
    IconPreview,
    ItemMetadata,
    DataTable,
    Column,
    ContextMenu,
    Checkbox,
  },
  props: {
    propShared: {
      type: Array,
      default: () => [],
    },
    propItems: {
      type: Array,
      default: () => [],
    },
    propTitle: {
      type: String,
      default: "",
    },
    propStyle: {
      type: Object,
      default: () => {
        return {
          width: "480px",
        };
      },
    },
    propShow: {
      type: Boolean,
      default: false,
    },
    propOrderStatusCompleted: {
      type: Boolean,
      default: false,
    },
    propButtons: {
      type: Object,
      default: () => [],
    },
    propImageTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedIds: null,
      selected: null,
      menuModel: [
        {
          label: "Metadata",
          icon: "fas fa-list-alt",
          command: () => this.openDialogMetadata(this.selected),
          visible: true,
        },
      ],
      time: new Date().getTime(),
      items: [],
    };
  },
  created() {
    this.show = this.propShow;
    if (!this.propSatelliteType || this.propSatelliteType.length === 0) {
      this.$emit("getAllSatelliteType");
    }
    if (this.propItems) {
      this.items = this.propItems.map((x) => x.image);
      this.selectedIds = this.items
        .map((x) => x.id)
        .filter((y) => !this.propShared.includes(y));
    }
  },
  watch: {
    propShow: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
  },
  methods: {
    onClose() {
      this.show = false;
      this.$emit("close");
    },
    onDownloadImages() {
      if (this.selectedIds && this.selectedIds.length > 0) {
        this.$emit("downloadImages", this.selectedIds);
      }
    },
    openDialogMetadata(item) {
      this.$emit("showDialogMetadata", item);
    },

    onRowContextMenu(event) {
      if (this.selected && Object.keys(this.selected).length > 0) {
        this.$refs.cmDlgOrderDetailDatatable.show(event.originalEvent);
      }
    },
    rowClick(e) {
      if (this.selected) {
        if (e.data.id == this.selected.id) {
          if (this.time) {
            if (new Date().getTime() - this.time <= 300) {
              // this.$emit("dbClick", {
              //   e: e,
              //   item: this.selected,
              // });
              this.$emit("showDialogMetadata", this.selected);
            }
          }
        }
      }
      this.time = new Date().getTime();
    },
  },
};
</script>
<style lang="scss" scoped>
.list-group {
  overflow-y: auto;
}
.list-group-item {
  padding: 0.5rem;
  border: 0;
  border-radius: 0;
  border-top: 1px solid rgba($color: #022259, $alpha: 0.4);
  &:first-child {
    border-top: 0;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba($color: #c7e7f9, $alpha: 0.5);
  }
}
.media {
  position: relative;
  img {
    border: 1px solid rgba($color: #022259, $alpha: 0.5);
    width: 64px;
    height: 64px;
    &:hover {
      cursor: pointer;
      border: 1px solid rgba($color: #022259, $alpha: 0.8);
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 13px;
  }
  button.remove-item {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
.img-shared {
  width: 28px;
}
button {
  font-size: 14px;
  .btn-share {
    width: 110px !important;
  }
  .btn-approve {
    width: 110px !important;
  }
}
</style>
<style lang="scss">
.order__dialog--images {
  .datatable {
    .p-datatable-thead {
      display: none;
    }
  }
}
</style>
