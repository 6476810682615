var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tbody',{staticClass:"p-datatable-tbody"},[(!_vm.empty)?[_vm._l((_vm.value),function(rowData,index){return [(
          _vm.templates['groupheader'] &&
          _vm.rowGroupMode === 'subheader' &&
          _vm.shouldRenderRowGroupHeader(_vm.value, rowData, index)
        )?_c('tr',{key:_vm.getRowKey(rowData, index) + '_subheader',staticClass:"p-rowgroup-header"},[_c('td',{attrs:{"colspan":_vm.columns.length - 1}},[(_vm.expandableRowGroups)?_c('button',{staticClass:"p-row-toggler p-link",attrs:{"type":"button"},on:{"click":function($event){return _vm.onRowGroupToggle($event, rowData)}}},[_c('span',{class:_vm.rowGroupTogglerIcon(rowData)})]):_vm._e(),_c('DTRowExpansionTemplate',{attrs:{"template":_vm.templates['groupheader'],"data":rowData,"index":index}})],1)]):_vm._e(),(_vm.expandableRowGroups ? _vm.isRowGroupExpanded(rowData) : true)?_c('tr',{key:_vm.getRowKey(rowData, index),class:_vm.getRowClass(rowData),attrs:{"tabindex":_vm.selectionMode || _vm.contextMenu ? '0' : null},on:{"click":function($event){return _vm.onRowClick($event, rowData, index)},"contextmenu":function($event){return _vm.onRowRightClick($event, rowData, index)},"touchend":function($event){return _vm.onRowTouchEnd($event)},"keydown":function($event){return _vm.onRowKeyDown($event, rowData, index)},"mousedown":function($event){return _vm.onRowMouseDown($event)},"dragstart":function($event){return _vm.onRowDragStart($event, index)},"dragover":function($event){return _vm.onRowDragOver($event, index)},"dragleave":function($event){return _vm.onRowDragLeave($event)},"dragend":function($event){return _vm.onRowDragEnd($event)},"drop":function($event){return _vm.onRowDrop($event)}}},[_vm._l((_vm.columns),function(col,i){return [(_vm.shouldRenderBodyCell(_vm.value, col, index))?_c('DTBodyCell',{key:col.columnKey || col.field || i,attrs:{"rowData":rowData,"column":col,"index":index,"selected":_vm.isSelected(rowData),"rowTogglerIcon":col.expander ? _vm.rowTogglerIcon(rowData) : null,"rowspan":_vm.rowGroupMode === 'rowspan'
                ? _vm.calculateRowGroupSize(_vm.value, col, index)
                : null,"editMode":_vm.editMode,"editing":_vm.editMode === 'row' && _vm.isRowEditing(rowData)},on:{"radio-change":function($event){return _vm.onRadioChange($event)},"checkbox-change":function($event){return _vm.onCheckboxChange($event)},"row-toggle":function($event){return _vm.onRowToggle($event)},"cell-edit-init":function($event){return _vm.onCellEditInit($event)},"cell-edit-complete":function($event){return _vm.onCellEditComplete($event)},"cell-edit-cancel":function($event){return _vm.onCellEditCancel($event)},"row-edit-init":function($event){return _vm.onRowEditInit($event)},"row-edit-save":function($event){return _vm.onRowEditSave($event)},"row-edit-cancel":function($event){return _vm.onRowEditCancel($event)}}}):_vm._e()]})],2):_vm._e(),(
          _vm.templates['expansion'] && _vm.expandedRows && _vm.isRowExpanded(rowData)
        )?_c('tr',{key:_vm.getRowKey(rowData, index) + '_expansion',staticClass:"p-datatable-row-expansion"},[_c('td',{attrs:{"colspan":_vm.columns.length}},[_c('DTRowExpansionTemplate',{attrs:{"template":_vm.templates['expansion'],"data":rowData,"index":index}})],1)]):_vm._e(),(
          _vm.templates['groupfooter'] &&
          _vm.rowGroupMode === 'subheader' &&
          _vm.shouldRenderRowGroupFooter(_vm.value, rowData, index)
        )?_c('tr',{key:_vm.getRowKey(rowData, index) + '_subfooter',staticClass:"p-rowgroup-footer"},[_c('DTRowExpansionTemplate',{attrs:{"template":_vm.templates['groupfooter'],"data":rowData,"index":index}})],1):_vm._e()]})]:_c('tr',{staticClass:"p-datatable-emptymessage"},[_c('td',{attrs:{"colspan":_vm.columns.length}},[(_vm.templates.empty && !_vm.loading)?_c('DTSlotTemplate',{attrs:{"template":_vm.templates.empty}}):_vm._e(),(_vm.templates.loading && _vm.loading)?_c('DTSlotTemplate',{attrs:{"template":_vm.templates.loading}}):_vm._e()],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }