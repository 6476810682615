import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { geoServiceApiURL } from "@/utils/commons/environment"

export default {
    async getMetadata(path, access_token) {
      let headers = basicApi.file.get_metadata.headers;
      if(!headers)
        headers= {}
      headers["Authorization"] = "bearer " + access_token;
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object =  axios({
        method: basicApi.file.get_metadata.method,
        url: geoServiceApiURL + basicApi.file.get_metadata.url,
        params: {
          path: path,
        },
        headers: headers,
      });
  
      let response = await object;
      return response;
    },
    
    async getMetadata2(params, access_token) {
      let headers = basicApi.file.get_metadata.headers;
      if(!headers)
        headers= {}
      headers["Authorization"] = "bearer " + access_token;      
      headers['Accept-Language'] = app.$store.getters.getLang;
      if (!params) params = {};
      const object =  axios({
        method: basicApi.file.get_metadata.method,
        url: geoServiceApiURL + basicApi.file.get_metadata.url,
        params: params,
        headers: headers,
      });
  
      let response = await object;
      return response;
    },
    async downloadPreview(key, access_token) {
      let headers = basicApi.file.download_preview.headers;
      if(!headers)
        headers= {}
      headers["Authorization"] = "bearer " + access_token;
      headers["Access-Control-Expose-Headers"] = "Content-Disposition";
      headers['Accept-Language'] = app.$store.getters.getLang;
      const object =  axios({
        method: basicApi.file.download_preview.method,
        url:geoServiceApiURL + basicApi.file.download_preview.url.replace("{key}",key),
        responseType:'blob',
        headers: headers,
        params: {
        }
      });
  
      let response = await object;
      return response;
    },
}