<template>
  <div class="order-info">
    <CommonAccordion :isChevronEnd="true" :propTitle="$t('label_order_info')"
      ><div slot="content" class="accordion w-100">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="row no-gutters">
              <label class="col-12 col-md-4 col-form-label">{{
                $t("label_order_code")
              }}</label>
              <div class="col-12 col-md-8">
                <InputText
                  class="form-control new-style w-100"
                  maxlength="50"
                  :value="propItem.order_code"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="row no-gutters">
              <label class="col-12 col-md-4 col-form-label">{{
                $t("label_order_time")
              }}</label>
              <div class="col-12 col-md-8">
                <InputText
                  class="form-control new-style w-100"
                  maxlength="50"
                  :value="formatUnixDateTime2String(propItem.created_at)"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="row no-gutters">
              <label class="col-12 col-md-4 col-form-label">{{
                $t("label_order_status")
              }}</label>
              <div class="col-12 col-md-8">
                <InputText
                  class="form-control new-style w-100"
                  maxlength="50"
                  :value="orderStatus"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="row no-gutters">
              <label class="col-12 col-md-4 col-form-label">{{
                $t("label_priority")
              }}</label>
              <div class="col-12 col-md-8">
                <InputText
                  class="form-control new-style w-100"
                  maxlength="50"
                  :value="orderPriority"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="row no-gutters">
              <label class="col-12 col-md-4 col-form-label">{{
                $t("label_area")
              }}</label>
              <div class="col-12 col-md-8">
                <InputText
                  class="form-control new-style w-100"
                  maxlength="50"
                  :value="area"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="row no-gutters">
              <button
                class="btn btn-primary btn-sm"
                title="Shape File (shp files archived)"
                @click="exportShapeFile"
              >
                {{ $t("button_text_export") }} &nbsp;AOI
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row no-gutters">
              <label
                class="col-form-label"
                style="width: calc((100% / 6) - 4px)"
                >{{ $t("label_order_note_new") }}</label
              >
              <div class="" style="width: calc((100% / 6) * 5 - 4px)">
                <InputText
                  class="form-control new-style w-100"
                  maxlength="255"
                  :value="propItem.note"
                  readonly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonAccordion>
  </div>
</template>
<script>
var formatArea = function (polygon) {
  var area = getArea(polygon);
  var output;
  if (area > 10000) {
    output = ((area / 1000000) * 100) / 100 + " " + "km2";
  } else {
    output = (area * 100) / 100 + " " + "m2";
  }
  return output;
};
import InputText from "@/components/commons/inputtext/InputText";
import CommonAccordion from "@/components/commons/accordion/Accordion2";
import moment from "moment";
import { getArea } from "ol/sphere.js";
import { transform } from "ol/proj";
import Polygon from "ol/geom/Polygon";
import shpwrite from "@/utils/commons/shp-write";
export default {
  components: {
    InputText,
    CommonAccordion,
  },
  props: {
    propItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment
            .unix(date.getTime() / 1000)
            .format("HH:mm DD/MM/YYYY");
          result = result
            .replace(":", this.lang === "vi" ? "h" : ":")
            .replace(" ", this.lang === "vi" ? " ngày " : " ");
        } catch (error) {}
      }
      return result;
    },

    exportShapeFile() {
      if (this.propItem && this.propItem.geom) {
        if (
          this.propItem.geom &&
          this.propItem.geom.coordinates &&
          this.propItem.geom.coordinates.length > 0
        ) {
          let points = [];
          for (let j = 0; j < this.propItem.geom.coordinates[0].length; j++) {
            try {
              points.push(
                transform(
                  this.propItem.geom.coordinates[0][j],
                  "EPSG:4326",
                  "EPSG:3857"
                )
              );
            } catch (error) {}
          }
          if (points.length > 0) {
            try {
              let geoFeatures = {
                type: "FeatureCollection",
                features: [
                  {
                    type: "Feature",
                    properties: {
                      area: formatArea(new Polygon([points])),
                    },
                    geometry: {
                      type: "Polygon",
                      coordinates: this.propItem.geom.coordinates,
                    },
                  },
                ],
              };
              if (geoFeatures.features) {
                var options = {
                  folder: "shapes",
                  types: {
                    point: "POINTS",
                    polygon: "POLYGONS",
                    line: "LINES",
                  },
                };
                shpwrite.download(geoFeatures, options);
              }
            } catch (error) {}
          }
        }
      }
    },
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    orderStatus() {
      let result = "";
      if (this.propItem && this.propItem.current_status) {
        result =
          this.propItem.current_status.translation &&
          this.propItem.current_status.translation[this.lang]
            ? this.propItem.current_status.translation[this.lang].value
            : this.propItem.current_status.value;
      }
      return result;
    },
    orderPriority() {
      let result = "";
      if (this.propItem && this.propItem.priority) {
        result =
          this.propItem.priority.translation &&
          this.propItem.priority.translation[this.lang]
            ? this.propItem.priority.translation[this.lang].value
            : this.propItem.priority.value;
      }
      return result;
    },
    area() {
      let result = "";
      if (this.propItem && this.propItem.geom) {
        if (
          this.propItem.geom &&
          this.propItem.geom.coordinates &&
          this.propItem.geom.coordinates.length > 0
        ) {
          let points = [];
          for (let j = 0; j < this.propItem.geom.coordinates[0].length; j++) {
            try {
              points.push(
                transform(
                  this.propItem.geom.coordinates[0][j],
                  "EPSG:4326",
                  "EPSG:3857"
                )
              );
            } catch (error) {}
          }
          if (points.length > 0) {
            try {
              return formatArea(new Polygon([points]));
            } catch (error) {}
          }
        }
      }
      return result;
    },
  },
};
</script>

<style lang="scss">
.order-info {
  .accordion {
    padding: 0.5rem;
    .accordion-header a {
      padding-left: 0;
    }
  }
  .row {
    margin-bottom: 0.25rem;
  }
}
</style>
