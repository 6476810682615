<template>
  <div class="confirmation-tasking-time-optical">
    <div class="row no-gutters">
      <div class="col">
        <fieldset class="p-0">
          <legend class="w-auto">
            {{ $t("label_request_collection_date_range") }}
          </legend>
          <div class="w-100 d-flex mb-3">
            <label style="width: 64px" class="range-time-label">{{
              $t("label_from")
            }}</label>
            <InputText
              style="width: calc(100% - 64px)"
              class="form-control new-style"
              :value="
                formatUnixDateTime2String(
                  new Date(propDto.optical.start).getTime()
                )
              "
              readonly
              maxlength="25"
            />
          </div>
          <div class="w-100 d-flex">
            <label style="width: 64px" class="range-time-label">{{
              $t("label_to")
            }}</label>
            <InputText
              style="width: calc(100% - 64px)"
              class="form-control new-style"
              :value="
                formatUnixDateTime2String(
                  new Date(propDto.optical.end).getTime()
                )
              "
              readonly
              maxlength="25"
            />
          </div>
        </fieldset>
      </div>
      <div class="col">
        <fieldset class="p-0">
          <legend class="w-auto">{{ $t("label_incidence_angle") }} (°)</legend>
          <div class="w-100 d-flex mb-3">
            <label style="width: 64px" class="range-time-label">{{
              $t("label_from")
            }}</label>
            <InputText
              style="width: calc(100% - 64px)"
              class="form-control new-style"
              :value="propDto.optical.inc_angle_from"
              readonly
              maxlength="255"
            />
          </div>
          <div class="w-100 d-flex">
            <label style="width: 64px" class="range-time-label">{{
              $t("label_to")
            }}</label>
            <InputText
              style="width: calc(100% - 64px)"
              class="form-control new-style"
              :value="propDto.optical.inc_angle_to"
              readonly
              maxlength="255"
            />
          </div>
        </fieldset>
      </div>
      <div class="col">
        <fieldset class="p-0">
          <legend class="w-auto">{{ $t("label_cloud_cove") }} (%)</legend>
          <!--<div class="w-100 d-flex mb-3">
             <label style="width: 64px" class="range-time-label">Từ</label>
            <InputText
              style="width: calc(100% - 64px)"
              class="form-control new-style"
              :value="propDto.optical.cloud_coverage_from"
              readonly
              maxlength="255"
            /> 
          </div>-->
          <div class="w-100 d-flex">
            <label style="width: 64px" class="range-time-label">{{
              $t("label_to")
            }}</label>
            <InputText
              style="width: calc(100% - 64px)"
              class="form-control new-style"
              :value="propDto.optical.cloud_coverage_to"
              readonly
              maxlength="255"
            />
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row no-gutters">
      <label class="col-12 col-md-2 col-form-label">{{
        $t("label_collection_order_type")
      }}</label>
      <div class="col-12 col-md-10">
        <InputText
          class="form-control new-style"
          maxlength="255"
          :value="propDto.optical.order_type_name"
          readonly
        />
      </div>
    </div>
  </div>
</template>
<script>
import InputText from "@/components/commons/inputtext/InputText";
import moment from "moment";
export default {
  components: {
    InputText,
  },
  props: {
    propDto: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    formatUnixDateTime2String(value) {
      let result = "";
      if (value) {
        try {
          let date = new Date(value);
          result = moment.unix(date.getTime() / 1000).format("DD/MM/YYYY");
        } catch (error) {}
      }
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
.confirmation-tasking-time-optical {
  .row {
    margin-bottom: 1rem;
    .col {
      padding-right: 0.25rem;
      padding-left: 0.25rem;
      &:last-child {
        padding-right: 0;
      }
    }
  }
  legend {
    font-size: 14px;
  }
  .range-time-label {
    line-height: 2.105rem;
    text-align: center;
    background-color: #009ef7; //#eee;
    border: 1px solid #009ef7; //#ccc;
    color: white;
    border-right: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    color: #f1f4fa;
    font-size: 14px;
    margin: 0;
  }
}
</style>
