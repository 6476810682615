var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"confirmation-production-parameters_optical"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row no-gutters"},[_c('label',{staticClass:"col-12 col-md-4 col-form-label"},[_vm._v(_vm._s(_vm.$t("label_product")))]),_c('div',{staticClass:"col-12 col-md-8"},[_c('InputText',{staticClass:"form-control new-style w-100",attrs:{"maxlength":"255","value":_vm.propDto.order_request_info.processing_level
                ? _vm.propDto.order_request_info.processing_level.translation &&
                  _vm.propDto.order_request_info.processing_level.translation[
                    _vm.lang
                  ] &&
                  _vm.propDto.order_request_info.processing_level.translation[
                    _vm.lang
                  ].value
                  ? _vm.propDto.order_request_info.processing_level.translation[
                      _vm.lang
                    ].value
                  : _vm.propDto.order_request_info.processing_level.value
                : '',"readonly":""}})],1)])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row no-gutters"},[_c('label',{staticClass:"col-12 col-md-4 col-form-label"},[_vm._v(_vm._s(_vm.$t("label_resampling_kernel")))]),_c('div',{staticClass:"col-12 col-md-8"},[_c('InputText',{staticClass:"form-control new-style w-100",attrs:{"maxlength":"255","value":_vm.propDto.order_request_info.resampling_kernel
                ? _vm.propDto.order_request_info.resampling_kernel.translation &&
                  _vm.propDto.order_request_info.resampling_kernel.translation[
                    _vm.lang
                  ] &&
                  _vm.propDto.order_request_info.resampling_kernel.translation[
                    _vm.lang
                  ].value
                  ? _vm.propDto.order_request_info.resampling_kernel.translation[
                      _vm.lang
                    ].value
                  : _vm.propDto.order_request_info.resampling_kernel.value
                : '',"readonly":""}})],1)])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row no-gutters"},[_c('label',{staticClass:"col-12 col-md-4 col-form-label"},[_vm._v(_vm._s(_vm.$t("label_bands")))]),_c('div',{staticClass:"col-12 col-md-8"},[_c('InputText',{staticClass:"form-control new-style w-100",attrs:{"maxlength":"255","value":_vm.propDto.order_request_info.band
                ? _vm.propDto.order_request_info.band.translation &&
                  _vm.propDto.order_request_info.band.translation[_vm.lang] &&
                  _vm.propDto.order_request_info.band.translation[_vm.lang].value
                  ? _vm.propDto.order_request_info.band.translation[_vm.lang].value
                  : _vm.propDto.order_request_info.band.value
                : '',"readonly":""}})],1)])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row no-gutters"},[_c('label',{staticClass:"col-12 col-md-4 col-form-label"},[_vm._v(_vm._s(_vm.$t("label_license")))]),_c('div',{staticClass:"col-12 col-md-8"},[_c('InputText',{staticClass:"form-control new-style w-100",attrs:{"maxlength":"255","value":_vm.propDto.order_request_info.license
                ? _vm.propDto.order_request_info.license.translation &&
                  _vm.propDto.order_request_info.license.translation[_vm.lang] &&
                  _vm.propDto.order_request_info.license.translation[_vm.lang].value
                  ? _vm.propDto.order_request_info.license.translation[_vm.lang].value
                  : _vm.propDto.order_request_info.license.value
                : '',"readonly":""}})],1)])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row no-gutters"},[_c('label',{staticClass:"col-12 col-md-4 col-form-label"},[_vm._v(_vm._s(_vm.$t("label_bit_depth")))]),_c('div',{staticClass:"col-12 col-md-8"},[_c('InputText',{staticClass:"form-control new-style w-100",attrs:{"maxlength":"255","value":_vm.propDto.order_request_info.bit_depth
                ? _vm.propDto.order_request_info.bit_depth.translation &&
                  _vm.propDto.order_request_info.bit_depth.translation[_vm.lang] &&
                  _vm.propDto.order_request_info.bit_depth.translation[_vm.lang].value
                  ? _vm.propDto.order_request_info.bit_depth.translation[_vm.lang]
                      .value
                  : _vm.propDto.order_request_info.bit_depth.value
                : '',"readonly":""}})],1)])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row no-gutters"},[_c('label',{staticClass:"col-12 col-md-4 col-form-label"},[_vm._v(_vm._s(_vm.$t("label_ortho_options")))]),_c('div',{staticClass:"col-12 col-md-8"},[_c('InputText',{staticClass:"form-control new-style w-100",attrs:{"maxlength":"255","value":_vm.propDto.order_request_info.ortho_options
                ? _vm.propDto.order_request_info.ortho_options.translation &&
                  _vm.propDto.order_request_info.ortho_options.translation[
                    _vm.lang
                  ] &&
                  _vm.propDto.order_request_info.ortho_options.translation[_vm.lang]
                    .value
                  ? _vm.propDto.order_request_info.ortho_options.translation[_vm.lang]
                      .value
                  : _vm.propDto.order_request_info.ortho_options.value
                : '',"readonly":""}})],1)])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row no-gutters"},[_c('label',{staticClass:"col-12 col-md-4 col-form-label"},[_vm._v(_vm._s(_vm.$t("label_pixel_resolution")))]),_c('div',{staticClass:"col-12 col-md-8"},[_c('InputText',{staticClass:"form-control new-style w-100",attrs:{"maxlength":"255","value":_vm.propDto.order_request_info.pixel_resolution
                ? _vm.propDto.order_request_info.pixel_resolution.translation &&
                  _vm.propDto.order_request_info.pixel_resolution.translation[
                    _vm.lang
                  ] &&
                  _vm.propDto.order_request_info.pixel_resolution.translation[
                    _vm.lang
                  ].value
                  ? _vm.propDto.order_request_info.pixel_resolution.translation[
                      _vm.lang
                    ].value
                  : _vm.propDto.order_request_info.pixel_resolution.value
                : '',"readonly":""}})],1)])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row no-gutters"},[_c('label',{staticClass:"col-12 col-md-4 col-form-label"},[_vm._v(_vm._s(_vm.$t("label_file_format")))]),_c('div',{staticClass:"col-12 col-md-8"},[_c('InputText',{staticClass:"form-control new-style w-100",attrs:{"maxlength":"255","value":_vm.propDto.order_request_info.file_format
                ? _vm.propDto.order_request_info.file_format.translation &&
                  _vm.propDto.order_request_info.file_format.translation[_vm.lang] &&
                  _vm.propDto.order_request_info.file_format.translation[_vm.lang]
                    .value
                  ? _vm.propDto.order_request_info.file_format.translation[_vm.lang]
                      .value
                  : _vm.propDto.order_request_info.file_format.value
                : '',"readonly":""}})],1)])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row no-gutters"},[_c('label',{staticClass:"col-12 col-md-6 col-form-label"},[_vm._v(_vm._s(_vm.$t("label_dra_contrast_enhancement")))]),_c('div',{staticClass:"col-12 col-md-6"},[_c('InputText',{staticClass:"form-control new-style w-100",attrs:{"maxlength":"255","value":_vm.propDto.order_request_info.dra_contrast_enhancement
                ? _vm.propDto.order_request_info.dra_contrast_enhancement
                    .translation &&
                  _vm.propDto.order_request_info.dra_contrast_enhancement
                    .translation[_vm.lang] &&
                  _vm.propDto.order_request_info.dra_contrast_enhancement
                    .translation[_vm.lang].value
                  ? _vm.propDto.order_request_info.dra_contrast_enhancement
                      .translation[_vm.lang].value
                  : _vm.propDto.order_request_info.dra_contrast_enhancement.value
                : '',"readonly":""}})],1)])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row no-gutters"},[_c('label',{staticClass:"col-12 col-md-4 col-form-label"},[_vm._v(_vm._s(_vm.$t("label_tiling")))]),_c('div',{staticClass:"col-12 col-md-8"},[_c('InputText',{staticClass:"form-control new-style w-100",attrs:{"maxlength":"255","value":_vm.propDto.order_request_info.tiling
                ? _vm.propDto.order_request_info.tiling.translation &&
                  _vm.propDto.order_request_info.tiling.translation[_vm.lang] &&
                  _vm.propDto.order_request_info.tiling.translation[_vm.lang].value
                  ? _vm.propDto.order_request_info.tiling.translation[_vm.lang].value
                  : _vm.propDto.order_request_info.tiling.value
                : '',"readonly":""}})],1)])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row no-gutters"},[_c('label',{staticClass:"col-12 col-md-4 col-form-label"},[_vm._v(_vm._s(_vm.$t("label_delivery_media")))]),_c('div',{staticClass:"col-12 col-md-8"},[_c('InputText',{staticClass:"form-control new-style w-100",attrs:{"maxlength":"255","value":_vm.propDto.order_request_info.delivery_media
                ? _vm.propDto.order_request_info.delivery_media.translation &&
                  _vm.propDto.order_request_info.delivery_media.translation[
                    _vm.lang
                  ] &&
                  _vm.propDto.order_request_info.delivery_media.translation[_vm.lang]
                    .value
                  ? _vm.propDto.order_request_info.delivery_media.translation[
                      _vm.lang
                    ].value
                  : _vm.propDto.order_request_info.delivery_media.value
                : '',"readonly":""}})],1)])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-2"},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t("label_note")))])]),_c('div',{staticClass:"col-10"},[_c('Textarea',{staticClass:"form-control new-style",attrs:{"rows":"2","maxlength":"512","value":_vm.propDto.note,"autoResize":true,"readonly":""}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }