<template>
  <CommonAccordion :propTitle="'Orders'">
    <div slot="content">
      <div class="orders-tracking">
        <div class="tracking-tabs">
          <vue-tabs>
            <v-tab
              :title="
                'In Progress' +
                (propInprogressLength ? ' (' + propInprogressLength + ')' : '')
              "
              ><InProgressTab
                :map="map"
                :propItems="propInprogressItems"
                @actionClick="actionClick"
                @loadMore="onLoadMore"
            /></v-tab>
            <v-tab
              :title="
                'Delivered' +
                (propDeliveredLength ? ' (' + propDeliveredLength + ')' : '')
              "
              ><DeliveredTab
                :map="map"
                :propItems="propDeliveredItems"
                @actionClick="actionClick"
                @loadMore="onLoadMore"
                @downloadImage="downloadImage"
            /></v-tab>
            <v-tab
              :title="
                'Errors' +
                (propErrorsLength ? ' (' + propErrorsLength + ')' : '')
              "
              ><ErrorsTab
                :map="map"
                :propItems="propErrorsItems"
                @actionClick="actionClick"
                @loadMore="onLoadMore"
            /></v-tab>
            <v-tab
              :title="
                'Rejected' +
                (propRejectedLength ? ' (' + propRejectedLength + ')' : '')
              "
              ><RejectedTab
                :map="map"
                :propItems="propRejectedItems"
                @actionClick="actionClick"
                @loadMore="onLoadMore"
            /></v-tab>
          </vue-tabs>
        </div>

        <div class="tracking-map" id="ordersTrackingMap"></div>
      </div>
    </div>
  </CommonAccordion>
</template>
<script>
import { VueTabs, VTab } from "vue-nav-tabs";
import CommonAccordion from "@/components/commons/accordion/Accordion3";
import InProgressTab from "./orders/InPorgress.vue";
import DeliveredTab from "./orders/Delivered.vue";
import ErrorsTab from "./orders/Errors.vue";
import RejectedTab from "./orders/Rejected.vue";

import Map from "ol/Map";
import View from "ol/View";
import { Tile as TileLayer } from "ol/layer";
import XYZ from "ol/source/XYZ";
import { defaults as defaultControls } from "ol/control";
import { fromLonLat } from "ol/proj";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";

import metaKeyFunc from "@/utils/functions/metakey";
import oauthFunc from "@/utils/functions/oauth";
import cryptoFunc from "@/utils/functions/crypto";

export default {
  components: {
    VueTabs,
    VTab,
    CommonAccordion,
    InProgressTab,
    DeliveredTab,
    ErrorsTab,
    RejectedTab,
  },
  props: {
    propInprogressItems: {
      type: Array,
      default: () => [],
    },
    propDeliveredItems: {
      type: Array,
      default: () => [],
    },
    propErrorsItems: {
      type: Array,
      default: () => [],
    },
    propRejectedItems: {
      type: Array,
      default: () => [],
    },
    propInprogressLength: {
      type: Number,
      default: 0,
    },
    propDeliveredLength: {
      type: Number,
      default: 0,
    },
    propErrorsLength: {
      type: Number,
      default: 0,
    },
    propRejectedLength: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      map: Object,
      layer: Object,
    };
  },
  created() {},
  async mounted() {
    if (!this.basemap || Object.keys(this.basemap).length === 0)
      await this.getAllBasemaps();
    this.initMapOpenlayer();
  },
  watch: {},
  methods: {
    actionClick(data) {
      this.$emit("actionClick", data);
    },
    onLoadMore(status) {
      this.$emit("loadMore", status);
    },

    downloadImage(data) {
      this.$emit("downloadImage", data);
    },
    initMapOpenlayer: function () {
      var vm = this;
      var source = new VectorSource({ wrapX: false, id: "sourcedraw" });

      this.layer = new VectorLayer({
        source: source,
      });
      this.layer.set("id", "layerdraw");
      this.map = new Map({
        controls: defaultControls({ attribution: false, zoom: false }),
        layers: [
          new TileLayer({
            title: "Basemap",
            id: "vgbasemap",
            source: new XYZ({
              url:
                vm.basemap &&
                Object.keys(vm.basemap).length > 0 &&
                vm.basemap.source &&
                vm.basemap.source.url
                  ? vm.basemap.source.url
                  : process.env.VUE_APP_BASEMAP_URL,
            }),
            show: true,
            opacity: 1,
          }),
          this.layer,
        ],
        target: "ordersTrackingMap",
        view: new View({
          center: fromLonLat(
            vm.propPoints && vm.propPoints.length > 0
              ? vm.propPoints[0]
              : [108.222848, 16.062833]
          ),
          zoom: 5,
          minZoom: 0,
          maxZoom: 20,
        }),
      });
      this.$emit("initMap", this.map);
    },
    async getAllBasemaps() {
      try {
        let response = await metaKeyFunc.getByKey(
          "BASEMAPS",
          this.$store.getters.getAccessToken
        );
        if (response && response.status === 200) {
          if (response.data && response.data.success) {
            let items = [];
            let data = response.data.data ? response.data.data : [];
            for (let i = 0; i < data.length; i++) {
              try {
                let obj = JSON.parse(data[i].value);
                if (obj && Object.keys(obj).length > 0) {
                  items.push({
                    id: data[i].id,
                    type:
                      obj.type && obj.type === "shapefile"
                        ? "JSON_FILE"
                        : "TILE_LAYER",
                    name: data[i].name,
                    title: obj.name ? obj.name : obj.NAME,
                    value: data[i].value,
                    description: data[i].description,
                    id:
                      new Date().getTime() -
                      Math.floor(Math.random() * 99999) +
                      Math.floor(Math.random() * 10000),
                    show:
                      (obj.is_show && obj.is_show + "" === "1") ||
                      (obj.IS_SHOW && obj.IS_SHOW + "" === "1")
                        ? true
                        : false,
                    opacity: 100,
                    basemap: true,
                    source: {
                      type: "TILE_IMAGE",
                      url: obj.path ? obj.path : obj.PATH,
                    },
                  });
                }
              } catch (error) {}
            }
            if (items && items.length > 0) {
              if (!this.basemap || Object.keys(this.basemap).length === 0) {
                let arrayBasemaps = items.filter(
                  (x) => x.show === true && (!x.type || x.type === "TILE_LAYER")
                );
                if (arrayBasemaps && arrayBasemaps.length > 0)
                  this.$store.dispatch(
                    "setBasemapDefault",
                    arrayBasemaps[arrayBasemaps.length - 1]
                  );
              }
            }
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.refreshToken(this.getAllBasemaps);
        }
      }
    },
    async refreshToken(callBack) {
      let lockRefresh = localStorage.getItem("lock-refresh");
      if (lockRefresh != null || lockRefresh != undefined) {
        if (lockRefresh && (lockRefresh + "").trim().toLowerCase() === "true") {
          callBack(arguments[1]);
          return;
        }
      }
      localStorage.setItem("lock-refresh", true);
      try {
        let response = await oauthFunc.refresh(
          this.$store.getters.getRefreshToken
        );
        if (response.status === 200) {
          await this.$store.dispatch("setToken", response.data);
          await localStorage.setItem(
            "data",
            btoa(cryptoFunc.encrypt(JSON.stringify(response.data)).toString())
          );
          localStorage.removeItem("lock-refresh");
          callBack(arguments[1]);
        } else {
          localStorage.removeItem("lock-refresh");
          this.$store.dispatch("clearToken").then((r) => {});
        }
      } catch (error) {
        localStorage.removeItem("lock-refresh");
        this.$store.dispatch("clearToken").then((r) => {});
      }
      localStorage.removeItem("lock-refresh");
    },
  },
};
</script>
<style lang="scss" scoped>
.orders-tracking {
  display: grid;
  grid-template-columns: [first] 512px [line2] auto [end];
  grid-gap: 0.5rem;
  .tracking-tabs {
    border: 1px solid #e8e8e8;
    border-radius: 0.25rem;
    padding: 0.5rem;
  }
  .tracking-map {
    border: 1px solid #e8e8e8;
    border-radius: 0.25rem;
    min-height: 528px;
  }
}
</style>
