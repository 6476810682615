import axios from 'axios';
import basicApi from "@/utils/apis/basic";
import app from './../../main'
import { geoServiceApiURL } from "@/utils/commons/environment"
export default {
  async getCountry(params, access_token) {
    let headers = basicApi.misc.get_country.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.misc.get_country.method,
      url:  geoServiceApiURL + basicApi.misc.get_country.url,
      headers: headers,
      params: params
    });
    let response = await object;
    return response;
  },

  async getStateByCountry(country_id , access_token) {
    let headers = basicApi.misc.get_state_by_country.headers;
    headers["Authorization"] = "bearer " + access_token;
    headers['Accept-Language'] = app.$store.getters.getLang;
    const object = axios({
      method: basicApi.misc.get_state_by_country.method,
      url:  geoServiceApiURL + basicApi.misc.get_state_by_country.url,
      headers: headers,
      params: {
        country_id :country_id 
      }
    });
    let response = await object;
    return response;
  },
}