<template>
  <div class="tasking-production-parameters_radar">
    <div class="row no-gutters">
      <label class="col-3 col-form-label"
        >{{ $t("label_product_level") }}&nbsp;<span class="required"
          >*</span
        ></label
      >
      <div class="col-9">
        <Dropdown
          class="new-style w-100"
          :options="propProductLevels"
          v-model="propEntity.radar.product_level"
          :showClear="false"
          optionValue="id"
          optionLabel="value"
          @change="productLevelChange"
        />
      </div>
    </div>
    <div class="row no-gutters">
      <label class="col-3 col-form-label"
        >{{ $t("label_file_format") }}&nbsp;<span class="required"
          >*</span
        ></label
      >
      <div class="col-9">
        <Dropdown
          class="new-style w-100"
          :options="propFileFormats"
          v-model="propEntity.radar.file_format"
          :showClear="false"
          optionValue="id"
          optionLabel="value"
          @change="fileFormatChange"
        />
      </div>
    </div>
    <div class="row no-gutters">
      <label class="col-3 col-form-label"
        >{{ $t("label_delivery_media") }}&nbsp;<span class="required"
          >*</span
        ></label
      >
      <div class="col-9">
        <Dropdown
          class="new-style w-100"
          :options="propDeliveriesMedia"
          v-model="propEntity.radar.delivery_media"
          :showClear="false"
          optionValue="id"
          optionLabel="value"
          @change="deliveryMediaChange"
        />
      </div>
    </div>
    <div class="row no-gutters">
      <label class="col-3 col-form-label"
        >{{ $t("label_delivery_service") }}&nbsp;<span class="required"
          >*</span
        ></label
      >
      <div class="col-9">
        <Dropdown
          class="new-style w-100"
          :options="propDeliveriesService"
          v-model="propEntity.radar.delivery_service"
          :showClear="false"
          optionValue="id"
          optionLabel="value"
          @change="deliveryServiceChange"
        />
      </div>
    </div>
    <div class="row no-gutters">
      <label class="col-3 col-form-label"
        >{{ $t("label_license") }}&nbsp;<span class="required">*</span></label
      >
      <div class="col-9">
        <Dropdown
          class="new-style w-100"
          :options="propLicenses"
          v-model="propEntity.radar.license"
          :showClear="false"
          optionValue="id"
          optionLabel="value"
          @change="licenseChange"
        />
      </div>
    </div>
    <div class="row no-gutters">
      <label class="col-3 col-form-label">{{ $t("label_note") }}</label>
      <div class="col-9">
        <Textarea
          class="form-control new-style"
          rows="2"
          maxlength="512"
          v-model="propEntity.note"
          :autoResize="true"
        >
        </Textarea>
      </div>
    </div>
  </div>
</template>
<script>
import Dropdown from "@/components/commons/dropdown/Dropdown";
import Textarea from "@/components/commons/textarea/Textarea";
export default {
  components: {
    Dropdown,
    Textarea,
  },
  props: {
    propEntity: {
      type: Object,
      default: () => {},
    },

    propDeliveriesMedia: {
      type: Array,
      default: () => [],
    },
    propFileFormats: {
      type: Array,
      default: () => [],
    },

    propProductLevels: {
      type: Array,
      default: () => [],
    },
    propDeliveriesService: {
      type: Array,
      default: () => [],
    },
    propLicenses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    productLevelChange(event) {
      let item = this.propProductLevels.find((x) => x.id === event.value);
      this.propEntity.radar.product_level_name =
        item && item.value ? item.value.trim() : null;
    },
    fileFormatChange(event) {
      let item = this.propFileFormats.find((x) => x.id === event.value);
      this.propEntity.radar.file_format_name =
        item && item.value ? item.value.trim() : null;
    },
    deliveryMediaChange(event) {
      let item = this.propDeliveriesMedia.find((x) => x.id === event.value);
      this.propEntity.radar.delivery_media_name =
        item && item.value ? item.value.trim() : null;
    },
    deliveryServiceChange(event) {
      let item = this.propDeliveriesService.find((x) => x.id === event.value);
      this.propEntity.radar.delivery_service_name =
        item && item.value ? item.value.trim() : null;
    },
    licenseChange(event) {
      let item = this.propLicenses.find((x) => x.id === event.value);
      this.propEntity.radar.license_name =
        item && item.value ? item.value.trim() : null;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.tasking-production-parameters_radar {
  padding: 0 0.5rem;
  width: 100%;
  color: $text-color;
  .row {
    margin-bottom: 1rem;
  }
  textarea {
    font-size: 14px;
  }
}
</style>
