<template>
  <div class="geohub-tab">
    <div class="tab-left left-content">
      <div class="geohub-steps">
        <StepProgress
          :steps="steps"
          :current-step="step"
          :activeColor="'#009EF7'"
          :activeTextColor="'#1E1E2D'"
          :passiveColor="'#B3C3D4'"
          :iconClass="'fas fa-check'"
        />
      </div>
      <div
        class="geohub-components p-4"
        :style="{ height: contentHeight + 'px' }"
      >
        <div class="component-order-name">
          <div class="form-group" v-show="step === 0">
            <label for="txt-cart-description" class="control-label pl-1">
              Order name
            </label>
            <Textarea
              id="txt-cart-description"
              class="form-control"
              rows="2"
              maxlength="512"
              autofocus
              :autoResize="true"
              v-model="cart.name"
              @change="orderNameChanged"
            >
            </Textarea>
            <small class="w-100 text-right float-right mt-1"
              >{{
                cart.name && cart.name.trim() ? cart.name.trim().length : 0
              }}/512</small
            >
          </div>
        </div>
        <div class="component-confirm text-center" v-show="step === 1">
          <label class="control-label"> Priority</label>
          <div class="form-row">
            <div class="col" v-for="item in propPriorityLevel" :key="item.id">
              <RadioButton
                :id="item.id"
                :name="item.id"
                :value="item.id"
                v-model="cart.priority_id"
              />
              <label class="m-0 ml-2" :for="item.id">{{ item.value }}</label>
            </div>
          </div>
        </div>
      </div>

      <div
        class="geohub-actions d-flex justify-content-between align-items-center px-4 py-2"
      >
        <Button
          v-if="step >= 1"
          @click="prevStep"
          :label="'Back'"
          :icon="'fas fa-arrow-left'"
          :class="'p-button-secondary p-button-text button-prev'"
        />
        <Button
          v-if="step < 1"
          @click="nextStep"
          :label="'Continue'"
          :class="'button-next'"
        />
      </div>
    </div>

    <div class="tab-right">
      <h6 class="summary-title">Order Summary - GeoHub</h6>
      <div class="summary-order">
        <div class="form-group">
          <label>Order name</label>
          <h6
            class="summary-order-name"
            :class="cart.name ? 'font-weight-bold' : 'text-muted'"
            :title="cart.name ? (cart.name.length > 26 ? cart.name : '') : ''"
          >
            {{
              cart.name
                ? cart.name.length > 26
                  ? cart.name.substring(0, 26) + "..."
                  : cart.name
                : "--"
            }}
          </h6>
        </div>
        <div class="form-group">
          <label>Orders to be placed</label>
          <h6 class="summary-order-name font-weight-bold">1</h6>
        </div>
      </div>
      <ul
        class="order-summary-list"
        :style="{ height: contentHeight - 80 + 'px' }"
      >
        <li class="summary-item" v-for="p in arraySatelliteName" :key="p.name">
          <div class="item-header">
            <svg
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-do1tfz"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="SatelliteIcon"
            >
              <path
                fill-rule="evenodd"
                d="m17.714 2.3597 4.0406 4.0406c.4463.4463.4463 1.17 0 1.6162l-4.0405 4.0404.808.8084c.4463.4463.4463 1.1699 0 1.6162l-4.0406 4.0406c-.4463.4463-1.17.4463-1.6162 0l-.8084-.808-4.0404 4.0405c-.4463.4463-1.1699.4463-1.6162 0L2.3597 17.714c-.4463-.4463-.4463-1.17 0-1.6162l4.0401-4.0409-.8076-.8079c-.4463-.4463-.4463-1.17 0-1.6162l4.0406-4.0406c.4463-.4463 1.17-.4463 1.6162 0l.808.8076 4.0408-4.0401c.4463-.4463 1.1699-.4463 1.6162 0ZM4.7838 15.2889l-1.616 1.617 4.0406 4.0406L8.8238 19.33l-4.04-4.0412Zm2.4229-2.4251-1.6149 1.6171 4.04 4.0412 1.616-1.6172-4.0411-4.0411Zm3.2342-6.4635-4.0406 4.0406 7.273 7.2731 4.0407-4.0406-7.2731-7.2731Zm4.04-.8085-1.6171 1.6149 4.0411 4.0411 1.6172-1.616-4.0412-4.04Zm2.425-2.424-1.617 1.616 4.0412 4.04 1.6164-1.6154-4.0406-4.0406Z"
              ></path>
            </svg>
            <span> {{ p.value }}</span>
          </div>
          <div class="item-card">
            <div class="px-2 py-3">
              <div class="item-count">
                <svg
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="BurstModeOutlinedIcon"
                >
                  <path
                    d="M1 5h2v14H1zm4 0h2v14H5zm17 0H10c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1zm-1 12H11V7h10v10zm-3.57-4.38-2 2.57L14 13.47l-2 2.52h8z"
                  ></path></svg
                ><span class="pl-1 pt-1">&nbsp; {{ p.count }} items</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="summary-actions" v-if="step > 0 || cart.name">
        <Button
          label="Order"
          class="button-confirm"
          @click="onOrderCapella"
          :icon="
            propButton && propButton.processing ? 'fas fa-spinner fa-spin' : ''
          "
          :disabled="
            propButton && propButton.disable ? propButton.disable : false
          "
        >
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import StepProgress from "@/components/commons/steps/StepProgress";
import Textarea from "@/components/commons/textarea/Textarea";
import Button from "@/components/commons/button/Button";
import RadioButton from "@/components/commons/radiobutton/RadioButton";
export default {
  components: {
    StepProgress,
    Textarea,
    Button,
    RadioButton,
  },
  props: {
    propCartName: {
      type: String,
      default: null,
    },
    propPriorityLevel: {
      type: Array,
      default: () => [],
    },
    propImages: {
      type: Array,
      default: () => [],
    },
    propButton: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      steps: ["Order name", "Programming service"],
      step: 0,
      cart: {
        name: null,
        address: null,
        description: null,
        note: null,
        email: null,
        full_name: null,
        image_ids: [],
        organization: null,
        phone_number: null,
        processing_level: null,
        priority_id: null,
      },
      satellites: [],
      arraySatelliteName: [],
    };
  },
  created() {
    this.satellites = this.propImages.map((x) => x.properties.platform);
    for (let i = 0; i < this.satellites.length; i++) {
      let index = this.arraySatelliteName.findIndex(
        (x) => x.name === this.satellites[i].name
      );
      if (index >= 0) {
        this.arraySatelliteName[index].count++;
      } else {
        this.arraySatelliteName.push({
          name: this.satellites[i],
          value: this.satellites[i],
          count: 1,
        });
      }
    }
  },
  mounted() {
    if (
      this.propPriorityLevel &&
      this.propPriorityLevel.length > 0 &&
      !this.cart.priority_id
    )
      this.cart.priority_id = this.propPriorityLevel[0].id;
  },
  watch: {
    propPriorityLevel(val) {
      if (
        this.propPriorityLevel &&
        this.propPriorityLevel.length > 0 &&
        !this.cart.priority_id
      )
        this.cart.priority_id = this.propPriorityLevel[0].id;
    },
    propCartName: {
      handler(val) {
        this.cart.name = val;
      },
      immediate: true,
    },
  },
  methods: {
    orderNameChanged() {
      this.$emit("orderNameChanged", this.cart.name);
    },
    nextStep() {
      this.step++;
    },
    prevStep() {
      this.step--;
    },

    clearCart() {
      this.cart.name = null;
      this.cart.description = null;
      this.cart.note = null;
      if (this.propPriorityLevel && this.propPriorityLevel.length > 0)
        this.cart.priority_id = this.propPriorityLevel[0].id;
      else this.cart.priority_id = null;
    },
    onOrderCapella() {
      let message = [];
      if (!this.cart.name || !this.cart.name.trim()) {
        message.push("Order Name is required.");
      }
      if (message.length > 0) {
        this.$emit("message", message);
        return;
      }

      this.cart.note = this.cart.description = this.cart.name.trim();
      this.$emit("onOrderCapella", this.cart);
    },
  },
  computed: {
    documentHeight() {
      return this.$store.getters.getDocumentHeight;
    },
    contentHeight() {
      return (this.documentHeight * 50) / 100;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.geohub-tab {
  display: grid;
  grid-template-columns: [first] auto [line2] 272px [end];
  .tab-left {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 100%;
    align-items: stretch;
    align-content: stretch;
    .geohub-steps {
      padding-left: 5rem;
      padding-right: 5rem;
      &.shadow-b {
        box-shadow: rgba(33, 35, 38, 0.2) 0px 10px 10px -10px;
      }
    }
    .geohub-components {
      overflow: auto;
      .component-select-assets {
        .assets-box {
          .border-t {
            border-top-width: 1px;
            border-top-style: solid;
            border-color: rgb(189, 197, 198);
            &:first-child {
              border-top: none;
            }
          }
          .title-box {
            font-size: 20px;
            font-weight: 700;
            &::after {
              content: "";
              display: block;
              width: 100%;
              margin-top: 0.25rem;
            }
          }
        }
      }
    }
    .geohub-actions {
      width: 100%;
      background-color: rgba($color: #000000, $alpha: 0.005);
      border-radius: 0.25rem;
      .button-prev {
        outline: none;
        box-shadow: none;
        &:focus {
          outline: none;
          box-shadow: none;
        }
        &:active {
          outline: none;
          box-shadow: none;
        }
      }
      .button-next {
        margin-left: auto;
        background-color: $background-color-2;
        border-color: $background-color-2;
      }
    }
  }
  .tab-right {
    background-color: rgba($color: $background-color-2, $alpha: 0.05);
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    .summary-title {
      font-size: 16px;
      font-weight: 700;
      padding: 0.5rem 1rem;
      border-bottom: 1px solid rgba(189, 197, 198, 0.5);
    }
    .summary-order {
      padding: 0.5rem 1rem 1rem;
      border-bottom: 1px solid rgba(189, 197, 198, 0.5);
      display: block;
      .form-group {
        margin-bottom: 0.5rem;
        h6 {
          margin: 0;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
    ul.order-summary-list {
      list-style: none;
      margin: 0;
      padding: 0;
      overflow: auto;
      height: 100%;
      position: relative;
      li.summary-item {
        padding: 0.75rem;
        border-bottom: 1px solid #eff2f5;
        &:last-child {
          border: 0;
        }
        .item-header {
          display: flex;
          box-sizing: border-box;
          list-style: none;
          font-weight: 500;
          font-size: 1rem;
          margin-bottom: 0.25rem;
          background-color: transparent;
          svg {
            user-select: none;
            width: 1em;
            height: 1em;
            display: inline-block;
            fill: currentcolor;
            flex-shrink: 0;
            transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            font-size: 1.5rem;
            margin-right: 4px;
          }
        }
        .item-card {
          width: 100%;
          color: rgb(0, 31, 35);
          transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          border-radius: 4px;
          border: 1px solid rgb(189, 197, 198);
          overflow: hidden;
          background-color: white;
          flex-shrink: 0;
          .item-count {
            max-width: 100%;
            font-family: Inter, sans-serif;
            font-size: 0.8125rem;
            display: inline-flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            height: 24px;
            color: rgb(0, 31, 35);
            white-space: nowrap;
            transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            cursor: default;
            outline: 0px;
            text-decoration: none;
            padding: 0px;
            vertical-align: middle;
            box-sizing: border-box;
            background-color: transparent;
            border-radius: 4px;
            border: none;
            svg {
              user-select: none;
              width: 1em;
              height: 1em;
              display: inline-block;
              fill: currentcolor;
              flex-shrink: 0;
              transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
              font-size: 1.5rem;
            }
          }
        }
      }
    }
    .summary-actions {
      padding: 0.5rem;
      transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      box-shadow: none;
      display: flex;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;
      flex-shrink: 0;
      position: static;
      background-color: transparent;
      color: inherit;
      background-image: unset;
      border-top: 1px solid rgba(189, 197, 198, 0.5);
      .button-confirm {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        outline: 0px;
        margin: 0px;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        font-size: 0.875rem;
        font-weight: 700;
        text-transform: none;
        line-height: 1.75;
        min-width: 64px;
        padding: 5px 10px;
        border-radius: 4px;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border: 1px solid $background-color-2;
        color: $background-color-2;
        width: 100%;
        box-shadow: none;
        background-color: transparent;
        &:hover {
          background-color: rgba($color: $background-color-2, $alpha: 0.125);
        }
      }
    }
  }
}
</style>
